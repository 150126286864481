<template>
  <div>
    <div v-if="isLoading" class="loader-container">
      <BaseLoader class="loader"></BaseLoader>
    </div>
    <codemirror v-model="val" :style="{ height: ' 125px' }" :extensions="extensions" tab-size="4" @ready="isLoading = false" @change="onChange">
    </codemirror>
  </div>
</template>

<script>
import { Codemirror } from 'vue-codemirror';
import { dracula } from '@uiw/codemirror-theme-dracula';
import { javascript } from '@codemirror/lang-javascript';
import BaseLoader from '../../components/base/BaseLoader.vue';

export default {
  components: {
    Codemirror,
    BaseLoader
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  emits: ['input', 'update:value'],
  data() {
    return {
      val: {},
      extensions: [javascript(), dracula],
      isLoading: true
    };
  },
  watch: {
    val(newVal) {
      this.$emit('update:value', newVal);
    },
    value: {
      handler: function onJsonInputValueChanged(newVal) {
        this.val = newVal;
      },
      immediate: true
    }
  },
  methods: {
    onChange(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss" scoped>
.loader-container {
  height: 125px;
  display: grid;
  place-items: center;
  border: 1px solid var(--secondaryOverlayShade1);
  .loader {
    width: 2rem;
    height: 2rem;
    margin: 50%;
  }
}
</style>
