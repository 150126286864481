export const POI_TYPES = {
  VISIT: 1,
  INSPECTION: 2,
  SCAN: 5
};

export const MAP_SETTINGS = {
  SCALE_FACTOR: 1.015,
  MAX_SCALE: 6,
  MIN_SCALE: 1,
  INITIAL_ZOOM: 10,
  INDICATOR_FONT_SIZE: 16,
  INDICATOR_RADIUS: 20
};

export const LANDMARKS_TYPES = {
  DOOR: 'DOOR',
  WINDOW: 'WINDOW',
  EMERGENCY_EXIT: 'EMERGENCY_EXIT',
  WC: 'WC',
  MAIN_ENTRANCE: 'MAIN_ENTRANCE',
  SERVER_ROOM: 'SERVER_ROOM',
  SAFETY_EQUIPMENT: 'SAFETY_EQUIPMENT',
  FIRE_EXTINGUISHER: 'FIRE_EXTINGUISHER',
  NOTE: 'NOTE'
};

export const MAP_MODE = {
  DEFAULT: 0,
  LANDMARKS_CREATE: 'LANDMARKS_CREATE',
  AREA_CREATE: 'AREA_CREATE',
  AREA_VIEW: 'AREA_VIEW',
  PLAN_CREATE: 'PLAN_CREATE',
  MISSION_TEMPLATE_EDIT: 'MISSION_TEMPLATE_EDIT',
  MISSION_TEMPLATE_VIEW: 'MISSION_TEMPLATE_VIEW',
  REPOSITION: 'REPOSITION',
  CONFIG_AREA_CREATE: 'CONFIG_AREA_CREATE'
};

export const MAP_POI_OPTIONS = {
  VISIT: 'VISIT',
  SCAN: 'SCAN',
  INSPECTION: 'INSPECTION',
  HIGH_INSPECTION: 'HIGH_INSPECTION',
  LOW_INSPECTION: 'LOW_INSPECTION'
};

export const POI_INSPECTIONS_HEIGHT_CODES = {
  LOW: 0,
  NOMINAL: 1,
  HIGH: 2
};

export const MAP_POI_OPTION_TO_HEIGHT_CODES = {
  [MAP_POI_OPTIONS.LOW_INSPECTION]: POI_INSPECTIONS_HEIGHT_CODES.LOW,
  [MAP_POI_OPTIONS.INSPECTION]: POI_INSPECTIONS_HEIGHT_CODES.NOMINAL,
  [MAP_POI_OPTIONS.HIGH_INSPECTION]: POI_INSPECTIONS_HEIGHT_CODES.HIGH,
  [MAP_POI_OPTIONS.SCAN]: POI_INSPECTIONS_HEIGHT_CODES.NOMINAL
};

export const MAP_POI_OPTION_TO_TYPE = {
  VISIT: POI_TYPES.VISIT,
  SCAN: POI_TYPES.SCAN,
  INSPECTION: POI_TYPES.INSPECTION,
  HIGH_INSPECTION: POI_TYPES.INSPECTION,
  LOW_INSPECTION: POI_TYPES.INSPECTION
};

import IconPoiVisit from '../components/icons/IconPoiVisit.svg?component';
import IconPoiScan from '../components/icons/IconPoiScan.svg?component';
import IconPoiInspection from '../components/icons/IconPoiInspection.svg?component';
import IconPoiHighInspection from '../components/icons/IconPoiHighInspection.svg?component';
import IconPoiLowInspection from '../components/icons/IconPoiLowInspection.svg?component';
export const POI_OPTIONS_ICONS = {
  VISIT: IconPoiVisit,
  SCAN: IconPoiScan,
  INSPECTION: IconPoiInspection,
  HIGH_INSPECTION: IconPoiHighInspection,
  LOW_INSPECTION: IconPoiLowInspection
};

export const MAP_QUICK_ACTION_TYPES = {
  INVESTIGATE: 'investigateNow',
  INSPECT: 'inspect',
  HIGH_INSPECTION: 'high_inspection',
  LOW_INSPECTION: 'low_inspection',
  SCAN: 'scan',
  SKIP_TILE: 'skipTile',
  FILTER_BY_LOCATION: 'filterByLocation',
  RESET_FILTER_BY_LOCATION: 'resetFilterByLocation',
  VIEW_LOCATION: 'viewLocation',
  DROP_AND_LAND: 'dropNLand',
  TAKEOFF_AND_DOCK: 'takeOffNDock'
};

export const MAP_QUICK_ACTION_TO_POI_OPTION = {
  [MAP_QUICK_ACTION_TYPES.VISIT]: MAP_POI_OPTIONS.VISIT,
  [MAP_QUICK_ACTION_TYPES.SCAN]: MAP_POI_OPTIONS.SCAN,
  [MAP_QUICK_ACTION_TYPES.INSPECT]: MAP_POI_OPTIONS.INSPECTION,
  [MAP_QUICK_ACTION_TYPES.HIGH_INSPECTION]: MAP_POI_OPTIONS.HIGH_INSPECTION,
  [MAP_QUICK_ACTION_TYPES.LOW_INSPECTION]: MAP_POI_OPTIONS.LOW_INSPECTION
};
