export const getEuclideanDistance = (point1, point2) => {
  return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
};

export const getAngleBetweenPointsInRadians = (fromPoint, toPoint) => {
  const pointsDistance = getEuclideanDistance(fromPoint, toPoint);
  let angle = Math.asin((toPoint.y - fromPoint.y) / pointsDistance);

  if (fromPoint.x < toPoint.x) {
    angle = -angle;
  } else {
    angle += Math.PI;
  }

  return angle;
};

export const getAngleBetweenPointsInDegrees = (fromPoint, toPoint) => {
  const pointsDistance = getEuclideanDistance(fromPoint, toPoint);
  let angle = Math.asin((toPoint.y - fromPoint.y) / pointsDistance);

  if (fromPoint.x < toPoint.x) {
    angle = -angle;
  } else {
    angle += Math.PI;
  }

  return ((angle * 180) / Math.PI) % 360;
};

/**
 * Add points to a given path, in a linear distance, so that the time difference between 2 points will not be more then the provided timeDiffMs
 * This is used for allowing smoother transitions fot the device over the map
 * @param {Array<PathPoint>} path
 * @param {Number} timeDiffMs
 * @returns {Array<PathPoint>}
 *
 * @typedef {Object} PathPoint
 * @property {Object} data - the point's location.
 * @property {Number} data.x - the x-coordinate.
 * @property {Number} data.y - the y-coordinate.
 * @property {Number} data.h - the height value.
 * @property {Number} timestamp - the timestamp in which the drone was at that point
 */
export const thickenPathForSmoothTransition = (path, timeDiffMs) => {
  if (!path?.length) return [];
  const newPath = [];

  const sortedPath = path.sort((a, b) => a.timestamp - b.timestamp);

  for (let i = 0; i < sortedPath.length - 1; i++) {
    const start = sortedPath[i];
    const end = sortedPath[i + 1];
    newPath.push(start);
    let currentTime = start.timestamp + timeDiffMs;

    while (currentTime < end.timestamp) {
      newPath.push(calculateNewLocation(start, end, currentTime));
      currentTime += timeDiffMs;
    }
  }
  newPath.push(sortedPath[path.length - 1]);
  return newPath;
};
/**
 * Generate a point between 2 given points, in a provided timestamp, assuming linear progress
 * @param {PathPoint} point1
 * @param {PathPoint} point2
 * @param {Number} newTimestamp
 * @returns {PathPoint}
 */
const calculateNewLocation = (point1, point2, newTimestamp) => {
  const timeDiffRatio = (newTimestamp - point1.timestamp) / (point2.timestamp - point1.timestamp);
  const newPoint = {
    data: {
      x: point1.data.x + (point2.data.x - point1.data.x) * timeDiffRatio,
      y: point1.data.y + (point2.data.y - point1.data.y) * timeDiffRatio
    },
    timestamp: newTimestamp
  };
  return newPoint;
};
