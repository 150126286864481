<template>
  <div class="missions-stats">
    <div v-for="(missionStat, index) in displayConfig" :key="index" class="mission-stat">
      <span v-if="missionStat.icon">
        <component :is="missionStat.icon" class="icon" />
      </span>
      <span class="value">{{ missionStat.value }}</span>
      <div>{{ missionStat.text }}</div>
    </div>
  </div>
</template>

<script>
import { get24TimeFromSeconds } from '../../utils/DateUtils';
import { useContextStore } from '../../store/ContextStore';
import { mapState } from 'pinia';
import IconSavedList from '../../components/icons/IconSavedList.svg?component';
import IconUnlocked from '../../components/icons/IconUnlocked.svg?component';
import IconMissionCompleted from '../../components/icons/IconMissionCompleted.svg?component';
import IconPatrol from '../../components/icons/IconPatrol.svg?component';
import IconDrone from '../../components/icons/IconDrone.svg?component';

export default {
  name: 'DashboardMissionsStat',
  components: {
    IconSavedList,
    IconUnlocked,
    IconMissionCompleted,
    IconPatrol,
    IconDrone
  },
  props: {
    missionsStat: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(useContextStore, ['userMeasurementUnits']),
    flightDistanceText() {
      const units = this.userMeasurementUnits == 'METRIC' ? 'Meters' : 'Feet';
      return `Flight distance (${units})`;
    },
    displayConfig() {
      const finishedSuccessfully = this.missionsStat.total ? `${Math.round((this.missionsStat.finished / this.missionsStat.total) * 100)}%` : 0;
      return [
        {
          text: 'Executed',
          value: this.missionsStat.total,
          icon: IconSavedList
        },
        {
          text: 'Open',
          value: this.missionsStat.unhandled,
          icon: IconUnlocked
        },
        {
          text: 'Finished successfully',
          value: finishedSuccessfully,
          icon: IconMissionCompleted
        },
        {
          text: this.flightDistanceText,
          value: this.missionsStat.totalDistance,
          icon: IconPatrol
        },
        {
          text: 'Flight time (hh:mm)',
          value: get24TimeFromSeconds(this.missionsStat.totalExecutionTimeInSeconds),
          icon: IconDrone
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.missions-stats {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-basis: 70%;

  .mission-stat {
    flex-basis: 33%;

    .icon {
      color: var(--highlightColor);
      margin: 0.5rem 0.3rem -0.1rem 0;
      width: 1.3rem;
      height: 1.3rem;
    }

    .value {
      font-weight: bold;
      font-size: 1.55rem;
    }
  }
}
</style>
