import { defineStore } from 'pinia';
import { useContextStore } from './ContextStore';
import { FETCH_STATE } from '../consts/appConsts';
import zonesService from '../services/api/zonesService';

export const useZonesStore = defineStore('zones', {
  state() {
    return {
      zonesFetchState: FETCH_STATE.INITIAL,
      zonesMap: new Map(),
      zoneSchedulesMap: new Map(),
      zoneSchedulesFetchState: FETCH_STATE.INITIAL,
      nextZoneSchedulesMap: new Map(),
      nextZoneSchedulesFetchState: FETCH_STATE.INITIAL
    };
  },
  getters: {
    wereZonesFetched() {
      return this.zonesFetchState === FETCH_STATE.SUCCESS || this.zonesFetchState === FETCH_STATE.ERROR;
    },
    zones() {
      return Array.from(this.zonesMap.values());
    },
    activeZones() {
      return this.zones.filter(zone => zone.enabled);
    },
    nextZoneSchedules() {
      return Array.from(this.nextZoneSchedulesMap.values());
    },
    nextContextZoneSchedules() {
      const contextStore = useContextStore();
      return this.nextZoneSchedules.filter(scheduleOccurrence => scheduleOccurrence.zone_id === contextStore.zoneId);
    },
    getZoneById() {
      return zoneId => this.zonesMap.get(zoneId);
    },
    getZoneScheduleByDevice() {
      return deviceId => this.zoneSchedulesMap.get(deviceId);
    }
  },
  actions: {
    async fetchZones(params) {
      try {
        if (this.zonesFetchState === FETCH_STATE.INITIAL) {
          this.zonesFetchState = FETCH_STATE.LOADING;
        }

        const zonesArr = await zonesService.fetchZones(params);
        this.zonesFetchState = FETCH_STATE.SUCCESS;
        zonesArr.forEach(zone => {
          const storedZone = this.zonesMap.get(zone.id);
          const mergedZone = { ...storedZone, ...zone };
          this.zonesMap.set(zone.id, mergedZone);
        });

        const contextStore = useContextStore();

        // If there is no zone context - select the first zone
        if (Object.keys(contextStore.zone).length === 0 && this.zones[0]) {
          contextStore.updateContextZone(this.zones[0].id);
        }

        return this.zones;
      } catch (e) {
        this.zonesFetchState = FETCH_STATE.ERROR;
        return Promise.reject(e);
      }
    },
    async fetchZoneById(zoneId, params) {
      try {
        let data = await zonesService.fetchZoneById(zoneId, params);
        if (data) {
          const storedZone = this.zonesMap.get(zoneId);
          data = { ...storedZone, ...data };
          this.zonesMap.set(data.id, data);
        }
        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async createZone(zoneObj) {
      try {
        const data = await zonesService.createZone(zoneObj);
        this.zonesMap.set(data.id, data);
        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    setZone(zone) {
      this.zonesMap.set(zone.id, zone);
    },
    async updateZone({ zoneChangedFields, zoneId }) {
      try {
        const data = await zonesService.updateZone(zoneChangedFields, zoneId);
        this.zonesMap.set(data.id, data);
        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchZoneSchedules(deviceId) {
      try {
        const contextStore = useContextStore();
        const initialContextZoneId = contextStore.zoneId;
        this.zoneSchedulesFetchState = FETCH_STATE.LOADING;
        const data = await zonesService.fetchZoneSchedules({ deviceId });
        if (data.length === 0) {
          this.zoneSchedulesFetchState = FETCH_STATE.SUCCESS;
          return [];
        }
        // Make sure that the context zone was not switched by now
        if (initialContextZoneId === contextStore.zoneId) {
          const latestRevision = data.sort((a, b) => b.revision - a.revision)[0];
          this.zoneSchedulesMap.set(latestRevision.device_id, latestRevision);
          this.zoneSchedulesFetchState = FETCH_STATE.SUCCESS;
        }
        return data;
      } catch (e) {
        this.zoneSchedulesFetchState = FETCH_STATE.ERROR;
        return Promise.reject(e);
      }
    },
    setZoneSchedules(schedules) {
      const contextStore = useContextStore();
      schedules.filter(schedule => schedule.zone_id === contextStore.zoneId);
      const latestRevision = schedules.sort((a, b) => b.revision - a.revision)[0];
      this.zoneSchedulesMap.set(latestRevision.device_id, latestRevision);
    },
    async saveZoneSchedules(schedule) {
      try {
        const zoneSchedule = await zonesService.saveZoneSchedule(schedule);
        const contextStore = useContextStore();
        // Make sure that the zone was not switched by now
        if (Number(zoneSchedule.zone_id) === contextStore.zoneId) {
          this.zoneSchedulesMap.set(zoneSchedule.device_id, zoneSchedule.data);
        }
        return zoneSchedule;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchNextSchedules(amount) {
      try {
        this.nextZoneSchedulesFetchState = FETCH_STATE.LOADING;
        const data = await zonesService.fetchZoneNextSchedules(amount);
        this.nextZoneSchedulesMap = data.reduce((map, nextSchedule) => {
          const nextSchedulesArr = map.get(nextSchedule.zone_id) || [];
          nextSchedulesArr.push(nextSchedule);
          map.set(nextSchedule.zone_id, nextSchedulesArr);
          return map;
        }, new Map());

        this.nextZoneSchedulesFetchState = FETCH_STATE.SUCCESS;
        return data;
      } catch (e) {
        this.nextZoneSchedulesFetchState = FETCH_STATE.ERROR;
        return Promise.reject(e);
      }
    },
    setNextZoneSchedule(nextSchedulesSet) {
      this.nextZoneSchedulesMap.set(nextSchedulesSet.zone_id, nextSchedulesSet);
    }
  }
});
