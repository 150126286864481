<template>
  <div class="table-container">
    <div v-if="Array.isArray(tableObject)" class="list">
      <span v-for="(item, index) in tableObject" :key="`item-${index}-${Math.random()}`" class="value table-cell">
        <base-horizontal-object-table v-if="isValueObjectOrArray(item)" :table-object="item" />
        <div v-else class="simple-value">
          {{ formatValue(item) }}
        </div>
      </span>
    </div>
    <div v-else class="object-grid">
      <template v-for="field in Object.keys(tableObject)" :key="`${field}-key`">
        <div class="key table-cell">
          {{ formatKey(field) }}
        </div>
        <div class="value table-cell">
          <base-horizontal-object-table v-if="isValueObjectOrArray(tableObject[field])" :table-object="tableObject[field]" />
          <div v-else class="simple-value">
            {{ formatValue(tableObject[field]) }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseHorizontalObjectTable',
  props: {
    tableObject: {
      type: [Object, Array],
      required: true
    }
  },
  methods: {
    isValueObjectOrArray(val) {
      return val && typeof val === 'object';
    },
    formatValue(val) {
      return val;
    },
    formatKey(key) {
      return key.replace(/_|-/g, ' ');
    }
  }
};
</script>

<style lang="scss" scoped>
.table-container {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  width: 100%;

  .list {
    display: flex;
    flex-direction: column;
    row-gap: 2px;

    > :nth-child(n + 2) {
      padding-top: 2px;
    }
  }

  .object-grid {
    row-gap: 2px;
    display: grid;
    grid-template-columns: fit-content(15rem) 5fr;
    text-align: left;
    column-gap: 2px;
    width: 100%;

    .table-cell {
      background: var(--secondaryOverlayShade1);
      line-height: 1.7rem;
      display: flex;
      align-items: center;

      .simple-value {
        padding: 0 1rem;
      }
    }

    .key {
      font-weight: bold;
      color: var(--titleColor);
      justify-content: flex-end;
      padding: 0 1rem;
      text-transform: capitalize;
    }
  }
}
</style>
