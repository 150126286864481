import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 150 100",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", { transform: "translate(-275.85 -448.56)" }, [
      _createElementVNode("g", { transform: "matrix(.83811 -.54550 .54550 .83811 -480.06 564.29)" }, [
        _createElementVNode("path", {
          style: 
          `stroke-linejoin:round;stroke-dashoffset:3.003;stroke-linecap:round;stroke-width:7;fill-opacity:0.5`
        ,
          d: "m678.74 400.93c0.10517 4.0988-3.2098 8.0808-7.4505 8.5635-3.4203 0.45829-6.9957-1.3599-8.5704-4.3472-1.8452-3.3018-1.2898-7.7503 1.5004-10.399 2.2656-2.2502 5.9189-3.1129 8.946-1.9092 3.1727 1.1834 5.4303 4.2873 5.5602 7.5844 0.01 0.16918 0.0145 0.33863 0.0143 0.50807zm8.4712-38.793c-9.7257 0.0685-19.447 4.2067-26.286 11.375-12.251 12.275-14.681 32.888-6.5791 48.188 6.9142 13.842 22.598 22.249 37.617 20.125 13.315-1.5991 24.544-11.487 29.697-23.938l9.5336 5.4688c2.7914 1.7417 4.3561-0.44379 4.3556-3l-0.18276-10.219 10.843 0.25 7.371 6.9375 9.5945-9.715 8.7721 8.3712h6.4877l8.9854-9 6.505 5.4986 8.2782-0.34752 7.1817-6.9025 7.7325-7.1236c-1.3116-4.3685-5.0487-7.0697-11.909-10.086l-70.36 0.55495-0.42642-7.7812c-0.0335-2.7765-3.3696-3.9446-5.5435-2.5l-7.9193 6.4688c-4.169-9.0407-11.571-16.515-20.742-20.125-4.1536-1.7429-8.5851-2.5311-13.006-2.5z"
        })
      ])
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }