<template>
  <BaseModal
    class="active-nodes-modal"
    :confirmation-option="{ text: 'Kill node', disabled: !selectedNodeName }"
    cancel-option
    @close="onCloseClick"
    @confirm="onConfirmClick()"
  >
    <template #title>
      <span> Device active nodes</span>
    </template>
    <template #body>
      <div class="body-text">
        <span class="message">Which node would you like to kill: </span>
        <div v-if="activeNodes" class="attributes-container">
          <div class="attribute-container">
            <BaseDropdown v-if="!isLoading && !showErrorMessage" v-model="selectedNodeName" :options="activeNodes" />
            <BaseLoader v-if="isLoading && !showErrorMessage" class="loader"></BaseLoader>
            <div v-if="showErrorMessage" class="error-message-container">Failed to fetch device active nodes, please try again</div>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '../../components/base/BaseModal.vue';
import BaseDropdown from '../../components/base/BaseDropdown.vue';
import BaseLoader from '../../components/base/BaseLoader.vue';
import devicesService from '../../services/api/devicesService';
import { useDevicesStore } from '../../store/DevicesStore';
import { mapActions, mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import { SNACKBAR_TYPE } from '../../consts/appConsts';

export default {
  name: 'DeviceActiveNodesModal',
  components: {
    BaseModal,
    BaseDropdown,
    BaseLoader
  },
  props: {
    device: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  data() {
    return {
      fetchDeviceActiveNodesTimeout: null,
      showErrorMessage: false,
      isLoading: false,
      selectedNodeName: ''
    };
  },
  computed: {
    ...mapStores(useContextStore),
    deviceActiveNodes() {
      return this.device.activeNodeList;
    },
    activeNodes() {
      const options = [];
      if (this.deviceActiveNodes?.length > 0) {
        clearTimeout(this.fetchDeviceActiveNodesTimeout);
        this.deviceActiveNodes.forEach(node => {
          options.push({ id: node, label: node });
        });
      }
      return options;
    }
  },
  watch: {
    deviceActiveNodes(newVal) {
      if (newVal.length > 0) {
        this.selectedNodeName = newVal[0];
        this.isLoading = false;
      }
    }
  },
  async created() {
    this.isLoading = true;
    await devicesService.fetchDeviceActiveNodes(this.device.id);
    this.fetchDeviceActiveNodesTimeout = setTimeout(() => {
      this.showErrorMessage = true;
      this.isLoading = false;
    }, 10000);
  },
  beforeUnmount() {
    clearTimeout(this.fetchDeviceActiveNodesTimeout);
    this.clearDeviceActiveNodeList(this.device.id);
  },
  methods: {
    ...mapActions(useDevicesStore, ['clearDeviceActiveNodeList']),
    onCloseClick() {
      clearTimeout(this.fetchDeviceActiveNodesTimeout);
      this.$emit('close');
    },
    async onConfirmClick() {
      try {
        const response = await devicesService.killDeviceActiveNode({
          deviceId: this.device.id,
          nodeName: this.selectedNodeName
        });
        this.contextStore.showSnackbar({
          message: response.data.message,
          type: SNACKBAR_TYPE.SUCCESS
        });
      } catch (e) {
        this.contextStore.showSnackbar({
          message: e.message,
          type: SNACKBAR_TYPE.ERROR
        });
      }
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">
.active-nodes-modal {
  .body-text {
    width: 380px;
    text-align: center;
    margin: 1rem auto auto auto;
    overflow: hidden;

    .message {
      display: inline-block;
      font-size: 18px;
      font-family: var(--font-family-secondary);
      font-weight: lighter;
      white-space: pre-line;
    }

    .attributes-container {
      margin-top: 20px;
      width: 100%;
      text-align: left;
    }
  }

  .loader {
    width: 30px;
    height: 30px;
    margin: auto;
  }

  .error-message-container {
    margin: 25px 0;
    font-size: 14px;
    text-align: center;
    color: var(--errorColor);
  }
}
</style>
