<template>
  <div>
    <VideoPlayer v-if="singleCamDevice" ref="singlePlayer" type="front" :publish-frame="true" @loaded="isOnePlayerReady = true" />
    <section v-else class="video-section">
      <BaseOptionsSwitch v-if="allowLayoutSwitching" v-model="selectedLayout" :options="videoManagerOptions" title="Video players layout" />
      <component
        :is="selectedVideoManager"
        ref="VideoManager"
        v-bind="$props"
        @one-player-ready="isOnePlayerReady = true"
        @stream-stop="resetLayout"
        @is-streams-exist="$emit('is-streams-exist', $event)"
        @close="onCloseClick"
      />
    </section>
    <div v-if="showObjectDetectionIndication" class="object-detection-enabled-indication">
      <IconScreenshot class="screenshot-icon" />
      {{ aiStatusLabel }}
      <BaseButton v-if="showRefreshButton" title="Refresh" @click="refreshPage">Refresh</BaseButton>
    </div>
  </div>
</template>

<script>
import VideoManager from './VideoManager.vue';
import SplitVideoManager from './SplitVideoManager.vue';
import VideoPlayer from './VideoPlayer.vue';
import BaseOptionsSwitch from '../base/BaseOptionsSwitch.vue';
import IconScreenshot from '../icons/IconScreenshot.svg?component';
import BaseButton from '../base/BaseButton.vue';
import { mapState, mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import { useVideoPlayerStore } from '../../store/VideoPlayerStore';
import { PRIVILEGES } from '../../consts/authConsts';
import { FISHEYE_MIN_ZOOM_CORRECTION } from '../../consts/appConsts';

const AI_STATUS_LABELS = {
  IN_PROGRESS: 'Analyzing Object detection...',
  FINISHED: 'Object detection is active'
};
const VIDEO_LAYOUTS = {
  TABS: {
    label: 'Tabs',
    component: VideoManager
  },
  SPLIT: {
    label: '360°',
    component: SplitVideoManager
  }
};
const videoManagerOptions = Object.keys(VIDEO_LAYOUTS).map(layoutKey => ({
  id: layoutKey,
  label: VIDEO_LAYOUTS[layoutKey].label
}));

const DEFAULT_LAYOUT = videoManagerOptions[0].id; // TABS

export default {
  name: 'VideoSection',
  components: {
    VideoManager,
    SplitVideoManager,
    BaseOptionsSwitch,
    VideoPlayer,
    IconScreenshot,
    BaseButton
  },
  props: {
    singleCamDevice: {
      type: Boolean,
      default: false
    },
    videoDownloadPath: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    thermalVideoDelay: {
      type: Number,
      default: 0
    },
    aiStatus: {
      type: String,
      default: ''
    }
  },
  emits: ['is-streams-exist'],
  data() {
    return {
      videoManagerOptions,
      selectedLayout: DEFAULT_LAYOUT,
      isOnePlayerReady: false,
      showRefreshButton: false
    };
  },
  computed: {
    ...mapStores(useVideoPlayerStore),
    ...mapState(useContextStore, ['isTechnician', 'hasPrivilege']),
    aiStatusLabel() {
      if (this.aiStatus === 'FAILED' && this.hasPrivilege(PRIVILEGES.VIEW_ERROR_DESCRIPTION)) {
        return 'Object detection failed';
      }
      return AI_STATUS_LABELS[this.aiStatus];
    },
    selectedVideoManager() {
      return VIDEO_LAYOUTS[this.selectedLayout].component;
    },
    allowLayoutSwitching() {
      return this.isTechnician && !this.hasError && !this.singleCamDevice && this.videoPlayerStore.front.src && this.videoPlayerStore.rear.src;
    },
    showObjectDetectionIndication() {
      return this.aiStatusLabel && this.isOnePlayerReady;
    }
  },
  watch: {
    allowLayoutSwitching: {
      handler: function videosHasErrorChanged(newVal) {
        if (!newVal && this.selectedLayout !== DEFAULT_LAYOUT) {
          this.resetLayout();
        }
      }
    },
    aiStatus(newVal, oldVal) {
      this.showRefreshButton = oldVal === 'IN_PROGRESS' && newVal === 'FINISHED';
    },
    selectedLayout: function selectedVideoLayoutChanged(newVal) {
      this.videoPlayerStore.setDefaultZoom(newVal === 'TABS' ? FISHEYE_MIN_ZOOM_CORRECTION : 1);
    }
  },
  beforeUnmount() {
    this.videoPlayerStore.$reset();
  },
  methods: {
    refreshPage() {
      this.$router.go();
    },
    resetLayout() {
      this.selectedLayout = DEFAULT_LAYOUT; // Reset to default layout: TABS
    },
    onCloseClick() {
      this.resetLayout();
    },
    getMainPlayerElement() {
      if (this.singleCamDevice) {
        return this.$refs.singlePlayer.player.el().getElementsByTagName('video')[0];
      } else {
        return this.$refs.VideoManager.activePlayersGroupMainVideoElement;
      }
    },
    getMainPlayerName() {
      return this.singleCamDevice ? 'front' : this.$refs.VideoManager.activePlayer;
    },
    pauseVideos() {
      if (this.singleCamDevice) {
        this.$refs.singlePlayer.player.pause();
      } else {
        this.$refs.VideoManager.pauseVideos();
      }
    },
    addMarker(data) {
      if (this.singleCamDevice) {
        this.$refs.singlePlayer.addMarker(data);
      } else {
        this.$refs.VideoManager.addMarker(data);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.video-section {
  display: grid;
  row-gap: 0.5rem;
}

.object-detection-enabled-indication {
  text-transform: capitalize;
  color: var(--highlightColor);
  margin-top: 10px;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;

  .screenshot-icon {
    width: 1rem;
    height: 1rem;
  }
}
</style>
