import videojs from 'video.js';
const Button = videojs.getComponent('Button');

export class CustomControlButton extends Button {
  constructor(player, options) {
    super(player, options);
    this.controlText(options.text || '');
    this.contentEl().appendChild(options.contentElement);
  }
}

videojs.registerComponent('CustomControlButton', CustomControlButton);
