<template>
  <TransitionGroup name="snackbar" tag="div" class="base-snackbar">
    <div
      v-for="[messageId, messageConfig] in contextStore.snackbarMessages.entries()"
      :key="messageId"
      :class="['snackbar-message', messageConfig.type]"
    >
      <span>{{ messageConfig.message }}</span>
      <div v-if="messageConfig.undoOption" class="action-button undo" @click="$emit('undo')">Undo</div>
      <IconClose class="action-button close" @click="closeSnackbar(messageId)"><title>Close</title></IconClose>
    </div>
  </TransitionGroup>
</template>

<script>
import { useContextStore } from '../../store/ContextStore';
import { mapStores } from 'pinia';
import IconClose from '../../components/icons/IconClose.svg?component';

export default {
  name: 'BaseSnackbar',
  components: { IconClose },
  emits: ['undo'],
  computed: {
    ...mapStores(useContextStore)
  },
  methods: {
    closeSnackbar(messageId) {
      this.contextStore.snackbarMessages.delete(messageId);
    }
  }
};
</script>

<style lang="scss" scoped>
.base-snackbar {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  position: fixed;
  bottom: 0;
  left: 1.5rem;
  z-index: 4;
  width: 30rem;
  margin: 2rem;
  color: var(--textColor);
  transition: 0.5s;

  .snackbar-enter-active,
  .snackbar-leave-active {
    transition: all 0.4s ease;
  }

  .snackbar-enter-from,
  .snackbar-leave-to {
    opacity: 0;
    transform: translateX(-2rem);
  }

  .snackbar-message {
    display: flex;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 2px 1px var(--shadowColor);
    border: 0.3rem solid;
    column-gap: 1rem;
    border-color: var(--secondaryOverlayShade2);
    border-radius: 6px;

    &:active {
      filter: opacity(0.5);
    }

    &.success {
      background: var(--successColor);
    }

    &.error {
      background: var(--errorColor);
    }

    &.info {
      background: var(--infoColor);
    }

    .action-button {
      color: var(--secondaryTextColor);
      cursor: pointer;
      flex-shrink: 0;

      &:hover {
        filter: brightness(1.3);
      }

      &.undo {
        background: var(--overlayShade3);
        padding: 8px;
        border-radius: 7px;
      }

      &.close {
        width: 1rem;
        aspect-ratio: 1/1;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}
</style>
