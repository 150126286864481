import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", { transform: "translate(-1220 -1887) translate(1220 1887)" }, [
      _createElementVNode("circle", {
        cx: "12",
        cy: "12",
        r: "12",
        fill: "#F24535"
      }),
      _createElementVNode("g", { fill: "#FFF" }, [
        _createElementVNode("path", {
          d: "M8 1c1.24 0 2.25.92 2.25 2.05v5.294C11.336 9.02 12 10.15 12 11.356 12 13.366 10.206 15 8 15s-4-1.635-4-3.644c0-1.206.664-2.337 1.75-3.012V3.05C5.75 1.92 6.76 1 8 1zm0 .911c-.69 0-1.25.51-1.25 1.139v5.808l-.25.132c-.925.488-1.5 1.394-1.5 2.366 0 1.507 1.346 2.733 3 2.733s3-1.226 3-2.733c0-.972-.575-1.878-1.5-2.366l-.25-.132V3.05c0-.628-.56-1.139-1.25-1.139zm0 3.19c.276 0 .5.204.5.456v3.695l.624.329c.694.366 1.126 1.046 1.126 1.775 0 1.13-1.01 2.05-2.25 2.05s-2.25-.92-2.25-2.05c0-.729.431-1.409 1.126-1.775l.624-.329V5.557c0-.252.224-.456.5-.456z",
          transform: "translate(4 4)"
        })
      ])
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }