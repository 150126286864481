<template>
  <div class="map-toolbox">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MapToolbox'
};
</script>

<style scoped lang="scss">
.map-toolbox {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  background: var(--secondaryColor);
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  padding: 1rem 0.7rem;
  border: 2px solid var(--secondaryColorShade1);
  border-left: 0;
  width: 3rem;
  position: absolute;
  top: 3rem;
}
</style>
