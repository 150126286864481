const ErrorsDictionary = {
  1: 'Error',
  1000: 'Error planning mission',
  1001: 'Error connecting to tile',
  1002: 'Error planning mission',
  1003: 'Error planning mission',
  1004: 'Tando is already docked on this Tile',
  1200: 'Navigation error',
  1201: 'Navigation error',
  1202: 'Navigation error',
  1300: 'Tile is not available',
  1301: 'Dock Error',
  1302: 'Destination Tile Error',
  1400: 'Navigation error',
  1500: 'Detach error - Mission canceled',
  1501: 'Detach error',
  1502: 'Detach error',
  1503: 'Detach error',
  1504: 'Detach error',
  1505: 'Detach error',
  1506: 'Detach error',
  1507: 'Detach error',
  1508: 'Detach error',
  1600: 'Dock error',
  1601: 'Dock error',
  1602: 'Dock error',
  1700: 'Error',
  1800: 'Charging error',
  1801: 'Charging error',
  1802: 'Charging error',
  1900: 'Charging error',
  1901: 'Charging error',
  1902: 'Charging error',
  2000: 'Pre-flight error',
  2001: 'Pre-flight error',
  2002: 'Pre-flight error',
  2003: 'Pre-flight error',
  2004: 'Pre-flight error',
  2005: 'Pre-flight error',
  2006: 'Pre-flight error',
  2007: 'Pre-flight error',
  2100: 'Post-flight error',
  2101: 'Post-flight error',
  2102: 'Post-flight error',
  2103: 'Post-flight error',
  2104: 'Post-flight error',
  2105: 'Post-flight error',
  2106: 'Post-flight error',
  2107: 'Post-flight error',
  2108: 'Post-flight error',
  2109: 'Post-flight error',
  2110: 'Post-flight error',
  2111: 'Post-flight error',
  2112: 'Post-flight error',
  2200: 'Mission error',
  2201: 'Mission error',
  2202: 'Mission error',
  2500: 'Mission initiation error',
  2501: 'Error executing a scheduled mission',
  2502: 'IOT Error',
  2503: 'Error executing a scheduled mission',
  2504: 'Mission Error',
  10000: 'System Error',
  10001: 'System Error',
  10002: 'System Error',
  10003: 'System Error',
  10100: 'Sensor error',
  10101: 'Sensor error',
  10102: 'Sensor error',
  10103: 'Sensor error',
  10200: 'Low voltage error',
  10201: 'Low voltage error',
  10202: 'Charging error',
  10203: 'Sensor error',
  10204: 'Sensor error',
  10300: 'Connectivity Error',
  10301: 'Connectivity Error',
  10302: 'Connectivity Error',
  10303: 'Connectivity Error',
  10400: 'Tando System Error',
  10401: 'Tando System Error',
  10402: 'Tando System Error',
  10403: 'Tando System Error',
  10404: 'Tando System Error',
  10405: 'Tando System Error',
  10406: 'Tando System Error',
  10500: 'Tando System Error',
  10501: 'Tando System Error',
  10502: 'Tando System Error',
  10503: 'Tando System Error',
  10600: 'Tando System Error',
  10601: 'Tando System Error',
  10602: 'Tando System Error',
  10603: 'Tando System Error',
  10604: 'Tando System Error',
  10605: 'Tando System Error',
  10606: 'Tando System Error',
  10607: 'Tando System Error',
  10608: 'Tando System Error',
  10609: 'Tando System Error',
  10610: 'Tando System Error',
  10611: 'Tando System Error',
  10612: 'Tando System Error',
  10613: 'Tando is blocked',
  10614: 'Tando is blocked',
  10700: 'Tando System Error',
  10701: 'Tando System Error',
  10702: 'Tando System Error',
  10703: 'Tando System Error',
  10705: 'Tando System Error',
  10706: 'Tando System Error',
  10707: 'Tando System Error',
  10708: 'Navigation Error',
  10709: 'Navigation Error',
  10710: 'Navigation Error',
  10800: 'Tando System Error',
  10801: 'Tando System Error',
  10802: 'Tando System Error',
  10803: 'Tando System Error',
  10804: 'Tando System Error',
  10805: 'Tando System Error',
  10806: 'Tando System Error',
  10807: 'Tando System Error',
  10808: 'Tando System Error',
  10809: 'Tando System Error',
  10810: 'Tando System Error',
  10811: 'Tando System Error',
  10812: 'Tando System Error',
  10813: 'Tando System Error',
  10900: 'Tando System Error',
  10901: 'Tando System Error',
  10902: 'Tando System Error',
  10903: 'Tando System Error',
  10904: 'Tando System Error',
  10905: 'Tando System Error',
  11000: 'Tando System Error',
  11001: 'Tando System Error',
  11002: 'Tando System Error',
  11003: 'Tando System Error',
  11004: 'Tando System Error',
  11005: 'Tando System Error',
  11006: 'Tando System Error',
  11007: 'Tando System Error',
  11008: 'Clearance Error',
  11009: 'Tampering Error',
  11010: 'Mission Error',
  11011: 'Tando System Error',
  11100: 'Tando System Error',
  11101: 'Tando System Error',
  11200: 'Tando System Error',
  11201: 'Tando System Error',
  11202: 'Tando System Error',
  11203: 'Tando System Error',
  11204: 'Tando System Error',
  11205: 'Tando System Error',
  11206: 'Tando System Error',
  11207: 'Tando System Error',
  11208: 'Tando System Error',
  11209: 'Tando System Error',
  11210: 'Tando System Error',
  11211: 'Emergency Land',
  11212: 'Tando System Error',
  11213: 'Tando System Error',
  11214: 'Tando System Error',
  11215: 'Tando System Error',
  11300: 'Tando System Error',
  11301: 'Tando System Error',
  11302: 'Tando System Error',
  11303: 'Tando System Error',
  20000: 'Stopped by user',
  2508: 'Unable to retrieve mission status due to a communication issue',
  401: 'You do not have sufficient rights to perform this action'
};

export const getErrorMessage = errorCode => ErrorsDictionary[errorCode] || '';
