import {
  drawDotIndicator as _drawDotIndicator,
  drawIconIndicator as _drawIconIndicator,
  drawLocation as _drawLocation,
  drawPath as _drawPath,
  drawXMark as _drawXMark,
  drawArrow as _drawArrow,
  drawNote as _drawNote,
  drawLandmark as _drawLandmark,
  drawSelectionMark as _drawSelectionMark,
  drawBadge as _drawBadge,
  BADGE_POSITIONS
} from '../../utils/CanvasUtils';
import { getStyleVar } from '../../utils/StyleUtils';

class MapCanvasManager {
  constructor(canvas, ctx, radius, fontSize) {
    this.canvas = canvas;
    this.ctx = ctx;
    this.radius = radius;
    this.fontSize = fontSize;
    this.stripesFillPattern = null;
    this.landmarkToRadiusRatio = 0.8;
    this.loadStyle();
    this.trackTransforms();
  }

  loadStyle() {
    this._style = {
      chargeMark: {
        bgColor: 'green',
        markColor: 'white'
      },
      warningMark: {
        bgColor: getStyleVar('--errorColor'),
        markColor: 'white'
      },
      fontSize: this.fontSize,
      fontFamily: getStyleVar('--font-family-secondary'),
      iconIndicator: {
        mainColor: getStyleVar('--secondaryTextColor'),
        bgColor: 'transparent',
        textColor: getStyleVar('--textColor')
      },
      path: {
        startPointColor: getStyleVar('--textColor'),
        startPointRadius: this.radius / 4,
        endPointColor: 'white',
        endPointRadius: this.radius / 4,
        lineColor: getStyleVar('--textColor'),
        lineWidth: 0.1 * this.radius
      }
    };
  }

  getStripesFillPattern() {
    if (!this.stripesFillPattern) {
      const utilCanvas = document.createElement('canvas');
      const utilCtx = utilCanvas.getContext('2d');
      const size = 30;
      utilCanvas.width = size;
      utilCanvas.height = size;
      const lineWidth = 10;
      const offset = (lineWidth * (lineWidth + size)) / 100;
      const pointsSet = [
        {
          from: { x: size / 4 - offset, y: -offset },
          to: { x: -offset, y: size / 4 - offset }
        },
        { from: { x: 0, y: size }, to: { x: size, y: 0 } },
        {
          from: { x: (3 * size) / 4 + offset, y: size + offset },
          to: { x: size + offset, y: (3 * size) / 4 + offset }
        }
      ];

      utilCtx.strokeStyle = getStyleVar('--secondaryOverlayShade1');
      utilCtx.lineWidth = lineWidth;
      utilCtx.beginPath();
      pointsSet.forEach(set => {
        utilCtx.moveTo(set.from.x, set.from.y);
        utilCtx.lineTo(set.to.x, set.to.y);
      });
      utilCtx.stroke();
      utilCtx.closePath();
      this.stripesFillPattern = utilCanvas;
    }
    return this.stripesFillPattern;
  }

  drawIconIndicator(options, mapZoom) {
    _drawIconIndicator({
      ctx: this.ctx,
      radius: this.radius,
      mapZoom,
      ...options,
      style: {
        ...this._style.iconIndicator,
        warningMark: this._style.warningMark,
        chargeMark: this._style.chargeMark,
        textColor: options?.style?.textColor || this._style.iconIndicator.textColor,
        textBackground: getStyleVar('--secondaryColorShade1'),
        fontSize: this._style.fontSize,
        fontFamily: this._style.fontFamily
      },
      showWarningMark: options?.customData?.isSystemError,
      showChargeMark: options?.customData?.isCharging,
      showSelectionMark: options.showSelectionMark
    });
    if (options.customData && typeof options.customData.heading !== 'undefined' && !options.isTile) {
      this.drawIconIndicatorHeading(options, mapZoom);
    }
  }

  drawIconIndicatorHeading(options, mapZoom) {
    const heading = options.customData.heading;
    const scaledRadius = this.radius * (1 / mapZoom);
    const headingRadius = 0.2 * scaledRadius;
    let headingX;
    let headingY;

    if (heading >= -45 && heading <= 45) {
      // Right border
      headingX = options.x + scaledRadius;
      headingY = options.y - (scaledRadius * heading) / 45;
    } else if (heading > 45 && heading <= 135) {
      // Top border
      headingX = options.x - (scaledRadius * (heading - 90)) / 45;
      headingY = options.y - scaledRadius;
    } else if (heading >= -135 && heading < -45) {
      // Bottom border
      headingX = options.x + (scaledRadius * (heading + 90)) / 45;
      headingY = options.y + scaledRadius;
    } else if (heading > 135) {
      // Top left border
      headingX = options.x - scaledRadius;
      headingY = options.y + (scaledRadius * (heading - 180)) / 45;
    } else if (heading < -135) {
      // Bottom left border
      headingX = options.x - scaledRadius;
      headingY = options.y + (scaledRadius * (heading + 180)) / 45;
    }
    if (headingX !== undefined && headingY !== undefined) {
      this.ctx.beginPath();
      this.ctx.arc(headingX, headingY, headingRadius, 0, 2 * Math.PI);
      this.ctx.strokeStyle = 'white';
      this.ctx.lineWidth = 0.15 * scaledRadius;
      this.ctx.fillStyle = 'black';
      this.ctx.fill();
      this.ctx.stroke();
      this.ctx.closePath();
    }
  }

  drawArrow({ from, to, mapZoom }) {
    _drawArrow({
      ctx: this.ctx,
      radius: this.radius,
      mapZoom,
      from,
      to,
      color: getStyleVar('--highlightColor')
    });
  }

  /**
   * Draws Polygon Area
   * @param options {Object} - Configuration for drawing polygon
   * @param options.points {Array} - Collection of {x, y} vertices
   * @param options.color {String} - Color for the Polygon outline
   * @param options.active {Boolean} - Should the polygon be highlighted
   * @param options.enabled {Boolean} - Should the polygon be partially transparent due to inactive definition
   * @param options.isInBackground {Boolean} - Area is in the background of other entities (avoid pattern + add opacity)
   * @param mapZoom {number} - current map zoom level
   * @param selectVertexMode {Boolean} - Whether to draw vertices indicator
   * @param selectableList {Array} - A collection of indexes within the  provided @param options.points,
   *                       indicates which vertices are selectable.
   *                       Empty Array with @param selectVertexMode set to true means that all vertices area selectable
   */
  drawAreaPolygon({ points, color, active, enabled, isInBackground }, mapZoom, selectVertexMode, selectableList) {
    const scaledRadius = this.radius * (1 / mapZoom);
    this.ctx.save();
    this.ctx.lineCap = 'round';
    if (points.length > 1) {
      if (!enabled || isInBackground) {
        this.ctx.globalAlpha = 0.3;
      }

      this.ctx.beginPath();
      this.ctx.strokeStyle = color;
      this.ctx.lineWidth = 2;
      this.ctx.moveTo(points[0].x, points[0].y);
      for (let index = 1; index < points.length; index++) {
        this.ctx.lineTo(points[index].x, points[index].y);
      }
      this.ctx.lineTo(points[0].x, points[0].y);
      this.ctx.fillStyle = active ? getStyleVar('--secondaryOverlayShade2') : getStyleVar('--secondaryOverlayShade1');
      this.ctx.fill();
      if (!isInBackground) {
        this.ctx.fillStyle = this.ctx.createPattern(this.getStripesFillPattern(), 'repeat');
        this.ctx.fill();
      }
      this.ctx.stroke();
      this.ctx.closePath();
    }

    if (selectVertexMode) {
      points.slice(0, -1).forEach((point, index) => {
        if (!selectableList || selectableList.includes(index)) {
          this.ctx.beginPath();
          this.ctx.fillStyle = point.hover ? getStyleVar('--successColor') : getStyleVar('--secondaryColor');
          this.ctx.arc(point.x, point.y, scaledRadius / 1.8, 0, 2 * Math.PI);
          this.ctx.fill();

          if (!point.hover) {
            this.ctx.strokeStyle = color;
            this.ctx.stroke();
          }
          this.ctx.closePath();

          if (point.hover) {
            this.ctx.beginPath();
            this.ctx.fillStyle = 'white';
            const p = new Path2D(
              `m-1.46.495c1.4875 1.3175.935 1.275 3.145-1.53c.085-.1275.085-.2125-.395-.145l-1.28 1.68c-.33.43-.45.34-1.03-.18c-.355.0475-.61.005-.44.175`
            );
            const scaledPath = new Path2D();
            const transformationMatrix = new DOMMatrixReadOnly().translate(point.x, point.y).scale(5 / mapZoom, 5 / mapZoom);
            scaledPath.addPath(p, transformationMatrix);
            this.ctx.fill(scaledPath);
          }
        }
      });
    }
    this.ctx.restore();
  }

  drawAreaRect({ points: [from, to], color, active, enabled, isInBackground }) {
    this.ctx.save();

    if (!enabled || isInBackground) {
      this.ctx.globalAlpha = 0.3;
    }
    this.ctx.beginPath();
    this.ctx.rect(from.x, from.y, to.x - from.x, to.y - from.y);
    this.ctx.strokeStyle = color;
    this.ctx.lineWidth = 1;
    this.ctx.fillStyle = active ? getStyleVar('--secondaryOverlayShade2') : getStyleVar('--secondaryOverlayShade1');
    this.ctx.fill();
    if (!isInBackground) {
      this.ctx.fillStyle = this.ctx.createPattern(this.getStripesFillPattern(), 'repeat');
      this.ctx.fill();
    }
    this.ctx.stroke();
    this.ctx.closePath();
    this.ctx.restore();
  }

  drawDotIndicator(options, mapZoom) {
    _drawDotIndicator({
      ctx: this.ctx,
      radius: this.radius,
      mapZoom,
      ...options,
      style: {
        warningMark: this._style.warningMark,
        chargeMark: this._style.chargeMark,
        textColor: options?.style?.textColor || getStyleVar('--textColor'),
        fontSize: this._style.fontSize,
        fontFamily: this._style.fontFamily
      },
      showWarningMark: options?.customData?.isSystemError,
      showChargeMark: options?.customData?.isCharging
    });
  }

  drawPath(options) {
    const { style } = options;
    _drawPath({
      ctx: this.ctx,
      ...options,
      style: {
        startPointColor: style?.startPointColor ?? getStyleVar('--secondaryOverlayShade3'),
        endPointColor: style?.endPointColor ?? getStyleVar('--secondaryOverlayShade3'),
        lineColor: style?.lineColor ?? getStyleVar('--secondaryOverlayShade3'),
        startPointRadius: (this.radius / 4) * (1 / options.mapZoom),
        endPointRadius: (this.radius / 4) * (1 / options.mapZoom),
        lineWidth: this.radius * 0.3 * (1 / options.mapZoom),
        opacity: 0.5
      }
    });
  }

  drawLocation(options) {
    if (options.showSelectionMark) {
      _drawSelectionMark(
        {
          ctx: this.ctx,
          radius: this.radius,
          ...options
        },
        'coral'
      );
    }

    _drawLocation({
      ctx: this.ctx,
      radius: this.radius,
      ...options,
      rangeColor: getStyleVar('--highlightColor')
    });

    if (options.badge) {
      _drawBadge({
        ctx: this.ctx,
        radius: this.radius,
        ...options,
        text: options.badge,
        positionId: BADGE_POSITIONS.BOTTOM_LEFT,
        style: {
          background: getStyleVar('--textColor'),
          stroke: getStyleVar('--secondaryColorShade3'),
          textColor: getStyleVar('--secondaryColorShade3'),
          fontSize: this.radius / 1.6
        }
      });
    }

    if (options.configBadge) {
      _drawBadge({
        ctx: this.ctx,
        radius: this.radius,
        ...options,
        positionId: BADGE_POSITIONS.TOP_LEFT,
        style: {
          background: getStyleVar('--infoColor'),
          stroke: getStyleVar('--primaryColorShade1'),
          textColor: getStyleVar('--disabledColor'),
          scale: 0.35
        }
      });
    }
  }

  drawXMark(options, mapZoom) {
    _drawXMark({
      ctx: this.ctx,
      radius: this.radius,
      mapZoom,
      ...options
    });
  }

  drawNote(options) {
    _drawNote({
      ctx: this.ctx,
      radius: this.radius,
      ...options,
      style: {
        textColor: 'lightgoldenrodyellow',
        fontSize: this.radius * 0.5,
        fontFamily: this._style.fontFamily
      },
      overlayXMark: options.hover
    });
  }

  drawLandmarkIcon(options) {
    _drawLandmark({
      ctx: this.ctx,
      radius: this.radius,
      ...options,
      overlayXMark: options.hover,
      landmarkToRadiusRatio: this.landmarkToRadiusRatio
    });
  }

  trackTransforms() {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    let xform = svg.createSVGMatrix();
    this.ctx.getTransform = function () {
      return xform;
    };

    const savedTransforms = [];
    const save = this.ctx.save;
    this.ctx.save = () => {
      savedTransforms.push(xform.translate(0, 0));
      return save.call(this.ctx);
    };

    const restore = this.ctx.restore;
    this.ctx.restore = () => {
      xform = savedTransforms.pop() || xform.translate(0, 0);
      return restore.call(this.ctx);
    };

    const scale = this.ctx.scale;
    this.ctx.scale = (sx, sy) => {
      xform = xform.scaleNonUniform ? xform.scaleNonUniform(sx, sy) : xform;
      return scale.call(this.ctx, sx, sy);
    };

    const rotate = this.ctx.rotate;
    this.ctx.rotate = radians => {
      xform = xform.rotate((radians * 180) / Math.PI);
      return rotate.call(this.ctx, radians);
    };

    const translate = this.ctx.translate;
    this.ctx.translate = (dx, dy) => {
      xform = xform.translate(dx, dy);
      return translate.call(this.ctx, dx, dy);
    };

    const transform = this.ctx.transform;
    this.ctx.transform = (a, b, c, d, e, f) => {
      let m2 = svg.createSVGMatrix();
      m2.a = a;
      m2.b = b;
      m2.c = c;
      m2.d = d;
      m2.e = e;
      m2.f = f;
      xform = xform.multiply(m2);
      return transform.call(this.ctx, a, b, c, d, e, f);
    };

    const setTransform = this.ctx.setTransform;
    this.ctx.setTransform = (a, b, c, d, e, f) => {
      const _a = Number(a);
      const _b = Number(b);
      const _c = Number(c);
      const _d = Number(d);
      const _e = Number(e);
      const _f = Number(f);
      xform.a = _a;
      xform.b = _b;
      xform.c = _c;
      xform.d = _d;
      xform.e = _e;
      xform.f = _f;
      return setTransform.call(this.ctx, new DOMMatrix([_a, _b, _c, _d, _e, _f]));
    };

    const pt = svg.createSVGPoint();
    this.ctx.transformedPoint = (x, y) => {
      pt.x = x;
      pt.y = y;
      return pt.matrixTransform(xform.inverse());
    };
  }

  convertCanvasToAbsoluteOverlayPoint(point, imageZoom) {
    const startGap = this.ctx.transformedPoint(0, 0);
    return {
      x: (point.x - startGap.x) * imageZoom,
      y: (point.y - startGap.y) * imageZoom
    };
  }

  convertScreenToCanvasPoint(point, imageZoom) {
    const imageStartGap = this.ctx.transformedPoint(0, 0);
    const canvasPositionInPage = this.canvas.getBoundingClientRect();
    const pointOnTopCanvas = {
      x: point.x - canvasPositionInPage.left,
      y: point.y - canvasPositionInPage.top
    };
    return {
      x: pointOnTopCanvas.x / imageZoom + imageStartGap.x,
      y: pointOnTopCanvas.y / imageZoom + imageStartGap.y
    };
  }

  initCanvas(callback) {
    const imageStartPoint = this.ctx.transformedPoint(0, 0);
    const imageEndPoint = this.ctx.transformedPoint(this.canvas.width, this.canvas.height);
    this.ctx.clearRect(imageStartPoint.x, imageStartPoint.y, imageEndPoint.x - imageStartPoint.x, imageEndPoint.y - imageStartPoint.y);

    this.ctx.save();
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.restore();
    callback();
  }
}

export { MapCanvasManager };
