<template>
  <BaseBoundaryEnforcer :position="position">
    <div class="map-quick-action-menu">
      <div class="title">Quick Action</div>
      <div v-for="action in actions" :key="action.text" class="action-button" @click.stop="action.callback">
        <component :is="action.icon" :class="['icon', { paddedIcon: action.padIcon }]" />
        <span>{{ action.text }}</span>
      </div>
    </div>
  </BaseBoundaryEnforcer>
</template>

<script>
import IconPoiInspection from '../../components/icons/IconPoiInspection.svg?component';
import IconPoiHighInspection from '../../components/icons/IconPoiHighInspection.svg?component';
import IconPoiLowInspection from '../../components/icons/IconPoiLowInspection.svg?component';
import IconFilter from '../../components/icons/IconFilter.svg?component';
import { mapStores } from 'pinia';
import { useEventListStore } from '../../store/EventListStore';
import { useContextStore } from '../../store/ContextStore';
import { MAP_QUICK_ACTION_TYPES } from '../../consts/mapConsts';
import BaseBoundaryEnforcer from '../../components/base/BaseBoundaryEnforcer.vue';
import { PAGES } from '../../router';

export default {
  name: 'MapQuickActionMenu',
  components: {
    IconPoiInspection,
    IconPoiHighInspection,
    IconPoiLowInspection,
    IconFilter,
    BaseBoundaryEnforcer
  },
  props: {
    position: {
      type: Object,
      required: true
    }
  },
  emits: ['mapQuickAction'],
  computed: {
    ...mapStores(useEventListStore, useContextStore),
    actions() {
      const actions = [
        {
          text: 'inspect',
          icon: IconPoiInspection,
          callback: () => this.$emit('mapQuickAction', MAP_QUICK_ACTION_TYPES.INSPECT),
          disabled: this.contextStore.zone?.halted
        },
        {
          text: 'inspect high',
          icon: IconPoiHighInspection,
          callback: () => this.$emit('mapQuickAction', MAP_QUICK_ACTION_TYPES.HIGH_INSPECTION),
          disabled: this.contextStore.zone?.halted
        },
        {
          text: 'inspect low',
          icon: IconPoiLowInspection,
          callback: () => this.$emit('mapQuickAction', MAP_QUICK_ACTION_TYPES.LOW_INSPECTION),
          disabled: this.contextStore.zone?.halted
        },
        {
          text: 'Filter by location',
          icon: IconFilter,
          padIcon: true,
          callback: () => this.$emit('mapQuickAction', MAP_QUICK_ACTION_TYPES.FILTER_BY_LOCATION),
          condition: this.$route.name === PAGES.EVENTS
        },

        {
          text: 'Clear filter by location',
          icon: IconFilter,
          padIcon: true,
          callback: () => this.$emit('mapQuickAction', MAP_QUICK_ACTION_TYPES.RESET_FILTER_BY_LOCATION),
          condition: this.$route.name === PAGES.EVENTS && this.eventListStore.isListFilteredByLocation
        }
      ];

      return actions.filter(item => item.condition !== false);
    }
  }
};
</script>

<style scoped lang="scss">
.map-quick-action-menu {
  border: 1px solid var(--primaryColorBrightShade1);
  background: var(--primaryColorDarkShade1);
  display: grid;
  row-gap: 2px;
  padding: 5px;
  user-select: none;

  .title {
    margin: auto;
    line-height: 2rem;
  }

  .action-button {
    background-color: var(--primaryColor);
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 8px 1.3rem 8px 0.7rem;
    text-transform: capitalize;
    cursor: pointer;
    font-family: var(--font-family-secondary);
    transition: background-color 0.4s cubic-bezier(0.49, 1.29, 0.79, 1.11);

    .icon {
      width: 2.2rem;
      height: 2.2rem;
      flex-shrink: 0;
      color: var(--highlightColor);

      &.paddedIcon {
        padding: 4px;
        width: calc(2.2rem - 8px);
        height: calc(2.2rem - 8px);
      }
    }

    &:hover {
      background-color: var(--primaryColorBrightShade2);
    }
  }
}
</style>
