<template>
  <form v-if="!isLoading" class="login-form">
    <BaseTextInput v-model="loginName" class="login-field" placeholder="Email" @keydown.enter.prevent.stop="onUserLoginClick" />
    <BasePasswordInput v-model="password" class="login-field" placeholder="Password" @keydown.enter.prevent.stop="onUserLoginClick" />
    <div v-if="showIndoorUserCheckbox">
      <BaseCheckbox v-model:checked="indoorTechnicianCheckboxState">Login as Indoor Technician</BaseCheckbox>
    </div>
    <div class="error-msg">
      <slot></slot>
      <span v-if="errorMsg">{{ errorMsg }}</span>
    </div>
    <BaseButton class="login-btn" type="submit" @click.prevent="onUserLoginClick" @keydown.enter.prevent.stop="onUserLoginClick">Login</BaseButton>
    <div class="forgot-password-link">
      <a href="" class="link" @click.prevent="onForgotPasswordClick">Forgot Password</a>
      <span v-if="forgotPasswordSuccess" class="success">If your email is on our system, expect a password reset email soon!<br /></span>
    </div>
    <BaseTextSeparator>Sign in with</BaseTextSeparator>
    <div class="sso-buttons">
      <BaseButton class="sso-login-button" type="submit" @click.prevent="onUserLoginClick('Azure')"><MicrosoftLogo /></BaseButton>
      <BaseButton class="sso-login-button google" type="submit" @click.prevent="onUserLoginClick('Google')"
        ><GoogleLogo class="google-icon" />Google</BaseButton
      >
    </div>
  </form>
  <BaseLoader v-else class="loader" />
</template>

<script>
import AzureSignInListener from '../../components/functional/AzureSignInListener';
import BasePasswordInput from '../../components/base/BasePasswordInput.vue';
import BaseLoader from '../../components/base/BaseLoader.vue';
import BaseButton from '../../components/base/BaseButton.vue';
import BaseTextSeparator from '../../components/base/BaseTextSeparator.vue';
import BaseCheckbox from '../../components/base/BaseCheckbox.vue';
import BaseTextInput from '../../components/base/BaseTextInput.vue';
import MicrosoftLogo from '../../assets/MicrosoftLogo.svg?component';
import GoogleLogo from '../../assets/GoogleLogo.svg?component';
import { mapActions, mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import AzureConnector from '../../services/AzureSSO';
import GoogleConnector from '../../services/GoogleSSO';
import authService from '../../services/api/authService';
import { ERROR_MESSAGES } from '../../consts/appConsts';

export default {
  name: 'UserLoginForm',
  components: {
    BasePasswordInput,
    BaseLoader,
    BaseButton,
    BaseTextSeparator,
    BaseCheckbox,
    BaseTextInput,
    MicrosoftLogo,
    GoogleLogo
  },
  mixins: [AzureSignInListener],
  props: {
    hasSessionExpired: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['next', 'done'],
  data() {
    return {
      loginName: '',
      password: '',
      errorMsg: '',
      isLoading: false,
      indoorTechnicianCheckboxState: false,
      forgotPasswordSuccess: false,
      googleLoginFailureMessage: 'Failed to authenticate with Google'
    };
  },
  computed: {
    ...mapStores(useContextStore),
    showIndoorUserCheckbox() {
      return this.loginName.indexOf('@indoor-robotics.com') > -1;
    },
    isIndoorTechnicianModeSelected() {
      return this.showIndoorUserCheckbox && this.indoorTechnicianCheckboxState === true;
    }
  },
  methods: {
    ...mapActions(useContextStore, ['userLogin', 'azureUserLogin', 'googleUserLogin']),
    async afterAzureSignIn(response) {
      try {
        await this.azureUserLogin(response);
        this.isLoading = false;
        if (this.isIndoorTechnicianModeSelected) {
          this.contextStore.setTechnicianMode(true);
        }
        if (this.contextStore.auth.isAccountMissing) {
          this.$emit('next');
        } else if (this.contextStore.auth.isAuthenticated) {
          this.$emit('done');
        }
      } catch (error) {
        console.error(error);
        this.errorMsg = this.azureLoginFailureMessage;
      }
    },
    async loginUsingAzure() {
      try {
        const response = await AzureConnector.authenticateUsingAzure();
        const credentials = {
          accessToken: response.accessToken,
          asIndoorUser: this.isIndoorTechnicianModeSelected
        };
        await this.azureUserLogin(credentials);
      } catch (error) {
        console.error(error);
        this.errorMsg = this.azureLoginFailureMessage;
      }
    },
    async handleGoogleCredentialsResponse(credentials) {
      try {
        this.isLoading = true;
        credentials.asIndoorUser = this.isIndoorTechnicianModeSelected;
        // Send the token to the backend
        await this.googleUserLogin(credentials);
        if (this.isIndoorTechnicianModeSelected) {
          this.contextStore.setTechnicianMode(true);
        }
        if (this.contextStore.auth.isAccountMissing) {
          this.$emit('next');
        } else if (this.contextStore.auth.isAuthenticated) {
          this.$emit('done');
        }
      } catch (error) {
        console.error(error);
        this.errorMsg = this.googleLoginFailureMessage;
      } finally {
        this.isLoading = false;
      }
    },
    handleGoogleErrorCallback(error) {
      this.isLoading = false;
      this.errorMsg = `${this.googleLoginFailureMessage} with error: ${error.message}`;
    },
    async onForgotPasswordClick() {
      if (!this.isLoading) {
        if (this.loginName.trim() === '') {
          this.errorMsg = ERROR_MESSAGES.REQUIRED_FIELD_EMAIL;
          return;
        }
        try {
          this.isLoading = true;
          await authService.forgotPassword({
            login_name: this.loginName
          });
          this.forgotPasswordSuccess = true;
          this.isLoading = false;
        } catch (e) {
          this.isLoading = false;
          this.errorMsg = e.message;
        }
      }
    },
    async onUserLoginClick(externalAuth) {
      if (!externalAuth && (!this.loginName.trim() || !this.password.trim())) {
        this.errorMsg = ERROR_MESSAGES.REQUIRED_FIELDS;
        return;
      }
      try {
        this.isLoading = true;
        if (externalAuth === 'Azure') {
          await this.loginUsingAzure();
        } else if (externalAuth === 'Google') {
          await GoogleConnector.authenticateUsingGoogle(this.handleGoogleCredentialsResponse, this.handleGoogleErrorCallback);
        } else {
          await this.userLogin({
            loginName: this.loginName,
            password: this.password,
            asIndoorUser: this.isIndoorTechnicianModeSelected
          });
        }

        this.isLoading = false;
        if (this.contextStore.auth.isAccountMissing) {
          this.$emit('next');
        } else if (this.contextStore.auth.isAuthenticated) {
          this.$emit('done');
        }
      } catch (e) {
        this.isLoading = false;
        if (e.status === 500) {
          this.errorMsg = ERROR_MESSAGES.GENERIC_500;
        } else if (e.message === 'Network Error') {
          this.errorMsg = ERROR_MESSAGES.NETWORK_ERROR;
        } else {
          this.errorMsg = e.message;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.loader {
  margin: 0 auto;
  width: 3rem;
  height: 3rem;
}
.login-form {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  .login-field {
    :deep(input) {
      border-radius: 3px;
      padding: 0.2rem 0.5rem;
    }
  }

  .login-btn {
    padding: 0.4rem 1rem;
  }

  .error-msg {
    font-size: 0.9rem;
    color: var(--errorColor);
  }

  .forgot-password-link {
    text-align: right;

    .link {
      text-decoration: none;
      color: var(--secondaryTextColor);
      font-size: 14px;
      cursor: pointer;
      border-radius: 3px;
      padding: 2px;

      &:hover {
        text-decoration: underline;
      }

      &:focus-visible {
        outline: 2px solid var(--secondaryOverlayShade2);
        background: var(--secondaryOverlayShade1);
      }
    }

    .success {
      display: block;
      font-size: 13px;
    }
  }

  .sso-buttons {
    display: flex;
    column-gap: 1rem;
    justify-content: center;
    width: fit-content;
    margin: auto;

    .sso-login-button {
      background: var(--textColor);
      padding: 8px 15px;
      line-height: 0;
      width: fit-content;

      &.google {
        color: gray;
        display: flex;
        align-items: center;
        column-gap: 5px;
        font-size: 1.3rem;

        .google-icon {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }
  }
}
</style>
