export function getRandomColor(colorToken) {
  const lightnessFactor = Math.floor(colorToken / 18);
  const base = (colorToken || colorToken === 0 ? colorToken : Math.random() * 12) * 1.5;
  const hue = 150 + 20 * base;
  const saturation = '100%';
  const lightness = 75 + (lightnessFactor % 2 == 0 ? -10 : 0) + '%';
  return `hsla(${hue},${saturation},${lightness},1)`;
}

export function rgbArrToHex(rgbArr) {
  const hexString = '#' + ((1 << 24) + (Number(rgbArr[0]) << 16) + (Number(rgbArr[1]) << 8) + Number(rgbArr[2])).toString(16).slice(1);
  return hexString;
}

export function hexToRgbArr(hexColor) {
  var hexColorArr = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
  let result = [];
  result[0] = parseInt(hexColorArr[1], 16);
  result[1] = parseInt(hexColorArr[2], 16);
  result[2] = parseInt(hexColorArr[3], 16);
  return result;
}

export function getColorDiff(rgbArr1, rgbArr2) {
  let diff = 0;
  for (let i = 0; i < rgbArr1.length; i++) {
    diff += (rgbArr1[i] - rgbArr2[i]) * (rgbArr1[i] - rgbArr2[i]);
  }
  return Math.sqrt(diff);
}
