<template>
  <div class="loader-container">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: 'BaseLoader'
};
</script>

<style scoped lang="scss">
.loader-container {
  overflow: hidden;
  aspect-ratio: 1/1;
  display: flex;
  width: 1.5rem;

  .loader {
    content: '';
    display: inline-block;
    border-radius: 50%;
    animation: spinner 0.8s linear infinite;
    border: 2px solid var(--secondaryTextColor);
    border-top-color: var(--highlightColor);
    aspect-ratio: 1/1;
    box-sizing: border-box;
    margin: 1px;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
