import { defineStore } from 'pinia';
import { FETCH_STATE, SORT_MODE } from '../consts/appConsts';
import { LOCATION_LIST_TIME_RANGE } from '../consts/locationListConsts';
import { isDateInTimeRange } from '../utils/DateUtils';
import { EVENT_TYPE } from '../consts/eventConsts';

const initialFilters = {
  type: [],
  device: [],
  handled: [0],
  timeRange: {
    type: LOCATION_LIST_TIME_RANGE.LAST_24_HOURS,
    from: 0,
    to: 0
  }
};

export const useLocationListStore = defineStore('locationList', {
  state() {
    return {
      location: '',
      locationListFetchState: FETCH_STATE.INITIAL,
      locationListMap: new Map(),
      listFilters: structuredClone(initialFilters),
      listSortMode: SORT_MODE.DESC
    };
  },
  getters: {
    wasLocationListFetched() {
      return this.locationListFetchState === FETCH_STATE.SUCCESS || this.locationListFetchState === FETCH_STATE.ERROR;
    },
    locationList() {
      return Array.from(this.locationListMap.values());
    },
    isListFiltered() {
      return JSON.stringify(this.locationListFilters) !== JSON.stringify(initialFilters);
    },
    getItemById() {
      return itemId => this.locationListMap.get(itemId);
    }
  },
  actions: {
    doesItemPassCurrentFilters(item) {
      const _item = {
        ...item,
        timestamp: item.timestamp || item.requested_timestamp,
        type: item.event_type || EVENT_TYPE.MISSION,
        grid_cells: item.grid_cells || []
      };

      return Object.values(filtersConditions).every(filterCondition => filterCondition.bind(this, _item));
    },
    setLocation(location) {
      this.location = location;
    },
    async fetchLocationItem(item) {
      try {
        const include = { stream: false };
        const existingLocationItem = this.locationListMap.get(item.id);

        if (!existingLocationItem || !existingLocationItem.streamsData || existingLocationItem.streamsData.ttl - Date.now() <= 0) {
          include.stream = true;
        }
        const data = await locationItemsService.fetchLocationItem(item, include);
        this.locationListMap.set(item.id, item);
        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchLocationList() {
      try {
        this.locationListFetchState = FETCH_STATE.LOADING;
        const filters = flattenObj(this.listFilters);
        const data = await locationItemsService.fetchLocationItemsList({
          ...filters,
          location: this.location
        });
        this.locationListMap = new Map();
        data.forEach(item => {
          this.locationListMap.set(item.id, item);
        });
        this.locationListFetchState = FETCH_STATE.SUCCESS;
        return data;
      } catch (e) {
        this.locationListFetchState = FETCH_STATE.ERROR;
        return Promise.reject(e);
      }
    },
    updateFilters(filters) {
      Object.entries(filters).forEach(([filterType, filterValue]) => {
        this.listFilters[filterType] = filterValue;
      });
    },
    resetFilters() {
      this.listFilters = structuredClone(initialFilters);
    },
    setSortMode(sortMode) {
      this.listSortMode = sortMode;
    },
    removeItem(itemId) {
      this.locationListMap.delete(itemId);
    },
    addItem(item) {
      this.locationListMap.set(item.id, item);
    }
  }
});

const filtersConditions = {
  itemInContextZone: item => contextStore.zoneId === item.zone_id,
  itemTypeInFilter: item => !this.listFilters?.type?.length || this.listFilters.type.includes(item.type),
  itemDeviceInFilter: item => !this.listFilters?.device?.length || this.listFilters.device.includes(item.device),
  itemHandledInFilter: item => !this.listFilters?.handled?.length || this.listFilters.handled.includes(Number(item.handled)),
  itemLocationInFilter: item => {
    if (this.location) {
      const [filterX, filterY] = this.location.split('_');
      const filterGridCellX = Math.round(Number(filterX) * 2) / 2;
      const filterGridCellY = Math.round(Number(filterY) * 2) / 2;
      const filterGridCell = `${filterGridCellX}_${filterGridCellY}`;
      return item.grid_cells.includes(filterGridCell);
    } else {
      return true;
    }
  },
  itemInTimeRangeFilter: item => {
    return isDateInTimeRange(item.timestamp, this.listFilters.timeRange.type);
  }
};
