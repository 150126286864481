import { defineStore } from 'pinia';
import { useContextStore } from './ContextStore';
import { MAP_MODE } from '../consts/mapConsts';

export const useMapStore = defineStore('map', {
  state() {
    return {
      selectedAreaId: null,
      areaColor: 'pink',
      areaDraft: null,
      missionTemplateMapViewMode: MAP_MODE.NONE,
      missionTemplateDraft: [],
      selectedPoiIndex: 0,
      mapMode: MAP_MODE.DEFAULT,
      repositionPoint: null,
      landmarksDraft: [],
      eventBus: new EventTarget()
    };
  },
  actions: {
    updateMapMode(mapMode, { repositionPoint = null } = {}) {
      this.mapMode = mapMode || MAP_MODE.DEFAULT;
      this.repositionPoint = repositionPoint;
    },
    updateSelectedAreaId(areaId) {
      this.selectedAreaId = areaId;
    },
    setAreaColor(color) {
      this.areaColor = color;
    },
    updateAreaDraft(area) {
      this.areaDraft = area;
    },
    addMapLandmarkToDraft(landmark) {
      this.landmarksDraft.push(landmark);
    },
    removeMapLandmarkFromDraft(landmarkIndex) {
      this.landmarksDraft.splice(landmarkIndex, 1);
    },
    resetMapLandmarksDraft() {
      this.landmarksDraft = [...(useContextStore().zone.map_landmarks || [])];
    },
    resetMapMode() {
      this.mapMode = MAP_MODE.NONE;
      this.missionTemplateDraft = [];
      this.selectedAreaId = null;
    },
    addMissionTemplatePoi(point) {
      this.missionTemplateDraft.push(point);
    },
    removeMissionTemplatePoi(index) {
      this.missionTemplateDraft.splice(index, 1);
      this.eventBus.dispatchEvent(new CustomEvent('MISSION_DRAFT:INDEX_REMOVED', { detail: index }));
    },
    updateMissionTemplateDraft(newVal) {
      this.missionTemplateDraft = [...(newVal || [])];
    },
    updateSelectedPoiIndex(index) {
      this.selectedPoiIndex = index;
    }
  }
});
