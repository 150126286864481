<template>
  <div v-if="!seen" class="black-cover" @click="seen = true"><slot></slot></div>
</template>

<script>
export default {
  name: 'MapInstructionsCover',
  data() {
    return {
      seen: false
    };
  }
};
</script>

<style scoped lang="scss">
.black-cover {
  position: absolute;
  display: grid;
  background: var(--overlayShade1);
  font-family: var(--font-family-secondary);
  font-size: 1.5rem;
  font-weight: lighter;
  text-align: center;
  place-items: center;
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
}
</style>
