import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 -1 25 25",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M12 0c6.617 0 12 5.384 12 12a1 1 0 1 1-2 0c0-5.514-4.486-10-10-10S2 6.486 2 12a9.998 9.998 0 0 0 4.32 8.231 1 1 0 1 1-1.134 1.646A11.999 11.999 0 0 1 0 12C0 5.384 5.383 0 12 0zm10.59 14.776c.577.15.89.768.687 1.328a12.048 12.048 0 0 1-2.638 4.219.997.997 0 0 1-1.414.026 1 1 0 0 1-.026-1.414 9.985 9.985 0 0 0 2.209-3.55.988.988 0 0 1 1.183-.61zm-5.783 6.086a1 1 0 1 1-.001 2 1 1 0 0 1 0-2zM15.48 5.928a7.007 7.007 0 0 1 2.712 9.333l-.121.22a7.006 7.006 0 0 1-9.552 2.592c-3.35-1.92-4.512-6.205-2.592-9.552C7.847 5.17 12.13 4.01 15.48 5.928zm-3.472 1.075a5.006 5.006 0 0 0-4.347 2.512 5.004 5.004 0 0 0 1.852 6.822 5.008 5.008 0 0 0 6.824-1.851 5.004 5.004 0 0 0-1.851-6.822 4.97 4.97 0 0 0-2.478-.661z" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }