import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 10 10",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M.6915 9.3233.6915.5915 7.2403.5915 9.4233 2.7744 9.4233 9.3233.6915 9.3233 1.4191 8.5956 8.6956 8.5956 8.6956 3.5021 6.5127 3.5021 6.5127 1.3191 1.4191 1.3191 1.4191 8.5956" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }