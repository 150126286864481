import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", { transform: "translate(-499 -2194) translate(40 1620) translate(459 574)" }, [
      _createElementVNode("circle", {
        cx: "12",
        cy: "12",
        r: "12",
        fill: "#4A44BA"
      }),
      _createElementVNode("g", {
        fill: "#FFF",
        "fill-rule": "nonzero"
      }, [
        _createElementVNode("path", {
          d: "M10.68 2.64c0-.904-.736-1.64-1.64-1.64h-6.4C1.737 1 1 1.736 1 2.64v6.4c0 .904.736 1.64 1.64 1.64h2.626v1.093h1.093V10.68h2.68c.905 0 1.64-.736 1.64-1.64V6.413h1.067V5.32H10.68V2.64zm-1.094 6.4c0 .3-.245.546-.547.546H2.641c-.302 0-.547-.245-.547-.547V2.641c0-.302.245-.547.547-.547h6.398c.302 0 .547.245.547.547v6.398zm4.32 3.773H15v1.093C15 14.51 14.51 15 13.906 15H12.84v-1.094h1.066v-1.094zm-5.441 1.093h1.094V15H8.465v-1.094zm2.187 0h1.094V15h-1.094v-1.094zM15 6.414v1.094h-1.094V6.414H12.84V5.32h1.066c.604 0 1.094.49 1.094 1.094zm-1.094 2.133H15V9.64h-1.094V8.547zm0 2.133H15v1.093h-1.094V10.68zM6.36 13.906h1.067V15H6.359c-.604 0-1.093-.49-1.093-1.094v-1.094h1.093v1.094zm1.012-8.695h1.094v3.281H5.184V7.398h1.414l-1.952-1.95.774-.774L7.37 6.625V5.211z",
          transform: "translate(4 4)"
        })
      ])
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }