<template>
  <textarea
    class="textarea-input"
    :value="modelValue"
    :placeholder="placeholder"
    :rows="rows"
    :maxlength="maxlength"
    @input="$emit('update:modelValue', $event.target.value)"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
export default {
  name: 'BaseTextareaInput',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    rows: {
      type: [String, Number],
      default: 5
    },
    maxlength: {
      default: null,
      type: [null, Number]
    }
  },
  emits: ['update:modelValue', 'blur']
};
</script>

<style lang="scss" scoped>
.textarea-input {
  font-family: var(--font-family-secondary);
  font-size: inherit;
  color: var(--textColor);
  background: var(--primaryColor);
  padding: 0.5rem;
  border-radius: 0.5rem;
  min-width: 15rem;
  min-height: 2rem;
  outline: none;
  transition: transform 0.5s;
  border: 0;
  line-height: 1.5;
  max-width: 100%;
  resize: vertical;
  caret-color: var(--highlightColor);
  width: 100%;
  box-sizing: border-box;

  &:focus-visible {
    outline: inset;
    outline-color: var(--secondaryOverlayShade2);
    outline-style: solid;
    outline-width: 2px;
  }
}
</style>
