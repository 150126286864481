import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "1 1 30 30",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", {
      d: "M24.215 3H7.785c-3.24.004-5.864 2.647-5.868 5.91v9.454c.004 3.262 2.628 5.905 5.868 5.909h7.041v2.363h-4.694c-.648 0-1.174.53-1.174 1.182 0 .653.526 1.182 1.174 1.182h11.736c.648 0 1.174-.53 1.174-1.182 0-.653-.526-1.182-1.174-1.182h-4.694v-2.363h7.041c3.24-.004 5.864-2.647 5.868-5.91V8.91C30.08 5.647 27.455 3.004 24.215 3zm3.134 15.39c0 1.893-1.524 3.428-3.405 3.428H8.056c-1.88 0-3.405-1.535-3.405-3.428V9.247c0-1.894 1.524-3.429 3.405-3.429h15.888c1.88 0 3.405 1.535 3.405 3.429v9.143zm-2.682-4.572c0 .603-.485 1.091-1.084 1.091h-3.212l-1.841 2.787c-.202.305-.542.487-.905.486h-.067c-.386-.024-.73-.253-.903-.601l-2.414-4.867-1.131 1.71c-.201.303-.54.485-.902.485H8.417c-.599 0-1.084-.488-1.084-1.09 0-.603.485-1.092 1.084-1.092h3.212L13.47 9.94c.213-.328.585-.513.972-.485.386.023.73.253.903.6l2.414 4.865 1.131-1.71c.202-.302.54-.483.902-.483h3.791c.599 0 1.084.489 1.084 1.091z",
      transform: "translate(-379 -2025) translate(379 2025)"
    }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }