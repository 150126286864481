import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 22 22",
  role: "icon",
  fill: "#e57373"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M10.999 0c.393 0 .786.143 1.072.43l9.485 9.49a1.519 1.519 0 0 1 0 2.145l-9.485 9.491A1.508 1.508 0 0 1 11 22c-.405 0-.786-.157-1.072-.444l-9.483-9.49a1.519 1.519 0 0 1 0-2.147L9.927.43c.286-.287.679-.43 1.072-.43zm0 2.185l-8.801 8.807 8.801 8.807 8.801-8.807L11 2.185zm.5 13.81h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5zm-.5-4.002a1 1 0 0 1-1-1V6.988a1 1 0 0 1 2 0v4.003a1 1 0 0 1-1 1z" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }