<template>
  <div class="mission-template-card" :class="{ selected: selected || viewMode }" @click="$emit('select')">
    <div class="mission-template-data">
      <div class="title" :title="missionTemplate.name">
        {{ missionTemplate.name }}
        <span v-if="isTechnician" class="template-id">({{ missionTemplate.id }})</span>
      </div>
      <div class="poi-count">{{ missionTemplateDisplayedPois.length }} POI</div>
      <div class="device-type-compatibility-mark" :title="compatibilityTitle">
        <IconDrone v-if="missionTemplate.compatible_device_types.includes(DEVICES_TYPES.SKYDIO)" class="drone-icon" />
        <IconDrone v-if="missionTemplate.compatible_device_types.includes(DEVICES_TYPES.TANDO)" class="tando drone-icon" />
      </div>
      <div class="source-info"></div>
      <div class="time">
        <span>Created At: </span>
        {{ createdAt }}
      </div>
      <div class="poi-icons">
        <span
          v-for="(poi, index) in missionTemplateDisplayedPois"
          :key="`mt-${missionTemplate.id}-poi-${index}`"
          :title="getPoiIconName(poi.poiType)"
          :class="['icon-container', { 'detection-badge': isTechnician && missionTemplate?.poi_detection_settings?.[index] }]"
        >
          <component :is="getPoiIcon(poi.poiType)" class="icon" />
        </span>
      </div>
      <div v-if="missionTemplate.hooks && missionTemplate.hooks.length" class="exec-info"><span>Hooks: </span>{{ missionTemplate.hooks.length }}</div>
    </div>
    <div v-if="selected && !viewMode" class="actions">
      <BaseButton class="action-btn" :disabled="!allowLaunch" @click="$emit('start')">Launch</BaseButton>
      <BaseButton :class="['action-btn', 'edit-btn']" :disabled="!allowEditAndDelete" @click="$emit('edit')">Edit</BaseButton>
      <BaseButton :class="['action-btn', 'edit-btn']" :disabled="!allowEditAndDelete" @click="$emit('delete')">Delete</BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../components/base/BaseButton.vue';
import { POI_OPTIONS_ICONS } from '../../consts/mapConsts';
import { PRIVILEGES } from '../../consts/authConsts';
import { getDateTime } from '../../utils/DateUtils';
import { useContextStore } from '../../store/ContextStore';
import { useMapStore } from '../../store/MapStore';
import { mapState } from 'pinia';
import IconDrone from '../../components/icons/IconDrone.svg?component';
import { DEVICES_TYPES } from '../../consts/deviceConsts';
import { MAP_MODE } from '../../consts/mapConsts';

export default {
  name: 'MissionTemplateCard',
  components: {
    BaseButton,
    IconDrone
  },
  props: {
    missionTemplate: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    viewMode: {
      type: Boolean,
      default: false
    }
  },
  emits: ['select', 'start', 'edit', 'delete'],
  data() {
    return {
      DEVICES_TYPES
    };
  },
  computed: {
    ...mapState(useMapStore, ['mapMode', 'missionTemplateDraft']),
    ...mapState(useContextStore, ['zone', 'hasPrivilege', 'isTechnician']),
    createdAt() {
      return getDateTime(this.missionTemplate.date_created);
    },
    allowLaunch() {
      return this.hasPrivilege(PRIVILEGES.INVOKE_MISSION_TEMPLATE) && !this.zone.halted;
    },
    allowEditAndDelete() {
      return this.hasPrivilege(PRIVILEGES.MANAGE_MISSION_TEMPLATES);
    },
    missionTemplateDisplayedPois() {
      // If POI is destination tile do not display it
      let pois;
      if (this.mapMode === MAP_MODE.MISSION_TEMPLATE_EDIT) {
        pois = this.missionTemplateDraft;
      } else if (this.missionTemplate.destination_tile_id) {
        pois = this.missionTemplate.pois.filter(poi => !poi.isTile);
      } else {
        pois = this.missionTemplate.pois;
      }
      return pois.filter(poi => poi.poiType);
    },
    compatibilityTitle() {
      return `Available for ${this.missionTemplate.compatible_device_types
        .join(' and ')
        .replace(DEVICES_TYPES.SKYDIO, 'Tando-X')
        .replace(DEVICES_TYPES.TANDO, 'Tando')}`;
    }
  },
  methods: {
    getPoiIcon(poiType) {
      return POI_OPTIONS_ICONS[poiType];
    },
    getPoiIconName(poiType) {
      return poiType.charAt(0).toUpperCase() + poiType.slice(1).toLowerCase().replace(/_/g, '-');
    }
  }
};
</script>

<style scoped lang="scss">
.mission-template-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid var(--primaryColorBrightShade1);
  background: var(--primaryColor);
  color: var(--textColor);
  row-gap: 1rem;
  font-size: 1rem;

  &.selected,
  &:hover {
    background: var(--primaryColorDarkShade1);
  }

  .mission-template-data {
    display: grid;
    grid-template-areas:
      'title title poi-count poi-count . .'
      'source source poi-icons poi-icons poi-icons poi-icons'
      '. . . . . .'
      'time time time exec-info exec-info exec-info';
    grid-template-rows: auto auto 0.4fr 1fr;
    grid-template-columns: auto auto 5rem 3rem 3rem 3rem;
    row-gap: 0.3rem;
    align-items: start;
    transition: 0.3s;
    column-gap: 0.5rem;

    .poi-icons {
      grid-area: poi-icons;
      display: flex;
      flex-wrap: wrap;
      column-gap: 0.7rem;
      row-gap: 0.7rem;

      .icon-container {
        position: relative;

        .icon {
          width: 1.5rem;
          height: 1.5rem;
          flex-shrink: 0;
        }

        &.detection-badge::after {
          content: '';
          position: absolute;
          width: 0.5rem;
          aspect-ratio: 1 / 1;
          border-radius: 50%;
          border: 2px solid var(--primaryColor);
          background: var(--infoColor);
          top: 0;
          left: 0;
          height: 10px;
          transform: translate(-25%, -25%);
        }
      }
    }

    .device-type-compatibility-mark {
      grid-area: device-type-mark;
      display: flex;
      column-gap: 8px;
      border-radius: 25%;
      justify-self: end;
      color: #3794fff0;
      font-size: 0.8rem;
      font-weight: bold;
      background: var(--primaryColorDarkShade1);
      padding: 4px;
      text-align: center;
      align-self: start;

      .drone-icon {
        pointer-events: none;
        width: 1.3rem;
        height: 1.3rem;
        &.tando {
          color: var(--highlightColor);
        }
      }
    }

    .poi-count,
    .title {
      font-size: 1.3rem;
      word-break: break-word;

      .template-id {
        color: var(--secondaryOverlayShade2);
        font-size: 1rem;
      }
    }

    .poi-count {
      grid-area: poi-count;
    }

    .title {
      grid-area: title;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .source-info {
      grid-area: source;
      color: var(--secondaryTextColor);
    }

    .exec-info {
      grid-area: exec-info;
      justify-self: right;

      span {
        color: var(--highlightColor);
      }
    }

    .time {
      grid-area: time;
      span {
        color: var(--highlightColor);
      }
    }
  }
  .actions {
    display: flex;
    column-gap: 1rem;

    .action-btn {
      flex-grow: 1;
    }
    .edit-btn {
      background: var(--disabledColor);
    }
  }
}
</style>
