<template>
  <BaseBoundaryEnforcer :position="position">
    <MapThumbnailCard class="thumbnail-card-popup" :item="item" />
  </BaseBoundaryEnforcer>
</template>

<script>
import BaseBoundaryEnforcer from '../../components/base/BaseBoundaryEnforcer.vue';
import MapThumbnailCard from './MapThumbnailCard.vue';

export default {
  name: 'MapPointMenu',
  components: {
    BaseBoundaryEnforcer,
    MapThumbnailCard
  },
  props: {
    position: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.thumbnail-card-popup {
  background: var(--primaryColorDarkShade1);
  border: 1px solid var(--primaryColorBrightShade1);
  padding: 0.5rem 0.5rem 1rem 0.5rem;
}
</style>
