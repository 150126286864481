import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", { transform: "translate(-1186 -1887) translate(1186 1887)" }, [
      _createElementVNode("circle", {
        cx: "12",
        cy: "12",
        r: "12",
        fill: "#9651DC"
      }),
      _createElementVNode("g", { fill: "#FFF" }, [
        _createElementVNode("path", {
          d: "M9.912 9c1.704.002 3.086 1.542 3.088 3.441v2.13c0 .237-.172.429-.385.429h-9.23C3.172 15 3 14.808 3 14.571v-2.13c.002-1.9 1.384-3.439 3.088-3.441zM8 2c1.657 0 3 1.343 3 3S9.657 8 8 8 5 6.657 5 5s1.343-3 3-3z",
          transform: "translate(4 4)"
        })
      ])
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }