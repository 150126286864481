import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "1 1 26 26",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("text", {
        fill: "currentColor",
        stroke: "null",
        transform: "matrix(0.653804361820221,0,0,0.583949625492096,4.305808249861002,8.607042122632265) ",
        "xml:space": "preserve",
        "text-anchor": "start",
        "font-family": "Helvetica, Arial, sans-serif",
        "font-size": "20",
        y: "17.24874",
        x: "8.56973",
        "stroke-opacity": "null",
        "stroke-width": "0"
      }, "?"),
      _createElementVNode("path", {
        fill: "currentColor",
        d: "M14 26c6.627 0 12-5.373 12-12S20.627 2 14 2a11.955 11.955 0 0 0-7.847 2.921A11.972 11.972 0 0 0 2 14c0 6.627 5.373 12 12 12zm0-2C8.477 24 4 19.523 4 14a9.973 9.973 0 0 1 3.461-7.566A9.956 9.956 0 0 1 14 4c5.523 0 10 4.477 10 10s-4.477 10-10 10z",
        opacity: ".5"
      })
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }