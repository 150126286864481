<template>
  <BaseModal
    v-if="contextStore.showConfirmationDialog"
    class="confirmation-dialog"
    :confirmation-option="{ text: contextStore.confirmationDialogData.confirmText }"
    :cancel-option="cancelOption"
    @close="onResolve"
    @confirm="onResolve(true)"
  >
    <template #title>
      <span>{{ contextStore.confirmationDialogData.title }}</span>
    </template>
    <template #body>
      <div class="dialog-body">
        {{ contextStore.confirmationDialogData.text }}
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from './BaseModal.vue';
import { useContextStore } from '../../store/ContextStore';
import { mapStores } from 'pinia';
import { computed } from 'vue';

export default {
  name: 'BaseConfirmationDialog',
  components: {
    BaseModal
  },
  computed: {
    ...mapStores(useContextStore)
  },
  methods: {
    onResolve(isConfirmed = false) {
      this.contextStore.$patch(state => {
        state.confirmationDialogData.callback(isConfirmed);
        state.showConfirmationDialog = false;
      });
    }
  }
};

const cancelOption = computed(() => (state.confirmationDialogData.cancelText ? { text: state.confirmationDialogData.cancelText } : null));
</script>

<style lang="scss" scoped>
.confirmation-dialog {
  .dialog-body {
    white-space: pre;
    padding: 0 2rem;
    line-height: 2rem;
    font-size: 1rem;
    overflow: auto;
    max-height: 75vh;
    margin: 1rem 0;
  }
}
</style>
