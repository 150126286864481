import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", null, [
      _createElementVNode("circle", {
        cx: "12",
        cy: "12",
        r: "12",
        fill: "#05AD70"
      }),
      _createElementVNode("path", {
        fill: "#FFF",
        d: "M19.531 11.531c-.259 0-.468.21-.468.469 0 3.894-3.169 7.063-7.063 7.063-3.894 0-7.062-3.169-7.063-7.063 0-3.894 3.169-7.063 7.063-7.063 1.73 0 3.364.618 4.651 1.749L15.18 8.157c-.893-.74-2.005-1.144-3.18-1.144-2.75 0-4.987 2.237-4.987 4.987S9.25 16.988 12 16.988 16.988 14.75 16.988 12c0-.259-.21-.469-.47-.469-.258 0-.468.21-.468.469 0 2.233-1.817 4.05-4.05 4.05-2.233 0-4.05-1.817-4.05-4.05 0-2.233 1.817-4.05 4.05-4.05.924 0 1.8.308 2.513.874l-1.487 1.488c-.306-.187-.658-.287-1.026-.287-1.09 0-1.975.886-1.975 1.975s.886 1.975 1.975 1.975 1.975-.886 1.975-1.975c0-.367-.1-.72-.287-1.025l3.969-3.969c.091-.092.137-.212.137-.331 0-.12-.046-.24-.137-.332C16.146 4.833 14.137 4 12 4s-4.146.832-5.657 2.343C4.833 7.854 4 9.863 4 12s.832 4.146 2.343 5.657C7.854 19.167 9.863 20 12 20s4.146-.832 5.657-2.343C19.167 16.146 20 14.137 20 12c0-.259-.21-.469-.469-.469z"
      })
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }