<template>
  <div class="carousel">
    <div class="carousel-btn" :class="{ disabled: !allowPrev }" @click="prev">
      <IconCollapse class="prev"><title>Previous</title></IconCollapse>
    </div>
    <div ref="carouselItems" class="items-container">
      <template v-for="(item, index) in items" :key="`carousel-item-${index}`">
        <div v-if="separationLines && index" class="separation-line" :style="separationLineStyle"></div>
        <div class="item" :style="{ flexBasis: ItemFlexBasis }">
          <slot :name="`carousel-item-${index}`"></slot>
        </div>
      </template>
    </div>
    <div class="carousel-btn" :class="{ disabled: !allowNext }" @click="next">
      <IconCollapse class="next"><title>Next</title></IconCollapse>
    </div>
  </div>
</template>

<script>
import IconCollapse from '../icons/IconCollapse.svg?component';
export default {
  name: 'BaseCarousel',
  components: {
    IconCollapse
  },
  props: {
    items: {
      required: true,
      type: Array
    },
    numOfItemsInView: {
      default: 1,
      type: Number
    },
    separationLines: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      separationLineStyle: { height: '100px' },
      allowPrev: false,
      allowNext: this.numOfItemsInView < this.items?.length,
      scrollTimeout: null,
      viewItemFractionSize: 100 / this.numOfItemsInView / 100
    };
  },
  computed: {
    ItemFlexBasis() {
      let factor = `${100 / Math.min(this.items?.length, this.numOfItemsInView)}%`;

      if (this.separationLines) {
        return `calc(${factor} - 2px)`;
      }
      return factor;
    }
  },
  mounted() {
    if (this.separationLines) {
      this.separationLineStyle.height = `${this.$refs.carouselItems.clientHeight - 10}px`;
    }
  },
  methods: {
    next() {
      const endOffset = this.$refs.carouselItems.scrollWidth - this.$refs.carouselItems.clientWidth;
      let targetScrollOffset = Math.ceil(this.$refs.carouselItems.scrollLeft + this.$refs.carouselItems.clientWidth * this.viewItemFractionSize);
      if (this.separationLines) {
        targetScrollOffset += 2;
      }
      this.allowNext = targetScrollOffset < endOffset;
      this.allowPrev = true;
      this.$refs.carouselItems.scroll({
        behavior: 'smooth',
        left: targetScrollOffset
      });
    },
    prev() {
      let targetScrollOffset = Math.floor(this.$refs.carouselItems.scrollLeft - this.$refs.carouselItems.clientWidth * this.viewItemFractionSize);
      if (this.separationLines) {
        targetScrollOffset -= 2;
      }
      this.allowNext = true;
      this.allowPrev = targetScrollOffset > 0;
      this.$refs.carouselItems.scroll({
        behavior: 'smooth',
        left: targetScrollOffset
      });
    }
  }
};
</script>

<style lang="scss">
.carousel {
  display: flex;
  align-items: center;

  .items-container {
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    scroll-snap-type: x mandatory;

    .item {
      flex-shrink: 0;
      scroll-snap-align: start;
    }

    .separation-line {
      width: 2px;
      flex-shrink: 0;
      height: 100%;
      background: var(--primaryColorBrightShade1);
      margin: 1px;
    }
  }

  .carousel-btn {
    cursor: pointer;
    .prev {
      transform: rotate(90deg);
      width: 1rem;
      height: 1rem;
    }

    .next {
      transform: rotate(-90deg);
      width: 1rem;
      height: 1rem;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}
</style>
