<template>
  <div class="pagination">
    <div class="pages">
      <button :class="[{ disabled: modelValue === 1 }, 'page-button']" :disabled="modelValue === 1" @click="setPage(modelValue - 1)">Previous</button>
      <button
        v-for="pageNumber in displayedPages"
        :key="`page-${pageNumber}`"
        :class="[
          {
            current: modelValue === pageNumber,
            last: collapseBefore(pageNumber),
            first: collapseAfter(pageNumber)
          },
          'page-button'
        ]"
        @click="setPage(pageNumber)"
      >
        {{ pageNumber }}
      </button>
      <button :class="[{ disabled: modelValue === totalPages }, 'page-button']" @click="setPage(modelValue + 1)">Next</button>
    </div>
    <div class="page-results-range-label">
      <span>{{ pageItemsRangeLabel }}</span> of {{ itemsCount }}
    </div>
  </div>
</template>

<script>
const COLLAPSE_PAGES_THRESHOLD = 4;
export default {
  name: 'BasePaginationBar',
  props: {
    modelValue: {
      type: Number,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      required: true
    },
    currentPageItemsCount: {
      type: Number,
      required: true
    },
    itemsCount: {
      type: Number,
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    totalPages() {
      return Math.ceil(this.itemsCount / this.itemsPerPage);
    },
    pageItemsRangeLabel() {
      const from = (this.modelValue - 1) * this.itemsPerPage + 1;
      const to = Math.min(from + this.currentPageItemsCount - 1, this.itemsCount);
      return `${from} - ${to}`;
    },
    displayedPages() {
      const pagesArray = [];
      let distance = 1;
      while (distance <= COLLAPSE_PAGES_THRESHOLD && pagesArray.length < COLLAPSE_PAGES_THRESHOLD) {
        if (this.modelValue + distance < this.totalPages) {
          pagesArray.push(this.modelValue + distance);
        }
        if (this.modelValue - distance > 1) {
          pagesArray.push(this.modelValue - distance);
        }
        distance++;
      }
      pagesArray.push(1);
      pagesArray.push(this.totalPages);
      if (this.modelValue !== 1 && this.modelValue !== this.totalPages) {
        pagesArray.push(this.modelValue);
      }
      return pagesArray.sort((a, b) => a - b);
    }
  },
  methods: {
    collapseBefore(pageNumber) {
      return pageNumber == this.totalPages && pageNumber - this.displayedPages[this.displayedPages.length - 2] > 1;
    },
    collapseAfter(pageNumber) {
      return pageNumber == 1 && this.displayedPages[1] - pageNumber > 1;
    },
    setPage(pageNumber) {
      this.$emit('update:modelValue', pageNumber);
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  row-gap: 8px;
  flex-wrap: wrap;
  color: var(--textColor);

  .pages {
    display: flex;
    column-gap: 4px;

    .page-button {
      cursor: pointer;
      border-radius: 5px;
      background: var(--secondaryColorShade1);
      padding: 4px 6px;
      color: inherit;
      outline: 0;
      border: 0;

      &.current {
        background: var(--highlightColorShade1);
      }

      &:hover {
        filter: brightness(1.4);
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
        user-select: none;
      }

      &:focus-visible {
        box-shadow: inset 0 0 0 2px var(--primaryColorBrightShade1);

        &.current {
          box-shadow: inset 0 0 0 2px var(--secondaryTextColor);
        }
      }

      &:active {
        filter: brightness(1.2);
      }

      &.first {
        position: relative;
        margin-right: 0.8rem;

        &::after {
          content: '...';
          position: absolute;
          right: -0.8rem;
          bottom: 0;
          pointer-events: none;
        }
      }

      &.last {
        position: relative;
        margin-left: 0.8rem;

        &::before {
          content: '...';
          position: absolute;
          left: -0.8rem;
          bottom: 0;
          pointer-events: none;
        }
      }
    }
  }

  .page-results-range-label {
    color: var(--disabledColor);
    align-self: flex-end;

    span {
      margin-right: 0.1rem;
      color: var(--highlightColorShade1);
    }
  }
}
</style>
