import { defineStore } from 'pinia';
import missionsTemplatesService from '../services/api/missionsTemplatesService';
const DEFAULT_MISSION_NAME_PREFIX = 'Mission Template';

export const useMissionTemplatesStore = defineStore('missionTemplates', {
  state() {
    return {
      missionTemplatesMap: {}
    };
  },
  getters: {
    missionTemplates() {
      return Object.values(this.missionTemplatesMap);
    },
    defaultMissionName() {
      let templateNum = 1;
      const defaultNamingRegExp = new RegExp('^' + DEFAULT_MISSION_NAME_PREFIX + ' ([0-9]+$)', 'i');
      this.missionTemplates.forEach(missionTemplate => {
        const nameMatch = defaultNamingRegExp.exec(missionTemplate.name);

        if (nameMatch) {
          const entityNumber = parseInt(nameMatch[1], 10);
          templateNum = entityNumber + 1;
        }
      });

      return `${DEFAULT_MISSION_NAME_PREFIX} ${templateNum}`;
    }
  },
  actions: {
    async fetchMissionTemplates() {
      try {
        const missionTemplates = await missionsTemplatesService.fetchMissionTemplates();
        this.missionTemplatesMap = missionTemplates.reduce((acc, mt) => {
          acc[mt.id] = mt;
          return acc;
        }, {});
        return missionTemplates;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async createMissionTemplate(params) {
      try {
        const missionTemplate = await missionsTemplatesService.createMissionTemplate(params);
        this.missionTemplatesMap[missionTemplate.id] = missionTemplate;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async updateMissionTemplate(params) {
      try {
        const missionTemplate = await missionsTemplatesService.updateMissionTemplate(params);
        this.missionTemplatesMap[missionTemplate.id] = missionTemplate;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async deleteMissionTemplate(missionTemplateId) {
      try {
        await missionsTemplatesService.deleteMissionTemplate(missionTemplateId);
        delete this.missionTemplatesMap[missionTemplateId];
      } catch (e) {
        return Promise.reject(e);
      }
    }
  }
});
