import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "-17 -17 34 34",
  role: "icon"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("defs", null, [
      _createElementVNode("mask", { id: "indr__iconMaskMainEntrance" }, [
        _createElementVNode("rect", {
          x: "-17",
          y: "-17",
          width: "34",
          height: "34",
          fill: "white"
        }),
        _createElementVNode("path", {
          d: "m12.3258-1.397.0151 1.8932-4.1839-.0364 1.3116 1.4378-1.4288 1.2492-2.847-3.1747.0097-.7912 2.8798-3.1878 1.3748 1.3215-1.2266 1.2888ZM5.1634-10.2722 1.394-10.2532V-12.74L-10.268-10.242-10.2798 10.4769 5.1634 10.4265Zm-3.9242 1.7582 1.769 0V8.4885h-1.9656ZM-.2912-10.1557V8.4885H-8.3993V-8.567Z",
          fill: "black"
        })
      ])
    ], -1)),
    _cache[1] || (_cache[1] = _createElementVNode("circle", {
      cx: "0",
      cy: "0",
      r: "17",
      fill: "currentColor",
      mask: "url(#indr__iconMaskMainEntrance)"
    }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }