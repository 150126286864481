<template>
  <BaseModal
    cancel-option
    :confirmation-option="{ text: 'Submit', disabled: !isDirty }"
    :is-executing="isSendingFrame"
    @close="onSubmit(false)"
    @confirm="onSubmit(true)"
  >
    <template #title> Frame Detection {{ getTimeStamp(timestamp) }} </template>
    <template #body>
      <div class="body-content">
        <canvas ref="snapshotCanvas" width="400" height="200" class="snapshot-canvas" />
        <BaseButton class="action-button" @click="addDetectionSettings">Add detection setting</BaseButton>
        <DetectionSettingForm
          v-for="(detectionSetting, index) in detectionSettings"
          :key="`ds-${elementIdentifiers[index]}`"
          v-model="detectionSettings[index]"
          @remove="removeDetectionSettings(index)"
        />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '../../components/base/BaseModal.vue';
import BaseButton from '../../components/base/BaseButton.vue';
import DetectionSettingForm from '../../components/DetectionSettingForm.vue';
import { getTimeStamp } from '../../utils/DateUtils';
import { mapActions, mapState } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import { SNACKBAR_TYPE } from '../../consts/appConsts';
import { v1 as uuidv1 } from 'uuid';
import missionsService from '../../services/api/missionsService';

export default {
  name: 'FrameDetectionModal',
  components: {
    BaseModal,
    BaseButton,
    DetectionSettingForm
  },
  props: {
    videoElement: {
      type: Object,
      required: true
    },
    timestamp: {
      type: Number,
      required: true
    },
    location: {
      type: Object,
      required: true
    },
    source: {
      type: String,
      required: true
    }
  },
  emits: ['close'],
  data() {
    return {
      ctx: null,
      width: 0,
      height: 0,
      isSendingFrame: false,
      detectionSettings: [],
      elementIdentifiers: []
    };
  },
  computed: {
    ...mapState(useContextStore, ['mission']),
    isDirty() {
      return this.detectionSettings.length;
    }
  },
  mounted() {
    this.width = this.videoElement.clientWidth;
    this.height = this.videoElement.clientHeight;
    this.$refs.snapshotCanvas.width = this.width;
    this.$refs.snapshotCanvas.height = this.height;
    this.ctx = this.$refs.snapshotCanvas.getContext('2d');
    this.ctx.drawImage(this.videoElement, 0, 0, this.width, this.height);
  },
  methods: {
    ...mapActions(useContextStore, ['showSnackbar']),
    getTimeStamp,
    addDetectionSettings() {
      this.detectionSettings.push(null); // This will trigger initiation of new DetectionSettingForm which will automatically initialize with default values
      this.elementIdentifiers.push(uuidv1());
    },
    removeDetectionSettings(index) {
      this.detectionSettings.splice(index, 1);
      this.elementIdentifiers.splice(index, 1);
    },
    async onSubmit(isConfirmed) {
      if (isConfirmed) {
        this.isSendingFrame = true;
        try {
          await missionsService.customFrameDetection(this.mission.id, {
            timestamp: this.timestamp,
            detection_settings: this.detectionSettings,
            location: this.location,
            source: this.source
          });
          this.showSnackbar({
            message: 'Frame was sent for analysis.',
            type: SNACKBAR_TYPE.SUCCESS
          });
        } catch (err) {
          console.error(err);
          this.showSnackbar({
            message: 'Unable to send frame for analysis.',
            type: SNACKBAR_TYPE.ERROR
          });
        } finally {
          this.isSendingFrame = false;
        }
      }
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">
.body-content {
  display: grid;
  row-gap: 1.5rem;
  padding: 1rem 0;
  column-gap: 1rem;
  row-gap: 1rem;
  place-content: center;

  .snapshot-canvas {
    border-radius: 5px;
  }
}
</style>
