import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 6 6",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M 2 0 L 0 0 L 0 2 L 1 2 L 1 1 L 2 1 L 2 0 M 4 0 L 6 0 L 6 2 L 5 2 L 5 1 L 4 1 L 4 0 M 5 4 L 6 4 L 6 6 L 4 6 L 4 5 L 5 5 L 5 4 M 2 6 L 0 6 L 0 4 L 1 4 L 1 5 L 2 5 L 2 6" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }