import { defineStore } from 'pinia';
import { useMapStore } from './MapStore';
import { useEventListStore } from './EventListStore';
import { useZonesStore } from './ZonesStore';
import { useDevicesStore } from './DevicesStore';
import LocalStorageManager from '../services/LocalStorageManager';
import authService from '../services/api/authService';
import AzureConnector from '../services/AzureSSO';
import { SNACKBAR_TYPE, SNACKBAR_DURATION, ERROR_MESSAGES } from '../consts/appConsts';
import { v1 as uuidv1 } from 'uuid';
import { clone } from '../utils/ObjectUtils';

export const useContextStore = defineStore('context', {
  state() {
    return {
      initialDataLoaded: false,
      themes: ['dark', 'light'],
      theme: 'dark',
      showConfirmationDialog: false,
      confirmationDialogData: {
        callback: () => {},
        title: '',
        text: '',
        confirmText: 'OK',
        cancelText: 'Cancel'
      },
      showMissionDialog: false,
      missionDialogData: {
        callback: () => {},
        text: '',
        title: 'Start Mission',
        cancelText: 'Cancel'
      },
      snackbarMessages: new Map(),
      zoneId: null,
      user: {
        measurement_units: 'METRIC',
        temperature_scale: 'CELSIUS',
        date_format: 'DD/MM/YYYY'
      },
      accountId: null,
      account: null,
      auth: {
        isAuthenticated: false,
        isAccountMissing: false,
        isTechnicianStorageState: undefined,
        userLoginToken: '',
        possibleAccounts: [],
        authMethod: 'PASS',
        azureRedirect: {
          isBeingProcessed: false,
          error: null,
          response: null
        },
        isSessionLoading: false,
        sessionLoadingResults: {}
      },
      isActive: false,
      mission: null,
      event: null,
      missionTemplate: null,
      showAppLoader: false
    };
  },
  getters: {
    isTechnician() {
      return this.user.isIndoorUser && this.auth.isTechnicianStorageState;
    },
    zone() {
      return useZonesStore().getZoneById(this.zoneId) ?? {};
    },
    hasPrivilege() {
      return privilegeId => this.isTechnician || this.user.isAdmin || this.user?.privileges?.includes?.(privilegeId);
    }
  },
  actions: {
    showSnackbar({ message, type = SNACKBAR_TYPE.INFO, undoOption, duration = SNACKBAR_DURATION }) {
      const messageId = uuidv1();
      this.snackbarMessages.set(messageId, { message, type, undoOption });
      setTimeout(() => {
        this.snackbarMessages.delete(messageId);
      }, duration);
    },
    updateTheme(theme, doNotStore) {
      this.theme = theme;

      if (!doNotStore) {
        LocalStorageManager.setItem('Theme', theme);
      }
    },
    async updateContextZone(zoneId, requiredAttrs = []) {
      let missingAttrs;

      if (this.zoneId !== zoneId) {
        this.zoneId = zoneId;
        useEventListStore().resetFilters();
        useMapStore().resetMapMode();
      }

      if (this.zone) {
        missingAttrs = requiredAttrs.filter(attr => !(attr in this.zone));
      }

      if (!this.zone || missingAttrs.length) {
        try {
          await useZonesStore().fetchZoneById(zoneId, this.zone ? { attrs: missingAttrs } : undefined);
        } catch (e) {
          console.error(e);
          this.showSnackbar({
            message: ERROR_MESSAGES.UNABLE_TO_DISPLAY_PAGE,
            type: SNACKBAR_TYPE.INFO
          });
          return false;
        }
      }
      return true;
    },
    async azureUserLogin(credentials) {
      try {
        const { data, headers } = await authService.azureUserLogin(credentials);

        if (headers['x-user-login-token']) {
          this.auth.userLoginToken = headers['x-user-login-token'];
          this.auth.isAccountMissing = true;
          this.auth.possibleAccounts = data.accounts;
          this.auth.isAuthenticated = false;
        }
        if (credentials.asIndoorUser) {
          this.setTechnicianMode(true);
        }

        if (headers['x-account-login-token']) {
          await this.fetchSession(headers['x-account-login-token']);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async googleUserLogin(credentials) {
      try {
        const { data, headers } = await authService.googleUserLogin(credentials);
        if (headers['x-user-login-token']) {
          this.auth.userLoginToken = headers['x-user-login-token'];
          this.auth.isAccountMissing = true;
          this.auth.possibleAccounts = data.accounts;
          this.auth.isAuthenticated = false;
        }
        if (credentials.asIndoorUser) {
          this.setTechnicianMode(true);
        }

        if (headers['x-account-login-token']) {
          await this.fetchSession(headers['x-account-login-token']);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async userLogin({ loginName, password, asIndoorUser }) {
      try {
        const { data, headers } = await authService.userLogin(loginName, password, asIndoorUser);

        if (headers['x-user-login-token']) {
          this.auth.userLoginToken = headers['x-user-login-token'];
          this.auth.isAccountMissing = true;
          this.auth.possibleAccounts = data.accounts;
          this.auth.isAuthenticated = false;
        }

        if (asIndoorUser) {
          this.setTechnicianMode(true);
        }

        if (headers['x-account-login-token']) {
          await this.fetchSession(headers['x-account-login-token']);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async accountLogin(accountId) {
      try {
        const { headers } = await authService.accountLogin(this.auth.userLoginToken, accountId);

        if (headers['x-account-login-token']) {
          await this.fetchSession(headers['x-account-login-token']);
          this.accountId = accountId;
        }
      } catch (e) {
        this.logoutAndClearData();
        return Promise.reject(e);
      }
    },
    async fetchSession(accessToken) {
      try {
        let resolvedAccessToken = accessToken || LocalStorageManager.getItem('AccessToken');

        if (!accessToken) {
          // Restoring session
          this.auth.isSessionLoading = true;
        }

        if (resolvedAccessToken) {
          const { data, headers } = await authService.fetchSession(resolvedAccessToken);
          LocalStorageManager.setItem('AccessToken', headers['x-account-login-token']);
          this.auth.userLoginToken = '';
          this.auth.isAccountMissing = false;
          this.user = data.user;
          this.account = data.account;
          this.accountId = data.account.id;
          this.auth.isAuthenticated = true;
          this.auth.authMethod = data.authMethod;
        }
      } catch (e) {
        this.logoutAndClearData();
        return Promise.reject(e);
      } finally {
        this.auth.isSessionLoading = false;
      }
    },
    logoutAndClearData() {
      LocalStorageManager.removeItem('AccessToken');
      this.auth.userLoginToken = '';
      this.auth.isAccountMissing = true;
      this.auth.isAuthenticated = false;
      this.user = {};
      this.account = {};
      this.auth.authMethod = '';
      this.initialDataLoaded = false;

      if (this.auth.authMethod === 'AZURE') {
        AzureConnector.logoutAzureUser();
        this.auth.azureRedirect.response = null;
        this.auth.azureRedirect.error = null;
        this.auth.azureRedirect.isBeingProcessed = false;
      }

      useZonesStore().$reset();
      useDevicesStore().$reset();
    },
    async updateActivity(isActive) {
      this.isActive = isActive;
    },
    async refreshToken() {
      try {
        let headersObj;

        if (this.auth.authMethod === 'AZURE') {
          await AzureConnector.refreshAzureToken(this.user.azureUsername);
          headersObj = await authService.refreshTokenWithAzureAuth(this.auth.azureRedirect.response.accessToken);
        } else if (this.auth.authMethod === 'GOOGLE') {
          headersObj = await authService.refreshTokenWithGoogleAuth(this.auth.azureRedirect.response.accessToken);
        } else {
          headersObj = await authService.refreshToken();
        }

        if (headersObj.headers['x-account-login-token']) {
          LocalStorageManager.setItem('AccessToken', headersObj.headers['x-account-login-token']);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    ping() {
      authService.ping();
    },
    setTechnicianMode(isActive) {
      LocalStorageManager.setItem('Technician', isActive);
      this.auth.isTechnicianStorageState = isActive;
      this.user.isIndoorUser = true;
    },
    loadCachedIsTechnician() {
      const storedIsTechnician = LocalStorageManager.getItem('Technician');
      if (storedIsTechnician !== undefined) {
        this.auth.isTechnicianStorageState = storedIsTechnician;
      }
    },
    async createAccount(params) {
      try {
        return await authService.createAccount(this.auth.userLoginToken, params);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    setAzureRedirectResponse(response) {
      this.auth.azureRedirect.isBeingProcessed = false;
      this.auth.azureRedirect.response = response;
    },
    setAzureRedirectError(error) {
      this.auth.azureRedirect.isBeingProcessed = false;
      this.auth.azureRedirect.error = error;
    },
    setAzureRedirectBeingProcessed() {
      this.auth.azureRedirect.isBeingProcessed = true;
    },
    setContextMission(mission) {
      this.mission = mission;
    },
    setContextEvent(event) {
      this.event = event;
    },
    setContextMissionTemplate(missionTemplate) {
      this.missionTemplate = missionTemplate;
    },
    patchContextMission(newVal = {}) {
      const keysToUpdate = new Set([...Object.keys(newVal), ...Object.keys(this.mission)]);
      keysToUpdate.forEach(key => {
        this.mission[key] = newVal[key] || this.mission[key];
      });
    },
    patchContextEvent(newVal) {
      const keysToUpdate = new Set([...Object.keys(newVal), ...Object.keys(this.event)]);
      keysToUpdate.forEach(key => {
        this.event[key] = newVal[key] || this.event[key];
      });
    },
    addEventToContextMission(event) {
      if (this.mission?.id === event.mission_id) {
        const existingEventIndex = this.mission?.events?.findIndex?.(savedEvent => savedEvent.id === event.id);
        if (existingEventIndex > -1) {
          this.mission.events[existingEventIndex] = event;
        } else {
          this.mission.events = this.mission.events || [];
          this.mission.events.push(event);
        }
      }
    }
  }
});
