<template>
  <div class="activity-scheduler-mission">
    <div class="header">Scheduled missions:</div>
    <BaseScheduler
      v-if="isDeviceSupportMissions"
      ref="scheduler"
      :days-hours-map="daysHoursMap"
      :minutes-interval="minutesInterval"
      :multiple-day-slots="false"
      :multiple-hour-slots="isTechnician"
      :popup-margin="20"
      :force-popup-alignment-top="true"
      @select-cells="onSelectCells"
      @popup-open="onPopupOpen"
      @popup-close="onPopupClose"
    >
      <template #popup>
        <div class="mission-popup-container">
          <div class="popup-header">Schedule Activity</div>
          <div class="popup-days-container">
            <span class="label">Day: </span>
            <span class="value">{{ selectedDaysRangeStr }}</span>
          </div>
          <div class="popup-hours-container">
            <span class="label">Hours: </span>
            <span class="value">{{ selectedHoursRangeStr }}</span>
          </div>
          <div v-if="isSelectedCellsError" class="popup-error-container">
            <span class="label">{{ selectedCellsErrorMessage }}</span>
          </div>
          <template v-if="!isSelectedCellsError">
            <div :class="['popup-type-container', 'actions']">
              <span class="label">Action: </span>
              <BaseRadioGroup
                v-model="selectedSchedulerType"
                class="radio-group"
                :options="availableActions"
                @select="updateDaysHoursMapWithSelectedCells"
              />
            </div>
            <template v-if="selectedSchedulerType === SCHEDULING_TYPES.ADD">
              <div class="popup-dropdown-container">
                <span class="label">Select mission to add:</span>
                <div class="dropdown-container">
                  <BaseDropdown
                    v-model="selectedCellsMissionIdToAdd"
                    :options="missionTemplatesOptions"
                    @selected="onSelectedCellsMissionIdToAddChange"
                  ></BaseDropdown>
                </div>
              </div>
              <div v-if="missionsIntervalOptions.length > 1" class="popup-dropdown-container">
                <span class="label">Select interval:</span>
                <div class="dropdown-container">
                  <BaseDropdown
                    v-model="selectedCellsMissionsInterval"
                    :options="missionsIntervalOptions"
                    @selected="onSelectedCellsMissionsIntervalChange"
                  ></BaseDropdown>
                </div>
              </div>
            </template>
            <template v-else-if="selectedSchedulerType === SCHEDULING_TYPES.REPLACE">
              <div class="popup-dropdown-container">
                <span class="label">Select mission to replace:</span>
                <div class="dropdown-container">
                  <BaseDropdown v-model="selectedCellsMissionIdToReplace" :options="missionTemplatesInSelectedCellsOptions"></BaseDropdown>
                </div>
              </div>
              <div class="popup-dropdown-container">
                <span class="label">Select mission to replace with:</span>
                <div class="dropdown-container">
                  <BaseDropdown
                    v-model="selectedCellsMissionIdToReplaceWith"
                    :options="missionTemplatesOptions"
                    @selected="onSelectedCellsMissionIdToReplaceWithChange"
                  ></BaseDropdown>
                </div>
              </div>
            </template>
            <template v-else-if="selectedSchedulerType === SCHEDULING_TYPES.CLEAR">
              <div class="popup-dropdown-container">
                <span class="label">Select mission to clear:</span>
                <div class="dropdown-container">
                  <BaseDropdown
                    v-model="selectedCellsMissionIdToClear"
                    :options="missionTemplatesInSelectedCellsOptions"
                    @selected="onSelectedCellsMissionIdToClearChange"
                  ></BaseDropdown>
                </div>
              </div>
            </template>
          </template>
          <div class="popup-buttons-container">
            <BaseButton v-if="!isSelectedCellsError" class="save-button" @click="onSaveClick">Apply</BaseButton>
          </div>
        </div>
      </template>
    </BaseScheduler>
    <div v-else class="not-support-message">This device does not support missions</div>
  </div>
</template>

<script>
import BaseScheduler from '../../components/base/BaseScheduler.vue';
import BaseButton from '../../components/base/BaseButton.vue';
import BaseRadioGroup from '../../components/base/BaseRadioGroup.vue';
import BaseDropdown from '../../components/base/BaseDropdown.vue';
import { DEVICE_PROPERTIES } from '../../consts/deviceConsts';
import { isDockingStation } from '../../utils/models/DeviceUtils';
import { useDevicesStore } from '../../store/DevicesStore';
import { useMissionTemplatesStore } from '../../store/MissionTemplatesStore';
import { useContextStore } from '../../store/ContextStore';
import { mapActions, mapState } from 'pinia';
import { getScheduledMissions } from '../../utils/ScheduleUtils.js';
import { DEVICES_TYPES } from '../../consts/deviceConsts';
import { getRandomColor } from '../../utils/ColorUtils';

const NO_PATROL_ID = -1;
const WRAP_UP_SLOTS = 1; // minimum 1
const MINUTES_INTERVAL = 15;
const ALL_MISSIONS_ID = 'ALL';
const ONE_TIME_INTERVAL_ID = 0;
const NUM_OF_AVAILABLE_COLORS = 18;

const SCHEDULING_TYPES = {
  ADD: 'ADD',
  REPLACE: 'REPLACE',
  CLEAR: 'CLEAR'
};

const MINUTES_INTERVAL_OPTIONS = [
  {
    id: ONE_TIME_INTERVAL_ID,
    label: 'One time'
  }
];
const firstOption = (WRAP_UP_SLOTS + 1) * MINUTES_INTERVAL;
for (let i = 0; i < 5; i++) {
  const minutes = firstOption + i * MINUTES_INTERVAL;
  MINUTES_INTERVAL_OPTIONS.push({
    id: minutes,
    label: `Every ${minutes} minutes`
  });
}

export default {
  name: 'ActivitySchedulerMission',
  components: {
    BaseButton,
    BaseRadioGroup,
    BaseDropdown,
    BaseScheduler
  },
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    zoneSchedule: {
      type: Object,
      required: true
    }
  },
  emits: ['patrolDropdownChange', 'save', 'popupOpen', 'selectCells'],
  data() {
    return {
      SCHEDULING_TYPES: SCHEDULING_TYPES,
      selectedCells: {},
      selectedDaysRangeStr: '',
      selectedHoursRangeStr: '',
      selectedIndexesToMerge: [],
      isSelectedCellsError: false,
      selectedCellsErrorMessage: '',
      selectedCellsMissionIdToAdd: '',
      selectedCellsMissionIdToClear: ALL_MISSIONS_ID,
      selectedCellsMissionIdToReplace: '',
      selectedCellsMissionIdToReplaceWith: '',
      selectedCellsMissionsInterval: (WRAP_UP_SLOTS + 1) * MINUTES_INTERVAL,
      daysHoursMap: {},
      minutesInterval: MINUTES_INTERVAL,
      isPopupOpen: false,
      selectedSchedulerType: SCHEDULING_TYPES.ADD,
      availableActions: [],
      missionIdsInSelectedCells: [],
      missionsIntervalOptions: [],
      selectedMissionTemplatesColors: {}
    };
  },
  computed: {
    ...mapState(useDevicesStore, ['contextZoneActiveDevices', 'getDeviceById']),
    ...mapState(useContextStore, ['isTechnician']),
    ...mapState(useMissionTemplatesStore, ['missionTemplates', 'missionTemplatesMap']),
    missionTemplatesOptions() {
      const device = this.getDeviceById(this.deviceId);
      const missionTemplatesOptions = this.missionTemplates
        .filter(missionTemplate => {
          return missionTemplate.compatible_device_types.includes(device.type_id);
        })
        .map(missionTemplate => {
          return { id: missionTemplate.id, label: missionTemplate.name };
        });
      if (device.type_id !== DEVICES_TYPES.SKYDIO) {
        const tilesDevices = this.contextZoneActiveDevices.filter(device => {
          return isDockingStation(device);
        });
        const skipTilesOptions = tilesDevices.map(device => {
          return {
            id: `SKIPTILE_${device.id}`,
            label: `Skip to ${device.name}`
          };
        });
        missionTemplatesOptions.push(...skipTilesOptions);
      }

      return missionTemplatesOptions;
    },
    missionTemplatesInSelectedCellsOptions() {
      const options = this.missionTemplatesOptions.filter(template => this.missionIdsInSelectedCells.includes(String(template.id)));
      options.unshift({ id: ALL_MISSIONS_ID, label: 'All patrols' });
      return options;
    },
    scheduledMissionsIndexes() {
      const indexes = [];
      getScheduledMissions(this.zoneSchedule).forEach(dayConfiguration => {
        dayConfiguration.actions.forEach(actionConfiguration => {
          const hour = actionConfiguration.time;
          const hourIndex = this.getHourIndexByLabel(hour);
          let missionType = 'GOTO';
          let missionId = actionConfiguration.data.config.missionTemplateId;
          const tileId = actionConfiguration?.data?.config?.tileId;
          if (!missionId && tileId) {
            missionType = 'SKIPTILE';
            missionId = `SKIPTILE_${tileId}`;
          }
          indexes.push({
            dayIndex: dayConfiguration.day,
            hourIndex: hourIndex,
            isMission: true,
            missionType,
            missionId
          });
        });
      });
      this.selectedIndexesToMerge.forEach(({ dayIndex, hourIndex, isMission, missionId, missionType }) => {
        const existingIndex = indexes.findIndex(indexObj => indexObj.dayIndex === dayIndex && indexObj.hourIndex === hourIndex);
        if (existingIndex !== -1) {
          indexes[existingIndex].isMission = isMission;
          indexes[existingIndex].missionId = missionId;
          indexes[existingIndex].missionType = missionType;
        } else {
          indexes.push({
            dayIndex,
            hourIndex,
            isMission,
            missionId,
            missionType
          });
        }
      });
      return indexes;
    },
    scheduledWrapUpTimeIndexes() {
      const indexes = [];
      this.scheduledMissionsIndexes.forEach(({ dayIndex, hourIndex, isMission, missionId, missionType }) => {
        if (isMission) {
          let wrapUpTime = this.getNextIndex({ dayIndex, hourIndex });
          indexes.push({ ...wrapUpTime, missionId, missionType });
          for (let i = 1; i < WRAP_UP_SLOTS; i++) {
            wrapUpTime = this.getNextIndex(wrapUpTime);
            indexes.push({ ...wrapUpTime, missionId, missionType });
          }
        }
      });
      return indexes;
    },
    isDeviceSupportMissions() {
      let missions = false;
      const device = this.getDeviceById(this.deviceId);
      if (device) {
        missions = device.props.includes(DEVICE_PROPERTIES.AUTONOMOUS_NAVIGATION);
      } else if (this.deviceId === -1) {
        missions = true;
      }
      return missions;
    }
  },
  watch: {
    selectedSchedulerType: {
      handler(newVal) {
        if (newVal === this.SCHEDULING_TYPES.CLEAR) {
          this.updateDaysHoursMapWithSelectedCells();
        }
      }
    }
  },
  created() {
    this.selectedCellsMissionIdToAdd = this.missionTemplates?.[0]?.id;
    this.buildDaysHoursMap();
  },
  mounted() {
    this.addMissionBackgroundColors();
  },
  methods: {
    addMissionBackgroundColors() {
      const missionColors = Array.from({ length: NUM_OF_AVAILABLE_COLORS }, (_, index) => getRandomColor(index + NUM_OF_AVAILABLE_COLORS)); // Just to start from prettier colors
      const wrapUpColors = missionColors.map(color => {
        let [_, hue, saturation, lightness, alpha] = color.match(/hsla\(([\d.]+),([\d.]+)%,([\d.]+)%,([\d.]+)\)/);
        lightness = Math.max(0, parseFloat(lightness) * 1.25);
        return `hsla(${hue},${saturation}%,${lightness}%,${alpha})`;
      });

      let style = document.createElement('style');
      ['mission', 'wrap-up'].forEach((type, i) => {
        const colors = i === 0 ? missionColors : wrapUpColors;
        colors.forEach((color, index) => {
          ['enabled', 'enabled.candidate', 'enabled.selected'].forEach(state => {
            style.innerHTML += `
          .${type}-${state}.${type}-${index + 1} {
            background-color: ${color} !important;
          }
        `;
          });
        });
      });

      document.head.appendChild(style);
    },
    resetSelectedIndexes() {
      this.selectedIndexesToMerge = [];
    },
    buildDaysHoursMap() {
      const daysHoursMap = {};
      this.scheduledMissionsIndexes.forEach(indexObj => {
        this.selectedMissionTemplatesColors[indexObj.missionId] =
          this.selectedMissionTemplatesColors[indexObj.missionId] ||
          (Object.keys(this.selectedMissionTemplatesColors).length + 1) % NUM_OF_AVAILABLE_COLORS;
        const index = this.selectedMissionTemplatesColors[indexObj.missionId];
        daysHoursMap[indexObj.dayIndex] = daysHoursMap[indexObj.dayIndex] || {};
        daysHoursMap[indexObj.dayIndex][indexObj.hourIndex] = {
          isMission: indexObj.isMission,
          missionId: indexObj.missionId,
          missionType: indexObj.missionType,
          classNames: indexObj.isMission ? ['mission-enabled', `mission-${index}`] : []
        };
      });
      this.scheduledWrapUpTimeIndexes.forEach(indexObj => {
        daysHoursMap[indexObj.dayIndex] = daysHoursMap[indexObj.dayIndex] || {};
        daysHoursMap[indexObj.dayIndex][indexObj.hourIndex] = {
          isWrapUp: true,
          classNames: ['wrap-up-enabled', `wrap-up-${this.selectedMissionTemplatesColors[indexObj.missionId]}`]
        };
      });
      this.daysHoursMap = daysHoursMap;
    },
    onSelectCells(selectedCells) {
      this.resetSelectedIndexes();
      this.buildDaysHoursMap();

      this.selectedCells = selectedCells;
      if (selectedCells.selectedIndexes.length > 0) {
        this.selectedDaysRangeStr =
          selectedCells.minDayLabel === selectedCells.maxDayLabel
            ? selectedCells.minDayLabel
            : `${selectedCells.minDayLabel} - ${selectedCells.maxDayLabel}`;
        this.selectedHoursRangeStr = `${selectedCells.minHourLabel} - ${selectedCells.maxHourLabel}`;

        this.calcMissionsInSelectedCells();
        this.calcAvailableIntervalInSelectedCells();
        this.calcAvailableActionsInSelectedCells();
        if (this.availableActions.length > 0) {
          this.isSelectedCellsError = false;
          this.selectedSchedulerType = this.availableActions[0].id;
        } else {
          this.isSelectedCellsError = true;
          this.selectedCellsErrorMessage = 'We cannot add any scheduled missions in this time range.';
        }

        this.updateDaysHoursMapWithSelectedCells();

        if (!this.isSelectedCellsError) {
          if (this.selectedSchedulerType === SCHEDULING_TYPES.ADD) {
            this.emitDropdownChange(this.selectedCellsMissionIdToAdd);
          }
          if (this.selectedSchedulerType === SCHEDULING_TYPES.REPLACE) {
            this.emitDropdownChange(this.selectedCellsMissionIdToReplace);
          }
        }
        this.$emit('selectCells', selectedCells);
      }
    },
    onPopupClose() {
      this.isPopupOpen = false;
      this.resetSelectedIndexes();
      this.buildDaysHoursMap();
      this.emitDropdownChange();
    },
    onPopupOpen() {
      this.isPopupOpen = true;
      this.$emit('popupOpen');
    },
    onSelectedCellsMissionsIntervalChange(missionsInterval) {
      this.selectedCellsMissionsInterval = missionsInterval.id;
      this.updateDaysHoursMapWithSelectedCells();
    },
    onSelectedCellsMissionIdToAddChange(missionOption) {
      this.selectedCellsMissionIdToAdd = missionOption.id;
      this.updateDaysHoursMapWithSelectedCells();
      this.emitDropdownChange(missionOption.id);
    },
    onSelectedCellsMissionIdToClearChange(missionOption) {
      this.selectedCellsMissionIdToClear = missionOption.id;
      this.updateDaysHoursMapWithSelectedCells();
      this.emitDropdownChange(missionOption.id);
    },
    onSelectedCellsMissionIdToReplaceWithChange(missionOption) {
      this.selectedCellsMissionIdToReplaceWith = missionOption.id;
      this.updateDaysHoursMapWithSelectedCells();
      this.emitDropdownChange(missionOption.id);
    },
    emitDropdownChange(missionId) {
      const isMission = missionId && missionId !== NO_PATROL_ID && missionId !== ALL_MISSIONS_ID;
      const selectedMissionId = isMission ? missionId : null;
      this.$emit('patrolDropdownChange', selectedMissionId);
    },
    async onSaveClick() {
      const actions = this.buildActions();
      this.$refs.scheduler.closeSelectedCellsPopup();
      this.isPopupOpen = false;
      this.$emit('save', { actions });
      this.emitDropdownChange();
    },
    closeSelectedCellsPopup() {
      if (this.isPopupOpen) {
        this.$refs.scheduler.closeSelectedCellsPopup();
        this.onPopupClose();
      }
    },
    updateDaysHoursMapWithSelectedCells() {
      this.resetSelectedIndexes();
      this.buildDaysHoursMap();

      switch (this.selectedSchedulerType) {
        case SCHEDULING_TYPES.ADD: {
          this.selectedIndexesToMerge = this.getSelectedIndexesToMergeForAdd();
          break;
        }
        case SCHEDULING_TYPES.REPLACE: {
          this.selectedIndexesToMerge = this.getSelectedIndexesToMergeForReplace();
          break;
        }
        case SCHEDULING_TYPES.CLEAR: {
          this.selectedIndexesToMerge = this.getSelectedIndexesToMergeForClear();
          break;
        }
        default: {
          break;
        }
      }

      this.buildDaysHoursMap();
    },
    getSelectedIndexesToMergeForAdd() {
      const indexesToMerge = [];
      const dayIndex = this.selectedCells.minDayIndex;
      let missionType = 'GOTO';
      if (this.selectedCellsMissionIdToAdd?.indexOf?.('SKIPTILE_') > -1) {
        missionType = 'SKIPTILE';
      }
      if (this.selectedCellsMissionsInterval === ONE_TIME_INTERVAL_ID) {
        indexesToMerge.push({
          dayIndex,
          hourIndex: this.selectedCells.minHourIndex,
          isMission: true,
          missionId: this.selectedCellsMissionIdToAdd,
          missionType
        });
      } else {
        const indexInterval = this.selectedCellsMissionsInterval / MINUTES_INTERVAL;
        for (let hourIndex = this.selectedCells.minHourIndex; hourIndex <= this.selectedCells.maxHourIndex; hourIndex = hourIndex + indexInterval) {
          indexesToMerge.push({
            dayIndex,
            hourIndex,
            isMission: true,
            missionId: this.selectedCellsMissionIdToAdd,
            missionType
          });
        }
      }
      return indexesToMerge;
    },
    getSelectedIndexesToMergeForReplace() {
      const indexesToMerge = [];
      let missionType = 'GOTO';
      if (this.selectedCellsMissionIdToReplaceWith.indexOf && this.selectedCellsMissionIdToReplaceWith.indexOf('SKIPTILE_') > -1) {
        missionType = 'SKIPTILE';
      }

      this.selectedCells.selectedIndexes.forEach(({ dayIndex, hourIndex }) => {
        if (this.isDayHourInMission(dayIndex, hourIndex)) {
          const currentMission = this.daysHoursMap[dayIndex][hourIndex].missionId;
          if (this.selectedCellsMissionIdToReplace === ALL_MISSIONS_ID || this.selectedCellsMissionIdToReplace === currentMission) {
            indexesToMerge.push({
              dayIndex,
              hourIndex,
              isMission: true,
              missionId: this.selectedCellsMissionIdToReplaceWith,
              missionType
            });
          }
        }
      });
      return indexesToMerge;
    },
    getSelectedIndexesToMergeForClear() {
      const indexesToMerge = [];
      let missionType = 'GOTO';
      this.selectedCells.selectedIndexes.forEach(({ dayIndex, hourIndex }) => {
        if (this.isDayHourInMission(dayIndex, hourIndex)) {
          const currentMission = this.daysHoursMap[dayIndex][hourIndex].missionId;
          if (this.selectedCellsMissionIdToClear === ALL_MISSIONS_ID || this.selectedCellsMissionIdToClear === currentMission) {
            indexesToMerge.push({
              dayIndex,
              hourIndex,
              isMission: false,
              missionId: NO_PATROL_ID,
              missionType
            });
          }
        }
      });
      return indexesToMerge;
    },
    buildActions() {
      const intervals = 60 / this.minutesInterval;
      const maxHourIndex = intervals * 24;
      const actions = [];
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        for (let hourIndex = 0; hourIndex < maxHourIndex; hourIndex++) {
          const isEnabled =
            this.daysHoursMap[dayIndex] && this.daysHoursMap[dayIndex][hourIndex] && this.daysHoursMap[dayIndex][hourIndex].isMission === true;
          if (isEnabled) {
            if (this.daysHoursMap[dayIndex][hourIndex].missionType === 'SKIPTILE') {
              const tileId = this.daysHoursMap[dayIndex][hourIndex].missionId.split('_')[1];
              actions.push({
                day: dayIndex,
                start: this.getHourLabelByIndex(hourIndex),
                tile: this.getDeviceById(Number(tileId))
              });
            } else {
              actions.push({
                day: dayIndex,
                start: this.getHourLabelByIndex(hourIndex),
                missionTemplate: this.missionTemplatesMap[this.daysHoursMap[dayIndex][hourIndex].missionId]
              });
            }
          }
        }
      }
      return actions;
    },
    isDayHourInMission(dayIndex, hourIndex) {
      return this.daysHoursMap[dayIndex] && this.daysHoursMap[dayIndex][hourIndex] && this.daysHoursMap[dayIndex][hourIndex].isMission;
    },
    isDayHourInWrapUp(dayIndex, hourIndex) {
      const isWrapUp = this.daysHoursMap[dayIndex] && this.daysHoursMap[dayIndex][hourIndex] && this.daysHoursMap[dayIndex][hourIndex].isWrapUp;

      let isBeforeMission = false;

      let nextIndex = this.getNextIndex({ dayIndex, hourIndex });
      let isNextIndexMission =
        this.daysHoursMap[nextIndex.dayIndex] &&
        this.daysHoursMap[nextIndex.dayIndex][nextIndex.hourIndex] &&
        this.daysHoursMap[nextIndex.dayIndex][nextIndex.hourIndex].isMission;
      isBeforeMission = isNextIndexMission;

      for (let i = 1; i < WRAP_UP_SLOTS; i++) {
        nextIndex = this.getNextIndex(nextIndex);
        isNextIndexMission =
          this.daysHoursMap[nextIndex.dayIndex] &&
          this.daysHoursMap[nextIndex.dayIndex][nextIndex.hourIndex] &&
          this.daysHoursMap[nextIndex.dayIndex][nextIndex.hourIndex].isMission;
        isBeforeMission = isBeforeMission || isNextIndexMission;
      }

      return isWrapUp || isBeforeMission;
    },
    getHourIndexByLabel(hourLabel) {
      const intervals = 60 / this.minutesInterval;
      const hour = Number(hourLabel.split(':')[0]);
      const minutes = Number(hourLabel.split(':')[1]);
      return hour * intervals + minutes / this.minutesInterval;
    },
    getHourLabelByIndex(hourIndex) {
      const intervals = 60 / this.minutesInterval;
      let hour = Math.floor(hourIndex / intervals);
      hour = hour < 10 ? `0${hour}` : hour;
      const minutes = hourIndex % intervals === 0 ? '00' : `${(hourIndex % intervals) * this.minutesInterval}`;
      return `${hour}:${minutes}`;
    },
    getNextIndex({ dayIndex, hourIndex }) {
      const hourIntervals = 60 / this.minutesInterval;
      const maxHourIndex = 24 * hourIntervals;
      const maxDayIndex = 7;
      let nextDayIndex = dayIndex;
      let nextHourIndex = hourIndex + 1;
      if (nextHourIndex === maxHourIndex) {
        nextDayIndex = nextDayIndex + 1;
        nextHourIndex = 0;
      }
      if (nextDayIndex === maxDayIndex) {
        nextDayIndex = 0;
      }
      return { dayIndex: nextDayIndex, hourIndex: nextHourIndex };
    },
    calcMissionsInSelectedCells() {
      const missionTemplates = {};
      if (this.selectedCells && this.selectedCells.selectedIndexes.length) {
        this.selectedCells.selectedIndexes.forEach(({ dayIndex, hourIndex }) => {
          if (this.isDayHourInMission(dayIndex, hourIndex)) {
            const missionId = this.daysHoursMap[dayIndex][hourIndex].missionId;
            missionTemplates[missionId] = true;
          }
        });
      }
      this.missionIdsInSelectedCells = Object.keys(missionTemplates);
    },
    calcAvailableIntervalInSelectedCells() {
      const availableIntervals = [];
      MINUTES_INTERVAL_OPTIONS.forEach(intervalOption => {
        const indexInterval = intervalOption.id / MINUTES_INTERVAL;
        const dayIndex = this.selectedCells.minDayIndex;
        let intervalOptionValid = true;
        if (intervalOption.id === ONE_TIME_INTERVAL_ID) {
          intervalOptionValid =
            !this.isDayHourInMission(dayIndex, this.selectedCells.minHourIndex) && !this.isDayHourInWrapUp(dayIndex, this.selectedCells.minHourIndex);
        } else {
          for (let hourIndex = this.selectedCells.minHourIndex; hourIndex <= this.selectedCells.maxHourIndex; hourIndex = hourIndex + indexInterval) {
            if (
              hourIndex > this.selectedCells.maxHourIndex ||
              this.isDayHourInMission(dayIndex, hourIndex) ||
              this.isDayHourInWrapUp(dayIndex, hourIndex)
            ) {
              intervalOptionValid = false;
              break;
            }
          }
          if (indexInterval > this.selectedCells.maxHourIndex - this.selectedCells.minHourIndex) {
            intervalOptionValid = false;
          }
        }
        if (intervalOptionValid) {
          availableIntervals.push(intervalOption);
        }
      });
      this.missionsIntervalOptions = availableIntervals;
    },
    calcAvailableActionsInSelectedCells() {
      const availableActions = [];
      const hasAvailableInterval = this.missionsIntervalOptions.length > 0;
      if (hasAvailableInterval && this.missionTemplatesOptions.length > 0) {
        availableActions.push({ id: SCHEDULING_TYPES.ADD, label: 'Add' });
        this.selectedCellsMissionsInterval = this.missionsIntervalOptions[0].id;
        this.selectedCellsMissionIdToAdd = this.missionTemplatesOptions[0].id;
      }
      if (this.missionIdsInSelectedCells.length > 0) {
        availableActions.push({
          id: SCHEDULING_TYPES.REPLACE,
          label: 'Replace'
        });
        availableActions.push({ id: SCHEDULING_TYPES.CLEAR, label: 'Clear' });
        this.selectedCellsMissionIdToClear = ALL_MISSIONS_ID;
        if (this.missionTemplatesInSelectedCellsOptions.length > 1) {
          this.selectedCellsMissionIdToReplace = this.missionTemplatesInSelectedCellsOptions[1].id;
          this.selectedCellsMissionIdToReplaceWith = this.missionTemplatesInSelectedCellsOptions[1].id;
        }
      }
      this.availableActions = availableActions;
    }
  }
};
</script>

<style lang="scss" scoped>
.activity-scheduler-mission {
  color: var(--secondaryTextColor);

  .header {
    color: var(--textColor);
    font-family: var(--font-family-secondary);
    font-size: 16px;
    margin-bottom: 15px;
  }
  .not-support-message {
    color: var(--textColor);
    font-family: var(--font-family-secondary);
    font-size: 14px;
  }
  :deep(.mission-popup-container) {
    min-width: 300px;

    .popup-header {
      font-family: var(--font-family-primary);
      text-transform: uppercase;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .popup-error-container,
    .popup-days-container,
    .popup-hours-container,
    .popup-type-container {
      margin-top: 5px;
      font-size: 14px;

      .label {
        font-weight: bold;
      }
    }
    .actions {
      display: flex;
      align-items: center;
    }

    .radio-group {
      display: inline-block;
      margin: 0 10px;

      .label {
        font-weight: normal;
        font-size: 14px;
      }
    }

    .popup-dropdown-container {
      margin-top: 10px;

      .label {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .popup-buttons-container {
      margin-top: 20px;

      .save-button {
        float: right;
      }
    }
  }
}
</style>
