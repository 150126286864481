import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "4 3 25 25",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", {
      d: "M28.999 16c-.005-1.425-.66-2.77-1.777-3.652 1.564-1.236 2.174-3.33 1.517-5.214C28.082 5.25 26.304 3.991 24.312 4H8.688c-1.992-.009-3.77 1.25-4.427 3.134-.657 1.884-.047 3.978 1.517 5.214-1.122.879-1.777 2.226-1.777 3.652 0 1.426.655 2.773 1.777 3.652-1.564 1.236-2.174 3.33-1.517 5.214.657 1.884 2.435 3.143 4.427 3.134h15.624c1.992.009 3.77-1.25 4.427-3.134.657-1.884.047-3.978-1.517-5.214 1.117-.882 1.772-2.227 1.777-3.652zM6.084 8.696c0-1.441 1.166-2.609 2.604-2.609h.521v1.044c0 .576.466 1.043 1.042 1.043.575 0 1.041-.467 1.041-1.043V6.087h2.083v1.044c0 .576.467 1.043 1.042 1.043s1.041-.467 1.041-1.043V6.087h8.854c1.438 0 2.604 1.168 2.604 2.609 0 1.44-1.166 2.608-2.604 2.608H8.688c-.69 0-1.353-.274-1.841-.764-.488-.489-.763-1.152-.763-1.844zm20.832 14.608c0 1.441-1.166 2.609-2.604 2.609H8.688c-1.438 0-2.604-1.168-2.604-2.609 0-1.44 1.166-2.608 2.604-2.608h.521v1.043c0 .576.466 1.044 1.042 1.044.575 0 1.041-.468 1.041-1.044v-1.043h2.083v1.043c0 .576.467 1.044 1.042 1.044s1.041-.468 1.041-1.044v-1.043h8.854c1.438 0 2.604 1.168 2.604 2.608zM8.688 18.61c-1.438 0-2.604-1.168-2.604-2.609 0-1.44 1.166-2.609 2.604-2.609h.521v1.044c0 .576.466 1.043 1.042 1.043.575 0 1.041-.467 1.041-1.043V13.39h2.083v1.044c0 .576.467 1.043 1.042 1.043s1.041-.467 1.041-1.043V13.39h8.854c1.438 0 2.604 1.168 2.604 2.609 0 1.44-1.166 2.609-2.604 2.609H8.688z",
      transform: "translate(-463 -2025) translate(463 2025)"
    }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }