import apiRequest from './apiRequest';

const INCLUDE_TO_ATTR_MAP = {
  predicted_path: ['predicted_path'],
  video: ['streamsData', 'object_detection'],
  path: ['path'],
  events: ['events'],
  pois: ['achieved_pois', 'requested_pois', 'template_id', 'template_revision', 'zone_id', 'state']
};

class MissionsService {
  async fetchMissionById({ id, include, attributes = [] }) {
    try {
      let attrs = [...attributes];
      if (include && include.length > 0) {
        include.forEach(field => (attrs = attrs.concat(INCLUDE_TO_ATTR_MAP[field])));
      }
      const params = {};

      if (attrs.length > 0) {
        params.attrs = attrs.join(',');
      }
      const response = await apiRequest({
        path: `/account/{accountId}/missions/${id}`,
        method: 'GET',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchMissionObjectDetectionById(missionId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/missions/${missionId}/object-detection`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchMissionObjectFrameDetectionsById(missionId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/missions/${missionId}/poi-detections`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async invokeMission(missionObj) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/missions`,
        method: 'POST',
        data: missionObj
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async patchMission(missionObj) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/missions/${missionObj.id}`,
        method: 'PATCH',
        data: missionObj
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async requestFullVideoDownload(missionId, cameraType, sourceType) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/missions/${missionId}/prepare-video-for-download`,
        method: 'POST',
        params: {
          cameraType,
          sourceType
        }
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async rerunMissionVideoDetection(missionId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/missions/${missionId}/run-video-detection`,
        method: 'POST',
        data: {
          create_events: false // First OD execution already created the events, this is to avoid duplication
        }
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async rerunMissionFrameDetection(missionId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/missions/${missionId}/run-frame-detection`,
        method: 'POST'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async customFrameDetection(missionId, { timestamp, detection_settings, location, source }) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/missions/${missionId}/run-on-demand-frame-detection`,
        method: 'POST',
        data: {
          timestamp,
          detection_settings,
          location,
          source
        }
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async abortMissionAndGoHome(missionId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/missions/${missionId}/abort-and-go-home`,
        method: 'POST'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchMissionSnapshot(missionId, timestamp) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/missions/${missionId}/snapshot`,
        method: 'GET',
        params: {
          timestamp
        }
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new MissionsService();
