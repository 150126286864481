import dayjs from 'dayjs';
import { TIME_FILTERS } from '../consts/appConsts';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

export const get24TimeFromSeconds = seconds => {
  // Format amount of time to HH:mm
  if (!isNaN(seconds)) {
    const hours = Math.floor(seconds / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    return `${hours}:${(minutes + '').padStart(2, 0)}`;
  }
  return '';
};

export const get24Time = date => {
  return dayjs(date).format('HH:mm');
};

export const getFullTime = date => {
  return dayjs(date).format('HH:mm:ss');
};

export const getTimeStamp = date => {
  return dayjs(date).format('DD/MM/YYYY HH:mm:ss');
};

export const getDate = date => {
  return dayjs(date).format('DD MMM YYYY');
};

export const getDayDate = date => {
  return dayjs(date).format('dddd DD MMM YYYY');
};

export const getDayTime = date => {
  return dayjs(date).format('dddd HH:mm');
};

export const getDateTime = date => {
  return dayjs(date).format('DD/MM/YYYY HH:mm');
};

export const getDatesMMSSDiffStr = (date1, date2) => {
  let diffInSeconds = dayjs(date1).diff(date2, 'second');
  const minutes = Math.floor(diffInSeconds / 60);
  const seconds = diffInSeconds - minutes * 60;
  return `${`${minutes}`.padStart(2, 0)}:${`${seconds}`.padStart(2, 0)}`;
};

export const getTimeDifferenceFormattedString = (date1, date2) => {
  const r = {};
  const s = {
    hour: 3600,
    minute: 60,
    second: 1
  };
  let d = Math.floor(Math.abs(date1 - date2) / 1000);
  Object.keys(s).forEach(key => {
    r[key] = Math.floor(d / s[key]);
    d -= r[key] * s[key];
  });
  const { hour, minute, second } = r;
  const hourStr = hour != 0 ? (hour <= 9 ? hour : hour) + (hour == 1 ? ' hour' : ' hours') : '';
  const minuteStr = minute != 0 ? (minute <= 9 ? minute : minute) + (minute == 1 ? ' minute ' : ' minutes') : '';
  const secondStr = second != 0 ? (second <= 9 ? second : second) + (second == 1 ? ' second ' : ' seconds') : '';
  return `${hourStr} ${minuteStr} ${secondStr}`.trim() || '0 seconds';
};

export const roundToNearestXMinutes = (date, x) => {
  const start = dayjs(date);
  const diff = x - (start.minute() % x);
  const roundedDate = start.add(diff, 'minute').toDate();
  return roundedDate;
};

export const isDateInTimeRange = (date, timeRange, data) => {
  let result = false;
  const _date = dayjs(date);

  switch (timeRange) {
    case TIME_FILTERS.LAST_24_HOURS: {
      const date24HoursAgo = dayjs().subtract(24, 'hour').startOf('hour');
      result = _date.isAfter(date24HoursAgo);
      break;
    }
    case TIME_FILTERS.LAST_7_DAYS: {
      const date7DaysAgo = dayjs().subtract(7, 'days').startOf('day');
      result = _date.isAfter(date7DaysAgo);
      break;
    }
    case TIME_FILTERS.LAST_MONTH: {
      const date1MonthAgo = dayjs().subtract(1, 'month').startOf('day');
      result = _date.isAfter(date1MonthAgo);
      break;
    }
    case TIME_FILTERS.CUSTOM: {
      const fromTimestamp = dayjs(data.from).startOf('day');
      const toTimestamp = dayjs(data.to).endOf('day');
      result = _date.isBetween(fromTimestamp, toTimestamp);
      break;
    }
    default:
      break;
  }

  return result;
};
