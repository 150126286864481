// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const FETCH_STATE = {
  INITIAL: '',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error'
};

export const SORT_MODE = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const TIME_FILTERS = {
  LAST_24_HOURS: 'LAST_24_HOURS',
  LAST_7_DAYS: 'LAST_7_DAYS',
  LAST_MONTH: 'LAST_1_MONTHS',
  CUSTOM: 'CUSTOM'
};

export const SNACKBAR_DURATION = 6000;

export const SNACKBAR_TYPE = {
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error'
};

export const BREAK_POINTS = {
  PHONE: 'phone',
  TABLET: 'tablet',
  LAPTOP: 'laptop',
  DESKTOP: 'desktop'
};

export const ERROR_MESSAGES = {
  GENERIC_500: `Something went wrong. Please try again later`,
  GENERIC_401: 'You are unauthorized to access the requested page',
  GENERIC_CONTACT_ADMIN: 'Something went wrong. Please contact your account admin',
  NETWORK_ERROR: 'Network error, please try again later.',
  REQUIRED_FIELDS: 'Please fill in the required fields.',
  REQUIRED_FIELD_EMAIL: 'Please fill in your email.',
  SESSION_EXPIRED: 'Your session has expired. Please log in again',
  SESSION_RESTORE_FAILED: 'Unable to restore session. Please login again',
  INVALID_EMAIL: 'Please make sure to enter valid email',
  UNABLE_TO_DISPLAY_PAGE: 'Unable to display the requested page',
  INVALID_INVITE_LINK: 'Invalid link. Please make sure to enter the link you got in the e-mail.',
  GENERIC_DEVICE_ERROR: 'System Error'
};

export const FISHEYE_MIN_ZOOM_CORRECTION = 1.3;
