<template>
  <div class="events-view">
    <EventsLog
      ref="eventsLog"
      class="events-list"
      :selected="focusedEvent"
      @filter-popup-location="onFilterPopupLocationChange"
      @select="onEventSelect"
    />
    <div class="map-section">
      <div v-if="focusedEvent" class="selected-event-chip">
        <BaseLoader v-show="isFetchingAdditionalData" class="loader" />
        Displaying:
        {{ focusedEvent.name || `${focusedEvent.event_type.toLowerCase()} event` }}
        <IconClose @click="onSelectionClose" />
      </div>
      <MapCanvas
        v-if="zoneId"
        class="map"
        :indicators="indicators"
        :is-quick-action-menu-enabled="true"
        :indicators-draw-order="MISSION_INDICATORS_DRAW_ORDER"
      />
    </div>
  </div>
</template>

<script>
import MapCanvas from '../../components/theMap/MapCanvas.vue';
import EventsLog from './EventsLog.vue';
import BaseLoader from '../../components/base/BaseLoader.vue';
import {
  hasStarted,
  hasMissionEnded,
  getPathFilteredByPointsDistance,
  getColorState,
  isUnknown,
  getLocationDisplayConfigForMap
} from '../../utils/models/MissionUtils';
import { getDisplayConfigForMap } from '../../utils/models/EventUtils';
import { EVENT_TYPE } from '../../consts/eventConsts';
import IconClose from '../../components/icons/IconClose.svg?component';
import IconDrone from '../../components/icons/IconDrone.svg?component';
import { getStyleVar } from '../../utils/StyleUtils';
import { useContextStore } from '../../store/ContextStore';
import { useEventListStore } from '../../store/EventListStore';
import { useDevicesStore } from '../../store/DevicesStore';
import { useAreasStore } from '../../store/AreasStore';
import { mapActions, mapState } from 'pinia';
import missionsService from '../../services/api/missionsService';
import { SNACKBAR_TYPE } from '../../consts/appConsts';
import { PAGES } from '../../router';
import { ERROR_MESSAGES } from '../../consts/appConsts';
const MISSION_INDICATORS_DRAW_ORDER = ['paths', 'dots', 'groups', 'locations', 'icons', 'areas'];

export default {
  name: 'EventsView',
  components: {
    MapCanvas,
    EventsLog,
    IconClose,
    BaseLoader
  },
  // Props are from the router
  props: {
    zoneId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      focusedEvent: null,
      eventListFilterPopupLocation: null,
      isFetchingAdditionalData: false,
      MISSION_INDICATORS_DRAW_ORDER
    };
  },
  computed: {
    ...mapState(useContextStore, ['zone', 'isTechnician']),
    ...mapState(useDevicesStore, ['getDeviceById']),
    ...mapState(useEventListStore, ['eventListItems', 'eventListOffset', 'listFilters', 'getItemById']),
    ...mapState(useAreasStore, ['activeNoFlightAreas']),
    listFiltersLocation() {
      return this.listFilters && this.listFilters.location;
    },
    indicators() {
      return {
        contextId: this.focusedEvent?.id,
        paths: [{ path: this.mapPathFormatter(this.focusedEvent) }],
        icons: this.mapIconsFormatter(this.focusedEvent),
        locations: [...this.mapLocationsFormatter(), ...this.mapLocationFilteredByFormatter()],
        areas: this.activeNoFlightAreas
      };
    }
  },
  watch: {
    eventListItems() {
      if (this.focusedEvent && !this.getItemById(this.focusedEvent.id)) {
        this.focusedEvent = null;
      }
    },
    zoneId: {
      immediate: true,
      handler() {
        if (this.zoneId) {
          this.fetchEventList().catch(err => {
            console.error(err);
            this.showSnackbar({
              message: ERROR_MESSAGES.UNABLE_TO_DISPLAY_PAGE,
              type: SNACKBAR_TYPE.ERROR
            });
          });
        }
      }
    }
  },
  methods: {
    ...mapActions(useContextStore, ['showSnackbar']),
    ...mapActions(useEventListStore, ['fetchEventList', 'fetchMissionById']),
    onFilterPopupLocationChange(value) {
      this.eventListFilterPopupLocation = value;
    },
    async onEventSelect(event) {
      if (event.event_type === EVENT_TYPE.MISSION) {
        try {
          this.isFetchingAdditionalData = true;
          const attributes = [];
          if (this.isTechnician) {
            attributes.push('poi_detection_settings');
          }
          const mission = await missionsService.fetchMissionById({ id: event.id, include: ['path', 'events', 'pois'], attributes });
          this.focusedEvent = { ...event, ...mission };
        } catch (error) {
          this.focusedEvent = event;
          console.error(error);
          this.showSnackbar({
            message: 'Failed to load mission path',
            type: SNACKBAR_TYPE.ERROR
          });
        } finally {
          this.isFetchingAdditionalData = false;
        }
      } else {
        this.focusedEvent = event;
      }
    },
    onSelectionClose() {
      this.focusedEvent = null;
    },
    mapIconsFormatter(event) {
      const icons = [];
      if (event) {
        const mission = event?.event_type === EVENT_TYPE.MISSION ? event : null;
        if (mission) {
          if (hasStarted(mission) && !hasMissionEnded(mission) && !isUnknown(mission)) {
            const device = this.getDeviceById(mission.device_id);
            if (device) {
              icons.push(this.getDeviceIcon(device));
            }
          }
          if (mission.events && mission.events.length > 0) {
            mission.events.forEach(missionEvent => {
              if (missionEvent.location) {
                icons.push(this.getEventIcon(missionEvent));
              }
            });
          }
        } else if (event.location) {
          icons.push(this.getEventIcon(event));
        }
      } else {
        this.eventListItems.forEach(event => {
          if (event.event_type !== EVENT_TYPE.MISSION && event.location) {
            icons.push(this.getEventIcon(event));
          }
        });
      }

      return icons;
    },
    mapPathFormatter(event) {
      let formattedPath = [];
      const mission = event?.event_type === EVENT_TYPE.MISSION ? event : null;
      if (mission?.path?.length > 0) {
        const filteredPath = getPathFilteredByPointsDistance(mission);
        formattedPath = filteredPath.map(point => ({
          x: point.data.x,
          y: point.data.y
        }));
        if (hasMissionEnded(mission) && formattedPath.length > 0) {
          formattedPath[formattedPath.length - 1].color = getColorState(mission);
        }
      }

      return formattedPath;
    },
    mapLocationFilteredByFormatter() {
      const location = this.eventListFilterPopupLocation !== null ? this.eventListFilterPopupLocation : this.listFiltersLocation;
      if (location && location !== '') {
        return [
          {
            x: location.split('_')[0],
            y: location.split('_')[1],
            showFilterMark: true,
            color: getStyleVar('--highlightColor')
          }
        ];
      }
      return [];
    },
    mapLocationsFormatter() {
      if (this.focusedEvent?.event_type === EVENT_TYPE.MISSION) {
        return getLocationDisplayConfigForMap(this.focusedEvent, this.isTechnician);
      }
      return [];
    },
    getDeviceIcon(device) {
      return {
        x: device.location.x,
        y: device.location.y,
        icon: IconDrone,
        text: device.name,
        isDisabled: false,
        isStatic: false,
        customData: {
          heading: device.location.h
        },
        callback: () => {
          this.$router.push({
            name: PAGES.DEVICE,
            params: {
              deviceId: device.id
            }
          });
        }
      };
    },
    getEventIcon(event) {
      return getDisplayConfigForMap({ event, clickable: true });
    }
  }
};
</script>

<style scoped lang="scss">
.events-view {
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  .events-list {
    height: 100%;
    flex-basis: 35rem;
    max-width: 40%;
    min-width: 20rem;
  }

  .map-section {
    display: flex;
    flex-grow: 1;
    height: 100%;
    width: auto;

    .selected-event-chip {
      position: absolute;
      background: var(--primaryColorDarkShade1);
      opacity: 0.7;
      color: var(--textColor);
      padding: 0.3rem 0.5rem 0.3rem 1rem;
      margin: 0.5rem 0;
      font-family: var(--font-family-secondary);
      display: flex;
      column-gap: 0.7rem;
      align-items: baseline;
      border: 2px solid var(--primaryColor);
      border-left: 0;
      z-index: 1;

      .loader {
        width: 0.8rem;
        height: 0.8rem;
      }

      svg {
        width: 0.8rem;
        height: 0.8rem;
        color: var(--highlightColor);
        transition: 0.3s;
        cursor: pointer;
      }

      &:hover {
        opacity: 1;

        svg {
          transform: scale(1.2);
        }
      }
    }
    .map {
      flex-grow: 1;
    }
  }
}
</style>
