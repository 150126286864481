<template>
  <div v-if="activeMissions.length > 0" :class="['active-mission-menu', { 'is-open': isOpen }]" @mouseleave="onMouseLeave" @mouseenter="onMouseEnter">
    <div class="menu-header">
      <div class="menu-toggle-title" @click="isOpen = !isOpen">
        Active Missions
        <IconCollapse :class="['icon', { open: isOpen }]" />
      </div>
      <Transition name="fade-labels">
        <div v-show="!isOpen" class="missions-labels">
          {{ missionsNamesStr }}
        </div>
      </Transition>
    </div>
    <div class="menu-body">
      <Transition name="slide-up">
        <div v-show="isOpen" class="missions-list">
          <GoToMissionActionBar v-for="activeMission in activeMissions" :key="activeMission.id" :active-mission="activeMission" :show-name="true" />
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import IconCollapse from '../../components/icons/IconCollapse.svg?component';
import GoToMissionActionBar from './GoToMissionActionBar.vue';
import { mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore';

const ACTIVE_MISSION_TIMEOUT = 8 * 60 * 1000;
const MOUSE_LEAVE_TIMEOUT_DURATION = 20 * 1000;
const TIMER_UPDATE_RATE = 1000;

import { useEventListStore } from '../../store/EventListStore';

export default {
  name: 'ActiveMissionsMenu',
  components: {
    GoToMissionActionBar,
    IconCollapse
  },
  data() {
    return {
      isOpen: false,
      mouseLeaveTimeoutId: null,
      nowIntervalId: null,
      now: new Date()
    };
  },
  computed: {
    ...mapStores(useContextStore, useEventListStore),
    activeMissions() {
      const activeMissions = this.eventListStore.activeMissions.filter(mission => {
        const relevantTimestamp = mission.start_timestamp || mission.requested_timestamp;
        const executionTimeInMS = Math.round(Math.abs(this.now.getTime() - relevantTimestamp));
        return executionTimeInMS < ACTIVE_MISSION_TIMEOUT;
      });
      return activeMissions;
    },
    missionsNamesStr() {
      return this.activeMissions.map(activeMission => activeMission.name).join(', ');
    }
  },
  created() {
    this.nowIntervalId = setInterval(() => {
      this.now = new Date();
    }, TIMER_UPDATE_RATE);
  },
  unmounted() {
    if (this.nowIntervalId) {
      clearInterval(this.nowIntervalId);
    }
  },
  methods: {
    onMouseLeave() {
      this.clearMouseLeaveTimeout();
      this.mouseLeaveTimeoutId = setTimeout(() => {
        this.isOpen = false;
        this.clearMouseLeaveTimeout();
      }, MOUSE_LEAVE_TIMEOUT_DURATION);
    },
    onMouseEnter() {
      this.clearMouseLeaveTimeout();
    },
    clearMouseLeaveTimeout() {
      if (this.mouseLeaveTimeoutId) {
        clearTimeout(this.mouseLeaveTimeoutId);
        this.mouseLeaveTimeoutId = null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.active-mission-menu {
  position: fixed;
  bottom: 0;
  margin-left: 1rem;
  transition: all 0.8s ease-in-out;
  z-index: 2;
  width: 40rem;

  .menu-header {
    display: flex;
    font-weight: lighter;

    .menu-toggle-title {
      background: var(--secondaryColor);
      display: flex;
      align-items: center;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      font-family: var(--font-family-primary);
      padding: 0.7rem 30px;
      column-gap: 12px;
      font-size: 1.5rem;
      cursor: pointer;
      flex-shrink: 0;

      .icon {
        width: 1.7rem;
        height: 1.7rem;
        color: var(--highlightColor);

        &.open {
          padding-top: 0.25rem;
        }
      }
    }

    .missions-labels {
      font-family: var(--font-family-secondary);
      padding: 15px;
      font-size: 1.2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .fade-labels-enter-active {
      transition: all 0.5s ease-out;
    }

    .fade-labels-leave-active {
      transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .fade-labels-enter-from,
    .fade-labels-leave-to {
      transform: translateX(20px);
      opacity: 0;
    }
  }

  .menu-body {
    display: flex;
    flex-direction: column;
    font-weight: lighter;
    border-top-right-radius: 8px;
    background: var(--secondaryColor);
    overflow-y: auto;
    transition: max-height 0.4s ease-in-out;
    min-height: 10px;
    max-height: 10px;

    .slide-up-enter-active,
    .slide-up-leave-active {
      transition: all 0.2s ease-in-out;
    }

    .slide-up-enter-from,
    .slide-up-leave-to {
      transform: scaleY(0);
      opacity: 0;
    }
  }

  &.is-open {
    .menu-header {
      .missions-labels {
        background-color: themed('color-active-mission-menu-header');
        padding: 10px 0 10px 4px;
      }
    }

    .menu-body {
      background: var(--secondaryColorShade1);
      max-height: 70vh;
    }
  }
}
</style>
