import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", { transform: "translate(-468 -2194) translate(40 1620) translate(428 574)" }, [
      _createElementVNode("circle", {
        cx: "12",
        cy: "12",
        r: "12",
        fill: "#4AC9D3"
      }),
      _createElementVNode("g", {
        fill: "#FFF",
        "fill-rule": "nonzero"
      }, [
        _createElementVNode("path", {
          d: "M13.33 5.416C13.265 2.986 11.267 1 8.82 1c-1.706 0-3.252.984-4.019 2.49-1.116-.044-2.063.825-2.13 1.925C1.678 5.886 1 6.89 1 8c0 1.583 1.315 2.898 2.898 2.898h1.23v2.461h-.895c-.17-.476-.62-.82-1.155-.82-.678 0-1.23.552-1.23 1.23 0 .679.552 1.231 1.23 1.231.534 0 .985-.344 1.155-.82H5.95v-3.282h1.64v1.717c-.476.17-.82.62-.82 1.155C6.77 14.448 7.323 15 8 15s1.23-.552 1.23-1.23c0-.535-.343-.985-.82-1.155v-1.717h1.64v3.282h1.717c.17.476.62.82 1.155.82.678 0 1.23-.552 1.23-1.23 0-.679-.479-1.23-1.23-1.23-.534 0-.985.343-1.155.82h-.896v-2.462h1.23C13.686 10.898 15 9.583 15 8c0-1.11-.676-2.113-1.67-2.584zm-4.978 4c-.156-.254-.548-.254-.704 0l-.699-.429c.453-.74 1.649-.74 2.102 0l-.7.429zM9.75 8.56C9.374 7.943 8.719 7.575 8 7.575c-.72 0-1.374.368-1.75.985l-.7-.428C6.075 7.27 6.992 6.755 8 6.755c1.007 0 1.924.515 2.451 1.377l-.7.428zm1.4-.856C10.472 6.596 9.295 5.934 8 5.934c-1.296 0-2.473.662-3.15 1.77l-.7-.428C4.977 5.922 6.416 5.114 8 5.114s3.023.808 3.85 2.162l-.7.428z",
          transform: "translate(4 4)"
        })
      ])
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }