<template>
  <button class="cancel-button" @click="onClick">
    <slot> Cancel </slot>
  </button>
</template>

<script>
export default {
  name: 'BaseCancelButton',
  emits: ['click'],
  methods: {
    onClick(ev) {
      this.$emit('click', ev);
    }
  }
};
</script>

<style lang="scss" scoped>
.cancel-button {
  font-size: 1rem;
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  border: 0;
  outline: none;
  transition: transform 0.5s;
  cursor: pointer;
  color: var(--disabledColor);
  background: transparent;

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(1.1);
    transform: translateY(3px);
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 2px var(--secondaryOverlayShade2);
  }
}
</style>
