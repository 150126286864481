const FAHRENHEIT_TO_CELSIUS_FACTOR = 1.8;
const CELSIUS_TO_FAHRENHEIT_FACTOR = 5 / 9;

export const convertCelsiusToFahrenheit = celsius => {
  const fahrenheit = celsius * FAHRENHEIT_TO_CELSIUS_FACTOR + 32;
  return parseFloat(fahrenheit.toFixed(0));
};

export const convertFahrenheitToCelsius = fahrenheit => {
  const celsius = (fahrenheit - 32) * CELSIUS_TO_FAHRENHEIT_FACTOR;
  return parseFloat(celsius.toFixed(0));
};
