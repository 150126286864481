import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 18 18",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M2 0C.907 0 0 .907 0 2v6h2V2h6V0H2zm8 0v2h6v6h2V2c0-1.093-.907-2-2-2h-6zm2.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-5 5.332L4 14h10l-2.5-3.332-1.5 2-2.5-3.336zM0 10v6c0 1.093.907 2 2 2h6v-2H2v-6H0zm16 0v6h-6v2h6c1.093 0 2-.907 2-2v-6h-2z" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }