import { defineStore } from 'pinia';
import { useContextStore } from './ContextStore';
import devicesService from '../services/api/devicesService';
import { FETCH_STATE } from '../consts/appConsts';
import { DEVICES_TYPES, DEVICE_PROPERTIES, DEVICE_STATE } from '../consts/deviceConsts';

export const useDevicesStore = defineStore('devices', {
  state() {
    return {
      devicesFetchState: FETCH_STATE.INITIAL,
      devicesMap: new Map(),
      deviceTypesMap: new Map()
    };
  },
  getters: {
    wereDevicesFetched() {
      return this.devicesFetchState === FETCH_STATE.SUCCESS || this.devicesFetchState === FETCH_STATE.ERROR;
    },
    devices() {
      return Array.from(this.devicesMap.values());
    },
    activeDevices() {
      return this.devices.filter(device => device.enabled);
    },
    activeTiles() {
      return this.activeDevices.filter(device => device?.props?.includes?.(DEVICE_PROPERTIES.DOCKING_STATION));
    },
    activeIotControllers() {
      return this.activeDevices.filter(device => device?.props?.includes?.(DEVICE_PROPERTIES.IOT_CONTROLLER));
    },
    deviceTypes() {
      return Array.from(this.deviceTypesMap.values());
    },
    contextZoneActiveIotControllers() {
      return this.activeIotControllers.filter(device => device.zone_id === useContextStore().zoneId);
    },
    contextZoneActiveDevices() {
      return this.activeDevices.filter(device => device.zone_id === useContextStore().zoneId);
    },
    contextZoneDronesForMission() {
      const contextZone = useContextStore().zone;
      if (contextZone.halted) {
        return [];
      }

      return this.activeDevices.filter(
        device =>
          device.zone_id === contextZone.id &&
          (device.type_id === DEVICES_TYPES.TANDO || device.type_id === DEVICES_TYPES.SKYDIO) &&
          (device.state === DEVICE_STATE.IDLE || device.state === DEVICE_STATE.IN_MISSION) &&
          device.is_connected &&
          !device.halted
      );
    },
    contextZoneActiveTiles() {
      return this.activeTiles.filter(device => device.zone_id === useContextStore().zoneId);
    },
    getActiveDevicesByZone() {
      return zoneId => {
        this.activeDevices.filter(device => device.zone_id === zoneId);
      };
    },
    getDeviceById() {
      return deviceId => this.devicesMap.get(deviceId);
    },
    isThereADeviceErrorInContextZone() {
      return this.activeDevices.some(
        device =>
          device.zone_id === useContextStore().zoneId && [DEVICE_STATE.ERROR, DEVICE_STATE.CRITICAL_ERROR, DEVICE_STATE.DOWN].includes(device.state)
      );
    },
    isThereADisconnectedDeviceInContextZone() {
      return this.activeDevices.some(
        device =>
          device.zone_id === useContextStore().zoneId &&
          !device.is_connected &&
          !(device.props.includes(DEVICE_PROPERTIES.DOCKING_STATION) || device.props.includes(DEVICE_PROPERTIES.IOT_CONTROLLER))
      );
    }
  },
  actions: {
    setDevice(device) {
      this.devicesMap.set(device.id, { ...this.devicesMap.get(device.id), ...device });
    },
    async fetchDevices(params) {
      try {
        if (this.devicesFetchState === FETCH_STATE.INITIAL) {
          this.devicesFetchState = FETCH_STATE.LOADING;
        }

        const [deviceTypes, devicesArr] = await Promise.all([devicesService.fetchDeviceTypes(), devicesService.fetchDevices(params)]);

        deviceTypes.forEach(deviceType => {
          this.deviceTypesMap.set(deviceType.id, deviceType);
        });

        devicesArr.forEach(device => {
          // Concat the device's custom props with the type properties
          device.type_name = this.deviceTypesMap.get(device.type_id).name;
          const type_properties = this.deviceTypesMap.get(device.type_id).deviceTypeProperties.map(property => property.propertyDetails.name);
          device.props = [...type_properties, ...(device.properties || [])];

          // Add device to the map
          this.setDevice(device);
        });

        this.devicesFetchState = FETCH_STATE.SUCCESS;
        return this.devices;
      } catch (e) {
        this.devicesFetchState = FETCH_STATE.ERROR;
        return Promise.reject(e);
      }
    },
    async isDeviceStreamsExist(deviceId) {
      try {
        const streamsExist = await devicesService.isDeviceStreamsExist(deviceId);
        if (!streamsExist.frontCamStreamURL || !streamsExist.rearCamStreamURL) {
          this.devicesMap.get(deviceId).streamsData = null;
          return false;
        }
        return true;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    clearDeviceActiveNodeList(deviceId) {
      try {
        delete this.devicesMap.get(deviceId).activeNodeList;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    clearAvailableTilesList(deviceId) {
      try {
        delete this.devicesMap.get(deviceId).availableTilesToReset;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchDeviceById(deviceId) {
      try {
        const device = await devicesService.fetchDeviceById(deviceId);
        device.type_name = this.deviceTypesMap.get(device.type_id).name;
        const type_properties = this.deviceTypesMap.get(device.type_id).deviceTypeProperties.map(property => property.propertyDetails.name);
        device.props = [...type_properties, ...(device.properties || [])];
        this.setDevice(device);
        return device;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async updateDevice(device) {
      try {
        const existingDevice = this.getDeviceById(device.id);
        const paramsToSave = { id: device.id };
        Object.keys(device).forEach(key => {
          if (JSON.stringify(existingDevice[key]) !== JSON.stringify(device[key])) {
            paramsToSave[key] = device[key];
          }
        });

        const data = await devicesService.updateDevice(paramsToSave);
        this.setDevice(data);
        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchDeviceStreams(deviceId) {
      try {
        const existingDevice = this.getDeviceById(deviceId);
        if (!existingDevice.streamsData || Object.keys(existingDevice.streamsData).length == 0 || existingDevice.streamsData.ttl - Date.now() <= 0) {
          const streams = await devicesService.fetchDeviceStreams(deviceId);
          this.devicesMap.get(deviceId).streamsData = streams;
        }
      } catch (e) {
        if (e.status !== 404) {
          console.error(e);
        }
      }
    },
    async fetchDeviceIotIntegration(deviceId) {
      try {
        const existingDevice = this.getDeviceById(deviceId);
        if (!existingDevice.iotIntegration) {
          const data = await devicesService.fetchDeviceIotIntegration(deviceId);
          this.devicesMap.get(deviceId).iotIntegration = data;
        }
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async updateDeviceIotIntegration(iotIntegrationObj) {
      try {
        const deviceId = iotIntegrationObj.device_id;
        const existingDevice = this.getDeviceById(deviceId);
        let data;

        if (!existingDevice.iotIntegration) {
          data = await devicesService.createDeviceIotIntegration(iotIntegrationObj);
        } else {
          data = await devicesService.updateDeviceIotIntegration(iotIntegrationObj);
        }

        this.devicesMap.get(deviceId).iotIntegration = data;
      } catch (e) {
        return Promise.reject(e);
      }
    }
  }
});
