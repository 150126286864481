<template>
  <div v-if="groupSize > 0" class="events-log-group">
    <div class="group-title">
      <div class="group-title-left">
        {{ title }}
        <span class="group-count">({{ groupSize }})</span>
        <div class="group-collapsible" @click="toggleCollapse">
          <IconCollapse :class="{ rotate: isCollapsed }" />
        </div>
      </div>
    </div>
    <div v-show="!isCollapsed" class="group-items">
      <div v-for="event in events" :key="event.id">
        <MissionCard
          v-if="event.event_type === 'MISSION'"
          :selected="selected && event.id === selected.id"
          :mission="event"
          @select="onCardSelect(event)"
        />
        <EventCard v-else :event="event" :selected="selected && event.id === selected.id" @select="onCardSelect(event)" />
      </div>
    </div>
  </div>
</template>

<script>
import IconCollapse from '../../components/icons/IconCollapse.svg?component';
import EventCard from './EventCard.vue';
import MissionCard from './MissionCard.vue';

export default {
  name: 'EventsLogGroup',
  components: {
    IconCollapse,
    EventCard,
    MissionCard
  },
  props: {
    title: {
      type: String,
      required: true
    },
    events: {
      type: Array,
      required: true,
      default: () => []
    },
    selected: {
      type: Object,
      default: null
    }
  },
  emits: ['select'],
  data() {
    return {
      isCollapsed: false
    };
  },
  computed: {
    groupSize() {
      return this.events?.length;
    }
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    onCardSelect(event) {
      this.$emit('select', event);
    }
  }
};
</script>

<style scoped lang="scss">
.events-log-group {
  .group-title {
    font-size: 1.25rem;
    margin: 1rem 0;
    width: 100%;
    text-transform: capitalize;
    padding: 0 0 0 1rem;
    color: var(--textColor);

    .group-count {
      color: var(--secondaryTextColor);
      margin-left: 0.5rem;
    }

    .group-collapsible {
      cursor: pointer;
      margin-left: 1rem;
      height: 1rem;
      width: 1rem;
      color: var(--highlightColor);

      .rotate {
        transform: rotate(-90deg);
      }
    }

    .group-title-left {
      display: inline-flex;
    }
  }

  .group-items {
    transition: all 0.4s ease-in-out;
  }
}
</style>
