<template>
  <DatePicker
    v-model="val"
    class="date-picker"
    :is-range="isRange"
    :min-date="computedMinDate"
    :max-date="computedMaxDate"
    :is-inline="isInline"
    :is-dark="isDarkTheme"
    :color="color"
    @update:model-value="onInput"
    @dayclick="onDayClick"
  >
  </DatePicker>
</template>

<script>
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';
import dayjs from 'dayjs';
import { useContextStore } from '../../store/ContextStore';
import { mapStores } from 'pinia';

export default {
  name: 'BaseDatePicker',
  components: { DatePicker },
  props: {
    modelValue: {
      type: [String, Object, Number, Array, Date],
      default: null
    },
    isRange: {
      type: Boolean,
      default: false
    },
    isInline: {
      type: Boolean,
      default: true
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    },
    maxRangeInDays: {
      type: Number,
      default: null
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      lastClickedDate: null,
      val: null
    };
  },
  computed: {
    ...mapStores(useContextStore),
    isDarkTheme() {
      return this.contextStore.theme === 'dark';
    },
    color() {
      return this.isDarkTheme ? 'yellow' : 'teal';
    },
    computedMinDate() {
      if (this.isRange && this.maxRangeInDays && this.lastClickedDate) {
        const minDateByRange = dayjs(this.lastClickedDate).subtract(this.maxRangeInDays, 'day').toDate();
        const result = this.minDate && this.minDate > minDateByRange ? this.minDate : minDateByRange;
        return result;
      } else {
        return this.minDate;
      }
    },
    computedMaxDate() {
      if (this.isRange && this.maxRangeInDays && this.lastClickedDate) {
        const maxDateByRange = dayjs(this.lastClickedDate).add(this.maxRangeInDays, 'day').toDate();
        const result = this.maxDate && this.maxDate < maxDateByRange ? this.maxDate : maxDateByRange;
        return result;
      } else {
        return this.maxDate;
      }
    }
  },
  watch: {
    modelValue: {
      handler: function onBaseDatePickerValueChanged(newVal) {
        this.val = newVal;
      },
      immediate: true
    }
  },
  methods: {
    onInput(selected) {
      let normalizedValue = selected;
      if (this.isRange && selected) {
        normalizedValue = {
          start: dayjs(selected.start).startOf('day').toDate(),
          end: dayjs(selected.end).endOf('day').toDate()
        };
      }
      this.$emit('update:modelValue', normalizedValue);
      this.lastClickedDate = null;
    },
    onDayClick(selected) {
      this.lastClickedDate = selected.date;
    }
  }
};
</script>

<style lang="scss" scoped>
.date-picker {
  font-family: var(--font-family-secondary);
  &.vc-is-dark {
    background-color: var(--shadowColor);
    border-color: var(--highlightColor);
  }
}
</style>
