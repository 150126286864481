import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 205 205",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M102.5 202.5A100 100 0 11202.5 102.5 100 100 0 01102.5 202.5M44 44 44 161 161 161 161 44 44 44M99 66 99 139 106 139 106 66M52 52 153 52 153 153 52 153 52 52 59 59 59 146 146 146 146 59 59 59M66 66 139 66 139 139 66 139M94 97A1 1 0 0088 97L88 107A1 1 0 0094 107M111 107A1 1 0 00117 107L117 97A1 1 0 00111 97" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }