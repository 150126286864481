import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", { transform: "translate(-436 -2194) translate(40 1620) translate(396 574)" }, [
      _createElementVNode("circle", {
        cx: "12",
        cy: "12",
        r: "12",
        fill: "#83BF5C"
      }),
      _createElementVNode("g", {
        fill: "#FFF",
        "fill-rule": "nonzero"
      }, [
        _createElementVNode("path", {
          d: "M8.15 10.406c.218 0 .397.186.397.403v2.26l.63-.63c.15-.15.409-.15.558 0l.212.211c.154.154.154.404 0 .558l-1.68 1.677c-.074.075-.173.115-.278.115h-.015c-.102 0-.201-.04-.276-.115l-1.644-1.642c-.154-.154-.155-.404 0-.558l.211-.212c.149-.149.41-.149.558 0l.63.63v-2.307c0-.007.004-.017.005-.024.017-.202.187-.366.393-.366zM2.795 6.06c.154-.154.404-.154.558 0l.212.211c.075.074.116.172.116.278 0 .105-.041.203-.116.277l-.63.627h2.253c.218 0 .404.183.404.4v.3c0 .207-.165.374-.367.39-.007.002-.018.004-.025.004h-2.3l.63.631c.075.074.116.174.116.28 0 .105-.04.205-.115.279l-.212.211c-.077.077-.178.116-.28.116-.1 0-.202-.039-.279-.116L1.115 8.306C1.039 8.23.998 8.128 1 8.019c-.002-.104.039-.206.115-.282zm9.853 0c.154-.154.405-.154.559 0l1.68 1.677c.075.076.116.178.114.286.002.105-.039.207-.115.283L13.24 9.948c-.077.077-.178.116-.279.116-.1 0-.202-.039-.279-.116l-.212-.211c-.074-.075-.115-.174-.115-.28 0-.105.04-.205.115-.28l.631-.63h-2.313c-.007 0-.011-.002-.018-.003-.202-.017-.361-.184-.361-.39v-.3c0-.218.175-.401.393-.401h2.265l-.631-.627c-.075-.074-.116-.17-.116-.275 0-.106.041-.205.116-.28zM8 6.906c.604 0 1.096.49 1.096 1.094 0 .603-.492 1.094-1.096 1.094-.604 0-1.095-.49-1.095-1.094 0-.604.491-1.094 1.095-1.094zM7.985 1c.105 0 .206.039.283.115l1.679 1.677c.154.154.154.404 0 .558l-.212.212c-.154.153-.404.153-.558 0l-.63-.63V5.2c0 .217-.179.394-.396.394h-.3c-.207 0-.376-.16-.393-.362-.001-.006-.006-.012-.006-.02V2.898l-.63.63c-.148.149-.408.149-.556 0l-.212-.212c-.154-.154-.154-.404 0-.558l1.644-1.642c.076-.076.18-.116.287-.115z",
          transform: "translate(4 4)"
        })
      ])
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }