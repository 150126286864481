<template>
  <div class="mission-template-edit-form">
    <div class="header">
      <span
        >{{ missionTemplate ? 'Edit Mission' : 'Create New Mission' }}
        <span v-if="isTechnician && missionTemplate" class="template-id">({{ missionTemplate.id }})</span></span
      >
      <IconClose class="close-icon" @click="onCancel"><title>Close</title></IconClose>
    </div>
    <div class="form-content">
      <span>Mission Name</span>
      <BaseTextInput v-model="templateDraft.name" class="name-input" />
      <PoisDetectionSettings v-model="poiDetectionSettings" />
      <MissionTemplateHooksConfig
        v-if="isHooksConfigAvailable"
        ref="beforeMissionHooksConfig"
        :timing="HOOKS_TIMING.BEFORE"
        :existing-hooks="existingHooksMap[HOOKS_TIMING.BEFORE]"
        class="hooks"
        @change="setMissionTemplateHooks($event, HOOKS_TIMING.BEFORE)"
      />
      <MissionTemplateHooksConfig
        v-if="isHooksConfigAvailable"
        ref="afterMissionHooksConfig"
        :timing="HOOKS_TIMING.AFTER"
        :existing-hooks="existingHooksMap[HOOKS_TIMING.AFTER]"
        class="hooks"
        @change="setMissionTemplateHooks($event, HOOKS_TIMING.AFTER)"
      />
      <template v-if="isTechnician">
        <span>Skydio template UUID</span>
        <BaseTextInput v-model="templateDraft.skydio_template_uuid" class="name-input" />
        <div v-if="templateDraft.skydio_template_uuid" class="compatibility-toggle">
          <span>Compatible with Tando</span>
          <BaseToggle v-model="templateDraft.isTandoCompatible" />
        </div>
      </template>
      <div v-if="!templateDraft.skydio_template_uuid || templateDraft.isTandoCompatible">
        <BaseCheckbox v-if="tileOptions.length > 1" v-model:checked="showTileSelection" @change="onShowTileSelection">
          Choose destination tile</BaseCheckbox
        >
        <div v-if="showTileSelection" class="tile-selection">
          <BaseRadioGroup v-model="selectedTileId" :options="tileOptions" @change="onTileSelect" />
        </div>
      </div>
      <span class="btns-group">
        <BaseButton class="action-button" :disabled="!allowSave" @click="saveMission">Save</BaseButton>
        <BaseCancelButton class="action-button" @click="onCancel">Cancel</BaseCancelButton>
      </span>
    </div>
  </div>
</template>

<script>
import IconClose from '../../components/icons/IconClose.svg?component';
import BaseTextInput from '../../components/base/BaseTextInput.vue';
import BaseToggle from '../../components/base/BaseToggle.vue';
import MissionTemplateHooksConfig from './MissionTemplateHooksConfig.vue';
import { clone, equals } from '../../utils/ObjectUtils.js';
import BaseButton from '../../components/base/BaseButton.vue';
import BaseCancelButton from '../../components/base/BaseCancelButton.vue';
import BaseRadioGroup from '../../components/base/BaseRadioGroup.vue';
import BaseCheckbox from '../../components/base/BaseCheckbox.vue';
import { MAP_POI_OPTIONS, MAP_POI_OPTION_TO_TYPE } from '../../consts/mapConsts.js';
import { mapState, mapActions } from 'pinia';
import { useMapStore } from '../../store/MapStore.js';
import { useMissionTemplatesStore } from '../../store/MissionTemplatesStore.js';
import { useContextStore } from '../../store/ContextStore.js';
import { useDevicesStore } from '../../store/DevicesStore.js';
import { useEventListStore } from '../../store/EventListStore.js';
import { SNACKBAR_TYPE } from '../../consts/appConsts.js';
import { DEVICES_TYPES } from '../../consts/deviceConsts.js';
import PoisDetectionSettings from './PoisDetectionSettings.vue';
import { POI_OPTIONS_ICONS } from '../../consts/mapConsts.js';

export default {
  name: 'MissionTemplateEditForm',
  components: {
    IconClose,
    BaseTextInput,
    BaseToggle,
    MissionTemplateHooksConfig,
    BaseButton,
    BaseCancelButton,
    BaseRadioGroup,
    BaseCheckbox,
    PoisDetectionSettings
  },
  emits: ['tile-select', 'cancel'],
  data() {
    return {
      initialMission: null,
      templateDraft: {},
      saveSuccessMsg: 'Mission successfully saved to your list',
      saveFailMsg: 'Failed to save mission. Please contact support for help',
      editSuccessMsg: 'Mission successfully updated',
      editFailMsg: 'Failed to update mission',
      HOOKS_TIMING: {
        BEFORE: 'BEFORE',
        AFTER: 'AFTER'
      },
      hooksDraft: {},
      selectedTileId: null,
      showTileSelection: false,
      initialPois: [],
      poiDetectionSettings: null
    };
  },
  computed: {
    ...mapState(useDevicesStore, ['contextZoneActiveIotControllers', 'contextZoneActiveTiles']),
    ...mapState(useEventListStore, ['activeMissions']),
    ...mapState(useContextStore, ['isTechnician', 'missionTemplate']),
    ...mapState(useMapStore, ['missionTemplateDraft', 'selectedPoiIndex']),
    ...mapState(useMissionTemplatesStore, ['defaultMissionName']),
    tileOptions() {
      return this.contextZoneActiveTiles.reduce((acc, tile) => {
        if (tile.type_id !== DEVICES_TYPES.SKYDIO_TILE) {
          acc.push({
            label: tile.name,
            id: tile.id
          });
        }
        return acc;
      }, []);
    },
    isHooksConfigAvailable() {
      return this.contextZoneActiveIotControllers.length > 0;
    },
    allowSave() {
      const fieldsToCompare = ['name', 'skydio_template_uuid'];
      const hasFieldChanged =
        !this.missionTemplate ||
        fieldsToCompare.some(
          field => this.missionTemplate[field] !== this.templateDraft[field] && !(!this.missionTemplate[field] && !this.templateDraft[field]) // ignore empty string & undefined comparison
        );
      const hasTandoCompatibilityChanged = this.isTemplateTandoCompatible != this.templateDraft.isTandoCompatible;
      return (
        (this.missionTemplateDraft.length > 0 && (!this.missionTemplate || this.isHooksDirty)) ||
        (this.missionTemplate &&
          (this.missionTemplate.name !== this.templateDraft.name ||
            this.isPoisDirty ||
            this.isHooksDirty ||
            this.isPoiDetectionSettingsDirty ||
            this.selectedTileId !== this.templateDraft.destination_tile_id ||
            hasFieldChanged ||
            hasTandoCompatibilityChanged))
      );
    },
    existingHooksMap() {
      return {
        [this.HOOKS_TIMING.BEFORE]: Object.values(this.initialHooks[this.HOOKS_TIMING.BEFORE] || {}),
        [this.HOOKS_TIMING.AFTER]: Object.values(this.initialHooks[this.HOOKS_TIMING.AFTER] || {})
      };
    },
    initialHooks() {
      const initialHooksMap = {
        [this.HOOKS_TIMING.BEFORE]: {},
        [this.HOOKS_TIMING.AFTER]: {}
      };
      if (Array.isArray(this.templateDraft.hooks) && this.templateDraft.hooks.length) {
        this.templateDraft.hooks.forEach(hook => {
          initialHooksMap[hook.timing][hook.device_id] = hook;
        });
      }
      return initialHooksMap;
    },
    isHooksDirty() {
      return !equals(this.initialHooks, this.hooksDraft);
    },
    isPoiDetectionSettingsDirty() {
      return !equals(this.poiDetectionSettings, this.missionTemplate.poi_detection_settings || {});
    },
    isPoisDirty() {
      return !equals(this.initialPois, this.missionTemplateDraft);
    },
    isTemplateTandoCompatible() {
      return this.missionTemplate?.compatible_device_types?.includes?.(DEVICES_TYPES.TANDO);
    }
  },
  watch: {
    poiDetectionSettings: {
      handler: function onPoiDetectionSettingsChange(newVal) {
        const draft = clone(this.missionTemplateDraft);
        draft.forEach((poi, index) => {
          poi.configBadge = !!newVal[index];
        });
        this.updateMissionTemplateDraft(draft);
      },
      deep: true
    }
  },
  created() {
    this.initMission();
  },
  methods: {
    getPoiIcon(poiType) {
      return POI_OPTIONS_ICONS[poiType];
    },
    ...mapActions(useContextStore, ['showSnackbar', 'setContextMissionTemplate']),
    ...mapActions(useMapStore, ['updateMissionTemplateDraft', 'addMissionTemplatePoi', 'removeMissionTemplatePoi', 'resetMapMode']),
    ...mapActions(useMissionTemplatesStore, ['createMissionTemplate', 'updateMissionTemplate']),
    initMission() {
      this.initialMission = clone(this.missionTemplate);

      if (this.missionTemplate) {
        this.templateDraft = { ...this.missionTemplate, isTandoCompatible: this.isTemplateTandoCompatible };
        this.selectedTileId = this.templateDraft.destination_tile_id;
        this.poiDetectionSettings = this.templateDraft.poi_detection_settings || {};
        this.updateMissionTemplateDraft(
          this.missionTemplate.pois?.map?.((poi, index) => ({
            ...poi,
            configBadge: !!this.missionTemplate.poi_detection_settings?.[index]
          }))
        );

        if (this.selectedTileId) {
          this.removeTileAsPoi();
          this.showTileSelection = true;
          this.onShowTileSelection();
        }
      } else {
        this.templateDraft.name = this.defaultMissionName;
        this.templateDraft.hooks = [];
      }

      this.hooksDraft = clone(this.initialHooks);
      this.initialPois = this.missionTemplateDraft.slice();
    },
    addSelectedTileAsPoi() {
      const point = this.convertTileLocationToPoiPoint(this.selectedTileId);
      this.addMissionTemplatePoi(point);
    },
    removeTileAsPoi() {
      const lastIndex = this.missionTemplateDraft.length - 1;
      const lastPoi = this.missionTemplateDraft[lastIndex];
      if (lastPoi.isTile) {
        this.removeMissionTemplatePoi(lastIndex);
      }
    },
    convertTileLocationToPoiPoint(tileId) {
      const tileLocation = this.contextZoneActiveTiles.filter(tile => tile.id === tileId)[0].location;
      return {
        x: tileLocation.x,
        y: tileLocation.y,
        poiType: MAP_POI_OPTIONS.VISIT,
        type: MAP_POI_OPTION_TO_TYPE[MAP_POI_OPTIONS.VISIT],
        isTile: true
      };
    },
    async saveMission() {
      if (this.allowSave) {
        let isEdit = false;
        if (this.selectedTileId) {
          this.addSelectedTileAsPoi();
        }
        const pois = [...this.missionTemplateDraft];
        pois.forEach(poi => {
          delete poi.configBadge;
        });
        const params = {
          name: this.templateDraft.name || this.defaultMissionName,
          pois,
          hooks: [...Object.values(this.hooksDraft[this.HOOKS_TIMING.BEFORE]), ...Object.values(this.hooksDraft[this.HOOKS_TIMING.AFTER])],
          skydio_template_uuid: this.templateDraft.skydio_template_uuid,
          isTandoCompatible: this.templateDraft.isTandoCompatible
        };
        // Only add destination tile for Tando compatible templates
        if ((!this.templateDraft.skydio_template_uuid || this.templateDraft.isTandoCompatible) && this.selectedTileId) {
          params.destination_tile_id = this.selectedTileId;
        }

        params.poi_detection_settings = this.poiDetectionSettings;

        try {
          if (this.missionTemplate) {
            await this.updateMissionTemplate({
              id: this.missionTemplate.id,
              current_revision: this.missionTemplate.revision,
              ...params
            });
            isEdit = true;
          } else {
            await this.createMissionTemplate(params);
          }
          this.resetMapMode();
          this.showSnackbar({
            message: isEdit ? this.editSuccessMsg : this.saveSuccessMsg,
            type: SNACKBAR_TYPE.SUCCESS
          });
        } catch (err) {
          console.error(err);
          this.showSnackbar({
            message: isEdit ? this.editFailMsg : this.saveFailMsg,
            type: SNACKBAR_TYPE.ERROR
          });
        }
      }
    },
    setMissionTemplateHooks(hooks, timing) {
      this.hooksDraft[timing] = {};
      hooks.forEach(hook => {
        this.hooksDraft[timing][hook.device_id] = hook;
      });
    },
    isTileChecked(tileId) {
      return this.selectedTileId === tileId;
    },
    onTileSelect() {
      this.$emit('tile-select', this.selectedTileId);
    },
    onShowTileSelection() {
      if (!this.showTileSelection) {
        this.selectedTileId = null;
      }
      this.$emit('tile-select', this.selectedTileId);
    },
    onCancel() {
      this.setContextMissionTemplate(this.initialMission);
      this.resetMapMode();
    }
  }
};
</script>

<style scoped lang="scss">
.mission-template-edit-form {
  font-size: 1.2rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  .mission-template-card {
    border-top: solid 1px var(--secondaryOverlayShade1);
  }

  .header {
    color: var(--highlightColor);
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    .close-icon {
      width: 1rem;
      height: 1rem;
      flex-shrink: 0;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }

    .template-id {
      color: var(--secondaryOverlayShade2);
    }
  }

  .form-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-secondary);
    row-gap: 1rem;
    color: var(--textColor);
    overflow-y: auto;
    flex-grow: 1;
    direction: rtl;

    * {
      direction: ltr;
    }

    .name-input {
      color: var(--secondaryTextColor);
      border: 1px solid;
      border-radius: 0.2rem;
      padding: 0.5rem;
      font-size: 1rem;
    }

    .btns-group {
      display: flex;
      column-gap: 1rem;

      .action-button {
        flex-grow: 1;
      }
    }

    svg {
      cursor: pointer;
      color: var(--highlightColor);
    }

    .mission-edit-bar-button-disabled {
      pointer-events: none;
      color: var(--disabledColor);
    }

    .tile-selection {
      margin: 0.5rem 0;
      flex-direction: column;
      padding: 0.5rem 2rem;
      background: var(--primaryColor);
    }

    .compatibility-toggle {
      display: flex;
      align-items: flex-end;
      column-gap: 16px;
    }
  }
}
</style>
