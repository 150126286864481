<template>
  <BaseModal
    cancel-option
    :confirmation-option="{ text: 'Create', disabled: !isDirty }"
    :is-executing="isCreatingEvent"
    @close="onSubmit(false)"
    @confirm="onSubmit(true)"
  >
    <template #title> Create event on {{ getTimeStamp(timestamp) }} </template>
    <template #body>
      <div class="body-content">
        <div class="attribute-label">
          Choose a type:
          <BaseDropdown v-model="fieldsValues.event_type" :options="EVENT_OPTIONS" :clearable="true" />
        </div>
        <div>
          <div class="edit-panel">
            <span>Press and Hold your cursor on the snapshot to mark the event.</span>
            <div class="action-btns">
              <IconUndo :class="['icon', { disabled: !isDirty }]" @click="undo"><title>Undo</title></IconUndo>
              <IconClear :class="['icon', { disabled: !isDirty }]" @click="clear"><title>Clear</title></IconClear>
            </div>
          </div>
          <canvas
            ref="snapshotCanvas"
            class="frame-canvas"
            width="400"
            height="200"
            @mousedown="onMouseDown"
            @mousemove="onMouseMove"
            @mouseup="onMouseUp"
          />
        </div>
        <div>
          <BaseCheckbox v-if="isTechnician" v-model:checked="fieldsValues.technician_mode"> Available to technicians only</BaseCheckbox>
        </div>
        <div class="attribute-label">
          Description:
          <BaseTextInput v-model="fieldsValues.description" class="description-field" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '../../components/base/BaseModal.vue';
import BaseCheckbox from '../../components/base/BaseCheckbox.vue';
import BaseDropdown from '../../components/base/BaseDropdown.vue';
import IconUndo from '../../components/icons/IconUndo.svg?component';
import IconClear from '../../components/icons/IconClear.svg?component';
import BaseTextInput from '../../components/base/BaseTextInput.vue';
import { getStyleVar } from '../../utils/StyleUtils';
import { getTimeStamp } from '../../utils/DateUtils';
import { mapState, mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import { EVENT_TYPE_NAME, MANUAL_EVENT_TYPES } from '../../consts/eventConsts';
import { useEventListStore } from '../../store/EventListStore';
import { SNACKBAR_TYPE } from '../../consts/appConsts';
const EVENT_OPTIONS = MANUAL_EVENT_TYPES.map(type => {
  return { id: type, label: EVENT_TYPE_NAME[type] };
});

export default {
  name: 'CreateEventModal',
  components: {
    BaseModal,
    BaseCheckbox,
    BaseDropdown,
    BaseTextInput,
    IconUndo,
    IconClear
  },
  props: {
    videoElement: {
      type: Object,
      required: true
    },
    timestamp: {
      type: Number,
      required: true
    },
    location: {
      type: Object,
      required: true
    },
    source: {
      type: String,
      required: true
    }
  },
  emits: ['close', 'eventCreated'],
  data() {
    return {
      EVENT_OPTIONS,
      ctx: null,
      width: 0,
      height: 0,
      boundBox: {},
      fieldsValues: {
        event_type: EVENT_OPTIONS[0].id,
        description: '',
        boundBoxes: [],
        technician_mode: false
      },
      isCreatingEvent: false
    };
  },
  computed: {
    ...mapStores(useEventListStore, useContextStore),
    ...mapState(useContextStore, ['isTechnician', 'mission']),
    isDirty() {
      return this.fieldsValues.boundBoxes.length;
    },
    videoPositionedBoundBoxes() {
      return this.fieldsValues.boundBoxes.map(boundBox => ({
        x: boundBox.x / this.width,
        y: boundBox.y / this.height,
        width: boundBox.width / this.width,
        height: boundBox.height / this.height
      }));
    }
  },
  mounted() {
    this.width = this.videoElement.clientWidth;
    this.height = this.videoElement.clientHeight;
    this.$refs.snapshotCanvas.width = this.width;
    this.$refs.snapshotCanvas.height = this.height;
    this.ctx = this.$refs.snapshotCanvas.getContext('2d');
    this.ctx.drawImage(this.videoElement, 0, 0, this.width, this.height);
  },

  methods: {
    getTimeStamp,
    async onSubmit(isConfirmed) {
      if (isConfirmed) {
        this.isCreatingEvent = true;
        const eventObj = {
          account_id: this.mission.account_id,
          zone_id: this.mission.zone_id,
          device_id: this.mission.device_id,
          event_type: this.fieldsValues.event_type,
          technician_mode: this.mission.technician_mode || this.fieldsValues.technician_mode,
          mission_id: this.mission.id,
          timestamp: this.timestamp,
          location: this.location,
          custom_data: {
            description: this.fieldsValues.description,
            BoundingBoxes: this.videoPositionedBoundBoxes,
            Source: this.source
          }
        };
        try {
          const event = await this.eventListStore.createEvent(eventObj);
          this.$emit('eventCreated', { event, player: this.source });
          this.contextStore.showSnackbar({
            message: 'Event created successfully',
            type: SNACKBAR_TYPE.SUCCESS
          });
        } catch (err) {
          console.error(err);
          this.contextStore.showSnackbar({
            message: 'Unable to create event',
            type: SNACKBAR_TYPE.ERROR
          });
        } finally {
          this.isCreatingEvent = false;
        }
      }
      this.$emit('close');
      this.fieldsValues = {
        event_type: EVENT_OPTIONS[0].id,
        description: '',
        boundBoxes: [],
        technician_mode: false
      };
    },
    undo() {
      this.fieldsValues.boundBoxes.pop();
      this.redraw();
    },
    clear() {
      this.fieldsValues.boundBoxes = [];
      this.redraw();
    },
    onMouseDown(ev) {
      this.boundBox.x = ev.offsetX || ev.pageX - this.$refs.snapshotCanvas.offsetLeft;
      this.boundBox.y = ev.offsetY || ev.pageY - this.$refs.snapshotCanvas.offsetTop;
      window.addEventListener('mouseup', this.onMouseUp);
    },
    onMouseMove(ev) {
      if (this.boundBox.hasOwnProperty('x')) {
        this.boundBox.width = (ev.offsetX || ev.pageX - this.$refs.snapshotCanvas.offsetLeft) - this.boundBox.x;
        this.boundBox.height = (ev.offsetY || ev.pageY - this.$refs.snapshotCanvas.offsetTop) - this.boundBox.y;
        this.redraw();
      }
    },
    onMouseUp(ev) {
      if (this.boundBox.hasOwnProperty('x')) {
        this.boundBox.width = (ev.offsetX || ev.pageX - this.$refs.snapshotCanvas.offsetLeft) - this.boundBox.x;
        this.boundBox.height = (ev.offsetY || ev.pageY - this.$refs.snapshotCanvas.offsetTop) - this.boundBox.y;
        this.fieldsValues.boundBoxes.push(this.boundBox);
        this.boundBox = {};
        this.redraw();
      }
    },
    redraw() {
      this.ctx.clearRect(0, 0, this.width, this.height);
      this.ctx.beginPath();
      this.ctx.drawImage(this.videoElement, 0, 0, this.width, this.height);
      this.fieldsValues.boundBoxes.forEach(boundBox => {
        this.ctx.rect(boundBox.x, boundBox.y, boundBox.width, boundBox.height);
      });

      if (this.boundBox.hasOwnProperty('x')) {
        this.ctx.rect(this.boundBox.x, this.boundBox.y, this.boundBox.width, this.boundBox.height);
      }
      this.ctx.strokeStyle = getStyleVar('--boundBoxDefaultColor');
      this.ctx.lineWidth = 2;
      this.ctx.stroke();
      this.ctx.closePath();
    }
  }
};
</script>

<style lang="scss">
.body-content {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 1rem 0;

  .attribute-label {
    display: grid;
    row-gap: 0.5rem;
  }

  .description-field {
    input {
      border-radius: 5px;
      padding: 0.5rem;
      border: 1px solid var(--highlightColor);

      &:focus {
        transform: none;
      }
    }
  }

  .edit-panel {
    display: flex;
    height: 2rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .action-btns {
      display: flex;
      column-gap: 0.5rem;

      .icon {
        width: 1.3rem;
        height: 1.3rem;
        transition: 0.2s;
        flex-shrink: 0;
        cursor: pointer;
        background: var(--primaryColorBrightShade2);
        padding: 5px;
        border-radius: 5px;

        &:hover {
          transform: scale(1.2);
        }

        &.disabled {
          pointer-events: none;
          color: var(--disabledColor);
        }
      }
    }
  }

  .frame-canvas {
    cursor: crosshair;
    border-radius: 5px;
  }
}
</style>
