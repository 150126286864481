<template>
  <BaseMenu v-if="showDeviceActionsMenu" ref="actionsMenu" class="device-actions-menu" :position="menuPosition">
    <template #toggle>
      <IconMaintenance class="action-button"><title>Device Actions</title></IconMaintenance>
    </template>
    <template #content>
      <div class="actions-list">
        <div v-for="action in allowedActions" :key="action.name" class="menu-button" @click="action.callback">
          {{ action.name }}
          <hr v-if="action.separate" />
        </div>
      </div>
    </template>
  </BaseMenu>
  <DeviceActiveNodesModal v-if="showDeviceActiveNodesModal" :device="device" @close="showDeviceActiveNodesModal = false" />
  <ResetTileModal v-if="showResetTileModal" :device="device" @close="showResetTileModal = false" />
  <PairDeviceModal v-if="showPairDeviceModal" :device-id="device.id" :device-name="device.name" @close="showPairDeviceModal = false" />
  <DebugMissionModal v-if="showDebugMissionModal" :device-id="device.id" :device-name="device.name" @close="showDebugMissionModal = false" />
</template>

<script>
import BaseMenu, { MENU_POSITION_OPTIONS } from '../../components/base/BaseMenu.vue';
import IconMaintenance from '../../components/icons/IconMaintenance.svg?component';
import { DEVICES_TYPES, COMMUNICATING_DEVICES, NEST_DEVICE_OPERATIONS } from '../../consts/deviceConsts';
import { useDevicesStore } from '../../store/DevicesStore';
import { useContextStore } from '../../store/ContextStore';
import { mapState, mapActions, mapStores } from 'pinia';
import { SNACKBAR_TYPE } from '../../consts/appConsts';
import { PRIVILEGES } from '../../consts/authConsts';
import DeviceActiveNodesModal from './DeviceActiveNodesModal.vue';
import ResetTileModal from './ResetTileModal.vue';
import devicesService from '../../services/api/devicesService';
import PairDeviceModal from './PairDeviceModal.vue';
import DebugMissionModal from './DebugMissionModal.vue';

export default {
  name: 'DeviceActionsMenu',
  components: {
    BaseMenu,
    DeviceActiveNodesModal,
    ResetTileModal,
    IconMaintenance,
    PairDeviceModal,
    DebugMissionModal
  },
  props: {
    device: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      menuPosition: [MENU_POSITION_OPTIONS.BOTTOM, MENU_POSITION_OPTIONS.RIGHT],
      showDeviceActiveNodesModal: false,
      showResetTileModal: false,
      DEVICES_TYPES,
      showPairDeviceModal: false,
      showDebugMissionModal: false
    };
  },
  computed: {
    ...mapState(useDevicesStore, ['getDeviceById', 'contextZoneActiveTiles']),
    ...mapState(useContextStore, ['isTechnician', 'hasPrivilege']),
    ...mapStores(useContextStore),
    allowedActions() {
      return this.menuActions.filter(option => option.hasPrivilege);
    },
    showDeviceActionsMenu() {
      return COMMUNICATING_DEVICES.includes(this.device.type_id) && (this.isTechnician || this.allowedActions.length > 0);
    },
    menuActions() {
      let menuActions;
      if (this.device.type_id === DEVICES_TYPES.TANDO) {
        menuActions = [
          {
            name: 'Restart device',
            selected: false,
            hasPrivilege: this.hasPrivilege(PRIVILEGES.RESTART_DEVICE),
            callback: () => this.onDeviceRestartClick()
          },
          {
            name: 'Shutdown device',
            selected: false,
            hasPrivilege: this.hasPrivilege(PRIVILEGES.SHUTDOWN_DEVICE),
            callback: () => this.onDeviceShutdownClick()
          },
          {
            name: 'Upload logs',
            selected: false,
            hasPrivilege: this.isTechnician,
            callback: () => this.onUploadLogsClick()
          },
          {
            name: 'Update config',
            selected: false,
            hasPrivilege: this.isTechnician,
            callback: () => this.onUpdateConfigClick()
          },
          {
            name: 'Reset Errors',
            selected: false,
            hasPrivilege: this.hasPrivilege(PRIVILEGES.RESET_DEVICE_ERRORS),
            callback: () => this.onDeviceResetErrorsClick()
          },
          {
            name: 'Reset States',
            selected: false,
            hasPrivilege: this.isTechnician,
            callback: () => this.onDeviceResetStatesClick()
          },
          {
            name: 'Pair to tile',
            selected: false,
            hasPrivilege: this.isTechnician,
            callback: () => (this.showPairDeviceModal = true)
          },
          {
            name: 'Unpair from tile',
            selected: false,
            hasPrivilege: this.isTechnician,
            callback: () => this.onDeviceUnpairFromTileClick()
          },
          {
            name: 'Invoke debug mission',
            selected: false,
            hasPrivilege: this.hasPrivilege(PRIVILEGES.INVOKE_DEBUG_MISSION),
            callback: () => (this.showDebugMissionModal = true)
          },
          {
            name: 'Show active nodes',
            selected: false,
            hasPrivilege: this.isTechnician,
            callback: () => (this.showDeviceActiveNodesModal = true)
          },
          {
            name: 'Reset Tile',
            selected: false,
            hasPrivilege: this.isTechnician,
            callback: () => (this.showResetTileModal = true)
          }
        ];
      } else if (this.device.type_id === DEVICES_TYPES.NEST) {
        menuActions = Object.entries(NEST_DEVICE_OPERATIONS).map(([key, label]) => ({
          name: label,
          selected: false,
          hasPrivilege: this.hasPrivilege(PRIVILEGES.MANAGE_DEVICES),
          callback: () => this.sendNestCommand(key, label)
        }));
      }

      return menuActions;
    }
  },
  methods: {
    ...mapActions(useContextStore, ['showSnackbar']),
    async sendNestCommand(command, label) {
      try {
        await devicesService.triggerNestCommand({ device_id: this.device.id, command });
        this.showSnackbar({
          message: `${label} Command was sent successfully`,
          type: SNACKBAR_TYPE.SUCCESS
        });
      } catch (error) {
        this.showSnackbar({
          message: `Failed to send ${label} command to the device, please try again later`,
          type: SNACKBAR_TYPE.ERROR
        });
        console.log(error);
      }
      // Close the menu
      this.$refs.actionsMenu.openClose();
    },
    onDeviceRestartClick() {
      this.contextStore.$patch(state => {
        state.showConfirmationDialog = true;
        state.confirmationDialogData.title = 'Restart device';
        state.confirmationDialogData.text = `Are you sure you want to restart ${this.device.name}?`;
        state.confirmationDialogData.confirmText = 'Yes';
        state.confirmationDialogData.callback = async isConfirmed => {
          if (isConfirmed) {
            try {
              await devicesService.invokeDeviceRestart(this.device.id);
              this.showSnackbar({
                message: 'Device restart request was sent successfully',
                type: SNACKBAR_TYPE.SUCCESS
              });
            } catch (err) {
              this.showSnackbar({
                message: 'Failed to restart the device, please try again later',
                type: SNACKBAR_TYPE.ERROR
              });
            }
          }
        };
      });
    },
    onDeviceShutdownClick() {
      this.contextStore.$patch(state => {
        state.showConfirmationDialog = true;
        state.confirmationDialogData.title = 'Shutdown device';
        state.confirmationDialogData.text = `Are you sure you want to Shutdown ${this.device.name}?`;
        state.confirmationDialogData.confirmText = 'Yes';
        state.confirmationDialogData.callback = async isConfirmed => {
          if (isConfirmed) {
            try {
              await devicesService.invokeDeviceShutdown(this.device.id);
              this.showSnackbar({
                message: 'Device shutdown request was sent successfully',
                type: SNACKBAR_TYPE.SUCCESS
              });
            } catch (err) {
              this.showSnackbar({
                message: 'Failed to shutdown the device, please try again later',
                type: SNACKBAR_TYPE.ERROR
              });
            }
          }
        };
      });
    },
    onUploadLogsClick() {
      this.contextStore.$patch(state => {
        state.showConfirmationDialog = true;
        state.confirmationDialogData.title = 'Upload device logs';
        state.confirmationDialogData.text = `Are you sure you want upload logs for ${this.device.name}?`;
        state.confirmationDialogData.confirmText = 'Yes';
        state.confirmationDialogData.callback = async isConfirmed => {
          if (isConfirmed) {
            try {
              await devicesService.invokeUploadLogs(this.device.id);
              this.showSnackbar({
                message: 'Device log upload request was sent successfully',
                type: SNACKBAR_TYPE.SUCCESS
              });
            } catch (err) {
              this.showSnackbar({
                message: 'Failed to upload device log, please try again later',
                type: SNACKBAR_TYPE.ERROR
              });
            }
          }
        };
      });
    },
    onUpdateConfigClick() {
      this.contextStore.$patch(state => {
        state.showConfirmationDialog = true;
        state.confirmationDialogData.title = 'Update device config';
        state.confirmationDialogData.text = `Are you sure you want update the config for ${this.device.name}?`;
        state.confirmationDialogData.confirmText = 'Yes';
        state.confirmationDialogData.callback = async isConfirmed => {
          if (isConfirmed) {
            try {
              await devicesService.installDeviceVersion({ deviceId: this.device.id, payload: { config: 'latest' } });
              this.showSnackbar({
                message: 'Device config update request was sent successfully',
                type: SNACKBAR_TYPE.SUCCESS
              });
            } catch (err) {
              this.showSnackbar({
                message: 'Failed to update device config, please try again later',
                type: SNACKBAR_TYPE.ERROR
              });
            }
          }
        };
      });
    },
    onDeviceResetErrorsClick() {
      this.contextStore.$patch(state => {
        state.showConfirmationDialog = true;
        state.confirmationDialogData.title = 'Reset device errors';
        state.confirmationDialogData.text = `Are you sure you want to reset errors for ${this.device.name}?`;
        state.confirmationDialogData.confirmText = 'Yes';
        state.confirmationDialogData.callback = async isConfirmed => {
          if (isConfirmed) {
            try {
              await devicesService.invokeDeviceResetErrors(this.device.id);
              this.showSnackbar({
                message: 'Device reset errors request was sent successfully',
                type: SNACKBAR_TYPE.SUCCESS
              });
            } catch (err) {
              this.showSnackbar({
                message: 'Failed to reset device errors, please try again later',
                type: SNACKBAR_TYPE.ERROR
              });
            }
          }
        };
      });
    },
    onDeviceResetStatesClick() {
      this.contextStore.$patch(state => {
        state.showConfirmationDialog = true;
        state.confirmationDialogData.title = 'Reset device states';
        state.confirmationDialogData.text = `Are you sure you want to reset states for ${this.device.name}?`;
        state.confirmationDialogData.confirmText = 'Yes';
        state.confirmationDialogData.callback = async isConfirmed => {
          if (isConfirmed) {
            try {
              await devicesService.invokeDeviceResetStates(this.device.id);
              this.showSnackbar({
                message: 'Device reset states request was sent successfully',
                type: SNACKBAR_TYPE.SUCCESS
              });
            } catch (err) {
              this.showSnackbar({
                message: 'Failed to reset device states, please try again later',
                type: SNACKBAR_TYPE.ERROR
              });
            }
          }
        };
      });
    },
    onDeviceUnpairFromTileClick() {
      this.contextStore.$patch(state => {
        state.showConfirmationDialog = true;
        state.confirmationDialogData.title = 'Unpair device';
        state.confirmationDialogData.text = `Are you sure you want to unpair ${this.device.name}?`;
        state.confirmationDialogData.confirmText = 'Yes';
        state.confirmationDialogData.callback = async isConfirmed => {
          if (isConfirmed) {
            try {
              await devicesService.invokeDeviceUnpair(this.device.id);
              this.showSnackbar({
                message: 'Device unpair request was sent successfully',
                type: SNACKBAR_TYPE.SUCCESS
              });
            } catch (err) {
              this.showSnackbar({
                message: 'Failed to unpair device, please try again later',
                type: SNACKBAR_TYPE.ERROR
              });
            }
          }
        };
      });
    }
  }
};
</script>

<style scoped lang="scss">
.device-actions-menu {
  cursor: pointer;

  .action-button {
    width: 1.2rem;
    height: 1.2rem;
    color: var(--highlightColor);
  }

  .actions-list {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--highlightColor);
    border-radius: 3px;
    width: max-content;

    .menu-button {
      font-family: var(--font-family-secondary);
      background: var(--mainBackground);
      font-size: 1.2rem;
      padding: 0.7rem 2rem;
      cursor: pointer;
      outline: none;
      text-transform: capitalize;

      &:hover {
        filter: brightness(1.2);
      }
    }
  }
}
</style>
