<template>
  <div class="devices-log">
    <div class="title">Devices</div>
    <div class="devices-log-list">
      <template v-if="contextZoneActiveDevices.length">
        <DeviceExpandableCard
          v-for="device in contextZoneActiveDevices"
          :key="device.id"
          :device="device"
          @button:open="onButtonOpen"
          @button:close="onButtonClose"
        />
      </template>
      <BaseLoader v-else-if="!wereDevicesFetched" class="loader" />
      <span v-else class="no-devices-label"> No Active Devices </span>
    </div>
  </div>
</template>

<script>
import DeviceExpandableCard from './DeviceExpandableCard.vue';
import { useDevicesStore } from '../../store/DevicesStore';
import { mapState } from 'pinia';
import BaseLoader from '../../components/base/BaseLoader.vue';

export default {
  name: 'DevicesLog',
  components: {
    DeviceExpandableCard,
    BaseLoader
  },
  emits: ['button:close', 'button:open'],
  computed: {
    ...mapState(useDevicesStore, ['contextZoneActiveDevices', 'wereDevicesFetched'])
  },
  methods: {
    onButtonOpen(deviceId) {
      this.$emit('button:open', deviceId);
    },
    onButtonClose(deviceId) {
      this.$emit('button:close', deviceId);
    }
  }
};
</script>
<style lang="scss" scoped>
.devices-log {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .loader {
    height: 3rem;
    width: 3rem;
    margin: auto;
  }

  .title {
    font-size: 1.875rem;
    padding: 0.5rem 1rem;
  }

  .no-devices-label {
    font-size: 1.4;
    padding: 1rem 2rem;
  }

  .devices-log-list {
    overflow: auto;
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    flex-grow: 1;
    direction: rtl;

    * {
      direction: ltr;
    }
  }
}
</style>
