import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", null, [
      _createElementVNode("circle", {
        cx: "12",
        cy: "12",
        r: "12",
        fill: "#E33B1C"
      }),
      _createElementVNode("path", {
        fill: "#FFF",
        d: "M11.105 5.54c.253 0 .437.21.437.467v5.75h2.189c.189 0 .355.111.428.284.07.173.028.374-.106.505l-4.538 4.51c-.088.09-.207.136-.323.136-.115 0-.235-.042-.322-.13L4.33 12.55c-.133-.136-.175-.332-.106-.51.074-.173.24-.285.429-.285h2.189v-5.75c0-.26.184-.467.437-.467zm0-1.45c.253 0 .46.21.46.468 0 .257-.207.467-.46.467H7.28c-.253 0-.46-.21-.46-.467s.207-.468.46-.468zm0-1.449c.253 0 .46.21.46.468 0 .257-.207.467-.46.467H7.28c-.253 0-.46-.21-.46-.467s.207-.468.46-.468zm0-1.449c.253 0 .46.21.46.468 0 .257-.207.467-.46.467H7.28c-.253 0-.46-.21-.46-.467s.207-.468.46-.468z",
        transform: "translate(2.807612, 2.807612) translate(9.192388, 9.192388) rotate(-45.000000) translate(-9.192388, -9.192388)"
      })
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }