<template>
  <div class="account-login">
    <template v-if="!isLoading">
      <div>
        <div class="choose-account-label">
          <template v-if="contextStore.isTechnician"> Account to log in to as <b>Indoor Technician</b> </template>
          <template v-else> Which account would you like to login to?</template>
        </div>
        <BaseSearchBox v-model="selectedAccount" class="account-search" :options="accountOptions" :min-chars-for-search="2" />
      </div>
      <template v-if="contextStore.isTechnician">
        <BaseTextSeparator>Or</BaseTextSeparator>
        <BaseButton class="create-account-btn" @click="$emit('create-account')">Create Account</BaseButton>
      </template>
      <BaseCancelButton @click="$emit('back')"
        ><div class="back-label"><IconBack class="back-icon" />Log in using a different user</div></BaseCancelButton
      >
    </template>
    <BaseLoader v-else class="loader" />
  </div>
</template>

<script>
import BaseLoader from '../../components/base/BaseLoader.vue';
import BaseButton from '../../components/base/BaseButton.vue';
import BaseSearchBox from '../../components/base/BaseSearchBox.vue';
import BaseTextSeparator from '../../components/base/BaseTextSeparator.vue';
import { mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import { ERROR_MESSAGES } from '../../consts/appConsts';
import BaseCancelButton from '../../components/base/BaseCancelButton.vue';
import IconBack from '../../components/icons/IconBack.svg?component';

export default {
  name: 'AccountLoginForm',
  components: {
    BaseLoader,
    BaseButton,
    BaseSearchBox,
    BaseTextSeparator,
    BaseCancelButton,
    IconBack
  },
  emits: ['done', 'back', 'create-account'],
  data() {
    return {
      errorMsg: false,
      isLoading: false,
      selectedAccount: null
    };
  },
  computed: {
    ...mapStores(useContextStore),
    accountOptions() {
      return this.contextStore.auth.possibleAccounts.map(account => ({
        code: account.id,
        label: account.name
      }));
    }
  },
  watch: {
    selectedAccount(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.onAccountClick(newVal);
      }
    }
  },
  methods: {
    async onAccountClick() {
      this.isLoading = true;
      this.errorMsg = '';
      const accountId = (this.selectedAccount || {}).code;
      if (accountId) {
        try {
          await this.contextStore.accountLogin(accountId);
          this.isLoading = false;
          this.$emit('done');
        } catch (e) {
          this.errorMsg = e.message;
        }
      } else {
        this.errorMsg = ERROR_MESSAGES.GENERIC_CONTACT_ADMIN;
      }

      if (this.errorMsg) {
        this.$emit('back', this.errorMsg);
      }
      this.isLoading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.account-login {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
  flex-grow: 1;

  .loader {
    margin: auto;
    width: 3rem;
    height: 3rem;
  }

  .choose-account-label {
    color: var(--primaryColorBrightShade1);
    padding-left: 0.5rem;
    margin-bottom: 10px;
  }

  .account-search {
    font-size: 1.1rem;
  }

  .create-account-btn {
    align-self: center;
    background: var(--primaryColorBrightShade2);
  }

  .back-label {
    display: flex;
    column-gap: 0.3rem;
    align-items: center;
    justify-content: center;

    .back-icon {
      width: 1.1rem;
      height: 1.1rem;
    }
  }
}
</style>
