import { defineStore } from 'pinia';
import userManagementService from '../services/api/userManagementService';

export const useUserManagementStore = defineStore('userManagement', {
  state() {
    return {
      usersMap: new Map(),
      rolesMap: new Map(),
      groupsMap: new Map(),
      privilegesMap: new Map(),
      privilegePackagesMap: new Map()
    };
  },
  getters: {
    users() {
      return Array.from(this.usersMap.values());
    },
    roles() {
      return Array.from(this.rolesMap.values());
    },
    groups() {
      return Array.from(this.groupsMap.values());
    },
    privileges() {
      return Array.from(this.privilegesMap.values());
    },
    privilegePackages() {
      return Array.from(this.privilegePackagesMap.values());
    },
    getUserById(state) {
      return userId => {
        return state.usersMap.get(userId);
      };
    },
    getRoleById(state) {
      return roleId => {
        return state.rolesMap.get(roleId);
      };
    },
    getPrivilegeById(state) {
      return privilegeId => {
        return state.privilegesMap.get(privilegeId);
      };
    },
    getGroupById(state) {
      return groupId => {
        return state.groupsMap.get(groupId);
      };
    },
    getPrivilegesTree() {
      let privilegesInPackages = [];
      const mainPackages = [];
      const packagesMap = new Map();
      this.privilegePackages.forEach(privilegePackage => {
        if (privilegePackage.parent_package_id) {
          const parentPackage =
            packagesMap.get(privilegePackage.parent_package_id) || this.privilegePackagesMap.get(privilegePackage.parent_package_id);
          parentPackage.sub_packages = parentPackage.sub_packages || [];
          parentPackage.sub_packages.push(privilegePackage);
          packagesMap.set(parentPackage.id, parentPackage);
        } else {
          mainPackages.push(privilegePackage);
        }

        privilegesInPackages = [...privilegesInPackages, ...privilegePackage.privileges];
      });

      const otherPackage = { id: 0, name: 'Other', privileges: [] };
      this.privileges.forEach(privilege => {
        if (!privilegesInPackages.includes(privilege.id)) {
          otherPackage.privileges.push(privilege.id);
        }
      });
      mainPackages.push(otherPackage);
      return mainPackages;
    }
  },
  actions: {
    async fetchUsers() {
      try {
        this.usersMap = new Map();
        const data = await userManagementService.fetchUsers();
        data.forEach(user => {
          user.id = user.userDetails.id;
          this.usersMap.set(user.id, user);
        });
        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async updateUser(data) {
      try {
        let users = [];

        if (data.id) {
          users.push(await userManagementService.patchUser(data));
        } else if (data.newUsersEmails) {
          users = await userManagementService.createUsers(data);
        }

        users.forEach(user => {
          user.id = user.userDetails.id;
          this.usersMap.set(user.id, user);
        });
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async deleteUser(userId) {
      try {
        await userManagementService.deleteUser(userId);
        this.usersMap.delete(userId);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchRoles() {
      try {
        this.rolesMap = new Map();
        const data = await userManagementService.fetchRoles();
        data.forEach(role => {
          this.rolesMap.set(role.id, role);
        });
        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async updateRole(role) {
      try {
        let _role;
        if (role.id) {
          _role = await userManagementService.patchRole(role);
        } else {
          _role = await userManagementService.createRole(role);
        }
        this.rolesMap.set(_role.id, _role);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async deleteRole(roleId) {
      try {
        await userManagementService.deleteRole(roleId);
        this.rolesMap.delete(roleId);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchGroups() {
      try {
        this.groupsMap = new Map();
        const data = await userManagementService.fetchGroups();
        data.forEach(group => {
          this.groupsMap.set(group.id, group);
        });
        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async updateGroup(group) {
      try {
        let _group;
        if (group.id) {
          _group = await userManagementService.patchGroup(group);
        } else {
          _group = await userManagementService.createGroup(group);
        }
        this.groupsMap.set(_group.id, _group);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async deleteGroup(groupId) {
      try {
        await userManagementService.deleteGroup(groupId);
        this.groupsMap.delete(groupId);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchPrivileges() {
      try {
        if (this.privilegesMap.size === 0) {
          const [privileges, privilegePackages] = await Promise.all([
            userManagementService.fetchPrivileges(),
            userManagementService.fetchPrivilegePackages()
          ]);

          privileges.forEach(privilege => {
            this.privilegesMap.set(privilege.id, privilege);
          });

          privilegePackages.forEach(privilegePackage => {
            this.privilegePackagesMap.set(privilegePackage.id, privilegePackage);
          });
        }

        return this.privileges;
      } catch (e) {
        return Promise.reject(e);
      }
    }
  }
});
