<template>
  <div class="mission-template-hooks-config">
    <div class="content">
      <div class="header">
        <span>{{ capitalize(timing.toLowerCase()) }} hooks</span>
        <BaseDropdown
          class="iot-devices-dropdown"
          :v-model="selectedDeviceId"
          placeholder="Choose device"
          :options="iotControllersOptions"
          @selected="onIotControllerSelected"
        ></BaseDropdown>
      </div>
      <div v-for="hookDevice in hooksDevices" :key="hookDevice.id" class="hook">
        <IconClose class="close-icon" @click="onRemoveHook(hookDevice.id)"><title>Close</title></IconClose>
        <span>{{ hookDevice.name }}</span>
        <BaseOptionsSwitch v-model="hooks[hookDevice.id].state" class="options-switch" :options="stateOptions" @change="onStateSelected" />
        <div class="required-checkbox-container">
          <BaseCheckbox v-model:checked="hooks[hookDevice.id].required" @change="onRequiredChanged($event, hookDevice)" />
          <span>Required</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseOptionsSwitch from '../../components/base/BaseOptionsSwitch.vue';
import IconClose from '../../components/icons/IconClose.svg?component';
import BaseDropdown from '../../components/base/BaseDropdown.vue';
import BaseCheckbox from '../../components/base/BaseCheckbox.vue';
import { useDevicesStore } from '../../store/DevicesStore';
import { mapState } from 'pinia';
import { clone } from '../../utils/ObjectUtils';
import { capitalize } from '../../utils/StringUtils';

const IOT_CONTROLLER_OPTIONS_ARR = [
  { id: 'OFF', label: 'OFF' },
  { id: 'ON', label: 'ON' },
  { id: 'INITIAL', label: 'INITIAL' }
];

const IOT_CONTROLLER_STATE = {
  OFF: 'OFF',
  ON: 'ON',
  INITIAL: 'INITIAL'
};

export default {
  name: 'MissionTemplateHooksConfig',
  components: {
    BaseOptionsSwitch,
    IconClose,
    BaseDropdown,
    BaseCheckbox
  },
  props: {
    timing: {
      type: String,
      default: 'BEFORE'
    },
    existingHooks: {
      type: Array,
      default: () => []
    }
  },
  emits: ['change'],
  data() {
    return {
      isMenuOpen: false,
      hooks: {},
      selectedDeviceId: null
    };
  },
  computed: {
    ...mapState(useDevicesStore, ['contextZoneActiveIotControllers']),
    stateOptions() {
      if (this.timing !== 'AFTER') {
        return IOT_CONTROLLER_OPTIONS_ARR.filter(option => option.id !== IOT_CONTROLLER_STATE.INITIAL);
      }
      return IOT_CONTROLLER_OPTIONS_ARR;
    },
    iotControllersOptions() {
      return this.contextZoneActiveIotControllers
        .filter(zoneIotController => !this.hooks[zoneIotController.id])
        .map(zoneIotController => {
          return {
            id: zoneIotController.id,
            label: zoneIotController.name
          };
        });
    },
    hooksDevices() {
      const hd = this.contextZoneActiveIotControllers.filter(controller => this.hooks[controller.id]);
      return hd;
    },
    initialHooks() {
      return this.existingHooks.reduce((map, hook) => {
        map[hook.device_id] = hook;
        map[hook.device_id].state = hook.action.state;
        return map;
      }, {});
    }
  },
  watch: {
    existingHooks() {
      this.hooks = clone(this.initialHooks);
    }
  },
  created() {
    this.hooks = clone(this.initialHooks);
  },
  methods: {
    capitalize,
    onStateSelected() {
      this.submitConfig();
    },
    onIotControllerSelected(deviceOption) {
      const deviceId = deviceOption.id;
      this.hooks[deviceId] = this.hooks[deviceId] || {};
      this.hooks[deviceId].state = this.stateOptions[0].id;
      this.hooks[deviceId].required = false;
      this.selectedDeviceId = this.selectedDeviceId === 0 ? null : 0;
      this.submitConfig();
    },
    onRemoveHook(deviceId) {
      this.hooks[deviceId] = null;
      this.submitConfig();
    },
    onRequiredChanged() {
      this.submitConfig();
    },
    submitConfig() {
      const hooksData = this.hooksDevices.map(hookDevice => ({
        ...this.hooks[hookDevice.id],
        device_id: hookDevice.id,
        device_name: hookDevice.name,
        device_revision: hookDevice.revision,
        timing: this.timing,
        required: this.hooks[hookDevice.id].required,
        action: { state: this.hooks[hookDevice.id].state }
      }));
      this.$emit('change', Object.values(hooksData));
    }
  }
};
</script>

<style scoped lang="scss">
.mission-template-hooks-config {
  color: var(--textColor);

  .toggle {
    text-transform: capitalize;
    cursor: pointer;

    &::after {
      content: attr(hooks-count);
      border-radius: 50%;
      width: 15px;
      line-height: 15px;
      position: absolute;
      margin: 0 0.3em;
      font-size: 12px;
      text-align: center;
      font-weight: bold;
      z-index: -1;
      background: var(--notificationsBudgeColor);
      color: var(--textColor);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .header {
      display: flex;
      column-gap: 0.5rem;
      align-items: center;

      span {
        flex-shrink: 0;
      }

      .iot-devices-dropdown {
        flex-basis: 80%;
        align-self: center;
      }
    }

    .hook {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 0.7em;
      padding: 0.5rem;
      background: var(--primaryColorBrightShade1);

      span {
        flex-grow: 1;
      }

      .options-switch {
        flex-shrink: 0;
      }

      .required-checkbox-container {
        width: 4.5rem;
        display: flex;
        flex-direction: column;
        row-gap: 0.2em;
        padding: 0 0 0 0.6rem;
        flex-shrink: 0;
        align-items: center;
        border-left: 1px solid var(--highlightColor);

        .required-checkbox {
          padding: 0 1rem 1.5rem 0;
          margin: 0 0 0 1rem;
        }

        span {
          font-size: 0.75em;
        }
      }

      .close-icon {
        width: 1rem;
        height: 1rem;
        opacity: 0.6;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
          opacity: 1;
        }
      }
    }
  }
}
</style>
