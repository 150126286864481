<template>
  <MapToolbox>
    <component
      :is="poiTypeConfig.icon"
      v-for="poiTypeConfig in poiTypesConfig"
      :key="`poi-type-button:${poiTypeConfig.type}`"
      :class="['poi-type-button', { selected: poiTypeConfig.type === modelValue }]"
      :title="poiTypeConfig.title"
      @click="onPoiTypeSelect(poiTypeConfig.type)"
    />
  </MapToolbox>
</template>

<script>
import MapToolbox from './MapToolbox.vue';
import { MAP_POI_OPTIONS, POI_OPTIONS_ICONS } from '../../consts/mapConsts';
import { shallowRef } from 'vue';

export default {
  name: 'MapMissionEditToolbox',
  components: { MapToolbox },
  props: {
    modelValue: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      poiTypesConfig: [
        {
          icon: shallowRef(POI_OPTIONS_ICONS[MAP_POI_OPTIONS.VISIT]),
          type: MAP_POI_OPTIONS.VISIT,
          title: 'WayPoint'
        },
        {
          icon: shallowRef(POI_OPTIONS_ICONS[MAP_POI_OPTIONS.SCAN]),
          type: MAP_POI_OPTIONS.SCAN,
          title: 'Scan 360°'
        },
        {
          icon: shallowRef(POI_OPTIONS_ICONS[MAP_POI_OPTIONS.INSPECTION]),
          type: MAP_POI_OPTIONS.INSPECTION,
          title: 'Inspect'
        },
        {
          icon: shallowRef(POI_OPTIONS_ICONS[MAP_POI_OPTIONS.HIGH_INSPECTION]),
          type: MAP_POI_OPTIONS.HIGH_INSPECTION,
          title: 'Inspect High'
        },
        {
          icon: shallowRef(POI_OPTIONS_ICONS[MAP_POI_OPTIONS.LOW_INSPECTION]),
          type: MAP_POI_OPTIONS.LOW_INSPECTION,
          title: 'Inspect Low'
        }
      ]
    };
  },
  computed: {
    selectedPoiType() {
      return this.value || MAP_POI_OPTIONS.VISIT;
    }
  },
  methods: {
    onPoiTypeSelect(poiType) {
      this.$emit('update:modelValue', poiType);
    }
  }
};
</script>

<style scoped lang="scss">
.poi-type-button {
  cursor: pointer;
  transition: 0.2s cubic-bezier(0.77, 1.44, 0.76, 1.43);
  height: 2.7rem;
  width: 2.7rem;

  &.selected {
    border-radius: 50%;
    box-shadow: 0 0 0px 2px var(--textColor);
  }

  &:hover {
    filter: brightness(1.2);
    transform: scale(1.05);
  }
}
</style>
