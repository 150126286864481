<template>
  <div class="scheduler-details-container">
    <div class="header">Schedule Activities</div>
    <div class="scheduler-details">
      <div v-if="wasDeviceSelected" class="choose-device-container">
        <div class="label">Choose device:</div>
        <BaseDropdown v-model="selectedDeviceId" class="dropdown" :options="devicesOptions"></BaseDropdown>
        <BaseLoader v-if="isLoading" />
        <div v-if="!isLoading && wasDeviceSelected">
          Status:
          <span style="text-transform: capitalize">{{ stateStr }}</span>
        </div>
      </div>
      <div v-if="!devicesOptions.length">No devices to choose from</div>
      <div v-if="!isLoading && wasDeviceSelected">
        <ActivitySchedulerDocked
          ref="activitySchedulerDocked"
          :device-id="selectedDeviceId"
          :zone-schedule="updatedZoneSchedule"
          @popup-open="onDockedPopupOpen"
          @save="onApplyDockedActions"
        ></ActivitySchedulerDocked>
      </div>
      <div v-if="!isLoading && wasDeviceSelected">
        <ActivitySchedulerMission
          ref="activitySchedulerMission"
          :device-id="selectedDeviceId"
          :zone-schedule="updatedZoneSchedule"
          @patrol-dropdown-change="onPatrolDropdownChange"
          @popup-open="onMissionPopupOpen"
          @save="onApplyMissionActions"
        ></ActivitySchedulerMission>
      </div>
      <div v-if="isChanged" class="section section-buttons">
        <BaseLoader v-if="isSaving"></BaseLoader>
        <BaseButton v-if="!isSaving" @click="onSaveClick">Save changes</BaseButton>
        <BaseCancelButton v-if="!isSaving" class="revert-button" @click="onRevertClick">Revert changes</BaseCancelButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLoader from '../../components/base/BaseLoader.vue';
import ActivitySchedulerDocked from './ActivitySchedulerDocked.vue';
import ActivitySchedulerMission from './ActivitySchedulerMission.vue';
import { isRobot, isVideoStreamingDevice, isMotionDetectionDevice } from '../../utils/models/DeviceUtils';
import BaseDropdown from '../../components/base/BaseDropdown.vue';
import BaseButton from '../../components/base/BaseButton.vue';
import BaseCancelButton from '../../components/base/BaseCancelButton.vue';
import { useContextStore } from '../../store/ContextStore';
import { useDevicesStore } from '../../store/DevicesStore';
import { useMissionTemplatesStore } from '../../store/MissionTemplatesStore';
import { useZonesStore } from '../../store/ZonesStore';
import { mapActions, mapState } from 'pinia';
import { clone } from '../../utils/ObjectUtils';
import { buildZoneScheduleDayObject, clearDayConfiguration, clearHandler, buildZoneScheduleObject } from '../../utils/ScheduleUtils';
import { SNACKBAR_TYPE } from '../../consts/appConsts';

export default {
  name: 'SchedulerPanel',
  components: {
    BaseButton,
    BaseLoader,
    ActivitySchedulerDocked,
    ActivitySchedulerMission,
    BaseDropdown,
    BaseCancelButton
  },
  async beforeRouteUpdate(to, from, next) {
    this.initDeviceScheduler();
  },
  emits: ['missionTemplateSelected'],
  data() {
    return {
      isLoading: true,
      selectedDeviceId: -1,
      selectedMissionId: null,
      updatedZoneSchedule: {},
      isSaving: false
    };
  },
  computed: {
    ...mapState(useContextStore, ['zoneId', 'accountId']),
    ...mapState(useDevicesStore, ['contextZoneActiveDevices']),
    ...mapState(useZonesStore, ['getZoneScheduleByDevice']),
    ...mapState(useMissionTemplatesStore, ['missionTemplates']),
    originZoneSchedule() {
      return this.getZoneScheduleByDevice(this.selectedDeviceId) || {};
    },
    stateStr() {
      let state = this.updatedZoneSchedule.state ? this.updatedZoneSchedule.state.toLowerCase() : 'Active';
      return state;
    },
    devicesOptions() {
      const list = [];
      this.contextZoneActiveDevices.forEach(device => {
        if (isRobot(device) || isVideoStreamingDevice(device) || isMotionDetectionDevice(device)) {
          list.push({ id: device.id, label: device.name });
        }
      });
      return list;
    },
    isChanged() {
      return this.updatedZoneSchedule.state === 'DRAFT';
    },
    wasDeviceSelected() {
      return this.selectedDeviceId !== -1;
    }
  },
  watch: {
    originZoneSchedule: {
      deep: true,
      handler: function () {
        if (!this.isChanged) {
          this.updatedZoneSchedule = clone(this.originZoneSchedule);
          this.rebuildSchedulers();
        }
      }
    },
    selectedDeviceId: {
      handler: function () {
        this.initDeviceScheduler();
      }
    },
    devicesOptions: {
      immediate: true,
      handler: function () {
        if (this.selectedDeviceId === -1 && this.devicesOptions?.length) {
          this.selectedDeviceId = this.devicesOptions?.[0]?.id || -1;
        }
      }
    }
  },
  methods: {
    ...mapActions(useContextStore, ['showSnackbar']),
    ...mapActions(useZonesStore, ['saveZoneSchedules', 'fetchZoneSchedules', 'setZoneSchedule']),
    async initDeviceScheduler() {
      this.isLoading = true;
      await this.fetchZoneSchedules(this.selectedDeviceId);
      this.updatedZoneSchedule = clone(this.originZoneSchedule);
      this.selectedMissionId = null;
      this.rebuildSchedulers();
      this.isLoading = false;
    },
    onDockedPopupOpen() {
      this.$refs.activitySchedulerMission.closeSelectedCellsPopup();
    },
    async onApplyDockedActions({ videoActions, motionActions }) {
      this.updatedZoneSchedule = this.updateZoneDockedSchedules({
        existingZoneSchedules: this.updatedZoneSchedule,
        deviceId: this.selectedDeviceId,
        videoActions,
        motionActions
      });
    },
    onMissionPopupOpen() {
      this.$refs.activitySchedulerDocked.closeSelectedCellsPopup();
    },
    async onPatrolDropdownChange(missionId) {
      this.selectedMissionId = missionId;
      const missionTemplate = this.missionTemplates?.find(mission => mission.id === missionId);
      this.$emit('missionTemplateSelected', missionTemplate);
    },
    async onApplyMissionActions({ actions }) {
      this.updatedZoneSchedule = this.updateZoneMissionsSchedules({
        existingZoneSchedules: this.updatedZoneSchedule,
        deviceId: this.selectedDeviceId,
        missionActions: actions
      });
      this.rebuildSchedulers();
    },
    onRevertClick() {
      this.updatedZoneSchedule = clone(this.originZoneSchedule);
      this.rebuildSchedulers();
    },
    async onSaveClick() {
      this.isSaving = true;
      try {
        const schedule = await this.saveZoneSchedules(this.updatedZoneSchedule);
        this.updatedZoneSchedule = schedule;
        this.rebuildSchedulers();
        this.showSnackbar({
          message: 'Schedules saved successfully',
          type: SNACKBAR_TYPE.SUCCESS
        });
      } catch (err) {
        console.error(err);
        this.showSnackbar({
          message: 'Failed to save schedules',
          type: SNACKBAR_TYPE.ERROR
        });
      } finally {
        this.isSaving = false;
      }
    },
    rebuildSchedulers() {
      this.$nextTick(() => {
        if (this.$refs.activitySchedulerMission && this.$refs.activitySchedulerDocked) {
          this.$refs.activitySchedulerMission.resetSelectedIndexes();
          this.$refs.activitySchedulerMission.buildDaysHoursMap();
          this.$refs.activitySchedulerDocked.buildDaysHoursMap();
        }
      });
    },
    updateZoneDockedSchedules({ existingZoneSchedules, deviceId, videoActions, motionActions }) {
      let zoneSchedules = buildZoneScheduleObject({
        accountId: this.accountId,
        zoneId: this.zoneId,
        deviceId
      });
      zoneSchedules.state = 'DRAFT';
      zoneSchedules.data = clone(existingZoneSchedules.data || []);

      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        let existingDayConfiguration = zoneSchedules.data.find(dayConfiguration => dayConfiguration.day === dayIndex);
        const newDayConfiguration = existingDayConfiguration ? clone(existingDayConfiguration) : buildZoneScheduleDayObject({ dayIndex });
        clearDayConfiguration(zoneSchedules.data, dayIndex);
        clearHandler(newDayConfiguration.schedules, 'video');
        clearHandler(newDayConfiguration.schedules, 'motion');

        const videoActionsArr = this.buildRangeActionsByDay(videoActions, dayIndex);
        if (videoActionsArr.length > 0) {
          newDayConfiguration.schedules.push({
            handler: 'video',
            actions: videoActionsArr
          });
        }

        const motionActionsArr = this.buildRangeActionsByDay(motionActions, dayIndex);
        if (motionActionsArr.length > 0) {
          newDayConfiguration.schedules.push({
            handler: 'motion',
            actions: motionActionsArr
          });
        }

        if (newDayConfiguration.schedules.length > 0) {
          zoneSchedules.data.push(newDayConfiguration);
        }
      }

      return zoneSchedules;
    },
    buildRangeActionsByDay(originActions, dayIndex) {
      const nextDayIndex = dayIndex === 6 ? 0 : dayIndex + 1;
      const dayActions = originActions.filter(action => action.day === dayIndex);
      const nextDayActions = originActions.filter(action => action.day === nextDayIndex);
      const actions = dayActions.map(dayAction => {
        const action = {
          type: 'range',
          enabled: true,
          start: {
            data: {
              action: 'start'
            },
            time: dayAction.start
          },
          end: {
            data: {
              action: 'stop'
            },
            time: dayAction.end
          }
        };

        if (dayAction.end === '24:00') {
          const nextDayStartsAt00 = nextDayActions.find(nextDayAction => nextDayAction.start === '00:00');
          if (nextDayStartsAt00) {
            delete action.end;
          }
        }
        return action;
      });
      return actions;
    },
    updateZoneMissionsSchedules({ existingZoneSchedules, deviceId, missionActions }) {
      let zoneSchedules = buildZoneScheduleObject({
        accountId: this.accountId,
        zoneId: this.zoneId,
        deviceId
      });
      zoneSchedules.state = 'DRAFT';
      zoneSchedules.data = clone(existingZoneSchedules.data || []);

      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        let existingDayConfiguration = zoneSchedules.data.find(dayConfiguration => dayConfiguration.day === dayIndex);
        const newDayConfiguration = existingDayConfiguration ? clone(existingDayConfiguration) : buildZoneScheduleDayObject({ dayIndex });
        clearDayConfiguration(zoneSchedules.data, dayIndex);
        clearHandler(newDayConfiguration.schedules, 'mission');

        const missionActionsArr = this.buildMissionActionsByDay(missionActions, dayIndex);
        if (missionActionsArr.length > 0) {
          newDayConfiguration.schedules.push({
            handler: 'mission',
            actions: missionActionsArr
          });
        }

        if (newDayConfiguration.schedules.length > 0) {
          zoneSchedules.data.push(newDayConfiguration);
        }
      }

      return zoneSchedules;
    },
    buildMissionActionsByDay(originActions, dayIndex) {
      const dayActions = originActions.filter(action => action.day === dayIndex);
      const actions = dayActions.map(action => {
        let config = {};
        if (action.missionTemplate) {
          config = {
            missionTemplateId: action.missionTemplate.id,
            missionTemplateRevision: action.missionTemplate.revision
          };
        } else if (action.tile) {
          config = {
            tileId: action.tile.id,
            tileName: action.tile.name,
            tilePhysicalId: action.tile.physical_id,
            tileLocation: action.tile.location
          };
        }
        return {
          time: action.start,
          data: {
            action: 'start',
            config: config
          },
          type: 'single',
          enabled: true
        };
      });

      return actions;
    }
  }
};
</script>

<style scoped lang="scss">
.scheduler-details-container {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  flex-basis: calc(100% - 3rem);
  padding: 1rem 0.5rem 0 1rem;
  overflow-y: hidden;

  .header {
    font-family: var(--font-family-primary);
    font-size: 2rem;
    color: var(--textColor);
  }

  .scheduler-details {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    row-gap: 1.2rem;
    padding: 0.5rem 0;

    .choose-device-container {
      color: var(--textColor);
      font-family: var(--font-family-secondary);
      display: flex;
      align-items: center;
      column-gap: 1rem;

      .dropdown {
        flex-basis: 15rem;
      }
    }

    .section-buttons {
      display: flex;
      column-gap: 1rem;
      align-items: baseline;

      button {
        transition: 0.5s ease;

        &:active {
          transform: scale(1.05);
        }
      }

      .revert-button {
        :deep(.button) {
          background: transparent;
        }
      }
    }
  }
}
</style>
