<template>
  <div v-if="deviceSupportsDockedActivities" class="activity-scheduler-docked">
    <div class="header">Activities while device docked on the tile:</div>
    <BaseScheduler
      v-if="isDeviceSupportMotion || isDeviceSupportVideo"
      ref="scheduler"
      :days-hours-map="daysHoursMap"
      :minutes-interval="minutesInterval"
      @select-cells="onSelectCells"
      @popup-open="onPopupOpen"
      @popup-close="onPopupClose"
    >
      <template #popup>
        <div class="on-tile-popup-container">
          <div class="popup-header">Schedule Activity</div>
          <div class="popup-days-container">
            <span class="label">Days: </span>
            <span class="value">{{ selectedDaysRangeStr }}</span>
          </div>
          <div class="popup-hours-container">
            <span class="label">Hours: </span>
            <span class="value">{{ selectedHoursRangeStr }}</span>
          </div>
          <div class="popup-checkboxes-container theme-light">
            <div v-show="isDeviceSupportVideo" class="video-streaming-container">
              <BaseCheckbox
                v-model:checked="videoStreamingCheckboxState"
                v-model:indeterminate="videoStreamingIndeterminateState"
                @change="onVideoStreamCheckboxChange"
                >Video recording <span class="legend video-enabled"></span
              ></BaseCheckbox>
            </div>
            <div v-show="isDeviceSupportMotion" class="motion-detection-container">
              <BaseCheckbox
                v-model:checked="motionDetectionCheckboxState"
                v-model:indeterminate="motionDetectionIndeterminateState"
                @change="onMotionDetectionCheckboxChange"
                >Motion alerts <span class="legend motion-enabled"></span
              ></BaseCheckbox>
            </div>
          </div>
          <div class="popup-buttons-container">
            <BaseButton class="save-button" @click="onSaveClick">Apply</BaseButton>
          </div>
        </div>
      </template>
    </BaseScheduler>
    <div v-else class="not-support-message">This device does not support any scheduled activity while docked</div>
  </div>
</template>

<script>
import BaseScheduler from '../../components/base/BaseScheduler.vue';
import BaseButton from '../../components/base/BaseButton.vue';
import BaseCheckbox from '../../components/base/BaseCheckbox.vue';
import { DEVICE_PROPERTIES, DEVICES_TYPES } from '../../consts/deviceConsts';
import { useDevicesStore } from '../../store/DevicesStore';
import { mapActions } from 'pinia';
import { getScheduledMotionDetection, getScheduledVideoStreaming } from '../../utils/ScheduleUtils';

export default {
  name: 'ActivitySchedulerDocked',
  components: {
    BaseCheckbox,
    BaseButton,
    BaseScheduler
  },
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    zoneSchedule: {
      type: Object,
      required: true
    }
  },
  emits: ['save', 'popupOpen', 'selectCells'],
  data() {
    return {
      selectedCells: {},
      selectedDaysRangeStr: '',
      selectedHoursRangeStr: '',
      videoStreamingCheckboxState: false,
      videoStreamingIndeterminateState: false,
      motionDetectionCheckboxState: false,
      motionDetectionIndeterminateState: false,
      daysHoursMap: {},
      minutesInterval: 15,
      isPopupOpen: false
    };
  },
  computed: {
    scheduledVideoStreamingIndexes() {
      const indexes = [];
      getScheduledVideoStreaming(this.zoneSchedule).forEach(dayConfiguration => {
        dayConfiguration.actions.forEach(actionConfiguration => {
          const fromLabel = actionConfiguration.start.time;
          const toLabel = actionConfiguration.end ? actionConfiguration.end.time : '24:00';
          const fromIndex = this.getHourIndexByLabel(fromLabel);
          const toIndex = this.getHourIndexByLabel(toLabel) - 1;
          for (let i = fromIndex; i <= toIndex; i++) {
            indexes.push({
              dayIndex: dayConfiguration.day,
              hourIndex: i
            });
          }
        });
      });
      return indexes;
    },
    scheduledMotionDetectionIndexes() {
      const indexes = [];
      getScheduledMotionDetection(this.zoneSchedule).forEach(dayConfiguration => {
        dayConfiguration.actions.forEach(actionConfiguration => {
          const fromLabel = actionConfiguration.start.time;
          const toLabel = actionConfiguration.end ? actionConfiguration.end.time : '24:00';
          const fromIndex = this.getHourIndexByLabel(fromLabel);
          const toIndex = this.getHourIndexByLabel(toLabel) - 1;
          for (let i = fromIndex; i <= toIndex; i++) {
            indexes.push({
              dayIndex: dayConfiguration.day,
              hourIndex: i
            });
          }
        });
      });
      return indexes;
    },
    device() {
      return this.getDeviceById(this.deviceId);
    },
    isDeviceSupportVideo() {
      let video = false;
      if (this.device) {
        video = this.device.props.includes(DEVICE_PROPERTIES.VIDEO_STREAM);
      } else if (this.deviceId === -1) {
        video = true;
      }
      return video;
    },
    isDeviceSupportMotion() {
      let motion = false;
      if (this.device) {
        motion = this.device.props.includes(DEVICE_PROPERTIES.MOTION_SENSOR);
      } else if (this.deviceId === -1) {
        motion = true;
      }
      return motion;
    },
    deviceSupportsDockedActivities() {
      const device = this.getDeviceById(this.deviceId);
      return device.type_id !== DEVICES_TYPES.SKYDIO; // check if the device type is skydio then hides it
    }
  },
  created() {
    this.buildDaysHoursMap();
  },
  methods: {
    ...mapActions(useDevicesStore, ['getDeviceById']),
    buildDaysHoursMap() {
      const daysHoursMap = {};
      this.scheduledVideoStreamingIndexes.forEach(indexObj => {
        daysHoursMap[indexObj.dayIndex] = daysHoursMap[indexObj.dayIndex] || {};
        daysHoursMap[indexObj.dayIndex][indexObj.hourIndex] = {
          isVideo: true,
          classNames: ['video-enabled']
        };
      });
      this.scheduledMotionDetectionIndexes.forEach(indexObj => {
        daysHoursMap[indexObj.dayIndex] = daysHoursMap[indexObj.dayIndex] || {};
        daysHoursMap[indexObj.dayIndex][indexObj.hourIndex] = daysHoursMap[indexObj.dayIndex][indexObj.hourIndex] || {};
        daysHoursMap[indexObj.dayIndex][indexObj.hourIndex].isMotion = true;
        daysHoursMap[indexObj.dayIndex][indexObj.hourIndex].classNames = daysHoursMap[indexObj.dayIndex][indexObj.hourIndex].classNames || [];
        daysHoursMap[indexObj.dayIndex][indexObj.hourIndex].classNames.push('motion-enabled');
      });
      this.daysHoursMap = daysHoursMap;
    },
    onSelectCells(selectedCells) {
      this.selectedCells = selectedCells;
      if (selectedCells.selectedIndexes.length > 0) {
        this.selectedDaysRangeStr =
          selectedCells.minDayLabel === selectedCells.maxDayLabel
            ? selectedCells.minDayLabel
            : `${selectedCells.minDayLabel} - ${selectedCells.maxDayLabel}`;
        this.selectedHoursRangeStr = `${selectedCells.minHourLabel} - ${selectedCells.maxHourLabel}`;
        this.calcMotionDetectionCheckboxStateBySelectedCells(selectedCells);
        this.calcVideoStreamingCheckboxStateBySelectedCells(selectedCells);

        this.$emit('selectCells', selectedCells);
      }
    },
    onPopupClose() {
      this.isPopupOpen = false;
      this.buildDaysHoursMap();
    },
    onPopupOpen() {
      this.isPopupOpen = true;
      this.$emit('popupOpen');
    },
    onVideoStreamCheckboxChange() {
      this.updateDaysHoursMapWithSelectedCells({
        attr: 'isVideo',
        state: this.videoStreamingCheckboxState,
        className: 'video-enabled'
      });
    },
    onMotionDetectionCheckboxChange() {
      this.updateDaysHoursMapWithSelectedCells({
        attr: 'isMotion',
        state: this.motionDetectionCheckboxState,
        className: 'motion-enabled'
      });
    },
    onSaveClick() {
      const videoActions = this.buildActions('isVideo');
      const motionActions = this.buildActions('isMotion');
      this.$refs.scheduler.closeSelectedCellsPopup();
      this.isPopupOpen = false;
      this.$emit('save', { videoActions, motionActions });
    },
    closeSelectedCellsPopup() {
      if (this.isPopupOpen) {
        this.$refs.scheduler.closeSelectedCellsPopup();
        this.onPopupClose();
      }
    },
    updateDaysHoursMapWithSelectedCells({ attr, state, className }) {
      this.selectedCells.selectedIndexes.forEach(indexObj => {
        if (!this.daysHoursMap[indexObj.dayIndex]) {
          this.daysHoursMap[indexObj.dayIndex] = {};
        }
        if (!this.daysHoursMap[indexObj.dayIndex][indexObj.hourIndex]) {
          this.daysHoursMap[indexObj.dayIndex][indexObj.hourIndex] = {};
        }
        this.daysHoursMap[indexObj.dayIndex][indexObj.hourIndex][attr] = state;
        let classNames = this.daysHoursMap[indexObj.dayIndex][indexObj.hourIndex].classNames
          ? [...this.daysHoursMap[indexObj.dayIndex][indexObj.hourIndex].classNames]
          : [];
        const videoClassIndex = classNames.indexOf(className);
        if (state && videoClassIndex === -1) {
          classNames.push(className);
        }
        if (!state && videoClassIndex > -1) {
          classNames.splice(videoClassIndex, 1);
        }
        this.daysHoursMap[indexObj.dayIndex][indexObj.hourIndex].classNames = classNames;
      });
    },
    buildActions(attr) {
      const intervals = 60 / this.minutesInterval;
      const maxHourIndex = intervals * 24;
      const actions = [];
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        let action = {};
        let inAction = false;
        for (let hourIndex = 0; hourIndex < maxHourIndex; hourIndex++) {
          const isEnabled =
            this.daysHoursMap[dayIndex] && this.daysHoursMap[dayIndex][hourIndex] && this.daysHoursMap[dayIndex][hourIndex][attr] === true;
          if (isEnabled) {
            if (!inAction) {
              action.day = dayIndex;
              action.start = this.getHourLabelByIndex(hourIndex);
            }
            action.end = this.getHourLabelByIndex(hourIndex + 1);
            inAction = true;
          } else {
            if (inAction) {
              actions.push(action);
            }
            inAction = false;
            action = {};
          }
        }
        if (inAction) {
          actions.push(action);
        }
      }
      return actions;
    },
    isDayHourInMotionDetection(dayIndex, hourIndex) {
      return this.daysHoursMap[dayIndex] && this.daysHoursMap[dayIndex][hourIndex] && this.daysHoursMap[dayIndex][hourIndex].isMotion;
    },
    isDayHourInVideoStream(dayIndex, hourIndex) {
      return this.daysHoursMap[dayIndex] && this.daysHoursMap[dayIndex][hourIndex] && this.daysHoursMap[dayIndex][hourIndex].isVideo;
    },
    calcMotionDetectionCheckboxStateBySelectedCells(selectedCells) {
      // initialize state
      // loop through the selected cells
      for (let i = 0; i < selectedCells.selectedIndexes.length; i++) {
        // get the day and hour index
        const { dayIndex, hourIndex } = selectedCells.selectedIndexes[i];
        // if the day and hour index is in the motion detection array then
        if (this.isDayHourInMotionDetection(dayIndex, hourIndex)) {
          // if the state is unchecked then
          if (!this.motionDetectionCheckboxState) {
            // set the state to indeterminate
            this.motionDetectionIndeterminateState = true;
          } else if (!this.motionDetectionCheckboxState) {
            // if the state is not set then set the state to checked
            this.motionDetectionCheckboxState = true;
          }
        } else {
          // if the state is checked then
          if (this.motionDetectionCheckboxState) {
            // set the state to indeterminate
            this.motionDetectionIndeterminateState = true;
          } else if (!this.motionDetectionCheckboxState) {
            // if the state is not set then set the state to unchecked
            this.motionDetectionCheckboxState = false;
          }
        }
        // if the state is indeterminate then stop the loop
        if (this.motionDetectionIndeterminateState) {
          break;
        }
      }
    },
    calcVideoStreamingCheckboxStateBySelectedCells(selectedCells) {
      for (let i = 0; i < selectedCells.selectedIndexes.length; i++) {
        const { dayIndex, hourIndex } = selectedCells.selectedIndexes[i];
        if (this.isDayHourInVideoStream(dayIndex, hourIndex)) {
          if (!this.videoStreamingCheckboxState) {
            this.videoStreamingIndeterminateState = true;
          } else if (!this.videoStreamingCheckboxState) {
            this.videoStreamingCheckboxState = true;
          }
        } else {
          if (this.videoStreamingCheckboxState) {
            this.videoStreamingIndeterminateState = true;
          } else if (!this.videoStreamingCheckboxState) {
            this.videoStreamingCheckboxState = false;
          }
        }
        if (this.videoStreamingIndeterminateState) {
          break;
        }
      }
    },
    getHourIndexByLabel(hourLabel) {
      const intervals = 60 / this.minutesInterval;
      const hour = Number(hourLabel.split(':')[0]);
      const minutes = Number(hourLabel.split(':')[1]);
      return hour * intervals + minutes / this.minutesInterval;
    },
    getHourLabelByIndex(hourIndex) {
      const intervals = 60 / this.minutesInterval;
      let hour = Math.floor(hourIndex / intervals);
      hour = hour < 10 ? `0${hour}` : hour;
      const minutes = hourIndex % intervals === 0 ? '00' : `${(hourIndex % intervals) * this.minutesInterval}`;
      return `${hour}:${minutes}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.activity-scheduler-docked {
  --color-coral: #f37559;
  --color-orange: #f5a623;
  --color-gray-dark-5: #524d4d;
  --color-gray-dark-6: #404746;
  --color-orange-h: 37.43deg;
  --color-orange-s: 91.3%;
  --color-orange-l: 54.9%;
  --color-coral-h: 10.91deg;
  --color-coral-s: 86.52%;
  --color-coral-l: 65.1%;
  --color-orange-40: hsl(var(--color-orange-h), var(--color-orange-s), calc(var(--color-orange-l) - 40%));
  --color-orange-20: hsl(var(--color-orange-h), var(--color-orange-s), calc(var(--color-orange-l) - 20%));
  --color-coral-20: hsl(var(--color-coral-h), var(--color-coral-s), calc(var(--color-coral-l) - 20%));
  --color-coral-40: hsl(var(--color-coral-h), var(--color-coral-s), calc(var(--color-coral-l) - 40%));

  --scheduler-cell-background-motion: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 13px,
    var(--color-orange) 13px,
    var(--color-orange) 30px
  );
  --scheduler-cell-background-motion-candidate: repeating-linear-gradient(
    -45deg,
    var(--color-gray-dark-6),
    var(--color-gray-dark-6) 13px,
    var(--color-orange-20) 13px,
    var(--color-orange-20) 30px
  );
  --scheduler-cell-background-motion-selected: repeating-linear-gradient(
    -45deg,
    var(--color-gray-dark-5),
    var(--color-gray-dark-5) 13px,
    var(--color-orange-40) 13px,
    var(--color-orange-40) 30px
  );
  --scheduler-cell-background-video-motion: repeating-linear-gradient(
    -45deg,
    var(--color-coral),
    var(--color-coral) 13px,
    var(--color-orange) 13px,
    var(--color-orange) 30px
  );
  --scheduler-cell-background-video-motion-candidate: repeating-linear-gradient(
    -45deg,
    var(--color-coral-20),
    var(--color-coral-20) 13px,
    var(--color-orange-20) 13px,
    var(--color-orange-20) 30px
  );
  --scheduler-cell-background-video-motion-selected: repeating-linear-gradient(
    -45deg,
    var(--color-coral-40),
    var(--color-coral-40) 13px,
    var(--color-orange-40) 13px,
    var(--color-orange-40) 30px
  );

  .header {
    color: var(--textColor);
    font-family: var(--font-family-secondary);
    font-size: 16px;
    margin-bottom: 15px;
  }

  .not-support-message {
    color: var(--textColor);
    font-family: var(--font-family-secondary);
    font-size: 14px;
  }

  :deep(.video-enabled) {
    background: var(--highlightColor) !important;

    &.candidate {
      background: var(--color-coral-20) !important;
    }

    &.selected {
      background: var(--color-coral-40) !important;
    }
  }

  :deep(.video-enabled.motion-enabled) {
    background: var(--scheduler-cell-background-video-motion) !important;

    &.candidate {
      background: var(--scheduler-cell-background-video-motion-candidate) !important;
    }

    &.selected {
      background: var(--scheduler-cell-background-video-motion-selected) !important;
    }
  }

  :deep(.motion-enabled) {
    background: var(--scheduler-cell-background-motion) !important;

    &.candidate {
      background: var(--scheduler-cell-background-motion-candidate) !important;
    }

    &.selected {
      background: var(--scheduler-cell-background-motion-selected) !important;
    }
  }

  :deep(.on-tile-popup-container) {
    min-width: 300px;

    .popup-header {
      font-family: var(--font-family-primary);
      text-transform: uppercase;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .popup-days-container,
    .popup-hours-container {
      margin-top: 5px;
      font-size: 14px;

      .label {
        font-weight: bold;
      }
    }

    .popup-checkboxes-container {
      margin-top: 20px;

      .motion-detection-container {
        margin-top: 10px;
      }

      .legend {
        display: inline-block;
        width: 16px;
        height: 18px;
        border: 2px solid var(--secondaryColor);
        vertical-align: middle;
        margin-left: 10px;
      }
    }

    .popup-buttons-container {
      margin-top: 20px;

      .save-button {
        float: right;
      }
    }
  }
}
</style>
