<script setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://vuejs.org/api/sfc-script-setup.html#script-setup
import BaseConfirmationDialog from './components/base/BaseConfirmationDialog.vue';
import BaseSnackbar from './components/base/BaseSnackbar.vue';
import MissionDialog from './components/MissionDialog.vue';
import NavBar from './components/NavBar/NavBar.vue';
import BaseLoader from './components/base/BaseLoader.vue';
import { PAGES } from './router/index.js';

import { storeToRefs } from 'pinia';
import { computed, watch, onBeforeMount } from 'vue';
import { initSocket, closeSocket } from './services/SocketService.js';
import { useContextStore } from './store/ContextStore.js';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
const route = useRoute();
const router = useRouter();

const contextStore = useContextStore();
const { isActive } = storeToRefs(contextStore);
const INACTIVITY_DETECTION_INTERVAL = 180000; // 3 minutes
// const NEXT_SCHEDULES_AMOUNT = 3;
let inactivityDetectionInterval;
const isAuthenticated = computed(() => contextStore.auth.isAuthenticated && route.meta?.requiresAuth);

onBeforeMount(async () => {
  document.body.classList.add(`theme-dark`);
});

async function initAppData() {
  initSocket();
  inactivityDetectionInterval = setInterval(async () => {
    if (isActive) {
      try {
        await contextStore.refreshToken();
        contextStore.updateActivity(false);
      } catch (err) {
        contextStore.logoutAndClearData();
        console.debug('token refresh failed', err);
        router.push({
          name: PAGES.LOGIN,
          params: {
            hasSessionExpired: true
          }
        });
        clearAppData();
      }
    } else {
      contextStore.ping();
    }
  }, INACTIVITY_DETECTION_INTERVAL);
}

function clearAppData() {
  clearInterval(inactivityDetectionInterval);
  closeSocket();
}

axios.interceptors.request.use(req => {
  if (!req.ignoreActivity) {
    contextStore.$patch({ isActive: true });
  }
  delete req.ignoreActivity;
  return req;
});
axios.interceptors.response.use(undefined, err => {
  if (err?.response?.status === 401 && err?.response?.data?.message?.includes?.('Token is invalid')) {
    contextStore.logoutAndClearData();
  }
  return Promise.reject(err);
});

watch(
  isAuthenticated,
  async function isAuthenticatedWatch(newVal, oldVal) {
    if (!oldVal && newVal) {
      // Logged in
      await initAppData();
    } else if (oldVal && !newVal) {
      // Logged out
      clearAppData();
      contextStore.logoutAndClearData();
    }
  },
  { immediate: true }
);
</script>

<template>
  <NavBar v-if="$route.meta?.requiresAuth" />
  <div v-if="contextStore.showAppLoader || contextStore.auth.isSessionLoading" class="main-loader-container">
    <span v-if="contextStore.auth.isSessionLoading">Restoring Session ...</span>
    <BaseLoader class="main-loader" />
  </div>
  <router-view v-else></router-view>
  <BaseConfirmationDialog />
  <MissionDialog v-if="contextStore.showMissionDialog" />
  <BaseSnackbar />
</template>

<style lang="scss">
@import './themes.scss';
</style>
