import videojs from 'video.js';
const Plugin = videojs.getPlugin('plugin');
const MAX_ZOOM = 7;

class ZoomAndDragPlugin extends Plugin {
  constructor(player, options) {
    super(player, options);
    this.player = player;
    this.minZoom = options.minZoom || 1;
    this.containerId = options.containerId;
    this.overlayId = options.overlayId;

    this.zoom = this.minZoom;
    this.dragStart = null;
    this.playerPos = {};
    this.lastPlayerFocusPoint = null;
    this.lastVideoOffsets = null;

    const container = document.getElementById(this.containerId);
    container.addEventListener('mousewheel', ev => this.onScroll(ev));
    container.addEventListener('mousedown', ev => this.onStartDragging(ev));
    container.addEventListener('mousemove', ev => this.onDrag(ev));
    container.addEventListener('mouseup', ev => this.onStopDragging(ev));
    this.stopDraggingFunc = ev => this.onStopDragging(ev);
  }

  dispose() {
    super.dispose();
    window.removeEventListener('mouseup', this.stopDraggingFunc);
  }

  /**
   * @param {Object} settings
   * @param {Boolean} settings.clearDragState = reset zoon and drag to initial pos
   * @param {Boolean} settings.setDragState = start dragging (initial pos should be stored)
   * @param {Boolean} settings.drag
   * @param {Number} settings.zoom
   * @param {Object} settings.posOnPlayer = focus point
   */
  zoomAndDrag(settings) {
    const options = { zoom: this.minZoom, ...settings };
    const player = this.player.el();
    const videoElements = player.getElementsByTagName('video');
    const video = videoElements[0];
    const canvas = player.getElementsByTagName('canvas')[0];
    const poster = player.getElementsByTagName('div')[1];
    const elementsToZoomAndDrag = [video];

    if (canvas) {
      elementsToZoomAndDrag.push(canvas);
    }
    if (poster) {
      elementsToZoomAndDrag.push(poster);
    }

    if (videoElements.length > 1) {
      for (let index = 1; index < videoElements.length; index++) {
        const videoElement = videoElements[index];
        if (videoElement.id.includes(this.overlayId)) {
          elementsToZoomAndDrag.push(videoElement);
        }
      }
    }

    const properties = ['transform', 'WebkitTransform', 'MozTransform', 'msTransform', 'OTransform'];
    let prop = properties[0];

    let i, j;

    for (i = 0, j = properties.length; i < j; i++) {
      if (typeof player.style[properties[i]] !== 'undefined') {
        prop = properties[i];
        break;
      }
    }

    player.style.overflow = 'hidden';
    const playerPos = player.getBoundingClientRect();
    const videoPos = video.getBoundingClientRect();
    let focusPointOffsets = {};
    if (options.clearDragState) {
      // Stop dragging
      this.lastPlayerFocusPoint = null;
      this.lastVideoOffsets = null;
    } else if (options.setDragState) {
      // Start dragging
      this.lastPlayerFocusPoint = options.posOnPlayer;
      this.lastVideoOffsets = {
        x: videoPos.x - playerPos.x,
        y: videoPos.y - playerPos.y
      };
    } else if (options.drag && options.zoom > this.minZoom && this.lastPlayerFocusPoint) {
      // Drag
      focusPointOffsets = {
        x: this.lastVideoOffsets.x + options.posOnPlayer.x - this.lastPlayerFocusPoint.x,
        y: this.lastVideoOffsets.y + options.posOnPlayer.y - this.lastPlayerFocusPoint.y
      };
    } else if (!options.drag) {
      // Zoom
      const alignVideoToPlayerOffsets = {
        x: (player.clientWidth * options.zoom - player.clientWidth) / (2 * options.zoom),
        y: (player.clientHeight * options.zoom - player.clientHeight) / (2 * options.zoom)
      };

      const pointOnVideo = {
        x: Math.round((options.posOnPlayer.x + (playerPos.x - videoPos.x)) / (videoPos.width / playerPos.width)),
        y: Math.round((options.posOnPlayer.y + (playerPos.y - videoPos.y)) / (videoPos.height / playerPos.height))
      };

      focusPointOffsets = {
        x: -(pointOnVideo.x * options.zoom - options.posOnPlayer.x),
        y: -(pointOnVideo.y * options.zoom - options.posOnPlayer.y)
      };

      elementsToZoomAndDrag.forEach(elem => {
        elem.style[prop] = `scale(${options.zoom}) translate(${alignVideoToPlayerOffsets.x}px, ${alignVideoToPlayerOffsets.y}px)`;
      });
    }

    // Limit offsets to player boundaries
    const minZoomWidthOffset = (((playerPos.width * this.minZoom - playerPos.width) / 2) * options.zoom) / this.minZoom;
    const minZoomHeightOffset = (((playerPos.height * this.minZoom - playerPos.height) / 2) * options.zoom) / this.minZoom;

    focusPointOffsets.x = Math.max(
      Math.min(-minZoomWidthOffset, focusPointOffsets.x),
      -(playerPos.width * options.zoom - playerPos.width - minZoomWidthOffset)
    );
    focusPointOffsets.y = Math.max(
      Math.min(-minZoomHeightOffset, focusPointOffsets.y),
      -(playerPos.height * options.zoom - playerPos.height - minZoomHeightOffset)
    );

    elementsToZoomAndDrag.forEach(elem => {
      elem.style['left'] = `${focusPointOffsets.x}px`;
      elem.style['top'] = `${focusPointOffsets.y}px`;
    });
  }

  onScroll(ev) {
    this.playerPos = this.player.el().getBoundingClientRect();
    const delta = ev.wheelDelta // Calculate how many scroll clicks has occurred (up or down)
      ? ev.wheelDelta / MAX_ZOOM
      : ev.detail
      ? -ev.detail
      : 0;
    if (delta) {
      const factor = Math.pow(1.015, delta);
      this.zoom = this.zoom * factor < this.minZoom ? this.minZoom : this.zoom * factor > MAX_ZOOM ? MAX_ZOOM : this.zoom * factor;
      this.zoomAndDrag({
        zoom: this.zoom,
        posOnPlayer: {
          x: ev.pageX - this.playerPos.left,
          y: ev.pageY - this.playerPos.top
        }
      });
    }
    if (ev.cancelable) {
      ev.preventDefault();
    }
  }
  onStartDragging(ev) {
    this.dragStart = true;
    this.playerPos = this.player.el().getBoundingClientRect();
    this.zoomAndDrag({
      setDragState: true,
      zoom: this.zoom,
      posOnPlayer: {
        x: ev.pageX - this.playerPos.left,
        y: ev.pageY - this.playerPos.top
      }
    });
    window.addEventListener('mouseup', this.stopDraggingFunc);
  }
  onDrag(ev) {
    if (this.dragStart) {
      this.zoomAndDrag({
        zoom: this.zoom,
        posOnPlayer: {
          x: ev.pageX - this.playerPos.left,
          y: ev.pageY - this.playerPos.top
        },
        drag: true
      });
    }
  }
  onStopDragging() {
    this.dragStart = false;
    if (this.player) {
      this.zoomAndDrag({
        clearDragState: true
      });
    }
    window.removeEventListener('mouseup', this.stopDraggingFunc);
  }
}

videojs.registerPlugin('zoomAndDrag', ZoomAndDragPlugin);
