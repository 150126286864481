import EditIntegrationSlack from '../views/IntegrationsView/EditIntegrationSlack.vue';
import EditIntegrationOpsGenie from '../views/IntegrationsView/EditIntegrationOpsGenie.vue';
import EditIntegrationEmail from '../views/IntegrationsView/EditIntegrationEmail.vue';
import EditIntegrationSMS from '../views/IntegrationsView/EditIntegrationSMS.vue';
import EditIntegrationShelly from '../views/IntegrationsView/EditIntegrationShelly.vue';
import EditIntegrationWebApi from '../views/IntegrationsView/EditIntegrationWebApi.vue';

import SlackLogo from '../assets/SlackLogo.png';
import OpsgenieLogo from '../assets/OpsgenieLogo.png';
import EmailLogo from '../assets/EmailLogo.png';
import SMSLogo from '../assets/SMSLogo.png';
import ShellyCloudLogo from '../assets/ShellyCloudLogo.png';
import WebApiLogo from '../assets/WebApiLogo.png';

export const INTEGRATION_CHANNEL = {
  SLACK: 'SLACK',
  OPSGENIE: 'OPSGENIE',
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  SHELLY: 'SHELLY',
  WEB_API: 'WEB_API'
};

export const INTEGRATION_CHANNEL_METADATA = {
  [INTEGRATION_CHANNEL.SLACK]: {
    name: 'Slack',
    component: EditIntegrationSlack,
    icon: SlackLogo
  },
  [INTEGRATION_CHANNEL.OPSGENIE]: {
    name: 'Opsgenie',
    component: EditIntegrationOpsGenie,
    icon: OpsgenieLogo
  },
  [INTEGRATION_CHANNEL.EMAIL]: {
    name: 'Email',
    component: EditIntegrationEmail,
    icon: EmailLogo
  },
  [INTEGRATION_CHANNEL.SMS]: {
    name: 'SMS',
    component: EditIntegrationSMS,
    icon: SMSLogo
  },
  [INTEGRATION_CHANNEL.SHELLY]: {
    name: 'SHELLY',
    component: EditIntegrationShelly,
    icon: ShellyCloudLogo
  },
  [INTEGRATION_CHANNEL.WEB_API]: {
    name: 'Web API',
    component: EditIntegrationWebApi,
    icon: WebApiLogo
  }
};

export const INTEGRATION_EVENT_TYPE = {
  DEVICE_CONNECTION: 'DEVICE_CONNECTION',
  DEVICE_STATE: 'DEVICE_STATE',
  DEVICE_STATUS: 'DEVICE_STATUS',
  DEVICE_ENERGY: 'DEVICE_ENERGY',
  EVENT: 'EVENT',
  MISSION: 'MISSION',
  HALT: 'HALT',
  BATTERY_HEALTH: 'BATTERY_HEALTH'
};

export const INTEGRATION_EVENT_TYPE_METADATA = {
  [INTEGRATION_EVENT_TYPE.DEVICE_CONNECTION]: {
    name: 'Device Connectivity',
    includeDevicesSelection: true,
    integrations: [
      INTEGRATION_CHANNEL.SLACK,
      INTEGRATION_CHANNEL.OPSGENIE,
      INTEGRATION_CHANNEL.EMAIL,
      INTEGRATION_CHANNEL.SMS,
      INTEGRATION_CHANNEL.SHELLY,
      INTEGRATION_CHANNEL.WEB_API
    ]
  },
  [INTEGRATION_EVENT_TYPE.DEVICE_STATE]: {
    name: 'Device Logical State',
    includeDevicesSelection: true,
    integrations: [
      INTEGRATION_CHANNEL.SLACK,
      INTEGRATION_CHANNEL.OPSGENIE,
      INTEGRATION_CHANNEL.EMAIL,
      INTEGRATION_CHANNEL.SMS,
      INTEGRATION_CHANNEL.SHELLY,
      INTEGRATION_CHANNEL.WEB_API
    ]
  },
  [INTEGRATION_EVENT_TYPE.DEVICE_STATUS]: {
    name: 'Device Physical Status',
    includeDevicesSelection: true,
    integrations: [
      INTEGRATION_CHANNEL.SLACK,
      INTEGRATION_CHANNEL.OPSGENIE,
      INTEGRATION_CHANNEL.EMAIL,
      INTEGRATION_CHANNEL.SMS,
      INTEGRATION_CHANNEL.SHELLY,
      INTEGRATION_CHANNEL.WEB_API
    ]
  },
  [INTEGRATION_EVENT_TYPE.DEVICE_ENERGY]: {
    name: 'Device Energy Status',
    includeDevicesSelection: true,
    integrations: [
      INTEGRATION_CHANNEL.SLACK,
      INTEGRATION_CHANNEL.OPSGENIE,
      INTEGRATION_CHANNEL.EMAIL,
      INTEGRATION_CHANNEL.SMS,
      INTEGRATION_CHANNEL.SHELLY,
      INTEGRATION_CHANNEL.WEB_API
    ]
  },
  [INTEGRATION_EVENT_TYPE.EVENT]: {
    name: 'Events and Anomalies',
    includeDevicesSelection: true,
    integrations: [
      INTEGRATION_CHANNEL.SLACK,
      INTEGRATION_CHANNEL.OPSGENIE,
      INTEGRATION_CHANNEL.EMAIL,
      INTEGRATION_CHANNEL.SMS,
      INTEGRATION_CHANNEL.SHELLY,
      INTEGRATION_CHANNEL.WEB_API
    ]
  },
  [INTEGRATION_EVENT_TYPE.MISSION]: {
    name: 'Missions',
    includeDevicesSelection: true,
    integrations: [
      INTEGRATION_CHANNEL.SLACK,
      INTEGRATION_CHANNEL.OPSGENIE,
      INTEGRATION_CHANNEL.EMAIL,
      INTEGRATION_CHANNEL.SMS,
      INTEGRATION_CHANNEL.SHELLY,
      INTEGRATION_CHANNEL.WEB_API
    ]
  },
  [INTEGRATION_EVENT_TYPE.HALT]: {
    name: 'Halt all flights',
    integrations: [
      INTEGRATION_CHANNEL.SLACK,
      INTEGRATION_CHANNEL.OPSGENIE,
      INTEGRATION_CHANNEL.EMAIL,
      INTEGRATION_CHANNEL.SMS,
      INTEGRATION_CHANNEL.SHELLY,
      INTEGRATION_CHANNEL.WEB_API
    ]
  },
  [INTEGRATION_EVENT_TYPE.BATTERY_HEALTH]: {
    name: 'Battery Health Alerts',
    integrations: [
      INTEGRATION_CHANNEL.SLACK,
      INTEGRATION_CHANNEL.OPSGENIE,
      INTEGRATION_CHANNEL.EMAIL,
      INTEGRATION_CHANNEL.SMS,
      INTEGRATION_CHANNEL.SHELLY,
      INTEGRATION_CHANNEL.WEB_API
    ]
  }
};
