import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "-7 -7 535 535",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M448,224h-64H256v-96C256,57.438,198.594,0,128,0C57.422,0,0,57.438,0,128v96h64v-96c0-35.313,28.703-64,64-64  c35.281,0,64,28.688,64,64v96c-35.344,0-64,28.656-64,64v160c0,35.344,28.656,64,64,64h256c35.344,0,64-28.656,64-64V288  C512,252.656,483.344,224,448,224z M336,379.094V432c0,8.844-7.156,16-16,16s-16-7.156-16-16v-52.906  c-9.375-5.563-16-15.375-16-27.094c0-17.688,14.313-32,32-32s32,14.313,32,32C352,363.719,345.375,373.531,336,379.094z" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }