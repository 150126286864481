import Joi from 'joi';
const urlSchema = Joi.string().uri();
// Do not allow numbers and special chars (except ' and -), demand at least 2 chars
export const validateName = val => {
  return /^[a-zA-Z\s'-]{2,}$/.test(val);
};

export const validatePhone = val => {
  const possibleFormats = [/^\+(?:[0-9] ?){6,14}[0-9]$/];
  return possibleFormats.some(regex => regex.test(val));
};

export const validatePassword = (password, fullName) => {
  const validLength = password.length >= 8;
  const containsNumber = /[0-9]/.test(password);
  const containsUppercase = /[A-Z]/.test(password);
  const containsLowercase = /[a-z]/.test(password);
  const containsSpecialCharacter = /[^\w\s]/.test(password);
  const fullNameArr = fullName.split(' ');
  const notContainsLoginName = !fullNameArr.some(name => password.toLowerCase().includes(name.toLowerCase()));
  return {
    validLength,
    containsNumber,
    containsUppercase,
    containsLowercase,
    containsSpecialCharacter,
    notContainsLoginName
  };
};

export const isNumberInRange = (num, min, max) => {
  return num >= min && num <= max;
};

export const validateUrl = url => !urlSchema.validate(url).error;
