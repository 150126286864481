import apiRequest from './apiRequest';

class StatsService {
  async fetchAccountStats(params, silent) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/stats`,
        method: 'GET',
        params,
        ignoreActivity: silent
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async sendAccountStatsToMail(params) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/stats/email`,
        method: 'POST',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchSubscriptions() {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/stats/subscriptions`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async addSubscription(payload) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/stats/subscriptions`,
        method: 'POST',
        data: payload
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async deleteSubscription(payload) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/stats/subscriptions`,
        method: 'DELETE',
        data: payload
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new StatsService();
