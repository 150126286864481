import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("path", {
        fill: "currentColor",
        d: "M14.167 9.167h-3.334V5.833c0-.46-.373-.833-.833-.833-.46 0-.833.373-.833.833v3.334H5.833C5.373 9.167 5 9.54 5 10c0 .46.373.833.833.833h3.334v3.334c0 .46.373.833.833.833.46 0 .833-.373.833-.833v-3.334h3.334c.46 0 .833-.373.833-.833 0-.46-.373-.833-.833-.833z"
      }),
      _createElementVNode("circle", {
        cx: "10",
        cy: "10",
        r: "9.25",
        stroke: "currentColor",
        "stroke-width": "1.5"
      })
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }