<template>
  <input
    type="password"
    class="input-password"
    autocomplete="on"
    :value="modelValue"
    :placeholder="placeholder"
    @input="$emit('update:modelValue', $event.target.value)"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
export default {
  name: 'BasePasswordInput',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue', 'blur']
};
</script>

<style lang="scss" scoped>
.input-password {
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  min-width: 15rem;
  font-size: inherit;
  outline: none;
  transition: transform 0.5s;
  border: 0;
  width: 100%;
  box-sizing: border-box;
  color: var(--textColor);
  background: var(--primaryColor);
  caret-color: var(--highlightColor);

  &:focus-visible {
    outline: inset;
    outline-color: var(--secondaryOverlayShade2);
    outline-style: solid;
    outline-width: 2px;
  }
}
</style>
