<template>
  <router-link :to="to" class="nav-button" :class="{ selected, disabled }">
    <span :class="[{ 'notification-badge': badge }, 'icon']">
      <slot name="icon" />
    </span>
    <div class="label">{{ label }}</div>
  </router-link>
</template>

<script>
export default {
  name: 'NavButton',
  props: {
    to: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    badge: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.nav-button {
  color: var(--secondaryTextColor);
  text-decoration: inherit; /* avoid default underline */
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  transition: 0.2s;

  .icon {
    width: 1.4rem;
    height: 1.4rem;
  }

  &:hover {
    transform: scale(1.1);

    .notification-badge {
      &::after {
        top: 1px;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &.selected {
    color: var(--highlightColor);

    .label {
      color: var(--textColor);
    }
  }

  .notification-badge {
    position: relative;

    &::after {
      content: '';
      width: 9px;
      height: 9px;
      background: var(--notificationsBudgeColor);
      border-radius: 50%;
      position: absolute;
      box-shadow: 2px 1px 4px 3px var(--shadowColor);
      transform: translateX(-25%);
    }
  }
}
</style>
