import { defineStore } from 'pinia';
import { DEVICES_TYPES } from '../consts/deviceConsts';
import { FISHEYE_MIN_ZOOM_CORRECTION } from '../consts/appConsts';

export const PLAYER_EVENTS = {
  FRAME_CHANGE: 'frame_change',
  NEW_MARKER: 'new_marker',
  DURATION: 'duration',
  FRAME: 'frame',
  REQUEST_STREAM_FILE: 'request_stream_file'
};

export const useVideoPlayerStore = defineStore('videoPlayer', {
  state() {
    return {
      front: {
        name: 'front_player',
        autoplay: true,
        src: null,
        objectDetection: null,
        markers: [],
        triggerPauseTime: null, // pauseAt
        isDownloading: false,
        isInError: false,
        source: null,
        eventBus: new EventTarget()
      },
      thermal: {
        name: 'thermal_feed_player',
        enabledByDefault: false,
        src: null,
        isInError: false,
        source: null
      },
      rear: {
        name: 'rear_player',
        autoplay: false,
        src: null,
        objectDetection: null,
        markers: [],
        triggerPauseTime: null,
        isDownloading: false,
        isInError: false,
        source: null,
        eventBus: new EventTarget()
      },
      defaultFocusPlayer: 'front',
      defaultZoom: 1,
      noVideoError: 'Video is not available',
      isLive: false,
      startVideoAtTimestamp: null, // Align segments so video 0 will be at mission.startTimestamp,
      odFetchFailed: false,
      hideOdLabel: false,
      isFetchingConfig: false,
      isVideoFullScreen: false,
      isAnalyticsLegacyMode: false,
      isFisheye: false
    };
  },
  actions: {
    setPlayerConfig({
      streams,
      markers = [],
      device_type_id,
      startVideoAtTimestamp,
      enabledThermalByDefault,
      hideOdLabel,
      isLive,
      pauseMarker,
      defaultFocusPlayer,
      noVideoError
    }) {
      this.isFetchingConfig = false;
      this.isAnalyticsLegacyMode = streams?.objectDetectionConfig?.data_front ? true : false;
      if (streams) {
        if (streams.frontCamStreamURL) {
          this.front.src = streams.frontCamStreamURL;
          this.front.objectDetection = streams?.objectDetectionConfig?.frontCam;
          this.front.source = streams.frontSource;
          this.front.markers = markers;
        }
        if (streams.rearCamStreamURL) {
          this.rear.src = streams.rearCamStreamURL;
          this.rear.objectDetection = streams?.objectDetectionConfig?.rearCam;
          this.rear.source = streams.rearSource;
          this.rear.markers = markers;
        }
        if (streams.thermalCamStreamURL) {
          this.thermal.src = streams.thermalCamStreamURL;
          this.thermal.source = streams.thermalSource;
        }

        if (startVideoAtTimestamp) {
          this.startVideoAtTimestamp = startVideoAtTimestamp;
        }

        this.thermal.enabledByDefault = enabledThermalByDefault;
        this.hideOdLabel = hideOdLabel;

        this.isFisheye = device_type_id === DEVICES_TYPES.TANDO;

        if (this.isFisheye) {
          this.defaultZoom = FISHEYE_MIN_ZOOM_CORRECTION;
        }

        if (pauseMarker) {
          if (pauseMarker.Source.match(/front/i)) {
            this.front.markers.push(pauseMarker);
            this.front.triggerPauseTime = pauseMarker;
          } else {
            this.rear.markers.push(pauseMarker);
            this.rear.triggerPauseTime = pauseMarker;
          }
        }

        if (defaultFocusPlayer) {
          this.defaultFocusPlayer = defaultFocusPlayer;
          // change autoplay to the default player
          this.front.autoplay = defaultFocusPlayer === 'front';
          this.rear.autoplay = !this.front.autoplay;
        }
      } else {
        this.$reset();
        this.rear.isInError = this.front.isInError = this.thermal.isInError = true;
      }

      this.isLive = isLive;

      if (noVideoError) {
        this.noVideoError = noVideoError;
      }
    },
    setDefaultZoom(defaultZoom) {
      if (this.isFisheye) {
        // Only allow adjusting the minimal zoom for fisheye lens
        this.defaultZoom = defaultZoom;
      }
    }
  }
});
