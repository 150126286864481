<template>
  <div ref="mapCanvasWrapper" class="map-canvas-wrapper">
    <div v-if="!isLoading && !mapLoadError" :class="['map-canvas-container', displayPointer ? 'mouse-pointer' : undefined]">
      <div ref="mapCanvasSubContainer">
        <MapInstructionsCover v-if="showInstructions">{{ instructions }}</MapInstructionsCover>
        <canvas
          ref="canvasElement"
          v-tap="onTap"
          :class="{
            'map-canvas': true,
            'draw-area-mode': isAreaCreateMode && !dragStart
          }"
          @mouseover="onMouseOver"
          @mouseout="onMouseOut"
          @mousedown="onMouseDown"
          @mousemove="onMouseMove"
          @mouseup="onMouseUp"
          @mousewheel="onScroll"
          @DOMMouseScroll="onScroll"
          @touchstart="onMobileStartDragging"
          @touchend="onMouseUp"
          @touchcancel="onMouseUp"
          @touchleave="onMouseUp"
          @touchmove="onMobileDrag"
        ></canvas>
        <canvas ref="helperCanvasElement" style="display: none"></canvas>
        <MapPopupList
          v-if="popups.GROUP.show"
          :position="popups.GROUP.position"
          :indicator-map-position="popups.GROUP.mapPoint"
          :list="popups[POPUP_TYPES.GROUP].list"
          @close="popups.GROUP.show = false"
        />
        <MapQuickActionMenu v-show="popups.ACTIONS.show" :position="popups.ACTIONS.position" @map-quick-action="doQuickAction" />
        <NoteLandmarkInput
          v-if="isLandmarksCreateMode"
          v-show="popups.LANDMARK_NOTE_INPUT.show"
          v-model="popups.LANDMARK_NOTE_INPUT.value"
          :position="popups.LANDMARK_NOTE_INPUT.position"
          @update:model-value="handleNoteLandmarkInput"
          @close="closeNoteLandmarkPopup"
        />
        <MapPointMenu v-if="popups.POINT_MENU.show" :position="popups.POINT_MENU.position" :item="popups.POINT_MENU.item" />
      </div>
    </div>
    <BaseLoader v-else-if="isLoading" class="loader" />
    <div v-else class="map-error">Map can't be displayed at the moment.</div>
    <MapMissionEditToolbox v-if="showMapMissionEditToolbox" v-model="selectedPoiType" class="map-toolbox" />
    <MapAreaEditToolbox v-if="isAreaCreateMode" v-model="selectedAreaShape" class="map-toolbox" />
    <MapLandmarksToolbox v-if="isLandmarksCreateMode" v-model="selectedLandmarkType" class="map-toolbox" />
    <ActiveMissionsMenu v-if="activeMissionsInCurrentZone.length && !isMissionTemplateActiveMode" />
    <ZoneHaltMessage v-if="halted" />
  </div>
</template>

<script>
/**
 * TODO: fix the following
 * 17. *mac mouse is oversensitive
 * 28. landmark note -> no auto focus for the input
 */
import { tapDirective as tap } from '../../directives/tap';
import { polygon as turfPolygon, point as turfPoint } from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import MapPopupList from './MapPopupList.vue';
import NoteLandmarkInput from './NoteLandmarkInput.vue';
import MapInstructionsCover from './MapInstructionsCover.vue';
import MapQuickActionMenu from './MapQuickActionMenu.vue';
import MapMissionEditToolbox from './MapMissionEditToolbox.vue';
import MapAreaEditToolbox from './MapAreaEditToolbox.vue';
import MapLandmarksToolbox from './MapLandmarksToolbox.vue';
import MapQuickAction from '../functional/MapQuickAction.js';
import ActiveMissionsMenu from './ActiveMissionsMenu.vue';
import ZoneHaltMessage from './ZoneHaltMessage.vue';
import { MapCanvasManager } from './map-canvas-manager.js';
import { BREAK_POINTS } from '../../consts/appConsts.js';
import { DRAW_ICON_OPTIONS } from '../../consts/drawingConsts.js';
import {
  LANDMARKS_TYPES,
  MAP_MODE,
  MAP_POI_OPTIONS,
  MAP_POI_OPTION_TO_HEIGHT_CODES,
  MAP_POI_OPTION_TO_TYPE,
  MAP_QUICK_ACTION_TO_POI_OPTION,
  MAP_QUICK_ACTION_TYPES,
  MAP_SETTINGS
} from '../../consts/mapConsts.js';
import { AREA_SHAPES } from '../../consts/areasConsts.js';
import { getColorDiff, getRandomColor } from '../../utils/ColorUtils.js';
import { getAngleBetweenPointsInRadians, getAngleBetweenPointsInDegrees, getEuclideanDistance } from '../../utils/PointsUtils.js';
import { getStyleVar } from '../../utils/StyleUtils.js';
import { mapState, mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore.js';
import { useEventListStore } from '../../store/EventListStore.js';
import { useMapStore } from '../../store/MapStore.js';
import { useAreasStore } from '../../store/AreasStore.js';
import { PRIVILEGES } from '../../consts/authConsts.js';
import BaseLoader from '../base/BaseLoader.vue';
import { shallowRef } from 'vue';
import { useVideoPlayerStore } from '../../store/VideoPlayerStore.js';
import { equals } from '../../utils/ObjectUtils.js';
import MapPointMenu from './MapPointMenu.vue';

const RESIZE_TIMEOUT_DURATION = 350;
const ZOOM_FLIP_CUSTOM_DIFF = 0.013;
const VERTEX_RADIUS_RATIO_FACTOR = 10 / 18;
const MAP_LOAD_TIMEOUT = 1000 * 5;
const POPUP_TYPES = {
  GROUP: 'GROUP',
  ACTIONS: 'ACTIONS',
  LANDMARK_NOTE_INPUT: 'LANDMARK_NOTE_INPUT',
  POINT_MENU: 'POINT_MENU'
};
const INDICATORS_DRAW_ORDER = ['paths', 'dots', 'groups', 'icons', 'locations', 'areas'];

export default {
  name: 'MapCanvas',
  components: {
    MapQuickActionMenu,
    BaseLoader,
    MapPopupList,
    MapMissionEditToolbox,
    MapAreaEditToolbox,
    MapLandmarksToolbox,
    MapInstructionsCover,
    NoteLandmarkInput,
    ActiveMissionsMenu,
    ZoneHaltMessage,
    MapPointMenu
  },
  directives: { tap },
  extends: MapQuickAction,
  inject: ['mq'],
  props: {
    indicators: {
      type: Object,
      default: () => ({})
    },
    isQuickActionMenuEnabled: {
      type: Boolean,
      default: true
    },
    indicatorsDrawOrder: {
      // Origin order: paths, dots, groups, icons (=devices), locations, areas (non-background = edit/view mode)
      // background areas and landmarks will always be drawn prior to this list
      type: Array,
      default: () => INDICATORS_DRAW_ORDER,
      validator: val => val.length === INDICATORS_DRAW_ORDER.length && val.every(key => INDICATORS_DRAW_ORDER.includes(key))
    }
  },
  emits: ['mapTap'],
  data() {
    return {
      POPUP_TYPES,
      popups: {
        [POPUP_TYPES.GROUP]: {
          show: false,
          mapPoint: { x: 0, y: 0 },
          position: { x: 0, y: 0 },
          list: [],
          indicatorMargins: true
        },
        [POPUP_TYPES.ACTIONS]: {
          show: false,
          mapPoint: { x: 0, y: 0 },
          position: { x: 0, y: 0 },
          indicatorMargins: true
        },
        [POPUP_TYPES.LANDMARK_NOTE_INPUT]: {
          show: false,
          mapPoint: { x: 0, y: 0 },
          position: { x: 0, y: 0 },
          value: ''
        },
        [POPUP_TYPES.POINT_MENU]: {
          show: false,
          position: { x: 0, y: 0 },
          item: {}
        }
      },
      mapLoadTimeout: null,
      mapCanvasManager: null,
      mapLoadError: false,
      canvas: null,
      ctx: null,
      mapImage: new Image(),
      lightMapImage: new Image(),
      isLoading: false,
      isLightMapImageLoaded: false,
      lightMapSrc: '',
      dragStart: null,
      lastX: null,
      lastY: null,
      resizeTimeout: null,
      canvasPositionedIndicators: {},
      groupedIndicators: {},
      displayPointer: false,
      virtualHeight: null,
      pinchStart: false,
      mobileZoomFocus: { x: 0, y: 0 },
      mobileZoomScreenFocus: { x: 0, y: 0 },
      lastFingersDistance: null,
      windowInitialWidth: null,
      gotoPointsDisplay: [],
      mapMaxScale: MAP_SETTINGS.MAX_SCALE,
      mapMinScale: MAP_SETTINGS.MIN_SCALE,
      mapIndicatorRadius: MAP_SETTINGS.INDICATOR_RADIUS,
      areaIndicator: [], // room_points collection
      polygonMouseIndicator: {},
      mapZoom: null,
      mapStartGapOffset: null,
      selectedPoiType: MAP_POI_OPTIONS.VISIT,
      selectedAreaShape: AREA_SHAPES.RECTANGLE,
      setAngleRequired: false,
      selectedAngledPoint: {},
      isMidQuickAction: false,
      midQuickAction: null,
      selectedLandmarkType: LANDMARKS_TYPES.DOOR,
      landmarkHoverIndex: null,
      sourceConfigArea: {},
      hoverVertexId: null,
      drawIndicatorsFunctionsMap: {
        paths: () => {
          this.groupedIndicators.paths &&
            this.groupedIndicators.paths.forEach(path => {
              this.mapCanvasManager.drawPath({
                ...path,
                mapZoom: this.mapZoom,
                style: {
                  startPointColor: path.color,
                  lineColor: path.color
                }
              });
            });
        },
        dots: () => {
          this.groupedIndicators.dots &&
            this.groupedIndicators.dots.forEach(ind => {
              this.mapCanvasManager.drawDotIndicator(ind, this.mapZoom);
            });
        },
        groups: () => {
          this.groupedIndicators.groups &&
            this.groupedIndicators.groups.forEach(ind => {
              this.mapCanvasManager.drawIconIndicator(ind, this.mapZoom);
            });
        },
        icons: () => {
          this.groupedIndicators.icons &&
            this.groupedIndicators.icons.forEach(ind => {
              this.mapCanvasManager.drawIconIndicator(ind, this.mapZoom);
            });
        },
        locations: () => {
          this.groupedIndicators.locations &&
            this.groupedIndicators.locations.forEach(ind => {
              // Avoid sending h attribute to poi types with no angle arrow
              const maskedLocation = { ...ind, mapZoom: this.mapZoom };
              if ([MAP_POI_OPTIONS.VISIT, MAP_POI_OPTIONS.SCAN].includes(ind.poiType)) {
                delete maskedLocation.h;
              }

              this.mapCanvasManager.drawLocation(maskedLocation);
            });
        },
        areas: this.drawAreas
      }
    };
  },
  computed: {
    ...mapStores(useContextStore, useMapStore, useAreasStore),
    ...mapState(useContextStore, {
      theme: 'theme',
      mapSrc: store => store.zone?.map,
      mapWidth: store => store.zone?.map_width,
      mapHeight: store => store.zone?.map_height,
      mapScale: store => store.zone?.map_scale,
      allowedPointColor: store => store.zone?.allowed_point_color,
      mapLandmarks: store => store.zone?.map_landmarks || [],
      halted: store => store.zone?.halted,
      missionTemplateSkydioTemplateUuid: store => store.missionTemplate?.skydio_template_uuid,
      isTechnician: store => store.isTechnician
    }),
    ...mapState(useMapStore, ['mapMode', 'missionTemplateDraft', 'selectedPoiIndex']),
    ...mapState(useEventListStore, ['activeMissionsInCurrentZone']),
    ...mapState(useVideoPlayerStore, ['isVideoFullScreen']),
    showMapMissionEditToolbox() {
      return this.isMissionTemplateActiveMode && (this.isTechnician || !this.missionTemplateSkydioTemplateUuid);
    },
    setAngleMode() {
      return this.setAngleRequired || this.mapMode === MAP_MODE.REPOSITION;
    },
    setAngleSourcePoint() {
      return this.mapMode === MAP_MODE.REPOSITION ? this.mapStore.repositionPoint : this.selectedAngledPoint;
    },
    setAngleSourceCanvasPoint() {
      if (this.setAngleSourcePoint) {
        return this.convertRoomToCanvasPoint(this.setAngleSourcePoint);
      }
      return null;
    },
    canInvokeCustomMission() {
      return this.contextStore.hasPrivilege(PRIVILEGES.INVOKE_CUSTOM_MISSIONS);
    },
    isConfigAreaCreateMode() {
      return this.mapMode === MAP_MODE.CONFIG_AREA_CREATE;
    },
    isConfigAreaPolygonStartMode() {
      return this.isConfigAreaCreateMode && this.areaIndicator.length === 0 && this.areas.length > 0 && !this.mapStore.areaDraft;
    },
    isAreaCreateMode() {
      return this.mapMode === MAP_MODE.AREA_CREATE;
    },
    isMissionTemplateActiveMode() {
      return this.mapMode === MAP_MODE.PLAN_CREATE || this.mapMode === MAP_MODE.MISSION_TEMPLATE_EDIT;
    },
    isMissionTemplateViewMode() {
      return this.mapMode === MAP_MODE.MISSION_TEMPLATE_VIEW;
    },
    isLandmarksCreateMode() {
      return this.mapMode === MAP_MODE.LANDMARKS_CREATE;
    },
    areas() {
      if (this.mapMode === MAP_MODE.AREA_CREATE) {
        return this.areasStore.areas;
      } else if (this.mapMode === MAP_MODE.AREA_VIEW) {
        return this.indicators.areas || this.areasStore.areas || [];
      } else {
        return this.indicators.areas || [];
      }
    },
    canvasPositionedAreas() {
      const areasToDraw = [];
      if (this.mapStore.areaDraft) {
        areasToDraw.push({
          area: this.mapStore.areaDraft,
          active: true,
          color: this.mapStore.areaColor,
          enabled: true
        });
      }

      return [...this.areas, ...areasToDraw].map((area, index) => {
        let canvasPoints;
        let roomPoints;

        if (area.points) {
          // Config Area
          canvasPoints = area.points.map(point => this.convertMapToCanvasPoint(point));
          roomPoints = area.points.map(point => this.convertMapToRoomPoint(point));
        } else {
          // Restriction Area
          canvasPoints = area.area.room_points.map(point => this.convertRoomToCanvasPoint(point));
          roomPoints = area.area.room_points;
        }

        const canvasPositionedArea = {
          id: area.id,
          shape: area.area ? area.area.shape : AREA_SHAPES.POLYGON,
          points: canvasPoints,
          room_points: roomPoints,
          color: area.color || getRandomColor(index),
          active: area.active || this.mapStore.selectedAreaId === area.id || this.mapStore.selectedAreaId === index,
          enabled: area.enabled,
          isInBackground: area.isInBackground
        };

        if (canvasPositionedArea.shape === AREA_SHAPES.POLYGON) {
          canvasPositionedArea.polygon = turfPolygon([canvasPositionedArea.points.map(point => [point.x, point.y])]);
        }
        return canvasPositionedArea;
      });
    },
    canvasPositionedLandmarks() {
      const landmarks = this.isLandmarksCreateMode ? this.mapStore.landmarksDraft : this.mapLandmarks || [];
      return landmarks.map((landmark, index) => ({
        ...landmark,
        ...this.convertRoomToCanvasPoint(landmark),
        hover: this.landmarkHoverIndex === index
      }));
    },
    instructions() {
      if (this.isLandmarksCreateMode) {
        return `Place the cursor on the map\nto set a landmark positions.\n\nSave when your'e done`;
      } else if (this.isMissionTemplateActiveMode) {
        return `Place your cursor on the map\nto set mission positions.\n\nHit Save\nwhen your'e done`;
      } else if (this.isAreaCreateMode) {
        return `Press and hold your cursor on the map to draw a rectangle area.\n\nDragging is available using wheel press.\n\nTo draw a polygon area \nclick on the map to set the vertices.\nFinish drawing by clicking the first vertex.\n\n\nRepeat to redraw.\nSave when your'e done`;
      } else if (this.isConfigAreaCreateMode) {
        return `Place the cursor on the map\nto set an area's vertex positions.\n\nTo finish drawing the first area click the start vertex.\n\nSave when your'e done\n\n\n* Please notice all area polygons must share an edge.\nTherefore, starting the second area,\nyou should start from an existing polygon's vertex\nand finish at one of it's neighbor vertices.`;
      }
      return null;
    },
    showInstructions() {
      return this.isLandmarksCreateMode || this.isMissionTemplateActiveMode || this.isAreaCreateMode || this.isConfigAreaCreateMode;
    }
  },
  watch: {
    setAngleMode(newVal) {
      if (newVal) {
        window.addEventListener('mousemove', this.onSetAngle);
      } else {
        window.removeEventListener('mousemove', this.onSetAngle);
      }
    },
    indicators: {
      handler: function onIndicatorsChange(newVal, oldVal) {
        if (!this.isLoading) {
          this.convertIndicatorsToMapPoints();
          this.redrawMap();
        }

        if (newVal.contextId !== oldVal.contextId) {
          this.popups[POPUP_TYPES.POINT_MENU].show &&= false;
          this.popups[POPUP_TYPES.GROUP].show &&= false;
        }
      },
      deep: true
    },
    missionTemplateDraft: {
      handler: function onMissionTemplateDraftChange() {
        this.calcGotoPointsDisplay();
        this.redrawMap();
      },
      deep: true
    },
    selectedPoiIndex: {
      handler: function onSelectedPoiChange() {
        if (this.isMissionTemplateActiveMode) {
          this.calcGotoPointsDisplay();
          this.redrawMap();
        }
      }
    },
    isMissionTemplateViewMode() {
      this.calcGotoPointsDisplay();
      this.redrawMap();
    },
    mapMode() {
      this.redrawMap();
      this.popups[POPUP_TYPES.GROUP].show = false;
      this.popups[POPUP_TYPES.ACTIONS].show = false;
      this.popups[POPUP_TYPES.LANDMARK_NOTE_INPUT].show = false;
    },
    areas(newVal, oldVal) {
      if (equals(newVal, oldVal)) {
        return;
      }

      this.sourceConfigArea = {};
      this.areaIndicator = [];

      if (!this.isLoading) {
        this.redrawMap();
      }
    },
    'mapStore.selectedAreaId': {
      handler() {
        this.redrawMap();
      }
    },
    selectedAreaShape() {
      this.areaIndicator = [];
      this.mapStore.updateAreaDraft(null);
      this.redrawMap();
    },
    isLandmarksCreateMode() {
      this.redrawMap();
    },
    selectedLandmarkType() {
      this.closeNoteLandmarkPopup();
    },
    'mapStore.landmarksDraft': {
      handler: function onLandmarksDraftChange() {
        this.closeNoteLandmarkPopup();
      }
    },
    isConfigAreaCreateMode(newVal) {
      if (newVal) {
        this.selectedAreaShape = AREA_SHAPES.POLYGON;
      } else {
        this.selectedAreaShape = AREA_SHAPES.RECTANGLE;
      }
    },
    mapSrc: {
      handler: function mountMap(newSrc, oldSrc) {
        if (newSrc && newSrc !== oldSrc) {
          this.isLoading = true;
          this.mapImage.src = newSrc;
          this.mapLoadError = false;
          this.mapImage.onload = () => {
            this.isLoading = false;
            clearTimeout(this.mapLoadTimeout);
            this.$nextTick(() => {
              this.canvas = this.$refs.canvasElement;
              this.ctx = this.canvas.getContext('2d', { willReadFrequently: true });
              this.mapCanvasManager = new MapCanvasManager(this.canvas, this.ctx, this.mapIndicatorRadius, MAP_SETTINGS.INDICATOR_FONT_SIZE);
              this.resizeCanvas(true);
              this.convertIndicatorsToMapPoints();
              this.redrawMap();
            });
          };
          clearTimeout(this.mapLoadTimeout);
          this.mapLoadTimeout = setTimeout(() => {
            this.mapLoadError = true;
            this.isLoading = false;
          }, MAP_LOAD_TIMEOUT);
        }
      },
      immediate: true
    }
  },
  created() {
    window.addEventListener('resize', this.resizeWait);
    window.addEventListener('scroll', this.repositionPopups);
    this.windowInitialWidth = window.innerWidth;
  },
  beforeUnmount() {
    clearTimeout(this.resizeTimeout);
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeWait);
    window.removeEventListener('scroll', this.repositionPopups);
  },
  methods: {
    resetCanvas() {
      if (this.mapZoom) {
        this.ctx.scale(1 / this.mapZoom, 1 / this.mapZoom); // Reset scale to 1
      }
      this.mapZoom = 1;

      // Reset the canvas transformations
      this.ctx.resetTransform();
      this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    },
    resizeMap() {
      this.resizeWait();
    },
    doQuickAction(action) {
      if ([MAP_QUICK_ACTION_TYPES.INSPECT, MAP_QUICK_ACTION_TYPES.HIGH_INSPECTION, MAP_QUICK_ACTION_TYPES.LOW_INSPECTION].includes(action)) {
        if (!this.setAngleRequired) {
          this.setAngleRequired = true;
          this.selectedPoiType = MAP_QUICK_ACTION_TO_POI_OPTION[action];
          this.selectedAngledPoint = this.convertCanvasToRoomPoint(this.popups[POPUP_TYPES.ACTIONS].mapPoint);
          this.isMidQuickAction = true;
          this.midQuickAction = action;
          this.redrawMap();
        }
      } else {
        this.callAction(this.convertCanvasToRoomPoint(this.popups[POPUP_TYPES.ACTIONS].mapPoint), action);
        this.selectedPoiType = MAP_POI_OPTIONS.VISIT;
      }

      this.popups[POPUP_TYPES.ACTIONS].show = false;
    },
    calcGotoPointsDisplay() {
      this.gotoPointsDisplay = this.missionTemplateDraft.reduce((draftToDraw, point, index) => {
        if (point => !point.isTile) {
          draftToDraw.push({
            ...point,
            isSelected: false,
            showSelectionMark: this.isMissionTemplateActiveMode && index === this.selectedPoiIndex
          });
        }
        return draftToDraw;
      }, []);
    },
    generateLightMapSrc() {
      const helperCanvasElement = this.$refs.helperCanvasElement;
      const helperCtx = helperCanvasElement.getContext('2d');
      helperCanvasElement.width = this.mapWidth;
      helperCanvasElement.height = this.mapHeight;
      helperCtx.filter = getStyleVar('--mapConverterFilter');
      helperCtx.imageSmoothingEnabled = false;
      helperCtx.drawImage(this.mapImage, 0, 0, helperCanvasElement.width, helperCanvasElement.height);
      this.lightMapSrc = helperCanvasElement.toDataURL('image/png');
      this.lightMapImage.src = this.lightMapSrc;
      this.lightMapImage.onload = () => {
        this.isLightMapImageLoaded = true;
        this.redrawMap();
      };
    },
    indicatorImageLoaded(index) {
      const reqIcons = Object.keys(this.requiredIcons);
      if (reqIcons.length > 0 && reqIcons[index] && this.requiredIcons[reqIcons[index]]) {
        this.requiredIcons[reqIcons[index]].imageLoaded = true;
        const allLoaded = !reqIcons.some(iconName => !this.requiredIcons[iconName].imageLoaded);
        if (allLoaded) {
          this.redrawMap();
        }
      }
    },
    groupIndicators() {
      this.groupedIndicators = { ...this.canvasPositionedIndicators };
      const isOnTopOfLocation = group => {
        if (!this.canvasPositionedIndicators.locations) {
          return false;
        }
        return this.canvasPositionedIndicators.locations.some(loc => indicatorFitsGroup(loc, group));
      };

      const groups = [];
      const R = this.mapIndicatorRadius * ((1 / this.mapZoom) * 1.5);
      const groupableIndicators = [...(this.canvasPositionedIndicators.icons || []), ...(this.canvasPositionedIndicators.dots || [])];

      for (let i = 0; i < groupableIndicators.length; i++) {
        const ind = groupableIndicators[i];
        const matchedGroup = matchGroup(ind);
        if (matchedGroup !== null) {
          if (groups[matchedGroup].isStatic) {
            if (ind.isStatic && (ind.x == !groups[matchedGroup].x || ind.y !== groups[matchedGroup].y)) {
              // If both group & indicator's are static & their locations are different - do not group them
              groups.push({
                indicators: [ind],
                x: ind.x,
                y: ind.y,
                isStatic: ind.isStatic
              });
            } else {
              // Add the new indicator, but do not update the group's location
              groups[matchedGroup].indicators.push(ind);
            }
          } else {
            groups[matchedGroup].indicators.push(ind);

            if (!ind.isStatic) {
              // Adjust group location to it's indicator's average location
              groups[matchedGroup].x = groups[matchedGroup].indicators.reduce((sum, ind) => sum + ind.x, 0) / groups[matchedGroup].indicators.length;
              groups[matchedGroup].y = groups[matchedGroup].indicators.reduce((sum, ind) => sum + ind.y, 0) / groups[matchedGroup].indicators.length;
            } else {
              // Set the group's location to it's static indicator's location
              groups[matchedGroup].x = ind.x;
              groups[matchedGroup].y = ind.y;
              groups[matchedGroup].isStatic = true;
            }
          }
        } else {
          groups.push({
            indicators: [ind],
            x: ind.x,
            y: ind.y,
            isStatic: ind.isStatic
          });
        }
      }

      this.groupedIndicators.groups = groups.reduce((groupsCollection, group) => {
        if (group.indicators.length > 1) {
          let iconKey;
          let text = '';
          let showSelectionMark = false;

          // If group is a pair of dock station (static) & drone
          if (group.indicators.length === 2 && group.isStatic && group.indicators.some(i => i?.customData?.isDocked)) {
            iconKey = DRAW_ICON_OPTIONS.DOCKED_DRONE;
          } else {
            text = `+${group.indicators.length}`;
            iconKey = DRAW_ICON_OPTIONS.EXPAND;
          }
          // If one of the group indicators is the selected one, draw selection mark
          if (group.indicators.some(i => i.showSelectionMark)) {
            showSelectionMark = true;
          }
          groupsCollection.push({
            ...group,
            isGroup: true,
            iconKey,
            text,
            isOnTopOfLocation: isOnTopOfLocation(group),
            showSelectionMark
          });
        } else {
          // Mark ungrouped indicators in order to filter out all grouped ones
          group.indicators[0].ungrouped = true;
        }
        return groupsCollection;
      }, []);

      // Filter out grouped indicators
      if (this.canvasPositionedIndicators.icons) {
        this.groupedIndicators.icons = this.canvasPositionedIndicators.icons.filter(ind => ind.ungrouped);
      }
      if (this.canvasPositionedIndicators.dots) {
        this.groupedIndicators.dots = this.canvasPositionedIndicators.dots.filter(ind => ind.ungrouped);
      }

      function matchGroup(ind) {
        for (let i = 0; i < groups.length; i++) {
          const grp = groups[i];
          if (indicatorFitsGroup(ind, grp)) {
            return i;
          }
        }
        return null;
      }

      function indicatorFitsGroup(indicator, group) {
        return (
          indicator.x < group.x + 1.5 * R && indicator.x > group.x - 1.5 * R && indicator.y < group.y + 1.5 * R && indicator.y > group.y - 1.5 * R
        );
      }
    },
    resizeWait(force) {
      if (
        force === true ||
        (!this.isVideoFullscreen &&
          ((this.mq.current === BREAK_POINTS.TABLET && this.windowInitialWidth !== window.innerWidth) ||
            this.mq.current === BREAK_POINTS.DESKTOP ||
            this.mq.current === BREAK_POINTS.LAPTOP))
      ) {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(this.resizeCanvas, RESIZE_TIMEOUT_DURATION);
      }
    },
    resizeCanvas(isInitial) {
      // If refs were not rendered yet, try again later
      if (!this.$refs.mapCanvasWrapper) {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(this.resizeCanvas, RESIZE_TIMEOUT_DURATION / 2);
        return;
      }

      // Resize the canvas (since it cannot get size in percents)
      this.canvas.height = this.$refs.mapCanvasWrapper.clientHeight;
      this.canvas.width = this.$refs.mapCanvasWrapper.clientWidth;
      this.$refs.mapCanvasSubContainer.style.width = this.canvas.width + 'px';
      this.$refs.mapCanvasSubContainer.style.height = this.canvas.height + 'px';
      this.lastX = this.canvas.width / 2;
      this.lastY = this.canvas.height / 2;

      this.virtualHeight = this.canvas.width * (this.mapHeight / this.mapWidth);
      this.mapMinScale = Math.min(this.canvas.height / this.virtualHeight, 1);

      if (isInitial) {
        this.generateLightMapSrc();
      }

      // If map state exist - restore map to the same zoom & offset
      if (isInitial && this.mapZoom && this.mapStartGapOffset) {
        const { x, y } = this.mapStartGapOffset;
        const lastZoom = this.mapZoom;
        this.mapZoom = 1;
        this.zoom(Math.log(lastZoom) / Math.log(MAP_SETTINGS.SCALE_FACTOR));
        const imageStartGap = this.ctx.transformedPoint(0, 0);
        this.ctx.translate(-(x - imageStartGap.x), -(y - imageStartGap.y));
      } else {
        this.resetCanvas();
        this.zoom(0, true);
        const imageStartGap = this.ctx.transformedPoint(0, 0);
        this.ctx.translate(0, imageStartGap.y);
      }

      this.convertIndicatorsToMapPoints();
      if (this.popups[POPUP_TYPES.ACTIONS].show) {
        this.toggleQuickMenu();
      }
      this.mapCanvasManager.initCanvas(this.redrawMap);
    },
    redrawMap() {
      const isLoaded = this.theme === 'light' ? this.isLightMapImageLoaded : !this.isLoading;
      const image = this.theme === 'light' ? this.lightMapImage : this.mapImage;
      if (this.ctx && isLoaded) {
        this.ctx.imageSmoothingEnabled = false;
        const startGapOffset = this.ctx.transformedPoint(0, 0);
        this.ctx.clearRect(startGapOffset.x, startGapOffset.y, this.canvas.width / this.mapZoom, Math.max(this.virtualHeight, this.canvas.height));
        this.ctx.drawImage(image, 0, 0, this.canvas.width, this.virtualHeight);
        this.drawLandmarks();

        if (this.isMissionTemplateActiveMode || this.isMissionTemplateViewMode) {
          this.drawIndicators();
          this.drawGotoPoints();
        } else {
          if (this.popups[POPUP_TYPES.ACTIONS].show) {
            this.mapCanvasManager.drawLocation({
              x: this.popups[POPUP_TYPES.ACTIONS].mapPoint.x,
              y: this.popups[POPUP_TYPES.ACTIONS].mapPoint.y,
              poiType: this.selectedPoiType,
              mapZoom: this.mapZoom
            });
          }
        }
        // drawIndicators on top of other icon only if not in mission template View
        if (!this.isMissionTemplateActiveMode && !this.isMissionTemplateViewMode) {
          this.drawIndicators();
        }
      }
    },
    configureGroupListPopup(groupIndicator) {
      this.popups[POPUP_TYPES.GROUP].list = groupIndicator.indicators.map(i => ({
        ...i,
        icon: shallowRef(i.icon),
        viewBox: i.viewBox,
        text: i.text,
        time: i.time,
        chargeMark: i.customData && i.customData.isCharging,
        warningMark: i.customData && i.customData.isSystemError,
        isDisabled: i.isDisabled,
        callback: i.callback
      }));
      this.openPopup(POPUP_TYPES.GROUP, groupIndicator);
    },
    toggleQuickMenu(tapPoint, pointOnCanvas) {
      if (this.popups[POPUP_TYPES.ACTIONS].show) {
        this.popups[POPUP_TYPES.ACTIONS].show = false;
      } else if (this.isPointValidByColor(pointOnCanvas)) {
        // Close all other popups
        this.popups[POPUP_TYPES.POINT_MENU].show &&= false;
        this.popups[POPUP_TYPES.GROUP].show &&= false;
        this.openPopup(POPUP_TYPES.ACTIONS, tapPoint);
      }
      this.redrawMap();
    },
    isPointValidByColor(point) {
      const allowedColorArr = this.allowedPointColor[this.theme];
      const pointColorData = this.ctx.getImageData(point.x, point.y, 1, 1).data;
      return allowedColorArr.some(allowedColor => {
        const colorsDiff = getColorDiff(allowedColor, pointColorData);
        return colorsDiff < 8; // we are using 8 as benchmark to avoid ignorance of same color that was impacted by JPG or PNG save
      });
    },
    resetSelectedLocationState() {
      this.gotoPointsDisplay.forEach(point => {
        point.isSelected = false;
      });
      this.displayPointer = false;
    },
    changeLocationState(ev) {
      const foundIndex = this.findLocationIndex(this.convertTapToMapPoint({ ev }));
      this.resetSelectedLocationState();
      if (foundIndex >= 0) {
        this.gotoPointsDisplay[foundIndex].originType = this.gotoPointsDisplay[foundIndex].type;
        this.gotoPointsDisplay[foundIndex].isSelected = true;
        this.displayPointer = true;
      }
      this.redrawMap();
    },
    displayMousePointer(ev) {
      const foundIndicator = this.findIndicator(this.convertTapToMapPoint({ ev }));

      if (!foundIndicator || (!foundIndicator.callback && !foundIndicator.isGroup && !foundIndicator.cardItem)) {
        this.displayPointer = false;
      } else {
        this.displayPointer = true;
      }
    },
    drawIndicators() {
      this.indicatorsDrawOrder.forEach(indicatorType => this.drawIndicatorsFunctionsMap[indicatorType]());

      if (this.setAngleRequired && this.selectedAngledPoint) {
        this.mapCanvasManager.drawLocation({
          ...this.convertRoomToCanvasPoint(this.selectedAngledPoint),
          poiType: this.selectedPoiType,
          mapZoom: this.mapZoom
        });
      }
    },
    drawGotoPoints() {
      this.gotoPointsDisplay.forEach((point, idx) => {
        const adjustedPoint = this.convertRoomToCanvasPoint(point);
        this.mapCanvasManager.drawLocation({
          x: adjustedPoint.x,
          y: adjustedPoint.y,
          h: point.h,
          poiType: point.poiType,
          mapZoom: this.mapZoom,
          badge: idx + 1,
          showSelectionMark: point.showSelectionMark,
          configBadge: point.configBadge
        });

        if (point.isSelected) {
          this.mapCanvasManager.drawXMark({
            ...adjustedPoint,
            mapZoom: this.mapZoom
          });
        }
      });
    },
    drawArea(area, index) {
      if (this.isConfigAreaCreateMode) {
        if (this.sourceConfigArea.index === index && this.areaIndicator.length > 1) {
          this.mapCanvasManager.drawAreaPolygon(area, this.mapZoom, true, this.sourceConfigArea.allowedEndVerticesIndexes);
        } else if (this.areaIndicator.length === 0) {
          this.mapCanvasManager.drawAreaPolygon(area, this.mapZoom, !this.mapStore.areaDraft);
        } else {
          this.mapCanvasManager.drawAreaPolygon(area, this.mapZoom);
        }
      } else {
        if (area.shape === AREA_SHAPES.POLYGON) {
          // First vertex is selectable on create mode
          const isFirstVertexSelectable = this.isAreaCreateMode || (this.isConfigAreaCreateMode && this.areas.length === 0);
          this.mapCanvasManager.drawAreaPolygon(area, this.mapZoom, isFirstVertexSelectable, isFirstVertexSelectable ? [0] : null);
        } else {
          this.mapCanvasManager.drawAreaRect(area);
        }
      }
    },
    drawAreas() {
      this.canvasPositionedAreas.forEach((area, index) => {
        this.drawArea(area, index);
      });

      if (this.selectedAreaShape === AREA_SHAPES.RECTANGLE && this.areaIndicator.length === 2) {
        this.mapCanvasManager.drawAreaRect({
          points: this.areaIndicator.map(point => this.convertRoomToCanvasPoint(point)),
          color: this.mapStore.areaColor,
          active: true,
          enabled: true
        });
      } else if (this.selectedAreaShape === AREA_SHAPES.POLYGON && this.areaIndicator.length) {
        const areaPoints = [].concat(this.areaIndicator);
        if (this.polygonMouseIndicator) {
          areaPoints.push(this.polygonMouseIndicator);
        }

        const isFirstVertexSelectable = this.isAreaCreateMode || (this.isConfigAreaCreateMode && this.areas.length === 0);
        this.mapCanvasManager.drawAreaPolygon(
          {
            points: areaPoints.map(point => ({
              ...this.convertRoomToCanvasPoint(point),
              hover: point.hover
            })),
            color: this.mapStore.areaColor,
            active: true,
            enabled: true
          },
          this.mapZoom,
          isFirstVertexSelectable,
          isFirstVertexSelectable ? [0] : null
        );
      }
    },
    drawLandmarks() {
      if (!this.isLandmarksCreateMode) {
        this.ctx.globalAlpha = 0.8;
      }
      this.canvasPositionedLandmarks.forEach(landmark => {
        if (landmark.type === LANDMARKS_TYPES.NOTE) {
          this.mapCanvasManager.drawNote(landmark);
        } else {
          this.mapCanvasManager.drawLandmarkIcon(landmark);
        }
      });
      this.ctx.globalAlpha = 1;
    },
    convertIndicatorsToMapPoints() {
      this.canvasPositionedIndicators = Object.entries(this.indicators).reduce((res, [indicatorType, indicators]) => {
        if (Array.isArray(indicators)) {
          res[indicatorType] = indicators.map(indicator => {
            if (indicatorType === 'paths') {
              return {
                ...indicator,
                path:
                  indicator?.path?.map?.(point => ({
                    ...point,
                    ...this.convertRoomToCanvasPoint(point)
                  })) || []
              };
            } else {
              const convertedPoint = this.convertRoomToCanvasPoint(indicator);
              const extendedInd = { ...indicator, ...convertedPoint };
              return extendedInd;
            }
          });
        }
        return res;
      }, {});
      this.groupIndicators();
    },
    convertCanvasToRoomPoint(point) {
      const widthRatio = this.canvas.width / this.mapWidth;
      return {
        x: (point.x / widthRatio - this.mapWidth / 2) * this.mapScale,
        y: ((this.virtualHeight - point.y) / widthRatio - this.mapHeight / 2) * this.mapScale
      };
    },
    convertRoomToCanvasPoint(point) {
      if (this.canvas && point) {
        const widthRatio = this.canvas.width / this.mapWidth;
        return {
          x: (point.x / this.mapScale + this.mapWidth / 2) * widthRatio,
          y: this.virtualHeight - (point.y / this.mapScale + this.mapHeight / 2) * widthRatio
        };
      }
    },
    convertRoomToMapPoint(point, useIntFormat) {
      let x = point.x / this.mapScale + this.mapWidth / 2;
      let y = point.y / this.mapScale + this.mapHeight / 2;

      if (useIntFormat) {
        x = parseInt(x);
        y = parseInt(y);
      }

      return { x, y };
    },
    convertMapToCanvasPoint(point) {
      const widthRatio = this.canvas.width / this.mapWidth;
      const heightRatio = this.virtualHeight / this.mapHeight;
      return {
        x: point.x * widthRatio,
        y: (this.mapHeight - point.y) * heightRatio
      };
    },
    convertTapToMapPoint({ ev, point }) {
      let tapPoint = point;
      if (ev) {
        // Extract point from the DOM event
        tapPoint = {
          x: ev.offsetX >= 0 ? ev.offsetX : ev.pageX - this.canvas.offsetLeft,
          y: ev.offsetY >= 0 ? ev.offsetY : ev.pageY - this.canvas.offsetTop
        };
      }
      const { x, y } = this.ctx.transformedPoint(0, 0);
      return {
        x: tapPoint.x / this.mapZoom + x,
        y: tapPoint.y / this.mapZoom + y
      };
    },
    convertMapToRoomPoint(point) {
      return {
        x: (point.x - this.mapWidth / 2) * this.mapScale,
        y: (point.y - this.mapHeight / 2) * this.mapScale
      };
    },
    findIndicator(tapPoint) {
      const indicatorRadius = this.mapIndicatorRadius * (1 / this.mapZoom);
      const foundIndicator = [
        ...(this.groupedIndicators.icons || []),
        ...(this.groupedIndicators.dots || []),
        ...(this.groupedIndicators.locations || []),
        ...(this.groupedIndicators.groups || [])
      ].find(
        indicator =>
          tapPoint.x <= indicator.x + indicatorRadius &&
          tapPoint.x >= indicator.x - indicatorRadius &&
          tapPoint.y <= indicator.y + indicatorRadius &&
          tapPoint.y >= indicator.y - indicatorRadius - MAP_SETTINGS.INDICATOR_FONT_SIZE * (1 / this.mapZoom)
      );
      return foundIndicator;
    },
    onLandmarkHover(ev) {
      const point = this.convertTapToMapPoint({ ev });
      this.landmarkHoverIndex = this.findLandmark(point);
      const lastDisplayPointerValue = this.displayPointer;
      this.displayPointer = this.landmarkHoverIndex > 0;
      if (lastDisplayPointerValue !== this.displayPointer) {
        this.redrawMap();
      }
    },
    highlightArea(ev) {
      const point = this.convertTapToMapPoint({ ev });
      const areaIndex = this.findAreaIndex(point);

      if (areaIndex >= 0) {
        this.mapStore.updateSelectedAreaId(this.canvasPositionedAreas[areaIndex].id || areaIndex);
      } else if (this.mapStore.selectedAreaId >= 0) {
        this.mapStore.updateSelectedAreaId(null);
      }
    },
    findAreaIndex(point) {
      const pt = turfPoint([point.x, point.y]);
      const foundAreaIndex = this.canvasPositionedAreas.findIndex(area => {
        if (area.shape === AREA_SHAPES.POLYGON) {
          return booleanPointInPolygon(pt, area.polygon);
        } else {
          const [from, to] = area.points;
          return point.x <= to.x && point.x >= from.x && point.y >= to.y && point.y <= from.y;
        }
      });
      return foundAreaIndex;
    },
    setCurrentArea(ev) {
      if (this.selectedAreaShape === AREA_SHAPES.RECTANGLE) {
        this.areaIndicator[1] = this.convertCanvasToRoomPoint(this.convertTapToMapPoint({ ev }));

        if (getEuclideanDistance(this.areaIndicator[1], this.areaIndicator[0]) >= 0.2) {
          // Area rectangle diagonal is bigger then 20cm
          // Normalize to area format (from = top left, to = bottom right)
          const normalizedArea = [
            {
              x: Math.min(this.areaIndicator[0].x, this.areaIndicator[1].x),
              y: Math.min(this.areaIndicator[0].y, this.areaIndicator[1].y)
            },
            {
              x: Math.max(this.areaIndicator[0].x, this.areaIndicator[1].x),
              y: Math.max(this.areaIndicator[0].y, this.areaIndicator[1].y)
            }
          ];
          this.mapStore.updateAreaDraft({
            room_points: normalizedArea,
            map_points: normalizedArea.map(point => this.convertRoomToMapPoint(point, true)),
            shape: this.selectedAreaShape
          });
        }
      } else {
        // Polygon
        delete this.areaIndicator[0].hover;
        this.areaIndicator.push(this.areaIndicator[0]);
        this.mapStore.updateAreaDraft({
          room_points: this.areaIndicator,
          map_points: this.areaIndicator.map(point => this.convertRoomToMapPoint(point, true)),
          shape: this.selectedAreaShape
        });
      }
      this.areaIndicator = [];
      this.redrawMap();
    },
    findLandmark(tapPoint) {
      this.ctx.font = `bold ${this.mapIndicatorRadius}px ${getStyleVar('--font-family-secondary')}`;
      const landmarkRadius = this.mapIndicatorRadius * this.mapCanvasManager.landmarkToRadiusRatio;
      const foundIndex = this.canvasPositionedLandmarks.findIndex(landmark => {
        if (landmark.type === LANDMARKS_TYPES.NOTE) {
          const textWidth = this.ctx.measureText(landmark.text).width;
          return (
            tapPoint.x <= landmark.x + textWidth / 3 &&
            tapPoint.x >= landmark.x - textWidth / 3 &&
            tapPoint.y <= landmark.y + landmarkRadius / 2 &&
            tapPoint.y >= landmark.y - landmarkRadius / 2
          );
        } else {
          return this.isOnPoint(tapPoint, landmark, this.mapCanvasManager.landmarkToRadiusRatio * this.mapZoom);
        }
      });
      return foundIndex;
    },
    findLocationIndex(tapPoint) {
      const foundIndex = this.missionTemplateDraft.findIndex(point => this.isOnPoint(tapPoint, this.convertRoomToCanvasPoint(point)));
      return foundIndex;
    },
    isIndicatorInCanvasRange(position) {
      const radiusSize = this.mapIndicatorRadius / this.mapZoom;
      return (
        position.x >= radiusSize &&
        position.y >= -1 * radiusSize &&
        position.x <= this.canvas.width - radiusSize &&
        position.y <= this.canvas.height - radiusSize
      );
    },
    isOnPoint(sourcePoint, targetPoint, radiusRatio = 1) {
      const indicatorRadius = this.mapIndicatorRadius * (1 / this.mapZoom) * radiusRatio;
      return (
        sourcePoint.x <= targetPoint.x + indicatorRadius &&
        sourcePoint.x >= targetPoint.x - indicatorRadius &&
        sourcePoint.y <= targetPoint.y + indicatorRadius &&
        sourcePoint.y >= targetPoint.y - indicatorRadius
      );
    },
    getVertexIdWithinReach(canvasPoint) {
      let areaIndex = this.canvasPositionedAreas.length; // Run reverse on areas to find the top area's vertex
      let vertexIndex = -1;

      // Search vertex under the hover point
      while (areaIndex > 0 && vertexIndex === -1) {
        areaIndex--;
        vertexIndex = this.canvasPositionedAreas[areaIndex].points.findIndex(vertex =>
          this.isOnPoint(canvasPoint, vertex, VERTEX_RADIUS_RATIO_FACTOR)
        );
      }

      // Reset area index if no vertex was found
      areaIndex = vertexIndex >= 0 ? areaIndex : -1;

      return [areaIndex, vertexIndex];
    },
    highlightConfigAreaStartVertices(ev) {
      const point = this.convertTapToMapPoint({ ev });
      const [areaIndex, vertexIndex] = this.getVertexIdWithinReach(point);

      const isVertexSameAsCurrentHover = this.hoverVertexId && this.hoverVertexId[0] === areaIndex && this.hoverVertexId[1] === vertexIndex;

      if (this.hoverVertexId) {
        delete this.canvasPositionedAreas[this.hoverVertexId[0]].points[this.hoverVertexId[1]].hover;
      }

      if (vertexIndex >= 0) {
        this.hoverVertexId = [areaIndex, vertexIndex];
        this.canvasPositionedAreas[areaIndex].points[vertexIndex].hover = true;
        this.displayPointer = true;
      } else {
        this.hoverVertexId = null;
        this.displayPointer = false;
      }

      if (!isVertexSameAsCurrentHover) {
        this.redrawMap();
      }
    },
    highlightConfigAreaEndVertices(ev) {
      if (this.mapStore.areaDraft || this.areaIndicator.length < 2) {
        return;
      }

      const point = this.convertTapToMapPoint({ ev });
      const [areaIndex, vertexIndex] = this.getEndVertexIdWithinReach(point);

      if (this.sourceConfigArea.lastHoverIndex >= 0) {
        delete this.canvasPositionedAreas[this.sourceConfigArea.index].points[this.sourceConfigArea.lastHoverIndex].hover;
      }

      if (areaIndex >= 0) {
        this.sourceConfigArea.lastHoverIndex = vertexIndex;
        this.canvasPositionedAreas[areaIndex].points[vertexIndex].hover = true;
      } else if (vertexIndex === 0) {
        this.areaIndicator[0].hover = true;
      } else {
        // No end vertex within reach
        delete this.areaIndicator[0].hover;
      }
    },
    mouseMoveHandler(ev) {
      if (this.isLandmarksCreateMode) {
        this.onLandmarkHover(ev);
      } else if (this.mapMode === MAP_MODE.AREA_VIEW) {
        this.highlightArea(ev);
      } else if (this.isMissionTemplateActiveMode) {
        this.changeLocationState(ev);
      } else if (this.isConfigAreaPolygonStartMode) {
        this.highlightConfigAreaStartVertices(ev);
      } else if (this.isConfigAreaCreateMode || this.isAreaCreateMode) {
        this.highlightConfigAreaEndVertices(ev);
      } else {
        this.displayMousePointer(ev);
      }
    },
    onMouseOver() {
      window.addEventListener('mousemove', this.mouseMoveHandler);

      if (this.setAngleMode) {
        window.addEventListener('mousemove', this.onSetAngle);
      }
    },
    onMouseOut() {
      window.removeEventListener('mousemove', this.mouseMoveHandler);

      if (this.setAngleMode) {
        window.removeEventListener('mousemove', this.onSetAngle);
      }
    },
    onSetAngle(ev) {
      if (this.setAngleSourcePoint) {
        const hoverPoint = this.convertTapToMapPoint({ ev });
        this.redrawMap();
        this.mapCanvasManager.drawArrow({
          from: this.setAngleSourceCanvasPoint,
          to: hoverPoint,
          mapZoom: this.mapZoom
        });
      }
    },
    getEndVertexWithinReach(canvasPoint) {
      const [areaIndex, vertexIndex] = this.getEndVertexIdWithinReach(canvasPoint);
      if (areaIndex >= 0) {
        return this.canvasPositionedAreas[areaIndex].room_points[vertexIndex];
      } else if (vertexIndex === 0) {
        return this.convertCanvasToRoomPoint(this.areaIndicator[0]);
      }
    },
    getEndVertexIdWithinReach(canvasPoint) {
      let vertexIndex = -1;
      let areaIndex = -1;

      if (this.areaIndicator.length >= 2 && this.sourceConfigArea.index != undefined) {
        vertexIndex = this.sourceConfigArea.allowedEndVerticesIndexes.find(endVertexIndex =>
          this.isOnPoint(canvasPoint, this.canvasPositionedAreas[this.sourceConfigArea.index].points[endVertexIndex], VERTEX_RADIUS_RATIO_FACTOR)
        );

        if (vertexIndex >= 0) {
          areaIndex = this.sourceConfigArea.index;
        }
      } else if (
        // Restriction Area mode / First polygon in Config Area mode
        this.sourceConfigArea.index === undefined &&
        this.areaIndicator.length >= 2 &&
        this.isOnPoint(canvasPoint, this.convertRoomToCanvasPoint(this.areaIndicator[0]), VERTEX_RADIUS_RATIO_FACTOR) // Is on polygon start
      ) {
        // The end point of the polygon should be the same as the start point
        vertexIndex = 0;
      }

      return [areaIndex, vertexIndex];
    },
    closeNoteLandmarkPopup() {
      this.popups[POPUP_TYPES.LANDMARK_NOTE_INPUT].show = false;
      this.popups[POPUP_TYPES.LANDMARK_NOTE_INPUT].value = null;
    },
    handleNoteLandmarkInput() {
      if (this.popups[POPUP_TYPES.LANDMARK_NOTE_INPUT].value) {
        this.popups[POPUP_TYPES.LANDMARK_NOTE_INPUT].show = false;
        this.mapStore.addMapLandmarkToDraft({
          ...this.convertCanvasToRoomPoint(this.popups[POPUP_TYPES.LANDMARK_NOTE_INPUT].mapPoint),
          type: this.selectedLandmarkType,
          text: this.popups[POPUP_TYPES.LANDMARK_NOTE_INPUT].value.toUpperCase().trim()
        });
        this.popups[POPUP_TYPES.LANDMARK_NOTE_INPUT].value = null;
        this.popups[POPUP_TYPES.LANDMARK_NOTE_INPUT].mapPoint = null;
        this.redrawMap();
      }
    },
    openPopup(type, canvasPoint) {
      // Close all other popups
      this.popups[POPUP_TYPES.POINT_MENU].show &&= false;
      this.popups[POPUP_TYPES.GROUP].show &&= false;
      if (this.popups[POPUP_TYPES.ACTIONS].show) {
        this.toggleQuickMenu();
      }

      this.popups[type].show = true;
      this.popups[type].mapPoint = canvasPoint;
      this.positionPopup(type);
    },
    positionPopup(type) {
      if (this.popups[type].show) {
        const newPosition = this.mapCanvasManager.convertCanvasToAbsoluteOverlayPoint(this.popups[type].mapPoint, this.mapZoom);
        if (this.popups[type].indicatorMargins) {
          newPosition.x = newPosition.x + 1.4 * this.mapIndicatorRadius;
          newPosition.y = newPosition.y - this.mapIndicatorRadius;
        }

        // If indicator is no longer in canvas range - hide the popup
        if (!this.isIndicatorInCanvasRange(newPosition)) {
          this.popups[type].show = false;
        }
        this.popups[type].position = newPosition;
      }
    },
    repositionPopups() {
      this.positionPopup(POPUP_TYPES.ACTIONS);
      this.positionPopup(POPUP_TYPES.GROUP);
      this.positionPopup(POPUP_TYPES.LANDMARK_NOTE_INPUT);
      this.positionPopup(POPUP_TYPES.POINT_MENU);
    },
    onTapLandmarkMode(tapPoint) {
      const foundIndex = this.findLandmark(tapPoint);
      if (foundIndex >= 0) {
        this.mapStore.removeMapLandmarkFromDraft(foundIndex);
        this.redrawMap();
      } else if (this.selectedLandmarkType !== LANDMARKS_TYPES.NOTE) {
        this.mapStore.addMapLandmarkToDraft({
          ...this.convertCanvasToRoomPoint(tapPoint),
          type: this.selectedLandmarkType
        });
        this.redrawMap();
      } else {
        this.openPopup(POPUP_TYPES.LANDMARK_NOTE_INPUT, tapPoint);
      }
    },
    onTap(ev) {
      if (this.isAreaCreateMode && this.selectedAreaShape === AREA_SHAPES.RECTANGLE) {
        return;
      }

      let pointOnCanvas = {};
      let tapPoint = {};
      if (ev.changedTouches) {
        // Mobile
        pointOnCanvas = {
          x: ev.changedTouches[0].pageX - window.scrollX,
          y: ev.changedTouches[0].pageY - window.scrollY
        };
        tapPoint = this.mapCanvasManager.convertScreenToCanvasPoint(pointOnCanvas, this.mapZoom);
      } else {
        // Desktop
        pointOnCanvas = {
          x: ev.offsetX || ev.pageX - this.canvas.offsetLeft,
          y: ev.offsetY || ev.pageY - this.canvas.offsetTop
        };
        tapPoint = this.convertTapToMapPoint({ ev });
      }

      if (this.isLandmarksCreateMode) {
        this.onTapLandmarkMode(tapPoint);
      } else if (this.isAreaCreateMode || this.isConfigAreaCreateMode) {
        const newPoint = this.convertCanvasToRoomPoint(tapPoint);
        const endPoint = this.getEndVertexWithinReach(tapPoint);

        if (endPoint) {
          // End point is a neighbor vertex of start point
          if (this.isConfigAreaCreateMode && this.areas.length > 0) {
            this.areaIndicator.push(endPoint);
            this.sourceConfigArea = {};
            this.hoverVertexId = null;
          }

          this.setCurrentArea();
          return;
        }

        if (this.mapStore.areaDraft) {
          // A polygon was already drawn -> overwrite it
          this.mapStore.updateAreaDraft(null);
          this.redrawMap();
          return;
        }

        // Config area - start point selection, not the first polygon:
        if (this.isConfigAreaCreateMode && this.areas.length > 0 && this.areaIndicator.length === 0) {
          // Point is verified as another polygon's vertex by the hover function
          if (this.hoverVertexId) {
            // Calculate neighbor vertices
            const [areaIndex, vertexIndex] = this.hoverVertexId;
            const area = this.canvasPositionedAreas[areaIndex];
            const virtualLength = area.points.length - 1; // Ignore the last vertex since it is identical to the first (polygons are closed)
            const prevVertexIndex = (virtualLength + vertexIndex - 1) % virtualLength;
            const nextVertexIndex = (virtualLength + vertexIndex + 1) % virtualLength;
            this.sourceConfigArea = {
              index: areaIndex,
              allowedEndVerticesIndexes: [prevVertexIndex, nextVertexIndex],
              allowedEndVertices: [area.room_points[prevVertexIndex], area.room_points[nextVertexIndex]]
            };
            this.areaIndicator.push(this.canvasPositionedAreas[this.hoverVertexId[0]].room_points[this.hoverVertexId[1]]);
          } else {
            // Non-vertices points are not allowed as start point
            return;
          }
        } else {
          // (Restriction)Area/First-Config-Area Start point OR nth point of any polygon (not start/end)
          let normalizedPoint = newPoint;

          if (this.isConfigAreaCreateMode && this.sourceConfigArea.index >= 0) {
            // If the point is within a radius of any vertex -> normalize the point to that vertex
            const [areaIndex, vertexIndex] = this.getVertexIdWithinReach(tapPoint);

            if (vertexIndex >= 0) {
              normalizedPoint = this.canvasPositionedAreas[areaIndex].room_points[vertexIndex];
            }
          }

          this.areaIndicator.push(normalizedPoint);
        }
      } else if (this.isMidQuickAction) {
        const point = {
          ...this.convertCanvasToRoomPoint(this.popups[POPUP_TYPES.ACTIONS].mapPoint),
          h: getAngleBetweenPointsInRadians(this.setAngleSourceCanvasPoint, tapPoint)
        };
        this.callAction(point, this.midQuickAction);
        this.selectedPoiType = MAP_POI_OPTIONS.VISIT;
        this.setAngleRequired = false;
        this.selectedAngledPoint = {};
        this.isMidQuickAction = false;
        this.midQuickAction = null;
      } else if (this.isMissionTemplateActiveMode) {
        const foundIndex = this.findLocationIndex(tapPoint);

        if (foundIndex >= 0) {
          if (this.gotoPointsDisplay[foundIndex].isSelected) {
            this.mapStore.removeMissionTemplatePoi(foundIndex);
          } else {
            this.resetSelectedLocationState();
            this.gotoPointsDisplay[foundIndex].isSelected = true;
            this.redrawMap();
          }
        } else if (this.isPointValidByColor(pointOnCanvas) || this.setAngleRequired) {
          this.resetSelectedLocationState();
          if ([MAP_POI_OPTIONS.INSPECTION, MAP_POI_OPTIONS.HIGH_INSPECTION, MAP_POI_OPTIONS.LOW_INSPECTION].includes(this.selectedPoiType)) {
            if (!this.setAngleRequired) {
              this.setAngleRequired = true;
              this.selectedAngledPoint = this.convertCanvasToRoomPoint(tapPoint);
              this.redrawMap();
            } else {
              const point = {
                ...this.selectedAngledPoint,
                poiType: this.selectedPoiType,
                type: MAP_POI_OPTION_TO_TYPE[this.selectedPoiType],
                h: getAngleBetweenPointsInRadians(this.setAngleSourceCanvasPoint, tapPoint),
                height: MAP_POI_OPTION_TO_HEIGHT_CODES[this.selectedPoiType]
              };

              this.mapStore.addMissionTemplatePoi(point);
              this.setAngleRequired = false;
              this.selectedAngledPoint = {};
            }
          } else {
            const point = {
              ...this.convertCanvasToRoomPoint(tapPoint),
              poiType: this.selectedPoiType,
              type: MAP_POI_OPTION_TO_TYPE[this.selectedPoiType]
            };

            if (this.selectedPoiType === MAP_POI_OPTIONS.SCAN) {
              point.height = MAP_POI_OPTION_TO_HEIGHT_CODES[this.selectedPoiType];
            }

            this.mapStore.addMissionTemplatePoi(point);
          }
        }
      } else {
        const foundIndicator = this.findIndicator(tapPoint);
        if (foundIndicator && this.mapMode !== MAP_MODE.REPOSITION) {
          if (foundIndicator.isGroup) {
            this.configureGroupListPopup(foundIndicator);
          } else if (foundIndicator.callback) {
            foundIndicator.callback();
          } else if (foundIndicator.cardItem) {
            this.popups[POPUP_TYPES.POINT_MENU].item = foundIndicator.cardItem;
            this.openPopup(POPUP_TYPES.POINT_MENU, tapPoint);
          }
        } else {
          const foundAreaIndex = this.findAreaIndex(tapPoint);
          if (foundAreaIndex >= 0) {
            return;
          }
          if (this.popups[POPUP_TYPES.GROUP].show) {
            this.popups[POPUP_TYPES.GROUP].show = false;
          } else if (this.popups[POPUP_TYPES.POINT_MENU].show) {
            this.popups[POPUP_TYPES.POINT_MENU].show = false;
          } else {
            if (this.isQuickActionMenuEnabled && this.canInvokeCustomMission && !(this.mapMode === MAP_MODE.REPOSITION)) {
              this.toggleQuickMenu(tapPoint, pointOnCanvas);
            }
            const canvasPoint = tapPoint;
            const roomPoint = this.convertCanvasToRoomPoint(tapPoint);
            let headingAngle;
            if (this.mapMode === MAP_MODE.REPOSITION && this.setAngleSourceCanvasPoint) {
              headingAngle = getAngleBetweenPointsInDegrees(this.setAngleSourceCanvasPoint, canvasPoint);
            }
            this.$emit('mapTap', { canvasPoint, roomPoint, headingAngle });
          }
        }
      }
    },
    onMouseDown(ev) {
      if (this.isAreaCreateMode && this.selectedAreaShape === AREA_SHAPES.RECTANGLE && ev.button === 0) {
        // Button 0 is left mouse button
        this.mapStore.updateAreaDraft(null);
        this.areaIndicator[0] = this.convertCanvasToRoomPoint(this.convertTapToMapPoint({ ev }));
        window.addEventListener('mouseup', this.onMouseUp);
      } else {
        const isCreatingPolygons = (this.isAreaCreateMode || this.isConfigAreaCreateMode) && this.areaIndicator.length;

        if (!isCreatingPolygons || (isCreatingPolygons && ev.button === 1)) {
          // Wheel button
          this.onStartDragging(ev);
        }
      }
    },
    onMouseUp(ev) {
      if (this.isAreaCreateMode && this.areaIndicator[0] && this.selectedAreaShape === AREA_SHAPES.RECTANGLE) {
        this.setCurrentArea(ev);
      } else {
        this.onStopDragging(ev);
      }
    },
    onMouseMove(ev) {
      this.lastX = ev.offsetX || ev.pageX - this.canvas.offsetLeft;
      this.lastY = ev.offsetY || ev.pageY - this.canvas.offsetTop;

      if ((this.isAreaCreateMode || this.isConfigAreaCreateMode) && this.areaIndicator[0] && !this.dragStart) {
        const evPoint = this.convertTapToMapPoint({ ev });
        const newPoint = this.convertCanvasToRoomPoint(evPoint);

        if (this.selectedAreaShape === AREA_SHAPES.RECTANGLE) {
          this.areaIndicator[1] = newPoint;
        } else {
          this.polygonMouseIndicator = newPoint;
        }

        this.redrawMap();
      } else {
        this.onDrag(ev);
      }
    },
    onStartDragging(ev) {
      this.lastX = ev.offsetX || ev.pageX - this.canvas.offsetLeft;
      this.lastY = ev.offsetY || ev.pageY - this.canvas.offsetTop;
      this.dragStart = this.ctx.transformedPoint(this.lastX, this.lastY);
      window.addEventListener('mouseup', this.onStopDragging);
    },
    onDrag(ev) {
      if (this.dragStart) {
        const point = this.ctx.transformedPoint(this.lastX, this.lastY);
        this.ctx.translate(point.x - this.dragStart.x, point.y - this.dragStart.y);
        this.repositionPopups();
        const { x, y } = this.ctx.transformedPoint(0, 0);
        this.mapStartGapOffset = { x, y };
        this.mapCanvasManager.initCanvas(this.redrawMap);
      }
    },
    onStopDragging(ev) {
      this.dragStart = null;
      this.pinchStart = false;
      this.lastFingersDistance = null;
      window.removeEventListener('mouseup', this.onStopDragging);
      ev.stopPropagation();
    },
    onMobileStartDragging(ev) {
      if (!this.pinchStart) {
        if (ev.touches.length > 1) {
          this.lastFingersDistance = this.getFingersDistance(ev);
        }
        ev.offsetX = ev.touches[0].offsetX || ev.touches[0].pageX - this.canvas.offsetLeft;
        ev.offsetY = ev.touches[0].offsetY || ev.touches[0].pageY - this.canvas.offsetTop;

        this.onStartDragging(ev);
      }
      if (ev.cancelable) {
        ev.preventDefault();
      }
    },
    onMobileDrag(ev) {
      let customEvent = { ...ev };
      if (ev.touches.length > 1) {
        const fingersDistance = this.getFingersDistance(ev);
        this.lastFingersDistance = this.lastFingersDistance || fingersDistance;
        customEvent.scale = fingersDistance / this.lastFingersDistance;
        customEvent.center = {
          x: (ev.touches[1].clientX + ev.touches[0].clientX) / 2,
          y: (ev.touches[1].clientY + ev.touches[0].clientY) / 2
        };
        this.onPinch(customEvent);
        this.lastFingersDistance = fingersDistance;
      }
      if (!this.pinchStart) {
        customEvent.offsetX = ev.touches[0].offsetX || ev.touches[0].pageX - this.canvas.offsetLeft;
        customEvent.offsetY = ev.touches[0].offsetY || ev.touches[0].pageY - this.canvas.offsetTop;

        this.onDrag(customEvent);
      }

      if (ev.cancelable) {
        ev.preventDefault();
      }
    },
    onPinch(ev) {
      if (!this.pinchStart) {
        this.mobileZoomScreenFocus = ev.center;
        this.mobileZoomFocus = this.mapCanvasManager.convertScreenToCanvasPoint(this.mobileZoomScreenFocus, this.mapZoom);
        this.pinchStart = true;
      }
      this.mobileZoom(ev.scale);
    },
    getFingersDistance(ev) {
      const diffX = ev.touches[0].clientX - ev.touches[1].clientX;
      const diffY = ev.touches[0].clientY - ev.touches[1].clientY;
      return Math.sqrt(diffX * diffX + diffY * diffY);
    },
    mobileZoom(scale) {
      // Makes sure the last mobile scale is only used (for factor calc) when it
      // is a move in the same direction (in\out)
      this.lastMobileScale =
        scale >= 1 && this.lastMobileScale >= 1
          ? this.lastMobileScale > scale
            ? scale + ZOOM_FLIP_CUSTOM_DIFF
            : this.lastMobileScale
          : scale <= 1 && this.lastMobileScale <= 1
          ? this.lastMobileScale < scale
            ? scale - ZOOM_FLIP_CUSTOM_DIFF
            : this.lastMobileScale
          : scale;
      const factor = this.lastMobileScale != 1 ? 1 + (1 - this.lastMobileScale / scale) : scale;
      this.lastMobileScale = scale;

      if (this.mapZoom * scale <= this.mapMaxScale && this.mapZoom * scale >= this.mapMinScale) {
        this.mapZoom *= scale;
        this.ctx.scale(scale, scale);
        this.ctx.translate(
          (this.mobileZoomFocus.x - this.mobileZoomFocus.x * scale) / scale,
          (this.mobileZoomFocus.y - this.mobileZoomFocus.y * scale) / scale
        );
      }
      this.mapCanvasManager.initCanvas(this.redrawMap);
    },
    zoom(scrollClicks, reset) {
      const factor = Math.pow(MAP_SETTINGS.SCALE_FACTOR, scrollClicks);

      // Check if it is between scale limits
      if ((this.mapZoom * factor <= this.mapMaxScale && this.mapZoom * factor >= this.mapMinScale) || reset) {
        const indicator = this.findIndicator(this.convertTapToMapPoint({ point: { x: this.lastX, y: this.lastY } }));

        const point = indicator || this.ctx.transformedPoint(this.lastX, this.lastY);
        this.ctx.translate(point.x, point.y);
        if (reset) {
          if (this.mapZoom) {
            this.ctx.scale(1 / this.mapZoom, 1 / this.mapZoom); // Reset scale to 1
          }
          this.ctx.scale(this.mapMinScale, this.mapMinScale); // Scale map to smallest scale allowed
          this.mapZoom = this.mapMinScale;
        } else {
          this.mapZoom *= factor;
          this.ctx.scale(factor, factor);
        }
        this.ctx.translate(-point.x, -point.y);
        this.repositionPopups();
        this.convertIndicatorsToMapPoints();
        this.mapCanvasManager.initCanvas(this.redrawMap);
      }

      if (this.mapZoom && this.mapZoom * factor < this.mapMinScale) {
        this.ctx.scale((1 / this.mapZoom) * this.mapMinScale, (1 / this.mapZoom) * this.mapMinScale);
        this.mapZoom = this.mapMinScale;
      }
    },
    onScroll(ev) {
      const delta = ev.wheelDelta // Calculate how many scroll clicks has occurred (up or down)
        ? ev.wheelDelta / this.mapMaxScale
        : ev.detail
        ? -ev.detail
        : 0;
      if (delta) this.zoom(delta);
      if (ev.cancelable) {
        ev.preventDefault();
      }
      this.mouseMoveHandler(ev);
    },
    handlerActionCancel() {
      this.redrawMap();
    }
  }
};
</script>

<style scoped lang="scss">
.map-canvas-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background: var(--overlayShade3);

  .map-canvas-container {
    flex-grow: 1;

    &.mouse-pointer {
      cursor: pointer;
    }

    .map-canvas {
      background: var(--secondaryColorShade2);
      transition: width 0.5s;

      &.draw-area-mode {
        cursor: crosshair;
      }
    }
  }

  .loader {
    margin: auto;
    width: 3.5rem;
    height: 3.5rem;
  }

  .map-error {
    margin: auto;
    color: var(--disabledColor);
  }
}
</style>
