import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "2 3 24 24",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M3 10l3.391 3.39a12.495 12.495 0 0 1 3.476-2.209 12.504 12.504 0 0 1 14.837 3.939c.393.518.747 1.068 1.057 1.647a1.5 1.5 0 0 1-2.643 1.418 9.467 9.467 0 0 0-.803-1.25 9.504 9.504 0 0 0-11.275-2.993 9.503 9.503 0 0 0-2.524 1.574L12 19H3v-9z" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }