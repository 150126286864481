<template>
  <button class="action" @click="$emit('click', $event)">
    <span class="label"><slot /></span>
    <span class="sign"><slot name="sign" /></span>
  </button>
</template>

<script>
export default {
  name: 'BaseSignButton',
  emits: ['click']
};
</script>

<style scoped lang="scss">
.action {
  cursor: pointer;
  transition: 0.3s;
  background: var(--overlayShade3);
  border: 1px solid var(--primaryColor);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 1.8rem;
  min-width: 6.5rem;
  outline: 0;
  padding: 0;
  color: currentColor;

  &:hover {
    transform: scale(1.05);
    filter: brightness(1.5);
  }

  .label {
    flex-grow: 1;
    text-align: center;
  }

  .sign {
    height: 100%;
    flex-basis: 1.5rem;
    flex-shrink: 0;
    background: var(--secondaryOverlayShade1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
