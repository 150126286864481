import apiRequest from './apiRequest';

class MissionTemplatesService {
  async fetchMissionTemplates(params) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zone/{zoneId}/mission-templates`,
        method: 'GET',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async fetchMissionTemplateById(templateId, params) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zone/{zoneId}/mission-templates/${templateId}`,
        method: 'GET',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async createMissionTemplate(missionTemplateObj) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zone/{zoneId}/mission-templates`,
        method: 'POST',
        data: missionTemplateObj
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async updateMissionTemplate(missionTemplateObj) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zone/{zoneId}/mission-templates/${missionTemplateObj.id}`,
        method: 'PUT',
        data: missionTemplateObj
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async deleteMissionTemplate(missionTemplateId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zone/{zoneId}/mission-templates/${missionTemplateId}`,
        method: 'DELETE'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new MissionTemplatesService();
