<template>
  <div class="mission-templates-list">
    <BaseButton v-if="showCreateTemplateButton" class="create-new-button" @click="onCreateNewMissionTemplate">
      <IconAddCircle class="icon" />
      Create New Mission
    </BaseButton>
    <MissionTemplateEditForm v-if="isMissionTemplateActiveMode" :mission-template="contextMissionTemplate" @tile-select="onTileSelect" />
    <div v-else class="saved-templates">
      <MissionTemplateCard
        v-for="missionTemplate in missionTemplates"
        :key="`mt-${missionTemplate.id}`"
        :mission-template="missionTemplate"
        :selected="contextMissionTemplate?.id === missionTemplate.id"
        @start="onMissionTemplateLaunchClick(missionTemplate)"
        @edit="onEditMissionTemplateClick(missionTemplate)"
        @delete="onDeleteMissionTemplateClick(missionTemplate)"
        @select="onMissionTemplateSelected(missionTemplate)"
      />
    </div>
  </div>
</template>

<script>
import { MAP_MODE } from '../../consts/mapConsts';
import { PRIVILEGES } from '../../consts/authConsts';
import MissionTemplateCard from './MissionTemplateCard.vue';
import MissionTemplateEditForm from './MissionTemplateEditForm.vue';
import IconAddCircle from '../../components/icons/IconAddCircle.svg?component';
import { useContextStore } from '../../store/ContextStore';
import { useMissionTemplatesStore } from '../../store/MissionTemplatesStore';
import { useMapStore } from '../../store/MapStore';
import { mapState, mapActions } from 'pinia';
import BaseButton from '../../components/base/BaseButton.vue';

export default {
  name: 'MissionTemplatesPanel',
  components: {
    MissionTemplateCard,
    MissionTemplateEditForm,
    IconAddCircle,
    BaseButton
  },
  emits: ['tileSelect', 'missionTemplateSelected', 'delete', 'launchMissionTemplate'],
  computed: {
    ...mapState(useContextStore, {
      isTechnician: 'isTechnician',
      contextMissionTemplate: 'missionTemplate',
      hasPrivilege: 'hasPrivilege'
    }),
    ...mapState(useMapStore, ['mapMode']),
    ...mapState(useMissionTemplatesStore, ['missionTemplates']),
    showCreateTemplateButton() {
      return this.hasPrivilege(PRIVILEGES.MANAGE_MISSION_TEMPLATES) && !this.isMissionTemplateActiveMode;
    },
    isCreateNewMissionTemplateActive() {
      return this.mapMode === MAP_MODE.PLAN_CREATE;
    },
    isMissionTemplateActiveMode() {
      return this.mapMode === MAP_MODE.PLAN_CREATE || this.mapMode === MAP_MODE.MISSION_TEMPLATE_EDIT;
    }
  },
  methods: {
    ...mapActions(useMapStore, ['updateMapMode', 'updateMissionTemplateDraft']),
    onCreateNewMissionTemplate() {
      this.$emit('missionTemplateSelected', null);
      this.updateMapMode(MAP_MODE.PLAN_CREATE);
      this.updateMissionTemplateDraft(null);
    },
    onEditMissionTemplateClick(missionTemplate) {
      this.updateMapMode(MAP_MODE.MISSION_TEMPLATE_EDIT);
      this.updateMissionTemplateDraft(
        missionTemplate.pois.map?.((poi, index) => ({ ...poi, configBadge: this.isTechnician && !!missionTemplate.poi_detection_settings?.[index] }))
      );
    },
    onMissionTemplateSelected(missionTemplate) {
      this.$emit('missionTemplateSelected', missionTemplate);
    },
    async onMissionTemplateLaunchClick(missionTemplate) {
      this.$emit('launchMissionTemplate', missionTemplate);
    },
    async onDeleteMissionTemplateClick(missionTemplate) {
      this.$emit('delete', missionTemplate);
    },
    onTileSelect(selectedTileId) {
      this.$emit('tileSelect', selectedTileId);
    }
  }
};
</script>
<style lang="scss" scoped>
.mission-templates-list {
  font-family: var(--font-family-secondary);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .create-new-button {
    border-radius: 0%;
    font-size: 1.25rem;
    padding: 0.5rem 2rem;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    transition: 0.3s;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .saved-templates {
    cursor: pointer;
    overflow-y: auto;
    direction: rtl;

    * {
      direction: ltr;
    }
  }
}
</style>
