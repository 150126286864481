<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" role="icon" fill="currentColor">
    <g>
      <path
        d="M23,28H7a2,2,0,0,1-2-2V8A2,2,0,0,1,7,6h3V5a2,2,0,0,1,2-2h6a2,2,0,0,1,2,2V6h3a2,2,0,0,1,2,2V26A2,2,0,0,1,23,28ZM7,8V26H23V8H18V5H12V8Z"
      />
      <rect x="9" :y="batteryLevels[0]" width="12" style="opacity: 0.4" display="none" :height="batteryLevels[1]" />
      <polygon points="15 9 12 16 15 16 13 22 20 14 16 14 19 9 15 9" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconBatteryCharging',
  props: {
    props: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    batteryLevels() {
      if (this.props.batteryLevel > 75) {
        return [10, 14];
      } else if (this.props.batteryLevel > 50) {
        return [14, 10];
      } else if (this.props.batteryLevel > 25) {
        return [17, 7];
      } else if (this.props.batteryLevel > 0) {
        return [21, 3];
      } else {
        return [21, 0];
      }
    }
  }
};
</script>

<style scoped lang="scss">
path {
  stroke-width: 2;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}

path,
rect {
  opacity: 0.8;
}
</style>
