import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "4 4 20 20",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      width: "18",
      height: "18",
      x: "5",
      y: "5",
      "fill-rule": "evenodd",
      rx: "2"
    }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }