<template>
  <div class="stats-container" :class="[mq.current, { hidden: isHidden, folded: isCollapsed }]">
    <div class="header">
      <div class="title-filter-container">
        <span>STATS</span>
        <baseMenu ref="filterMenu" :position="[menuDirectionX, menuDirectionY]">
          <template #toggle>
            <IconFilter v-show="!isCollapsed" class="filter-icon" :style="{ color: filterIconColor }"><title>Filter</title></IconFilter>
          </template>
          <template #content>
            <DashboardStatsFilterMenu @filter="onFilterPopupFilterClick" @reset="onFilterPopupResetClick" @cancel="onFilterPopupCancelClick" />
          </template>
        </baseMenu>
        <span class="time-range-label">{{ currentTimeRangeLabel }}</span>
      </div>
      <div v-if="isCompactView" class="collapse-btn" @click="handleCollapseClick">
        <IconCollapse :class="{ expand: !isCollapsed }" />
      </div>
      <div v-else>
        <div class="email-btn" @click="onEmailIconClick">
          <IconEnvelope><title>Email</title></IconEnvelope>
        </div>
        <div class="close-btn" @click="$emit('close')">
          <IconClose>Close</IconClose>
        </div>
      </div>
    </div>
    <BaseLoader v-if="isLoading" class="loader"></BaseLoader>
    <div v-else class="stats-grid">
      <div class="stats">
        <div class="title">Zones</div>
        <BaseCarousel class="zones-stats" :items="zones" :num-of-items-in-view="isCompactView ? 2 : 3" :separation-lines="true">
          <template v-for="(zone, i) in zones" #[`carousel-item-${i}`] :key="zone.id">
            <DashboardZoneStat :zone-stat="zone" />
          </template>
        </BaseCarousel>
      </div>
      <div class="stats">
        <div class="title">Events</div>
        <BaseCarousel v-if="events.length > 0" class="events-stats" :items="events" :num-of-items-in-view="isCompactView ? 3 : 4">
          <template v-for="(event, i) in events" #[`carousel-item-${i}`] :key="event.event_type">
            <DashboardEventStat :event-stat="event" />
          </template>
        </BaseCarousel>
        <div v-else class="no-items-label">No Events Detected</div>
      </div>
      <div class="stats">
        <div class="title">Missions</div>
        <DashboardMissionsStat :missions-stat="missions" />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardZoneStat from './DashboardZoneStat.vue';
import DashboardEventStat from './DashboardEventStat.vue';
import DashboardMissionsStat from './DashboardMissionsStat.vue';
import { FETCH_STATE } from '../../consts/appConsts';
import { STATS_TIME_RANGE } from '../../consts/statsConsts';
import DashboardStatsFilterMenu from './DashboardStatsFilterMenu.vue';
import BaseCarousel from '../../components/base/BaseCarousel.vue';
import { mapState, mapActions, mapStores } from 'pinia';
import { useStatsStore } from '../../store/StatsStore';
import { getStyleVar } from '../../utils/StyleUtils';
import { useContextStore } from '../../store/ContextStore';
import BaseMenu, { MENU_POSITION_OPTIONS } from '../../components/base/BaseMenu.vue';
import { getDate } from '../../utils/DateUtils';
import BaseLoader from '../../components/base/BaseLoader.vue';
import IconFilter from '../../components/icons/IconFilter.svg?component';
import IconEnvelope from '../../components/icons/IconEnvelope.svg?component';
import IconCollapse from '../../components/icons/IconCollapse.svg?component';
import IconClose from '../../components/icons/IconClose.svg?component';
import { BREAK_POINTS } from '../../consts/appConsts';

const SYNC_INTERVAL = 30000;

export default {
  name: 'DashboardStats',
  components: {
    BaseLoader,
    DashboardZoneStat,
    DashboardEventStat,
    DashboardMissionsStat,
    DashboardStatsFilterMenu,
    BaseCarousel,
    BaseMenu,
    IconFilter,
    IconEnvelope,
    IconCollapse,
    IconClose
  },
  inject: ['mq'],
  props: {
    isHidden: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close'],
  data() {
    return {
      isCollapsed: false,
      syncStatsInterval: null,
      menuDirectionX: MENU_POSITION_OPTIONS.LEFT,
      menuDirectionY: MENU_POSITION_OPTIONS.BOTTOM
    };
  },

  computed: {
    ...mapStores(useContextStore),
    ...mapState(useStatsStore, ['isStatsFiltered', 'statsFilters', 'statsFetchState', 'missions', 'events', 'zones']),
    isLoading() {
      return this.statsFetchState === FETCH_STATE.LOADING || this.statsFetchState === FETCH_STATE.INITIAL;
    },
    filterIconColor() {
      return this.isStatsFiltered || this.$refs.filterMenu.isOpen ? getStyleVar('--highlightColor') : getStyleVar('--primaryColorBrightShade2');
    },
    isCompactView() {
      return this.mq.current === BREAK_POINTS.PHONE || this.mq.current === BREAK_POINTS.TABLET;
    },
    currentTimeRangeLabel() {
      const timeRange = this.statsFilters.timeRange;
      switch (timeRange.type) {
        case STATS_TIME_RANGE.LAST_24_HOURS:
          return 'Last 24 hours';
        case STATS_TIME_RANGE.LAST_7_DAYS:
          return 'Last 7 days';
        case STATS_TIME_RANGE.LAST_MONTH:
          return 'Last month';
        case STATS_TIME_RANGE.CUSTOM: {
          const from = new Date(timeRange.from);
          const to = new Date(timeRange.to);
          return `${getDate(from)} - ${getDate(to)}`;
        }
        default:
          return '';
      }
    }
  },
  async created() {
    try {
      await this.fetchStats();
    } catch (e) {
      console.error(e);
    }

    this.syncStatsInterval = setInterval(async () => {
      try {
        await this.fetchStats({ silent: true });
      } catch (e) {
        console.error(e);
      }
    }, SYNC_INTERVAL);
  },
  unmounted() {
    if (this.syncStatsInterval) {
      clearInterval(this.syncStatsInterval);
    }
  },
  methods: {
    ...mapActions(useStatsStore, ['fetchStats', 'sendStatsEmail', 'updateStatsFilters']),
    handleCollapseClick() {
      this.isCollapsed = !this.isCollapsed;
    },
    onFilterPopupFilterClick(filters) {
      if (JSON.stringify(this.statsFilters) !== JSON.stringify(filters)) {
        this.updateStatsFilters(filters);
        this.fetchStats();
      }
      this.$refs.filterMenu.openClose();
    },
    onFilterPopupResetClick() {
      if (this.isStatsFiltered) {
        this.fetchStats();
      }
      this.$refs.filterMenu.openClose();
    },
    onFilterPopupCancelClick() {
      this.$refs.filterMenu.openClose();
    },
    onEmailIconClick() {
      this.contextStore.$patch(state => {
        state.showConfirmationDialog = true;
        state.confirmationDialogData.title = 'Send stats to email confirmation';
        state.confirmationDialogData.text = `Are you sure you want to send those stats to your email?`;
        state.confirmationDialogData.confirmText = 'Yes';
        state.confirmationDialogData.callback = async isConfirmed => {
          if (isConfirmed) {
            this.sendStatsEmail();
          }
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.stats-container {
  color: var(--textColor);
  background: var(--primaryColorBrightShade1);
  margin: 0 0 0 1.5rem;
  min-width: 30%;
  max-width: 100%;
  display: grid;
  grid-template-rows: max-content;

  .header {
    font-size: 1.55rem;
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 0.5rem;
    background: var(--primaryColorDarkShade1);

    .title-filter-container {
      display: inline-flex;
      align-items: center;

      .filter-icon {
        width: 1rem;
        height: 100%;
        margin: 0 0 0 0.7rem;
      }
      .time-range-label {
        margin-left: 1.5rem;
        font-size: 0.9rem;
      }
    }

    .title-filter-container .filter-icon,
    .email-btn,
    .collapse-btn,
    .close-btn {
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }

      &:active {
        color: var(--textColor);
      }
    }

    .email-btn,
    .close-btn {
      display: inline-block;
      margin-left: 10px;
      width: 1rem;
      height: 100%;
    }

    .collapse-btn {
      height: 1.3rem;
      width: 1.3rem;
      padding: 0 0.3rem 0.3rem 0;

      .expand {
        transform: rotate(180deg);
      }
    }
  }

  .loader {
    width: 2rem;
    height: 2rem;
    margin: 50%;
  }

  .stats-grid {
    display: grid;
    row-gap: 1px;
    grid-template-rows: 1fr 1fr 1.35fr;
    grid-template-columns: minmax(0, 1fr);

    .stats {
      background: var(--primaryColor);
      display: flex;
      flex-direction: column;
      padding: 1rem;
      justify-content: space-around;
      align-content: space-evenly;
      overflow-wrap: break-word;

      .title {
        font-size: 1.55rem;
        text-align: center;
      }
      .zones-stats,
      .events-stats {
        flex-grow: 1;
      }

      .no-items-label {
        align-self: center;
      }
    }
  }

  &.hidden {
    min-width: 0;
    transform: scale(0);
    margin: 0;
    padding: 0;

    * {
      display: none;
    }
  }

  &.tablet,
  &.phone {
    margin: 1rem 0 0 0;

    .stats-grid {
      padding: 0.2rem;
      row-gap: 0.2rem;

      .stats {
        padding: 0.5rem;
      }
    }

    &.folded {
      min-height: 2.5rem;

      .stats-grid {
        min-height: 0;

        .stats {
          padding: 0;
          margin: 0;

          * {
            display: none;
          }
        }
      }
    }
  }
}
</style>
