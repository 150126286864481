<template>
  <div class="nav-bar">
    <router-link to="/">
      <NavBarLogo />
    </router-link>
    <div v-if="zoneId" class="left-controls">
      <NavButton
        v-for="pageLink in pageLinks"
        :key="pageLink.name"
        :label="pageLink.meta.title"
        :selected="selected(pageLink.name)"
        :disabled="pageLink.disabled"
        :badge="badgeMap[pageLink.name]"
        :to="pageLink.path.replace(':zoneId', zoneId)"
      >
        <template #icon>
          <component :is="pageLink.icon" />
        </template>
      </NavButton>
    </div>
    <div class="right-controls">
      <ZonesMenu v-if="allowZoneSwitch" />
      <SettingsMenu />
      <UserMenu />
    </div>
  </div>
</template>

<script>
import UserMenu from './UserMenu.vue';
import NavButton from './NavButton.vue';
import SettingsMenu from './SettingsMenu.vue';
import ZonesMenu from './ZonesMenu.vue';
import { ROUTES_MAP, PAGES } from '../../router/index.js';
import NavBarLogo from '../../assets/NavBarLogo.svg?component';
import { useContextStore } from '../../store/ContextStore';
import { useZonesStore } from '../../store/ZonesStore';
import { mapState } from 'pinia';
import { useDevicesStore } from '../../store/DevicesStore';
const NAV_BAR_LINKS = [PAGES.DASHBOARD, PAGES.EVENTS, PAGES.MISSIONS, PAGES.DEVICES, PAGES.MAP];
export default {
  name: 'NavBar',
  components: {
    NavButton,
    UserMenu,
    ZonesMenu,
    SettingsMenu,
    NavBarLogo
  },
  data() {
    return {
      pageLinks: []
    };
  },
  computed: {
    ...mapState(useContextStore, ['hasPrivilege', 'zoneId', 'isTechnician']),
    ...mapState(useZonesStore, ['getZoneById']),
    ...mapState(useDevicesStore, ['isThereADeviceErrorInContextZone', 'isThereADisconnectedDeviceInContextZone']),
    allowZoneSwitch() {
      return this.$route.meta.allowZoneSwitch === true;
    },
    zoneInContext() {
      return this.getZoneById(this.zoneId);
    },
    badgeMap() {
      return {
        [PAGES.DEVICES]: this.isThereADisconnectedDeviceInContextZone || this.isThereADeviceErrorInContextZone
      };
    }
  },
  created() {
    this.pageLinks = NAV_BAR_LINKS.map(page => ROUTES_MAP[page]).filter(link => !link.privileges || link.privileges.some(p => this.hasPrivilege(p)));
  },
  methods: {
    selected(navButtonName) {
      return (
        this.$route.matched[0].name === navButtonName ||
        // EVENT & MISSION are children of EVENTS, so we need to check if we are in one of them.
        (navButtonName === PAGES.EVENTS && (this.$route.name === PAGES.EVENT || this.$route.name === PAGES.MISSION)) ||
        (navButtonName === PAGES.DEVICES && this.$route.name === PAGES.DEVICE)
      );
    }
  }
};
</script>

<style scoped lang="scss">
.nav-bar {
  background-color: var(--primaryColorDarkShade1);
  height: 4.3rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  column-gap: 2rem;
  filter: drop-shadow(0 2px 5px var(--secondaryShadowColor));
  flex-shrink: 0;
  position: relative;
  z-index: 2;

  .right-controls,
  .left-controls {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .right-controls {
    column-gap: 1rem;
    flex-shrink: 0;
    font-family: var(--font-family-secondary);
    color: var(--secondaryTextColor);
    margin-left: auto;
  }

  .left-controls {
    column-gap: 2rem;
    flex-grow: 1;
  }
}
</style>
