<template>
  <div class="video-manager">
    <div class="video-manager-title-container">
      <div class="video-manager-title" :class="{ active: activePlayer === playerTypes.FRONT }" @click="switchPlayers">front view</div>
      <div class="video-manager-title" :class="{ active: activePlayer === playerTypes.REAR }" @click="switchPlayers">rear view</div>
    </div>
    <div v-show="activePlayer === playerTypes.FRONT" class="player-container">
      <VideoPlayer
        ref="frontVideoPlayer"
        :type="playerTypes.FRONT"
        :publish-frame="activePlayer === playerTypes.FRONT"
        @loaded="onPlayerReady(playerTypes.FRONT, $event)"
        @thermal-loaded="onPlayerReady(playerTypes.THERMAL, $event)"
        @inactive="onPlayerInactive(playerTypes.FRONT)"
        @thermal-inactive="onPlayerInactive(playerTypes.THERMAL)"
        @stream-stop="onStreamStop"
        @waiting="onPlayerWaiting"
        @can-play="onPlayerCanPlay"
      />
    </div>
    <div v-show="activePlayer === playerTypes.REAR" class="player-container">
      <VideoPlayer
        ref="rearVideoPlayer"
        :type="playerTypes.REAR"
        :publish-frame="activePlayer === playerTypes.REAR"
        @loaded="onPlayerReady(playerTypes.REAR, $event)"
        @inactive="onPlayerInactive(playerTypes.REAR)"
        @stream-stop="onStreamStop"
        @waiting="onPlayerWaiting"
        @can-play="onPlayerCanPlay"
      />
    </div>
    <div v-if="showDownloadLinkForNotChromeBrowsers" :class="['full-video-download-link', { 'has-error': hasError }]">
      <a :href="videoPlayerStore.front.src"> Download front video </a>
      <a :href="videoPlayerStore.rear.src"> Download rear video </a>
    </div>
    <div v-if="isVideoDownloadPathZip" :class="['full-video-download-link', { 'has-error': hasError }]">
      <a :href="videoDownloadPath" target="_blank"> Download full video </a>
    </div>
  </div>
</template>

<script>
import VideoPlayer from './VideoPlayer.vue';
import { VideoSyncManager } from './video-sync-manager';

import { isChrome } from '../../utils/browserUtils';
import { useVideoPlayerStore } from '../../store/VideoPlayerStore';
import { mapState, mapStores } from 'pinia';

const PLAYLIST_LOAD_TIME_LIMIT_MS = 5000;

export default {
  name: 'VideoManager',
  components: { VideoPlayer },
  props: {
    videoDownloadPath: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    thermalVideoDelay: {
      type: Number,
      default: 0
    }
  },
  emits: ['stream-stop', 'one-player-ready', 'is-streams-exist'],
  data() {
    return {
      videoSyncManager: null,
      activePlayer: null,
      playersData: {
        front: {},
        rear: {},
        thermal: {}
      },
      playerTypes: {
        FRONT: 'front',
        REAR: 'rear',
        THERMAL: 'thermal'
      },
      checkStreamsExistInterval: null
    };
  },
  computed: {
    ...mapStores(useVideoPlayerStore),
    ...mapState(useVideoPlayerStore, ['defaultFocusPlayer']),
    activePlayersGroupMainPlayer() {
      if (this.activePlayer === 'front') {
        return this.playersData[this.playerTypes.FRONT].player;
      } else {
        return this.playersData[this.playerTypes.REAR].player;
      }
    },
    activePlayersGroupMainVideoElement() {
      return this.activePlayersGroupMainPlayer.el().getElementsByTagName('video')[0];
    },
    areAllPlayersReady() {
      return (
        (this.playersData[this.playerTypes.FRONT].isReady || this.playersData[this.playerTypes.FRONT].isInactive) &&
        (this.playersData[this.playerTypes.REAR].isReady || this.playersData[this.playerTypes.REAR].isInactive) &&
        (!this.videoPlayerStore.thermal.src ||
          this.playersData[this.playerTypes.THERMAL].isInactive ||
          this.playersData[this.playerTypes.THERMAL].isReady)
      );
    },
    isVideoDownloadPathZip() {
      return this.videoDownloadPath && this.videoDownloadPath.includes('.zip');
    },
    showDownloadLinkForNotChromeBrowsers() {
      return !isChrome() && this.videoDownloadPath && !this.isVideoDownloadPathZip;
    }
  },
  watch: {
    defaultFocusPlayer: {
      handler: function (newVal) {
        if (newVal === 'front' && this.activePlayer === this.playerTypes.REAR) {
          this.switchPlayers();
        } else if (newVal !== 'front' && this.activePlayer === this.playerTypes.FRONT) {
          this.switchPlayers();
        }
      }
    }
  },
  created() {
    this.activePlayer = this.defaultFocusPlayer;
  },

  beforeUnmount() {
    clearInterval(this.checkStreamsExistInterval);
  },
  methods: {
    addMarker(data) {
      data.time = data.time || this.activePlayersGroupMainPlayer.currentTime();

      if (this.$refs.frontVideoPlayer) {
        this.$refs.frontVideoPlayer.addMarker(data);
      }
      if (this.$refs.rearVideoPlayer) {
        this.$refs.rearVideoPlayer.addMarker(data);
      }
    },
    onAllPlayersReady() {
      const playersGroups = {};

      if (this.playersData[this.playerTypes.FRONT].player) {
        const frontPlayersGroup = [];
        frontPlayersGroup.push(this.playersData[this.playerTypes.FRONT].player);

        if (this.playersData[this.playerTypes.THERMAL].player) {
          frontPlayersGroup.push(this.playersData[this.playerTypes.THERMAL].player);
        }

        playersGroups['front'] = frontPlayersGroup;
      }
      if (this.playersData[this.playerTypes.REAR].player) {
        playersGroups['rear'] = [this.playersData[this.playerTypes.REAR].player];
      }
      let delayMap = {};

      if (this.playersData[this.playerTypes.THERMAL] && this.playersData[this.playerTypes.THERMAL].player) {
        delayMap[this.playersData[this.playerTypes.THERMAL].player.id()] = this.thermalVideoDelay;
      }

      this.videoSyncManager = new VideoSyncManager({
        groups: playersGroups,
        activeGroup: this.activePlayer,
        delayMap
      });
    },
    onPlayerReady(type, player) {
      this.playersData[type].isReady = true;
      this.playersData[type].player = player;
      if (this.areAllPlayersReady) {
        this.onAllPlayersReady();
      } else if (this.playersData[this.playerTypes.FRONT].isInactive) {
        this.switchPlayers();
      }

      this.$emit('one-player-ready');
    },
    onPlayerInactive(type) {
      this.playersData[type].isInactive = true;
      this.playersData[type].isReady = false;
    },
    switchPlayers() {
      this.activePlayer = this.activePlayer === this.playerTypes.FRONT ? this.playerTypes.REAR : this.playerTypes.FRONT;
      if (this.areAllPlayersReady) {
        // Only allow player switch if the other player is available
        this.videoSyncManager.switchGroup(this.activePlayer);
      }
    },
    pauseVideos() {
      if (this.activePlayersGroupMainPlayer) {
        this.activePlayersGroupMainPlayer.pause();
      }
    },
    onStreamStop() {
      clearInterval(this.checkStreamsExistInterval);
      this.checkStreamsExistInterval = null;
      this.$emit('stream-stop');
    },
    onPlayerWaiting() {
      if (!this.checkStreamsExistInterval) {
        this.checkStreamsExistInterval = setInterval(() => {
          this.$emit('is-streams-exist');
        }, PLAYLIST_LOAD_TIME_LIMIT_MS);
      }
    },
    onPlayerCanPlay() {
      clearInterval(this.checkStreamsExistInterval);
      this.checkStreamsExistInterval = null;
    }
  }
};
</script>

<style scoped lang="scss">
#app {
  .video-manager {
    .player-container {
      min-height: 280px;
    }

    .video-manager-title-container {
      text-transform: capitalize;
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: center;
      font-size: 1.1rem;
      font-weight: lighter;
      line-height: 2.3rem;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      overflow: hidden;
      background: var(--secondaryColorShade2);

      .video-manager-title {
        opacity: 0.3;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        &.active {
          opacity: 1;
          cursor: initial;
          pointer-events: none;
          background: var(--secondaryColor);
        }
      }
    }

    .full-video-download-link {
      float: right;
      margin-top: 10px;
      a {
        color: var(--highlightColor);
      }
      &.has-error {
        float: left;
      }
    }
  }
}
</style>
