import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 16 16",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", {
      "fill-rule": "nonzero",
      stroke: "currentColor"
    }, [
      _createElementVNode("path", { d: "M15.5 11.13c.276 0 .5.205.5.457v2.74c0 .922-.822 1.673-1.833 1.673h-3c-.276 0-.5-.205-.5-.457s.224-.456.5-.456h3c.459 0 .833-.342.833-.76v-2.74c0-.252.224-.457.5-.457zm-15 0c.276 0 .5.205.5.457v2.74c0 .418.374.76.833.76h3c.276 0 .5.204.5.456s-.224.457-.5.457h-3C.822 16 0 15.25 0 14.326v-2.739c0-.252.224-.457.5-.457zM4.833 0c.276 0 .5.205.5.457s-.224.456-.5.456h-3c-.459 0-.833.342-.833.76v2.74c0 .252-.224.457-.5.457S0 4.665 0 4.413v-2.74C0 .752.822 0 1.833 0h3zm9.334 0C15.178 0 16 .75 16 1.674v2.739c0 .252-.224.457-.5.457s-.5-.205-.5-.457v-2.74c0-.418-.374-.76-.833-.76h-3c-.276 0-.5-.204-.5-.456s.224-.457.5-.457h3z" })
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }