import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "-17 -17 34 34",
  role: "icon"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("defs", null, [
      _createElementVNode("mask", { id: "indr__iconMaskSafetyEquipment" }, [
        _createElementVNode("rect", {
          x: "-17",
          y: "-17",
          width: "34",
          height: "34",
          fill: "white"
        }),
        _createElementVNode("path", {
          d: "M11.5368-3.6216 2.5682-6.882a1.9061 1.9061 90 00-2.4578 1.1436 1.0935 1.0935 90 00.6521 1.3944A3.8122 3.8122 90 002.508-4.1332l.4013-.0502a4.0128 4.0128 90 011.7757.2107l3.2203 1.1738-5.7483.5217a29.0928 29.0928 90 01-5.4474 0L-8.9586-2.7889-5.7182-3.9727a4.0128 4.0128 90 011.7757-.2107l.4013.0502a3.8122 3.8122 90 001.7757-.2107 1.0935 1.0935 90 00.6521-1.3944A1.9061 1.9061 90 00-3.5714-6.882L-12.54-3.6216v1.4045a1.2941 1.2941 90 000 .1906v2.0064H-11.918a5.7784 5.7784 90 005.0962 6.0192 5.1364 5.1364 90 00.5618 0H-3.7921a3.0096 3.0096 90 002.1268-.8828l.4214-.4113a1.0032 1.0032 90 01.7022-.301 1.0032 1.0032 90 01.7123.301l.4113.4113a3.0096 3.0096 90 002.1368.8828h2.4578a5.4574 5.4574 90 00.5718 0 5.7885 5.7885 90 005.0962-6.0192H11.5368V-1.5048l0 0Zm-3.0096 6.4004A4.0128 4.0128 90 015.6079 4.5144l-.4314 0H2.7187a1.5449 1.5449 90 01-1.0734-.4414l-.4113-.4214a2.508 2.508 90 00-3.5413 0l-.4214.4214A1.5048 1.5048 90 01-3.7921 4.5144H-6.26l-.4214 0A4.0128 4.0128 90 01-9.5304 2.7789a4.5244 4.5244 90 01-.8226-2.1669 1.0032 1.0032 90 011.0032-1.1336H-2.8992l.7925.4013a3.5112 3.5112 90 001.565.3612 3.5814 3.5814 90 001.575-.3612l.7925-.4013h6.4907a1.0032 1.0032 90 011.0032 1.1336A4.4342 4.4342 90 018.5272 2.7789Z",
          fill: "black"
        })
      ])
    ], -1)),
    _cache[1] || (_cache[1] = _createElementVNode("circle", {
      cx: "0",
      cy: "0",
      r: "17",
      fill: "currentColor",
      mask: "url(#indr__iconMaskSafetyEquipment)"
    }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }