<template>
  <BaseMenu ref="userMenu" class="user-menu" :position="menuPosition">
    <template #toggle>
      <IconProfile class="icon" title="User settings" />
    </template>
    <template #content>
      <div class="pages-list">
        <a class="menu-button" href="mailto:support@indoor-robotics.com"><IconEnvelope class="icon" />Support</a>
        <template v-for="page in pages" :key="page.name">
          <router-link
            v-if="page.path"
            :to="page.path"
            :class="['menu-button', { selected: $route.name === page.name }]"
            @click="onMenuButtonClick(page.callback)"
            >{{ page.meta.title }}</router-link
          >
          <a v-else href="" :class="['menu-button', { selected: $route.name === page.name }]" @click="onMenuButtonClick(page.callback)">
            {{ page.name }}
          </a>
        </template>
      </div>
    </template>
  </BaseMenu>
</template>

<script>
import IconProfile from '../../components/icons/IconProfile.svg?component';
import IconEnvelope from '../../components/icons/IconEnvelope.svg?component';
import BaseMenu, { MENU_POSITION_OPTIONS } from '../../components/base/BaseMenu.vue';
import { useContextStore } from '../../store/ContextStore';
import { mapActions } from 'pinia';
import { PAGES, ROUTES_MAP } from '../../router/index.js';
const USER_MENU_LINKS = [PAGES.PROFILE];

export default {
  name: 'NavUser',
  components: { BaseMenu, IconProfile, IconEnvelope },
  data() {
    return {
      menuPosition: [MENU_POSITION_OPTIONS.BOTTOM, MENU_POSITION_OPTIONS.RIGHT],
      pages: []
    };
  },
  created() {
    this.pages = USER_MENU_LINKS.map(page => ROUTES_MAP[page]);
    this.pages.push({
      name: 'Logout',
      component: 'div',
      callback: this.handleLogout
    });
  },
  methods: {
    ...mapActions(useContextStore, ['logoutAndClearData']),
    onMenuButtonClick(callback) {
      callback?.();
      this.$refs.userMenu.openClose();
    },
    async handleLogout() {
      await this.$router.push({ name: PAGES.LOGIN });
      this.logoutAndClearData();
    }
  }
};
</script>

<style lang="scss">
.icon {
  width: 1.4rem;
  height: 1.4rem;
}
.pages-list {
  background: var(--mainBackground);
  display: flex;
  flex-direction: column;
  border: 1px solid var(--highlightColor);
  border-radius: 3px;
  width: max-content;

  .menu-button {
    font-family: var(--font-family-secondary);
    color: inherit; /* avoid default blue colors for links */
    text-decoration: inherit; /* avoid default underline */
    font-size: 1.2rem;
    padding: 0.7rem 0.4rem;
    display: flex;
    column-gap: 0.5rem;
    align-items: center;

    .icon {
      width: 1.2rem;
      height: 1.2rem;
    }

    &:hover {
      filter: brightness(1.2);
    }

    &.selected {
      color: var(--highlightColor);
    }
  }
}
</style>
