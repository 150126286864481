<template>
  <div class="mission-action-bar">
    <template v-if="showName">
      <IconGoToLocation class="avoid-shrink location-icon" @click="navigateToMission"><title>Checkout mission</title></IconGoToLocation>
      <div v-if="isRunning" class="avoid-shrink start-time">
        {{ get24Time(new Date(activeMission.start_timestamp)) }}
      </div>
      <span class="mission-name">{{ activeMission.name }}</span>
      <span class="avoid-shrink separator">|</span>
      <span class="avoid-shrink mission-status">{{ executionStatusLabel }}</span>
    </template>
    <template v-if="isRunning">
      <div class="avoid-shrink time-passed">{{ executionClock }}</div>
      <template v-if="displayActionButtons">
        <IconStop class="avoid-shrink action" @click="abortAndGoHome"><title>Abort Mission</title></IconStop>
        <IconLand class="avoid-shrink action warning" @click="land"><title>Land</title></IconLand>
      </template>
    </template>
  </div>
</template>

<script>
import ActiveMission from '../../components/functional/ActiveMission.js';
import IconGoToLocation from '../../components/icons/IconGoToLocation.svg?component';
import IconStop from '../../components/icons/IconStop.svg?component';
import IconLand from '../../components/icons/IconLand.svg?component';
import { get24Time } from '../../utils/DateUtils';
import { PRIVILEGES } from '../../consts/authConsts';
import { getExecutionStatusLabel } from '../../utils/models/MissionUtils';
import { MISSION_STATE } from '../../consts/missionConsts.js';
import { DEVICES_TYPES } from '../../consts/deviceConsts';
import { PAGES } from '../../router';

export default {
  name: 'GoToMissionActionBar',
  components: {
    IconGoToLocation,
    IconStop,
    IconLand
  },
  extends: ActiveMission,
  props: {
    showName: {
      type: Boolean,

      default: true
    }
  },
  computed: {
    isRunning() {
      return this.activeMission?.state === MISSION_STATE.RUNNING;
    },
    executionStatusLabel() {
      return getExecutionStatusLabel(this.activeMission);
    },
    hasPrivilegeToInvokeMissions() {
      return this.contextStore.hasPrivilege(PRIVILEGES.INVOKE_CUSTOM_MISSIONS) || this.contextStore.hasPrivilege(PRIVILEGES.INVOKE_MISSION_TEMPLATE);
    },
    hasPrivilegeToInvokeDebugMission() {
      return this.contextStore.hasPrivilege(PRIVILEGES.INVOKE_DEBUG_MISSION);
    },
    displayActionButtons() {
      return (
        (this.hasPrivilegeToInvokeMissions || this.hasPrivilegeToInvokeDebugMission) && this.activeMission.device_type_id !== DEVICES_TYPES.SKYDIO
      );
    }
  },
  methods: {
    get24Time,
    navigateToMission() {
      this.$router.push({
        name: PAGES.MISSION,
        params: {
          missionId: this.activeMission.id
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.mission-action-bar {
  display: flex;
  padding: 8px 16px;
  column-gap: 8px;
  align-items: center;
  border-bottom: 1px solid var(--secondaryColor);

  .avoid-shrink {
    flex-shrink: 0;
  }

  .location-icon {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    &:hover {
      color: var(--highlightColor);
    }
  }

  .action {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    color: var(--highlightColor);

    &.warning {
      color: var(--errorColor);
    }

    &:hover {
      filter: brightness(1.3);
    }
  }

  .mission-name {
    font-size: 1.5rem;
    white-space: nowrap;
  }

  .mission-status {
    font-weight: lighter;
    width: fit-content;
    font-family: var(--font-family-secondary);
    flex-grow: 1;
    color: var(--highlightColorShade1);
  }

  .start-time {
    font-size: 1.1rem;
    opacity: 0.6;
  }

  .time-passed {
    font-size: 0.9rem;
  }

  .separator {
    opacity: 0.4;
  }
}
</style>
