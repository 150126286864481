import IconMissionRequested from '../components/icons/IconMissionRequested.svg?component';
import IconMissionCompleted from '../components/icons/IconMissionCompleted.svg?component';
import IconMissionFailed from '../components/icons/IconMissionFailed.svg?component';
import IconMissionStarted from '../components/icons/IconMissionStarted.svg?component';
import IconMissionUnknown from '../components/icons/IconMissionUnknown.svg?component';
import IconMissionStopped from '../components/icons/IconMissionStopped.svg?component';

export const MISSION_STATE = {
  REQUESTED: 'REQUESTED',
  PREPARING: 'PREPARING',
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  POST_ACTIONS: 'POST_ACTIONS',
  PAUSED: 'PAUSED',
  FINISHED: 'FINISHED',
  STOPPED: 'STOPPED',
  FAILED: 'FAILED',
  EXPIRED: 'EXPIRED',
  UNKNOWN: 'UNKNOWN'
};

export const MISSION_STATE_ICONS = {
  [MISSION_STATE.REQUESTED]: IconMissionRequested,
  [MISSION_STATE.PREPARING]: IconMissionRequested,
  [MISSION_STATE.PENDING]: IconMissionRequested,
  [MISSION_STATE.RUNNING]: IconMissionStarted,
  [MISSION_STATE.POST_ACTIONS]: IconMissionStarted,
  [MISSION_STATE.PAUSED]: IconMissionStopped,
  [MISSION_STATE.FINISHED]: IconMissionCompleted,
  [MISSION_STATE.STOPPED]: IconMissionFailed,
  [MISSION_STATE.FAILED]: IconMissionFailed,
  [MISSION_STATE.EXPIRED]: IconMissionFailed,
  [MISSION_STATE.UNKNOWN]: IconMissionUnknown
};

export const MISSION_STATE_NAME = {
  [MISSION_STATE.REQUESTED]: 'Requested',
  [MISSION_STATE.PREPARING]: 'Preparing',
  [MISSION_STATE.PENDING]: 'Pending',
  [MISSION_STATE.RUNNING]: 'Active',
  [MISSION_STATE.POST_ACTIONS]: 'Post Actions',
  [MISSION_STATE.PAUSED]: 'Paused',
  [MISSION_STATE.FINISHED]: 'Completed',
  [MISSION_STATE.STOPPED]: 'Incomplete',
  [MISSION_STATE.FAILED]: 'Incomplete',
  [MISSION_STATE.EXPIRED]: 'Expired',
  [MISSION_STATE.UNKNOWN]: 'Unknown'
};

export const MISSION_STATES_NAMES_TO_STATES = Object.entries(MISSION_STATE_NAME).reduce((map, [state, stateName]) => {
  map[stateName] = map[stateName] || [];
  map[stateName].push(state);
  return map;
}, {});

export const MISSION_SOURCE = {
  MANUAL: 'MANUAL',
  SCHEDULE: 'SCHEDULE',
  TRIGGER: 'TRIGGER',
  ROBOT: 'ROBOT'
};

export const STATE_COLOR_MAP = {
  [MISSION_STATE.REQUESTED]: '',
  [MISSION_STATE.PREPARING]: '',
  [MISSION_STATE.PENDING]: '',
  [MISSION_STATE.RUNNING]: '',
  [MISSION_STATE.POST_ACTIONS]: '',
  [MISSION_STATE.PAUSED]: '--infoColor',
  [MISSION_STATE.FINISHED]: '--successColor',
  [MISSION_STATE.STOPPED]: '--infoColor',
  [MISSION_STATE.FAILED]: '--infoColor',
  [MISSION_STATE.EXPIRED]: '--infoColor',
  [MISSION_STATE.UNKNOWN]: ''
};
export const STATE_ICON_COLOR_MAP = {
  [MISSION_STATE.REQUESTED]: 'var(--highlightColor)',
  [MISSION_STATE.PREPARING]: 'var(--highlightColor)',
  [MISSION_STATE.PENDING]: 'var(--highlightColor)',
  [MISSION_STATE.RUNNING]: 'var(--highlightColor)',
  [MISSION_STATE.POST_ACTIONS]: 'var(--highlightColor)',
  [MISSION_STATE.PAUSED]: 'var(--infoColor)',
  [MISSION_STATE.FINISHED]: 'var(--successColor)',
  [MISSION_STATE.STOPPED]: 'var(--infoColor)',
  [MISSION_STATE.FAILED]: 'var(--infoColor)',
  [MISSION_STATE.EXPIRED]: 'var(--infoColor)',
  [MISSION_STATE.UNKNOWN]: 'var(--disabledColor)'
};

export const MISSION_STATE_LABELS = {
  [MISSION_STATE.REQUESTED]: 'Requested',
  [MISSION_STATE.PREPARING]: 'Preparing',
  [MISSION_STATE.PENDING]: 'Pending',
  [MISSION_STATE.RUNNING]: 'Active',
  [MISSION_STATE.POST_ACTIONS]: 'Active',
  [MISSION_STATE.PAUSED]: 'Paused',
  [MISSION_STATE.FINISHED]: 'Completed',
  [MISSION_STATE.STOPPED]: 'Incomplete',
  [MISSION_STATE.FAILED]: 'Incomplete',
  [MISSION_STATE.EXPIRED]: 'Expired',
  [MISSION_STATE.UNKNOWN]: 'Unknown'
};

export const MISSION_SOURCE_LABELS = {
  [MISSION_SOURCE.MANUAL]: 'Manually',
  [MISSION_SOURCE.SCHEDULE]: 'Device scheduler',
  [MISSION_SOURCE.ROBOT]: 'Device AI',
  [MISSION_SOURCE.TRIGGER]: '3rd party integration'
};
