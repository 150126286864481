import { mapStores } from 'pinia';
import { MAP_POI_OPTIONS, MAP_QUICK_ACTION_TYPES, POI_INSPECTIONS_HEIGHT_CODES, POI_TYPES } from '../../consts/mapConsts';
import { useContextStore } from '../../store/ContextStore';
import { useEventListStore } from '../../store/EventListStore';
import { PAGES } from '../../router/index.js';
import { DEVICES_TYPES } from '../../consts/deviceConsts.js';

export default {
  data() {
    return {
      actionsMap: {
        [MAP_QUICK_ACTION_TYPES.DROP_AND_LAND]({ metadata }) {
          this.contextStore.$patch(state => {
            state.showConfirmationDialog = true;
            state.confirmationDialogData.title = 'Start Mission Confirmation';
            state.confirmationDialogData.text = `Would you like to detach the robot from the tile and land it on the ground?`;
            state.confirmationDialogData.confirmText = 'Yes, Start Mission';
            state.confirmationDialogData.callback = async isConfirmed => {
              if (isConfirmed) {
                const missionObj = {
                  zone_id: this.contextStore.zoneId,
                  type: 'DROPNLAND',
                  device_id: metadata.device.id,
                  name: 'Mission drop and land'
                };
                const mission = await this.eventListStore.invokeMission(missionObj);
                this.$router.push({
                  name: PAGES.MISSION,
                  params: {
                    missionId: mission.id
                  }
                });
              }
            };
          });
        },
        [MAP_QUICK_ACTION_TYPES.TAKEOFF_AND_DOCK]({ metadata }) {
          this.contextStore.$patch(state => {
            state.showConfirmationDialog = true;
            state.confirmationDialogData.title = 'Start Mission Confirmation';
            state.confirmationDialogData.text = `Would you like to takeoff and dock the robot on a tile?\n
              Please make sure the robot is positioned under the desired tile.`;
            state.confirmationDialogData.confirmText = 'Yes, Start Mission';
            state.confirmationDialogData.callback = async isConfirmed => {
              if (isConfirmed) {
                const missionObj = {
                  zone_id: this.contextStore.zoneId,
                  type: 'TAKEOFFNDOCK',
                  device_id: metadata.device.id,
                  name: `Take off and dock on ${metadata.device.name}`
                };
                const mission = await this.eventListStore.invokeMission(missionObj);
                this.$router.push({
                  name: PAGES.MISSION,
                  params: {
                    missionId: mission.id
                  }
                });
              }
            };
          });
        },
        [MAP_QUICK_ACTION_TYPES.INVESTIGATE]({ point }) {
          this.baseInspectAction({
            point,
            text: this.getPoiMissionText('investigate'),
            poiData: {
              type: POI_TYPES.VISIT,
              poiType: MAP_POI_OPTIONS.VISIT
            }
          });
        },
        [MAP_QUICK_ACTION_TYPES.INSPECT]({ point }) {
          this.baseInspectAction({
            point,
            text: this.getPoiMissionText('inspect'),
            missionData: {
              name: 'Inspect Mission'
            },
            poiData: {
              type: POI_TYPES.INSPECTION,
              height: POI_INSPECTIONS_HEIGHT_CODES.NOMINAL,
              poiType: MAP_POI_OPTIONS.INSPECTION
            }
          });
        },
        [MAP_QUICK_ACTION_TYPES.HIGH_INSPECTION]({ point }) {
          this.baseInspectAction({
            point,
            text: this.getPoiMissionText('inspect from a high point of view'),
            missionData: {
              name: 'Inspect mission(high)'
            },
            poiData: {
              type: POI_TYPES.INSPECTION,
              height: POI_INSPECTIONS_HEIGHT_CODES.HIGH,
              poiType: MAP_POI_OPTIONS.HIGH_INSPECTION
            }
          });
        },
        [MAP_QUICK_ACTION_TYPES.LOW_INSPECTION]({ point }) {
          this.baseInspectAction({
            point,
            text: this.getPoiMissionText('inspect from a low point of view'),
            missionData: {
              name: 'Inspect mission(low)'
            },
            poiData: {
              type: POI_TYPES.INSPECTION,
              height: POI_INSPECTIONS_HEIGHT_CODES.LOW,
              poiType: MAP_POI_OPTIONS.LOW_INSPECTION
            }
          });
        },
        [MAP_QUICK_ACTION_TYPES.SCAN]({ point }) {
          this.baseInspectAction({
            point,
            text: this.getPoiMissionText('scan'),
            missionData: {
              name: 'Scan mission'
            },
            poiData: {
              type: POI_TYPES.SCAN,
              poiType: MAP_POI_OPTIONS.SCAN,
              height: POI_INSPECTIONS_HEIGHT_CODES.NOMINAL
            }
          });
        },
        [MAP_QUICK_ACTION_TYPES.SKIP_TILE]({ point, metadata }) {
          this.baseInspectAction(
            {
              point,
              text: `Would you like to place a robot on tile ${metadata.device.name}?`,
              title: 'Place robot on tile',
              missionData: {
                type: 'SKIPTILE',
                name: `Skip to ${metadata.device.name}`,
                custom_data: {
                  tile_id: metadata.device.physicalId
                }
              }
            },
            true
          );
        },
        [MAP_QUICK_ACTION_TYPES.FILTER_BY_LOCATION]({ point, metadata }) {
          this.contextStore.$patch(state => {
            state.showConfirmationDialog = true;
            state.confirmationDialogData.title = 'Filter event log';
            state.confirmationDialogData.text = `Would you like to filter the event log by this location?`;
            state.confirmationDialogData.confirmText = 'Yes';
            state.confirmationDialogData.callback = async isConfirmed => {
              if (isConfirmed && point) {
                this.eventListStore.updateFilters({
                  location: `${point.x}_${point.y}`
                });
                this.eventListStore.fetchEventList();
              }
            };
          });
        },
        [MAP_QUICK_ACTION_TYPES.RESET_FILTER_BY_LOCATION]() {
          this.contextStore.$patch(state => {
            state.showConfirmationDialog = true;
            state.confirmationDialogData.title = 'Clear event log location filter';
            state.confirmationDialogData.text = `Would you like to remove the event log's location filter?`;
            state.confirmationDialogData.confirmText = 'Yes';
            state.confirmationDialogData.callback = async isConfirmed => {
              if (isConfirmed) {
                this.eventListStore.updateFilters({
                  location: ''
                });
                this.eventListStore.fetchEventList();
              }
            };
          });
        }
      }
    };
  },
  computed: {
    ...mapStores(useContextStore, useEventListStore)
  },
  methods: {
    callAction(point, action, metadata) {
      if (this.actionsMap[action]) {
        this.actionsMap[action].call(this, { point, metadata });
      }
    },
    getPoiMissionText(description) {
      return `Would you like to send the robot to ${description} the selected point on the map?`;
    },
    baseInspectAction({ point, title, text, poiData = {}, missionData = {} }, navigateToMission) {
      this.contextStore.$patch(state => {
        state.showMissionDialog = true;
        state.missionDialogData.type_ids = [DEVICES_TYPES.TANDO];
        state.missionDialogData.text = text;
        state.missionDialogData.title = title || state.missionDialogData.title;
        state.missionDialogData.callback = async ({ isConfirmed, deviceId }) => {
          if (isConfirmed && point) {
            const missionObj = {
              zone_id: this.contextStore.zoneId,
              type: 'GOTO',
              ...missionData,
              requested_pois: [{ ...point, ...poiData }],
              device_id: deviceId
            };
            const mission = await this.eventListStore.invokeMission(missionObj);

            if (navigateToMission) {
              this.$router.push({
                name: PAGES.MISSION,
                params: {
                  missionId: mission.id
                }
              });
            }
          }
        };
      });
    }
  }
};
