export const EVENT_SOURCE = {
  MANUAL: 'MANUAL',
  ROBOT: 'ROBOT'
};

export const EVENT_TYPE = {
  MISSION: 'MISSION',
  THERMAL: 'THERMAL',
  MOTION: 'MOTION',
  PERSON: 'PERSON',
  PERSON_DOWN: 'PERSON_DOWN',
  CONNECTIVITY: 'CONNECTIVITY',
  TAMPER: 'TAMPER',
  LAPTOP: 'LAPTOP',
  LIQUID: 'LIQUID',
  CHANGE: 'CHANGE',
  GENERAL: 'GENERAL',
  IOT: 'IOT',
  HARD_COLLISION: 'HARD_COLLISION',
  SOFT_COLLISION: 'SOFT_COLLISION',
  DOOR: 'DOOR'
};

export const EVENT_TYPE_NAME = {
  [EVENT_TYPE.MISSION]: 'Mission',
  [EVENT_TYPE.THERMAL]: 'Thermal Anomaly',
  [EVENT_TYPE.MOTION]: 'Motion Anomaly',
  [EVENT_TYPE.PERSON]: 'Person',
  [EVENT_TYPE.PERSON_DOWN]: 'Person State Anomaly',
  [EVENT_TYPE.TAMPER]: 'Tampering',
  [EVENT_TYPE.LAPTOP]: 'Laptop',
  [EVENT_TYPE.LIQUID]: 'Liquid',
  [EVENT_TYPE.CHANGE]: 'Change',
  [EVENT_TYPE.GENERAL]: 'General Anomaly',
  [EVENT_TYPE.IOT]: 'IoT Action',
  [EVENT_TYPE.SOFT_COLLISION]: 'Soft Collision',
  [EVENT_TYPE.HARD_COLLISION]: 'Hard Collision',
  [EVENT_TYPE.DOOR]: 'Door Anomaly'
};

import IconThermal from '../components/icons/IconThermal.svg?component';
import IconMotionEvent from '../components/icons/IconMotionEvent.svg?component';
import IconPerson from '../components/icons/IconPerson.svg?component';
import IconPersonDown from '../components/icons/IconPersonDown.svg?component';
import IconTamperEvent from '../components/icons/IconTamperEvent.svg?component';
import IconLaptopEvent from '../components/icons/IconLaptopEvent.svg?component';
import IconLiquidEvent from '../components/icons/IconLiquidEvent.svg?component';
import IconChangeEvent from '../components/icons/IconChangeEvent.svg?component';
import IconGeneralEvent from '../components/icons/IconGeneralEvent.svg?component';
import IconIotEvent from '../components/icons/IconIotEvent.svg?component';
import IconDoorEvent from '../components/icons/IconDoorEvent.svg?component';

export const EVENT_TYPE_ICONS = {
  [EVENT_TYPE.THERMAL]: IconThermal,
  [EVENT_TYPE.MOTION]: IconMotionEvent,
  [EVENT_TYPE.PERSON]: IconPerson,
  [EVENT_TYPE.PERSON_DOWN]: IconPersonDown,
  [EVENT_TYPE.TAMPER]: IconTamperEvent,
  [EVENT_TYPE.LAPTOP]: IconLaptopEvent,
  [EVENT_TYPE.LIQUID]: IconLiquidEvent,
  [EVENT_TYPE.CHANGE]: IconChangeEvent,
  [EVENT_TYPE.GENERAL]: IconGeneralEvent,
  [EVENT_TYPE.IOT]: IconIotEvent,
  [EVENT_TYPE.SOFT_COLLISION]: IconTamperEvent,
  [EVENT_TYPE.HARD_COLLISION]: IconTamperEvent,
  [EVENT_TYPE.DOOR]: IconDoorEvent
};

export const MANUAL_EVENT_TYPES = [
  EVENT_TYPE.THERMAL,
  EVENT_TYPE.MOTION,
  EVENT_TYPE.TAMPER,
  EVENT_TYPE.PERSON,
  EVENT_TYPE.PERSON_DOWN,
  EVENT_TYPE.LAPTOP,
  EVENT_TYPE.LIQUID,
  EVENT_TYPE.CHANGE,
  EVENT_TYPE.GENERAL,
  EVENT_TYPE.SOFT_COLLISION,
  EVENT_TYPE.HARD_COLLISION,
  EVENT_TYPE.DOOR
];
