<template>
  <div class="base-modal">
    <div class="modal-form">
      <div class="header">
        <div class="title">
          <slot name="title" />
        </div>
        <IconClose @click="onCloseClick"><title>Close</title></IconClose>
      </div>
      <div class="content"><slot name="body" /></div>
      <div class="footer">
        <BaseLoader v-if="isExecuting" class="execute-loader" />
        <template v-else>
          <BaseCancelButton v-if="cancelOption" @click="onCloseClick">
            <span v-if="cancelOption.text">{{ cancelOption.text }}</span>
          </BaseCancelButton>
          <BaseButton v-if="confirmationOption" :disabled="confirmationOption.disabled" @click="onConfirmClick">
            <span v-if="confirmationOption.text">{{ confirmationOption.text }}</span>
            <span v-else>OK</span>
          </BaseButton>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from '../icons/IconClose.svg?component';
import BaseButton from './BaseButton.vue';
import BaseCancelButton from './BaseCancelButton.vue';
import BaseLoader from './BaseLoader.vue';
export default {
  name: 'BaseModal',
  components: {
    IconClose,
    BaseButton,
    BaseCancelButton,
    BaseLoader
  },
  props: {
    cancelOption: {
      type: [Boolean, Object],
      default: false,
      validator: val => typeof val === 'boolean' || (typeof val === 'object' && val?.text?.length)
    },
    confirmationOption: {
      type: [Boolean, Object],
      default: false,
      validator: val => typeof val === 'boolean' || (typeof val === 'object' && (val?.text?.length || 'disabled' in val))
    },
    isExecuting: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'confirm'],
  methods: {
    onCloseClick() {
      this.$emit('close');
    },
    onConfirmClick() {
      this.$emit('confirm');
    }
  }
};
</script>

<style lang="scss" scoped>
.base-modal {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--overlayShade3);
  width: 100vw;
  height: 100vh;
  z-index: 2;
  margin: 0;
  display: flex;

  @keyframes grow {
    from {
      transform: scale(0.8);
    }
  }

  .modal-form {
    color: var(--textColor);
    background: var(--mainBackground);
    box-shadow: 0 2px 10px var(--shadowColor);
    border-radius: 0.5rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 90vh;
    max-width: 90vw;
    overflow: hidden;
    row-gap: 0.5rem;
    animation: grow 0.5s;

    .content {
      padding: 0 16px;
      overflow: auto;
      max-height: 80vh;
    }

    .header {
      color: var(--highlightColor);
      display: flex;
      justify-content: space-between;
      font-size: 1.3rem;
      font-weight: bold;
      align-items: baseline;
      padding: 12px;
      background: var(--secondaryOverlayShade1);
      column-gap: 20px;
      align-items: flex-start;

      .title {
        flex-grow: 1;
        text-align: center;
      }

      svg {
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          transform: scale(1.1);
          filter: brightness(1.2);
        }

        &:active {
          transform: scale(1.1);
        }
      }
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      column-gap: 0.5rem;
      padding: 16px;

      .execute-loader {
        width: 2.5rem;
        height: 2.5rem;
        margin: 0 16px;
      }
    }
  }
}
</style>
