import { capitalize } from '../StringUtils';
import { router } from '../../router/index.js';
import { DEVICE_PROPERTIES, DEVICE_STATUS, DEVICE_STATE, DEVICES_TYPES, DEVICE_ICON } from '../../consts/deviceConsts';
import { DRAW_ICON_OPTIONS } from '../../consts/drawingConsts';
import { getStyleVar } from '../StyleUtils';
import { useDevicesStore } from '../../store/DevicesStore';
import IconConnection from '../../components/icons/IconConnection.svg?component';
import IconNoConnection from '../../components/icons/IconNoConnection.svg?component';
import IconBatteryCharging from '../../components/icons/IconBatteryCharging.vue';
import IconBattery from '../../components/icons/IconBattery.vue';
import { ERROR_MESSAGES } from '../../consts/appConsts';
import { shallowRef } from 'vue';

export function isPaired(device) {
  return device.status === DEVICE_STATUS.PAIRED && !!getPairedDevice(device);
}
export function getPairedDevice(device) {
  if (device?.state_data?.paired_device) {
    const deviceId = device?.state_data?.paired_device;

    if (deviceId) {
      const devicesStore = useDevicesStore();
      const device = devicesStore.getDeviceById(deviceId);
      return device;
    }
  }
}

export function isCharging(device) {
  return device.is_charging && device.is_connected;
}
export function isDocked(device) {
  return device.status === DEVICE_STATUS.DOCKED;
}
export function isInError(device) {
  return device.state === DEVICE_STATE.ERROR || device.state === DEVICE_STATE.CRITICAL_ERROR || device.state === DEVICE_STATE.DOWN;
}
export function isDockingStation(device) {
  return device?.props?.includes?.(DEVICE_PROPERTIES.DOCKING_STATION);
}
export function isRobot(device) {
  return device.type_id === DEVICES_TYPES.TANDO || device.type_id === DEVICES_TYPES.SKYDIO || device.type_id === DEVICES_TYPES.OSTRICH;
}
export function isIotController(device) {
  return device?.props?.includes?.(DEVICE_PROPERTIES.IOT_CONTROLLER) && device?.type_id !== DEVICES_TYPES.NEST;
}
export function isOutlet(device) {
  return device?.props?.includes?.(DEVICE_PROPERTIES.ELECTRICITY_CONTROL);
}
export function isLight(device) {
  return device?.props?.includes?.(DEVICE_PROPERTIES.LIGHT_CONTROL);
}
export function isVideoStreamingDevice(device) {
  return device?.props?.includes?.(DEVICE_PROPERTIES.VIDEO_STREAM);
}
export function isMotionDetectionDevice(device) {
  return device?.props?.includes?.(DEVICE_PROPERTIES.MOTION_SENSOR);
}

export function getIconKey(device) {
  if (device.type_id === DEVICES_TYPES.SKYDIO) {
    return DRAW_ICON_OPTIONS.SKYDIO_DRONE;
  } else if (isRobot(device)) {
    return DRAW_ICON_OPTIONS.DRONE;
  } else if (isDockingStation(device)) {
    return DRAW_ICON_OPTIONS.TILE;
  } else if (isOutlet(device)) {
    return DRAW_ICON_OPTIONS.IOT_PLUG;
  } else if (isLight(device)) {
    return DRAW_ICON_OPTIONS.IOT_LIGHT;
  }
}
export function getIcon(device) {
  if (isDocked(device)) {
    return DEVICE_ICON.DOCKED_DRONE;
  }
  return DEVICE_ICON[device.type_id];
}
export function getIconConnection(device) {
  return device.is_connected ? IconConnection : IconNoConnection;
}
export function getIconCharging(device) {
  return isCharging(device) ? IconBatteryCharging : IconBattery;
}
export function getStrConnection(device) {
  return device.is_connected ? 'Active' : 'Inactive';
}
export function getStrCharging(device) {
  return isCharging(device) ? 'Active' : 'Inactive';
}
export function getStrState(device) {
  return capitalize(device.state?.replace?.(/_/g, ' ')?.toLowerCase?.());
}
export function getStrErrors(device) {
  return device.errors?.map?.(error => error.description)?.join?.(', ');
}
export function getErrorsFriendlyDescriptions(device) {
  return device.errors
    .map(error => {
      return `(${error.code}) ${error.friendlyDescription || ERROR_MESSAGES.GENERIC_DEVICE_ERROR}`;
    })
    .join(', ');
}
export function getStrBatteryLevel(device) {
  return device.battery_level ? `${device.battery_level}%` : '';
}
export function getColorConnection(device) {
  return device.is_connected ? getStyleVar('--successColor') : undefined;
}
export function getColorCharging(device) {
  return isCharging(device) ? getStyleVar('--successColor') : undefined;
}
export function getColorState(device) {
  return isInError(device) ? getStyleVar('--errorColor') : undefined;
}

export function getDisplayConfigForMap({ device, clickable, showSelectionMark } = {}) {
  const config = {
    id: device.id,
    x: device.location.x,
    y: device.location.y,
    icon: shallowRef(getIcon(device)),
    iconKey: getIconKey(device),
    text: device.name,
    isDisabled: !device.enabled,
    isStatic: isDockingStation(device) || isIotController(device),
    isTile: isDockingStation(device),
    isDocked: isDocked(device),
    customData: {
      isCharging: isCharging(device),
      isSystemError: isInError(device),
      heading: device.location.h
    },
    showSelectionMark
  };

  if (clickable) {
    config.callback = () => {
      router.push({
        path: `/zones/${device.zone_id}/devices/${device.id}`
      });
    };
  }

  return config;
}
export function isOnUpgrade(device) {
  return device.state === DEVICE_STATE.UPGRADE && device.enabled;
}
export function isBooting(device) {
  return device.state === DEVICE_STATE.BOOT;
}
