import * as msal from '@azure/msal-browser';
import { useContextStore } from '../store/ContextStore';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/organizations`,
    redirectUri: import.meta.env.VITE_SSO_REDIRECT_URI
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const loginRequest = {
  scopes: ['openid', 'profile', 'email']
};

/**
 * Scopes you add here will be used to request a token from Azure AD to be used for accessing a protected resource.
 * To learn more about how to work with scopes and resources, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
const tokenScopes = [`${import.meta.env.VITE_AZURE_WEB_API_URI}/access_as_user`];

const tokenRequest = {
  scopes: tokenScopes
};

const refreshTokenRequest = {
  scopes: tokenScopes,
  forceRefresh: true
};

class AzureConnector {
  constructor() {
    this.azureClient = new msal.PublicClientApplication(msalConfig);
    this.azureClient
      .handleRedirectPromise()
      .then(loginResponse => this._handleRedirectLoginResponse(loginResponse))
      .catch(error => useContextStore().setAzureRedirectError(error));
  }

  async _handleRedirectLoginResponse(loginResponse) {
    if (loginResponse !== null) {
      let getTokenResponse;
      const contextStore = useContextStore();
      contextStore.setAzureRedirectBeingProcessed();
      this.username = loginResponse.account.username;

      if (!tokenRequest.account) {
        tokenRequest.account = await this.azureClient.getAccountByUsername(this.username);
      }

      try {
        getTokenResponse = await this.azureClient.acquireTokenSilent(tokenRequest);
      } catch (error) {
        if (error instanceof msal.InteractionRequiredAuthError) {
          getTokenResponse = await this.azureClient.acquireTokenRedirect(tokenRequest);
        } else {
          contextStore.setAzureRedirectError(error);
        }
      }

      if (getTokenResponse) {
        contextStore.setAzureRedirectResponse(getTokenResponse);
      } else {
        contextStore.setAzureRedirectError(true);
      }
    }
  }

  async authenticateUsingAzure() {
    try {
      // Attempt to retrieve account from cache
      if (!tokenRequest.account) {
        const currentAccounts = this.azureClient.getAllAccounts();
        if (currentAccounts.length === 1) {
          tokenRequest.account = currentAccounts[0];
        }
      }

      // Attempt to login silently (with no redirect to azure login)
      if (tokenRequest.account) {
        try {
          return await this.azureClient.acquireTokenSilent(tokenRequest);
        } catch (error) {
          // eslint-disable-next-line no-empty
        }
      }

      await this.azureClient.loginRedirect(loginRequest);
    } catch (e) {
      console.warn(e);
    }
  }

  async logoutAzureUser() {
    this.azureClient.logoutRedirect({
      onRedirectNavigate: url => {
        // Return false if you would like to stop navigation after local logout
        // Meaning: avoid redirect to azure for logout dialog
        return false;
      }
    });
  }
  async refreshAzureToken(azureUsername) {
    if (!refreshTokenRequest.account) {
      refreshTokenRequest.account = await this.azureClient.getAccountByUsername(azureUsername);
    }
    let getTokenResponse;
    const contextStore = useContextStore();
    try {
      getTokenResponse = await this.azureClient.acquireTokenSilent(refreshTokenRequest);
    } catch (error) {
      contextStore.setAzureRedirectError(error);
      throw new Error(error);
    }
    if (getTokenResponse) {
      contextStore.setAzureRedirectResponse(getTokenResponse);
    } else {
      contextStore.setAzureRedirectError(true);
      throw new Error('refreshAzureToken did not get response');
    }
  }
}

export default new AzureConnector();
