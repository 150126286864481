<template>
  <div class="menu-wrapper">
    <div class="menu-toggle" @click="openClose">
      <slot name="toggle" />
    </div>
    <div v-show="isOpen" ref="menu" class="menu-items" :class="position">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export const MENU_POSITION_OPTIONS = {
  BOTTOM: 'bottom',
  TOP: 'top',
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center'
};

export default {
  name: 'BaseMenu',
  props: {
    position: {
      type: Array,
      default: () => [MENU_POSITION_OPTIONS.BOTTOM, MENU_POSITION_OPTIONS.LEFT]
    }
  },
  data() {
    return {
      isOpen: false,
      closeEventId: 0
    };
  },
  beforeUnmount() {
    window.removeEventListener('click', this.closeListener);
  },
  methods: {
    closeListener(e) {
      if (e.id != this.closeEventId && this.isClickOutsideMenu(e, this.$refs.menu)) {
        window.removeEventListener('click', this.closeListener);
        this.isOpen = false;
      }
    },
    openClose(e) {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        window.addEventListener('click', this.closeListener);
        this.closeEventId = e.id = Date.now();
      } else {
        window.removeEventListener('click', this.closeListener);
      }
    },
    isClickOutsideMenu(event, menuElement) {
      if (menuElement == event.target || menuElement.contains(event.target)) {
        return false;
      }

      if (this.isOpen && menuElement != event.target) {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-wrapper {
  position: relative;

  .menu-toggle {
    cursor: pointer;
    transition: filter 0.3s;

    &:hover {
      filter: brightness(0.9);
    }

    &:active {
      filter: brightness(1.1);
    }
  }

  .menu-items {
    position: absolute;
    z-index: 2;

    &.bottom {
      top: 2rem;
      animation: slideUp 0.3s ease forwards;
    }

    &.top {
      animation: slideDown 0.3s ease forwards;
      top: -4px;
      transform: translateY(-100%);
    }

    &.right {
      right: 100%;

      &.bottom,
      &.top {
        right: 0;
      }

      &:not(.top):not(.bottom) {
        animation: slideRight 0.3s ease forwards;
      }
    }

    &.left {
      left: 100%;

      &.bottom,
      &.top {
        left: 0;
      }

      &:not(.top):not(.bottom) {
        animation: slideLeft 0.3s ease forwards;
      }
    }

    &.center {
      left: 50%;
      transform: translateX(-50%);

      &.top {
        transform: translate(-50%, -100%);
      }
    }
  }

  @keyframes slideUp {
    from {
      padding-top: 2rem;
    }
  }
  @keyframes slideDown {
    from {
      padding-bottom: 2rem;
    }
  }
  @keyframes slideLeft {
    from {
      padding-left: 2rem;
    }
  }
  @keyframes slideRight {
    from {
      padding-right: 2rem;
    }
  }
}
</style>
