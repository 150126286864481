import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 30 30",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", null, [
      _createElementVNode("path", { d: "M29,11.15c-0.27,0-0.53-0.11-0.73-0.31C24.72,7.07,20.01,5,15,5S5.28,7.07,1.73,10.84c-0.38,0.4-1.01,0.42-1.41,0.04  c-0.4-0.38-0.42-1.01-0.04-1.41C4.2,5.3,9.43,3,15,3s10.8,2.3,14.73,6.46c0.38,0.4,0.36,1.03-0.04,1.41  C29.49,11.06,29.25,11.15,29,11.15z" }),
      _createElementVNode("path", { d: "M25.79,14.8c-0.27,0-0.54-0.11-0.73-0.32C22.38,11.59,18.8,10,15,10s-7.38,1.59-10.05,4.48c-0.38,0.41-1.01,0.43-1.41,0.05  c-0.41-0.38-0.43-1.01-0.05-1.41C6.54,9.82,10.63,8,15,8s8.46,1.82,11.52,5.12c0.38,0.4,0.35,1.04-0.05,1.41  C26.27,14.71,26.03,14.8,25.79,14.8z" }),
      _createElementVNode("path", { d: "M15,14c-3.87,0-7,3.13-7,7s3.13,7,7,7s7-3.13,7-7S18.87,14,15,14z M18.11,22.69c0.39,0.39,0.39,1.02,0,1.41  c-0.2,0.2-0.45,0.29-0.71,0.29s-0.51-0.1-0.71-0.29L15,22.41l-1.69,1.69c-0.2,0.2-0.45,0.29-0.71,0.29s-0.51-0.1-0.71-0.29  c-0.39-0.39-0.39-1.02,0-1.41L13.59,21l-1.69-1.69c-0.39-0.39-0.39-1.02,0-1.41s1.02-0.39,1.41,0L15,19.59l1.69-1.69  c0.39-0.39,1.02-0.39,1.41,0s0.39,1.02,0,1.41L16.41,21L18.11,22.69z" })
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }