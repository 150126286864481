<template>
  <div :id="`${playerId}_container`" class="player-container">
    <video :id="playerId" :ref="playerId" class="video-js vjs-fluid" />
    <VideoCanvas
      v-if="renderCanvas"
      v-show="!playerConfig.isInError"
      ref="videoCanvas"
      class="video-canvas"
      :player="player"
      :pause-at="playerConfig.triggerPauseTime"
      :config="playerConfig.objectDetection"
      :thermal-dimensions="thermalVideoDimensions"
      :mark-config="playerConfig.markers"
      :video-start-time="videoStartTime"
    />
    <video
      v-if="renderThermalPlayer"
      v-show="thermalOverlayState.showOverlay"
      :id="thermalPlayerId"
      ref="thermalOverlay"
      class="video-js vjs-fluid hide-wrapper"
      :style="thermalOverlayStyle"
    ></video>
    <div
      v-if="renderThermalPlayer"
      v-show="showThermalPlayerSwitch"
      ref="thermalOverlaySwitch"
      :title="thermalPlayerSwitcherTitle"
      class="thermal-toggle"
    >
      <span>Thermal</span>
      <BaseToggle v-model="thermalOverlayState.showOverlay" :disabled="!enableThermalSwitch" />
    </div>
    <div v-if="showDownloadButton" ref="downloadIcon">
      <IconDownload v-if="!playerConfig.isDownloading" class="download-icon"><title>Download Video</title></IconDownload>
      <BaseLoader v-else class="loader" />
    </div>
  </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './customVideoJsPlugins/zoomAndDragPlugin.js';
import 'videojs-markers-plugin';
import 'videojs-markers-plugin/dist/videojs.markers.plugin.min.css';

import { timestampToVideoTime, videoTimeToTimestamp } from '../../utils/VideoTimeUtils';
import BaseLoader from '../base/BaseLoader.vue';
import VideoCanvas from './VideoCanvas.vue';
import BaseToggle from '../base/BaseToggle.vue';
import IconDownload from '../icons/IconDownload.svg?component';
import { PRIVILEGES } from '../../consts/authConsts';
import { mapState, mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import { useVideoPlayerStore, PLAYER_EVENTS } from '../../store/VideoPlayerStore';
import { CustomControlButton } from './customVideoJsPlugins/customControlButton';
import { toRaw } from 'vue';

const THERMAL_DATA_MATRIX = {
  width: 24,
  height: 32
};
const VIDEO_FIELD_OF_VIEW_ANGLE = 110;
const THERMAL_FIELD_OF_VIEW_ANGLE = 75;
const VIDEO_TO_THERMAL_LANDSCAPE_RATIO = (THERMAL_FIELD_OF_VIEW_ANGLE / VIDEO_FIELD_OF_VIEW_ANGLE) * 100;
/**
 * TODO: reimplement markers plugin as it uses deprecated videojs apis and no longer maintained
 */
export default {
  name: 'VideoPlayer',
  components: {
    VideoCanvas,
    BaseLoader,
    BaseToggle,
    IconDownload
  },
  props: {
    type: {
      type: String,
      default: `front`
    },
    allowFullscreen: {
      type: Boolean,
      default: true
    },
    displayControls: {
      type: Boolean,
      default: true
    },
    publishFrame: {
      type: Boolean,
      required: true
    }
  },
  emits: ['stream-stop', 'loaded', 'thermal-loaded', 'inactive', 'waiting', 'can-play'],
  data() {
    return {
      player: null,
      thermalOverlayPlayer: null,
      options: {
        controls: this.displayControls,
        preload: 'metadata',
        muted: true,
        inactivityTimeout: 0,
        playbackRates: [0.5, 1, 1.5, 2, 3, 4],
        controlBar: {
          remainingTimeDisplay: false,
          liveDisplay: true,
          volumePanel: false,
          pictureInPictureToggle: false,
          fullscreenToggle: this.allowFullscreen,
          skipButtons: {
            forward: 10,
            backward: 10
          }
        }
      },
      thermalOverlayState: {
        showOverlay: true
      },
      thermalVideoDimensions: { ...THERMAL_DATA_MATRIX },
      activePlayersReadyMap: {},
      zoomAndDragManger: null,
      wasCanvasRendered: false,
      segments: null,
      videoStartTime: 0,
      videoDuration: 0,
      playerHeight: 0,
      showThermalPlayerSwitch: false
    };
  },
  computed: {
    ...mapStores(useContextStore, useVideoPlayerStore),
    ...mapState(useVideoPlayerStore, ['thermal', 'isAnalyticsLegacyMode']),
    playerConfig() {
      return this.videoPlayerStore[this.type];
    },
    playerId() {
      return `video_player_${this.type}`;
    },
    thermalPlayerId() {
      return `thermal_overlay_${this.type}`;
    },
    renderThermalPlayer() {
      return this.type === 'front' && this.playerConfig.src && this.thermal.src && !this.thermal.isInError;
    },
    thermalPlayerSwitcherTitle() {
      return this.renderThermalPlayer ? 'Thermal feed' : 'Thermal feed is unavailable';
    },
    showDownloadButton() {
      return (
        this.playerConfig.src &&
        !this.playerConfig.isInError &&
        !this.videoPlayerStore.isLive &&
        this.contextStore.hasPrivilege(PRIVILEGES.DOWNLOAD_VIDEOS)
      );
    },
    enableThermalSwitch() {
      return this.renderThermalPlayer && !this.thermal.isInError;
    },
    thermalOverlayStyle() {
      const style = {
        opacity: 0.7,
        filter: 'blur(0.8em)'
      };

      // If thermal is in landscape layout
      if (this.thermalVideoDimensions.width > this.thermalVideoDimensions.height) {
        style.height = `${VIDEO_TO_THERMAL_LANDSCAPE_RATIO}%`;
        style.marginTop = `${((50 - VIDEO_TO_THERMAL_LANDSCAPE_RATIO / 2) / 100) * this.playerHeight}px`;
      }

      return style;
    },
    renderCanvas() {
      return (
        !this.videoPlayerStore.odFetchFailed &&
        this.playerConfig.src &&
        this.player &&
        (this.playerConfig.objectDetection || this.playerConfig.triggerPauseTime)
      );
    }
  },
  watch: {
    'playerConfig.src': {
      handler: function playerSrcWatch(newVal) {
        if (newVal) {
          this.player.src({ src: newVal, type: this.playerConfig.source === 'MP4 VIDEO FILE' ? 'video/mp4' : 'application/x-mpegURL' });
          this.thermalOverlayPlayer?.src?.({
            src: this.videoPlayerStore.thermal.src,
            type: this.videoPlayerStore.thermal.source === 'MP4 VIDEO FILE' ? 'video/mp4' : 'application/x-mpegURL'
          });
        } else {
          this.player.src({ src: '_', type: 'video/mp4' });
          this.thermalOverlayPlayer?.src?.({ src: '_', type: 'video/mp4' });
        }
      }
    }
  },
  beforeMount() {
    this.playerConfig.eventBus.addEventListener(PLAYER_EVENTS.NEW_MARKER, this.addMarker);
    this.playerConfig.eventBus.addEventListener(PLAYER_EVENTS.FRAME_CHANGE, this.changeFrame);
    if (this.videoPlayerStore.isLive) {
      delete this.options.controlBar.skipButtons;
    }
    this.thermalOverlayState.showOverlay = !!this.videoPlayerStore.thermal.enabledByDefault;
  },
  mounted() {
    // Override videoJS default error message of no media
    videojs.addLanguage('en', {
      'No compatible source was found for this media.': this.videoPlayerStore.noVideoError
    });
    videojs.addLanguage('en', {
      'The media could not be loaded, either because the server or network failed or because the format is not supported.':
        this.videoPlayerStore.noVideoError
    });
    this.initPlayer();
  },
  beforeUnmount() {
    this?.player?.dispose?.();
    this?.thermalOverlayPlayer?.dispose?.();
    this.playerConfig.eventBus.removeEventListener(PLAYER_EVENTS.NEW_MARKER, this.addMarker);
    this.playerConfig.eventBus.removeEventListener(PLAYER_EVENTS.FRAME_CHANGE, this.changeFrame);
    window.removeEventListener('resize', this.resizeThermalOverlay);
  },
  methods: {
    initPlayer() {
      this.options.autoplay = this.videoPlayerStore[this.type].autoplay;
      this.player = videojs(
        this.playerId,
        {
          ...this.options,
          sources: [
            {
              src: this.playerConfig.src,
              type: this.playerConfig.source === 'MP4 VIDEO FILE' ? 'video/mp4' : 'application/x-mpegURL'
            }
          ]
        },
        () => {
          if (this.showDownloadButton) {
            this.embedDownloadButton();
          }
          if (this.renderThermalPlayer) {
            this.initializeThermalOverlayPlayer();
          }

          this.zoomAndDragManger = this.player.zoomAndDrag({
            minZoom: this.videoPlayerStore.defaultZoom,
            containerId: `${this.playerId}_container`,
            overlayId: this.thermalPlayerId
          });
          this.player.markers({
            markerTip: {
              display: true,
              text: marker => marker.text,
              time: marker => marker.time
            }
          });
        }
      );
      this.player.ready(() => {
        this.$emit('loaded', toRaw(this.player));
        this.attachListeners();
        const VideoFrameCallback = () => {
          if (this.renderCanvas) {
            this.$refs.videoCanvas.calcFrame();
          }
          this.triggerFrameChange();
          this.player.tech({ IWillNotUseThisInPlugins: true }).el_.requestVideoFrameCallback(VideoFrameCallback);
        };
        this.player.tech({ IWillNotUseThisInPlugins: true }).el_.requestVideoFrameCallback(VideoFrameCallback);
      });
    },
    addMarker(e) {
      const marker = e.detail;
      const videoStartTime = this.segments?.length ? this.segments[0].dateTimeObject.getTime() : this.videoPlayerStore.startVideoAtTimestamp;
      marker.time = Math.max(0, (marker.timestamp - videoStartTime) / 1000);
      this.player.markers.add([marker]);
      this.videoPlayerStore.$patch(state => state[this.type].markers.push(marker));
    },
    changeFrame(e) {
      const timestamp = e.detail;
      // To be used by parent components upon change triggered by map click
      const relativeFrame = this.getRelativeFrameByTimestamp(timestamp);
      this.player.currentTime(relativeFrame);
      this.triggerFrameChange();
    },
    resizeThermalOverlay() {
      if (this.$refs?.thermalOverlay) {
        this.$refs.thermalOverlay.style.height = `${this.player.currentHeight()}px`;
      }
    },
    embedThermalSwitch() {
      this.resizeThermalOverlay();
      window.addEventListener('resize', this.resizeThermalOverlay);
      const playerContainer = document.getElementById(this.playerId);

      // Append overlay just after the video, and before the controls
      if (this.$refs.thermalOverlay) {
        playerContainer.insertBefore(this.$refs.thermalOverlay, playerContainer.children[2]);
      }
      if (this.$refs.thermalOverlaySwitch) {
        const thermalSwitch = new CustomControlButton(this.player, {
          name: 'thermalToggle',
          contentElement: this.$refs.thermalOverlaySwitch,
          text: 'Thermal feed'
        });
        const controlBar = this.player.getChild('controlBar');
        this.thermalToggleButton = controlBar.addChild(thermalSwitch, {}, controlBar.children().length - 2);
        this.showThermalPlayerSwitch = true;
      }
    },
    embedDownloadButton() {
      const downloadButton = new CustomControlButton(this.player, {
        name: 'downloadButton',
        contentElement: this.$refs.downloadIcon,
        text: 'Download video'
      });
      const controlBar = this.player.getChild('controlBar');
      this.downloadButtonElement = controlBar.addChild(downloadButton, {}, controlBar.children().length - 1);
      this.downloadButtonElement.el().onclick = () => {
        this.downloadButtonElement.el().style.setProperty('pointer-events', 'none');

        // Blob download case
        if (this.player.currentType() === 'video/mp4') {
          this.videoPlayerStore.$patch(state => {
            state[this.type].isDownloading = true;
          });
          window.location.replace(this.playerConfig.src);
          setTimeout(() => {
            if (this.playerConfig.isDownloading) {
              this.downloadButtonElement.el().style.removeProperty('pointer-events');
              this.videoPlayerStore.$patch(state => {
                state[this.type].isDownloading = false;
              });
            }
          }, 1000);
        } else {
          // Stream download case
          this.playerConfig.eventBus.dispatchEvent(new CustomEvent(PLAYER_EVENTS.REQUEST_STREAM_FILE, { detail: this.type }));
        }
      };
    },
    embedCanvasContainer() {
      if (this.renderCanvas && !this.wasCanvasRendered) {
        const playerContainer = document.getElementById(this.playerId);
        // Append canvas just after the video, and before the controls
        playerContainer.insertBefore(this.$refs.videoCanvas.$el, playerContainer.children[2]);
        this.wasCanvasRendered = true;
      }
    },
    initializeThermalOverlayPlayer() {
      this.thermalOverlayPlayer = videojs(this.thermalPlayerId, {
        controls: false,
        autoplay: true,
        preload: 'auto',
        muted: true,
        sources: [
          {
            src: this.thermal.src,
            type: this.thermal.source === 'MP4 VIDEO FILE' ? 'video/mp4' : 'application/x-mpegURL'
          }
        ]
      });
      this.activePlayersReadyMap.overlay = false;
      this.thermalOverlayPlayer.ready(() => {
        this.embedThermalSwitch();
        this.thermalOverlayPlayer.on('loadedmetadata', () => {
          this.$emit('thermal-loaded', this.thermalOverlayPlayer);
          this.thermalVideoDimensions = {
            width: this.thermalOverlayPlayer.videoWidth() || THERMAL_DATA_MATRIX.width,
            height: this.thermalOverlayPlayer.videoHeight() || THERMAL_DATA_MATRIX.height
          };
          this.activePlayersReadyMap.overlay = true;
          this.onPlayerReady();
        });
        this.thermalOverlayPlayer.on('error', err => {
          // Show disabled thermal overlay switch
          console.error('Disabling thermal player due to player error:', err);
          this.embedThermalSwitch();
          this.videoPlayerStore.$patch(store => (store.thermal.isInError = true));
        });
      });
    },
    onPlayerReady() {
      if (Object.values(this.activePlayersReadyMap).every(isPlayerReady => isPlayerReady)) {
        this.embedCanvasContainer();
        this.zoom = this.videoPlayerStore.defaultZoom;
        this.zoomAndDragManger.zoomAndDrag({
          zoom: this.zoom,
          posOnPlayer: {
            x: 0,
            y: 0
          }
        });
      }
    },
    triggerFrameChange() {
      if (this.player) {
        let relativeFrame = this.player.currentTime() * 1000;
        let absoluteFrame;
        try {
          this.segments = this.segments || this.player.tech({ IWillNotUseThisInPlugins: true }).vhs.playlists.media().segments;
          absoluteFrame = videoTimeToTimestamp({
            segments: this.segments,
            videoTime: this.player.currentTime()
          });
        } catch (e) {
          console.error('frame update failed due to:', e);
        }
        if (this.publishFrame) {
          this.playerConfig.eventBus.dispatchEvent(new CustomEvent(PLAYER_EVENTS.FRAME, { detail: { relativeFrame, absoluteFrame } }));
        }
      }
    },
    getRelativeFrameByTimestamp(timestamp) {
      try {
        const params = { timestamp };
        if (this.player.currentType() === 'video/mp4') {
          params.videoStartTimestamp = this.videoPlayerStore.startVideoAtTimestamp;
        } else {
          this.segments = this.segments || this.player.tech({ IWillNotUseThisInPlugins: true }).vhs.playlists.media().segments;
          params.segments = this.segments;
        }
        return timestampToVideoTime(params);
      } catch (e) {
        console.error(e);
      }
    },
    attachListeners() {
      this.player.on('error', e => {
        // Change src to be empty in order to prevent infinite retry playlist event
        this.videoPlayerStore.$patch(store => {
          store[this.type].src = null;
          store[this.type].isInError = true;
        });
        this.$emit('inactive');
        this.$emit('stream-stop');
        this.isPlayerInError = true;
      });
      this.player.on('waiting', () => {
        if (this.player.readyState() <= 2) {
          this.$emit('waiting');
        }
      });
      this.player.on('canplay', () => {
        if (this.videoStartTime === 0) {
          this.videoStartTime = this.player.currentTime();
          this.videoDuration = this.player.duration();
        }
        this.player.controlBar.el().style.setProperty('opacity', '100%');
        this.$emit('can-play');
      });
      this.player.on('loadedmetadata', () => {
        this.videoPlayerStore.$patch(store => {
          store[this.type].isInError = false;
        });
        if (!this.timePublished) {
          this.timePublished = true;
          this.playerConfig.eventBus.dispatchEvent(new CustomEvent(PLAYER_EVENTS.DURATION, { detail: this.player.duration() }));
        }
        this.activePlayersReadyMap.main = true;
        this.onPlayerReady();
        this.segments = this.segments || this.player.tech({ IWillNotUseThisInPlugins: true }).vhs.playlists.media().segments;
        const videoStartTime = this.segments?.length ? this.segments[0].dateTimeObject.getTime() : this.videoPlayerStore.startVideoAtTimestamp;
        const markers = this.playerConfig.markers.map(marker => ({
          time: Math.max(0, (marker.timestamp - videoStartTime) / 1000),
          text: marker.label,
          class: marker.type === 'event' ? 'event-marker' : 'poi-marker'
        }));
        this.player.markers.add(markers);
      });
      this.player.on('fullscreenchange', () => {
        this.videoPlayerStore.$patch(state => {
          state.isVideoFullScreen = this.player.isFullscreen();
        });
        this.zoom = this.minZoom;
        this.player.zoomAndDrag({
          zoom: this.zoom,
          posOnPlayer: {
            x: 0,
            y: 0
          }
        });
      });
      this.player.on('playerresize', () => {
        this.playerHeight = this.player.currentHeight();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.player-container {
  position: relative;

  .download-icon {
    cursor: pointer;
    width: 1.3rem;
    height: 1.3rem;
  }

  .video-js {
    .thermal-toggle {
      transform: scale(0.85);
      height: 2.3rem;
    }

    :deep(.vjs-seek-button) {
      cursor: pointer;

      &.skip-back .vjs-icon-placeholder::before {
        transform: rotate(-45deg);
      }

      &.skip-forward .vjs-icon-placeholder::before {
        transform: scale(-1, 1) rotate(-45deg);
      }
    }

    :deep(.vjs-control-bar) {
      font-size: 0.9rem;

      .loader {
        margin: 0 auto;
      }
    }

    :deep(.vjs-time-control) {
      padding: 0 !important;
      display: flex !important;
      justify-content: center;
    }

    :deep(.vjs-skip-backward-10, .vjs-skip-forward-10) {
      flex-basis: 1.5rem;
    }

    &.hide-wrapper {
      display: none;
    }

    :deep(.vjs-marker) {
      z-index: 0;
    }

    :deep(.event-marker) {
      border-radius: 50% !important;
      background-color: var(--highlightColor) !important;
    }

    :deep(.poi-marker) {
      border-radius: 50% !important;
      background-color: var(--successColorSade1) !important;
    }

    :deep(.vjs-skip-forward-10),
    :deep(.vjs-skip-backward-10) {
      .vjs-icon-placeholder::before {
        font-size: 1.4rem;
      }
    }
  }
}
</style>
