import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32",
  role: "icon"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", { transform: "translate(-1735 -1872) translate(1735 1872)" }, [
      _createElementVNode("rect", {
        width: "30",
        height: "30",
        x: "1",
        y: "1",
        fill: "#3B3B3B",
        stroke: "#A9A9A9",
        "stroke-width": "2",
        rx: "6"
      }),
      _createElementVNode("g", { fill: "#75787D" }, [
        _createElementVNode("path", {
          d: "M3.876.184c.244.243.244.637 0 .88C2.183 2.75 1.25 4.99 1.25 7.37s.933 4.62 2.625 6.306c.244.243.244.637 0 .88-.122.121-.282.182-.442.182-.16 0-.32-.06-.441-.183C1.062 12.634 0 10.082 0 7.37 0 4.656 1.063 2.105 2.992.184c.244-.243.64-.243.884 0zM17.009.182C18.937 2.105 20 4.656 20 7.37s-1.063 5.265-2.992 7.185c-.121.122-.281.183-.441.183-.16 0-.32-.06-.442-.183-.244-.243-.244-.637 0-.88 1.693-1.686 2.625-3.924 2.625-6.306 0-2.38-.933-4.62-2.625-6.305-.244-.243-.244-.638 0-.88.244-.244.64-.244.884 0zm-5.755.592c.268.104.43.375.394.658l-.521 4.07h2.415c.236 0 .451.132.557.341.107.21.086.46-.053.65l-5.356 7.26c-.12.164-.31.254-.504.254-.09 0-.18-.02-.265-.06-.27-.125-.413-.424-.342-.713l1.205-4.828H6.458c-.233 0-.447-.13-.555-.336-.107-.206-.09-.455.045-.645l4.57-6.43c.166-.234.47-.325.736-.221zM5.643 1.943c.244.243.244.637 0 .88C4.421 4.037 3.75 5.652 3.75 7.37c0 1.718.672 3.332 1.893 4.546.244.242.244.637 0 .88-.123.122-.283.183-.443.183-.16 0-.32-.06-.442-.183C3.302 11.345 2.5 9.42 2.5 7.37c0-2.05.802-3.977 2.258-5.426.245-.243.64-.243.885 0zm9.599 0c1.456 1.45 2.258 3.376 2.258 5.426 0 2.05-.802 3.977-2.258 5.426-.122.122-.282.183-.442.183-.16 0-.32-.06-.442-.183-.244-.243-.244-.637 0-.88 1.22-1.214 1.892-2.828 1.892-4.546 0-1.717-.672-3.332-1.893-4.546-.244-.242-.244-.637 0-.88.245-.243.64-.243.885 0z",
          transform: "translate(6 9)"
        })
      ])
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }