import { videoTimeToTimestamp, timestampToVideoTime } from '../../utils/VideoTimeUtils';
const MAX_START_TIMESTAMP_GAP = 3000; // 3 Seconds

class VideoSyncManager {
  constructor(options) {
    this.groups = options.groups;
    this.activeGroup = options.activeGroup;
    this.delayMap = options.delayMap;
    this.latePlayers = {};
    this.groupsStates = Object.keys(this.groups).reduce((statesMap, groupId) => {
      statesMap[groupId] = this.groups[groupId].reduce((playersStateMap, player) => {
        playersStateMap[player.id()] = {
          isSeeked: false
        };
        return playersStateMap;
      }, {});
      return statesMap;
    }, {});
    this.initProps();
    this.fetchPlayersSegments();
    this.syncPlayersStartTime();

    Object.keys(this.eventMap).forEach(eventType => {
      this.activePlayers.forEach(player => {
        player.on(eventType, this.eventMap[eventType]);
      });
    });
  }

  get activePlayers() {
    return this.groups[this.activeGroup];
  }
  get activePlayersStates() {
    return this.groupsStates[this.activeGroup];
  }

  get activePlayersMap() {
    return this.groups[this.activeGroup].reduce((map, player) => {
      map[player.id()] = player;
      return map;
    }, {});
  }

  initProps() {
    this.eventMap = {
      pause: async event => {
        const player = event.target.player;
        if (player.seeking() || player.ended() || this.activePlayersStates[player.id()].isDormant) {
          // Seek triggers pause, and should be manually ignored
          // Also do not sync dormant late players (players that are paused until the other videos reach their start time)
          return;
        }

        const pausePromises = [];
        for (let index = 0; index < this.activePlayers.length; index++) {
          const otherPlayer = this.activePlayers[index];

          if (otherPlayer.id() !== player.id() && !otherPlayer.paused()) {
            pausePromises.push(otherPlayer.pause());
          }
        }

        await Promise.allSettled(pausePromises);
      },
      play: async event => {
        const player = event.target.player;
        if (this.activePlayersStates[player.id()].isDormant) {
          return;
        }

        if (this.activePlayersStates[player.id()].isBuffering) {
          this.activePlayersStates[player.id()].isBuffering = false;

          // Align time to other player
          const playerToAlignTo = this.activePlayers.find(
            p => !this.activePlayersStates[p.id()].isBuffering && p.id() !== player.id() && !this.activePlayersStates[p.id()].isDormant
          );

          if (playerToAlignTo) {
            await this.alignToPlayer(playerToAlignTo);
          }
        }

        const playRequests = [];
        this.activePlayers.forEach(otherPlayer => {
          if (
            otherPlayer.id() !== player.id() &&
            otherPlayer.paused() &&
            !otherPlayer.ended() &&
            !this.activePlayersStates[otherPlayer.id()].isDormant
          ) {
            playRequests.push(otherPlayer.play());
          }
        });
        await Promise.allSettled(playRequests);
      },
      waiting: async event => {
        const player = event.target.player;
        const isBuffering = player.readyState() < 3;
        this.activePlayersStates[player.id()].isBuffering = isBuffering;
      },
      seeking: async event => {
        const player = event.target.player;
        if (this.activePlayersStates[player.id()].isDormant) {
          return;
        }

        if (this.otherPlayerTriggeredSeek(player.id())) {
          this.activePlayersStates[player.id()].isSeeked = true;
          const allPlayersSeeked = !Object.keys(this.activePlayersStates).some(pId => !this.activePlayersStates[pId].isSeeked);
          if (allPlayersSeeked) {
            // Reset seek flag
            Object.keys(this.activePlayersStates).forEach(pId => (this.activePlayersStates[pId].isSeeked = false));
          }
          return;
        } else {
          this.activePlayersStates[player.id()].isSeeked = true;
        }

        await this.alignToPlayer(player);
      },
      timeupdate: async event => {
        const player = typeof event.target.player === 'function' ? event.target.player() : event.target.player;

        if (this.activePlayersStates[player.id()].isDormant) {
          return;
        }

        for (let index = 0; index < Object.keys(this.latePlayers).length; index++) {
          const latePlayerId = Object.keys(this.latePlayers)[index];
          const currentTimestamp = videoTimeToTimestamp({
            segments: this.activePlayersStates[player.id()].segments,
            videoTime: player.currentTime()
          });
          if (currentTimestamp >= this.latePlayers[latePlayerId].wakeAtTimestamp && this.activePlayersStates[latePlayerId].isDormant) {
            await this.activePlayersMap[latePlayerId].play();
            this.activePlayersStates[latePlayerId].isDormant = false;
          }
        }
      },
      volumechange: event => {
        const player = event.target.player;
        const volume = player.volume();
        this.activePlayers.forEach(otherPlayer => {
          if (otherPlayer.id() !== player.id() && otherPlayer.volume() !== volume) {
            otherPlayer.volume(volume);
          }
        });
      },
      ratechange: event => {
        const player = event.target.player;
        const playbackRate = player.playbackRate();
        this.activePlayers.forEach(otherPlayer => {
          if (otherPlayer.id() !== player.id() && otherPlayer.playbackRate() !== playbackRate) {
            otherPlayer.playbackRate(playbackRate);
          }
        });
      }
    };
  }

  otherPlayerTriggeredSeek(playerId) {
    return Object.keys(this.activePlayersStates).some(pId => this.activePlayersStates[pId].isSeeked && playerId !== pId);
  }

  async alignToPlayer(playerToAlignTo) {
    let seekTimestamp;
    if (playerToAlignTo.currentType() !== 'video/mp4') {
      seekTimestamp = videoTimeToTimestamp({
        segments: this.activePlayersStates[playerToAlignTo.id()].segments,
        videoTime: playerToAlignTo.currentTime()
      });

      if (this.delayMap[playerToAlignTo.id()]) {
        seekTimestamp = Math.max(0, seekTimestamp - this.delayMap[playerToAlignTo.id()]);
      }
    }

    for (let index = 0; index < this.activePlayers.length; index++) {
      const otherPlayer = this.activePlayers[index];

      if (otherPlayer.id() !== playerToAlignTo.id()) {
        let otherPlayerVideoTime = seekTimestamp;

        if (otherPlayer.currentType() !== 'video/mp4' && playerToAlignTo.currentType() !== 'video/mp4') {
          if (this.latePlayers[otherPlayer.id()] && seekTimestamp < this.latePlayers[otherPlayer.id()].wakeAtTimestamp) {
            this.activePlayersStates[otherPlayer.id()].isDormant = true;
            await otherPlayer.pause();
            otherPlayer.currentTime(0);
            return;
          }

          otherPlayerVideoTime = timestampToVideoTime({
            segments: this.activePlayersStates[otherPlayer.id()].segments,
            timestamp: seekTimestamp
          });
        } else {
          otherPlayerVideoTime = playerToAlignTo.currentTime();
        }

        if (this.delayMap[otherPlayer.id()]) {
          otherPlayerVideoTime += this.delayMap[otherPlayer.id()] / 1000;
        }

        const seekToVideoTIme = Math.max(0, Math.min(otherPlayer.duration(), otherPlayerVideoTime));
        this.syncPlayerByVideoTimePlaybackRateAndVolume(otherPlayer, seekToVideoTIme);
      }
    }
  }

  async syncPlayersStartTime() {
    this.allPlayersLoaded = true;
    try {
      const startTimestampArr = Object.keys(this.activePlayersStates)
        .map(playerId => {
          let timestamp = 0;
          if (this.activePlayersStates[playerId].segments) {
            const timestamp = this.activePlayersStates[playerId].segments[0].dateTimeObject.getTime();
          }
          return {
            playerId,
            timestamp
          };
        })
        .sort((a, b) => b.timestamp - a.timestamp); // DESC

      let latestStartTimestamp = null;
      let index = 0;
      const earliestStartTimestamp = startTimestampArr[startTimestampArr.length - 1].timestamp;
      while (!latestStartTimestamp && index < startTimestampArr.length) {
        if (startTimestampArr[index].timestamp - earliestStartTimestamp < MAX_START_TIMESTAMP_GAP) {
          latestStartTimestamp = startTimestampArr[index].timestamp;
          if (this.delayMap[startTimestampArr[index].playerId]) {
            latestStartTimestamp = Math.max(0, latestStartTimestamp - this.delayMap[startTimestampArr[index].playerId]);
          }
        } else {
          this.latePlayers[startTimestampArr[index].playerId] = {
            wakeAtTimestamp: startTimestampArr[index].timestamp
          };
        }
        index++;
      }

      for (let playerIndex = 0; playerIndex < this.activePlayers.length; playerIndex++) {
        const player = this.activePlayers[playerIndex];
        let playerVideoTime = player.currentTime();
        if (player.currentType() !== 'video/mp4') {
          playerVideoTime = timestampToVideoTime({
            segments: this.activePlayersStates[player.id()].segments,
            timestamp: latestStartTimestamp
          });
        }

        if (this.delayMap[player.id()]) {
          playerVideoTime += this.delayMap[player.id()] / 1000;
        }

        if (this.latePlayers[player.id()]) {
          this.activePlayersStates[player.id()].isDormant = true;
          player.pause();
          player.currentTime(0);
        } else {
          await player.currentTime(playerVideoTime);
        }
      }
    } catch (e) {
      console.error('syncPlayersStartTime', { e });
    }
  }

  fetchPlayersSegments() {
    try {
      for (let index = 0; index < this.activePlayers.length; index++) {
        const player = this.activePlayers[index];
        if (player.currentType() && player.currentType() !== 'video/mp4') {
          if (!this.activePlayersStates[player.id()]?.segments) {
            this.activePlayersStates[player.id()].segments = player.tech({ IWillNotUseThisInPlugins: true }).vhs.playlists.media().segments;
          }
        }
      }
    } catch (e) {
      console.error('fetchPlayersSegments', { e });
    }
  }

  syncPlayerByVideoTimePlaybackRateAndVolume(player, playerVideoTime, playbackRate, volume) {
    if (player.duration() >= playerVideoTime) {
      player.currentTime(playerVideoTime);
    } else {
      player.currentTime(player.duration() || 0);
    }

    if (playbackRate) {
      player.playbackRate(playbackRate);
    }

    if (volume !== undefined) {
      player.volume(volume);
    }
  }

  async switchGroup(newGroupId) {
    if (this.groups.hasOwnProperty(newGroupId) && Array.isArray(this.groups[newGroupId]) && this.groups[newGroupId].length > 0) {
      const currentActiveGroup = this.activeGroup;
      Object.keys(this.eventMap).forEach(eventType => {
        // Clear events from old group
        this.groups[currentActiveGroup].forEach(player => {
          player.off(eventType, this.eventMap[eventType]);
        });
      });
      this.activeGroup = newGroupId;
      this.fetchPlayersSegments();

      // Align new group to the old group's timestamp
      const playerFromOldGroup = this.groups[currentActiveGroup][0];
      let alignToTimestamp;
      if (playerFromOldGroup.currentType() !== 'video/mp4') {
        alignToTimestamp = videoTimeToTimestamp({
          segments: this.groupsStates[currentActiveGroup][playerFromOldGroup.id()].segments,
          videoTime: playerFromOldGroup.currentTime()
        });
      }

      this.activePlayers.forEach(player => {
        let playerVideoTime = playerFromOldGroup.currentTime();
        if (player.currentType() !== 'video/mp4' && alignToTimestamp) {
          playerVideoTime = timestampToVideoTime({
            segments: this.activePlayersStates[player.id()].segments,
            timestamp: alignToTimestamp
          });
        }
        this.syncPlayerByVideoTimePlaybackRateAndVolume(player, playerVideoTime, playerFromOldGroup.playbackRate(), playerFromOldGroup.volume());
      });
      const playerFromNewGroup = this.groups[newGroupId][0];
      const isCurrentlyPlaying = !playerFromOldGroup.paused();
      if (isCurrentlyPlaying) {
        playerFromOldGroup.pause();
      }

      if (playerFromNewGroup.paused() && isCurrentlyPlaying) {
        await playerFromNewGroup.play();
      }
      if (!isCurrentlyPlaying && !playerFromNewGroup.paused()) {
        await playerFromNewGroup.pause();
      }

      Object.keys(this.eventMap).forEach(eventType => {
        // Add events to new group
        this.activePlayers.forEach(player => {
          player.on(eventType, this.eventMap[eventType]);
        });
      });
    }
  }
}

export { VideoSyncManager };
