import { defineStore } from 'pinia';
import { useContextStore } from './ContextStore';
import eventListService from '../services/api/eventListService';
import { FETCH_STATE, SORT_MODE } from '../consts/appConsts';
import { EVENT_LIST_TIME_RANGE } from '../consts/eventListConsts';
import { MISSION_STATES_NAMES_TO_STATES, MISSION_STATE_NAME } from '../consts/missionConsts';
import { EVENT_TYPE } from '../consts/eventConsts';
import { flattenObj } from '../utils/ObjectUtils';
import { isDateInTimeRange } from '../utils/DateUtils';
import missionsService from '../services/api/missionsService';
import eventsService from '../services/api/eventsService';
import dayjs from 'dayjs';
import { isActive } from '../utils/models/MissionUtils';

const initialFilters = {
  type: [],
  device: [],
  handled: [0],
  timeRange: {
    type: EVENT_LIST_TIME_RANGE.LAST_7_DAYS,
    from: dayjs().subtract(7, 'days').toDate().getTime(),
    to: dayjs().toDate().getTime()
  },
  location: '',
  missionStates: []
};

export const useEventListStore = defineStore('eventList', {
  state() {
    return {
      eventListFetchState: FETCH_STATE.INITIAL,
      eventListMap: new Map(),
      sortMode: SORT_MODE.DESC,
      listFilters: structuredClone(initialFilters),
      eventsPerPage: 50,
      eventListOffset: 0,
      eventListCount: 0
    };
  },
  getters: {
    wereItemsFetched() {
      return this.eventListFetchState === FETCH_STATE.SUCCESS || this.eventListFetchState === FETCH_STATE.ERROR;
    },
    eventListItems() {
      return Array.from(this.eventListMap.values());
    },
    isListFiltered() {
      JSON.stringify(this.listFilters) != JSON.stringify(initialFilters);
    },
    isListFilteredByLocation() {
      return this.listFilters.location !== '';
    },
    activeMissions() {
      return this.eventListItems.filter(item => item.event_type === EVENT_TYPE.MISSION && isActive(item));
    },
    getItemById() {
      this.doesItemPassCurrentFilters({ item: {} });
      return itemId => this.eventListMap.get(itemId);
    },
    getActiveMissionsByDevice() {
      return deviceId => this.activeMissions.filter(mission => mission.device_id === deviceId);
    },
    activeMissionsInCurrentZone() {
      const zoneId = useContextStore().zoneId;
      return this.activeMissions.filter(mission => mission.zone_id === zoneId);
    }
  },
  actions: {
    doesItemPassCurrentFilters(item) {
      const _item = {
        ...item,
        timestamp: item.timestamp || item.requested_timestamp,
        type: item.event_type || EVENT_TYPE.MISSION,
        grid_cells: item.grid_cells || []
      };

      return Object.values(filtersConditions).every(filterCondition => filterCondition.bind(this)(_item));
    },
    async fetchEventList({ offset, eventsPerPage } = {}) {
      try {
        this.eventListFetchState = FETCH_STATE.LOADING;
        let filters = {
          ...this.listFilters,
          offset: offset || 0,
          eventsPerPage: eventsPerPage || this.eventsPerPage,
          sortOrder: this.sortMode,
          missionStates: (this.listFilters.missionStates || []).map(stateName => MISSION_STATES_NAMES_TO_STATES[stateName])
        };
        filters = flattenObj(filters);
        const data = await eventListService.fetchEventList(filters);
        this.eventListMap.clear();
        this.eventListOffset = data.offset;
        this.eventListCount = data.count;
        this.eventListMap = new Map(data.items.map(item => [item.id, item]));
        this.eventListFetchState = FETCH_STATE.SUCCESS;
        return data;
      } catch (e) {
        this.eventListFetchState = FETCH_STATE.ERROR;
        return Promise.reject(e);
      }
    },
    async fetchNextItem(offset) {
      try {
        let filters = {
          ...this.listFilters,
          offset: offset || 0,
          eventsPerPage: 1,
          sortOrder: this.sortMode
        };
        filters.missionStates = (filters.missionStates || []).map(stateName => MISSION_STATES_NAMES_TO_STATES[stateName]);
        filters = flattenObj(filters);
        const data = await eventListService.fetchEventList(filters);
        this.addItem(data.items[0]);
        this.eventListCount = data.count;
        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    updateFilters(filters) {
      Object.keys(filters).forEach(filterType => (this.listFilters[filterType] = filters[filterType]));
      this.eventListOffset = 0;
    },
    resetFilters() {
      this.listFilters = structuredClone(initialFilters);
      this.eventListOffset = 0;
    },
    updateSortMode(sortMode) {
      this.sortMode = sortMode;
    },
    removeItem(itemId) {
      if (this.eventListMap.delete(itemId)) {
        this.eventListCount--;
      }
    },
    addItem(newItem) {
      const oldItem = this.eventListMap.get(newItem.id);
      this.eventListMap.set(newItem.id, { ...oldItem, ...newItem });
      if (!oldItem) {
        this.eventListCount++;
      }
      const contextStore = useContextStore();
      // Update context store if the item is in the context store
      if (contextStore.mission?.id === newItem.id) {
        contextStore.patchContextMission(newItem);
      } else if (contextStore.event?.id === newItem.id) {
        contextStore.patchContextEvent(newItem);
      }
    },
    updateItem(itemId, props) {
      const item = this.getItemById(itemId);
      const contextStore = useContextStore();

      Object.entries(props).forEach(([key, val]) => {
        item[key] = val;
      });

      if (contextStore.mission?.id === itemId) {
        contextStore.patchContextMission(props);
      }

      if (contextStore.event?.id === itemId) {
        contextStore.patchContextEvent(props);
      }
    },
    addEventToMission(mission_id, event) {
      const mission = this.getItemById(mission_id);
      const contextStore = useContextStore();

      if (mission) {
        contextStore.addEventToContextMission(event);
        mission.events = mission.events || [];
        const existingEventIndex = mission.events.findIndex(savedEvent => savedEvent.id === event.id);
        if (existingEventIndex > -1) {
          mission.events[existingEventIndex] = event;
        } else {
          mission.events.push(event);
        }
      }
    },
    addMissionPathPoint(missionId, point) {
      const existingMission = this.eventListMap.get(missionId);
      if (existingMission) {
        existingMission.path = existingMission.path || [];
        existingMission.path.push(point);
        existingMission.path.sort((a, b) => a.timestamp - b.timestamp);
      }
    },
    async fetchMissionObjectDetectionById(missionId) {
      try {
        const objectDetectionConfig = await eventListService.fetchMissionObjectDetectionById(missionId);

        // Save result in store if the mission is stored there
        (this.eventListMap.get(missionId) || {}).objectDetectionConfig = objectDetectionConfig;
        return objectDetectionConfig;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async invokeMission(missionParams) {
      try {
        if (missionParams.device_id === '-1') {
          delete missionParams.device_id;
        }
        const mission = await missionsService.invokeMission(missionParams);
        this.addItem(mission);
        return mission;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async updateMissionHandled({ id, handled, skipFilter = false }) {
      try {
        const mission = await missionsService.patchMission({ id, handled });
        if (!this.doesItemPassCurrentFilters(mission) && !skipFilter) {
          this.removeItem(mission.id);
        } else {
          this.addItem(mission);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async stopMission(missionId) {
      try {
        const mission = await missionsService.patchMission({
          id: missionId,
          state: 'STOPPED',
          end_timestamp: new Date().getTime()
        });
        const existingMission = this.eventListMap.get(missionId);
        const updatedMission = { ...existingMission, ...mission };
        if (!this.doesItemPassCurrentFilters(updatedMission)) {
          this.removeItem(mission.id);
        } else {
          this.addItem(mission);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async createEvent(eventParams) {
      try {
        const event = await eventsService.createEvent(eventParams);
        const contextStore = useContextStore();

        if (this.doesItemPassCurrentFilters(event)) {
          this.addItem(event);
        }

        if (event.mission_id) {
          const mission = this.eventListMap.get(event.mission_id);

          if (mission) {
            mission.events = mission.events || [];
            mission.events.push(event);
          }
          contextStore.addEventToContextMission(event);
        }

        return event;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getOrFetchEventById({ id, fetchStreams }) {
      try {
        const include = { stream: false };
        let event = this.getItemById(id);

        if (!event?.streamsData || event.streamsData?.data?.ttl - Date.now() <= 0) {
          include.stream = fetchStreams !== false;
          event = await eventsService.fetchEventById(id, include);

          if (this.doesItemPassCurrentFilters(event)) {
            this.addItem(event);
          }
        }

        return event;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getOrFetchMissionById({ id, fetchStreams }, isContextMission) {
      try {
        const include = { stream: false };
        const existingMission = this.getItemById(id);
        if (existingMission && isContextMission) {
          useContextStore().setContextMission(existingMission);
        }
        let mission;

        if (!existingMission?.streamsData || fetchStreams || existingMission.streamsData?.data?.ttl - Date.now() <= 0) {
          include.stream = fetchStreams !== false;
          const fetchedMission = await missionsService.fetchMissionById({ id, include });

          // Merged fetched data with existing (as some updates are not fetched - predicted_path for example)
          mission = { ...existingMission, ...fetchedMission };
          if (existingMission) {
            this.updateItem(id, mission);
          }
          if (isContextMission) {
            useContextStore().setContextMission(mission);
          }
        } else {
          mission = existingMission;
        }

        return mission;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async updateEventHandled({ id, handled, skipFilter = false }) {
      try {
        const event = await eventsService.patchEvent({ id, handled });
        if (!this.doesItemPassCurrentFilters(event) && !skipFilter) {
          this.removeItem(event.id);
        } else {
          this.addItem(event);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async updateEventTechnicianMode({ id, technician_mode }) {
      try {
        const data = await eventsService.patchEvent({ id, technician_mode });
        const existingEvent = this.eventListMap.get(id);
        const updatedEvent = { ...existingEvent, technician_mode: data.technician_mode };
        this.addItem(updatedEvent);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchEventStreams(eventId) {
      try {
        const existingEvent = this.eventListMap.get(eventId);
        if (!existingEvent.streamsData || existingEvent.streamsData.ttl - Date.now() <= 0) {
          const streams = await eventsService.fetchEventStreams(eventId);
          this.existingEvent.streamsData = streams;
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async exportEventList() {
      try {
        let filters = {
          ...this.listFilters,
          sortOrder: this.sortMode,
          missionStates: (this.listFilters.missionStates || []).flatMap(stateName => MISSION_STATES_NAMES_TO_STATES[stateName])
        };

        const fieldsToIgnore = ['type', 'missionStates', 'device']; // send as array instead of comma seperated
        filters = flattenObj(filters, undefined, {}, fieldsToIgnore);

        const data = await eventListService.ExportToCsv(filters);

        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    }
  }
});

const filtersConditions = {
  itemInContextZone: function (item) {
    return useContextStore().zoneId === item?.zone_id;
  },
  itemTypeInFilter: function (item) {
    return !this.listFilters?.type?.length || this.listFilters?.type?.includes(item.type);
  },
  itemDeviceInFilter: function (item) {
    return !this.listFilters?.device?.length || this.listFilters?.device?.includes(item.device);
  },
  itemHandledInFilter: function (item) {
    return !this.listFilters?.handled?.length || this.listFilters?.handled?.includes(Number(item.handled));
  },
  itemLocationInFilter: function (item) {
    if (this.isListFilteredByLocation) {
      const [filterX, filterY] = this.listFilters?.location?.split('_');
      const filterGridCellX = Math.round(Number(filterX) * 2) / 2;
      const filterGridCellY = Math.round(Number(filterY) * 2) / 2;
      const filterGridCell = `${filterGridCellX}_${filterGridCellY}`;
      return item.grid_cells.includes(filterGridCell);
    } else {
      return true;
    }
  },
  itemInTimeRangeFilter: function (item) {
    return isDateInTimeRange(item.timestamp, this.listFilters.timeRange.type, this.listFilters.timeRange);
  },
  itemMissionStateFilter: function (item) {
    const missionState = item?.state;
    const isFilterActive = this.listFilters?.missionStates?.length;
    const isMissionStateInFilter = this.listFilters?.missionStates?.includes(MISSION_STATE_NAME[missionState]);
    return !missionState || !isFilterActive || isMissionStateInFilter;
  }
};
