import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 24",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", {
      d: "M717 799c-.552 0-1-.448-1-1v-8c0-.552.448-1 1-1s1 .448 1 1v8c0 .552-.448 1-1 1zm.793 8.76c2.16-.868 9.207-4.28 9.207-11.717v-5.17c.002-2.156-1.38-4.07-3.426-4.746l-6.259-2.075c-.204-.07-.426-.07-.63 0l-6.259 2.075c-2.047.676-3.428 2.59-3.426 4.746v5.17c0 6.562 7.005 10.576 9.153 11.65.272.13.555.232.847.307.271-.055.537-.136.793-.24zm5.151-19.775c1.228.41 2.056 1.566 2.056 2.87v5.21c0 6.23-6.087 9.18-7.953 9.935-1.888-.951-8.047-4.479-8.047-9.936v-5.21c0-1.303.828-2.46 2.056-2.87L717 786l5.944 1.985zM717 801c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z",
      transform: "translate(-707 -784)"
    }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }