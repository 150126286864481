import { defineStore } from 'pinia';
import { useContextStore } from './ContextStore';
import { useMapStore } from './MapStore';
import areasService from '../services/api/areasService';
import { getRandomColor } from '../utils/ColorUtils';

export const useAreasStore = defineStore('areas', {
  state() {
    return {
      areasMap: new Map()
    };
  },
  getters: {
    areas() {
      return Array.from(this.areasMap.values());
    },
    activeNoFlightAreas() {
      const areas = this.areas
        .filter(area => area.type === 'NO_FLIGHTS' && area.enabled)
        .map(area => ({
          ...area,
          isInBackground: true
        }));
      return areas;
    }
  },
  actions: {
    async fetchAreas() {
      const contextStore = useContextStore();
      try {
        this.$reset();
        const _zoneId = contextStore.zoneId;
        const areasArr = await areasService.fetchAreas(_zoneId);

        // Make sure that the context zone was not switched by now
        if (_zoneId === contextStore.zoneId) {
          const existingAreasCount = this.areasMap.size;

          areasArr.forEach((area, index) => {
            this.areasMap.set(area.id, {
              ...area,
              color: area.color || getRandomColor(existingAreasCount + index)
            });
          });
        }
        return areasArr;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async createArea(area) {
      try {
        const mapStore = useMapStore();
        const data = await areasService.createArea(area);
        this.areasMap.set(data.id, { ...data, color: mapStore.areaColor });
        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async updateArea(area) {
      try {
        const data = await areasService.updateArea(area);
        this.areasMap.set(area.id, area);
        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async deleteArea(area) {
      try {
        const data = await areasService.deleteArea(area);
        this.areasMap.delete(area.id);
        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    }
  }
});
