<template>
  <div v-if="missionTemplateDraft.length" class="pois-detection-setting">
    <div class="poi-selection-menu">
      <template v-for="(poi, index) in missionTemplateDraft" :key="`poi-config-${index}`">
        <span
          v-if="!poi.isTile"
          :class="['poi-icon', { selected: index === selectedPoiIndex, 'config-badge': poiDetectionSettings[index] }]"
          :data-badge="index + 1"
          tabindex="0"
          @click="updateSelectedPoiIndex(index)"
          @keydown.space="updateSelectedPoiIndex(index)"
        >
          <component :is="getPoiIcon(poi.poiType)" class="icon" />
        </span>
      </template>
    </div>
    <BaseButton v-if="showAddDetectionSettingsButton" @click="addDetectionSettings">Add detection setting</BaseButton>
    <div class="detection-settings-list">
      <template
        v-for="(detectionSetting, index) in poiDetectionSettings[selectedPoiIndex]"
        :key="`ds-${selectedPoiIndex}-${elementIdentifiers?.[index]}`"
      >
        <DetectionSettingForm
          v-if="!poiDetectionSettings[selectedPoiIndex][index]?.technician_mode || isTechnician"
          v-model="poiDetectionSettings[selectedPoiIndex][index]"
          @update:model-value="onDetectionUpdate"
          @remove="removeDetectionSettings(index)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../components/base/BaseButton.vue';
import { mapState, mapActions } from 'pinia';
import { useMapStore } from '../../store/MapStore';
import DetectionSettingForm from '../../components/DetectionSettingForm.vue';
import { POI_OPTIONS_ICONS } from '../../consts/mapConsts';
import { clone } from '../../utils/ObjectUtils';
import { v1 as uuidv1 } from 'uuid';
import { useContextStore } from '../../store/ContextStore';

export default {
  name: 'PoisDetectionSettings',
  components: {
    BaseButton,
    DetectionSettingForm
  },
  props: {
    modelValue: {
      type: [null, Object],
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      /**
       * @typedef {Object} poiDetectionSettings
       * @property [key: keyof missionTemplatePois-Indexes]: Array<Object> - an array of detection settings objects for the key-matching poi.
       */
      poiDetectionSettings: {},
      /**
       * @typedef {Array<uuid>} elementIdentifiers
       * This is to support ":key=" binding, which cannot be index based, for detection settings objects (that has no unique identifiers)
       */
      elementIdentifiers: []
    };
  },
  computed: {
    ...mapState(useMapStore, ['missionTemplateDraft', 'selectedPoiIndex', 'eventBus']),
    ...mapState(useContextStore, ['zone', 'isTechnician']),
    showAddDetectionSettingsButton() {
      return this.isTechnician || this.zone?.enabled_gen_ai_prompts?.length > 0;
    }
  },
  watch: {
    selectedPoiIndex: {
      handler: function onSelectedPoiIndexChanged(newVal) {
        this.elementIdentifiers = this.modelValue?.[newVal]?.map?.(() => uuidv1()) || [];
      },
      immediate: true
    }
  },
  created() {
    if (this.modelValue) {
      this.poiDetectionSettings = clone(this.modelValue);
    }
    this.eventBus.addEventListener('MISSION_DRAFT:INDEX_REMOVED', this.onDraftPoiRemoved);
  },
  beforeUnmount() {
    this.eventBus.removeEventListener('MISSION_DRAFT:INDEX_REMOVED', this.onDraftPoiRemoved);
  },
  methods: {
    getPoiIcon(poiType) {
      return POI_OPTIONS_ICONS[poiType];
    },
    ...mapActions(useMapStore, ['updateSelectedPoiIndex']),
    addDetectionSettings() {
      this.poiDetectionSettings[this.selectedPoiIndex] = this.poiDetectionSettings[this.selectedPoiIndex] || [];
      this.poiDetectionSettings[this.selectedPoiIndex].push(null); // This will trigger initiation of new DetectionSettingForm which will automatically initialize with default values
      this.elementIdentifiers.push(uuidv1());
    },
    removeDetectionSettings(index) {
      this.poiDetectionSettings[this.selectedPoiIndex].splice(index, 1);
      this.elementIdentifiers.splice(index, 1);

      if (!this.poiDetectionSettings[this.selectedPoiIndex].length) {
        delete this.poiDetectionSettings[this.selectedPoiIndex];
      }
      this.$emit('update:modelValue', this.poiDetectionSettings);
    },
    onDetectionUpdate() {
      this.$emit('update:modelValue', this.poiDetectionSettings);
    },
    onDraftPoiRemoved(e) {
      const index = e.detail;
      delete this.poiDetectionSettings[index];
      const shiftedDetections = {};
      Object.entries(this.poiDetectionSettings).forEach(([key, val]) => {
        if (key > index) {
          shiftedDetections[key - 1] = val;
        } else {
          shiftedDetections[key] = val;
        }
      });
      this.poiDetectionSettings = shiftedDetections;
    }
  }
};
</script>

<style scoped lang="scss">
.pois-detection-setting {
  background: var(--primaryColorShade1);
  border-radius: 8px;
  padding: 0.5rem;
  display: grid;
  row-gap: 1rem;

  .poi-selection-menu {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    row-gap: 0.3rem;

    .poi-icon {
      position: relative;
      flex-shrink: 0;
      border-radius: 8px;
      border: 4px solid transparent;
      transition: background 0.25s ease-out, transform 0.2s cubic-bezier(0.36, 1.47, 0.59, 1.63);
      cursor: pointer;
      background: transparent;
      padding: 0;

      &[data-badge]:after {
        content: attr(data-badge);
        position: absolute;
        background: white;
        border: 2px solid black;
        color: black;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        font-size: 0.75rem;
        font-weight: bold;
        display: grid;
        place-content: center;
        bottom: 0;
        left: 0;
        line-height: 0.75rem;
      }

      .icon {
        cursor: inherit;
        width: 2.5rem;
        height: 2.5rem;
      }

      &.selected {
        background: var(--highlightColor);
        pointer-events: none;
        cursor: default;
      }

      &:hover:not(.selected) {
        background: var(--overlayShade2);
        transform: scale(1.05, 1.05);
      }

      &.config-badge:before {
        content: '';
        width: 0.75rem;
        height: 0.75rem;
        background: var(--infoColor);
        border-radius: 50%;
        position: absolute;
        border: 2px solid var(--primaryColorShade1);
        transform: translate(-20%, -20%);
        top: 0;
        left: 0;
      }
    }
  }

  .detection-settings-list {
    display: grid;
    row-gap: 1rem;
  }
}
</style>
