import { io } from 'socket.io-client';
import LocalStorageManager from './LocalStorageManager';
import { useZonesStore } from '../store/ZonesStore';
import { useContextStore } from '../store/ContextStore';
import { useEventListStore } from '../store/EventListStore';
import { useDashboardStore } from '../store/DashboardStore';
import { useLocationListStore } from '../store/LocationListStore';
import { useDevicesStore } from '../store/DevicesStore';
import { SNACKBAR_TYPE } from '../consts/appConsts';
import { DEVICES_TYPES } from '../consts/deviceConsts';

const LIVE_LOCATION_DELAY_MS = 2650;
let socket;

export const initSocket = () => {
  const serverEndpoint = import.meta.env.VITE_DEFAULT_SERVER;
  const socket = io(`//${serverEndpoint}`, {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    auth: {
      token: LocalStorageManager.getItem('AccessToken')
    }
  });

  socket.on('disconnect', reason => {
    console.debug(`Socket.io is disconnected due to: ${reason}`);
  });

  socket.on('connect', () => {
    console.debug('Socket.io is connected');
  });

  socket.on('error', err => {
    console.error('Socket error:', err);
  });

  Object.entries(socketHandlers).forEach(([messageType, messageHandler]) => {
    socket.on(messageType, messageHandler);
  });
};

export const closeSocket = () => {
  if (socket) {
    socket.disconnect();
  }
};

const socketHandlers = {
  ZONE_SCHEDULES_UPDATE(response) {
    useZonesStore().setZoneSchedules(response.zone_schedules);
  },
  NEXT_SCHEDULE_UPDATE(response) {
    useZonesStore().setNextZoneSchedule(response);
  },
  async MISSION_UPDATE(response) {
    const mission = response.mission;
    const contextStore = useContextStore();

    if (!contextStore.isTechnician && mission.technician_mode) {
      return;
    }

    if (contextStore.mission?.id === mission.id) {
      contextStore.patchContextMission(mission);
    }

    const eventListStore = useEventListStore();
    const dashboardStore = useDashboardStore();
    dashboardStore.addDashboardEventByZoneId(mission.zone_id, {
      id: mission.id,
      state: mission.state,
      name: mission.name,
      requested_timestamp: mission.requested_timestamp,
      event_type: mission.event_type
    });

    const existingEventListItem = eventListStore.getItemById(mission.id);

    if (eventListStore.doesItemPassCurrentFilters(mission)) {
      eventListStore.addItem(mission);
    } else if (existingEventListItem) {
      eventListStore.removeItem(mission.id);
    }

    const locationListStore = useLocationListStore();
    let existingLocationItem = locationListStore.getItemById(mission.id);

    if (locationListStore.doesItemPassCurrentFilters(mission)) {
      locationListStore.addItem(mission);
    } else if (existingLocationItem) {
      locationListStore.removeItem(mission.id);
    }
  },
  MISSION_PATH_PREDICTION(response) {
    const { predicted_path, mission_id } = response;
    const eventListStore = useEventListStore();
    const mission = eventListStore.getItemById(mission_id);

    if (mission) {
      eventListStore.updateItem(mission_id, { predicted_path });
    }
  },
  EVENT_UPDATE(response) {
    const event = response.event;
    const contextStore = useContextStore();

    if (event.technician_mode && !contextStore.isTechnician) {
      return;
    }

    if (contextStore.event?.id === event.id) {
      contextStore.patchContextEvent(event);
    }
    const eventListStore = useEventListStore();
    const dashboardStore = useDashboardStore();
    dashboardStore.addDashboardEventByZoneId(event.zone_id, {
      id: event.id,
      event_type: event.event_type,
      timestamp: event.timestamp
    });

    eventListStore.addItem(event);

    if (event.mission_id) {
      eventListStore.addEventToMission(event.mission_id, event);
    }

    const locationListStore = useLocationListStore();

    if (locationListStore.doesItemPassCurrentFilters(event)) {
      locationListStore.addItem(event);
    } else {
      locationListStore.removeItem(event.id);
    }

    if (eventListStore.doesItemPassCurrentFilters(event)) {
      eventListStore.addItem(event);
    } else {
      eventListStore.removeItem(event.id);
    }
  },
  DEVICE_UPDATE(response) {
    const deviceUpdate = response.device;
    const contextStore = useContextStore();
    // Iif user is not technician and device is in technician mode - ignore update
    if (!contextStore.isTechnician && deviceUpdate.technician_mode) {
      return;
    }
    const devicesStore = useDevicesStore();
    const existingDevice = devicesStore.getDeviceById(response.id);

    // Merge update with existing data (for partial update sockets)
    const device = { ...existingDevice, ...deviceUpdate };

    devicesStore.setDevice(device);

    if (device.activeNodeList) {
      device.activeNodeList = device.activeNodeList.map(s => s.slice(1)); // remove '/' prefix from node names
      device.activeNodeList.sort();
    }

    if (device.resetTileStatus && contextStore.isTechnician) {
      if (device.resetTileStatus.success) {
        contextStore.showSnackbar({
          message: `Reset tile service for tile with physical id: ${device.resetTileStatus.tileToResetPhysicalId} triggered successfully`,
          type: SNACKBAR_TYPE.SUCCESS
        });
      } else {
        contextStore.showSnackbar({
          message: `ROS failed to reset tile with physical id: ${device.resetTileStatus.tileToResetPhysicalId}\nDue to: ${device.resetTileStatus.message}`,
          type: SNACKBAR_TYPE.ERROR
        });
      }
    }

    // If device update contains location, check there is an active mission for the device
    // and if so - add location to path (if was not added before)
    if (deviceUpdate?.location) {
      const eventListStore = useEventListStore();
      const activeMissionsByDevice = eventListStore.getActiveMissionsByDevice(device.id);

      if (
        activeMissionsByDevice.length &&
        (device.type_id === DEVICES_TYPES.SKYDIO || JSON.stringify(device.location) !== JSON.stringify(existingDevice?.location))
      ) {
        activeMissionsByDevice.forEach(activeMission => {
          eventListStore.addMissionPathPoint(activeMission.id, {
            data: device.location,
            timestamp: response.timestamp + LIVE_LOCATION_DELAY_MS
          });
        });
      }
    }
  },
  ZONE_UPDATE(response) {
    useZonesStore().setZone(response.zone);
  }
};
