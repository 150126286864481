import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", null, [
      _createElementVNode("circle", {
        cx: "12",
        cy: "12",
        r: "12",
        fill: "#75787D"
      }),
      _createElementVNode("path", {
        fill: "#FFF",
        d: "M7.519.012c3.588 0 6.506 2.918 6.506 6.507 0 2.869-3.984 7.178-5.53 8.742l-.29.292c-.167.164-.274.267-.304.295-.107.1-.245.152-.382.152-.138 0-.276-.052-.383-.152-.25-.238-6.124-5.846-6.124-9.33C1.012 2.93 3.93.013 7.519.013zm0 1.115c-2.975 0-5.392 2.417-5.392 5.392 0 2.32 3.592 6.358 5.392 8.146 1.8-1.788 5.39-5.83 5.39-8.146 0-2.975-2.416-5.392-5.39-5.392zm0 2.603c1.539 0 2.788 1.25 2.788 2.789 0 1.539-1.25 2.788-2.788 2.788-1.54 0-2.79-1.25-2.79-2.788 0-1.54 1.25-2.79 2.79-2.79zm0 1.115c-.923 0-1.674.751-1.674 1.674 0 .922.751 1.673 1.674 1.673.922 0 1.673-.751 1.673-1.673 0-.923-.751-1.674-1.673-1.674z",
        transform: "translate(4 4)"
      })
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }