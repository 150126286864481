import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", { transform: "translate(-531 -2194) translate(40 1620) translate(491 574)" }, [
      _createElementVNode("circle", {
        cx: "12",
        cy: "12",
        r: "12",
        fill: "#5B2F0E"
      }),
      _createElementVNode("g", {
        fill: "#FFF",
        "fill-rule": "nonzero"
      }, [
        _createElementVNode("path", {
          d: "M11.686 13.425V15H4v-1.575h7.686zm-2.412-3.239c.26.919.802 1.742 1.573 2.356l.078.062H4.761l.078-.062c.771-.614 1.313-1.437 1.573-2.356zM9.448 1c.908 0 1.648.733 1.648 1.633 0 .202-.037.4-.11.588L9.413 7.257c-.209.535-.314 1.097-.314 1.67 0 .148.007.294.02.44H6.567c.013-.146.02-.292.02-.44 0-.573-.105-1.135-.313-1.67L4.7 3.22c-.073-.188-.11-.386-.11-.588C4.59 1.733 5.33 1 6.238 1z",
          transform: "translate(4 4)"
        })
      ])
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }