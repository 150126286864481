<template>
  <BaseBoundaryEnforcer :position="position" class="map-popup-list">
    <IconClose class="close-btn" @click.stop="$emit('close')"><title>Close</title></IconClose>
    <div class="list">
      <div class="investigate-now-btn" @click="callAction(indicatorMapPosition, MAP_QUICK_ACTION_TYPES.INVESTIGATE)">
        <IconDrone class="drone-icon" />
        investigate now
      </div>
      <div v-for="listItem in list" :key="listItem.id" class="map-popup-list-item" @click.stop="() => toggleThumbnailCard(listItem)">
        <span class="title">{{ listItem.text }}</span>
        <span v-if="listItem.time" class="date">{{ getDate(listItem.time) }}</span>
        <div
          :class="[
            'item-icon-container',
            {
              'warning-mark': listItem.warningMark,
              'charge-mark': listItem.chargeMark && !listItem.warningMark,
              disconnected: listItem.isDisabled
            }
          ]"
          :data-badge="listItem.warningMark ? '!' : '⚡︎'"
        >
          <component :is="toRaw(listItem.icon)" class="item-icon" />
        </div>
        <MapThumbnailCard v-if="thumbnailToggleMap[listItem.id]" class="thumbnail-card" :item="listItem.cardItem" :show-time="false" />
      </div>
    </div>
  </BaseBoundaryEnforcer>
</template>

<script>
import BaseBoundaryEnforcer from '../../components/base/BaseBoundaryEnforcer.vue';
import IconClose from '../../components/icons/IconClose.svg?component';
import { mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import { PRIVILEGES } from '../../consts/authConsts';
import { getDate, get24Time } from '../../utils/DateUtils';
import MapQuickAction from '../../components/functional/MapQuickAction.js';
import IconDrone from '../../components/icons/IconDrone.svg?component';
import { toRaw } from 'vue';
import { MAP_QUICK_ACTION_TYPES } from '../../consts/mapConsts';
import MapThumbnailCard from './MapThumbnailCard.vue';

export default {
  name: 'MapPopupList',
  components: {
    BaseBoundaryEnforcer,
    IconClose,
    IconDrone,
    MapThumbnailCard
  },
  extends: MapQuickAction,
  props: {
    position: {
      type: Object,
      required: true
    },
    indicatorMapPosition: {
      type: Object,
      required: true
    },
    list: {
      type: Array,
      required: true
    }
  },
  emits: ['close'],
  data() {
    return {
      MAP_QUICK_ACTION_TYPES,
      thumbnailToggleMap: {}
    };
  },
  computed: {
    ...mapStores(useContextStore),
    canInvokeCustomMission() {
      return this.contextStore.hasPrivilege(PRIVILEGES.INVOKE_CUSTOM_MISSIONS);
    }
  },
  methods: {
    toRaw,
    getDate,
    get24Time,
    toggleThumbnailCard(item) {
      this.thumbnailToggleMap[item.id] = !this.thumbnailToggleMap[item.id];
    }
  }
};
</script>

<style scoped lang="scss">
.map-popup-list {
  display: flex;
  user-select: none;

  .close-btn {
    flex-shrink: 0;
    margin: 0 5px 0 0;
    padding: 8px;
    background: var(--mainBackground);
    color: var(--highlightColor);
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    transition: 0.2s all;
    cursor: pointer;
    border: 1px solid var(--overlayShade2);
    box-shadow: 0px 0px 7px var(--primaryColorDarkShade1);

    &:hover {
      filter: brightness(1.5);
    }
  }

  .list {
    max-height: 23rem;
    overflow-y: auto;
    overflow-x: hidden;
    background: var(--primaryColorDarkShade1);
    color: var(--textColor);
    font-size: 1.2rem;
    padding: 3px;
    display: grid;
    row-gap: 3px;

    .investigate-now-btn {
      border-radius: 3px;
      padding: 12px 1rem;
      border: solid 2px var(--secondaryTextColor);
      background-color: var(--mainBackground);
      font-family: var(--font-family-secondary);
      font-size: 1.2rem;
      color: var(--secondaryTextColor);
      text-transform: capitalize;
      font-weight: lighter;
      cursor: pointer;
      display: flex;
      column-gap: 8px;
      transition: background-color 0.4s cubic-bezier(0.49, 1.29, 0.79, 1.11);

      &:hover {
        background-color: var(--primaryColorBrightShade2);
      }

      .drone-icon {
        width: 1.5rem;
        height: 1.5rem;
        color: var(--highlightColor);
      }
    }

    .map-popup-list-item {
      display: grid;
      padding: 2px 5px;
      grid-template-areas:
        'title title icon'
        'date date icon';
      justify-content: space-between;
      transition: all 0.2s ease-in-out;
      background: var(--primaryColor);
      font-family: var(--font-family-secondary);
      align-items: center;
      column-gap: 10px;

      .thumbnail-card {
        grid-column: 1 / 4;
        padding: 0.5rem 0;
      }

      &.lean {
        grid-template-areas: 'title title icon';
      }

      &:not(.disabled) {
        cursor: pointer;

        &:hover {
          filter: brightness(1.2);
        }
      }

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      .title {
        grid-area: title;
      }

      .date {
        grid-area: date;
        font-size: 14px;
        opacity: 0.5;
      }

      .item-icon-container {
        grid-area: icon;
        display: grid;
        justify-content: end;

        .item-icon {
          width: 2.2rem;
          height: 2.2rem;
          padding: 2px;
        }

        &.warning-mark,
        &.charge-mark {
          position: relative;
          padding: 0px;
        }

        &.charge-mark[data-badge]:after {
          content: attr(data-badge);
          position: absolute;
          top: 2px;
          left: -5px;
          font-size: 17px;
          font-weight: normal;
          background: var(--successColor);
          color: var(--textColor);
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          border-radius: 50%;
          box-shadow: 0 0 1px var(--shadowColor);
        }

        &.warning-mark[data-badge]:after {
          content: attr(data-badge);
          position: absolute;
          top: 3px;
          left: -5px;
          font-size: 15px;
          font-weight: bold;
          color: white;
          width: 4px;
          height: 0px;
          line-height: 19px;
          border-radius: 5px;
          border-bottom: 19px solid var(--errorColor);
          border-left: 9px solid transparent;
          border-right: 10px solid transparent;
        }

        .disconnected {
          opacity: 0.2;
        }
      }
    }
  }
}
</style>
