import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 65 65",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", null, [
      _createElementVNode("path", {
        d: "M 45.095374,0 18.632436,0.11277853 0,18.904626 0.11277853,45.367563 18.904626,64 45.367564,63.887223 64,45.095375 63.887222,18.632437 Z M 26.18496,10.879987 h 11.629749 v 2.210591 H 26.18496 Z m 0,3.68465 h 11.629749 v 6.632271 H 26.18496 Z m 0,8.105998 h 11.629749 v 13.315971 l 11.145232,-0.0098 -16.919427,18.423086 -17.000455,-18.393653 11.144901,-0.0098 z",
        style: {"stroke-width":"0.132595"}
      })
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }