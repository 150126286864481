import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M7 8.5 25 8.5 25 12 23 12 23 10.5 17 10.5 17 24.5 18.5 24.5 18.5 26.5 13.5 26.5 13.5 24.5 15 24.5 15 10.5 9 10.5 9 12 7 12M.5 16A.5.5 90 0031.5 16 .5.5 90 00.5 16" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }