<template>
  <div class="edit-integration-opsgenie">
    <div class="property-container">
      <div class="label-container">Opsgenie API Key:</div>
      <div class="value-container">
        <BaseTextInput v-model="integrationData.data.api_key"></BaseTextInput>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTextInput from '../../components/base/BaseTextInput.vue';
import { toRaw } from 'vue';

export default {
  name: 'EditIntegrationOpsGenie',
  components: { BaseTextInput },
  props: {
    integration: {
      type: Object,
      required: true
    }
  },
  emits: ['update'],
  data() {
    return {
      integrationData: {}
    };
  },
  computed: {
    isSaveEnabled() {
      return this.integrationData.data.api_key.trim() !== '';
    }
  },
  created() {
    this.integrationData = structuredClone(toRaw(this.integration));
    if (!this.integrationData.data.api_key) {
      this.integrationData.data.api_key = '';
    }
  },
  mounted() {
    this.$emit('update');
  },
  updated() {
    this.$emit('update');
  },
  methods: {
    getDiffAttributes() {
      const origin = this.integration.data;
      const diff = {};
      Object.keys(this.integrationData.data).forEach(key => {
        if (JSON.stringify(this.integrationData.data[key]) !== JSON.stringify(origin[key])) {
          diff[key] = this.integrationData.data[key];
        }
      });
      return diff;
    }
  }
};
</script>

<style scoped lang="scss">
.edit-integration-opsgenie {
  .property-container {
    margin-top: 20px;
    .label-container {
      color: var(--secondaryTextColor);
      margin-bottom: 5px;
    }
    .value-container {
      &.text-value {
        color: var(--secondaryTextColor);
      }
    }
  }
}
</style>
