import apiRequest from './apiRequest';

class ZonesService {
  async fetchZones(params) {
    try {
      if (params?.attrs?.length) {
        params.attrs = params.attrs.join(',');
      }
      const response = await apiRequest({
        path: `/account/{accountId}/zones`,
        method: 'GET',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchZoneById(zoneId, params) {
    try {
      if (params?.attrs?.length) {
        params.attrs = params.attrs.join(',');
      }
      const response = await apiRequest({
        path: `/account/{accountId}/zones/${zoneId}`,
        method: 'GET',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async createZone(zone) {
    try {
      const formData = new FormData();

      Object.keys(zone).forEach(key => {
        formData.append(key, zone[key]);
      });

      const response = await apiRequest({
        path: `/account/{accountId}/zones`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async updateZone(zoneChangedFields, zoneId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zones/${zoneId}`,
        method: 'PATCH',
        data: zoneChangedFields
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchZoneSchedules({ deviceId, zoneId, state }) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zone/${zoneId || '{zoneId}'}/zone-schedules`,
        method: 'GET',
        params: { device_id: deviceId, state: state || 'ACTIVE,PENDING' }
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchZoneNextSchedules(amount) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/next-zone-schedules/${amount}`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async saveZoneSchedule(zoneSchedule) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zone/{zoneId}/zone-schedules`,
        method: 'POST',
        data: zoneSchedule
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new ZonesService();
