<template>
  <div class="events-log-filter-popup">
    <div class="icon-close">
      <IconClose @click="onCloseClick"><title>Close</title></IconClose>
    </div>
    <div class="title">Filter Events:</div>
    <div class="filters-container">
      <div class="property-container">
        <span class="label">Type:</span>
        <BaseDropdown
          v-model="filters.type"
          placeholder="All types"
          :clearable="true"
          :options="typeOptions"
          :multiple="true"
          @click.stop=""
        ></BaseDropdown>
      </div>
      <div v-if="filters.type && filters.type.includes('MISSION')" class="property-container">
        <span class="label">Mission state:</span>
        <BaseDropdown
          v-model="filters.missionStates"
          placeholder="All states"
          :clearable="true"
          :options="missionStatesOptions"
          :multiple="true"
          @click.stop=""
        ></BaseDropdown>
      </div>
      <div class="property-container">
        <span class="label">Device:</span>
        <BaseDropdown
          v-model="filters.device"
          placeholder="All devices"
          :clearable="true"
          :options="deviceOptions"
          :multiple="true"
          @click.stop=""
        ></BaseDropdown>
      </div>
      <div class="property-container">
        <span class="label">Location:</span>
        <div class="location-value-container">
          <template v-if="isListFilteredByLocation">
            <span>Filtered by a location</span>
            <span class="filter-by-location-icon" title="Remove">
              <IconClose class="icon" @click.stop="onRemoveLocationFilterClick" />
            </span>
          </template>
          <template v-else>
            <span>No specific location</span>
            <span v-if="originFilters.location" class="filter-by-location-icon" title="Revert">
              <IconUndo class="icon" @click.stop="onRevertRemovingLocationFilterClick"><title>Undo</title></IconUndo>
            </span>
          </template>
        </div>
      </div>
      <div class="property-container">
        <span class="label">Show resolved:</span>
        <BaseToggle v-model="showResolved" class="show-resolved-options" />
      </div>
      <div class="property-container">
        <span class="label time-label">Time:</span>
        <div class="time-radios">
          <BaseRadioGroup v-model="selectedTimeOptionId" :options="timeOptions" @input="onFilterTimeRangeSelect(selectedTimeOptionId)" />
          <BaseDatePicker v-show="isTimeOptionChecked" v-model.range="datePickerValues" :max-date="datePickerMaxDate" is-range />
        </div>
      </div>
    </div>
    <BaseCancelButton @click="onResetClick">Reset filters</BaseCancelButton>
    <BaseButton class="filter-button" @click="onFilterClick">Filter</BaseButton>
  </div>
</template>

<script>
import IconClose from '../../components/icons/IconClose.svg?component';
import IconUndo from '../../components/icons/IconUndo.svg?component';
import { clone } from '../../utils/ObjectUtils';
import BaseButton from '../../components/base/BaseButton.vue';
import BaseCancelButton from '../../components/base/BaseCancelButton.vue';
import BaseDropdown from '../../components/base/BaseDropdown.vue';
import { EVENT_LIST_TIME_RANGE } from '../../consts/eventListConsts';
import { EVENT_TYPE, EVENT_TYPE_NAME } from '../../consts/eventConsts';
import { MISSION_STATES_NAMES_TO_STATES } from '../../consts/missionConsts';
import { isRobot } from '../../utils/models/DeviceUtils';
import BaseRadioGroup from '../../components/base/BaseRadioGroup.vue';
import BaseDatePicker from '../../components/base/BaseDatePicker.vue';
import BaseToggle from '../../components/base/BaseToggle.vue';
import { useDevicesStore } from '../../store/DevicesStore';
import { useEventListStore } from '../../store/EventListStore';
import { mapState, mapActions } from 'pinia';
import dayjs from 'dayjs';

export default {
  name: 'EventsLogFilterMenu',
  components: { BaseRadioGroup, BaseDropdown, BaseButton, BaseCancelButton, IconClose, IconUndo, BaseDatePicker, BaseToggle },
  emits: ['cancel', 'reset', 'filter', 'update-filter-by-location'],
  data() {
    return {
      originFilters: {},
      filters: { timeRange: {} },
      datePickerValues: {},
      datePickerMaxDate: new Date(),
      selectedTimeOptionId: EVENT_LIST_TIME_RANGE.LAST_7_DAYS,
      showResolved: false
    };
  },
  computed: {
    ...mapState(useDevicesStore, ['devices']),
    ...mapState(useEventListStore, ['listFilters', 'isListFilteredByLocation']),
    typeOptions() {
      return Object.keys(EVENT_TYPE).map(type => ({
        id: type,
        label: EVENT_TYPE_NAME[type]
      }));
    },
    missionStatesOptions() {
      return Object.keys(MISSION_STATES_NAMES_TO_STATES).map(name => ({
        id: name,
        label: name
      }));
    },
    deviceOptions() {
      const robots = [];
      this.devices.forEach(device => {
        if (isRobot(device)) {
          robots.push({
            id: device.id,
            label: device.name
          });
        }
      });
      return robots;
    },
    timeOptions() {
      return [
        { id: EVENT_LIST_TIME_RANGE.LAST_24_HOURS, label: 'Last 24 hours' },
        { id: EVENT_LIST_TIME_RANGE.LAST_7_DAYS, label: 'Last 7 days' },
        { id: EVENT_LIST_TIME_RANGE.CUSTOM, label: 'Custom' }
      ];
    },
    isTimeOptionChecked() {
      return this.selectedTimeOptionId === EVENT_LIST_TIME_RANGE.CUSTOM;
    }
  },
  created() {
    this.setFilters(this.listFilters);
  },
  methods: {
    ...mapActions(useEventListStore, ['resetFilters']),
    setFilters(filters) {
      this.originFilters = clone(filters);
      this.filters = clone(filters);
      this.datePickerValues = {
        start: filters.timeRange.from,
        end: filters.timeRange.to
      };
      this.selectedTimeOptionId = filters.timeRange.type;
      this.showResolved = filters.handled.includes(1);
    },
    onCloseClick() {
      this.$emit('cancel');
    },
    onResetClick() {
      this.resetFilters();
      this.showResolved = false;
      this.selectedTimeOptionId = EVENT_LIST_TIME_RANGE.LAST_7_DAYS;
      this.setFilters(this.listFilters);
      this.$emit('reset');
    },
    onFilterClick() {
      this.filters.handled = this.showResolved ? [0, 1] : [0];
      this.filters.timeRange.type = this.selectedTimeOptionId;
      if (this.selectedTimeOptionId === EVENT_LIST_TIME_RANGE.CUSTOM) {
        // If time format is Date object, convert it to timestamp
        this.filters.timeRange.from = this.datePickerValues.start.getTime?.() || this.datePickerValues.start;
        this.filters.timeRange.to = this.datePickerValues.end.getTime?.() || this.datePickerValues.end;
      }
      this.$emit('filter', this.filters);
    },
    onRevertRemovingLocationFilterClick() {
      this.filters.location = this.originFilters.location || null;
      this.$emit('update-filter-by-location', this.filters.location);
    },
    onRemoveLocationFilterClick() {
      this.originFilters.location = this.listFilters.location;
      this.filters.location = null;
      this.$emit('update-filter-by-location', null);
    },
    onFilterTimeRangeSelect(timeRangeId) {
      if (timeRangeId === EVENT_LIST_TIME_RANGE.LAST_24_HOURS) {
        this.datePickerValues = {
          start: dayjs().subtract(1, 'day').toDate(),
          end: new Date()
        };
      } else if (timeRangeId === EVENT_LIST_TIME_RANGE.LAST_7_DAYS) {
        this.datePickerValues = {
          start: dayjs().subtract(7, 'day').toDate(),
          end: new Date()
        };
      }
    }
  }
};
</script>

<style scoped lang="scss">
.events-log-filter-popup {
  z-index: 2;
  position: relative;
  width: 30rem;
  font-family: var(--font-family-secondary);
  row-gap: 1rem;
  background-color: var(--mainBackground);
  border-radius: 3px;
  box-shadow: 0px 2px 0.5rem var(--shadowColor);
  padding: 0.5rem;

  .icon-close {
    position: absolute;
    right: 0.5rem;

    cursor: pointer;
    width: 0.6rem;
    height: 0.6rem;
    color: var(--secondaryTextColor);

    &:hover {
      color: var(--textColor);
    }
  }

  .title {
    font-size: 1.25rem;
    padding-bottom: 0.3rem;
    color: var(--textColor);
    border-bottom: 1px solid var(--textColor);
  }

  .filters-container {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .property-container {
      display: flex;
      column-gap: 0.5rem;
      margin-top: 0.5rem;
      align-items: flex-start;

      .label {
        display: inline-block;
        min-width: 5rem;
        color: var(--secondaryTextColor);
      }

      .show-resolved-options {
        display: block;
      }

      .time-radios {
        color: var(--secondaryTextColor);
        display: contents;
      }

      .resolved-radio-container {
        color: var(--secondaryTextColor);
        margin-right: 10px;
      }

      .location-value-container {
        display: flex;
        color: var(--secondaryTextColor);

        .filter-by-location-icon {
          cursor: pointer;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon {
            width: 0.8rem;
            height: 0.8rem;
          }
        }
      }
    }
  }

  .filter-button {
    float: right;
  }
}
</style>
