import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "1 1 26 26",
  role: "icon"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createStaticVNode("<g><defs><path id=\"indr__a4\" d=\"M11 17h6v-6h-6z\"></path></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"indr__b4\" fill=\"#fff\"><use href=\"#indr__a4\"></use></mask><use fill=\"#A4AFB9\" fill-rule=\"nonzero\" href=\"#indr__a4\"></use><g fill=\"currentColor\" mask=\"url(#indr__b4)\"><path d=\"M0 0h28v28H0z\"></path></g><path fill=\"currentColor\" fill-rule=\"nonzero\" d=\"M14 26c6.627 0 12-5.373 12-12S20.627 2 14 2a11.955 11.955 0 0 0-7.847 2.921A11.972 11.972 0 0 0 2 14c0 6.627 5.373 12 12 12zm0-2C8.477 24 4 19.523 4 14a9.973 9.973 0 0 1 3.461-7.566A9.956 9.956 0 0 1 14 4c5.523 0 10 4.477 10 10s-4.477 10-10 10z\" opacity=\".5\"></path></g></g>", 1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }