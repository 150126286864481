<template>
  <BaseModal
    class="mission-dialog"
    :confirmation-option="{ text: 'Start Mission', disabled: deviceId === undefined }"
    cancel-option
    @close="onResolve"
    @confirm="onResolve(true)"
  >
    <template #title>
      <div class="modal-title">
        <IconDrone class="icon" />
        <span> {{ contextStore.missionDialogData.title }}</span>
      </div>
    </template>
    <template #body>
      <div class="dialog-body">
        <div class="text">
          {{ contextStore.missionDialogData.text }}
          <br />
          Assign a robot:
        </div>
        <BaseDropdown v-if="deviceOptions.length" v-model="deviceId" :options="deviceOptions" />
        <span v-else>No available devices for this operation</span>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from './base/BaseModal.vue';
import { useContextStore } from '../store/ContextStore';
import { mapStores } from 'pinia';
import BaseDropdown from './base/BaseDropdown.vue';
import { useDevicesStore } from '../store/DevicesStore';
import IconDrone from '../components/icons/IconDrone.svg?component';
import { DEVICES_TYPES } from '../consts/deviceConsts';
const ASSIGN_A_ROBOT_OPTION = { id: 0, name: 'Auto assigned' };

export default {
  name: 'MissionDialog',
  components: {
    BaseModal,
    BaseDropdown,
    IconDrone
  },
  data() {
    return {
      deviceId: undefined,
      deviceOptions: null
    };
  },
  computed: {
    ...mapStores(useContextStore, useDevicesStore)
  },
  created() {
    const type_ids = this.contextStore.missionDialogData?.type_ids;
    let options = this.devicesStore.contextZoneDronesForMission;

    if (type_ids) {
      options = options.filter(({ type_id }) => type_ids.includes(type_id));
    }
    // Add auto assigned option only if there are more than 1 device
    if ((!type_ids || type_ids.includes(DEVICES_TYPES.TANDO)) && options.length > 1) {
      options.unshift(ASSIGN_A_ROBOT_OPTION);
    }

    this.deviceOptions = options.map(({ id, name }) => ({ id, label: name ?? id }));
    this.deviceId = this.deviceOptions[0]?.id;
  },
  methods: {
    onResolve(isConfirmed = false) {
      this.contextStore.$patch(state => {
        state.missionDialogData.callback({ isConfirmed, deviceId: this.deviceId });
        state.showMissionDialog = false;
        state.missionDialogData = {
          callback: () => {},
          title: 'Start Mission',
          text: '',
          confirmText: 'Start Mission',
          cancelText: 'Cancel'
        };
      });
      this.deviceId = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.mission-dialog {
  .modal-title {
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin: auto;
    width: fit-content;

    .icon {
      width: 2rem;
      height: 2rem;
    }
  }
  .dialog-body {
    padding: 0 2rem;
    line-height: 2rem;
    overflow: auto;
    max-height: 75vh;
    margin: 1rem 0;
  }

  .text {
    max-width: 30rem;
    padding: 0 0 0.5rem 0;
  }
}
</style>
