import apiRequest from './apiRequest';

class UserManagementService {
  async fetchUsers() {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/users`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async createUsers(user) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/users`,
        method: 'POST',
        data: user
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async patchUser(user) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/users/${user.id}`,
        method: 'PATCH',
        data: user
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async deleteUser(userId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/users/${userId}`,
        method: 'DELETE'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async reInviteUser(userId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/users/re-invite/${userId}`,
        method: 'POST'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchGroups() {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/groups`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async createGroup(group) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/groups`,
        method: 'POST',
        data: group
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async patchGroup(group) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/groups/${group.id}`,
        method: 'PATCH',
        data: group
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async deleteGroup(groupId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/groups/${groupId}`,
        method: 'DELETE'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchRoles() {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/roles`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async createRole(role) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/roles`,
        method: 'POST',
        data: role
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async patchRole(role) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/roles/${role.id}`,
        method: 'PATCH',
        data: role
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async deleteRole(roleId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/roles/${roleId}`,
        method: 'DELETE'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchPrivileges() {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/privileges`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchPrivilegePackages() {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/privileges/packages`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new UserManagementService();
