<template>
  <div class="start-mission-button" :title="label" @click="callAction(point, actionType || MAP_QUICK_ACTION_TYPES.INVESTIGATE, metadata)">
    <IconDrone />
    {{ label }}
  </div>
</template>

<script>
import IconDrone from '../../components/icons/IconDrone.svg?component';
import MapQuickAction from '../../components/functional/MapQuickAction';
import { MAP_QUICK_ACTION_TYPES } from '../../consts/mapConsts';

export default {
  name: 'StartMissionButton',
  components: { IconDrone },
  extends: MapQuickAction,
  props: {
    label: {
      type: String,
      default: 'investigate now'
    },
    point: {
      type: Object,
      default: () => {}
    },
    actionType: {
      type: String,
      default: null
    },
    metadata: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      MAP_QUICK_ACTION_TYPES
    };
  }
};
</script>

<style scoped lang="scss">
.start-mission-button {
  display: flex;
  border-radius: 3px;
  padding: 5px 10px;
  border: solid 2px var(--secondaryInfoColor);
  background-color: var(--mainBackground);
  font-family: var(--font-family-secondary);
  font-size: 18px;
  line-height: 2.22;
  color: var(--secondaryTextColor);
  text-transform: capitalize;
  font-weight: lighter;
  cursor: pointer;

  &:hover {
    color: var(--textColor);

    svg {
      color: var(--textColor);
    }
  }

  svg {
    align-self: center;
    margin-right: 5px;
    width: 24px;
    height: 24px;
    color: var(--highlightColor);
  }
}
</style>
