<template>
  <div class="device-view">
    <div class="details-container">
      <BaseLoader v-if="!device" class="loader" />
      <DeviceDetails v-else :zone-id="zoneId" :device-id="deviceId" @save-device-reposition-params="onDeviceRepositionParametersSave" />
    </div>
    <MapCanvas v-if="zoneId" class="map" :indicators="mapIndicators" :is-quick-action-menu-enabled="true" @map-tap="onMapTap" />
  </div>
</template>

<script>
import MapCanvas from '../../components/theMap/MapCanvas.vue';
import DeviceDetails from './DeviceDetails.vue';
import { getDisplayConfigForMap, isRobot } from '../../utils/models/DeviceUtils';
import BaseLoader from '../../components/base/BaseLoader.vue';
import devicesService from '../../services/api/devicesService';
import { useDevicesStore } from '../../store/DevicesStore';
import { useZonesStore } from '../../store/ZonesStore';
import { useContextStore } from '../../store/ContextStore';
import { useAreasStore } from '../../store/AreasStore';
import { useMapStore } from '../../store/MapStore';
import { mapState, mapActions } from 'pinia';
import { MAP_MODE } from '../../consts/mapConsts';
import { ERROR_MESSAGES, SNACKBAR_TYPE } from '../../consts/appConsts';
import { PAGES } from '../../router';

export default {
  name: 'DeviceView',
  components: {
    MapCanvas,
    DeviceDetails,
    BaseLoader
  },
  async beforeRouteUpdate(to, from, next) {
    await this.initDevice();
    next();
  },
  // Props are from the router
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    zoneId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      device: null
    };
  },
  computed: {
    ...mapState(useAreasStore, ['activeNoFlightAreas']),
    ...mapState(useZonesStore, ['getZoneById']),
    ...mapState(useMapStore, ['repositionPoint', 'mapMode']),
    ...mapState(useDevicesStore, ['getDeviceById', 'wereDevicesFetched', 'contextZoneActiveDevices', 'activeNoFlightAreas']),
    mapIndicators() {
      return {
        icons: this.mapDevicesFormatter(),
        areas: this.activeNoFlightAreas?.map(area => ({
          ...area,
          isInBackground: true
        })),
        ...(this.repositionPoint && {
          locations: [
            {
              ...this.repositionPoint
            }
          ]
        })
      };
    }
  },
  async created() {
    await this.initDevice();
  },
  methods: {
    ...mapActions(useContextStore, ['showSnackbar']),
    ...mapActions(useZonesStore, ['fetchZoneById']),
    ...mapActions(useDevicesStore, ['fetchDeviceById']),
    ...mapActions(useMapStore, ['updateMapMode']),
    async onMapTap({ roomPoint, headingAngle }) {
      if (this.mapMode === MAP_MODE.REPOSITION) {
        if (!this.repositionPoint) {
          this.updateMapMode(MAP_MODE.REPOSITION, { repositionPoint: roomPoint });
        } else {
          this.saveDeviceNewLocation({
            roomPoint: this.repositionPoint,
            headingAngle
          });
        }
      }
    },
    onDeviceRepositionParametersSave(params) {
      this.saveDeviceNewLocation({
        roomPoint: {
          x: Number(params.x),
          y: Number(params.y),
          z: Number(params.z)
        },
        headingAngle: Number(params.h)
      });
    },
    async saveDeviceNewLocation({ roomPoint, headingAngle }) {
      try {
        await devicesService.repositionDevice(
          {
            id: this.device.id,
            location: roomPoint,
            heading: headingAngle
          },
          { force: !isRobot(this.device) }
        );
      } catch (e) {
        console.error(e);
      }
      this.updateMapMode(MAP_MODE.DEFAULT);
    },
    async initDevice() {
      try {
        this.device = await this.fetchDeviceById(this.deviceId);
      } catch (e) {
        console.error(e);
        this.showSnackbar({
          message: e.status === 401 || e.status === 404 ? ERROR_MESSAGES.GENERIC_401 : ERROR_MESSAGES.UNABLE_TO_DISPLAY_PAGE,
          type: SNACKBAR_TYPE.INFO
        });
        this.$router.push({
          name: PAGES.DEVICES
        });
      }
    },
    mapDevicesFormatter() {
      const icons = this.contextZoneActiveDevices.reduce((iconsArray, device) => {
        if (device.location) {
          if (device.id !== Number(this.deviceId)) {
            const clickable = this.mapMode !== MAP_MODE.REPOSITION;
            iconsArray.push(
              getDisplayConfigForMap({
                device,
                clickable
              })
            );
          } else {
            iconsArray.push(
              getDisplayConfigForMap({
                device,
                showSelectionMark: true
              })
            );
          }
        }
        return iconsArray;
      }, []);
      return icons;
    }
  }
};
</script>

<style scoped lang="scss">
.device-view {
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  .details-container {
    flex-basis: 35rem;
    max-width: 40%;
    display: flex;

    .loader {
      height: 3rem;
      width: 3rem;
      margin: auto;
    }
  }

  .map {
    flex-grow: 1;
  }
}
</style>
