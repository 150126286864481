import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", null, [
      _createElementVNode("circle", {
        cx: "12",
        cy: "12",
        r: "12",
        fill: "#AD239D"
      }),
      _createElementVNode("path", {
        fill: "#FFF",
        d: "M2.58 2c.295 0 .538.226.576.519l.005.081v2.219c4.296.275 7.627 3.595 7.74 7.952l-.001.029h2.52c.293 0 .536.226.575.519L14 13.4c0 .304-.218.555-.502.595L13.42 14H2.581c-.294 0-.537-.226-.576-.519L2 13.4V2.6c0-.331.26-.6.58-.6zm.582 4.021V12.8h6.577l-.001-.043C9.619 9.087 6.823 6.29 3.162 6.02zm3.11 2.802c.243-.216.61-.188.819.063.21.25.182.63-.061.846l-.586.522c-.243.217-.61.189-.82-.062-.209-.251-.181-.63.061-.846zm3.894-3.69c.243-.216.61-.188.819.063.209.251.182.63-.061.847l-.586.522c-.243.216-.61.188-.82-.063-.209-.25-.182-.63.061-.846zm2.345-2.089c.243-.216.61-.188.82.063.209.251.181.63-.062.846l-.586.523c-.243.216-.61.188-.819-.063-.21-.251-.182-.63.06-.846z",
        transform: "translate(4.000000, 4.000000)"
      })
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }