<template>
  <div class="zone-stat">
    <div class="zone-title">{{ zoneStat.name }}</div>
    <div class="zone-stat-items">
      <div class="zone-stat-item">
        <div class="zone-stat-count">{{ zoneStat.events_count }}</div>
        Events
      </div>
      <div class="zone-stat-item">
        <div class="zone-stat-count">{{ zoneStat.missions_count }}</div>
        Missions
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardZoneStat',
  props: {
    zoneStat: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.zone-stat {
  text-align: center;
  flex-grow: 1;
  min-width: 33%;

  .zone-title {
    font-weight: bold;
  }

  .zone-stat-items {
    display: flex;
    justify-content: center;

    .zone-stat-item {
      padding: 0.5rem;
      display: inline-block;
      text-align: center;

      .zone-stat-count {
        font-size: 1.55rem;
        font-weight: bold;
      }
    }
  }

  &.mobile {
    .zone-stat {
      min-width: 50%;
    }
  }
}
</style>
