<template>
  <div ref="boundedContainer" class="bounded-container" :style="cssProps">
    <slot />
  </div>
</template>

<script>
const MARGIN = 5;

export default {
  name: 'BaseBoundaryEnforcer',
  props: {
    position: {
      type: Object,
      required: true,
      validator(val) {
        return typeof val.x === 'number' && typeof val.y === 'number';
      }
    }
  },
  data() {
    return {
      menuPosition: { x: 0, y: 0 }
    };
  },
  computed: {
    cssProps() {
      return {
        left: `${this.menuPosition.x}px`,
        top: `${this.menuPosition.y}px`
      };
    }
  },
  watch: {
    position: {
      handler: function onPositionChanged(val) {
        const rect = this?.$refs?.boundedContainer?.getBoundingClientRect?.();
        if (rect) {
          const parentElm = this.$refs.boundedContainer.parentElement;
          const parentRect = parentElm.getBoundingClientRect();
          const maxX = parentRect.width - MARGIN - rect.width;
          const maxY = parentRect.height - MARGIN - rect.height;
          this.menuPosition = {
            x: Math.max(0, Math.min(val.x, maxX)),
            y: Math.max(0, Math.min(val.y, maxY))
          };
        } else {
          this.menuPosition = val;
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
.bounded-container {
  width: fit-content;
  position: absolute;
}
</style>
