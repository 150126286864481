import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "-10 -10 240 240",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createStaticVNode("<g><line y2=\"4\" x2=\"108.09391\" y1=\"4\" x1=\"71.90609\" stroke-width=\"12\" stroke=\"currentColor\"></line><line y2=\"4\" x2=\"40.18782\" y1=\"4\" x1=\"4\" stroke-width=\"12\" stroke=\"currentColor\"></line><line y2=\"4\" x2=\"176\" y1=\"4\" x1=\"139.81218\" stroke-width=\"12\" stroke=\"currentColor\"></line><line y2=\"176\" x2=\"108.09391\" y1=\"176\" x1=\"71.90609\" stroke-width=\"12\" stroke=\"currentColor\"></line><line x1=\"4\" y2=\"176\" x2=\"40.18782\" y1=\"176\" stroke-width=\"12\" stroke=\"currentColor\"></line><line y2=\"40.27344\" x2=\"176\" y1=\"4\" x1=\"176\" stroke-width=\"12\" stroke=\"currentColor\"></line><line y2=\"40.27344\" x2=\"4\" y1=\"4\" x1=\"4\" stroke-width=\"12\" stroke=\"currentColor\"></line><line y2=\"176\" x2=\"4\" y1=\"139.72656\" x1=\"4\" stroke-width=\"12\" stroke=\"currentColor\"></line><line y2=\"108.13672\" x2=\"4\" y1=\"71.86328\" x1=\"4\" stroke-width=\"12\" stroke=\"currentColor\"></line><line y2=\"108.13672\" x2=\"176\" y1=\"71.86328\" x1=\"176\" stroke-width=\"12\" stroke=\"currentColor\"></line><path d=\"m165.49377,110.02031c-30.92442,0.01326 -55.98585,25.07469 -55.99911,56.00353c0,30.92884 25.07027,55.99027 55.99911,55.99911c30.92442,-0.00884 55.98585,-25.07027 55.99469,-55.99469c-0.00442,-30.93326 -25.06585,-56.00795 -55.99469,-56.00795zm42.72558,56.00353c-0.01326,8.5911 -2.57777,16.57202 -6.93743,23.27511l-59.06326,-59.05441c6.69867,-4.36851 14.67959,-6.93743 23.27954,-6.95512c23.59347,0.04864 42.68136,19.14095 42.72115,42.73442zm-85.45999,0c0.01769,-8.5911 2.58219,-16.58087 6.95512,-23.28396l59.04999,59.05883c-6.70309,4.35966 -14.67959,6.92417 -23.27511,6.93743c-23.58905,-0.02211 -42.68136,-19.11884 -42.73,-42.71231z\"></path></g>", 1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }