export const AREA_SHAPES = {
  RECTANGLE: 'RECTANGLE',
  POLYGON: 'POLYGON'
};

export const CONFIG_AREA_RANGES = {
  ODOMETRY_FACTOR: {
    MIN: 50,
    MAX: 150,
    STEP: 1
  },
  FLIGHT_HEIGHT: {
    MIN: 0.5,
    MAX: 3.5,
    STEP: 0.05
  },
  TILE_PHYSICAL_ID: {
    MIN: 10001,
    MAX: 99999,
    STEP: 1
  }
};

export const CONFIG_AREA_DEFAULT_VALUES = {
  odometry: {
    type: 'odometry',
    enabled: true,
    factor: 100
  },
  tiles: {
    type: 'tiles',
    enabled: true,
    x: 0.0,
    y: 0.0,
    h: 2.5,
    alpha: 0.0,
    tile_id: 10001,
    drop_x: 0.0,
    drop_y: 0.0,
    drop_h: 1.0
  },
  height: {
    type: 'height',
    enabled: true,
    h_low: 0.85,
    h_nominal: 1.5,
    h_high: 1.8
  }
};
