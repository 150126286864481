<template>
  <vSelect
    v-model="value"
    class="dropdown"
    :placeholder="placeholder"
    :options="options"
    :multiple="multiple"
    :taggable="taggable"
    :push-tags="true"
    :select-on-tab="true"
    :close-on-select="!multiple"
    :no-drop="areAllOptionsSelected"
    :searchable="!areAllOptionsSelected && !loading"
    :loading="loading"
    :disabled="disabled"
    :reduce="item => item.id"
    :clearable="clearable"
    append-to-body
    @option:selected="$emit('selected', $event)"
    @blur="$emit('blur', $event)"
  >
    <template v-if="loading" #spinner>
      <BaseLoader class="loader" />
    </template>
    <template #search>
      <slot name="search" />
    </template>
    <template v-if="overwriteOptions" #option="option">
      <component :is="option.slot" v-if="option.slot" v-bind="option.slotProps" :class="{ large }" />
      <div v-else :class="{ large }">{{ option?.label }}</div>
    </template>
    <template v-if="overwriteOptions" #selected-option="option">
      <component :is="option.slot" v-if="option.slot" v-bind="option.slotProps" :class="{ large }" />
      <div v-else :class="{ large }">{{ option?.label }}</div>
    </template>
  </vSelect>
</template>

<script>
/* DOCUMENTATION: https://vue-select.org/ */
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import BaseLoader from './BaseLoader.vue';

export default {
  name: 'BaseDropdown',
  components: {
    vSelect,
    BaseLoader
  },
  props: {
    modelValue: {
      type: [Array, Object, String, Number],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'blur', 'selected'],
  data() {
    return {
      value: []
    };
  },
  computed: {
    areAllOptionsSelected() {
      return this.multiple && this.value?.length === this.options?.length;
    },
    overwriteOptions() {
      return this.large || this.options.some(opt => opt.slot);
    }
  },
  watch: {
    value(newVal) {
      this.$emit('update:modelValue', newVal);
    },
    modelValue: {
      handler: function onBindedValueChange(newVal) {
        if (this.value !== newVal) {
          this.value = newVal;
        }
      },
      immediate: true
    }
  }
};
</script>
<style lang="scss">
.dropdown {
  font-family: var(--font-family-secondary);
  display: inline-block;
  width: 100%;

  .loader {
    width: 1.2rem;
    height: 1.2rem;
    margin-bottom: 6px;
  }

  &.vs--disabled {
    opacity: 0.5;
    filter: grayscale(0.5);

    .vs__search,
    .vs__open-indicator,
    .vs__dropdown-toggle {
      background: var(--dropdown-bg, var(--primaryColorDarkShade1));
    }
  }

  .vs__search::placeholder,
  .vs__dropdown-toggle {
    background: var(--dropdown-bg, var(--primaryColorDarkShade1));
    color: var(--textColor);
    caret-color: var(--highlightColor);
  }

  .vs__search {
    color: var(--secondaryTextColor);
    height: 1.8rem;
  }

  .vs__dropdown-toggle {
    padding: 0.2rem;
  }

  &:not(.vs--single) {
    .vs__selected {
      background: var(--primaryColorBrightShade2);
      align-items: baseline;

      .vs__deselect {
        fill: var(--highlightColorShade1);
      }
    }
  }

  .vs__selected {
    color: var(--textColor);
    border-radius: 6px;
    line-height: 1.65rem;
  }
  .vs__actions {
    cursor: pointer;
  }

  .vs__clear,
  .vs__open-indicator {
    fill: var(--highlightColor);
    transition: 0.2s;

    &:hover {
      filter: grayscale(1);
    }
  }
}
</style>
