<template>
  <div class="stats-filter-menu">
    <div class="close-icon">
      <IconClose @click="onCloseClick"><title>Close</title></IconClose>
    </div>
    <div class="title">Filter Stats:</div>
    <div class="filters-container">
      <div class="property-container">
        <span class="label">Zone:</span>
        <BaseDropdown
          v-model="filters.zone"
          class="zone-dropdown"
          placeholder="All Zones"
          :clearable="true"
          :options="zoneOptions"
          :multiple="true"
          @click.stop=""
        ></BaseDropdown>
      </div>
      <div class="property-container">
        <span class="label time-label">Time:</span>
        <div class="time-radios">
          <BaseRadioGroup v-model="selectedTimeOptionId" :options="timeOptions"></BaseRadioGroup>
          <BaseDatePicker
            v-show="isTimeOptionChecked"
            v-model.range="datePickerValues"
            :max-date="datePickerMaxDate"
            :max-range-in-days="31"
            is-range
            class="date-picker"
          />
        </div>
      </div>
    </div>
    <div class="buttons-container">
      <BaseCancelButton class="reset-button" @click="onResetClick">Reset filters</BaseCancelButton>
      <BaseButton class="filter-button" @click="onFilterClick">Filter</BaseButton>
    </div>
  </div>
</template>

<script>
import { STATS_TIME_RANGE } from '../../consts/statsConsts';
import { useZonesStore } from '../../store/ZonesStore';
import BaseButton from '../../components/base/BaseButton.vue';
import BaseCancelButton from '../../components/base/BaseCancelButton.vue';
import BaseDropdown from '../../components/base/BaseDropdown.vue';
import BaseRadioGroup from '../../components/base/BaseRadioGroup.vue';
import BaseDatePicker from '../../components/base/BaseDatePicker.vue';
import IconClose from '../../components/icons/IconClose.svg?component';
import { useStatsStore } from '../../store/StatsStore';
import { mapState, mapActions } from 'pinia';
import { clone } from '../../utils/ObjectUtils';

export default {
  name: 'DashboardStatsFilterMenu',
  components: {
    BaseRadioGroup,
    BaseDropdown,
    BaseButton,
    BaseCancelButton,
    BaseDatePicker,
    IconClose
  },
  emits: ['reset', 'filter', 'cancel'],
  data() {
    return {
      filters: { timeRange: {} },
      datePickerValues: { start: new Date(), end: new Date() },
      datePickerMaxDate: new Date(),
      selectedTimeOptionId: STATS_TIME_RANGE.LAST_24_HOURS
    };
  },
  computed: {
    ...mapState(useZonesStore, ['zones']),
    ...mapState(useStatsStore, ['statsFilters']),
    zoneOptions() {
      return this.zones.map(zone => ({ id: zone.id, label: zone.name }));
    },
    timeOptions() {
      return [
        {
          id: STATS_TIME_RANGE.LAST_24_HOURS,
          label: 'Last 24 hours'
        },
        {
          id: STATS_TIME_RANGE.LAST_7_DAYS,
          label: 'Last 7 days'
        },
        {
          id: STATS_TIME_RANGE.LAST_MONTH,
          label: 'Last month'
        },
        {
          id: STATS_TIME_RANGE.CUSTOM,
          label: 'Custom'
        }
      ];
    },
    isTimeOptionChecked() {
      return this.selectedTimeOptionId === STATS_TIME_RANGE.CUSTOM;
    }
  },
  created() {
    this.filters.timeRange.type = STATS_TIME_RANGE.LAST_24_HOURS;
  },
  methods: {
    ...mapActions(useStatsStore, ['resetStatsFilters']),
    setFilters(filters) {
      this.filters = clone(filters);
    },
    onCloseClick() {
      this.$emit('cancel');
    },
    onResetClick() {
      this.resetStatsFilters();
      this.setFilters(this.statsFilters);
      this.selectedTimeOptionId = STATS_TIME_RANGE.LAST_24_HOURS;
      this.$emit('reset');
    },
    onFilterClick() {
      this.filters.timeRange.type = this.selectedTimeOptionId;

      if (this.selectedTimeOptionId === STATS_TIME_RANGE.CUSTOM) {
        this.filters.timeRange.from = this.datePickerValues.start.getTime();
        this.filters.timeRange.to = this.datePickerValues.end.getTime();
      }
      this.$emit('filter', this.filters);
    },
    getTimeRangeLabel(id) {
      const selectedOption = this.timeOptions.filter(item => item.id === id);
      return selectedOption ? selectedOption.label : '';
    },
    onDatePickerInput(range) {
      if (range && range.timestamps) {
        this.filters.timeRange.from = range.timestamps.from;
        this.filters.timeRange.to = range.timestamps.to;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.stats-filter-menu {
  background-color: var(--mainBackground);
  font-family: var(--font-family-secondary);
  border-radius: 3px;
  box-shadow: 0px 2px 0.5rem var(--shadowColor);
  position: relative;
  width: 21rem;
  font-size: 1rem;
  padding: 0.5rem;

  .close-icon {
    position: absolute;
    right: 0.5rem;

    svg {
      color: var(--secondaryTextColor);
      width: 0.6rem;
      height: 0.6rem;
      cursor: pointer;

      &:hover {
        color: var(--textColor);
      }
    }
  }

  .title {
    font-size: 1.25rem;
    padding-bottom: 0.3rem;
    color: var(--textColor);
    border-bottom: 1px solid var(--textColor);
  }

  .filters-container {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;

    .property-container {
      margin-top: 0.6rem;

      .label {
        display: inline-block;
        width: 4.3rem;
        color: var(--secondaryTextColor);

        &.time-label {
          vertical-align: top;
        }
      }
      .zone-dropdown,
      .time-radios {
        width: calc(100% - 4.3rem);
      }

      .time-radios {
        display: inline-block;

        .date-picker {
          margin: 0.5rem 0 0 0;
        }
      }
    }
  }
  .buttons-container {
    .reset-button {
      color: var(--highlightColor);
    }
    .filter-button {
      float: right;
      margin-right: 1rem;
    }
  }

  &.mobile {
    .stats-filter-menu {
      width: 19.2rem;
    }
  }
}
</style>
