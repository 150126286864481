import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 10 10",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", { fill: "#FDEB92" }, [
      _createElementVNode("path", {
        d: "m -0.663 1.6362 v 0.5413 c 0 0.4974 -0.3915 0.9024 -0.8732 0.9024 c -0.4216 0 -0.7746 -0.3107 -0.8555 -0.7219 h 0.8555 c 0.0963 0 0.1748 -0.0809 0.1748 -0.1806 s -0.0782 -0.1806 -0.1748 -0.1806 h -0.8732 v -0.3607 h 1.7464 z m -0.8751 -4.7162 c 1.0499 0 1.9231 0.8736 1.9231 1.9851 c 0 0.7354 -0.318 1.1138 -0.5983 1.4484 c -0.2287 0.2722 -0.4274 0.5094 -0.4478 0.9217 h -1.7502 c -0.0231 -0.4528 -0.2352 -0.6972 -0.4789 -0.9783 c -0.3253 -0.3754 -0.6942 -0.8008 -0.5378 -1.7421 c 0.1517 -0.9121 0.9217 -1.6347 1.89 -1.6347 z m 3.7846 0.4551 c 0.7423 0.7404 1.1512 1.7225 1.1512 2.767 s -0.4089 2.027 -1.1515 2.7662 c -0.047 0.047 -0.1086 0.0705 -0.1702 0.0705 c -0.0616 0 -0.1232 -0.0231 -0.1698 -0.0705 c -0.0943 -0.0936 -0.0943 -0.2452 0 -0.3388 c 0.6518 -0.6491 1.0106 -1.5107 1.0106 -2.4278 c 0 -0.9163 -0.3592 -1.7787 -1.0106 -2.4274 c -0.0943 -0.0936 -0.0943 -0.2456 0 -0.3388 c 0.0939 -0.0939 0.2464 -0.0939 0.3403 0 z m -0.6807 0.6776 c 0.5609 0.5583 0.8697 1.3001 0.8697 2.0894 s -0.3088 1.5311 -0.8697 2.089 c -0.0466 0.047 -0.1082 0.0705 -0.1698 0.0705 c -0.0616 0 -0.1232 -0.0231 -0.1702 -0.0705 c -0.0939 -0.0936 -0.0939 -0.2452 0 -0.3388 c 0.4697 -0.4674 0.7284 -1.0888 0.7284 -1.7502 c 0 -0.661 -0.2587 -1.2828 -0.7288 -1.7502 c -0.0939 -0.0932 -0.0939 -0.2452 0 -0.3388 s 0.2464 -0.0936 0.3403 0 z z z m -6.0658 1.9473 a 0.5 0.5 90 0 1 9 0 a 0.5 0.5 90 0 1 -9 0 m -0.5 0 a 0.5 0.5 90 0 0 10 0 a 0.5 0.5 90 0 0 -10 0",
        transform: "translate(5, 5)"
      })
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }