<template>
  <div class="edit-integration-email">
    <div class="property-container">
      <div class="label-container">Email Addresses:</div>
      <div class="value-container">
        <BaseTagInput v-model="integrationData.data.to" />
        <div v-if="showError" class="error-message">Please make sure all email addresses are valid.</div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTagInput from '../../components/base/BaseTagInput.vue';
import { EMAIL_REGEX } from '../../consts/appConsts';
import { toRaw } from 'vue';

export default {
  name: 'EditIntegrationEmail',
  components: { BaseTagInput },
  props: {
    integration: {
      type: Object,
      required: true
    }
  },
  emits: ['update'],
  data() {
    return {
      aaaa: [],
      integrationData: {}
    };
  },
  computed: {
    toOptions() {
      return this.integrationData.data.to.map(emailAddress => ({
        id: emailAddress,
        label: emailAddress
      }));
    },
    showError() {
      return !this.isAllEmailsValid;
    },
    isAllEmailsValid() {
      return this.integrationData?.data?.to.every(email => EMAIL_REGEX.test(email));
    },
    isSaveEnabled() {
      return this.integrationData.data.to && this.integrationData.data.to.length > 0 && this.isAllEmailsValid;
    }
  },
  created() {
    this.integrationData = structuredClone(toRaw(this.integration));
    if (!this.integrationData.data?.to) {
      this.integrationData.data.to = [];
    }
  },
  mounted() {
    this.$emit('update');
  },
  updated() {
    this.$emit('update');
  },
  methods: {
    getDiffAttributes() {
      const origin = this.integration.data;
      const diff = {};
      Object.keys(this.integrationData.data).forEach(key => {
        if (JSON.stringify(this.integrationData.data[key]) !== JSON.stringify(origin[key])) {
          diff[key] = this.integrationData.data[key];
        }
      });
      return diff;
    }
  }
};
</script>

<style scoped lang="scss">
.edit-integration-email {
  .property-container {
    margin-top: 20px;
    .label-container {
      color: var(--secondaryTextColor);
      margin-bottom: 5px;
    }
    .value-container {
      &.text-value {
        color: var(--secondaryTextColor);
      }
      .error-message {
        font-size: 13px;
        color: var(--errorColor);
      }
    }
  }
}
</style>
