import { POI_STYLE_CONFIG, ICONS_STYLE_CONFIG, LANDMARKS_STYLE_CONFIG, X_MARK_PATH } from '../consts/drawingConsts.js';
import { getAngleBetweenPointsInRadians } from './PointsUtils.js';
import { getStyleVar } from './StyleUtils';
import { MAP_POI_OPTIONS } from '../consts/mapConsts';

export const BADGE_POSITIONS = {
  TOP_LEFT: 'TOP_LEFT',
  TOP_RIGHT: 'TOP_RIGHT',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT'
};

const drawIndicatorWrapper = (options, drawIndicator) => {
  const { ctx, radius, mapZoom, isDisabled, showWarningMark, showChargeMark, showSelectionMark, x, y, text, style, labelY } = options;

  if (isDisabled) {
    ctx.globalAlpha = 0.2;
  }
  if (showSelectionMark) {
    drawSelectionMark({
      ctx,
      radius,
      mapZoom,
      x,
      y
    });
  }
  drawIndicator(options);
  if (isDisabled) {
    ctx.globalAlpha = 1;
  }

  if (text) {
    drawLabel({
      ctx,
      text,
      mapZoom,
      x,
      y: labelY || y + (2 * radius + 1.5) * (1 / mapZoom),
      style: {
        textColor: style.textColor,
        textBackground: style.textBackground,
        fontSize: style.fontSize,
        fontFamily: style.fontFamily
      }
    });
  }
  if (showWarningMark) {
    drawWarningMark({
      ...options,
      style: style.warningMark,
      radius: radius / 2.5,
      x: options.x - (radius / 2) * (1 / mapZoom),
      y: options.y - radius * 0.6 * (1 / mapZoom)
    });
  } else if (showChargeMark) {
    drawChargeMark({
      ...options,
      style: style.chargeMark,
      radius: radius / 2.5,
      x: options.x - (radius / 2) * (1 / mapZoom),
      y: options.y - radius * 0.7 * (1 / mapZoom)
    });
  }
};

/**
 * Draws icons in a framed square with label above
 * @param options {Object} - configuration for drawing icon indicator
 * @param options.img {string} - the icon's image source
 * @param options.ctx {CanvasRenderingContext2D} - the canvas context on which to draw
 * @param options.radius {number} - half of the containing square size
 * @param options.mapZoom {number} - current map zoom level
 * @param options.isDisabled {boolean} - flag to determine whether to draw the indicator with opacity or not
 * @param options.showWarningMark {boolean} - flag to determine whether to draw warning mark
 * @param options.showChargeMark {boolean} - flag to determine whether to draw charging mark
 * @param options.x {number} - x coordinate, in the canvas, to draw on
 * @param options.y {number} - y coordinate, in the canvas, to draw on
 * @param options.text {string} - label text
 * @param options.labelY {string} - label y coordinate (above/below/on the drawing)
 * @param options.style {Object} - visual configuration for icon drawing
 * @param options.style.mainColor {string} - icon and frame's color
 * @param options.style.bgColor {string} - containing square's color
 * @param options.style.warningMark {DrawMarkStyle} - visual configuration for warning mark
 * @param options.style.chargeMark {DrawMarkStyle} - visual configuration for charging mark
 * @param options.style.textColor {string} - text color
 * @param options.style.fontSize {number} - font size
 * @param options.style.fontFamily {string} - font family for the icon's label
 */
export const drawIconIndicator = options => {
  drawIndicatorWrapper(options, () => {
    const { ctx, radius, mapZoom, x, y, img, style, iconKey } = options;

    if (iconKey) {
      drawIcon({
        ctx,
        radius,
        mapZoom,
        x,
        y,
        config: ICONS_STYLE_CONFIG[iconKey]
      });
    } else if (img) {
      // Draw frame
      ctx.beginPath();
      // ctx.rect(x - radius, y - radius, 2 * radius, 2 * radius);
      ctx.arc(x, y, radius, 0, 2 * Math.PI);
      ctx.lineWidth = 0.5;
      ctx.fillStyle = style.bgColor;
      ctx.fill();
      ctx.closePath();

      // Draw icon
      ctx.beginPath();
      ctx.fillStyle = style.mainColor;
      ctx.drawImage(img, x - radius, y - radius, 2 * radius, 2 * radius);
      ctx.closePath();
    }
  });
};

/**
 * Draws dot with label above
 * @param options {Object} - configuration for drawing dot indicator
 * @param options.radius {number} - half of the containing square size
 * @param options.mapZoom {number} - current map zoom level
 * @param options.x {number} - x coordinate, in the canvas, to draw on
 * @param options.y {number} - y coordinate, in the canvas, to draw on
 * @param options.colorSet {Object} - dot colors
 * @param options.colorSet.dark {string} - main color
 * @param options.colorSet.bright {string} - stroke color
 */
export const drawDotIndicator = options => {
  const { ctx, radius, mapZoom, x, y, colorSet = {} } = options;
  drawIndicatorWrapper(
    {
      ...options,
      labelY: y + (2 - radius * 2) * (1 / mapZoom)
    },
    () => {
      ctx.beginPath();
      ctx.arc(x, y, radius * (1 / mapZoom), 0, 2 * Math.PI);
      ctx.fillStyle = colorSet.dark || 'green';
      ctx.fill();
      ctx.lineWidth = (radius / 2) * (1 / mapZoom);
      ctx.strokeStyle = colorSet.bright || 'lightgreen';
      ctx.stroke();
      ctx.closePath();
    }
  );
};

/**
 * Draws text
 * @param options {Object} - configuration for drawing label
 * @param options.ctx {CanvasRenderingContext2D} - the canvas context on which to draw
 * @param options.mapZoom {number} - current map zoom level
 * @param options.x {number} - x coordinate for the text's center
 * @param options.y {number} - y coordinate for the text's center
 * @param options.text {string} - text to write
 * @param options.style {Object} - visual configuration
 * @param options.style.textColor {string} - text color
 * @param options.style.fontSize {number} - font size
 * @param options.style.fontFamily {string} - font family for the icon's label
 */
export const drawLabel = ({ ctx, mapZoom, x, y, text, style }) => {
  ctx.beginPath();
  const fontSize = style.fontSize * (1 / mapZoom);
  ctx.font = `${fontSize}px ${style.fontFamily || 'Arial'}`;

  // Get width of text
  const width = ctx.measureText(text).width;

  // Draw label background
  ctx.strokeStyle = style.textBackground;
  ctx.fillStyle = style.textBackground;
  ctx.lineJoin = 'round';
  ctx.lineWidth = 5 * (1 / mapZoom);
  const padding = 12 * (1 / mapZoom);
  const bgRect = {
    x: x - width / 2 - padding - ctx.lineWidth / 2,
    y: y - fontSize + ctx.lineWidth,
    width: width + 2 * padding + ctx.lineWidth,
    height: fontSize
  };
  ctx.fillRect(bgRect.x, bgRect.y, bgRect.width, bgRect.height);
  ctx.strokeRect(bgRect.x, bgRect.y, bgRect.width, bgRect.height);

  // Popup sign
  const signSize = 3 * (1 / mapZoom);
  ctx.lineJoin = 'miter';
  ctx.moveTo(x - signSize * 2, y - fontSize + ctx.lineWidth);
  ctx.lineTo(x, y - fontSize);
  ctx.lineTo(x + signSize * 2, y - fontSize + ctx.lineWidth);
  ctx.lineTo(x - signSize * 2, y - fontSize + ctx.lineWidth);
  ctx.stroke();
  ctx.fill();

  // Text
  ctx.fillStyle = style.textColor || 'white';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'alphabetic';
  ctx.fillText(text, x, y + ctx.lineWidth / 3);
  ctx.closePath();
};

export const drawNote = ({ ctx, x, y, text, style, overlayXMark }) => {
  ctx.beginPath();
  const spacedText = text.split('').join(' ');
  const fontSize = style.fontSize;
  ctx.font = `bold ${fontSize}px ${style.fontFamily || 'Arial'}`;
  const width = ctx.measureText(spacedText).width;
  const padding = 6;
  ctx.fillStyle = style.textColor || 'white';
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'center';
  ctx.fillText(spacedText, x, y + ctx.lineWidth / 3);
  ctx.closePath();

  if (overlayXMark) {
    const bgRect = {
      x: x - width / 2 - padding / 2,
      y: y - fontSize / 2 - padding / 4,
      width: width + padding,
      height: fontSize + padding / 2
    };
    ctx.beginPath();
    ctx.fillStyle = getStyleVar('--secondaryOverlayShade1');
    ctx.fillRect(...Object.values(bgRect));
    ctx.fillStyle = getStyleVar('--errorColor');
    const xMarkToHeightRatio = 0.7;
    const transformationMatrix = new DOMMatrixReadOnly()
      .scale(xMarkToHeightRatio, xMarkToHeightRatio)
      .translate(x / xMarkToHeightRatio, y / xMarkToHeightRatio);
    ctx.closePath();

    const xMarkPath = new Path2D(X_MARK_PATH);
    const scaledPath = new Path2D();
    scaledPath.addPath(xMarkPath, transformationMatrix);
    ctx.fill(scaledPath);
    ctx.closePath();
  }
};

export const drawLandmark = ({ ctx, x, y, radius, type, overlayXMark, landmarkToRadiusRatio }) => {
  const LANDMARK_RADIUS = radius * landmarkToRadiusRatio;
  const config = LANDMARKS_STYLE_CONFIG[type];
  ctx.beginPath();
  ctx.arc(x, y, LANDMARK_RADIUS, 0, Math.PI * 2, true);
  ctx.fillStyle = config.background || getStyleVar('--overlayShade2');
  ctx.fill();
  const transformationMatrix = new DOMMatrixReadOnly().translate(x, y);
  const scaledPath = new Path2D();
  scaledPath.addPath(new Path2D(config.path), transformationMatrix);
  ctx.fillStyle = config.color;
  ctx.fill(scaledPath);
  ctx.closePath();

  if (overlayXMark) {
    ctx.beginPath();
    ctx.arc(x, y, LANDMARK_RADIUS, 0, Math.PI * 2, true);
    ctx.fillStyle = getStyleVar('--overlayShade2');
    ctx.fill();
    const xMarkPath = new Path2D(X_MARK_PATH);
    const xMarkScaledPath = new Path2D();
    xMarkScaledPath.addPath(xMarkPath, transformationMatrix.scale(0.8, 0.8));
    ctx.fillStyle = getStyleVar('--errorColor');
    ctx.fill(xMarkScaledPath);
    ctx.closePath();
  }
};

function calcBadgeLocationAndSize(mapZoom, x, y, radius, positionId, fontSize = 12, radiusScale = 0.45) {
  const xOffset = (1 - radiusScale) * 1.4 * radius * (1 / mapZoom);
  const yOffset = (1 - radiusScale) * 1.15 * radius * (1 / mapZoom);
  const xMovement = [BADGE_POSITIONS.TOP_LEFT, BADGE_POSITIONS.BOTTOM_LEFT].includes(positionId) ? -1 : 1;
  const yMovement = [BADGE_POSITIONS.TOP_LEFT, BADGE_POSITIONS.TOP_RIGHT].includes(positionId) ? -1 : 1;

  return {
    x: x + xOffset * xMovement,
    y: y + yOffset * yMovement,
    radius: radius * radiusScale * (1 / mapZoom),
    fontSize: fontSize * (1 / mapZoom)
  };
}

/**
 * Draws Budge (based on radius)
 * @param options {Object} - configuration for drawing the badge
 * @param options.ctx {CanvasRenderingContext2D} - the canvas context on which to draw
 * @param options.mapZoom {number} - current map zoom level
 * @param options.text {string} - text to write within the badge
 * @param options.x {number} - x coordinate for the badge-target-element's center
 * @param options.y {number} - y coordinate for the badge-target-element's center
 * @param options.style {Object} - visual configuration
 * @param options.style.textColor {string} - text color
 * @param options.style.fontSize {number} - font size
 * @param options.style.background {string} - the badge background color
 * @param options.style.stroke {string} - the badge stroke color
 * @param options.style.scale {string} - scale of the badge radius based on the badge-target-element's radius
 */
export function drawBadge(options) {
  const { ctx, radius, mapZoom, text, x, y, positionId, style } = options;
  ctx.beginPath();
  const badgeConfig = calcBadgeLocationAndSize(mapZoom, x, y, radius, positionId, style.fontSize, style.scale);
  ctx.arc(badgeConfig.x, badgeConfig.y, badgeConfig.radius, 0, Math.PI * 2, true);
  ctx.strokeStyle = style.stroke;
  ctx.lineWidth = 2 / mapZoom;
  ctx.fillStyle = style.background;
  ctx.fill();
  ctx.fillStyle = style.textColor;

  if (text) {
    ctx.textAlign = 'center';
    ctx.textBaseline = 'alphabetic';
    ctx.font = `bold ${style.fontSize / mapZoom}px Arial`;
    ctx.fillText(text, badgeConfig.x, badgeConfig.y + (badgeConfig.radius + badgeConfig.fontSize) * 0.25);
  }
  ctx.stroke();
  ctx.closePath();
}

/**
 * Draws path with start and end point
 * @param options {Object} - configuration for drawing path
 * @param options.ctx {CanvasRenderingContext2D} - the canvas context on which to draw
 * @param options.path {Object[]} - array of points
 * @param options.path[].x {number} - x coordinate for a path point
 * @param options.path[].y {number} - y coordinate for a path point
 * @param options.path[].color {number} - point color, should only be sent for end point
 * @param options.isOnGoing {boolean} - flag to determine whether to draw end point or not
 * @param options.style {Object} - visual configuration
 * @param options.style.startPointColor {string} - color for first point in the path
 * @param options.style.startPointRadius {number} - start point radius
 * @param options.style.endPointColor {string} - default color for last point in the path (will be used if color is not defined on the point object)
 * @param options.style.endPointRadius {number} - end point radius
 * @param options.style.lineColor {string} - path line color
 * @param options.style.lineWidth {number} - path line thickness
 */
export const drawPath = ({ ctx, path, isOnGoing, style }) => {
  const anchoredPoints = path.map((p, idx) => ({
    preAnchor:
      idx === 0
        ? null
        : {
            x: (path[idx - 1].x + p.x) / 2,
            y: (path[idx - 1].y + p.y) / 2
          },
    point: p,
    postAnchor:
      idx === path.length - 1
        ? null
        : {
            x: (path[idx + 1].x + p.x) / 2,
            y: (path[idx + 1].y + p.y) / 2
          }
  }));

  anchoredPoints.forEach(anchoredPoint => {
    if (!anchoredPoint.preAnchor && !anchoredPoint.postAnchor) {
      // Only point in path
      return;
    } else if (!anchoredPoint.preAnchor && anchoredPoint.postAnchor) {
      ctx.globalAlpha = style.opacity || 1;
      ctx.beginPath();
      ctx.moveTo(anchoredPoint.point.x, anchoredPoint.point.y);
      ctx.lineTo(anchoredPoint.postAnchor.x, anchoredPoint.postAnchor.y);
      ctx.strokeStyle = style.lineColor;
      ctx.lineWidth = style.lineWidth;
      ctx.stroke();
      ctx.closePath();

      // Start point
      ctx.globalAlpha = 1;
      ctx.beginPath();
      ctx.arc(anchoredPoint.point.x, anchoredPoint.point.y, style.startPointRadius, 0, 2 * Math.PI);
      ctx.fillStyle = style.startPointColor;
      ctx.fill();
      ctx.closePath();
      ctx.globalAlpha = style.opacity || 1;
    } else if (!anchoredPoint.postAnchor && anchoredPoint.preAnchor) {
      // End point
      ctx.beginPath();
      ctx.moveTo(anchoredPoint.preAnchor.x, anchoredPoint.preAnchor.y);
      ctx.lineTo(anchoredPoint.point.x, anchoredPoint.point.y);
      ctx.stroke();
      ctx.closePath();
    } else if (anchoredPoint.preAnchor && anchoredPoint.postAnchor) {
      ctx.beginPath();
      ctx.moveTo(anchoredPoint.preAnchor.x, anchoredPoint.preAnchor.y);
      ctx.bezierCurveTo(
        anchoredPoint.point.x,
        anchoredPoint.point.y,
        anchoredPoint.point.x,
        anchoredPoint.point.y,
        anchoredPoint.postAnchor.x,
        anchoredPoint.postAnchor.y
      );
      ctx.stroke();
      ctx.closePath();
    }
  });

  ctx.closePath();

  ctx.globalAlpha = 1;
  // Do not draw end point if path is not complete
  if (!isOnGoing) {
    const lastInd = path[path.length - 1];
    if (lastInd) {
      ctx.beginPath();
      ctx.arc(lastInd.x, lastInd.y, style.endPointRadius, 0, 2 * Math.PI);
      ctx.fillStyle = lastInd.color || style.endPointColor;
      ctx.fill();
      ctx.closePath();
    }
  }
};

/**
 * Draws range mark
 * @param options {Object} - configuration for drawing location
 * @param options.ctx {CanvasRenderingContext2D} - the canvas context on which to draw
 * @param options.radius {number} - half of the containing square size
 * @param options.x {number} - x coordinate for the text's center
 * @param options.y {number} - y coordinate for the text's center
 * @param options.text {string} - text to write
 * @param options.style {Object} - visual configuration
 * @param options.style.rangeColor {string} - color of the mark
 */
export const drawLocationRange = ({ ctx, radius, x, y, rangeColor }) => {
  ctx.globalAlpha = 0.1;
  ctx.lineWidth = 4;
  ctx.strokeStyle = rangeColor;
  ctx.beginPath();
  ctx.arc(x, y, radius * 1.25, 0, Math.PI * 2, true);
  ctx.stroke();
  ctx.closePath();
  ctx.globalAlpha = 0.25;
  ctx.beginPath();
  ctx.arc(x, y, radius * (3 / 4), 0, Math.PI * 2, true);
  ctx.stroke();
  ctx.closePath();
  ctx.globalAlpha = 0.4;
  ctx.beginPath();
  ctx.arc(x, y, radius / 4, 0, Math.PI * 2, true);
  ctx.stroke();
  ctx.closePath();
  ctx.globalAlpha = 1;
};

export const drawSelectionMark = ({ ctx, mapZoom, x, y, radius }, selectedColor = '#0EDBF2') => {
  ctx.strokeStyle = selectedColor;
  const gradient = ctx.createRadialGradient(x, y, 0, x, y, radius * 2 * (1 / mapZoom));
  gradient.addColorStop(0, 'transparent');
  gradient.addColorStop(1, selectedColor);
  ctx.beginPath();
  ctx.arc(x, y, radius * 1.5 * (1 / mapZoom), 0, Math.PI * 2, true);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.lineWidth = 0.5;
  ctx.stroke();
  ctx.closePath();
};

/**
 * Draws location (poi) mark
 * @param options {Object} - configuration for drawing location
 * @param options.ctx {CanvasRenderingContext2D} - the canvas context on which to draw
 * @param options.radius {number} - half of the containing square size
 * @param options.mapZoom {number} - current map zoom level
 * @param options.x {number} - x coordinate for the text's center
 * @param options.y {number} - y coordinate for the text's center
 * @param options.text {string} - text to write
 * @param options.poiType {string} - from MAP_POI_OPTIONS enum
 * @param options.style {Object} - visual configuration
 * @param options.style.strokeColor {string} - color for the location mark stroke
 */
export const drawLocation = options => {
  const { ctx, radius, mapZoom, x, y, h, poiType, showFilterMark, showVideoMark, style } = options;
  const type = poiType || MAP_POI_OPTIONS.VISIT;
  if (showFilterMark || showVideoMark) {
    drawLocationRange(options);
  } else {
    drawIcon({
      ctx,
      radius,
      mapZoom,
      x,
      y,
      h,
      config: POI_STYLE_CONFIG[type]
    });

    if (style && style.strokeColor) {
      ctx.globalAlpha = 0.8;
      ctx.beginPath();
      ctx.arc(x, y, (radius + 0.7) * (1 / mapZoom), 0, Math.PI * 2, true);
      ctx.strokeStyle = style.strokeColor;
      ctx.lineWidth = 2.5 * (1 / mapZoom);
      ctx.stroke();
      ctx.closePath();
      ctx.globalAlpha = 1;
    }
  }
};

export const drawIcon = ({ ctx, radius, mapZoom, x, y, h, config }) => {
  const scaledRadius = radius / mapZoom;
  if (config.background) {
    ctx.beginPath();
    ctx.fillStyle = config.background;

    if (config.rectBackground) {
      ctx.strokeStyle = config.background;
      ctx.lineJoin = 'round';
      ctx.lineWidth = (12 * 1) / mapZoom;
      ctx.strokeRect(
        x - scaledRadius + ctx.lineWidth / 2,
        y - scaledRadius + ctx.lineWidth / 2,
        2 * scaledRadius - ctx.lineWidth,
        2 * scaledRadius - ctx.lineWidth
      );
      ctx.fillRect(
        x - scaledRadius + ctx.lineWidth / 2,
        y - scaledRadius + ctx.lineWidth / 2,
        2 * scaledRadius - ctx.lineWidth,
        2 * scaledRadius - ctx.lineWidth
      );
    } else {
      ctx.arc(x, y, scaledRadius, 0, Math.PI * 2, true);
      ctx.fill();
    }
    ctx.closePath();
  }

  ctx.beginPath();
  ctx.fillStyle = config.color;
  const transformationMatrix = new DOMMatrixReadOnly().scale(1 / mapZoom, 1 / mapZoom).translate(mapZoom * x, mapZoom * y);
  const scaledPath = new Path2D();
  scaledPath.addPath(new Path2D(config.path), transformationMatrix);
  ctx.fill(scaledPath);
  ctx.closePath();

  if (!isNaN(h)) {
    drawArrow({
      ctx,
      from: { x, y },
      scaledRadius,
      color: config.color,
      angle: h
    });
  }
};

/**
 * Draws round charge badge icon
 * @param options {Object} - configuration for drawing
 * @param options.ctx {CanvasRenderingContext2D} - the canvas context on which to draw
 * @param options.radius {number} - radius in which to draw the badge
 * @param options.mapZoom {number} - current map zoom level
 * @param options.x {number} - x coordinate for the badge center
 * @param options.y {number} - y coordinate for the badge center
 * @param options.style {Object} - visual configuration
 * @param options.style.bgColor {string} - mark background color
 * @param options.style.markColor {string} - mark text color
 */
export const drawChargeMark = ({ x, y, ctx, mapZoom, radius, style }) => {
  const scaledRadius = radius * (1 / mapZoom);
  const _x = x - scaledRadius * 1.5;
  const _y = y - scaledRadius;
  ctx.beginPath();
  ctx.arc(_x + 0.55 * scaledRadius, _y + scaledRadius, (radius + 0.5) * (1 / mapZoom), 0, 2 * Math.PI, true);
  ctx.fillStyle = style.bgColor || 'green';
  ctx.fill();
  ctx.closePath();
  ctx.beginPath();
  ctx.moveTo(_x + scaledRadius, _y);
  ctx.lineTo(_x, _y + scaledRadius);
  ctx.lineTo(_x + 0.75 * scaledRadius, _y + scaledRadius);
  ctx.moveTo(_x + 0.5 * scaledRadius, _y + scaledRadius);
  ctx.lineTo(_x + 0.25 * scaledRadius, _y + 2 * scaledRadius);
  ctx.lineTo(_x + 1.25 * scaledRadius, _y + scaledRadius);
  ctx.lineTo(_x + 0.25 * scaledRadius, _y + scaledRadius);
  ctx.fillStyle = style.markColor || 'white';
  ctx.fill();
  ctx.closePath();
};

/**
 * Draws triangle warning badge icon
 * @param options {Object} - configuration for drawing
 * @param options.ctx {CanvasRenderingContext2D} - the canvas context on which to draw
 * @param options.radius {number} - radius in which to draw the badge
 * @param options.mapZoom {number} - current map zoom level
 * @param options.x {number} - x coordinate for the badge center
 * @param options.y {number} - y coordinate for the badge center
 * @param options.style {Object} - visual configuration
 * @param options.style.bgColor {string} - mark background color
 * @param options.style.markColor {string} - mark text color
 */
export const drawWarningMark = ({ ctx, radius, mapZoom, x, y, style }) => {
  const scaledRadius = radius * (1 / mapZoom);
  const _x = x - scaledRadius;
  const _y = y - scaledRadius * 0.6;
  ctx.lineJoin = 'round';
  ctx.lineWidth = 0.6 * scaledRadius;
  ctx.globalAlpha = 1;

  ctx.beginPath();
  ctx.moveTo(_x - scaledRadius, _y + scaledRadius);
  ctx.lineTo(_x, _y - scaledRadius);
  ctx.lineTo(_x + scaledRadius, _y + scaledRadius);
  ctx.closePath();

  ctx.fillStyle = style.bgColor || 'red';
  ctx.fill();
  ctx.strokeStyle = style.bgColor || 'red';
  ctx.stroke();

  ctx.beginPath();
  ctx.font = `${scaledRadius * 2}px Arial`;
  ctx.fillStyle = style.markColor || 'white';
  ctx.textAlign = 'center';
  ctx.fillText('!', _x, _y + 0.85 * scaledRadius);
  ctx.closePath();
};

/**
 * Draws X icon
 * @param options {Object} - configuration for drawing
 * @param options.ctx {CanvasRenderingContext2D} - the canvas context on which to draw
 * @param options.radius {number} - radius in which to draw the badge
 * @param options.mapZoom {number} - current map zoom level
 * @param options.x {number} - x coordinate for the badge center
 * @param options.y {number} - y coordinate for the badge center
 */
export const drawXMark = ({ ctx, radius, mapZoom, x, y }) => {
  ctx.beginPath();
  ctx.arc(x, y, radius * (1 / mapZoom), 0, Math.PI * 2, true);
  ctx.fillStyle = getStyleVar('--overlayShade2');
  ctx.fill();
  ctx.closePath();
  ctx.beginPath();
  ctx.fillStyle = getStyleVar('--errorColor');
  const transformationMatrix = new DOMMatrixReadOnly().scale(1 / mapZoom, 1 / mapZoom).translate(mapZoom * x, mapZoom * y);
  const p = new Path2D(X_MARK_PATH);
  const scaledPath = new Path2D();
  scaledPath.addPath(p, transformationMatrix);
  ctx.fill(scaledPath);
  ctx.closePath();
};

export const drawArrow = ({ ctx, from, to, scaledRadius, radius, mapZoom, color, angle }) => {
  scaledRadius = scaledRadius || radius / mapZoom;
  const _angle = !isNaN(angle) ? angle : getAngleBetweenPointsInRadians(from, to);
  const anchorsAngle = Math.PI / 5;
  const arrowHeadSize = scaledRadius / 1.4;
  const startPointDistance = 1.4 * scaledRadius;
  const endPointDistance = 1.7 * scaledRadius;
  let startPoint, endPoint, leftAnchor, rightAnchor;

  startPoint = {
    x: from.x - startPointDistance * Math.cos(_angle - Math.PI),
    y: from.y + startPointDistance * Math.sin(_angle - Math.PI)
  };

  endPoint = {
    x: from.x - endPointDistance * Math.cos(_angle - Math.PI),
    y: from.y + endPointDistance * Math.sin(_angle - Math.PI)
  };

  leftAnchor = {
    x: endPoint.x + arrowHeadSize * Math.cos(anchorsAngle - _angle - Math.PI),
    y: endPoint.y + arrowHeadSize * Math.sin(anchorsAngle - _angle - Math.PI)
  };
  rightAnchor = {
    x: endPoint.x + arrowHeadSize * Math.cos(anchorsAngle + _angle - Math.PI),
    y: endPoint.y - arrowHeadSize * Math.sin(anchorsAngle + _angle - Math.PI)
  };

  ctx.beginPath();
  ctx.moveTo(startPoint.x, startPoint.y);
  ctx.lineTo(leftAnchor.x, leftAnchor.y);
  ctx.lineTo(endPoint.x, endPoint.y);
  ctx.lineTo(rightAnchor.x, rightAnchor.y);
  ctx.lineTo(startPoint.x, startPoint.y);

  ctx.fillStyle = color;
  ctx.lineWidth = 1;
  ctx.fill();
  ctx.closePath();
};
