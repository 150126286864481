import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 -3 19 19",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "m 0.771 7.359 c 0.753 0.336 1.28 0.226 1.778 -0.296 l 12.675 3.555 l -0.811 -1.158 l -11.546 -3.051 c 0.035 -0.197 0.052 -0.295 -0.012 -0.51 l 7.762 -1.982 c 0.786 0.385 0.872 0.413 0.959 0.428 c 0.274 0.048 0.557 0.043 0.829 -0.016 l 2.418 3.927 c -0.23 0.28 -0.387 0.612 -0.455 0.974 c -0.207 1.176 0.576 2.295 1.752 2.502 c 1.176 0.207 2.295 -0.576 2.502 -1.752 c 0.197 -1.163 -0.498 -1.997 -1.251 -2.333 c -0.506 -0.214 -0.925 -0.244 -1.323 -0.152 l -2.417 -3.926 c 0.229 -0.28 0.383 -0.618 0.45 -0.975 c 0.211 -1.175 -0.572 -2.298 -1.743 -2.509 c -1.175 -0.211 -2.298 0.572 -2.509 1.743 c -0.097 0.551 0.02 1.126 0.137 1.162 l -7.304 2.266 c -0.585 -0.712 -1.587 -0.788 -2.201 -0.185 c -0.116 0.081 -0.365 0.342 -0.405 1.147 c 0.093 0.626 0.255 0.857 0.713 1.141 z" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }