import axios from 'axios';
import LocalStorageManager from '../LocalStorageManager';
import { useContextStore } from '../../store/ContextStore';

const DEFAULT_HEADERS = {
  'content-type': 'application/json'
};

const getAccessTokenHeader = () => {
  const accessTokenHeader = {};
  const accessToken = LocalStorageManager.getItem('AccessToken');

  if (accessToken) {
    accessTokenHeader['Access-Token'] = accessToken;
  }

  return accessTokenHeader;
};

const getTechnicianModeHeader = () => {
  const technicianModeHeader = {};
  const contextStore = useContextStore();
  if (contextStore.isTechnician) {
    technicianModeHeader['Technician-Mode'] = 'true';
  }
  return technicianModeHeader;
};

const resolvePath = path => {
  const contextStore = useContextStore();
  const accountId = contextStore.accountId;
  const zoneIdInContext = contextStore.zoneId;
  const serverEndpoint = import.meta.env.VITE_DEFAULT_SERVER;
  const concatPath = `//${serverEndpoint}/api${path}`;
  return concatPath.replace('{accountId}', accountId).replace('{zoneId}', zoneIdInContext);
};

const apiRequest = async ({ path, method = 'GET', data, params, headers = {}, ignoreActivity = false, responseType = 'json' }) => {
  try {
    const response = await axios({
      url: resolvePath(path),
      method,
      responseType,
      data: data || {},
      params: params,
      headers: Object.assign({}, DEFAULT_HEADERS, getAccessTokenHeader(), getTechnicianModeHeader(), headers),
      ignoreActivity
    });
    return response;
  } catch (error) {
    let msg;
    if (
      error?.request?.responseType === 'blob' &&
      error?.response?.data instanceof Blob &&
      error?.response?.data?.type &&
      error.response.data.type.toLowerCase().indexOf('json') != -1
    ) {
      msg = new Error(JSON.parse(await error.response.data.text()).message);
      msg.status = error.response.status;
    } else {
      msg =
        (error?.response && {
          ...error.response?.data,
          status: error.response?.status
        }) ||
        error;
    }
    return Promise.reject(msg);
  }
};

export default apiRequest;
