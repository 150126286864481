<template>
  <div class="missions-view">
    <div class="panels" :class="[$route.name == PAGES.SCHEDULE ? 'schedule' : 'mission-template']">
      <div class="panels-tabs-switcher">
        <router-link
          v-for="tab in tabs"
          :key="`tab-${tab.name}`"
          :title="tab.meta?.title"
          :class="['tab-button', { selected: $route.name === tab.name }]"
          :to="{ name: tab.name }"
        >
          <component :is="tab.icon" class="icon" />
          {{ tab.meta.title }}
        </router-link>
      </div>
      <router-view
        :class="[$route.name == PAGES.SCHEDULE ? 'scheduler' : 'mission-templates-list']"
        @mission-template-selected="onMissionTemplateSelected"
        @tile-select="selectedTileId = $event"
        @launch-mission-template="onLaunchMissionTemplate"
        @delete="onDeleteMissionTemplate"
      />
    </div>
    <MapCanvas v-if="zoneId" ref="zoneMap" class="map" :indicators="indicators" :is-quick-action-menu-enabled="true" />
  </div>
</template>

<script>
import MissionTemplatesPanel from './MissionTemplatesPanel.vue';
import SchedulerPanel from './SchedulerPanel.vue';
import { MAP_MODE } from '../../consts/mapConsts';
import { getDisplayConfigForMap } from '../../utils/models/DeviceUtils';
import { useContextStore } from '../../store/ContextStore';
import { useMissionTemplatesStore } from '../../store/MissionTemplatesStore';
import { useDevicesStore } from '../../store/DevicesStore';
import { useAreasStore } from '../../store/AreasStore';
import { useMapStore } from '../../store/MapStore';
import { useEventListStore } from '../../store/EventListStore';
import { mapState, mapActions, mapStores } from 'pinia';
import MapCanvas from '../../components/theMap/MapCanvas.vue';
import { SNACKBAR_TYPE } from '../../consts/appConsts';
import { shallowRef } from 'vue';
import { useZonesStore } from '../../store/ZonesStore';
import { PAGES, ROUTES_MAP } from '../../router';

export default {
  name: 'MissionsView',
  components: {
    MapCanvas,
    MissionTemplatesPanel,
    SchedulerPanel
  },
  inject: ['mq'],
  beforeRouteUpdate(to, from) {
    this?.$refs?.zoneMap?.resizeMap?.();
    this.updateMapMode(to?.meta?.initialMapMode);
    this.setContextMissionTemplate(null);
  },
  data() {
    return {
      PAGES,
      tabs: shallowRef(ROUTES_MAP[PAGES.MISSIONS].children),
      selectedTileId: null
    };
  },
  computed: {
    ...mapStores(useContextStore, useZonesStore),
    ...mapState(useContextStore, ['zoneId', 'zone', 'hasPrivilege', 'missionTemplate', 'isTechnician']),
    ...mapState(useDevicesStore, ['contextZoneActiveTiles', 'getDeviceById']),
    ...mapState(useAreasStore, ['activeNoFlightAreas']),
    indicators() {
      const icons = [];
      this.contextZoneActiveTiles.forEach(tile => {
        if (tile.location) {
          icons.push(getDisplayConfigForMap({ device: tile, clickable: true, showSelectionMark: tile.id === this.selectedTileId }));
        }
      });
      return {
        areas: this.activeNoFlightAreas.map(area => ({
          ...area,
          isInBackground: true
        })),
        icons: icons
      };
    }
  },
  watch: {
    zoneId: {
      handler: async function onZoneChange() {
        await this.fetchMissionTemplates();
      },
      immediate: true
    },
    missionTemplate: {
      handler: function onContextMissionTemplateChange(newVal, oldVal) {
        if (oldVal && !newVal) {
          this.selectedTileId = null;
        }
      }
    }
  },
  methods: {
    ...mapActions(useMissionTemplatesStore, ['fetchMissionTemplates', 'deleteMissionTemplate']),
    ...mapActions(useMapStore, ['updateMissionTemplateDraft', 'updateMapMode', 'resetMapMode']),
    ...mapActions(useEventListStore, ['invokeMission']),
    ...mapActions(useContextStore, ['setContextMissionTemplate']),
    isTabAvailable(tab) {
      return !tab.privileges || tab.privileges.every(privilege => this.contextStore.hasPrivilege(privilege));
    },
    onMissionTemplateSelected(missionTemplate) {
      this.updateMapMode(MAP_MODE.MISSION_TEMPLATE_VIEW);
      this.setContextMissionTemplate(missionTemplate);
      this.updateMissionTemplateDraft(
        missionTemplate?.pois?.map?.((poi, index) => ({
          ...poi,
          configBadge: this.isTechnician && !!missionTemplate.poi_detection_settings?.[index]
        }))
      );
      this.selectedTileId = missionTemplate?.destination_tile_id;
    },
    onDeleteMissionTemplate(missionTemplate) {
      this.contextStore.$patch(state => {
        state.showConfirmationDialog = true;
        state.confirmationDialogData.title = 'Delete mission plan';
        state.confirmationDialogData.text = 'Are you sure you want to delete this mission plan?';
        state.confirmationDialogData.confirmText = 'Delete';
        state.confirmationDialogData.callback = async isConfirmed => {
          if (isConfirmed) {
            try {
              await this.deleteMissionTemplate(missionTemplate.id);
              this.contextStore.showSnackbar({
                message: 'The Mission was deleted successfully',
                type: SNACKBAR_TYPE.SUCCESS
              });

              if (this.missionTemplate?.id === missionTemplate.id) {
                this.resetMapMode();
              }
            } catch (err) {
              console.error(err);
              this.contextStore.showSnackbar({
                message: 'Failed to delete the mission, please try again later',
                type: SNACKBAR_TYPE.ERROR
              });
            }
          }
        };
      });
    },
    async onLaunchMissionTemplate(missionTemplate) {
      this.contextStore.$patch(state => {
        state.showMissionDialog = true;
        state.missionDialogData.type_ids = missionTemplate.compatible_device_types;
        state.missionDialogData.text = 'Would you like to execute the mission?';
        state.missionDialogData.callback = async ({ isConfirmed, deviceId }) => {
          if (isConfirmed) {
            try {
              const missionObj = {
                zone_id: missionTemplate.zone_id,
                type: 'GOTO',
                template_id: missionTemplate.id,
                template_revision: missionTemplate.revision,
                device_id: deviceId
              };
              const mission = await this.invokeMission(missionObj);
              this.contextStore.showSnackbar({
                message: 'The mission was started successfully',
                type: SNACKBAR_TYPE.SUCCESS
              });
              this.$router.push({
                name: PAGES.MISSION,
                params: {
                  missionId: mission.id
                }
              });
            } catch (e) {
              console.error(e);
              this.contextStore.showSnackbar({
                message: 'Failed to start the mission',
                type: SNACKBAR_TYPE.ERROR
              });
            }
          }
        };
      });
    }
  }
};
</script>

<style scoped lang="scss">
.missions-view {
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  .panels {
    flex-basis: 35rem;
    transition: 0.3s;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    .panels-tabs-switcher {
      display: flex;
      column-gap: 1.2rem;
      padding: 0.6rem 0.6rem;
      border-bottom: 1px solid var(--primaryColorBrightShade1);

      .tab-button {
        cursor: pointer;
        display: flex;
        column-gap: 0.5rem;
        align-items: center;
        text-decoration: none;
        color: inherit;

        &.selected {
          color: var(--highlightColor);
          background: linear-gradient(0deg, var(--overlayColor1), transparent);
        }

        .icon {
          width: 1.5rem;
          height: 1.5rem;
          flex-shrink: 0;
        }
      }
    }

    &.schedule {
      flex-basis: 50%;
    }

    .mission-templates-list {
      flex-grow: 1;
    }
  }

  .map {
    flex-grow: 1;
  }
}
</style>
