import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "-8 -8 235 235",
  role: "icon",
  fill: "currentColor"
}
const _hoisted_2 = { fill: "#FFFF" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("g", null, [
      _cache[1] || (_cache[1] = _createElementVNode("circle", {
        cx: "107",
        cy: "107",
        r: "115",
        fill: "#F24535"
      }, null, -1)),
      _createElementVNode("g", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("path", {
          d: "M-.3884 19.0306M-6.6779-10.5515 6.7437-10.5439 6.7437 12.659-6.7597 12.659-5.2383 11.1378 5.2222 11.1378 5.2222-9.0223-5.2383-9.0223-5.2383 11.1378-6.7597 12.659-6.7597-10.5439M7.8848 13.0397 7.8848-11.685-7.901-11.685-7.901 13.0397-9.4223 13.0397-9.4223-13.2066 9.4064-13.2066 9.4064 13.0397M-3.9069-.2738-1.054-.2738A.1903.1903 90 01-1.054.8674L-3.3362.8674-3.3362 1.6282A.1903.1903 90 01-4.4774 1.6282L-4.4774.2969Q-4.4774-.2738-3.9069-.2738",
          transform: "scale(6, 6) translate(18 17.5)"
        }, null, -1)),
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}
export default { render: render }