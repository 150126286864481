<template>
  <div class="events-log">
    <div ref="eventsLogHeader" class="events-log-header">
      <div>
        <span class="events-log-header-text">Event Log</span>
        <div class="events-log-header-time-range">Time range: {{ eventsLogTimeRangeLabel }}</div>
      </div>
      <div class="header-buttons">
        <BaseSignButton v-if="isTechnician" title="Resolve all items in this zone" class="resolve-all-button" @click="onResolveAllIconClick">
          Resolve All
          <template #sign>
            <span>✓</span>
          </template>
        </BaseSignButton>
        <div v-if="eventListItems.length" class="icon-container">
          <component :is="sortIcon" class="icon sort-icon" @click="onSortIconClick" />
        </div>
        <baseMenu ref="filterMenu" :position="[menuDirectionX, menuDirectionY]">
          <template #toggle>
            <div class="icon-container">
              <IconFilter ref="filterIcon" class="icon" :style="{ color: filterIconColor }"><title>Filter</title></IconFilter>
            </div>
          </template>
          <template #content>
            <EventsLogFilterMenu
              ref="EventsLogFilterMenu"
              @update-filter-by-location="onUpdateLocationByFilter"
              @filter="onFilterClick"
              @reset="onFilterResetClick"
              @cancel="onFilterCancelClick"
            />
          </template>
        </baseMenu>
        <div class="icon-container">
          <IconExport v-if="hasPrivilegeToExportEventList && !isEventListExportLoading" class="icon export-icon" @click="onExportIconClick"
            ><title>Export</title></IconExport
          >
          <div v-if="isEventListExportLoading" class="export-loader">
            <BaseLoader />
          </div>
        </div>
      </div>
    </div>
    <div ref="eventsLogList" class="events-log-list">
      <div class="sticky-header">
        <BasePaginationBar
          v-if="eventListCount > eventsPerPage"
          v-model="currentPage"
          :selected-page="currentPage"
          :items-count="eventListCount"
          :current-page-items-count="eventListItems.length"
          :items-per-page="eventsPerPage"
        />
        <div class="separator" />
      </div>
      <div v-if="isLoading" class="loading-log">
        <BaseLoader />
      </div>
      <div v-else-if="groupIds.length === 0" class="empty-log">No Events Detected</div>
      <EventsLogGroup
        v-for="groupId in groupIds"
        v-else
        :key="groupId"
        :selected="selected"
        :title="getEventsLogGroup(groupId).name"
        :events="getEventsLogGroup(groupId).events"
        @select="onEventSelect"
      >
      </EventsLogGroup>
    </div>
  </div>
</template>

<script>
import IconFilter from '../../components/icons/IconFilter.svg?component';
import IconSortAsc from '../../components/icons/IconSortAsc.svg?component';
import IconSortDesc from '../../components/icons/IconSortDesc.svg?component';
import IconExport from '../../components/icons/IconExport.svg?component';
import EventsLogFilterMenu from './EventsLogFilterMenu.vue';
import EventsLogGroup from './EventsLogGroup.vue';
import BaseLoader from '../../components/base/BaseLoader.vue';
import BasePaginationBar from '../../components/base/BasePaginationBar.vue';
import BaseSignButton from '../../components/base/BaseSignButton.vue';
import { EVENT_LIST_TIME_RANGE } from '../../consts/eventListConsts';
import { SORT_MODE, FETCH_STATE, SNACKBAR_TYPE } from '../../consts/appConsts';
import { useContextStore } from '../../store/ContextStore';
import { useEventListStore } from '../../store/EventListStore';
import { mapActions, mapState, mapStores } from 'pinia';
import { getStyleVar } from '../../utils/StyleUtils';
import { getDate } from '../../utils/DateUtils';
import BaseMenu, { MENU_POSITION_OPTIONS } from '../../components/base/BaseMenu.vue';
import eventListService from '../../services/api/eventListService';
import { PRIVILEGES } from '../../consts/authConsts';
import { downloadFile } from '../../utils/browserUtils';

export default {
  name: 'EventsLog',
  components: {
    EventsLogFilterMenu,
    EventsLogGroup,
    BaseLoader,
    BaseSignButton,
    BasePaginationBar,
    IconFilter,
    IconSortAsc,
    IconSortDesc,
    IconExport,
    BaseMenu
  },
  props: {
    selected: {
      type: Object,
      default: null
    }
  },
  emits: ['filter-popup-location', 'select'],
  data() {
    return {
      currentPage: 1,
      menuDirectionX: MENU_POSITION_OPTIONS.RIGHT,
      menuDirectionY: MENU_POSITION_OPTIONS.BOTTOM,
      isEventListExportLoading: false
    };
  },
  computed: {
    ...mapStores(useContextStore),
    ...mapState(useContextStore, ['isTechnician']),
    ...mapState(useEventListStore, [
      'eventListFetchState',
      'eventListItems',
      'isListFiltered',
      'listFilters',
      'groupBy',
      'sortMode',
      'eventsPerPage',
      'eventListCount',
      'eventListOffset'
    ]),
    isLoading() {
      return this.eventListFetchState === FETCH_STATE.LOADING || this.eventListFetchState === FETCH_STATE.INITIAL;
    },
    filterIconColor() {
      return this.isListFiltered || this.$refs?.filterMenu?.isOpen ? getStyleVar('--highlightColor') : getStyleVar('-textColor');
    },
    eventsLogTimeRangeLabel() {
      const timeRange = this.listFilters.timeRange;
      switch (timeRange.type) {
        case EVENT_LIST_TIME_RANGE.LAST_24_HOURS:
          return 'Last 24 hours';
        case EVENT_LIST_TIME_RANGE.LAST_7_DAYS:
          return 'Last 7 days';
        case EVENT_LIST_TIME_RANGE.CUSTOM: {
          const from = new Date(timeRange.from);
          const to = new Date(timeRange.to);
          return `${getDate(from)} - ${getDate(to)}`;
        }
        default:
          return '';
      }
    },
    groupIds() {
      return Object.keys(this.eventsLogGroupsMap).map(id => Number(id));
    },
    eventsLogGroupsMap() {
      const groupsMap = {};
      this.eventListItems.forEach(eventListItem => {
        const group = this.getRelevantGroupForEvent(eventListItem);
        if (!groupsMap[group.id]) {
          groupsMap[group.id] = {
            ...group,
            events: []
          };
        }
        groupsMap[group.id].events.push(eventListItem);
      });
      // Sort groups events by date
      Object.values(groupsMap).forEach(group => {
        group.events.sort((a, b) => {
          const a_ts = a.timestamp || new Date(a.requested_timestamp).getTime();
          const b_ts = b.timestamp || new Date(b.requested_timestamp).getTime();
          return this.sortMode === SORT_MODE.ASC ? a_ts - b_ts : b_ts - a_ts;
        });
      });
      return groupsMap;
    },
    sortIcon() {
      return this.sortMode === SORT_MODE.ASC ? IconSortAsc : IconSortDesc;
    },
    eventListFiltersLocation() {
      return this.listFilters.location;
    },
    hasPrivilegeToExportEventList() {
      return this.contextStore.hasPrivilege(PRIVILEGES.EXPORT_EVENT_LIST);
    }
  },
  watch: {
    eventListFiltersLocation() {
      if (this.$refs?.filterMenu?.isOpen) {
        this.onFilterCancelClick();
      }
    },
    async eventListCount(newVal, oldVal) {
      if (newVal < oldVal && this.eventListItems.length < this.eventsPerPage && this.eventListItems.length < this.eventListCount) {
        // If the current page got smaller (item was resolved) fetch the next item
        await this.fetchNextItem(this.eventListOffset + this.eventsPerPage);
      }
    },
    currentPage(newVal) {
      this.fetchEventsPage(newVal);
    }
  },
  methods: {
    ...mapActions(useContextStore, ['showSnackbar']),
    ...mapActions(useEventListStore, [
      'fetchEventList',
      'fetchNextItem',
      'updateFilters',
      'resetFilters',
      'updateSortMode',
      'resolveAllItems',
      'exportEventList'
    ]),
    getEventsLogGroup(groupId) {
      return this.eventsLogGroupsMap[groupId];
    },
    onResolveAllIconClick() {
      this.contextStore.$patch(state => {
        state.showConfirmationDialog = true;
        state.confirmationDialogData.title = 'Resolve All';
        state.confirmationDialogData.text = `Are you sure you want to resolve all missions and events in this zone?`;
        state.confirmationDialogData.confirmText = 'Resolve All';
        state.confirmationDialogData.callback = async isConfirmed => {
          if (isConfirmed) {
            const { affected_items } = await eventListService.resolveAllItems();
            this.showSnackbar({
              message: `${affected_items} items were closed.`,
              type: SNACKBAR_TYPE.SUCCESS
            });
            this.fetchEventsPage();
          }
        };
      });
    },
    onSortIconClick() {
      this.updateSortMode(this.sortMode === SORT_MODE.ASC ? SORT_MODE.DESC : SORT_MODE.ASC);
      this.fetchEventsPage();
    },
    onFilterIconClick() {
      this.$refs.filterMenu.openClose();
    },
    onFilterClick(filters) {
      this.$refs.filterMenu.openClose();
      this.updateFilters(filters);
      this.fetchEventsPage();
      this.$emit('filter-popup-location', null);
    },
    onFilterResetClick() {
      this.$refs.filterMenu.openClose();
      this.fetchEventsPage();
      this.$emit('filter-popup-location', null);
    },
    onFilterCancelClick() {
      this.$refs.filterMenu.openClose();
      this.$emit('filter-popup-location', null);
    },
    getRelevantGroupForEvent(eventListItem) {
      const eventGroup = {};
      const eventTimestamp = eventListItem.start_timestamp || eventListItem.requested_timestamp || eventListItem.timestamp;
      const date = new Date(eventTimestamp);
      const eventDay = date.getDate();
      const eventMonth = date.getMonth();
      const eventYear = date.getFullYear();
      const cleanDateObject = new Date(`${eventMonth + 1}/${eventDay}/${eventYear}`);
      const dateStr = `${getDate(date)}`;
      eventGroup.id = cleanDateObject.getTime();
      eventGroup.name = dateStr;

      return eventGroup;
    },
    onUpdateLocationByFilter(value) {
      this.updateFilters({ location: value });
      this.fetchEventsPage();
      this.$emit('filter-popup-location', value);
    },
    onEventSelect(event) {
      this.$emit('select', event);
    },
    async onExportIconClick() {
      try {
        this.isEventListExportLoading = true;
        const exportData = await this.exportEventList();
        downloadFile(exportData, `Control-Bridge Data ${new Date().toISOString()}.csv`, 'text/csv');
      } catch (err) {
        this.showSnackbar({
          message: err.message,
          type: SNACKBAR_TYPE.ERROR
        });
      } finally {
        this.isEventListExportLoading = false;
      }
    },
    async fetchEventsPage(pageNumber = 1) {
      const offset = pageNumber === 1 ? 0 : (pageNumber - 2) * this.eventsPerPage + this.eventListItems.length;
      try {
        await this.fetchEventList({ offset: offset });
      } catch (err) {
        this.showSnackbar({
          message: `Failed to fetch events data`,
          type: SNACKBAR_TYPE.ERROR
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.events-log {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  row-gap: 0.5rem;

  .sticky-header {
    top: 0;
    position: sticky;
    z-index: 1;
    background: var(--mainBackground);
  }

  .separator {
    border-bottom: 2px solid var(--tertiaryColor);
    box-shadow: 0px 2px 12px 3px var(--mainBackground);
  }

  .events-log-header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;

    .header-buttons {
      display: flex;
      column-gap: 16px;
      align-items: center;

      .icon-container {
        display: flex;
        float: right;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        color: var(--secondaryTextColor);

        .icon {
          width: 1.125rem;
          height: 1.125rem;
        }

        .sort-icon {
          color: var(--highlightColor);
        }

        .export-icon {
          height: 1.6rem;
          width: 1.6rem;
        }

        &.active {
          color: var(--highlightColor);
        }

        &:hover {
          filter: brightness(1.5);
        }
      }
    }

    .export-loader {
      float: right;
    }

    .events-log-header-text {
      font-size: 2rem;
      color: var(--textColor);
    }

    .events-log-header-time-range {
      color: var(--textColor);
      font-family: var(--font-family-secondary);
    }

    .resolve-all-button {
      width: 8rem;

      span {
        font-size: 1.5rem;
        font-weight: bold;
        color: var(--successColor);
      }
    }
  }

  .events-log-list {
    display: flex;
    flex-grow: 1;
    font-family: var(--font-family-secondary);
    overflow-y: auto;
    overflow-x: hidden;
    direction: rtl;
    flex-direction: column;

    .loading-log {
      position: sticky;
      top: 5rem;
      margin: auto;
    }

    .empty-log {
      color: var(--textColor);
      font-size: 1.2rem;
      margin: 1rem;
      padding: 1rem 0;
    }

    * {
      direction: ltr;
    }
  }
}
</style>
