<template>
  <div class="create-account-container">
    <div class="create-account-label">Create account</div>
    <div class="create-account-form">
      <BaseLoader v-if="isLoading" class="loader" />
      <template v-else-if="!accountWasCreated">
        <BaseTextInput v-model="newAccountName" placeholder="Account Name"></BaseTextInput>
        <BaseTextInput v-model="newAccountUser" placeholder="First User Email"></BaseTextInput>
        <div>
          Account Timezone:
          <BaseDropdown v-model="newAccountTimezone" placeholder="Account Timezone" class="timezone-dropdown" :options="TIMEZONES_OPTIONS" />
        </div>
        <div class="auth-type">
          Authentication Methods:
          <BaseCheckbox v-for="(authType, key) in authTypes" :key="key" v-model:checked="authType.checked">{{ authType.label }}</BaseCheckbox>
        </div>
      </template>
      <div v-else class="success-msg">
        <IconCheck class="check-icon" />
        <span>
          Account <b>{{ newAccountName }}</b> created successfully with id: <b>{{ newAccountId }}</b
          >.<br />
          Email was sent to <b>{{ newAccountUser }}</b>
        </span>
      </div>
      <div v-if="errorMsg" class="error-msg">{{ errorMsg }}</div>
      <div v-if="!isLoading" class="action-btns">
        <BaseCancelButton @click="$emit('back')">
          <div v-if="accountWasCreated" class="back-label"><IconBack class="back-icon" />Back to account selection</div></BaseCancelButton
        >
        <BaseButton v-if="!accountWasCreated" @click="onCreateAccountButtonClick"> Create Account </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
const TIMEZONES_OPTIONS = Intl.supportedValuesOf('timeZone').map(timezone => {
  return { id: timezone, label: timezone };
});
import BaseButton from '../../components/base/BaseButton.vue';
import BaseDropdown from '../../components/base/BaseDropdown.vue';
import BaseCheckbox from '../../components/base/BaseCheckbox.vue';
import BaseTextInput from '../../components/base/BaseTextInput.vue';
import BaseLoader from '../../components/base/BaseLoader.vue';
import { EMAIL_REGEX, ERROR_MESSAGES } from '../../consts/appConsts';
import { mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import BaseCancelButton from '../../components/base/BaseCancelButton.vue';
import IconBack from '../../components/icons/IconBack.svg?component';
import IconCheck from '../../components/icons/IconCheck.svg?component';

export default {
  name: 'LoginView',
  components: {
    BaseButton,
    BaseDropdown,
    BaseCheckbox,
    BaseTextInput,
    BaseCancelButton,
    BaseLoader,
    IconBack,
    IconCheck
  },
  props: {
    hasSessionExpired: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['back'],
  data() {
    return {
      TIMEZONES_OPTIONS,
      errorMsg: false,
      accountWasCreated: false,
      isLoading: false,
      form: {
        username: '',
        password: ''
      },
      newAccountName: '',
      newAccountUser: '',
      newAccountId: 0,
      newAccountTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      authTypes: {
        PASS: {
          checked: false,
          label: 'Username and password'
        },
        AZURE: {
          checked: false,
          label: 'Microsoft'
        },
        GOOGLE: {
          checked: false,
          label: 'Google'
        }
      }
    };
  },
  computed: {
    ...mapStores(useContextStore)
  },
  methods: {
    async onCreateAccountButtonClick() {
      if (!this.isLoading) {
        let authTypes = Object.keys(this.authTypes).filter(type => this.authTypes[type].checked);

        if (this.newAccountName.trim() === '' || this.newAccountUser === '') {
          this.errorMsg = ERROR_MESSAGES.REQUIRED_FIELDS;
          return;
        } else if (authTypes.length === 0) {
          this.errorMsg = 'Please select at least one authentication method';
          return;
        } else if (!EMAIL_REGEX.test(this.newAccountUser)) {
          this.errorMsg = ERROR_MESSAGES.INVALID_EMAIL;
          return;
        }

        try {
          this.isLoading = true;
          const response = await this.contextStore.createAccount({
            name: this.newAccountName,
            user: this.newAccountUser,
            timezone: this.newAccountTimezone,
            authTypes
          });

          this.contextStore.$patch(state => state.auth.possibleAccounts?.push?.(response));
          this.isLoading = false;
          this.errorMsg = '';
          this.newAccountId = response.id;
          this.accountWasCreated = true;
        } catch (e) {
          this.isLoading = false;
          this.errorMsg = e.message;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.create-account-container {
  max-width: 450px;
  margin: 0 auto;
  border: 2px solid var(--primaryColorBrightShade2);
  border-radius: 10px;

  .create-account-label {
    font-size: 1.2rem;
    background: var(--primaryColorBrightShade2);
    padding: 0.5rem;
  }

  .create-account-form {
    padding: 1rem 3rem 3rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    min-width: 16rem;
    min-height: 8rem;

    .auth-type {
      display: grid;
    }

    .loader {
      width: 3.5rem;
      height: 3.5rem;
      margin: auto;
    }
  }

  .error-msg {
    font-size: 0.9rem;
    color: var(--errorColor);
  }

  .action-btns {
    display: flex;
    justify-content: space-between;
  }

  .success-msg {
    font-size: 1.1rem;
    display: flex;
    column-gap: 1rem;
    align-items: self-start;

    .check-icon {
      height: 1.4rem;
      width: 1.4rem;
      border: 2px solid;
      border-radius: 50%;
      padding: 3px;
      flex-shrink: 0;
      transform: translateY(33%);
      color: var(--successColor);
    }
  }

  .back-label {
    display: flex;
    column-gap: 0.3rem;
    align-items: center;
    justify-content: center;

    .back-icon {
      width: 1.1rem;
      height: 1.1rem;
    }
  }
}
</style>
