import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 16 16",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", {
      d: "M2.203 16h11.594C15.012 16 16 15.049 16 13.879V3.307c0-1.17-.988-2.122-2.203-2.122h-.874V.593c0-.326-.277-.593-.615-.593-.339 0-.616.267-.616.593v.592H4.308V.593C4.308.267 4.03 0 3.692 0c-.338 0-.615.267-.615.593v.592h-.874C.988 1.185 0 2.136 0 3.307v10.572C0 15.049.988 16 2.203 16zM1.231 3.307c0-.516.437-.937.972-.937h.874v.593c0 .326.277.593.615.593.339 0 .616-.267.616-.593V2.37h7.384v.593c0 .326.277.593.616.593.338 0 .615-.267.615-.593V2.37h.874c.535 0 .972.421.972.937v1.73H1.231v-1.73zm0 2.915h13.538v7.657c0 .515-.437.936-.972.936H2.203c-.535 0-.972-.42-.972-.936V6.222z",
      transform: "translate(-727.000000, -1892.000000) translate(727.000000, 1892.000000)"
    }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }