<template>
  <canvas ref="videoCanvas" class="video-canvas"></canvas>
</template>

<script>
import { timestampToVideoTime, videoTimeToTimestamp } from '../../utils/VideoTimeUtils';
import { getStyleVar } from '../../utils/StyleUtils';

const THERMAL_INCIDENT_PAUSE_RELATIVE_TIME = 7000;
const DRAW_RANGE_MS = 100;
export default {
  name: 'VideoCanvas',
  props: {
    player: {
      type: Object,
      required: true
    },
    config: {
      type: Array,
      default: () => []
    },
    pauseAt: {
      type: Object,
      default: null
    },
    thermalDimensions: {
      type: Object,
      default: () => ({})
    },
    markConfig: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      firstStop: true,
      context: null,
      processedTimes: {},
      markTime: null,
      pauseAtTime: null,
      timeToObjectsMap: null,
      pauseAtOnVideo: null
    };
  },
  created() {
    window.addEventListener('resize', this.resizeCanvas);
    if (this.config.length) {
      this.calcTimestampsMap();
    }
    if (this.pauseAt && this.pauseAt.x !== undefined && this.pauseAt.y !== undefined) {
      const playerElement = this.player.el();
      const thermalToVideoRatio = playerElement.clientHeight / this.thermalDimensions.height;
      const thermalFeedOffsetOnVideoX = (playerElement.clientWidth - thermalToVideoRatio * this.thermalDimensions.width) / 2;

      this.pauseAtOnVideo = {
        x: thermalFeedOffsetOnVideoX + this.pauseAt.x * thermalToVideoRatio,
        y: this.pauseAt.y * thermalToVideoRatio
      };
    } else {
      this.pauseAtOnVideo = null;
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeCanvas);
  },
  mounted() {
    this.canvas = this.$refs.videoCanvas;
    this.context = this.canvas.getContext('2d');
    this.context.font = `15px ${getStyleVar('--font-family-secondary')}`;
    this.context.textBaseline = 'top';

    this.attachListeners();
  },
  methods: {
    drawObject({ x, y, width, height, strokeColor = getStyleVar('--boundBoxDefaultColor'), text, textColor = getStyleVar('--textColor') }) {
      this.context.strokeStyle = strokeColor;
      this.context.lineWidth = 2;
      this.context.strokeRect(x, y, width, height);
      const textWidth = this.context.measureText(text).width;
      const textHeight = parseInt(this.context.font, 10);
      this.context.fillStyle = strokeColor;
      this.context.fillRect(x - 1, y - textHeight - 1, textWidth + 2, textHeight + 1);
      this.context.fillStyle = textColor;
      this.context.fillText(text, x, y - 2);
    },
    processTimesForTimestamp(end, timestamp) {
      let left = 0;
      let right = end;
      while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        if (this.config[mid].timestamp > timestamp) {
          right = mid - 1;
        } else if (this.config[mid].timestamp < timestamp) {
          left = mid + 1;
        } else {
          this.processedTimes[timestamp] = {
            objects: this.timeToObjectsMap[this.config[mid].timestamp]
          };
          return this.processedTimes[timestamp];
        }
      }
    },
    getFrameItem(timestamp) {
      if (this.processedTimes[timestamp]) {
        return this.processedTimes[timestamp];
      }

      const end = this.config.length - 1;
      if (end < 0 || this.config[end].timestamp < timestamp || this.config[0].timestamp > timestamp) {
        return (this.processedTimes[timestamp] = { objects: [] });
      }

      // Binary search to find the correct time range
      this.processTimesForTimestamp(end, timestamp);

      // If a bound-box exists within the DRAW_RANGE_MS - draw it
      for (let offset = 0; offset < DRAW_RANGE_MS; offset++) {
        // Iterate over two possible directions: forward (1) and backward (-1).
        for (let direction of [1, -1]) {
          // Calculate the index by subtracting the offset, multiplied by the direction, from the timestamp.
          const index = Math.floor(timestamp - direction * offset);

          // If there's an entry in the timeToObjectsMap for this index...
          if (this.timeToObjectsMap[index]) {
            // ...then we've found the objects for this timestamp. Store them in processedTimes and return them.
            this.processedTimes[timestamp] = {
              objects: this.timeToObjectsMap[index]
            };
            return this.processedTimes[timestamp];
          }
        }
      }
    },
    drawCircle() {
      if (!this.pauseAtOnVideo) {
        return;
      }

      const innerRadius = this.canvas.width / 17.5;
      const outerRadius = innerRadius * 1.5;
      var gradient = this.context.createRadialGradient(
        this.pauseAtOnVideo.x,
        this.pauseAtOnVideo.y,
        innerRadius,
        this.pauseAtOnVideo.x,
        this.pauseAtOnVideo.y,
        outerRadius
      );

      gradient.addColorStop(0, 'rgba(255, 0, 0, 0.12)');
      gradient.addColorStop(0.2, 'rgba(255, 0, 0, 0.6)');
      gradient.addColorStop(0.6, 'transparent');

      // Fill with gradient
      this.context.fillStyle = gradient;
      this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    },
    getBoundBoxDrawingConfig(boundBox) {
      const playerWidth = this.player.el().clientWidth;
      const playerHeight = this.player.el().clientHeight;
      const from = { x: boundBox.startX * playerWidth, y: boundBox.startY * playerHeight };
      const to = { x: boundBox.endX * playerWidth, y: boundBox.endY * playerHeight };

      return {
        ...from,
        width: to.x - from.x,
        height: to.y - from.y
      };
    },
    calcFrame(force) {
      if (this.player.paused() && !force) {
        // Avoid drawing while buffering
        return;
      }

      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      const playerPositionMs = this.player.currentTime() * 1000;
      const playerWidth = this.player.el().clientWidth;
      const playerHeight = this.player.el().clientHeight;

      if (this.firstStop && this.pauseAt && this.pauseAtTime && playerPositionMs >= this.pauseAtTime && !this.player.seeking()) {
        this.player.pause();
        if (this.pauseAt.x !== undefined && this.pauseAt.y !== undefined) {
          this.drawCircle(playerPositionMs);
        } else if (this.pauseAt.BoundingBoxes) {
          this.pauseAt.BoundingBoxes.forEach(boundBox => {
            this.drawObject({
              x: boundBox.x * playerWidth,
              y: boundBox.y * playerHeight,
              width: boundBox.width * playerWidth,
              height: boundBox.height * playerHeight,
              text: this.pauseAt.name,
              strokeColor: getStyleVar('--highlightColor')
            });
          });
        }
        this.firstStop = false;
      }
      if (this.markConfig && this.markTime && Math.abs(playerPositionMs - this.markTime) < 500) {
        this.markConfig.BoundingBoxes.forEach(boundBox => {
          this.drawObject({
            ...this.getBoundBoxDrawingConfig(boundBox),
            text: this.markConfig.name,
            strokeColor: getStyleVar('--highlightColor')
          });
        });
      }
      let item;
      const segments = this.player.tech({ IWillNotUseThisInPlugins: true }).vhs.playlists?.media?.()?.segments;
      if (segments) {
        const currTimestamp = videoTimeToTimestamp({
          segments,
          videoTime: this.player.currentTime()
        });
        item = this.getFrameItem(currTimestamp);
      }

      if (item?.objects?.length) {
        item.objects.forEach(boundBox => {
          const drawConfig = {
            ...this.getBoundBoxDrawingConfig(boundBox),
            text: boundBox.class,
            strokeColor: boundBox.class === 'person' ? getStyleVar('--personEventColor') : undefined,
            textColor: boundBox.class === 'person' ? getStyleVar('--textColor') : undefined
          };
          this.drawObject(drawConfig);
        });
      }
    },
    resizeCanvas() {
      this.canvas.width = this.player.el().clientWidth;
      this.canvas.height = this.player.el().clientHeight;
      this.calcFrame();
    },
    calcMarkTime() {
      if (this.player && this.markConfig && this.markConfig.timestamp) {
        try {
          const segments = this.player.tech({ IWillNotUseThisInPlugins: true }).vhs.playlists.media().segments;
          return (
            timestampToVideoTime({
              segments,
              timestamp: this.markConfig.timestamp
            }) * 1000
          );
        } catch (e) {
          console.error(e);
        }
      }
      return null;
    },
    calcPauseAtTime() {
      if (this.player && this.pauseAt && this.pauseAt.timestamp) {
        try {
          const segments = this.player.tech({ IWillNotUseThisInPlugins: true }).vhs.playlists.media().segments;
          return (
            timestampToVideoTime({
              segments,
              timestamp: this.pauseAt.timestamp
            }) * 1000
          );
        } catch (e) {
          console.error(e);
        }
      }
      return THERMAL_INCIDENT_PAUSE_RELATIVE_TIME;
    },
    attachListeners() {
      this.player.on('seeking', () => {
        if (this.player.currentTime() * 1000 < this.pauseAtTime) {
          this.firstStop = true;
        }
        this.calcFrame(true);
      });

      this.player.on('ended', () => {
        this.firstStop = true;
      });

      this.player.on('playerresize', () => {
        this.resizeCanvas();
      });

      this.player.on('loadedmetadata', () => {
        this.resizeCanvas();
        this.markTime = this.calcMarkTime();
        this.pauseAtTime = this.calcPauseAtTime();
      });
    },
    calcTimestampsMap() {
      this.timeToObjectsMap = this.config.reduce((acc, { timestamp, detections }) => {
        acc[timestamp] = detections;
        return acc;
      }, {});
    }
  }
};
</script>

<style scoped lang="scss">
.video-canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}
</style>
