import { mapState } from 'pinia';
import { useContextStore } from '../../store/ContextStore';

export default {
  data() {
    return {
      azureLoginFailureMessage: 'Failed to authenticate using Microsoft'
    };
  },
  computed: {
    ...mapState(useContextStore, {
      azureRedirectResponse: store => store.auth.azureRedirect.response,
      azureAuthError: store => store.auth.azureRedirect.error,
      isAzureAuthBeingProcessed: store => store.auth.azureRedirect.isBeingProcessed
    })
  },
  watch: {
    azureRedirectResponse: {
      handler: function onAzureRedirectResponse(response) {
        if (response) {
          this.afterAzureSignIn(response);
        }
      },
      immediate: true
    },
    azureAuthError: {
      handler: function onAzureAuthFailed(error) {
        if (error) {
          console.error('Azure auth failed: ', error);
          (this.isLoading = false), (this.errorMsg = this.azureLoginFailureMessage);
        }
      },
      immediate: true
    },
    isAzureAuthBeingProcessed: {
      handler: function (newVal) {
        if (newVal) {
          this.isLoading = true;
        }
      },
      immediate: true
    }
  }
};
