export const flattenObj = (obj, parent, result = {}, fieldsToIgnore = []) => {
  for (let key in obj) {
    let propName = parent ? parent + '_' + key : key;
    if (fieldsToIgnore.includes(propName) && obj[key]) {
      result[propName] = obj[key];
    } else if (Array.isArray(obj[key]) && obj[key].length > 0) {
      result[propName] = obj[key].join(',');
    } else if (typeof obj[key] == 'object') {
      flattenObj(obj[key], propName, result);
    } else {
      result[propName] = obj[key];
    }
  }
  return result;
};

export const clone = o => {
  return JSON.parse(JSON.stringify(o));
};

export const equals = (o1 = {}, o2 = {}) => {
  return JSON.stringify(o1) === JSON.stringify(o2);
};
