<template>
  <vSelect
    v-model="val"
    class="searchbox"
    :options="availableOptions"
    :multiple="multiple"
    :select-on-tab="true"
    :filterable="true"
    :close-on-select="true"
    :no-drop="availableOptions.length === 0"
    @search="onSearch"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
// documentation: https://github.com/sagalbot/vue-select
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'BaseSearchBox',
  components: {
    vSelect
  },
  props: {
    modelValue: {
      type: [String, Number, Object],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    minCharsForSearch: {
      type: Number,
      default: 1
    }
  },
  emits: ['update:modelValue', 'blur'],
  data() {
    return {
      val: null,
      availableOptions: []
    };
  },
  watch: {
    val(newVal) {
      this.$emit('update:modelValue', newVal);
    }
  },
  created() {
    this.val = this.modelValue;
  },
  methods: {
    onSearch(search) {
      if (search.length >= this.minCharsForSearch) {
        this.availableOptions = this.options;
      } else {
        this.availableOptions = [];
      }
    }
  }
};
</script>
<style lang="scss">
.searchbox {
  width: 100%;
  border-radius: 7px;

  .vs__search::placeholder,
  .vs__dropdown-toggle,
  .vs__dropdown-menu {
    background: var(--primaryColor);
    caret-color: var(--highlightColor);
    font-family: var(--font-family-primary);
  }

  .vs__search::placeholder,
  .vs__dropdown-toggle {
    border-bottom: 0.2rem solid var(--primaryColorBrightShade1);
  }

  .vs__search {
    color: var(--textColor);
    font-family: var(--font-family-primary);
  }

  .vs__dropdown-toggle {
    border-radius: 7px;
    padding: 0.2rem;
  }

  .vs__dropdown-menu {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 2px solid var(--primaryColorBrightShade1);
    max-height: 15rem;
    overflow: hidden;
    padding: 0;
  }

  &.vs--single {
    .vs__selected {
      color: var(--textColor);
    }
  }

  .vs__selected {
    border-radius: 1rem !important;
    color: var(--textColor);
  }

  .vs__dropdown-option {
    color: var(--textColor);

    &.vs__dropdown-option--highlight {
      background: var(--primaryColorBrightShade1);
    }
  }

  .vs__clear,
  .vs__open-indicator {
    fill: var(--highlightColor);
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      filter: grayscale(1);
    }
  }
}
</style>
