<template>
  <div class="edit-integration-slack">
    <div class="property-container">
      <div class="label-container">Slack Webhook URL:</div>
      <div class="value-container">
        <BaseTextInput v-model="integrationData.data.webhook_url"></BaseTextInput>
      </div>
    </div>
    <div class="property-container">
      <div class="label-container">Channel Name:</div>
      <div class="value-container">
        <BaseTextInput v-model="integrationData.data.channel_name"></BaseTextInput>
        <div class="channel-name-note">
          You can use the following macros in your channel name:
          <template v-if="useZoneMacros">
            <div class="macro-container">
              <span class="macro">{zoneName}</span> - Zone name, lower cased, white spaces are replaced with '-' character
            </div>
            <div class="macro-container"><span class="macro">{zoneId}</span> - Zone ID, can be found on the any of the zone's pages URL</div>
          </template>
          <template v-else>
            <div class="macro-container">
              <span class="macro">{deviceName}</span> - Device name, lower cased, white spaces are replaced with '-' character
            </div>
            <div class="macro-container"><span class="macro">{deviceId}</span> - Device ID, can be found on the device page</div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { INTEGRATION_EVENT_TYPE } from '../../consts/integrationsConsts';
import BaseTextInput from '../../components/base/BaseTextInput.vue';
import { validateUrl } from '../../utils/ValidationUtils';
import { toRaw } from 'vue';

export default {
  name: 'EditIntegrationSlack',
  components: { BaseTextInput },
  props: {
    integration: {
      type: Object,
      required: true
    }
  },
  emits: ['update'],
  data() {
    return {
      integrationData: {}
    };
  },
  computed: {
    useZoneMacros() {
      return this.integrationData.event_type === INTEGRATION_EVENT_TYPE.HALT;
    },
    isSaveEnabled() {
      return validateUrl(this.integrationData.data.webhook_url) && this.integrationData.data.channel_name.trim() !== '';
    }
  },
  created() {
    this.integrationData = structuredClone(toRaw(this.integration));
    if (!this.integrationData.data.webhook_url) {
      this.integrationData.data.webhook_url = '';
    }
    if (!this.integrationData.data.channel_name) {
      this.integrationData.data.channel_name = '';
    }
  },
  mounted() {
    this.$emit('update');
  },
  updated() {
    this.$emit('update');
  },
  methods: {
    getDiffAttributes() {
      const origin = this.integration.data;
      const diff = {};
      Object.keys(this.integrationData.data).forEach(key => {
        if (JSON.stringify(this.integrationData.data[key]) !== JSON.stringify(origin[key])) {
          diff[key] = this.integrationData.data[key];
        }
      });
      return diff;
    }
  }
};
</script>

<style scoped lang="scss">
.edit-integration-slack {
  .property-container {
    margin-top: 20px;
    .label-container {
      color: var(--secondaryTextColor);
      margin-bottom: 5px;
    }
    .value-container {
      .channel-name-note {
        margin-top: 5px;
        font-size: 12px;
        color: var(--secondaryTextColor);
        .macro-container {
          margin-top: 3px;
          .macro {
            font-style: italic;
            user-select: text;
          }
        }
      }
      &.text-value {
        color: var(--secondaryTextColor);
      }
    }
  }
}
</style>
