<template>
  <div class="zone-card">
    <div class="image-wrapper" @click="$router.push({ path: `/zones/${zone.id}/events` })">
      <img :src="zone.thumbnail" :class="[theme]" />
    </div>
    <div class="zone-title">
      {{ zone.name }}
      <span v-if="zone.halted" class="halt-tag">Flights Halt</span>
      <BaseNotificationBadge v-if="zoneItems.length > 0" :count="zoneItems.length" style="margin-left: 5px" />
    </div>
    <div class="messages">
      <div v-if="topItems.length > 0" class="recent-incidents-container">
        <span class="title">Recent Events:</span>
        <div v-for="item in topItems" :key="item.id" class="recent-incident">
          <span class="incident-time">{{ itemTime(item) }}</span>
          <span class="incident-label" @click="onIncidentClick(item.id, item.event_type)">{{ itemLabel(item) }}</span>
        </div>
        <div v-for="item in moreItems" v-show="showMore" :key="item.id" class="recent-incident">
          <span class="incident-time">{{ itemTime(item) }}</span>
          <span class="incident-label" @click="onIncidentClick(item.id, item.event_type)">{{ itemLabel(item) }}</span>
        </div>
        <div v-if="moreItems.length > 0" class="more-incidents-container" @click="showMore = !showMore">
          {{ showMore ? 'hide' : moreItems.length }} more
          <div :class="['triangle', showMore ? 'opposite' : undefined]"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseNotificationBadge from '../../components/base/BaseNotificationBadge.vue';
import { mapState, mapActions } from 'pinia';
import { get24Time } from '../../utils/DateUtils';
import { EVENT_TYPE } from '../../consts/eventConsts';
import { useContextStore } from '../../store/ContextStore';
import { useDashboardStore } from '../../store/DashboardStore';
import { useZonesStore } from '../../store/ZonesStore';
import { getMissionStateLabel } from '../../utils/models/MissionUtils';
import { PAGES } from '../../router';

const TOP_ITEMS = 3;

export default {
  name: 'ZoneCard',
  components: { BaseNotificationBadge },
  props: {
    zoneId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showMore: false
    };
  },
  computed: {
    ...mapState(useContextStore, ['theme']),
    ...mapState(useZonesStore, ['getZoneById']),
    ...mapState(useDashboardStore, ['getDashboardEventListByZoneId']),
    zone() {
      return this.getZoneById(this.zoneId);
    },
    zoneItems() {
      return this.getDashboardEventListByZoneId(this.zoneId);
    },
    topItems() {
      return this.zoneItems.slice(0, TOP_ITEMS);
    },
    moreItems() {
      return this.zoneItems.length > TOP_ITEMS ? this.zoneItems.slice(TOP_ITEMS) : [];
    }
  },
  async created() {
    try {
      await this.fetchDashboardEventListByZoneId(this.zoneId);
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    itemTime(item) {
      return get24Time(new Date(item.timestamp || item.requested_timestamp));
    },
    itemLabel(item) {
      if (item.event_type === EVENT_TYPE.MISSION) {
        return item.name + ' ' + getMissionStateLabel(item);
      }
      return item.event_type.toLowerCase();
    },
    ...mapActions(useDashboardStore, ['fetchDashboardEventListByZoneId']),
    onIncidentClick(id, type) {
      if (type === EVENT_TYPE.MISSION) {
        this.$router.push({
          name: PAGES.MISSION,
          params: {
            zoneId: this.zoneId,
            missionId: id
          }
        });
      } else {
        this.$router.push({
          name: PAGES.EVENT,
          params: {
            zoneId: this.zoneId,
            eventId: id
          }
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.zone-card {
  transition: all 0.2s ease-in-out;
  position: relative;
  background: var(--primaryColor);
  padding: 1rem;

  .image-wrapper {
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    background: var(--secondaryColorShade2);
    border: 1px solid transparent;
    opacity: 0.7;

    img {
      transition: all 0.2s ease-in-out;
      height: 200px;
      width: 100%;
      object-fit: contain;
      display: block;
      margin: 0 auto;

      &.light {
        filter: invert(1) hue-rotate(250deg);
      }
    }

    &:hover {
      border: 1px solid;
      opacity: 1;

      img {
        transform: scale(2);
      }

      & ~ .zone-title {
        color: var(--textColor);
        font-size: 26px;
      }

      & ~ .recent-incidents-container {
        opacity: 0.2;
      }
    }
  }

  .zone-title {
    transition: all 0.2s ease-in-out;
    font-size: 20px;
    font-family: var(--font-family-secondary);
    color: var(--secondaryTextColor);
    margin: 0.5rem 0 1rem 0;
    font-weight: lighter;
    display: flex;
    column-gap: 0.7rem;
    align-items: baseline;
    height: 30px;

    .halt-tag {
      color: var(--errorColor);
      padding: 0.2rem 0.5rem;
      font-size: 1.1rem;
      border-radius: 20px;
      background: var(--mainBackground);
      font-weight: 400;
    }
  }

  .messages {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-content: flex-start;
    color: var(--secondaryTextColor);
    font-family: Hind;
    font-size: 14px;
    font-weight: lighter;

    .recent-incidents-container {
      max-height: 14vh;
      overflow-x: hidden;
      transition: all 0.2s ease-in-out;
      flex-basis: 50%;
      margin-right: 1.5rem;

      .recent-incident {
        &.unseen {
          color: var(--highlightColor);
        }

        .incident-time {
          margin-right: 1rem;
        }
        .incident-label {
          text-transform: capitalize;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }

    .more-incidents-container {
      transition: all 0.2s ease-in-out;
      margin-top: 0.5rem;
      cursor: pointer;
      font-size: 12px;
      color: var(--secondaryOverlayShade3);
      display: flex;

      .triangle {
        width: 0;
        height: 0;
        margin: 6px 0 0 0.5rem;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid;

        &.opposite {
          border-top: initial;
          border-bottom: 5px solid;
        }
      }

      &:hover {
        color: var(--textColor);

        .triangle {
          border-top-color: var(--textColor);

          &.opposite {
            border-top: initial;
            border-bottom-color: var(--textColor);
          }
        }
      }
    }

    .title {
      font-weight: bold;
    }
  }
}
</style>
