import { defineStore } from 'pinia';
import { useContextStore as contextStore } from './ContextStore';
import { FETCH_STATE } from '../consts/appConsts';
import { STATS_TIME_RANGE } from '../consts/statsConsts';
import { flattenObj } from '../utils/ObjectUtils';
import statsService from '../services/api/statsService';

const initialFilters = {
  zone: '',
  timeRange: {
    type: STATS_TIME_RANGE.LAST_24_HOURS,
    from: 0,
    to: 0
  }
};

export const useStatsStore = defineStore('stats', {
  state() {
    return {
      statsFetchState: FETCH_STATE.INITIAL,
      statsFilters: structuredClone(initialFilters),
      missions: {},
      events: [],
      zones: []
    };
  },
  getters: {
    isStatsFiltered() {
      return JSON.stringify(this.statsFilter) !== JSON.stringify(initialFilters);
    }
  },
  actions: {
    async fetchStats(params = {}) {
      try {
        if (!params.silent) {
          this.statsFetchState = FETCH_STATE.LOADING;
        }

        const query = flattenObj(this.statsFilters);
        query.measurementUnits = contextStore().measurementUnits;
        const data = await statsService.fetchAccountStats(query, params.silent);
        this.missions = data.missions;
        this.events = data.events;
        this.zones = data.zones;
        this.statsFetchState = FETCH_STATE.SUCCESS;
        return data;
      } catch (e) {
        this.statsFetchState = FETCH_STATE.ERROR;
        return Promise.reject(e);
      }
    },
    async sendStatsEmail() {
      try {
        const query = flattenObj(this.statsFilters);
        query.measurementUnits = contextStore().measurementUnits;
        const data = await statsService.sendAccountStatsToMail(query);
        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    updateStatsFilters(filters) {
      Object.entries(filters).forEach(([filterType, filterValue]) => {
        this.statsFilters[filterType] = filterValue;
      });
    },
    resetStatsFilters() {
      this.statsFilters = structuredClone(initialFilters);
    }
  }
});
