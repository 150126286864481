<template>
  <div class="event-details">
    <div class="header">
      <span class="text">{{ EVENT_TYPE_NAME[event.event_type] }}</span>
      <span class="action-btns">
        <BaseLoader v-if="isResolveLoading" class="resolve-loader" />
        <BaseSignButton v-else class="handled-button" @click="onHandledIconClick">
          {{ handledText }}
          <template #sign>
            <span :class="[event.handled ? 'unresolve' : 'resolve']">{{ handledIcon }}</span>
          </template>
        </BaseSignButton>
        <BaseShareButton
          class="action-btn"
          :title="EVENT_TYPE_NAME[event.event_type]"
          :text="`${EVENT_TYPE_NAME[event.event_type]} Detected`"
          :url="event.ui_url"
        />
        <IconClose class="action-btn" @click="$emit('close')"><title>Close</title></IconClose>
      </span>
    </div>
    <div class="event-metadata-container">
      <template v-for="item in metadata" :key="item.label">
        {{ item.label }}:
        <router-link v-if="item.linkTo" :to="item.linkTo" class="value clickable">{{ item.value }}</router-link>
        <span v-else class="value">
          {{ item.value }}
        </span>
      </template>
    </div>
    <div class="event-video-container">
      <BaseLoader v-if="videoPlayerStore.isFetchingConfig" class="loader" />
      <VideoSection
        v-else
        :video-download-path="event.custom_data?.s3_video_path"
        :has-error="isStreamsError"
        :single-cam-device="displayFrontCameraOnly"
        :ai-status="event.ai_status"
      />
    </div>
    <div :title="event.mission_id ? 'Rerun AI is possible only on the entire mission' : 'Rerun AI'">
      <BaseButton
        v-if="contextStore.isTechnician && event?.streamsData?.success"
        class="tech-ops-button"
        :disabled="isAiRerunLoading || event.mission_id ? true : false"
        @click="onAiRerunClick"
      >
        <BaseLoader v-if="isAiRerunLoading" />
        <span v-else>Rerun Analytics</span>
      </BaseButton>
    </div>

    <BaseButton v-if="contextStore.isTechnician && !event.technician_mode" class="tech-ops-button" @click="onMakeAvailableToTechniciansClick"
      >Make available to technicians only</BaseButton
    >
    <BaseButton v-if="contextStore.isTechnician && event.technician_mode" class="tech-ops-button" @click="onMakeAvailableToAllUsersClick"
      >Make available to all users
    </BaseButton>
  </div>
</template>

<script>
import VideoSection from '../../components/thePlayer/VideoSection.vue';
import BaseSignButton from '../../components/base/BaseSignButton.vue';
import BaseLoader from '../../components/base/BaseLoader.vue';
import BaseShareButton from '../../components/base/BaseShareButton.vue';
import { EVENT_TYPE_NAME, EVENT_TYPE, EVENT_SOURCE } from '../../consts/eventConsts';
import IconClose from '../../components/icons/IconClose.svg?component';
import BaseButton from '../../components/base/BaseButton.vue';
import { mapActions, mapState, mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import { DEVICES_TYPES } from '../../consts/deviceConsts';
import { getTimeStamp } from '../../utils/DateUtils';
import { convertCelsiusToFahrenheit } from '../../utils/UnitsUtils';
import { SNACKBAR_TYPE } from '../../consts/appConsts';
import { useEventListStore } from '../../store/EventListStore';
import eventsService from '../../services/api/eventsService';
import { PLAYER_EVENTS, useVideoPlayerStore } from '../../store/VideoPlayerStore';
import { PAGES } from '../../router';
import { downloadFile } from '../../utils/browserUtils';

export default {
  name: 'EventDetails',
  components: {
    VideoSection,
    BaseSignButton,
    BaseLoader,
    BaseShareButton,
    IconClose,
    BaseButton
  },
  emits: ['close'],
  data() {
    return {
      device: null,
      frontVideoFileFetchResult: null,
      rearVideoFileFetchResult: null,
      isResolveLoading: false,
      isAiRerunLoading: false,
      EVENT_TYPE_NAME,
      EVENT_TYPE
    };
  },
  computed: {
    ...mapStores(useContextStore, useVideoPlayerStore),
    ...mapState(useContextStore, ['event']),
    handledText() {
      return this.event.handled ? 'Unresolve' : 'Resolve';
    },
    handledIcon() {
      return this.event.handled ? 'X' : '✓';
    },
    displayFrontCameraOnly() {
      return !this.event?.streamsData?.rearCamStreamURL && [DEVICES_TYPES.TANDO_TILE, DEVICES_TYPES.SKYDIO].includes(this.event.device_type_id);
    },
    metadata() {
      let metadata = [
        {
          label: 'Device Name',
          value: this.event.device_name || this.event.device_id,
          linkTo: this.device
            ? {
                name: PAGES.DEVICE,
                params: {
                  deviceId: this.device.id
                }
              }
            : null
        },
        {
          label: 'Time',
          value: getTimeStamp(this.event.timestamp)
        }
      ];

      if (this.event.event_type === EVENT_TYPE.THERMAL) {
        const temperature = this.event.custom_data?.Temperature;
        metadata.push({
          label: 'Temperature',
          value: this.contextStore.user.temperature_scale === 'CELSIUS' ? `${temperature}ºC` : `${convertCelsiusToFahrenheit(temperature)}ºF`,
          condition: temperature
        });
      }

      if (this.event.event_type === EVENT_TYPE.DOOR) {
        metadata.push({
          label: 'Door Name',
          value: this.event.custom_data?.doorName,
          condition: this.event.custom_data?.doorName
        });
        metadata.push({
          label: 'Expected Door State',
          value: this.event.custom_data?.expectedDoorState,
          condition: this.event.custom_data?.expectedDoorState
        });
        metadata.push({
          label: 'Detected Door State',
          value: this.event.custom_data?.detectedDoorState,
          condition: this.event.custom_data?.detectedDoorState
        });
      }

      if (this.event?.mission?.name) {
        metadata.push({
          label: 'Mission',
          value: this.event?.mission?.name,
          linkTo: {
            name: PAGES.MISSION,
            params: {
              missionId: this.event?.mission?.id
            }
          }
        });
      }

      const handledBy = this.event?.handled_modified?.user_name;
      if (handledBy) {
        metadata.push({
          label: `${this.event.handled ? 'Closed' : 'Reopened'} by`,
          value: handledBy
        });
      }

      const description = this.event.custom_data?.description;
      if (description) {
        metadata.push({
          label: 'Description',
          value: description
        });
      }

      if (this.contextStore.isTechnician) {
        let sourceLabel = this.event.source;
        if (this.event.source === EVENT_SOURCE.MANUAL) {
          sourceLabel = `Manual (${this.event.originator_name})`;
        } else if (this.event.source === EVENT_SOURCE.EXTERNAL) {
          sourceLabel = '3rd party integration';
        }
        metadata.push({
          label: 'Source',
          value: sourceLabel
        });
      }

      return metadata.filter(item => item.condition !== false);
    },
    isStreamsError() {
      return !this.event.streamsData || Object.keys(this.event.streamsData).length === 0 || !this.event.streamsData.success;
    }
  },
  mounted() {
    this.videoPlayerStore.rear.eventBus.addEventListener(PLAYER_EVENTS.REQUEST_STREAM_FILE, this.onStreamVideoFileRequest);
    this.videoPlayerStore.front.eventBus.addEventListener(PLAYER_EVENTS.REQUEST_STREAM_FILE, this.onStreamVideoFileRequest);
  },
  beforeUnmount() {
    this.videoPlayerStore.rear.eventBus.removeEventListener(PLAYER_EVENTS.REQUEST_STREAM_FILE, this.onStreamVideoFileRequest);
    this.videoPlayerStore.front.eventBus.removeEventListener(PLAYER_EVENTS.REQUEST_STREAM_FILE, this.onStreamVideoFileRequest);
  },
  methods: {
    ...mapActions(useEventListStore, ['updateEventHandled', 'updateEventTechnicianMode']),
    async onHandledIconClick() {
      this.isResolveLoading = true;
      const newValue = !this.event.handled;
      try {
        await this.updateEventHandled({
          id: this.event.id,
          handled: newValue,
          skipFilter: true
        });
      } catch (err) {
        console.error(err);
        this.contextStore.showSnackbar({
          message: `Failed to ${this.event.handled ? 'unresolve' : 'resolve'} event`,
          type: SNACKBAR_TYPE.ERROR
        });
      }
      this.isResolveLoading = false;
    },
    handleMetadataClick(item) {
      if (item.linkTo) {
        this.$router.push({
          path: item.linkTo
        });
      }
    },
    async onMakeAvailableToTechniciansClick() {
      await this.updateEventTechnicianMode({
        id: this.event.id,
        technician_mode: true
      });
    },
    async onMakeAvailableToAllUsersClick() {
      await this.updateEventTechnicianMode({
        id: this.event.id,
        technician_mode: false
      });
    },
    async onAiRerunClick() {
      this.isAiRerunLoading = true;
      try {
        await eventsService.rerunEventVideoDetection(this.event.id);
        this.contextStore.showSnackbar({
          message: 'Event object detection rerun initiated successfully',
          type: SNACKBAR_TYPE.SUCCESS
        });
      } catch (err) {
        this.contextStore.showSnackbar({
          message: 'Unable to rerun event object detection',
          type: SNACKBAR_TYPE.ERROR
        });
      }
      this.isAiRerunLoading = false;
    },
    async onStreamVideoFileRequest(e) {
      const playerType = e.detail;
      const sourceType = this.event.streamsData.data[`${playerType}Source`];
      try {
        this.videoPlayerStore.$patch(state => {
          state[playerType].isDownloading = true;
        });
        const videoFileFetchResult = await eventsService.fetchStreamMp4File(this.event.id, playerType, sourceType);
        downloadFile(videoFileFetchResult, `${playerType}Cam.mp4`, 'video/mp4');
      } catch (err) {
        this.contextStore.showSnackbar({
          message: 'Unable to download video',
          type: SNACKBAR_TYPE.ERROR
        });

        console.error(err.message);
      } finally {
        this.videoPlayerStore.$patch(state => {
          state[playerType].isDownloading = false;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.event-details {
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  direction: rtl;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  color: var(--textColor);
  font-family: var(--font-family-secondary);

  * {
    direction: ltr;
  }

  .header {
    font-family: var(--font-family-primary);
    display: flex;
    justify-content: space-between;
    column-gap: 1.5rem;
    align-items: flex-start;

    .text {
      font-size: 2rem;

      .mission-state-icon {
        width: 2.5rem;
        height: 2.5rem;
        transform: translateY(15%);
      }
    }

    .action-btns {
      column-gap: 1.5rem;
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: center;

      .action-btn {
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;

        &.open-close-btn {
          color: var(--highlightColor);
        }

        &:hover {
          color: var(--textColor);
        }
      }

      .resolve-loader {
        width: 1rem;
        height: 1rem;
        margin: auto;
      }

      .handled-button {
        font-size: 1rem;
        width: 8rem;

        .resolve {
          font-size: 1.5rem;
          font-weight: bold;
          color: var(--successColor);
        }

        .unresolve {
          font-size: 1.5rem;
          color: var(--errorColor);
        }
      }
    }
  }

  .tech-ops-button {
    width: fit-content;
    align-self: flex-end;
  }

  .event-video-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    flex-basis: 20rem;

    .loader {
      margin: auto;
      width: 4rem;
      height: 4rem;
    }
  }

  .event-metadata-container {
    font-size: 1.2rem;
    word-break: break-word;
    white-space: normal;
    text-overflow: ellipsis;
    color: var(--textColor);
    display: grid;
    grid-template-columns: 8rem auto;
    column-gap: 0.5rem;
    row-gap: 0.2rem;

    .value {
      font-weight: 300;
      color: var(--secondaryTextColor);

      &.clickable {
        cursor: pointer;
        pointer-events: auto;

        &:hover {
          text-decoration: underline;
          text-decoration-color: var(--highlightColor);
        }
      }
    }
  }
}
</style>
