export const isChrome = () => {
  return !!window.chrome;
};

export const printPackageInfo = (name, version, color = '#45AEB1') => {
  console.log(`%c ${name} ${version}`, `color: ${color};  font-size: large`);
};

export function downloadFile(blobData, fileName, fileType) {
  const downloadElement = document.createElement('a');
  const newBlob = new Blob([blobData], { type: fileType });
  const href = window.URL.createObjectURL(newBlob);
  downloadElement.href = href;
  downloadElement.download = fileName;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
}

export const base64ToBlob = async base64 => {
  const res = await fetch(base64);
  return await res.blob();
};
