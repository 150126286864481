import { getDatesMMSSDiffStr } from '../../utils/DateUtils';
import missionsService from '../../services/api/missionsService.js';
import { mapStores } from 'pinia';
import { useDevicesStore } from '../../store/DevicesStore';
import { useContextStore } from '../../store/ContextStore';
import { useEventListStore } from '../../store/EventListStore';
import { SNACKBAR_TYPE } from '../../consts/appConsts';
const TIMER_UPDATE_RATE = 1000;

export default {
  props: {
    activeMission: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      now: new Date(),
      nowIntervalId: null
    };
  },
  created() {
    this.nowIntervalId = setInterval(() => {
      this.now = new Date();
    }, TIMER_UPDATE_RATE);
  },
  unmounted() {
    if (this.nowIntervalId) {
      clearInterval(this.nowIntervalId);
    }
  },
  computed: {
    ...mapStores(useDevicesStore, useContextStore, useEventListStore),
    executionClock() {
      return getDatesMMSSDiffStr(this.now, new Date(this.activeMission.start_timestamp || this.activeMission.requested_timestamp));
    }
  },
  methods: {
    async land() {
      const deviceId = this.activeMission.device_id;
      const deviceName = this.devicesStore.getDeviceById(deviceId).name;
      this.contextStore.$patch(state => {
        state.showConfirmationDialog = true;
        state.confirmationDialogData.title = 'Land Device confirmation';
        state.confirmationDialogData.text = `Are you sure you want to land ${deviceName}?`;
        state.confirmationDialogData.confirmText = 'Yes';
        state.confirmationDialogData.callback = async isConfirmed => {
          if (isConfirmed) {
            try {
              const missionObj = {
                zone_id: this.activeMission.zone_id,
                type: 'LAND',
                device_id: deviceId,
                requested_pois: []
              };
              await this.eventListStore.invokeMission(missionObj);
              this.contextStore.showSnackbar({
                message: `Request to land was sent successfully`,
                type: SNACKBAR_TYPE.SUCCESS
              });
            } catch (err) {
              console.error(err);
              this.contextStore.showSnackbar({
                message: 'Failed to land',
                type: SNACKBAR_TYPE.ERROR
              });
            }
          }
        };
      });
    },
    async abortAndGoHome() {
      this.contextStore.$patch(state => {
        state.showConfirmationDialog = true;
        state.confirmationDialogData.title = 'Abort Mission confirmation';
        state.confirmationDialogData.text = `Are you sure you want to abort mission ${this.activeMission.name} and send the device home?`;
        state.confirmationDialogData.confirmText = 'Yes';
        state.confirmationDialogData.callback = async isConfirmed => {
          if (isConfirmed) {
            try {
              await missionsService.abortMissionAndGoHome(this.activeMission.id);

              this.contextStore.showSnackbar({
                message: `Request to abort mission was sent successfully`,
                type: SNACKBAR_TYPE.SUCCESS
              });
            } catch (err) {
              console.error(err);
              this.contextStore.showSnackbar({
                message: 'Failed to abort mission',
                type: SNACKBAR_TYPE.ERROR
              });
            }
          }
        };
      });
    }
  }
};
