<template>
  <BaseModal
    class="confirmation-dialog"
    :confirmation-option="{ text: 'Reset Tile', disabled: !tilePhysicalId }"
    cancel-option
    @close="onCloseClick"
    @confirm="onConfirmClick"
  >
    <template #title>
      <span> Device available tiles</span>
    </template>
    <template #body>
      <div class="body-text">
        <span class="message">Which tile would you like to reset: </span>
        <div v-if="availableTilesOptions" class="attributes-container">
          <div class="attribute-container">
            <BaseDropdown v-if="!isLoading && !showErrorMessage" v-model="tilePhysicalId" :options="availableTilesOptions" />
            <BaseLoader v-if="isLoading && !showErrorMessage" class="loader"></BaseLoader>
            <div v-if="showErrorMessage" class="error-message-container">Failed to fetch available tiles, please try again</div>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '../../components/base/BaseModal.vue';
import BaseDropdown from '../../components/base/BaseDropdown.vue';
import BaseLoader from '../../components/base/BaseLoader.vue';
import devicesService from '../../services/api/devicesService';
import { useDevicesStore } from '../../store/DevicesStore';
import { mapActions, mapState, mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import { SNACKBAR_TYPE } from '../../consts/appConsts';

export default {
  name: 'ResetTileModal',
  components: {
    BaseModal,
    BaseDropdown,
    BaseLoader
  },
  props: {
    device: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  data() {
    return {
      fetchActiveTilesTimeout: null,
      showErrorMessage: false,
      isLoading: false,
      tilePhysicalId: ''
    };
  },
  computed: {
    ...mapStores(useContextStore),
    ...mapState(useDevicesStore, ['contextZoneActiveTiles']),
    availableTilesToReset() {
      return this.device.availableTilesToReset;
    },
    availableTilesOptions() {
      const options = [];
      if (this.availableTilesToReset?.length > 0) {
        clearTimeout(this.fetchActiveTilesTimeout);
        this.availableTilesToReset.forEach(tilePhysicalId => {
          const tile = this.contextZoneActiveTiles.find(tile => Number(tile.physical_id) === tilePhysicalId);
          if (tile) {
            options.push({
              id: Number(tile.physical_id),
              label: tile.name
            });
          }
        });
      }
      return options;
    }
  },
  watch: {
    availableTilesToReset(newVal) {
      if (newVal.length > 0) {
        this.tilePhysicalId = newVal[0];
        this.isLoading = false;
      }
    }
  },
  async created() {
    this.isLoading = true;
    await devicesService.fetchDeviceAvailableTilesToReset(this.device.id);
    this.fetchActiveTilesTimeout = setTimeout(() => {
      this.showErrorMessage = true;
      this.isLoading = false;
    }, 10000);
  },
  beforeUnmount() {
    clearTimeout(this.fetchActiveTilesTimeout);
    this.clearAvailableTilesList(this.device.id);
  },
  methods: {
    ...mapActions(useDevicesStore, ['clearAvailableTilesList']),
    onCloseClick() {
      clearTimeout(this.fetchActiveTilesTimeout);
      this.$emit('close');
    },
    async onConfirmClick() {
      const deviceId = this.device.id;
      try {
        const response = await devicesService.resetTile({
          deviceId,
          tilePhysicalId: this.tilePhysicalId
        });
        this.contextStore.showSnackbar({
          message: response.data.message,
          type: SNACKBAR_TYPE.SUCCESS
        });
      } catch (e) {
        this.contextStore.showSnackbar({
          message: e.message,
          type: SNACKBAR_TYPE.ERROR
        });
      }

      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">
.confirmation-dialog {
  .body-text {
    width: 380px;
    text-align: center;
    margin: 1rem auto auto auto;
    overflow: hidden;

    .message {
      display: inline-block;
      font-size: 18px;
      font-family: var(--font-family-secondary);
      font-weight: lighter;
      white-space: pre-line;
    }

    .attributes-container {
      margin-top: 20px;
      width: 100%;
      text-align: left;
    }
  }

  .loader {
    width: 30px;
    height: 30px;
    margin: auto;
  }

  .error-message-container {
    margin: 25px 0;
    font-size: 14px;
    text-align: center;
    color: var(--errorColor);
  }
}
</style>
