import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 6 6",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M 0.5 0.9 L 4.1 0.9 L 3.2 0 L 4.1 0 L 5.45 1.35 L 4.1 2.7 L 3.2 2.7 L 4.1 1.8 L 0.5 1.8 M 0.5 4.5 L 1.85 3.15 L 2.75 3.15 L 1.85 4.05 L 5.45 4.05 L 5.45 4.95 L 1.85 4.95 L 2.75 5.85 L 1.85 5.85 L 0.5 4.5" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }