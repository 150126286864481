import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import './plugins';
import { SetupCalendar } from 'v-calendar';
import { router } from './router';
import { Vue3Mq } from 'vue3-mq';

const app = createApp(App);
app.use(router);
app.use(createPinia());
app.use(SetupCalendar, {});
app.use(Vue3Mq, {
  preset: 'devices'
});
app.mount('#app');
