import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "-17 -17 34 34",
  role: "icon"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("defs", null, [
      _createElementVNode("mask", { id: "indr__iconMaskServerRoom" }, [
        _createElementVNode("rect", {
          x: "-17",
          y: "-17",
          width: "34",
          height: "34",
          fill: "white"
        }),
        _createElementVNode("path", {
          d: "M10.0889 10.5276H-10.9662V-10.5276h21.0552V10.5276zM.4387 8.773h7.8957V-8.773h-7.8957V8.773zM-9.2116 8.773h7.8957V-8.773H-9.2116V8.773zM6.5797 1.7546h-4.3865v-1.7546h4.3865V1.7546zM-3.0705 1.7546H-7.457v-1.7546h4.3865V1.7546zM6.5797-1.7546h-4.3865V-3.5092h4.3865V-1.7546zM-3.0705-1.7546H-7.457V-3.5092h4.3865V-1.7546zM6.5797-5.2638h-4.3865V-7.0184h4.3865V-5.2638zM-3.0705-5.2638H-7.457V-7.0184h4.3865V-5.2638z",
          fill: "black"
        })
      ])
    ], -1)),
    _cache[1] || (_cache[1] = _createElementVNode("circle", {
      cx: "0",
      cy: "0",
      r: "17",
      fill: "currentColor",
      mask: "url(#indr__iconMaskServerRoom)"
    }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }