import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", null, [
      _createElementVNode("circle", {
        cx: "12",
        cy: "12",
        r: "12",
        fill: "#BF5A71"
      }),
      _createElementVNode("g", { fill: "#FFF" }, [
        _createElementVNode("path", { d: "m 11.373 14.2126 c -0.3277 1.4764 -2.26 2.4388 -4.3174 2.1504 l -2.3074 -0.3255 c -0.2567 -0.0362 -0.4319 -0.2146 -0.3912 -0.3992 l 1.7631 -7.999 c 0.0407 -0.1846 0.2815 -0.3043 0.5382 -0.2681 l 2.3074 0.3255 c 2.0579 0.2921 3.461 1.7249 3.1378 3.202 z m 8.25 -2.1032 c 0 1.8227 -1.4773 3.3 -3.3 3.3 s -3.3 -1.4773 -3.3 -3.3 s 1.4773 -3.3 3.3 -3.3 s 3.3 1.4773 3.3 3.3 z" })
      ])
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }