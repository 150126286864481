<template>
  <vSelect
    v-model="value"
    class="dropdown"
    :options="options"
    :multiple="true"
    :taggable="true"
    :push-tags="true"
    :select-on-tab="true"
    :close-on-select="false"
    :no-drop="!options.length"
    :loading="loading"
    :disabled="disabled"
    append-to-body
  >
    <template v-if="loading" #spinner>
      <BaseLoader class="loader" />
    </template>
    <template v-if="noOptionsStr !== ''" #no-options>
      <span>{{ noOptionsStr }}</span>
    </template>
  </vSelect>
</template>

<script>
/* DOCUMENTATION: https://vue-select.org/ */
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import BaseLoader from './BaseLoader.vue';

export default {
  name: 'BaseTagInput',
  components: {
    vSelect,
    BaseLoader
  },
  props: {
    modelValue: {
      type: [Array, Object, String, Number],
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noOptionsStr: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: []
    };
  },
  computed: {
    areAllOptionsSelected() {
      return this.multiple && this.value?.length === this.options?.length;
    }
  },
  watch: {
    value(newVal) {
      this.$emit('update:modelValue', newVal);
    },
    modelValue: {
      handler: function onBindedValueChange(newVal) {
        if (this.value !== newVal) {
          this.value = newVal;
        }
      },
      immediate: true
    }
  }
};
</script>
<style lang="scss">
.dropdown {
  font-family: var(--font-family-secondary);
  display: inline-block;
  width: 100%;

  .loader {
    width: 1.2rem;
    height: 1.2rem;
    margin-bottom: 6px;
  }

  &.vs--disabled {
    opacity: 0.5;
    filter: grayscale(0.5);

    .vs__search,
    .vs__open-indicator,
    .vs__dropdown-toggle {
      background: var(--dropdown-bg, var(--primaryColorDarkShade1));
    }
  }

  .vs__search::placeholder,
  .vs__dropdown-toggle {
    background: var(--dropdown-bg, var(--primaryColorDarkShade1));
    color: var(--textColor);
    caret-color: var(--highlightColor);
  }

  .vs__search {
    color: var(--secondaryTextColor);
    height: 1.8rem;
  }

  .vs__dropdown-toggle {
    padding: 0.2rem;
  }

  &:not(.vs--single) {
    .vs__selected {
      background: var(--primaryColorBrightShade2);
      align-items: baseline;

      .vs__deselect {
        fill: var(--highlightColorShade1);
      }
    }
  }

  .vs__selected {
    color: var(--textColor);
    border-radius: 6px;
    line-height: 1.65rem;
  }
  .vs__actions {
    cursor: pointer;
  }

  .vs__clear,
  .vs__open-indicator {
    fill: var(--highlightColor);
    transition: 0.2s;

    &:hover {
      filter: grayscale(1);
    }
  }
}
</style>
