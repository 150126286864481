<template>
  <div class="event-card" :class="{ selected }" @click="handleCardClick">
    <component :is="EVENT_TYPE_ICONS[event.event_type]" class="event-icon" />
    <div class="title">{{ EVENT_TYPE_NAME[event.event_type] }} Detected</div>
    <div class="actions">
      <div v-if="isLoading" class="loading">
        <BaseLoader />
      </div>
      <BaseSignButton v-else class="action" @click.stop="onResolvedIconClick">
        {{ handledText }}
        <template #sign>
          <span :class="[event.handled ? 'unresolve' : 'resolve']">{{ handledIcon }}</span>
        </template>
      </BaseSignButton>
      <BaseSignButton class="action" @click="handleMoreClick">
        More Info
        <template #sign>
          <IconCollapse class="arrow-icon" />
        </template>
      </BaseSignButton>
    </div>
    <div v-if="event?.mission?.name" class="mission-info">During mission: {{ event?.mission.name }}</div>
    <div v-else-if="event.source !== EVENT_SOURCE.EXTERNAL" class="mission-info">While Docked</div>
    <div class="device-info">
      {{ deviceLabel(event) }}
    </div>
    <div class="time">
      {{ getDateTime(new Date(event.timestamp)) }}
    </div>
  </div>
</template>

<script>
import { getDateTime } from '../../utils/DateUtils';
import { deviceLabel } from '../../utils/models/EventUtils';
import BaseSignButton from '../../components/base/BaseSignButton.vue';
import BaseLoader from '../../components/base/BaseLoader.vue';
import { EVENT_SOURCE, EVENT_TYPE_NAME, EVENT_TYPE_ICONS } from '../../consts/eventConsts';
import { SNACKBAR_TYPE } from '../../consts/appConsts';
import { useContextStore } from '../../store/ContextStore';
import { useEventListStore } from '../../store/EventListStore';
import { mapActions } from 'pinia';
import IconCollapse from '../../components/icons/IconCollapse.svg?component';
import { shallowRef } from 'vue';
import { PAGES } from '../../router';

export default {
  name: 'EventCard',
  components: {
    BaseSignButton,
    BaseLoader,
    IconCollapse
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    selected: Boolean
  },
  emits: ['select'],
  data() {
    return {
      isLoading: false,
      EVENT_SOURCE,
      EVENT_TYPE_NAME,
      EVENT_TYPE_ICONS: shallowRef(EVENT_TYPE_ICONS)
    };
  },
  computed: {
    handledText() {
      return this.event.handled ? 'Unresolve' : 'Resolve';
    },
    handledIcon() {
      return this.event.handled ? 'X' : '✓';
    }
  },
  methods: {
    getDateTime,
    deviceLabel,
    ...mapActions(useContextStore, ['showSnackbar']),
    ...mapActions(useEventListStore, ['updateEventHandled']),
    async onResolvedIconClick() {
      try {
        const newValue = !this.event.handled;
        this.isLoading = true;
        await this.updateEventHandled({
          id: this.event.id,
          handled: newValue
        });
      } catch (e) {
        this.showSnackbar({
          message: 'Failed to resolve event',
          type: SNACKBAR_TYPE.ERROR
        });
        console.error(e);
      }
      this.isLoading = false;
    },
    handleCardClick() {
      this.$emit('select');
    },
    handleMoreClick() {
      this.$router.push({
        name: PAGES.EVENT,
        params: {
          eventId: this.event.id
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.event-card {
  border-bottom: 1px solid var(--primaryColorBrightShade1);
  background: var(--primaryColor);
  color: var(--textColor);
  display: grid;
  grid-template-areas:
    'event-icon title title actions'
    'event-icon mission mission .'
    '. . . .'
    'device device time time';
  grid-template-rows: auto auto 0.4fr 1fr;
  grid-template-columns: 3rem auto auto 14rem;
  padding: 1rem;
  row-gap: 0.3rem;
  align-items: center;
  transition: 0.3s;
  column-gap: 0.5rem;

  &.selected,
  &:hover {
    background: var(--primaryColorDarkShade1);
  }

  .event-icon {
    grid-area: event-icon;
  }

  .title {
    grid-area: title;
    font-size: 1.3rem;
  }

  .loading {
    top: 5rem;
    margin: auto;
  }

  .actions {
    cursor: pointer;
    grid-area: actions;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
    transition: 0.3s;

    .action {
      font-size: 0.9rem;
      flex-grow: 1;
      .arrow-icon {
        height: 1rem;
        width: 1rem;
        transform: rotate(-90deg);
      }

      .resolve {
        font-size: 1.5rem;
        font-weight: bold;
        color: var(--successColor);
      }

      .unresolve {
        font-size: 1.5rem;
        color: var(--errorColor);
      }
    }
  }

  .mission-info {
    grid-area: mission;
    color: var(--secondaryTextColor);
    word-break: break-word;
  }

  .device-info {
    color: var(--highlightColor);
    grid-area: device;
  }

  .time {
    grid-area: time;
    text-align: right;
  }
}
</style>
