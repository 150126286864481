import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M1.15 21Q1.15 22 2.15 22L7.15 20.5 14.5 23.5 21.15 20.5Q22.65 20 22.65 19L22.65 2.5Q22.65 1.5 20.65 2L14.5 4 7.15 1.5 1.65 3Q1.15 3 1.15 4M7.15 19.5 7.15 19.5 7.15 2.5 14.5 5 14.5 22.5 7.15 19.5M18.65 15.5Q15.65 12 15.65 8.5A1 1 90 0121.65 8.5Q21.65 12 18.65 15.5M20.4 8.5A1 1 90 0016.9 8.5 1 1 90 0020.4 8.5" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }