<template>
  <div class="options-selector">
    <template v-for="(option, index) in options" :key="`${option.id}-radio-input-${id}`">
      <input
        :id="`${option.id}-${id}`"
        type="radio"
        :name="`state-options-${id}`"
        :checked="selectedIndex === index"
        @change="onSelectionChange(option, index)"
      />
      <div class="option-label">
        <label v-if="!(selectedIndex === index && isLoading)" :for="`${option.id}-${id}`" :class="{ disabled, pointer: selectedIndex !== index }">{{
          option.label
        }}</label>
      </div>
    </template>
    <div class="selector-slider" :style="selectorStyle">
      {{ offsetIndex }}
      <BaseLoader v-if="isLoading" class="loader" />
    </div>
  </div>
</template>

<script>
import BaseLoader from './BaseLoader.vue';
import { v1 as uuidv1 } from 'uuid';

export default {
  name: 'BaseOptionsSwitch',
  components: {
    BaseLoader
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      id: uuidv1(),
      selectedIndex: null
    };
  },
  computed: {
    offsetIndex() {
      if (!this.selectedIndex && this.modelValue) {
        return this.options.findIndex(option => option.id === this.modelValue);
      }
      return this.selectedIndex || 0;
    },
    selectorStyle() {
      let val = '0';
      if (this.offsetIndex > 0) {
        const left = ((100 / this.options.length) * this.offsetIndex).toFixed(3);
        val = `calc(${left}% - 0.3rem)`;
      }
      return {
        left: val
      };
    }
  },
  watch: {
    modelValue: function optionsSwitchValueChange(newVal) {
      this.selectedIndex = this.options.findIndex(option => option.id === newVal);
    }
  },
  methods: {
    onSelectionChange(option, index) {
      this.selectedIndex = index;
      this.$emit('update:modelValue', option.id);
    }
  }
};
</script>

<style scoped lang="scss">
.options-selector {
  position: relative;
  border-radius: 10em;
  padding: 0 5px;
  position: relative;
  width: max-content;
  background: var(--primaryColorDarkShade1);
  border: 1px solid var(--secondaryColor);
  display: flex;

  .loader {
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  .option-label {
    width: 4.5rem;
    text-align: center;
    line-height: 1.8rem;
    display: grid;
    place-content: center;
  }

  .pointer {
    cursor: pointer;
  }

  input[type='radio'] {
    display: none;
  }

  .selector-slider {
    transition: left 0.4s;
    width: 4.5rem;
    position: absolute;
    border-radius: 2em;
    top: 0.3em;
    margin-left: 0.3em;
    left: 0;
    background: var(--secondaryOverlayShade1);
    box-shadow: 0 0px 2px var(--secondaryOverlayShade2);
    color: transparent;
    height: calc(100% - 10px);
  }
}
</style>
