<template>
  <input
    type="text"
    class="input-text"
    :value="modelValue"
    :placeholder="placeholder"
    :disabled="disabled"
    :maxlength="maxlength"
    @input="$emit('update:modelValue', $event.target.value)"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
export default {
  name: 'BaseTextInput',
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      default: null,
      type: [null, Number]
    }
  },
  emits: ['update:modelValue', 'blur']
};
</script>

<style lang="scss" scoped>
.input-text {
  font-family: var(--font-family-secondary);
  font-size: inherit;
  background: var(--primaryColor);
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  min-width: 15rem;
  outline: none;
  border: 0;
  width: 100%;
  box-sizing: border-box;
  color: var(--textColor);
  caret-color: var(--highlightColor);

  &:disabled {
    opacity: 0.5;
    filter: grayscale(0.5);
  }

  &:focus-visible {
    outline: inset;
    outline-color: var(--secondaryOverlayShade2);
    outline-style: solid;
    outline-width: 2px;
  }
}
</style>
