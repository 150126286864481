<template>
  <div class="zone-halt-message" @click="isOpen = !isOpen">
    <div class="message-text" :class="{ hide: !isOpen }">
      This zone is on halt,<br />
      therefore no missions will be executed, manual or scheduled.
    </div>
    <IconWarning class="warning-icon" />
  </div>
</template>

<script>
import IconWarning from '../../components/icons/IconWarning.svg?component';

export default {
  name: 'ZoneHaltMessage',
  components: { IconWarning },
  data() {
    return {
      isOpen: true
    };
  }
};
</script>

<style lang="scss" scoped>
.zone-halt-message {
  position: fixed;
  bottom: 0.5rem;
  padding: 0.5rem 1rem;
  background: #9a2929e6;
  z-index: 1;
  display: flex;
  cursor: pointer;

  .message-text {
    transition: 0.3s;
    width: 30rem;
    height: 3rem;
    overflow: hidden;

    &:not(.hide) {
      margin-right: 1rem;
    }

    &.hide {
      width: 0;
    }
  }

  .warning-icon {
    width: 3rem;
    height: 3rem;
  }
}
</style>
