import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 6 6",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M 1 0 L 1 1 L 0 1 L 0 2 L 2 2 L 2 0 L 1 0 M 4 0 L 4 2 L 6 2 L 6 1 L 5 1 L 5 0 L 4 0 M 4 4 L 6 4 L 6 5 L 5 5 L 5 6 L 4 6 L 4 4 M 2 4 L 2 6 L 1 6 L 1 5 L 0 5 L 0 4 L 2 4" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }