export const videoTimeToTimestamp = ({ videoTime, segments }) => {
  let timestamp = 0;
  try {
    if (segments?.length > 0) {
      let closestSegment;
      let closestSegmentDiff;
      let closestSegmentStartCalc = 0;

      // Find the fragment that contains the video time provided
      for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        const segmentDiff = closestSegmentStartCalc - videoTime;
        if (!closestSegment || segmentDiff < 0) {
          closestSegment = segment;
          closestSegmentDiff = segmentDiff;
          closestSegmentStartCalc += segment.duration;

          if (i !== 0) {
            const prevSegment = segments[i - 1];
            const segmentsGap = (segment.dateTimeObject.getTime() - (prevSegment.dateTimeObject.getTime() + prevSegment.duration * 1000)) / 1000;
            closestSegmentStartCalc += segmentsGap;
          }
        } else {
          break;
        }
      }
      const fragmentStartTimestamp = closestSegment.dateTimeObject.getTime();
      timestamp = fragmentStartTimestamp + Math.round(closestSegmentDiff * -1000);
    }
  } catch (e) {
    console.error(e);
  }
  return timestamp;
};

export const timestampToVideoTime = ({ timestamp, segments = [], videoStartTimestamp }) => {
  let videoTime = 0;
  try {
    if (segments?.length > 0) {
      let closestSegment;
      let closestSegmentDiff;
      let closestSegmentStartCalc = 0;

      // Find the fragment in which the timestamp occurred
      for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        const segmentDiff = segment.dateTimeObject.getTime() - timestamp;
        if (!closestSegment || segmentDiff < 0) {
          closestSegment = segment;
          closestSegmentDiff = segmentDiff;
          closestSegmentStartCalc += segment.duration;

          if (i !== 0) {
            const prevSegment = segments[i - 1];
            const segmentsGap = (segment.dateTimeObject.getTime() - (prevSegment.dateTimeObject.getTime() + prevSegment.duration * 1000)) / 1000;
            closestSegmentStartCalc += segmentsGap;
          }
        } else {
          break;
        }
      }
      const fragmentStart = closestSegmentStartCalc - closestSegment.duration;
      videoTime = fragmentStart + Number((closestSegmentDiff / -1000).toFixed(6));
    } else if (videoStartTimestamp) {
      videoTime = Math.floor((timestamp - videoStartTimestamp) / 1000);
    }
  } catch (e) {
    console.error(e);
  }
  return videoTime;
};
