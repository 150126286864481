<template>
  <div :class="[mq.current, 'dashboard']">
    <div class="stats-toggle" :class="{ hidden: isStatsOpen }" @click="isStatsOpen = true">
      <IconBack />
    </div>
    <template v-if="wereZonesFetched">
      <DashboardStats class="stats" :class="{ hidden: !isStatsOpen }" :is-hidden="!isStatsOpen" @close="isStatsOpen = false" />
      <div class="zones-list">
        <ZoneCard v-for="zone in activeZones" :key="zone.id" class="zone-card" :zone-id="zone.id" :next-schedules="nextZoneSchedules[zone.id]" />
      </div>
    </template>
    <BaseLoader v-else class="loader"></BaseLoader>
  </div>
</template>

<script>
import BaseLoader from '../../components/base/BaseLoader.vue';
import ZoneCard from './ZoneCard.vue';
import { useZonesStore } from '../../store/ZonesStore';
import { mapState } from 'pinia';
import DashboardStats from './DashboardStats.vue';
import IconBack from '../../components/icons/IconBack.svg?component';

export default {
  name: 'DashboardView',
  components: {
    ZoneCard,
    DashboardStats,
    BaseLoader,
    IconBack
  },
  inject: ['mq'],
  data() {
    return {
      isStatsOpen: true
    };
  },

  computed: {
    ...mapState(useZonesStore, ['activeZones', 'nextZoneSchedules', 'wereZonesFetched'])
  }
};
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-grow: 1;
  padding: 1rem;
  overflow: hidden;
  height: calc(100% - 2rem);

  .stats-toggle {
    cursor: pointer;
    display: block;
    position: absolute;
    right: 0;
    background: var(--highlightColor);
    padding: 0.5rem 1rem 0.5rem;
    color: var(--textColor);
    z-index: 1;
    transform: translateY(-50%);
    transition: 0.7s ease;

    svg {
      transition: 0.3s ease;
      height: 1.2rem;
      width: 1.2rem;
    }

    &.hidden {
      transform: translate(105%, -50%);
    }

    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }

  .zones-list {
    flex-grow: 1;
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;
    overflow-y: auto;
    grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
  }

  .stats {
    flex-basis: 35%;
    order: 2;
    transition: 0.5s ease, transform 0.7s cubic-bezier(0.77, 1.06, 0.83, 1.08);
    min-height: 80vh;
    overflow: hidden;

    &.hidden {
      transform: translateX(105%);
      flex-basis: 0;
    }
  }

  .loader {
    height: 3rem;
    width: 3rem;
    margin: auto;
  }

  &.tablet,
  &.phone {
    flex-direction: column;
    overflow-y: auto;

    .stats {
      flex-basis: auto;

      &.hidden {
        transform: none;
        flex-basis: auto;
      }
    }

    .zones-list {
      flex-shrink: 0;
    }
  }
}
</style>
