<template>
  <BaseMenu ref="settingsMenu" :position="menuPosition">
    <template #toggle>
      <IconSettings class="icon" title="Account settings" />
    </template>
    <template #content>
      <div class="settings-list">
        <div v-for="page in settingsPages" :key="page.name" class="menu-button">
          <router-link class="menu-button" :to="page.path" :class="{ selected: $route.name === page.name }" @click="$refs.settingsMenu.openClose()">
            {{ page.meta.title }}
          </router-link>
        </div>
      </div>
    </template>
  </BaseMenu>
</template>

<script>
import IconSettings from '../../components/icons/IconSettings.svg?component';
import BaseMenu, { MENU_POSITION_OPTIONS } from '../../components/base/BaseMenu.vue';
import { useContextStore } from '../../store/ContextStore';
import { mapState } from 'pinia';
import { PAGES, ROUTES_MAP } from '../../router/index.js';
const SETTINGS_MENU_LINKS = [PAGES.DEVICES_CONFIG, PAGES.ZONES_CONFIG, PAGES.USERS_MANAGEMENT_VIEW, PAGES.INTEGRATIONS, PAGES.SETTINGS];

export default {
  name: 'SettingsMenu',
  components: {
    BaseMenu,
    IconSettings
  },
  data() {
    return {
      settingsPages: [],
      menuPosition: [MENU_POSITION_OPTIONS.BOTTOM, MENU_POSITION_OPTIONS.RIGHT]
    };
  },
  computed: {
    ...mapState(useContextStore, ['hasPrivilege'])
  },
  created() {
    this.settingsPages = SETTINGS_MENU_LINKS.map(page => ROUTES_MAP[page]).filter(
      link => !link.privileges || link.privileges.some(p => this.hasPrivilege(p))
    );
  }
};
</script>

<style lang="scss" scoped>
.icon {
  width: 1.4rem;
  height: 1.4rem;
}
.settings-list {
  background: var(--mainBackground);
  display: flex;
  flex-direction: column;
  border: 1px solid var(--highlightColor);
  border-radius: 3px;
  width: max-content;

  .menu-button {
    font-family: var(--font-family-secondary);
    color: inherit; /* avoid default blue colors for links */
    text-decoration: inherit; /* avoid default underline */
    font-size: 1.2rem;
    padding: 0.7rem 0.4rem;

    &:hover {
      filter: brightness(1.2);
    }

    &.selected {
      color: var(--highlightColor);
    }
  }
}
</style>
