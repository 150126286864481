const TAP_TIMEOUT = 200;
export const tapDirective = (() => {
  let tapStartTimestamp;
  const onTouchStart = () => {
    tapStartTimestamp = new Date();
  };

  const onTouchEnd = (ev, callback) => {
    // If touch lasted less then tap timeout -> it's a tap
    const timestamp = new Date();
    if (Number(timestamp) - Number(tapStartTimestamp) < TAP_TIMEOUT) {
      callback(ev);
    }
  };

  return {
    beforeMount: function (el, binding) {
      el.addEventListener('mousedown', onTouchStart);
      el.addEventListener('touchstart', onTouchStart);
      el.addEventListener('mouseup', ev => onTouchEnd(ev, binding.value));
      el.addEventListener('touchend', ev => onTouchEnd(ev, binding.value));
      el.addEventListener('touchcancel', ev => onTouchEnd(ev, binding.value));
      el.addEventListener('touchleave', ev => onTouchEnd(ev, binding.value));
    },
    beforeUnmount: function (el) {
      el.removeEventListener('mousedown', onTouchStart);
      el.removeEventListener('touchstart', onTouchStart);
      el.removeEventListener('mouseup', onTouchEnd);
      el.removeEventListener('touchend', onTouchEnd);
      el.removeEventListener('touchcancel', onTouchEnd);
      el.removeEventListener('touchleave', onTouchEnd);
    }
  };
})();
