import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "-10 -10 20 20",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "m0 5.5 1 0 5-5c1-2-1-2-2-1l-3 3 0-11c0-1-2-1-2 0l0 11-3-3c-1-1-3-1-2 1l5 5q1 1 2 0m-7 2 12 0q1 1 0 2l-12 0q-1-1 0-2" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }