<template>
  <BaseExpandableCard :is-open="isOpen">
    <template #foreground>
      <div class="card-foreground" :class="{ 'is-open': isOpen }" @click="!isOpen && handleButtonClick()">
        <component :is="getIcon(device)" class="device-icon" />
        {{ device.name }}
        <IconCollapse :class="['expand-icon', { expand: isOpen }]" @click="toggleButtonOpen" />
        <span v-if="device.halted" class="halt-tag">Flights Halt</span>
        <div class="status-icons">
          <component
            :is="statusIcon.icon"
            v-for="(statusIcon, idx) in statusIcons"
            :key="idx"
            :title="statusIcon.text"
            class="status-icon"
            :class="statusIcon.class"
            :style="statusIcon.style"
            :props="statusIcon.props"
          />
        </div>
      </div>
    </template>
    <template #body>
      <div class="card-body">
        <template v-for="(item, index) in rows.filter(row => !!row.value && row.condition !== false)" :key="index">
          <span class="metadata-key">{{ item.label }}:</span>
          <span :style="{ color: item.color }" class="metadata-value">
            {{ item.value }}
          </span>
        </template>
      </div>
    </template>
    <template #footer>
      <IconInvestigate class="footer-icon" @click="handleButtonClick"><title>Details</title></IconInvestigate>
    </template>
  </BaseExpandableCard>
</template>

<script>
import BaseExpandableCard from '../../components/base/BaseExpandableCard.vue';
import IconInvestigate from '../../components/icons/IconInvestigate.svg?component';
import IconLink from '../../components/icons/IconLink.svg?component';
import IconReport from '../../components/icons/IconReport.svg?component';
import IconSettings from '../../components/icons/IconSettings.svg?component';
import IconCollapse from '../../components/icons/IconCollapse.svg?component';
import {
  getColorConnection,
  getPairedDevice,
  getIcon,
  isRobot,
  getIconConnection,
  isCharging,
  isInError,
  isOnUpgrade,
  getStrBatteryLevel,
  getStrConnection,
  getIconCharging,
  isPaired,
  getStrCharging,
  getColorCharging,
  getStrState,
  getColorState,
  isDockingStation
} from '../../utils/models/DeviceUtils';
import { PAGES } from '../../router';

export default {
  name: 'DeviceExpandableCard',
  components: {
    BaseExpandableCard,
    IconInvestigate,
    IconCollapse
  },
  props: {
    device: {
      type: Object,
      required: true
    }
  },
  emits: ['button:open', 'button:close'],
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    statusIcons() {
      let icons = [];
      if (this.device) {
        if (isRobot(this.device)) {
          icons.push({
            icon: getIconConnection(this.device),
            text: this.device.is_connected ? 'Connected' : 'Disconnected',
            style: { color: 'var(--highlightColor)' }
          });

          if (this.device?.battery_level) {
            icons.push({
              icon: getIconCharging(this.device),
              props: {
                batteryLevel: this.device.battery_level
              },
              style: { color: 'var(--highlightColor)' },
              text: `${isCharging(this.device) ? 'Charging ' : ''}${getStrBatteryLevel(this.device)}`
            });
          }
        } else if (isPaired(this.device)) {
          icons.push({
            icon: IconLink,
            text: 'Paired To Device',
            style: { color: 'var(--highlightColor)' }
          });
        }

        if (isInError(this.device)) {
          icons.unshift({
            icon: IconReport,
            text: 'Device error occurred'
          });
        }
        if (isOnUpgrade(this.device)) {
          icons.unshift({
            icon: IconSettings,
            class: 'active-installation',
            text: `Device Installing...`
          });
        }
      }
      return icons;
    },
    rows() {
      return [
        {
          label: 'Type',
          value: this.device.type_name
        },
        {
          label: 'Connection',
          value: getStrConnection(this.device),
          color: getColorConnection(this.device),
          condition: isRobot(this.device)
        },
        {
          label: 'Charging',
          value: getStrCharging(this.device),
          color: getColorCharging(this.device),
          condition: this.device.is_connected && isRobot(this.device)
        },
        {
          label: 'State',
          value: getStrState(this.device),
          color: getColorState(this.device),
          condition: this.device.is_connected
        },
        {
          label: 'Battery Level',
          value: getStrBatteryLevel(this.device),
          condition: this.device.battery_level && this.device.is_connected
        },
        {
          label: 'Paired Device',
          value: getPairedDevice(this.device)?.name || '',
          condition: isDockingStation(this.device)
        }
      ];
    }
  },
  methods: {
    getIcon,
    handleButtonClick() {
      this.$router.push({
        name: PAGES.DEVICE,
        params: {
          deviceId: this.device.id
        }
      });
    },
    toggleButtonOpen() {
      this.$emit(`button:${this.isOpen ? 'open' : 'close'}`, this.device.id);
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style scoped lang="scss">
.card-foreground {
  background-color: var(--primaryColor);
  display: flex;
  column-gap: 1rem;
  align-items: center;
  color: var(--textColor);
  font-family: var(--font-family-secondary);
  font-size: 1.2rem;
  padding: 0.5rem;

  .expand-icon,
  .status-icon,
  .device-icon {
    width: 1.7rem;
    height: 1.7rem;
    flex-shrink: 0;
  }

  .halt-tag {
    color: var(--errorColor);
    padding: 0.2rem 0.5rem;
    font-size: 1.1rem;
    border-radius: 20px;
    background: var(--mainBackground);
  }

  .status-icons {
    display: flex;
    column-gap: 1rem;
    margin-left: auto;
    flex-wrap: wrap;
    row-gap: 1rem;
    justify-content: flex-end;
  }

  .expand-icon {
    transform: rotate(-90deg);
    transition: transform 0.4s;
    width: 1rem;
    height: 1rem;
    color: var(--highlightColor);
    cursor: pointer;
  }

  &.tablet,
  &.mobile {
    .card-foreground {
      font-size: 0.9rem;
    }
  }

  .expand {
    transform: rotate(0deg);
  }

  &:hover {
    &:not(.is-open) {
      cursor: pointer;
      background-color: var(--primaryColorDarkShade1);
      border-color: var(--primaryColor);
    }
  }

  .active-installation {
    animation: spin-animation 0.8s infinite;
    color: var(--highlightColor);
  }

  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }
}

.card-body {
  padding: 0.5rem 0;
  font-family: var(--font-family-secondary);
  color: var(--textColor);
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  text-align: left;

  .metadata-key {
    flex-basis: 12rem;
    flex-shrink: 0;
    text-indent: 4rem;
    margin-right: auto;
  }

  .metadata-value {
    font-weight: 300;
    color: var(--secondaryTextColor);
    flex-basis: 50%;
    flex-shrink: 0;
    text-indent: 2rem;
  }
}

.footer-icon {
  padding: 5px;
  color: var(--highlightColor);
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
}
</style>
