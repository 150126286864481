import apiRequest from './apiRequest';

class AreasService {
  async fetchAreas(zoneId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zone/${zoneId || '{zoneId}'}/areas`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async createArea(area) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zone/{zoneId}/areas`,
        method: 'POST',
        data: area
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async updateArea(area) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zone/{zoneId}/areas/${area.id}`,
        method: 'PATCH',
        data: area
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async deleteArea(area) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zone/{zoneId}/areas/${area.id}`,
        method: 'DELETE',
        data: area
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new AreasService();
