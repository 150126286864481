<template>
  <div class="event-view">
    <div class="event-data">
      <BaseLoader v-if="isLoading" class="loader" />
      <EventDetails v-else @close="onCloseClick" />
    </div>
    <MapCanvas v-if="zoneId" :indicators="indicators" :is-quick-action-menu-enabled="false" />
  </div>
</template>

<script>
import EventDetails from './EventDetails.vue';
import MapCanvas from '../../components/theMap/MapCanvas.vue';
import BaseLoader from '../../components/base/BaseLoader.vue';
import { mapState, mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import { ERROR_MESSAGES, SNACKBAR_TYPE } from '../../consts/appConsts';
import { useEventListStore } from '../../store/EventListStore';
import { getDisplayConfigForMap, hasObjectDetection } from '../../utils/models/EventUtils';
import { useAreasStore } from '../../store/AreasStore';
import { useVideoPlayerStore } from '../../store/VideoPlayerStore';
import { EVENT_TYPE, EVENT_TYPE_NAME, EVENT_SOURCE } from '../../consts/eventConsts.js';
import { PAGES } from '../../router';

export default {
  name: 'EventView',
  components: {
    EventDetails,
    BaseLoader,
    MapCanvas
  },
  async beforeRouteUpdate(to, from, next) {
    await this.initEvent();
  },
  beforeRouteEnter(to, from, next) {
    next(componentState => {
      componentState.fromRouteName = from?.name;
    });
  },
  beforeRouteLeave() {
    this.contextStore.setContextEvent({});
    this.videoPlayerStore.$reset();
  },
  // Prop is from the router
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      eventModel: null,
      relevantModel: null,
      fromRouteName: null,
      isLoading: false
    };
  },
  computed: {
    ...mapStores(useContextStore, useEventListStore, useVideoPlayerStore),
    ...mapState(useAreasStore, ['activeNoFlightAreas']),
    ...mapState(useContextStore, ['zoneId', 'event']),
    indicators() {
      const icons = [];
      if (this.event?.location) {
        icons.push(getDisplayConfigForMap({ event: this.event }));
      }
      const indicators = {
        contextId: this.event?.id,
        icons,
        areas: this.activeNoFlightAreas.map(area => ({
          ...area,
          isInBackground: true
        }))
      };
      return indicators;
    }
  },
  async created() {
    await this.initEvent();
  },
  methods: {
    async initEvent() {
      try {
        this.isLoading = true;
        const event = await this.eventListStore.getOrFetchEventById({ id: this.eventId, fetchStreams: true });
        this.contextStore.setContextEvent(event);
      } catch (e) {
        this.contextStore.showSnackbar({
          message: e.status === 401 || e.status === 404 ? ERROR_MESSAGES.GENERIC_401 : ERROR_MESSAGES.UNABLE_TO_DISPLAY_PAGE,
          type: SNACKBAR_TYPE.INFO
        });
        this.$router.push({
          name: PAGES.EVENTS
        });
        return;
      } finally {
        this.isLoading = false;
      }
      const videoSource = this.event?.custom_data?.Source;
      let pauseMarker;

      if (this.event?.custom_data?.hasOwnProperty?.('BoundingBoxes') && this.event.source === EVENT_SOURCE.MANUAL) {
        pauseMarker = {
          ...this.event.custom_data,
          type: 'event',
          timestamp: this.event.timestamp,
          name: EVENT_TYPE_NAME[this.event.event_type],
          label: EVENT_TYPE_NAME[this.event.event_type]
        };
      }

      const streams = this.event?.streamsData?.data;
      this.videoPlayerStore.setPlayerConfig({
        streams,
        enabledThermalByDefault: this.event.event_type === EVENT_TYPE.THERMAL,
        device_type_id: this.event.device_type_id,
        hideOdLabel: !hasObjectDetection(this.event),
        pauseMarker,
        defaultFocusPlayer: videoSource?.match?.(/rear/i) ? 'rear' : 'front'
      });
    },
    onCloseClick() {
      if (this.fromRouteName) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          name: PAGES.EVENTS
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.event-view {
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  .event-data {
    flex-basis: 50rem;
    max-width: 50%;
    display: flex;
    flex-direction: column;

    .loader {
      margin: auto;
      width: 3rem;
      height: 3rem;
    }
  }
}
</style>
