<template>
  <BaseDropdown :model-value="zoneId" class="zones-menu" :options="options" :large="true" @selected="navigate" />
</template>
<script>
import BaseDropdown from '../../components/base/BaseDropdown.vue';
import { useZonesStore } from '../../store/ZonesStore';
import { useContextStore } from '../../store/ContextStore';
import { mapState } from 'pinia';
import ZoneLabelWithHaltChip from './ZoneLabelWithHaltChip.vue';
import { markRaw } from 'vue';

export default {
  name: 'ZonesMenu',
  components: { BaseDropdown },
  computed: {
    ...mapState(useZonesStore, ['activeZones', 'getZoneById']),
    ...mapState(useContextStore, ['zoneId']),
    options() {
      return this.activeZones.map(zone => ({
        id: zone.id,
        label: zone.name,
        ...(zone.halted && { slot: markRaw(ZoneLabelWithHaltChip), slotProps: { label: zone.name } })
      }));
    }
  },
  methods: {
    async navigate(selectedZone) {
      if (this.zoneId !== selectedZone.id) {
        await this.$router.push({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            zoneId: selectedZone.id
          }
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.zones-menu {
  min-width: 18rem;
  font-size: 1.1rem;
  border: 1px solid var(--secondaryOverlayShade1);
  border-radius: 5px;
}
</style>
