<template>
  <div ref="splitVideoManager" class="split-video-manager" :style="positionStyle">
    <div class="close-button" title="close" @click="$emit('close')">X</div>
    <div class="drag-button" title="drag" @mousedown.prevent="onMouseDown">=</div>
    <BaseLoader v-if="isLoading" class="loader" />
    <component
      :is="fullscreenIcon"
      ref="fullscreenButton"
      :class="['icon', { hide: isLoading }]"
      :title="fullscreenButtonTitle"
      @click="toggleFullscreen"
    />
    <IconSwitchSides ref="reverseButton" :class="['icon', { hide: isLoading }]" @click="reversePlayersLayout = !reversePlayersLayout"
      ><title>Switch players sides</title></IconSwitchSides
    >
    <div ref="players" :class="['players', { hide: isLoading }]">
      <VideoPlayer
        ref="rearVideoPlayer"
        class="player"
        :type="playerTypes.REAR"
        :display-controls="false"
        :publish-frame="false"
        @loaded="onPlayerReady(playerTypes.REAR, $event)"
        @inactive="onPlayerInactive(playerTypes.REAR)"
        @stream-stop="onStreamStop"
      />
      <VideoPlayer
        ref="frontVideoPlayer"
        :class="['player', { reverse: reversePlayersLayout }]"
        :type="playerTypes.FRONT"
        :allow-fullscreen="false"
        :allow-video-download="false"
        :publish-frame="true"
        @loaded="onPlayerReady(playerTypes.FRONT, $event)"
        @thermal-loaded="onPlayerReady(playerTypes.THERMAL, $event)"
        @inactive="onPlayerInactive(playerTypes.FRONT)"
        @thermal-inactive="onPlayerInactive(playerTypes.THERMAL)"
        @stream-stop="onStreamStop"
        @waiting="onPlayerWaiting"
        @can-play="onPlayerCanPlay"
      />
    </div>
  </div>
</template>

<script>
import VideoPlayer from './VideoPlayer.vue';
import IconFullscreen from '../icons/IconFullscreen.svg?component';
import { VideoSyncManager } from './video-sync-manager';
import { CustomControlButton } from './customVideoJsPlugins/customControlButton';
import IconSwitchSides from '../icons/IconSwitchSides.svg?component';
import IconExitFullscreen from '../icons/IconExitFullscreen.svg?component';
import BaseLoader from '../base/BaseLoader.vue';
import { shallowRef } from 'vue';
import { mapState } from 'pinia';
import { useVideoPlayerStore } from '../../store/VideoPlayerStore';
const PLAYLIST_LOAD_TIME_LIMIT_MS = 5000;

export default {
  name: 'SplitVideoManager',
  components: { VideoPlayer, BaseLoader, IconFullscreen, IconExitFullscreen, IconSwitchSides },
  props: {
    thermalVideoDelay: {
      type: Number,
      default: 0
    }
  },
  emits: ['close', 'stream-stop', 'is-streams-exist'],
  data() {
    return {
      videoSyncManager: null,
      playersData: {
        front: {},
        rear: {},
        thermal: {}
      },
      playerTypes: {
        FRONT: 'front',
        REAR: 'rear',
        THERMAL: 'thermal'
      },
      checkStreamsExistInterval: null,
      positionStyle: {
        bottom: '1rem',
        left: '1rem'
      },
      isDragging: false,
      fullscreenIcon: shallowRef(IconFullscreen),
      isLoading: true,
      reversePlayersLayout: false
    };
  },
  computed: {
    ...mapState(useVideoPlayerStore, ['thermal']),
    activePlayersGroupMainPlayer() {
      return this.playersData[this.playerTypes.FRONT].player;
    },
    activePlayersGroupMainVideoElement() {
      return this.activePlayersGroupMainPlayer.el().getElementsByTagName('video')[0];
    },
    areAllPlayersReady() {
      return (
        this.playersData[this.playerTypes.FRONT].isReady &&
        this.playersData[this.playerTypes.REAR].isReady &&
        (!this.thermal.src || this.playersData[this.playerTypes.THERMAL].isInactive || this.playersData[this.playerTypes.THERMAL].isReady)
      );
    },
    fullscreenButtonTitle() {
      return document?.fullscreenElement ? 'Exit Fullscreen' : 'Fullscreen';
    }
  },
  beforeUnmount() {
    clearInterval(this.checkStreamsExistInterval);
  },
  methods: {
    addMarker(data) {
      data.time = data.time || this.activePlayersGroupMainPlayer.currentTime();

      if (this.$refs.frontVideoPlayer) {
        this.$refs.frontVideoPlayer.addMarker(data);
      }
      if (this.$refs.rearVideoPlayer) {
        this.$refs.rearVideoPlayer.addMarker(data);
      }
    },
    async onAllPlayersReady() {
      const playersGroups = {};

      if (this.playersData[this.playerTypes.FRONT].player) {
        const frontPlayersGroup = [];
        frontPlayersGroup.push(this.playersData[this.playerTypes.FRONT].player);

        if (this.playersData[this.playerTypes.THERMAL].player) {
          frontPlayersGroup.push(this.playersData[this.playerTypes.THERMAL].player);
        }
        if (this.playersData[this.playerTypes.REAR].player) {
          frontPlayersGroup.push(this.playersData[this.playerTypes.REAR].player);
        }

        playersGroups['all_players'] = frontPlayersGroup;
      }
      let delayMap = {};

      if (this.playersData[this.playerTypes.THERMAL] && this.playersData[this.playerTypes.THERMAL].player) {
        delayMap[this.playersData[this.playerTypes.THERMAL].player.id()] = this.thermalVideoDelay;
      }

      this.videoSyncManager = new VideoSyncManager({
        groups: playersGroups,
        activeGroup: 'all_players',
        delayMap
      });
      for (const playerData of Object.values(this.playersData)) {
        const player = playerData.player;
        if (player.paused()) {
          await player.play();
        }
      }
      this.isLoading = false;
    },
    async onPlayerReady(type, player) {
      this.playersData[type].isReady = true;
      this.playersData[type].player = player;

      if (player.controls()) {
        player.controlBar.el().classList.add('control-bar');
        const controlBar = player.getChild('controlBar');
        const switchPlayersSidesButton = new CustomControlButton(player, {
          name: 'switchPlayersSidesButton',
          contentElement: this.$refs.reverseButton.$el
        });
        controlBar.addChild(switchPlayersSidesButton, {}, controlBar.children().length - 1);
        const fullscreenButton = new CustomControlButton(player, {
          name: 'fullscreenCustomButton',
          contentElement: this.$refs.fullscreenButton.$el
        });
        controlBar.addChild(fullscreenButton, {}, controlBar.children().length - 1);
      }

      if (this.areAllPlayersReady) {
        await this.onAllPlayersReady();
      }
    },
    onPlayerInactive(type) {
      this.playersData[type].isInactive = true;
      this.playersData[type].isReady = false;
    },
    pauseVideos() {
      if (this.activePlayersGroupMainPlayer) {
        this.activePlayersGroupMainPlayer.pause();
      }
    },
    onStreamStop() {
      clearInterval(this.checkStreamsExistInterval);
      this.checkStreamsExistInterval = null;
      this.$emit('stream-stop');
    },
    onPlayerWaiting() {
      if (!this.checkStreamsExistInterval) {
        this.checkStreamsExistInterval = setInterval(() => {
          this.$emit('is-streams-exist');
        }, PLAYLIST_LOAD_TIME_LIMIT_MS);
      }
    },
    onPlayerCanPlay() {
      clearInterval(this.checkStreamsExistInterval);
      this.checkStreamsExistInterval = null;
    },
    onMouseDown() {
      this.isDragging = true;
      window.addEventListener('mousemove', this.drag);
      window.addEventListener('mouseup', this.onMouseUp);
      window.addEventListener('mouseleave', this.onMouseUp);
      window.addEventListener('click', this.onMouseUp);
    },
    drag(event) {
      if (this.isDragging) {
        const boundingRect = this.$refs.splitVideoManager.getBoundingClientRect();
        delete this.positionStyle.bottom;
        this.positionStyle.top = `${boundingRect.top + event.movementY}px`;
        this.positionStyle.left = `${boundingRect.left + event.movementX}px`;
      }
    },
    onMouseUp() {
      this.isDragging = false;
      window.removeEventListener('mousemove', this.drag);
      window.removeEventListener('mouseup', this.onMouseUp);
      window.removeEventListener('mouseleave', this.onMouseUp);
      window.removeEventListener('click', this.onMouseUp);
    },
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        this.$refs.players.requestFullscreen();
        this.fullscreenIcon = shallowRef(IconExitFullscreen);
      } else {
        document.exitFullscreen();
        this.fullscreenIcon = shallowRef(IconFullscreen);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.split-video-manager {
  position: fixed;
  width: 70%;
  z-index: 2;
  border: 4px solid var(--primaryColorDarkShade1);
  background: var(--primaryColorDarkShade1);
  filter: drop-shadow(2px 4px 6px var(--shadowColor));

  .hide {
    opacity: 0;
    pointer-events: none;
  }

  .loader {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
  }

  .icon {
    width: 1.2rem;
    height: 1.2rem;
  }

  .close-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 2.15rem;
    height: 2rem;
    background: var(--primaryColor);
    text-align: center;
    transform: translate(35%, -50%);
    color: var(--secondaryTextColor);
    border-radius: 50%;
    border: 3px solid var(--primaryColorDarkShade1);
    font-size: 1.1rem;
    cursor: pointer;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    line-height: 2rem;
    z-index: 1;

    &:hover {
      transform: translate(35%, -50%) scale(1.05);
      filter: brightness(1.1);
    }

    &:active {
      transform: translate(35%, -50%) scale(0.95);
      filter: brightness(1.2);
    }
  }

  .drag-button {
    position: absolute;
    top: -1.4rem;
    height: 1.4rem;
    left: 50%;
    transform: translateX(-50%);
    background: var(--primaryColorDarkShade1);
    width: 6.5rem;
    text-align: center;
    clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
    font-weight: bold;
    cursor: move;
    z-index: 1;

    &:hover {
      filter: brightness(1.1);
    }

    &:active {
      filter: brightness(1.2);
    }
  }

  .players {
    display: grid;
    grid-template-columns: 1fr 1fr;

    :deep(.control-bar) {
      opacity: 1;
      position: fixed;
      z-index: 4;
    }

    &:fullscreen {
      align-content: center;
    }

    .player {
      grid-row: 1;

      &.reverse {
        grid-column: 1;
      }
    }
  }
}
</style>
