<template>
  <label :class="['base-checkbox', { disabled }]">
    <input
      type="checkbox"
      class="checkbox-input"
      :disabled="disabled"
      :checked="checked"
      :class="{ checked: checked && !indeterminate, indeterminate }"
      @change="onChange"
    />
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    checked: {
      type: Boolean,
      required: true
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:checked', 'update:indeterminate'],
  methods: {
    onChange(event) {
      if (this.indeterminate) {
        this.$emit('update:indeterminate', false);
      }
      this.$emit('update:checked', event.target.checked);
    }
  }
};
</script>

<style scoped lang="scss">
.base-checkbox {
  display: inline-flex;
  cursor: pointer;
  column-gap: 0.5rem;
  align-items: center;

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:hover:not(.disabled) {
    filter: brightness(1.1);
  }

  .checkbox-input {
    appearance: none;
    cursor: inherit;
    position: relative;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 3px;
    border: 0.15rem solid var(--highlightColor);
    margin: 0;

    &.checked {
      &:after {
        content: '';
        pointer-events: none;
        position: absolute;
        border: solid var(--highlightColor);
        border-width: 0 0.14rem 0.14rem 0;
        width: 0.3rem;
        height: 0.7rem;
        transform: rotate(45deg);
        left: Calc(50% - 0.22rem);
        top: Calc(40% - 0.42rem);
      }
    }

    &.indeterminate {
      &:after {
        content: '';
        pointer-events: none;
        position: absolute;
        width: 0.5rem;
        height: 0.14rem;
        background-color: var(--highlightColor);
        top: calc(50% - 0.075rem);
        left: calc(50% - 0.25rem);
      }
    }

    &:focus-visible {
      outline: 2px solid var(--secondaryOverlayShade2);
    }

    &:active:enabled {
      filter: brightness(1.2);
      transform: scale(0.95);
    }
  }
}
</style>
