class LocalStorageManager {
  static setItem(key, item) {
    if (localStorage) {
      localStorage.setItem(`${this._namespace}.${key}`, JSON.stringify(item));
      return true;
    }
    return false;
  }

  static getItem(key) {
    if (localStorage) {
      const item = localStorage.getItem(`${this._namespace}.${key}`);

      if (item) {
        try {
          return JSON.parse(item);
        } catch (e) {
          return item;
        }
      }
    }
  }

  static removeItem(key) {
    if (localStorage) {
      localStorage.removeItem(`${this._namespace}.${key}`);
    }
  }
}

LocalStorageManager._namespace = import.meta.env.VITE_LOCAL_STORAGE_NAMESPACE;
export default LocalStorageManager;
