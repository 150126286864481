<template>
  <div class="notification-badge">
    <div class="count">{{ count }}</div>
  </div>
</template>

<script>
export default {
  name: 'BaseNotificationBadge',
  props: {
    count: {
      type: Number,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.notification-badge {
  background-color: var(--notificationsBudgeColor);
  border-radius: 15px;
  min-width: 1.3rem;
  padding: 1px 3px;

  .count {
    text-align: center;
    font-family: var(--font-family-secondary);
    font-size: 0.9rem;
    font-weight: bold;
    color: var(--primaryColor);
  }
}
</style>
