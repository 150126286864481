const filterSchedulesByActionType = (zoneSchedules, handlerType) => {
  const results = [];
  if (zoneSchedules && zoneSchedules.data) {
    zoneSchedules.data.forEach(dayConfiguration => {
      const handler = dayConfiguration.schedules.find(schedule => schedule.handler === handlerType);
      if (handler) {
        results.push({
          day: dayConfiguration.day,
          actions: handler.actions
        });
      }
    });
  }
  return results;
};

export const getScheduledMissions = zoneSchedule => {
  return filterSchedulesByActionType(zoneSchedule, 'mission');
};
export const getScheduledMotionDetection = zoneSchedule => {
  return filterSchedulesByActionType(zoneSchedule, 'motion');
};
export const getScheduledVideoStreaming = zoneSchedule => {
  return filterSchedulesByActionType(zoneSchedule, 'video');
};
export const buildZoneScheduleObject = ({ accountId, zoneId, deviceId }) => {
  return {
    account_id: accountId,
    zone_id: zoneId,
    device_id: deviceId,
    data: []
  };
};

export const buildZoneScheduleDayObject = ({ dayIndex }) => {
  return {
    day: dayIndex,
    schedules: []
  };
};

export const clearDayConfiguration = (data, dayIndex) => {
  let index = data.findIndex(dayConfiguration => dayConfiguration.day === dayIndex);
  if (index > -1) {
    data.splice(index, 1);
  }
};

export const clearHandler = (schedules, handler) => {
  const handlerIndex = schedules.findIndex(schedule => schedule.handler === handler);
  if (handlerIndex > -1) {
    schedules.splice(handlerIndex, 1);
  }
};
