import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 10 10",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", { fill: "#BAEBF2" }, [
      _createElementVNode("path", {
        d: "m 2.6002 -2.7398 c 0.6733 0.6715 1.0441 1.5623 1.0441 2.5097 s -0.3708 1.8385 -1.0445 2.509 c -0.0426 0.0426 -0.0985 0.0639 -0.1543 0.0639 c -0.0559 0 -0.1117 -0.021 -0.154 -0.0639 c -0.0856 -0.0849 -0.0856 -0.2224 0 -0.3073 c 0.5912 -0.5888 0.9166 -1.3702 0.9166 -2.2021 c 0 -0.8311 -0.3258 -1.6133 -0.9166 -2.2017 c -0.0856 -0.0849 -0.0856 -0.2228 0 -0.3073 c 0.0852 -0.0852 0.2235 -0.0852 0.3087 0 z m -0.6174 0.6146 c 0.5088 0.5063 0.7888 1.1792 0.7888 1.8951 s -0.28 1.3887 -0.7888 1.8948 c -0.0423 0.0426 -0.0982 0.0639 -0.154 0.0639 c -0.0559 0 -0.1117 -0.021 -0.1543 -0.0639 c -0.0852 -0.0849 -0.0852 -0.2224 0 -0.3073 c 0.426 -0.4239 0.6607 -0.9876 0.6607 -1.5875 c 0 -0.5996 -0.2346 -1.1635 -0.6611 -1.5875 c -0.0852 -0.0845 -0.0852 -0.2224 0 -0.3073 s 0.2235 -0.0849 0.3087 0 z z z m -1.1076 1.225 h -0.8035 v -1.5085 c 0 -0.2179 -0.1729 -0.3946 -0.3863 -0.3946 c -0.213 0 -0.3859 0.1767 -0.3859 0.3946 v 1.5085 h -0.9659 v -1.5085 c 0 -0.2179 -0.1729 -0.3946 -0.3859 -0.3946 s -0.3863 0.1767 -0.3863 0.3946 v 1.5085 h -0.8032 c -0.2134 0 -0.3863 0.177 -0.3863 0.395 s 0.1729 0.3946 0.3859 0.3946 h 0.0384 v 0.829 c 0.0004 1.1388 0.9069 2.0655 2.0201 2.0655 c 1.1139 0 2.0197 -0.9267 2.0197 -2.0655 v -0.8293 h 0.0384 c 0.2134 0 0.3863 -0.1767 0.3863 -0.3946 c 0 -0.2179 -0.1729 -0.3946 -0.3859 -0.3946 z m -5.3752 0.9002 a 0.5 0.5 90 0 1 9 0 a 0.5 0.5 90 0 1 -9 0 m -0.5 0 a 0.5 0.5 90 0 0 10 0 a 0.5 0.5 90 0 0 -10 0",
        transform: "translate(5 5)"
      })
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }