import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 311 311",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M234.309,187.535c-15.435,0-30.111,5.707-41.52,16.107l-58.162-26.847c2.52-6.823,3.796-13.977,3.796-21.296\n\tc0-7.408-1.302-14.633-3.873-21.515l58.054-26.796c11.434,10.508,26.176,16.274,41.705,16.274c34.039,0,61.732-27.693,61.732-61.733\n\tc0-34.038-27.693-61.73-61.732-61.73s-61.731,27.692-61.731,61.73c0,6.548,1.023,12.981,3.044,19.154l-59.271,27.358\n\tc-11.119-9.346-25.138-14.475-39.658-14.475c-34.039,0-61.732,27.693-61.732,61.732c0,34.04,27.693,61.733,61.732,61.733\n\tc14.616,0,28.704-5.187,39.855-14.637l59.15,27.302c-2.071,6.245-3.12,12.75-3.12,19.37c0,34.039,27.692,61.732,61.731,61.732\n\ts61.732-27.693,61.732-61.732C296.041,215.229,268.348,187.535,234.309,187.535z M234.309,280\n\tc-16.945,0-30.731-13.786-30.731-30.732s13.786-30.732,30.731-30.732c16.946,0,30.732,13.786,30.732,30.732S251.255,280,234.309,280\n\tz M234.309,92.463c-16.945,0-30.731-13.787-30.731-30.733c0-16.945,13.786-30.73,30.731-30.73c16.946,0,30.732,13.786,30.732,30.73\n\tC265.041,78.677,251.255,92.463,234.309,92.463z M76.691,124.768c16.945,0,30.731,13.786,30.731,30.732\n\tc0,6.322-1.918,12.396-5.55,17.581c0,0-1.404,1.826-2.084,2.654c-5.854,6.675-14.26,10.498-23.098,10.498\n\tc-16.946,0-30.732-13.787-30.732-30.733S59.745,124.768,76.691,124.768z" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }