<template>
  <button class="base-button" :class="{ disabled }" :disabled="disabled" @click="onClick">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: '',
      validator: val => !val || val === 'submit'
    }
  },
  emits: ['click'],
  methods: {
    onClick(ev) {
      this.$emit('click', ev);
    }
  }
};
</script>

<style lang="scss" scoped>
.base-button {
  font-size: inherit;
  padding: 0.25rem 1rem;
  border-radius: 6px;
  border: 0;
  outline: none;
  transition: transform 0.5s, filter 0.3s;
  color: var(--textColor);
  background: var(--highlightColor);
  cursor: pointer;

  &:disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.4;
  }

  &:not([disabled]) {
    &:focus-visible {
      box-shadow: inset 0 0 0 4px var(--overlayShade3);
    }

    &:hover {
      filter: brightness(0.9);
    }

    &:active {
      filter: brightness(1.1);
      transform: translateY(3px);
    }
  }
}
</style>
