<template>
  <div class="mission-view">
    <div class="mission-data">
      <BaseLoader v-if="isLoading" class="loader" />
      <MissionDetails
        v-else
        ref="missionDetails"
        :absolute-frame-time="absoluteFrame"
        :is-fetching-streams="isFetchingStreams || videoPlayerStore.isFetchingConfig"
        @video-duration="onVideoDurationEvent"
        @event-click="onEventClick"
      />
    </div>
    <MapCanvas
      v-if="zoneId"
      ref="zoneMap"
      :indicators="indicators"
      :indicators-draw-order="MISSION_INDICATORS_DRAW_ORDER"
      :is-quick-action-menu-enabled="false"
      @map-tap="onMapTap"
    />
  </div>
</template>

<script>
import { DRAW_ICON_OPTIONS } from '../../consts/drawingConsts';
import MissionDetails from './MissionDetails.vue';
import BaseLoader from '../../components/base/BaseLoader.vue';
import MapCanvas from '../../components/theMap/MapCanvas.vue';
import { ERROR_MESSAGES, SNACKBAR_TYPE } from '../../consts/appConsts';
import { mapStores, mapState } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import { getStyleVar } from '../../utils/StyleUtils';
import { useAreasStore } from '../../store/AreasStore';
import { useDevicesStore } from '../../store/DevicesStore';
import { useEventListStore } from '../../store/EventListStore';
import { getEuclideanDistance, thickenPathForSmoothTransition } from '../../utils/PointsUtils';
import {
  isActive,
  getPathFilteredByPointsDistance,
  getClosestLocationByTimestamp,
  hasMissionEnded,
  deviceLabel,
  isUnknown,
  getColorState as getMissionStateColor,
  getRemainingPredictedPath,
  hasStarted,
  getLocationDisplayConfigForMap
} from '../../utils/models/MissionUtils';
import { getDisplayConfigForMap as getDeviceDisplayConfigForMap } from '../../utils/models/DeviceUtils';
import { getDisplayConfigForMap as getEventDisplayConfigForMap } from '../../utils/models/EventUtils';
import { DEVICES_TYPES } from '../../consts/deviceConsts';
import IconDrone from '../../components/icons/IconDrone.svg?component';
import { PLAYER_EVENTS, useVideoPlayerStore } from '../../store/VideoPlayerStore';
import { EVENT_TYPE_NAME } from '../../consts/eventConsts';
import { PAGES } from '../../router';

const MISSION_INDICATORS_DRAW_ORDER = ['paths', 'dots', 'groups', 'locations', 'icons', 'areas'];
const FETCH_LIVE_STREAMS_TIMEOUT_MS = 5000; // 5 seconds.
const MAX_LIVE_STREAMS_FETCH_ATTEMPTS = 12; // 5 * 12 => 60 sec max fetch attempt
const PATH_POINT_TIME_GAP_MS = 100;

export default {
  name: 'MissionView',
  components: {
    MapCanvas,
    MissionDetails,
    BaseLoader
  },
  beforeRouteLeave() {
    this.contextStore.setContextMission({});
    this.videoPlayerStore.$reset();
  },
  // Props are from the router
  props: {
    missionId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      absoluteFrame: 0,
      videoDurationInSec: 0,
      device: null,
      deviceDisplayConfig: null,
      fetchLiveStreamsInterval: null,
      isFetchingStreams: false,
      retryVideoFetchInterval: null,
      MISSION_INDICATORS_DRAW_ORDER,
      isLoading: false,
      extendedMissionPath: null
    };
  },
  computed: {
    ...mapStores(useContextStore, useVideoPlayerStore, useEventListStore),
    ...mapState(useContextStore, ['zoneId', 'zone', 'mission', 'isTechnician']),
    ...mapState(useAreasStore, ['activeNoFlightAreas']),
    ...mapState(useDevicesStore, ['getDeviceById']),
    ...mapState(useEventListStore, {
      getEventListItem: 'getItemById'
    }),
    indicators() {
      const paths = [{ path: this.mapPathFormatter() }];
      if (this.isMissionActive) {
        paths.push({
          path: this.mapPredictedPathFormatter(),
          color: getStyleVar('--highlightColorShade1')
        });
      }
      const indicators = {
        paths,
        contextId: this.mission?.id,
        icons: this.mapIconsFormatter(),
        locations: getLocationDisplayConfigForMap(this.mission, this.isTechnician, this.absoluteFrame, this.skipToTimestamp),
        areas: this.activeNoFlightAreas.map(area => ({
          ...area,
          isInBackground: true
        }))
      };
      return indicators;
    },
    closestLocationByFrame() {
      // For live mission - path is accumulation of device locations, for past missions - path is thickened for display purposes
      const basePath = this.isMissionActive ? this.mission.path : this.extendedMissionPath;
      return getClosestLocationByTimestamp(basePath, this.absoluteFrame);
    },
    closestLocationByFrameHeading() {
      return this.closestLocationByFrame.h;
    },
    videoOffsetInMS() {
      let videoOffset = 0;
      if (this.videoDurationInSec && this.mission?.start_timestamp && this.mission?.end_timestamp) {
        const missionDurationInMS = this.mission.end_timestamp - this.mission.start_timestamp;
        const videoDurationInMS = this.videoDurationInSec * 1000;
        videoOffset = missionDurationInMS - videoDurationInMS;
      }
      return videoOffset;
    },
    isMissionActive() {
      return isActive(this.mission);
    }
  },
  async created() {
    let errorCode;
    try {
      if (!this.mission || this.mission.id !== this.missionId) {
        await this.loadMissionData(true);
      }
      if (!this.mission?.streamsData?.success && this.isMissionActive) {
        this.startLiveVideoFetchInterval();
      }

      if (!this.isMissionActive) {
        if (this.mission.device_type_id === DEVICES_TYPES.SKYDIO) {
          this.extendedMissionPath = this.mission && this.mission.path && thickenPathForSmoothTransition(this.mission.path, PATH_POINT_TIME_GAP_MS);
        } else {
          this.extendedMissionPath = this.mission.path;
        }
      }
    } catch (e) {
      console.error(e);
      errorCode = e.status;
    }

    if (!this.mission || this.mission.zone_id !== this.zoneId) {
      this.contextStore.showSnackbar({
        message: errorCode === 401 || errorCode === 404 ? ERROR_MESSAGES.GENERIC_401 : ERROR_MESSAGES.UNABLE_TO_DISPLAY_PAGE,
        type: SNACKBAR_TYPE.ERROR
      });

      this.$router.push({
        name: PAGES.EVENTS
      });
      return;
    }

    const deviceId = this.mission.device_id;
    this.device = this.getDeviceById(deviceId);
    if (!this.device) {
      // Mission's device is no longer available
      this.deviceDisplayConfig = {
        icon: IconDrone,
        iconKey: this.mission.device_type_id === DEVICES_TYPES.SKYDIO ? DRAW_ICON_OPTIONS.SKYDIO_DRONE : DRAW_ICON_OPTIONS.DRONE,
        text: deviceLabel(this.mission)
      };
    }
  },
  mounted() {
    this.videoPlayerStore.rear.eventBus.addEventListener(PLAYER_EVENTS.DURATION, this.onVideoDurationEvent);
    this.videoPlayerStore.front.eventBus.addEventListener(PLAYER_EVENTS.DURATION, this.onVideoDurationEvent);
    this.videoPlayerStore.rear.eventBus.addEventListener(PLAYER_EVENTS.FRAME, this.onFrameEvent);
    this.videoPlayerStore.front.eventBus.addEventListener(PLAYER_EVENTS.FRAME, this.onFrameEvent);
  },
  beforeUnmount() {
    this.clearFetchLiveStreamInterval();
    this.videoPlayerStore.rear.eventBus.removeEventListener(PLAYER_EVENTS.DURATION, this.onVideoDurationEvent);
    this.videoPlayerStore.front.eventBus.removeEventListener(PLAYER_EVENTS.DURATION, this.onVideoDurationEvent);
    this.videoPlayerStore.rear.eventBus.removeEventListener(PLAYER_EVENTS.FRAME, this.onFrameEvent);
    this.videoPlayerStore.front.eventBus.removeEventListener(PLAYER_EVENTS.FRAME, this.onFrameEvent);
  },
  methods: {
    async loadMissionData(fetchStreams = false) {
      try {
        // Start by taking data from the store if exist
        this.isLoading = true && !this.mission;
        await this.eventListStore.getOrFetchMissionById({ id: this.missionId, fetchStreams }, true);
        const markers = [];

        if (hasStarted(this.mission)) {
          this.mission?.achieved_pois?.forEach?.(achievedPOI => {
            markers.push({
              timestamp: achievedPOI.timestamp,
              type: 'checkpoint',
              label: 'Checkpoint #' + achievedPOI.poi_index
            });
          });
          this.mission?.events?.map?.(event => {
            markers.push({
              timestamp: event.timestamp,
              type: 'event',
              label: EVENT_TYPE_NAME[event.event_type]
            });
          });
        }

        const streams = this.mission?.streamsData?.data;
        this.videoPlayerStore.setPlayerConfig({
          streams,
          markers,
          isLive: this.isMissionActive,
          device_type_id: this.mission?.device_type_id,
          startVideoAtTimestamp: this.mission?.start_timestamp
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    startLiveVideoFetchInterval() {
      this.isFetchingStreams = true;
      let retries = 0;

      // If live streams not fetched yet try fetch them for 30 sec or until mission end or turn unknown
      this.fetchLiveStreamsInterval = setInterval(async () => {
        if (!this.mission?.streamsData?.success && this.isMissionActive && retries < MAX_LIVE_STREAMS_FETCH_ATTEMPTS) {
          retries++;
          try {
            await this.loadMissionData(true);

            if (this.mission?.streamsData?.success) {
              this.clearFetchLiveStreamInterval();
            }
          } catch (e) {
            console.error(e);
          }
        } else {
          this.clearFetchLiveStreamInterval();
        }
      }, FETCH_LIVE_STREAMS_TIMEOUT_MS);
    },
    mapIconsFormatter() {
      return [...this.mapEventsFormatter(), ...this.mapDevicesFormatter()];
    },
    mapEventsFormatter() {
      let events = [];
      if (this.mission?.events?.length) {
        this.mission.events.forEach(event => {
          if (event.location) {
            events.push(
              getEventDisplayConfigForMap({
                event,
                clickable: true,
                skipVideoFunction: this.skipToTimestamp
              })
            );
          }
        });
      }
      return events;
    },
    mapDevicesFormatter() {
      let devices = [];
      if (this.isMissionActive) {
        if (this.device && this.closestLocationByFrame) {
          const displayConfig = {
            ...getDeviceDisplayConfigForMap({ device: this.device, clickable: true }),
            ...this.closestLocationByFrame,
            customData: {
              heading: this.closestLocationByFrame.h
            }
          };
          devices.push(displayConfig);
        } else if (this.device && this.device.location) {
          devices.push(getDeviceDisplayConfigForMap({ device: this.device, clickable: true }));
        }
      } else if ((hasMissionEnded(this.mission) || isUnknown(this.mission)) && this.closestLocationByFrame) {
        const drawConfig = this.device ? getDeviceDisplayConfigForMap({ device: this.device, clickable: true }) : this.deviceDisplayConfig;
        devices.push({
          ...drawConfig,
          ...this.closestLocationByFrame,
          customData: {
            heading: this.closestLocationByFrame.h
          }
        });
      }
      return devices;
    },
    mapPathFormatter() {
      let formattedPath = [];
      if (this.mission) {
        let endIndex = null;
        const liveMissionDisplay = this.isMissionActive && this.device && this.closestLocationByFrame;

        if (liveMissionDisplay) {
          endIndex = this.closestLocationByFrame.index;
        }

        let filteredPath = getPathFilteredByPointsDistance(this.mission, {
          endIndex
        });
        formattedPath = filteredPath.map(point => ({
          x: point.data.x,
          y: point.data.y
        }));

        if (liveMissionDisplay) {
          formattedPath.push({
            x: this.closestLocationByFrame.x,
            y: this.closestLocationByFrame.y
          });
        }

        if (hasMissionEnded(this.mission) && formattedPath.length > 0) {
          formattedPath[formattedPath.length - 1].color = getMissionStateColor(this.mission);
        }
      }

      return formattedPath;
    },
    mapPredictedPathFormatter() {
      let formattedPath = [];
      if (this.mission) {
        let pathEndIndex = null;

        if (this.isMissionActive && this.device && this.closestLocationByFrame) {
          pathEndIndex = this.closestLocationByFrame.index;
        }

        const requestedPath = getRemainingPredictedPath(this.mission, {
          pathEndIndex
        });
        if (Array.isArray(requestedPath)) {
          if (this.device && this.closestLocationByFrame) {
            formattedPath.push({
              x: this.closestLocationByFrame.x,
              y: this.closestLocationByFrame.y
            });
          }
          requestedPath.forEach(point => {
            formattedPath.push({
              x: point.x,
              y: point.y
            });
          });
        }
      }
      return formattedPath;
    },
    onFrameEvent(e) {
      const { relativeFrame, absoluteFrame } = e.detail;
      if (absoluteFrame) {
        this.absoluteFrame = absoluteFrame;
      } else if (relativeFrame) {
        this.absoluteFrame = this.mission.start_timestamp + relativeFrame + this.videoOffsetInMS;
      }
    },
    onVideoDurationEvent(e) {
      if (!this.isMissionActive) {
        const duration = e.detail;
        this.videoDurationInSec = duration;
      }
    },
    onEventClick(eventId) {
      this.$router.push({
        name: PAGES.EVENT,
        params: { eventId }
      });
    },
    onMapTap({ roomPoint }) {
      const closestLocation = this.getClosestLocationByPoint(roomPoint);
      if (closestLocation) {
        this.videoPlayerStore.front.eventBus.dispatchEvent(new CustomEvent(PLAYER_EVENTS.FRAME_CHANGE, { detail: closestLocation.timestamp }));
        this.videoPlayerStore.rear.eventBus.dispatchEvent(new CustomEvent(PLAYER_EVENTS.FRAME_CHANGE, { detail: closestLocation.timestamp }));
      }
    },
    getClosestLocationByPoint(roomPoint) {
      let location = null;
      const missionPath = this.mission.path || [];
      if (missionPath.length > 0) {
        const closest = missionPath.reduce((prev, curr) => {
          return getEuclideanDistance(roomPoint, curr.data) < getEuclideanDistance(roomPoint, prev.data) ? curr : prev;
        });
        const distanceToClosest = getEuclideanDistance(roomPoint, closest.data);
        if (distanceToClosest < 1.5) {
          // ignore all clicks more than 1.5 meter from the path
          location = closest;
        }
      }
      return location;
    },
    clearFetchLiveStreamInterval() {
      clearInterval(this.fetchLiveStreamsInterval);
      this.fetchLiveStreamsInterval = null;
      this.isFetchingStreams = false;
    },
    skipToTimestamp(timestamp) {
      this.videoPlayerStore.front.eventBus.dispatchEvent(new CustomEvent(PLAYER_EVENTS.FRAME_CHANGE, { detail: timestamp }));
    }
  }
};
</script>

<style scoped lang="scss">
.mission-view {
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  .mission-data {
    flex-basis: 50rem;
    max-width: 50%;
    display: flex;
    flex-direction: column;

    .loader {
      margin: auto;
      width: 3.5rem;
      height: 3.5rem;
    }
  }
}
</style>
