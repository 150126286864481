<template>
  <div class="separator"><slot /></div>
</template>

<script>
export default {
  name: 'BaseTextSeparator'
};
</script>

<style scoped lang="scss">
.separator {
  display: flex;
  align-items: flex-start;
  column-gap: 1rem;
  color: var(--primaryColorBrightShade1);
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: bold;

  &::after,
  &::before {
    content: '';
    border-bottom: 2px solid var(--primaryColorBrightShade1);
    height: 50%;
    flex-grow: 1;
  }
}
</style>
