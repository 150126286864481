import apiRequest from './apiRequest';

class EventsService {
  async fetchEventById(eventId, include) {
    try {
      const params = {};
      if (include.stream) {
        params.include_stream = true;
        params.include_od = true;
      }
      const response = await apiRequest({
        path: `/account/{accountId}/events/${eventId}`,
        method: 'GET',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async createEvent(eventObj) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/events`,
        method: 'POST',
        data: eventObj
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async patchEvent(eventObj) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/events/${eventObj.id}`,
        method: 'PATCH',
        data: eventObj
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchEventStreams(eventId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/events/${eventId}/streams`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchStreamMp4File(eventId, cameraType, sourceType) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/events/${eventId}/download-stream-video`,
        method: 'POST',
        params: {
          cameraType,
          sourceType
        },
        responseType: 'blob',
        timeout: 60 * 1000 // 60 seconds
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async rerunEventVideoDetection(eventId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/events/${eventId}/run-video-detection`,
        method: 'POST'
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchEventSnapshot(eventId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/events/${eventId}/snapshot`,
        method: 'GET'
      });
      if (response.status === 200) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new EventsService();
