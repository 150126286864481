import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "-1 -1 42 42",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M39,7.3c-0.2-0.6-0.7-1-1.3-1.1c-0.6-0.1-1.2,0.1-1.6,0.5l-5.7,5.7l-2.8-2.8l5.7-5.7c0.4-0.4,0.6-1,0.5-1.6\n\tc-0.1-0.6-0.5-1.1-1.1-1.3c-4.1-1.6-8.9-0.6-12,2.5c-2.9,2.9-4,7.1-2.8,11.1L2.1,30.4l-1.3,1.4c-0.7,0.7-0.7,1.8,0,2.4l5.1,5.1\n\tc0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5l1.4-1.3c6.5-6.5,15-15,15.8-15.8c3.9,1.2,8.1,0.1,11-2.8C39.6,16.2,40.6,11.5,39,7.3z\n\t M25.9,20.3c-0.6-0.2-1.3,0-1.8,0.4L8.3,36.6L7,37.8L2.2,33l17.1-17.1c0.5-0.5,0.6-1.2,0.4-1.8c-1-3.3-0.1-6.8,2.3-9.2\n\tc2.6-2.6,6.4-3.4,9.8-2.1L25,9.5l-0.1,0.1l5.5,5.5l7-6.9c1.2,3.4,0.4,7.2-2.2,9.8C32.7,20.4,29.2,21.3,25.9,20.3z" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }