// Lazy loaded views
const IntegrationsView = () => import('../views/IntegrationsView/IntegrationsView.vue');
const MapView = () => import('../views/MapView/MapView.vue');
const SettingsView = () => import('../views/SettingsView.vue');
const DevicesConfigView = () => import('../views/DevicesConfigView/DevicesConfigView.vue');
const ZonesConfigView = () => import('../views/ZonesConfigView/ZonesConfigView.vue');
const ProfileView = () => import('../views/ProfileView/ProfileView.vue');
const UnsubscribeView = () => import('../views/UnsubscribeView.vue');
const UsersManagementView = () => import('../views/UsersManagementView/UsersManagementView.vue');
const UsersView = () => import('../views/UsersManagementView/UsersView.vue');
const RolesView = () => import('../views/UsersManagementView/RolesView.vue');
const GroupsView = () => import('../views/UsersManagementView/GroupsView.vue');
const ResetPasswordView = () => import('../views/ResetPasswordView/ResetPasswordView.vue');
const UserInvitationView = () => import('../views/UserInvitationView/UserInvitationView.vue');
const MapLandmarksPanel = () => import('../views/MapView/MapLandmarksPanel.vue');
const AreaRestrictionsPanel = () => import('../views/MapView/AreaRestrictionsPanel.vue');
const MapSettingsPanel = () => import('../views/MapView/MapSettingsPanel.vue');

import DashboardView from '../views/DashboardView/DashboardView.vue';
import MissionsView from '../views/MissionsView/MissionsView.vue';
import MissionView from '../views/MissionView/MissionView.vue';
import EventsView from '../views/EventsView/EventsView.vue';
import EventView from '../views/EventView/EventView.vue';
import DevicesView from '../views/DevicesView/DevicesView.vue';
import DeviceView from '../views/DeviceView/DeviceView.vue';
import LoginView from '../views/LoginView/LoginView.vue';
import MissionTemplatesPanel from '../views/MissionsView/MissionTemplatesPanel.vue';
import SchedulerPanel from '../views/MissionsView/SchedulerPanel.vue';

import { createRouter, createWebHistory } from 'vue-router';
import { MAP_MODE } from '../consts/mapConsts.js';
import { PRIVILEGES } from './../consts/authConsts.js';
import { useContextStore } from '../store/ContextStore.js';
import { useAreasStore } from '../store/AreasStore.js';
import { useDevicesStore } from '../store/DevicesStore.js';
import { useIntegrationsStore } from '../store/IntegrationsStore.js';
import { useUserManagementStore } from '../store/UserManagementStore.js';
import { useZonesStore } from '../store/ZonesStore.js';
import { useVideoPlayerStore } from '../store/VideoPlayerStore.js';
import { ERROR_MESSAGES, SNACKBAR_TYPE } from '../consts/appConsts.js';
import LocalStorageManager from '../services/LocalStorageManager.js';
import { shallowRef } from 'vue';

// Icons
import IconDashboard from '../components/icons/IconDashboard.svg?component';
import IconEvents from '../components/icons/IconEvents.svg?component';
import IconMissions from '../components/icons/IconMissions.svg?component';
import IconDrone from '../components/icons/IconDrone.svg?component';
import IconMap from '../components/icons/IconMap.svg?component';
import IconUser from '../components/icons/IconUser.svg?component';
import IconGroup from '../components/icons/IconGroup.svg?component';
import IconRole from '../components/icons/IconRole.svg?component';
import IconNote from '../components/icons/IconNote.svg?component';
import IconAreaRestrictions from '../components/icons/IconAreaRestrictions.svg?component';
import IconSettings from '../components/icons/IconSettings.svg?component';
import IconSchedule from '../components/icons/IconSchedule.svg?component';

export const PAGES = {
  LOGIN: 'LOGIN',
  DASHBOARD: 'DASHBOARD',
  EVENTS: 'EVENTS',
  EVENT: 'EVENT',
  MISSIONS: 'MISSIONS',
  MISSION: 'MISSION',
  DEVICES: 'DEVICES',
  MAP: 'MAP',
  DEVICES_CONFIG: 'DEVICES_CONFIG',
  ZONES_CONFIG: 'ZONES_CONFIG',
  USERS_MANAGEMENT: 'USERS_MANAGEMENT',
  INTEGRATIONS: 'INTEGRATIONS',
  GENERAL_SETTINGS: 'GENERAL_SETTINGS',
  HELPER: 'HELPER',
  HELLO_WORLD: 'HELLO_WORLD',
  SETTINGS: 'SETTINGS',
  PROFILE: 'PROFILE',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  GROUPS_MANAGEMENT: 'GROUPS_MANAGEMENT',
  ROLES_MANAGEMENT: 'ROLES_MANAGEMENT',
  RESET_PASSWORD: 'RESET_PASSWORD',
  DEVICE: 'DEVICE',
  USER_INVITATION: 'USER_INVITATION',
  USERS_MANAGEMENT_VIEW: 'USERS_MANAGEMENT_VIEW',
  MAP_SETTINGS: 'MAP_SETTINGS',
  LANDMARKS: 'LANDMARKS',
  AREAS: 'AREAS',
  MISSION_TEMPLATES: 'MISSION_TEMPLATES',
  SCHEDULE: 'SCHEDULE'
};

const ZONE_ATTRS = {
  BASE: ['id', 'account_id', 'name', 'halted', 'enabled'],
  VIEWS_WITH_MAP: ['map', 'map_landmarks', 'map_width', 'map_height', 'map_scale', 'allowed_point_color']
};

export const ROUTES_MAP = {
  [PAGES.DEVICE]: {
    name: PAGES.DEVICE,
    path: '/zones/:zoneId/devices/:deviceId',
    component: DeviceView,
    title: 'Device',
    meta: {
      requiresAuth: true,
      contextZoneAttrs: ZONE_ATTRS.VIEWS_WITH_MAP
    },
    props: route => ({ deviceId: Number(route.params.deviceId), zoneId: Number(route.params.zoneId) }),
    beforeEnter() {
      useVideoPlayerStore().$patch({ isFetchingConfig: true });
    }
  },
  [PAGES.RESET_PASSWORD]: {
    name: PAGES.RESET_PASSWORD,
    path: '/reset-password',
    component: ResetPasswordView,
    meta: { title: 'Reset Password' }
  },
  [PAGES.DEVICES]: {
    name: PAGES.DEVICES,
    path: '/zones/:zoneId/devices',
    component: DevicesView,
    icon: shallowRef(IconDrone),
    meta: {
      title: 'Devices',
      requiresAuth: true,
      allowZoneSwitch: true,
      contextZoneAttrs: ZONE_ATTRS.VIEWS_WITH_MAP
    }
  },
  [PAGES.EVENT]: {
    name: PAGES.EVENT,
    path: '/zones/:zoneId/events/:eventId',
    component: EventView,
    meta: {
      title: 'Event',
      requiresAuth: true,
      contextZoneAttrs: ZONE_ATTRS.VIEWS_WITH_MAP
    },
    props: true,
    beforeEnter() {
      useVideoPlayerStore().$patch({ isFetchingConfig: true });
    }
  },
  [PAGES.EVENTS]: {
    name: PAGES.EVENTS,
    path: '/zones/:zoneId/events',
    component: EventsView,
    icon: shallowRef(IconEvents),
    meta: {
      title: 'Events',
      requiresAuth: true,
      allowZoneSwitch: true,
      contextZoneAttrs: ZONE_ATTRS.VIEWS_WITH_MAP
    },
    props: route => ({ zoneId: Number(route.params.zoneId) })
  },
  [PAGES.USERS_MANAGEMENT_VIEW]: {
    name: PAGES.USERS_MANAGEMENT_VIEW,
    path: '/user-management',
    component: UsersManagementView,
    meta: {
      title: 'Users Management',
      requiresAuth: true
    },
    async beforeEnter() {
      try {
        const userManagementStore = useUserManagementStore();
        await Promise.all([
          userManagementStore.fetchUsers(),
          userManagementStore.fetchRoles(),
          userManagementStore.fetchGroups(),
          userManagementStore.fetchPrivileges()
        ]);
      } catch (e) {
        console.error(e);
        useContextStore().showSnackbar({
          message: 'Failed to fetch users data',
          type: SNACKBAR_TYPE.ERROR
        });
      }
    },
    privileges: [PRIVILEGES.MANAGE_USERS, PRIVILEGES.MANAGE_ROLES, PRIVILEGES.MANAGE_GROUPS],
    children: [
      {
        name: PAGES.USERS_MANAGEMENT,
        path: '',
        component: UsersView,
        meta: { title: 'Users Management' },
        label: 'Users',
        icon: IconUser,
        privileges: [PRIVILEGES.MANAGE_USERS]
      },
      {
        name: PAGES.ROLES_MANAGEMENT,
        path: 'roles',
        component: RolesView,
        meta: { title: 'Roles Management' },
        icon: IconRole,
        label: 'Roles',
        privileges: [PRIVILEGES.MANAGE_ROLES]
      },
      {
        name: PAGES.GROUPS_MANAGEMENT,
        path: 'groups',
        component: GroupsView,
        meta: { title: 'Groups Management' },
        label: 'Groups',
        icon: IconGroup,
        privileges: [PRIVILEGES.MANAGE_GROUPS]
      }
    ]
  },
  [PAGES.UNSUBSCRIBE]: {
    name: PAGES.UNSUBSCRIBE,
    path: '/unsubscribe',
    component: UnsubscribeView,
    meta: { title: 'Unsubscribe' }
  },
  [PAGES.PROFILE]: {
    name: PAGES.PROFILE,
    path: '/profile',
    component: ProfileView,
    meta: {
      title: 'Profile',
      requiresAuth: true
    }
  },
  [PAGES.SETTINGS]: {
    name: PAGES.SETTINGS,
    path: '/settings',
    component: SettingsView,
    meta: {
      title: 'General Settings',
      requiresAuth: true
    },
    async beforeEnter() {
      try {
        await useZonesStore().fetchZones({
          attrs: ['enabled_gen_ai_prompts']
        });
      } catch (err) {
        console.error(err);
        useContextStore().showSnackbar({
          message: ERROR_MESSAGES.UNABLE_TO_DISPLAY_PAGE,
          type: SNACKBAR_TYPE.ERROR
        });
      }
    }
  },
  [PAGES.INTEGRATIONS]: {
    name: PAGES.INTEGRATIONS,
    path: '/integrations',
    component: IntegrationsView,
    privileges: [PRIVILEGES.MANAGE_INTEGRATIONS],
    meta: {
      title: 'Integrations',
      requiresAuth: true
    },
    async beforeEnter() {
      try {
        await useIntegrationsStore().fetchIntegrations();
      } catch (err) {
        console.error(err);
        useContextStore().showSnackbar({
          message: ERROR_MESSAGES.UNABLE_TO_DISPLAY_PAGE,
          type: SNACKBAR_TYPE.ERROR
        });
      }
    }
  },
  [PAGES.DEVICES_CONFIG]: {
    name: PAGES.DEVICES_CONFIG,
    path: '/devices-config',
    component: DevicesConfigView,
    privileges: [PRIVILEGES.MANAGE_DEVICES],
    meta: {
      title: 'Devices Management',
      requiresAuth: true
    },
    async beforeEnter() {
      try {
        await useDevicesStore().fetchDevices({ include_disabled: true });
      } catch (err) {
        console.error(err);
        useContextStore().showSnackbar({
          message: ERROR_MESSAGES.UNABLE_TO_DISPLAY_PAGE,
          type: SNACKBAR_TYPE.ERROR
        });
      }
    }
  },
  [PAGES.ZONES_CONFIG]: {
    name: PAGES.ZONES_CONFIG,
    path: '/zones-config',
    component: ZonesConfigView,
    privileges: [PRIVILEGES.HALT_ALL_FLIGHTS],
    meta: {
      title: 'Zones Management',
      requiresAuth: true
    },
    async beforeEnter() {
      try {
        let requiredAttrs = [...ZONE_ATTRS.BASE];
        if (useContextStore().isTechnician) {
          requiredAttrs.push('thermal_min_threshold', 'thermal_max_threshold');
        }
        await useZonesStore().fetchZones({
          attrs: requiredAttrs,
          include_disabled: true
        });
      } catch (err) {
        console.error(err);
        useContextStore().showSnackbar({
          message: ERROR_MESSAGES.UNABLE_TO_DISPLAY_PAGE,
          type: SNACKBAR_TYPE.ERROR
        });
      }
    }
  },
  [PAGES.LOGIN]: {
    name: PAGES.LOGIN,
    path: '/login',
    component: LoginView,
    meta: { title: 'Login' },
    props: true
  },
  [PAGES.USER_INVITATION]: {
    name: PAGES.USER_INVITATION,
    path: '/user-invitation',
    component: UserInvitationView,
    meta: { title: 'User Invitation' }
  },
  [PAGES.DASHBOARD]: {
    name: PAGES.DASHBOARD,
    icon: shallowRef(IconDashboard),
    component: DashboardView,
    path: '/',
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
      zonesAttrs: ['thumbnail']
    }
  },
  [PAGES.MISSIONS]: {
    name: PAGES.MISSIONS,
    path: '/zones/:zoneId/missions',
    component: MissionsView,
    icon: shallowRef(IconMissions),
    privileges: [PRIVILEGES.MANAGE_MISSION_TEMPLATES, PRIVILEGES.SCHEDULE_MISSIONS, PRIVILEGES.INVOKE_MISSION_TEMPLATE],
    meta: {
      title: 'Missions',
      requiresAuth: true,
      allowZoneSwitch: true,
      contextZoneAttrs: [...ZONE_ATTRS.VIEWS_WITH_MAP, 'enabled_gen_ai_prompts']
    },
    children: [
      {
        name: PAGES.MISSION_TEMPLATES,
        path: '',
        meta: { title: 'Missions', initialMapMode: MAP_MODE.DEFAULT },
        icon: IconMissions,
        component: MissionTemplatesPanel
      },
      {
        name: PAGES.SCHEDULE,
        path: 'schedule',
        meta: { title: 'Schedule', initialMapMode: MAP_MODE.DEFAULT },
        icon: IconSchedule,
        privileges: [PRIVILEGES.SCHEDULE_MISSIONS],
        component: SchedulerPanel
      }
    ]
  },
  [PAGES.MISSION]: {
    name: PAGES.MISSION,
    path: '/zones/:zoneId/missions/:missionId',
    component: MissionView,
    meta: {
      title: 'Mission',
      requiresAuth: true,
      contextZoneAttrs: ZONE_ATTRS.VIEWS_WITH_MAP
    },
    props: true,
    beforeEnter() {
      useVideoPlayerStore().$patch({ isFetchingConfig: true });
    }
  },
  [PAGES.MAP]: {
    name: PAGES.MAP,
    path: '/zones/:zoneId/map',
    component: MapView,
    icon: shallowRef(IconMap),
    privileges: [PRIVILEGES.MANAGE_AREA_RESTRICTIONS, PRIVILEGES.MANAGE_MAP_LANDMARKS],
    meta: {
      title: 'Map',
      requiresAuth: true,
      allowZoneSwitch: true,
      viewWithMap: true,
      contextZoneAttrs: ZONE_ATTRS.VIEWS_WITH_MAP
    },
    children: [
      {
        name: PAGES.LANDMARKS,
        path: '',
        meta: { title: 'Landmarks', initialMapMode: MAP_MODE.DEFAULT },
        icon: IconNote,
        privileges: [PRIVILEGES.MANAGE_MAP_LANDMARKS],
        component: MapLandmarksPanel
      },
      {
        name: PAGES.AREAS,
        path: 'areas',
        meta: { title: 'Areas', initialMapMode: MAP_MODE.AREA_VIEW },
        icon: IconAreaRestrictions,
        privileges: [PRIVILEGES.MANAGE_AREA_RESTRICTIONS],
        component: AreaRestrictionsPanel
      },
      {
        name: PAGES.MAP_SETTINGS,
        path: 'settings',
        meta: {
          title: 'Map Settings',
          initialMapMode: MAP_MODE.AREA_VIEW,
          contextZoneAttrs: [...ZONE_ATTRS.VIEWS_WITH_MAP, 'semantic_map_size', 'config_areas', 'map_params']
        },
        icon: IconSettings,
        privileges: [PRIVILEGES.TECH_ONLY],
        component: MapSettingsPanel
      }
    ]
  },
  fallback: {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
};

async function _loadAppInitialData(to) {
  try {
    // Skip initial fetch for zone_config as it will refetch anyway (to include disabled zones)
    if (to.name !== PAGES.ZONES_CONFIG) {
      useZonesStore().fetchZones({
        attrs: [...ZONE_ATTRS.BASE, ...(to.meta?.zonesAttrs || [])]
      });
    }
    // commented out until this functionality is re-implemented
    // zonesStore.fetchNextSchedules(NEXT_SCHEDULES_AMOUNT);
    await useDevicesStore().fetchDevices();
    useContextStore().$patch({ initialDataLoaded: true });
  } catch (err) {
    console.error(`Failed to initialize states`, err);
  }
}

export const router = createRouter({
  history: createWebHistory(),
  routes: Object.values(ROUTES_MAP),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

router.beforeEach(async (to, from) => {
  const contextStore = useContextStore();
  contextStore.$patch({ showAppLoader: true });
  const routeRequiresAuth = to.matched.some(record => record.meta?.requiresAuth);
  if (!contextStore.auth.isAuthenticated && routeRequiresAuth) {
    try {
      contextStore.loadCachedIsTechnician();
      await contextStore.fetchSession();
    } catch (error) {
      console.error(JSON.stringify(error));
    }
    if (!contextStore.auth.isAuthenticated) {
      LocalStorageManager.setItem('RequestedPath', to.fullPath);
      return from.name !== PAGES.LOGIN ? { name: PAGES.LOGIN } : false;
    }
  }

  if (routeRequiresAuth) {
    if (!contextStore.initialDataLoaded) {
      await _loadAppInitialData(to);
    }

    // Make sure that zone is fetched before routing to page
    if (to?.params?.zoneId) {
      const isContextUpdated = await contextStore.updateContextZone(Number(to.params.zoneId), to.meta?.contextZoneAttrs);
      if (!isContextUpdated) {
        return from.path !== '/' ? { path: '/' } : false;
      } else {
        useAreasStore()
          .fetchAreas()
          .catch(e => {
            console.error(`Error fetching zone ${contextStore.zoneId} areas`, e);
            contextStore.showSnackbar({
              message: `Zone map load error occurred`,
              type: SNACKBAR_TYPE.ERROR
            });
          });
      }
    } else {
      const requiredZoneAttrs = [...ZONE_ATTRS.BASE, ...(to.meta?.zonesAttrs || [])];
      const zonesStore = useZonesStore();
      const missingAttrs = requiredZoneAttrs.filter(attr => zonesStore.zones.some(zone => !(attr in zone)));
      if (missingAttrs.length) {
        zonesStore.fetchZones({
          attrs: missingAttrs
        });
      }
    }

    if (
      to?.meta?.privileges?.length &&
      !(
        contextStore.user.isIndoorUser ||
        contextStore.user.isAdmin ||
        to.meta.privileges.some(requiredPrivilege => contextStore.user.privileges.includes(requiredPrivilege))
      )
    ) {
      contextStore.showSnackbar({
        message: ERROR_MESSAGES.GENERIC_401,
        type: SNACKBAR_TYPE.INFO
      });
      return from.path !== '/' ? { path: '/' } : false;
    }
  }
});

router.afterEach((to, from) => {
  document.title = `Control Bridge ${to?.meta?.title ? '| ' + to?.meta?.title : ''}`;
  useContextStore().$patch({ showAppLoader: false });
});
