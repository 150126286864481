<template>
  <div>
    {{ label }}
    <span class="halt-tag">Flights Halt</span>
  </div>
</template>
<script>
export default {
  name: 'ZoneLabelWithHaltChip',
  props: {
    label: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped lang="scss">
.halt-tag {
  margin-left: 2px;
  color: var(--errorColor);
  padding: 2px 6px;
  border-radius: 20px;
  background: var(--primaryColorDarkShade1);
}
</style>
