<template>
  <div class="mission-details">
    <div class="header">
      <span class="text"
        >{{ mission.name }}
        <component :is="MISSION_STATE_ICONS[mission.state]" class="mission-state-icon" :style="{ color: STATE_ICON_COLOR_MAP[mission.state] }"
      /></span>
      <span class="action-btns">
        <BaseLoader v-if="isResolveLoading" class="resolve-loader" />
        <BaseSignButton v-else class="handled-button" @click="onHandledIconClick">
          {{ handledText }}
          <template #sign>
            <span :class="[mission.handled ? 'unresolve' : 'resolve']">{{ handledIcon }}</span>
          </template>
        </BaseSignButton>
        <BaseShareButton
          class="action-btn"
          :title="mission.name"
          :text="`${mission.name} ${MISSION_STATE_LABELS[mission.state]}`"
          :url="mission.ui_url"
        />
        <IconClose class="action-btn" @click="onCloseClick">Close</IconClose>
      </span>
    </div>
    <div class="mission-metadata-container">
      <template v-for="item in metadata" :key="item.label">
        {{ item.label }}:
        <router-link v-if="item.linkTo" :to="item.linkTo" class="value clickable">{{ item.value }}</router-link>
        <span v-else class="value">
          {{ item.value }}
        </span>
      </template>
      <template v-if="mission?.hooks?.length">
        <span> Hooks execution: </span>
        <MissionHooksResults :mission="mission" class="value" />
      </template>
    </div>
    <div class="mission-video-container">
      <BaseLoader v-if="isFetchingStreams" class="loader" />
      <VideoSection
        v-else
        ref="videoSection"
        :single-cam-device="displayFrontCameraOnly"
        :video-download-path="mission?.custom_data?.s3_video_path"
        :has-error="!mission?.streamsData?.success"
        :video-start-timestamp="mission.start_timestamp"
        :ai-status="mission?.ai_status"
      />
    </div>
    <div class="manual-operations">
      <BaseButton
        v-if="contextStore.isTechnician && mission?.streamsData?.success"
        :disabled="isAnalyticsVideoRerunLoading"
        @click="onAnalyticsVideoRerunClick"
      >
        <BaseLoader v-if="isAnalyticsVideoRerunLoading" />
        <span v-else>Rerun AI</span>
      </BaseButton>
      <BaseButton
        v-if="contextStore.isTechnician && mission?.poi_detection_settings"
        :disabled="isAnalyticsFrameRerunLoading"
        @click="onAnalyticsFrameRerunClick"
      >
        <BaseLoader v-if="isAnalyticsFrameRerunLoading" />
        <span v-else> Rerun POI GenAI detections</span>
      </BaseButton>
      <BaseButton
        v-if="allowEventCreation && hasStarted(mission) && absoluteFrameTime && closestLocationByVideoTimestamp"
        :disabled="!absoluteFrameTime || !closestLocationByVideoTimestamp"
        @click="onCreateEventClick"
        >Create Event</BaseButton
      >
      <BaseButton
        v-if="contextStore.isTechnician && hasStarted(mission) && absoluteFrameTime && closestLocationByVideoTimestamp && displayedPlayer === 'front'"
        :disabled="!absoluteFrameTime || !closestLocationByVideoTimestamp"
        @click="onFrameDetectionClick"
        >Frame Detection</BaseButton
      >
      <BaseButton v-if="contextStore.isTechnician && hasStarted(mission)" @click="showDetectionsLog = true">View Detection Log</BaseButton>
      <BaseButton v-if="allowMissionStop" @click="onStopMissionClick">Stop this mission (UI only!)</BaseButton>
    </div>
    <div v-if="contextStore.isTechnician && mission?.streamsData?.success" class="skip-to-form">
      <label>Skip to Timestamp:</label>
      <BaseTextInput v-model.number="skipTo" />
      <div v-if="skipTo" :class="['timestamp-format', { error: !isSkipToValid }]">
        {{ getTimeStamp(skipTo) }}
      </div>
      <BaseButton @click="skipToTimestamp">Go</BaseButton>
    </div>
    <div v-if="relatedEvents.length > 0" class="related-events-container">
      <div class="header">Related Events:</div>
      <ul>
        <li v-for="event in relatedEvents" :key="event.id" @click="onEventClick(event.id)">{{ event.label }} - {{ event.absoluteTimeStr }}</li>
      </ul>
    </div>
    <CreateEventModal v-if="showCreateEventModal" v-bind="createEventData" @close="showCreateEventModal = false" @event-created="onEventCreated" />
    <FrameDetectionModal v-if="showFrameDetectionModal" v-bind="frameDetectionData" @close="showFrameDetectionModal = false" />
    <DetectionsInfoModal v-if="showDetectionsLog" :mission-id="mission.id" @close="showDetectionsLog = false" />
  </div>
</template>

<script>
import CreateEventModal from './CreateEventModal.vue';
import FrameDetectionModal from './FrameDetectionModal.vue';
import BaseSignButton from '../../components/base/BaseSignButton.vue';
import { shallowRef } from 'vue';
import BaseLoader from '../../components/base/BaseLoader.vue';
import BaseButton from '../../components/base/BaseButton.vue';
import IconClose from '../../components/icons/IconClose.svg?component';
import BaseShareButton from '../../components/base/BaseShareButton.vue';
import BaseHorizontalObjectTableVue from '../../components/base/BaseHorizontalObjectTable.vue';
import { MISSION_STATE_ICONS, STATE_COLOR_MAP, MISSION_STATE_LABELS, MISSION_STATE, STATE_ICON_COLOR_MAP } from '../../consts/missionConsts';
import {
  deviceLabel,
  getClosestLocationByTimestamp,
  getFailureCause,
  getTriggeredByLabel,
  hasMissionEnded,
  hasObjectDetection,
  hasStarted,
  isActive,
  isUnknown,
  getStrDuration,
  getFailureError
} from '../../utils/models/MissionUtils';
import VideoSection from '../../components/thePlayer/VideoSection.vue';
import { getTimeStamp } from '../../utils/DateUtils';
import { PRIVILEGES } from '../../consts/authConsts';
import { mapState, mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import { useDevicesStore } from '../../store/DevicesStore';
import missionsService from '../../services/api/missionsService';

import { SNACKBAR_TYPE } from '../../consts/appConsts';
import { DEVICES_TYPES } from '../../consts/deviceConsts';
import { EVENT_TYPE_NAME } from '../../consts/eventConsts';
import MissionHooksResults from './MissionHooksResults.vue';
import { PLAYER_EVENTS, useVideoPlayerStore } from '../../store/VideoPlayerStore';
import { PAGES } from '../../router';
import BaseTextInput from '../../components/base/BaseTextInput.vue';
import DetectionsInfoModal from './DetectionsInfoModal.vue';
import dayjs from 'dayjs';

const FETCH_OD_INTERVAL_MS = 10 * 1000; // 10 seconds
const MAX_OD_FETCH_ATTEMPTS = 18;
const OBJECT_DETECTION_FETCH_TTL_MS = MAX_OD_FETCH_ATTEMPTS * FETCH_OD_INTERVAL_MS;

export default {
  name: 'MissionDetails',
  components: {
    CreateEventModal,
    BaseSignButton,
    BaseLoader,
    BaseButton,
    IconClose,
    BaseShareButton,
    BaseHorizontalObjectTableVue,
    MissionHooksResults,
    VideoSection,
    FrameDetectionModal,
    BaseTextInput,
    DetectionsInfoModal
  },
  props: {
    absoluteFrameTime: {
      type: Number,
      default: 0
    },
    isFetchingStreams: {
      type: Boolean,
      required: true
    }
  },
  emits: ['video-duration', 'eventClick'],
  data() {
    return {
      MISSION_STATE_ICONS: shallowRef(MISSION_STATE_ICONS),
      STATE_ICON_COLOR_MAP,
      STATE_COLOR_MAP,
      MISSION_STATE_LABELS,
      fetchStreamTimeout: null,
      rearVideoFileFetchResult: null,
      fetchObjectDetectionInterval: null,
      fetchObjectDetectionCount: 0,
      isResolveLoading: false,
      isAnalyticsVideoRerunLoading: false,
      isAnalyticsFrameRerunLoading: false,
      showCreateEventModal: false,
      showFrameDetectionModal: false,
      createEventData: {
        timestamp: null,
        videoElement: null,
        location: null,
        source: null
      },
      frameDetectionData: {
        timestamp: null,
        videoElement: null,
        source: null
      },
      skipTo: null,
      showDetectionsLog: false
    };
  },
  computed: {
    ...mapStores(useContextStore, useDevicesStore, useVideoPlayerStore),
    ...mapState(useContextStore, ['mission']),
    displayedPlayer() {
      return this.$refs?.videoSection?.getMainPlayerName?.();
    },
    isSkipToValid() {
      return dayjs(this.skipTo).isBetween(this.mission.start_timestamp || this.mission.requested_timestamp, this.mission.end_timestamp);
    },
    displayFrontCameraOnly() {
      return this.mission.device_type_id === DEVICES_TYPES.SKYDIO;
    },
    handledText() {
      return this.mission.handled ? 'Unresolve' : 'Resolve';
    },
    handledIcon() {
      return this.mission.handled ? 'X' : '✓';
    },
    allowMissionStop() {
      return (
        this.contextStore.isTechnician &&
        hasStarted(this.mission) &&
        !hasMissionEnded(this.mission) &&
        !isUnknown(this.mission) &&
        this.mission.device_type_id !== DEVICES_TYPES.SKYDIO
      );
    },
    allowEventCreation() {
      return this.contextStore.hasPrivilege(PRIVILEGES.CREATE_EVENT);
    },
    metadata() {
      const device = this.devicesStore.getDeviceById(this.mission.device_id);
      const metadata = [
        {
          label: 'Device Name',
          value: deviceLabel(this.mission),
          linkTo: device ? `/zones/${device.zone_id}/devices/${device.id}` : null
        },
        {
          label: 'State',
          value: MISSION_STATE_LABELS[this.mission.state]
        }
      ];
      if (!hasStarted(this.mission)) {
        metadata.push({
          label: 'Requested Time',
          value: getTimeStamp(new Date(this.mission.requested_timestamp))
        });
      } else {
        metadata.push({
          label: 'Start Time',
          value: getTimeStamp(new Date(this.mission.start_timestamp))
        });
      }
      if (hasMissionEnded(this.mission)) {
        metadata.push({
          label: 'End Time',
          value: getTimeStamp(new Date(this.mission.end_timestamp))
        });
        if (this.mission.start_timestamp && this.mission.end_timestamp) {
          metadata.push({
            label: 'Duration',
            value: getStrDuration(this.mission)
          });
        }
      }
      metadata.push({
        label: 'Triggered by',
        value: getTriggeredByLabel(this.mission)
      });

      const failureCause = getFailureCause(this.mission);
      if (failureCause) {
        metadata.push({
          label: `Failure cause`,
          value: failureCause
        });
      } else if (this.mission.state === MISSION_STATE.UNKNOWN && !this.mission.custom_data?.error) {
        metadata.push({
          label: `Failure cause`,
          value: getFailureCause({ custom_data: { error: { errorCode: 2508 } } })
        });
      }

      if (this.contextStore.hasPrivilege(PRIVILEGES.VIEW_ERROR_DESCRIPTION) && getFailureError(this.mission)) {
        metadata.push({
          label: `Failure error`,
          value: getFailureError(this.mission)
        });
      }

      const handledBy = this.mission?.handled_modified?.user_name;
      if (handledBy) {
        metadata.push({
          label: `${this.mission.handled ? 'Closed' : 'Reopened'} by`,
          value: handledBy
        });
      }

      return metadata.filter(item => item.condition !== false);
    },
    relatedEvents() {
      let relatedEvents = [];
      if (this.mission?.events?.length) {
        relatedEvents = this.mission.events.map(event => {
          return {
            id: event.id,
            label: EVENT_TYPE_NAME[event.event_type],
            // timestamp: event.timestamp,
            absoluteTimeStr: getTimeStamp(event.timestamp)
          };
        });
      }
      return relatedEvents;
    },
    closestLocationByVideoTimestamp() {
      return this.absoluteFrameTime ? getClosestLocationByTimestamp(this.mission?.path, this.absoluteFrameTime) : null;
    }
  },
  watch: {
    'mission.streamsData': {
      handler: async function onStreamDataChanged(newVal) {
        if (newVal && !isActive(this.mission) && !hasObjectDetection(this.mission) && hasMissionEnded(this.mission)) {
          // If Object detection data is missing post mission
          if (Date.now() - this.mission.end_timestamp < OBJECT_DETECTION_FETCH_TTL_MS) {
            if (!this.fetchObjectDetectionInterval) {
              this.fetchObjectDetectionInterval = setInterval(async () => {
                try {
                  if (!hasObjectDetection(this.mission) && this.fetchObjectDetectionCount < MAX_OD_FETCH_ATTEMPTS) {
                    this.fetchObjectDetectionCount++;
                    const objectDetection = await missionsService.fetchMissionObjectDetectionById(this.mission.id);
                    this.contextStore.$patch(store => {
                      store.mission.streamsData = store.mission.streamsData || {};
                      store.mission.streamsData.data = store.mission.streamsData.data || {};
                      store.mission.streamsData.data.objectDetectionConfig = objectDetection;
                    });
                  } else {
                    this.clearFetchObjectDetectionInterval();
                    if (!hasObjectDetection(this.mission) && this.fetchObjectDetectionCount >= MAX_OD_FETCH_ATTEMPTS) {
                      this.videoPlayerStore.$patch({ odFetchFailed: true });
                      this.contextStore.showSnackbar({
                        message: 'Object detection is unavailable',
                        type: SNACKBAR_TYPE.ERROR
                      });
                    }
                  }
                  // eslint-disable-next-line no-empty
                } catch (e) {}
              }, FETCH_OD_INTERVAL_MS);
            }
          } else {
            this.videoPlayerStore.$patch({ odFetchFailed: true });
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.videoPlayerStore.rear.eventBus.addEventListener(PLAYER_EVENTS.REQUEST_STREAM_FILE, this.onStreamVideoFileRequest);
    this.videoPlayerStore.front.eventBus.addEventListener(PLAYER_EVENTS.REQUEST_STREAM_FILE, this.onStreamVideoFileRequest);
  },
  beforeUnmount() {
    this.clearFetchObjectDetectionInterval();
    this.videoPlayerStore.rear.eventBus.removeEventListener(PLAYER_EVENTS.REQUEST_STREAM_FILE, this.onStreamVideoFileRequest);
    this.videoPlayerStore.front.eventBus.removeEventListener(PLAYER_EVENTS.REQUEST_STREAM_FILE, this.onStreamVideoFileRequest);
  },
  methods: {
    getTimeStamp,
    hasStarted,
    clearFetchObjectDetectionInterval() {
      clearInterval(this.fetchObjectDetectionInterval);
      this.fetchObjectDetectionInterval = null;
    },
    onEventClick(eventId) {
      this.$emit('eventClick', eventId);
    },
    onCloseClick() {
      this.$router.push({
        name: PAGES.EVENTS
      });
    },
    async onHandledIconClick() {
      this.isResolveLoading = true;
      const newValue = !this.mission.handled;

      try {
        const updatedMission = await missionsService.patchMission({
          id: this.mission.id,
          handled: newValue
        });
        this.contextStore.$patch(store => (store.mission = { ...store.mission, ...updatedMission }));
      } catch (err) {
        console.error(err);
        this.contextStore.showSnackbar({
          message: `Failed to ${this.mission.handled ? 'unresolve' : 'resolve'} mission`,
          type: SNACKBAR_TYPE.ERROR
        });
      }
      this.isResolveLoading = false;
    },
    onEventCreated({ event, player }) {
      this.videoPlayerStore[player].eventBus.dispatchEvent(
        new CustomEvent(PLAYER_EVENTS.NEW_MARKER, {
          detail: { timestamp: event.timestamp, type: 'event', label: EVENT_TYPE_NAME[event.event_type] }
        })
      );
    },
    async onCreateEventClick() {
      this.$refs?.videoSection?.pauseVideos?.();
      this.createEventData = {
        videoElement: this.$refs?.videoSection?.getMainPlayerElement?.(),
        timestamp: this.absoluteFrameTime,
        location: this.closestLocationByVideoTimestamp,
        source: this.$refs?.videoSection?.getMainPlayerName?.()
      };
      this.showCreateEventModal = true;
    },
    async onFrameDetectionClick() {
      this.$refs?.videoSection?.pauseVideos?.();
      this.frameDetectionData = {
        videoElement: this.$refs?.videoSection?.getMainPlayerElement?.(),
        timestamp: this.absoluteFrameTime,
        location: this.closestLocationByVideoTimestamp,
        source: this.$refs?.videoSection?.getMainPlayerName?.()
      };
      this.showFrameDetectionModal = true;
    },
    async onAnalyticsVideoRerunClick() {
      this.isAnalyticsVideoRerunLoading = true;
      try {
        await missionsService.rerunMissionVideoDetection(this.mission.id);
        this.contextStore.showSnackbar({
          message: 'Mission video detection rerun initiated successfully',
          type: SNACKBAR_TYPE.SUCCESS
        });
      } catch (err) {
        console.error(err);
        this.contextStore.showSnackbar({
          message: 'Unable to rerun mission video detection',
          type: SNACKBAR_TYPE.ERROR
        });
      }
      this.isAnalyticsVideoRerunLoading = false;
    },
    async onAnalyticsFrameRerunClick() {
      this.isAnalyticsFrameRerunLoading = true;
      try {
        await missionsService.rerunMissionFrameDetection(this.mission.id);
        this.contextStore.showSnackbar({
          message: 'Mission GenAI POI detections rerun initiated successfully',
          type: SNACKBAR_TYPE.SUCCESS
        });
      } catch (err) {
        console.error(err);
        this.contextStore.showSnackbar({
          message: 'Unable to rerun mission GenAI POI detections',
          type: SNACKBAR_TYPE.ERROR
        });
      }
      this.isAnalyticsFrameRerunLoading = false;
    },
    async onStopMissionClick() {
      const updatedMission = await missionsService.patchMission({
        id: this.mission.id,
        state: MISSION_STATE.STOPPED,
        end_timestamp: Date.now()
      });
      this.contextStore.$patch(store => (store.mission = { ...store.mission, ...updatedMission }));
    },
    async onStreamVideoFileRequest(e) {
      const playerType = e.detail;
      const sourceType = this.mission.streamsData.data[`${playerType}Source`];
      try {
        this.videoPlayerStore.$patch(state => {
          state[playerType].isDownloading = true;
        });
        await missionsService.requestFullVideoDownload(this.mission.id, playerType, sourceType);
        this.contextStore.$patch(state => {
          state.showConfirmationDialog = true;
          state.confirmationDialogData.title = 'Full video download request';
          state.confirmationDialogData.text = `Your download is being prepared and will be emailed to you. Please check your inbox shortly`;
          state.confirmationDialogData.confirmText = 'OK';
          state.confirmationDialogData.cancelText = null;
        });
      } catch (err) {
        this.contextStore.showSnackbar({
          message: 'Unable to download video',
          type: SNACKBAR_TYPE.ERROR
        });

        console.error(err.message);
      } finally {
        this.videoPlayerStore.$patch(state => {
          state[playerType].isDownloading = false;
        });
      }
    },
    skipToTimestamp() {
      this.videoPlayerStore.front.eventBus.dispatchEvent(new CustomEvent(PLAYER_EVENTS.FRAME_CHANGE, { detail: this.skipTo }));
      this.videoPlayerStore.rear.eventBus.dispatchEvent(new CustomEvent(PLAYER_EVENTS.FRAME_CHANGE, { detail: this.skipTo }));
    }
  }
};
</script>

<style lang="scss" scoped>
.mission-details {
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  direction: rtl;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  color: var(--textColor);
  font-family: var(--font-family-secondary);

  * {
    direction: ltr;
  }

  .header {
    font-family: var(--font-family-primary);
    display: flex;
    justify-content: space-between;
    column-gap: 1.5rem;
    align-items: flex-start;

    .text {
      font-size: 2rem;

      .mission-state-icon {
        width: 2.5rem;
        height: 2.5rem;
        transform: translateY(15%);
      }
    }

    .action-btns {
      column-gap: 1.5rem;
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: center;

      .action-btn {
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;

        &.open-close-btn {
          color: var(--highlightColor);
        }

        &:hover {
          color: var(--textColor);
        }
      }

      .resolve-loader {
        width: 1rem;
        height: 1rem;
        margin: auto;
      }

      .handled-button {
        font-size: 1rem;
        width: 8rem;

        .resolve {
          font-size: 1.5rem;
          font-weight: bold;
          color: var(--successColor);
        }

        .unresolve {
          font-size: 1.5rem;
          color: var(--errorColor);
        }
      }
    }
  }

  .mission-metadata-container {
    font-size: 1.2rem;
    word-break: break-word;
    white-space: normal;
    text-overflow: ellipsis;
    color: var(--textColor);
    display: grid;
    grid-template-columns: 8rem auto;
    column-gap: 0.5rem;
    row-gap: 0.2rem;

    .value {
      font-weight: 300;
      color: var(--secondaryTextColor);

      &.clickable {
        cursor: pointer;
        pointer-events: auto;

        &:hover {
          text-decoration: underline;
          text-decoration-color: var(--highlightColor);
        }
      }
    }
  }

  .mission-video-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    flex-basis: 20rem;

    .loader {
      margin: auto;
      width: 4rem;
      height: 4rem;
    }
  }

  .manual-operations {
    display: flex;
    column-gap: 1rem;
    flex-wrap: wrap;
    row-gap: 0.5rem;
  }

  .skip-to-form {
    display: flex;
    padding: 5px;
    background: var(--secondaryOverlayShade1);
    border-radius: 7px;
    column-gap: 0.3rem;
    position: relative;

    .timestamp-format {
      opacity: 0.3;
      position: absolute;
      right: 4.5rem;
      top: 0.3rem;

      &.error {
        color: var(--errorColor);
      }
    }
  }

  .related-events-container {
    .header {
      margin-bottom: 15px;
    }

    li {
      cursor: pointer;
      color: var(--secondaryTextColor);

      &:hover {
        color: var(--textColor);
      }
    }
  }
}
</style>
