<template>
  <div class="scheduler">
    <div class="hours-header-container">
      <div :class="['hour-header', 'day-header']"></div>
      <div v-for="(hour, index) in hours" :key="index" :class="['hour-header', `scheduler-scale-${minutesInterval}`]">
        <div v-if="hour.label !== ''" class="hour-label">
          {{ hour.label }}
        </div>
        <div v-if="index === hours.length - 1" class="hour-label">24</div>
      </div>
    </div>
    <div>
      <div class="days-header-container">
        <div v-for="day in days" :key="day.label" class="day-header">
          <span class="day-label">{{ day.label }}</span>
        </div>
      </div>
      <div class="days-hours-container" @mouseleave="onDaysHoursMouseLeave">
        <div
          v-for="(day, dayIndex) in days"
          :key="dayIndex"
          :class="[
            'day-container',
            {
              'first-day': dayIndex === 0,
              'last-day': dayIndex === days.length - 1
            }
          ]"
        >
          <div
            v-for="(hour, hourIndex) in hours"
            :key="hourIndex"
            :ref="`day-${dayIndex}-hour-${hourIndex}`"
            :class="[
              'day-hour-container',
              `scheduler-scale-${minutesInterval}`,
              getCustomStyleCell(dayIndex, hourIndex),
              {
                'round-hour': hour.label !== '',
                'first-hour': hourIndex === 0,
                'last-hour': hourIndex === hours.length - 1,
                selected: isCellSelected(dayIndex, hourIndex),
                candidate: isCellCandidate(dayIndex, hourIndex)
              }
            ]"
            @mousedown="onDayHourMouseDown(dayIndex, hourIndex)"
            @mouseenter="onDayHourMouseEnter(dayIndex, hourIndex)"
            @mouseup="onDayHourMouseUp(dayIndex, hourIndex)"
          >
            <div :class="['day-hour-fill']"></div>
          </div>
        </div>
        <div v-show="showSelectedCellsPopup" ref="selectedCellsPopup" class="selected-cells-popup-container">
          <div class="icon-close-container">
            <IconClose class="icon" @click="onSelectedCellsPopupCloseClick"><title>Close</title></IconClose>
          </div>
          <slot name="popup"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from '../../components/icons/IconClose.svg?component';

export default {
  name: 'BaseScheduler',
  components: {
    IconClose
  },
  props: {
    daysHoursMap: {
      type: Object,
      default: () => {}
    },
    enabled: {
      type: Boolean,
      default: true
    },
    minutesInterval: {
      type: Number,
      default: 15,
      validator: val => [15, 30].includes(val)
    },
    multipleHourSlots: {
      type: Boolean,
      default: true
    },
    multipleDaySlots: {
      type: Boolean,
      default: true
    },
    popupMargin: {
      type: Number,
      default: 10
    },
    forcePopupAlignmentTop: {
      type: Boolean,
      default: false
    },
    forcePopupAlignmentLeft: {
      type: Boolean,
      default: false
    }
  },
  emits: ['popupClose', 'popupOpen', 'selectCells'],
  data() {
    return {
      startCell: null,
      endCell: null,
      selectedCellsIndexes: [],
      candidateCellsIndexes: [],
      hours: [],
      days: [],
      showSelectedCellsPopup: false
    };
  },
  watch: {
    enabled() {
      if (!this.enabled) {
        this.startCell = null;
        this.endCell = null;
        this.candidateCellsIndexes = [];
      }
    }
  },
  created() {
    this.hours = this.calcHours();
    this.days = this.calcDays();
  },
  methods: {
    calcDays() {
      const days = [
        { index: 0, label: 'Sunday' },
        { index: 1, label: 'Monday' },
        { index: 2, label: 'Tuesday' },
        { index: 3, label: 'Wednesday' },
        { index: 4, label: 'Thursday' },
        { index: 5, label: 'Friday' },
        { index: 6, label: 'Saturday' }
      ];
      return days;
    },
    calcHours() {
      const hours = [];
      for (let i = 0; i < 24; i++) {
        const hour = this.zeroPadding(i);
        const nextHour = this.zeroPadding(i + 1);
        const intervals = 60 / this.minutesInterval;
        for (let j = 0; j < intervals; j++) {
          const fromMinutes = this.zeroPadding(j * this.minutesInterval);
          const from = `${hour}:${fromMinutes}`;
          const label = fromMinutes === '00' ? hour : '';
          let toMinutes = this.zeroPadding((j + 1) * this.minutesInterval);
          const to = toMinutes === '60' ? `${nextHour}:00` : `${hour}:${toMinutes}`;
          hours.push({ from, to, label });
        }
      }
      return hours;
    },
    zeroPadding(numStr) {
      return numStr < 10 ? `0${numStr}` : `${numStr}`;
    },
    onDayHourMouseDown(dayIndex, hourIndex) {
      if (this.enabled) {
        this.startCell = { dayIndex, hourIndex };
        this.candidateCellsIndexes = [this.startCell];
        this.setSelectedCellsIndexes({});
        this.toggleSelectedCellsPopup(false);
      }
    },
    onDayHourMouseEnter(dayIndex, hourIndex) {
      if (this.enabled && this.startCell) {
        this.endCell = { dayIndex, hourIndex };
        const { cellsIndexesInRange } = this.getCellsIndexesInRange(this.startCell, this.endCell);
        this.candidateCellsIndexes = cellsIndexesInRange;
      }
    },
    onDaysHoursMouseLeave(dayIndex, hourIndex) {
      if (this.startCell) {
        this.startCell = null;
        this.endCell = null;
        this.candidateCellsIndexes = [];
      }
    },
    onDayHourMouseUp(dayIndex, hourIndex) {
      if (this.enabled && this.startCell) {
        this.selectCells(this.startCell, this.endCell);
      }
      this.startCell = null;
      this.endCell = null;
    },
    selectCells(startCell, endCell) {
      endCell = endCell || startCell;
      const selectedCells = this.getCellsIndexesInRange(startCell, endCell);
      this.setSelectedCellsIndexes(selectedCells);
      this.candidateCellsIndexes = [];
      this.toggleSelectedCellsPopup(true, selectedCells);
      this.$emit('popupOpen');
    },
    toggleSelectedCellsPopup(show, selectedCells) {
      this.showSelectedCellsPopup = show;
      if (show) {
        this.locateSelectedCellsPopup(selectedCells);
      }
    },
    locateSelectedCellsPopup(selectedCells) {
      let referralCellDay = selectedCells.minDayIndex;
      let referralCellHour = selectedCells.maxHourIndex;
      let referralCell = this.$refs[`day-${referralCellDay}-hour-${referralCellHour}`];
      const boundingRect = referralCell[0].getBoundingClientRect();
      this.$refs.selectedCellsPopup.style.top = boundingRect.top + 'px';
      this.$refs.selectedCellsPopup.style.left = boundingRect.left + this.popupMargin + 'px';
      this.$nextTick(() => {
        if (!this.isPopupInViewPortHeight() || this.forcePopupAlignmentTop) {
          referralCellDay = selectedCells.maxDayIndex;
          referralCell = this.$refs[`day-${referralCellDay}-hour-${referralCellHour}`];
          const popupHeight = this.$refs.selectedCellsPopup.offsetHeight;
          const boundingRect = referralCell[0].getBoundingClientRect();
          this.$refs.selectedCellsPopup.style.top = boundingRect.top - popupHeight + 'px';
        }
        if (!this.isPopupInViewPortWidth() || this.forcePopupAlignmentLeft) {
          referralCellHour = selectedCells.minHourIndex;
          referralCell = this.$refs[`day-${referralCellDay}-hour-${referralCellHour}`];
          const popupWidth = this.$refs.selectedCellsPopup.offsetWidth;
          this.$refs.selectedCellsPopup.style.left = referralCell[0].offsetLeft - popupWidth - this.popupMargin + 'px';
        }
      });
    },
    isPopupInViewPortHeight() {
      const popupRect = this.$refs.selectedCellsPopup.getBoundingClientRect();
      return popupRect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
    },
    isPopupInViewPortWidth() {
      const popupRect = this.$refs.selectedCellsPopup.getBoundingClientRect();
      return popupRect.right <= (window.innerWidth || document.documentElement.clientWidth);
    },
    closeSelectedCellsPopup() {
      this.toggleSelectedCellsPopup(false);
      this.setSelectedCellsIndexes({});
    },
    onSelectedCellsPopupCloseClick() {
      this.toggleSelectedCellsPopup(false);
      this.setSelectedCellsIndexes({});
      this.$emit('popupClose');
    },
    isCellCandidate(dayIndex, hourIndex) {
      return !!this.candidateCellsIndexes.find(candidateCell => candidateCell.dayIndex === dayIndex && candidateCell.hourIndex === hourIndex);
    },
    isCellSelected(dayIndex, hourIndex) {
      return !!this.selectedCellsIndexes.find(selectedCell => selectedCell.dayIndex === dayIndex && selectedCell.hourIndex === hourIndex);
    },
    getCustomStyleCell(dayIndex, hourIndex) {
      let classNames = [];
      if (this.daysHoursMap && this.daysHoursMap[dayIndex] && this.daysHoursMap[dayIndex][hourIndex]) {
        classNames = this.daysHoursMap[dayIndex][hourIndex].classNames;
      }
      return classNames;
    },
    getCellsIndexesInRange(startCell, endCell) {
      const minDayIndex = this.multipleDaySlots ? Math.min(startCell.dayIndex, endCell.dayIndex) : startCell.dayIndex;
      const maxDayIndex = this.multipleDaySlots ? Math.max(startCell.dayIndex, endCell.dayIndex) : startCell.dayIndex;
      const minHourIndex = this.multipleHourSlots ? Math.min(startCell.hourIndex, endCell.hourIndex) : startCell.hourIndex;
      const maxHourIndex = this.multipleHourSlots ? Math.max(startCell.hourIndex, endCell.hourIndex) : startCell.hourIndex;
      const cellsIndexesInRange = [];
      for (let dayIndex = minDayIndex; dayIndex <= maxDayIndex; dayIndex++) {
        for (let hourIndex = minHourIndex; hourIndex <= maxHourIndex; hourIndex++) {
          cellsIndexesInRange.push({ dayIndex, hourIndex });
        }
      }
      return {
        minDayIndex,
        maxDayIndex,
        minHourIndex,
        maxHourIndex,
        cellsIndexesInRange
      };
    },
    getDayLabelByIndex(dayIndex) {
      return this.days.find(day => day.index === dayIndex).label;
    },
    getHourLabelByIndex(hourIndex) {
      return { from: this.hours[hourIndex].from, to: this.hours[hourIndex].to };
    },
    setSelectedCellsIndexes(selectedCells) {
      const selectedCellsParams = {
        daysLabels: [],
        hoursLabels: [],
        daysIndexes: [],
        hoursIndexes: [],
        minDayLabel: '',
        minDayIndex: '',
        maxDayLabel: '',
        maxDayIndex: '',
        minHourLabel: '',
        minHourIndex: '',
        maxHourLabel: '',
        maxHourIndex: '',
        selectedIndexes: []
      };
      if (Object.keys(selectedCells).length > 0) {
        selectedCellsParams.minDayIndex = selectedCells.minDayIndex;
        selectedCellsParams.minDayLabel = this.getDayLabelByIndex(selectedCells.minDayIndex);
        selectedCellsParams.maxDayIndex = selectedCells.maxDayIndex;
        selectedCellsParams.maxDayLabel = this.getDayLabelByIndex(selectedCells.maxDayIndex);
        selectedCellsParams.minHourIndex = selectedCells.minHourIndex;
        selectedCellsParams.minHourLabel = this.getHourLabelByIndex(selectedCells.minHourIndex).from;
        selectedCellsParams.maxHourIndex = selectedCells.maxHourIndex;
        selectedCellsParams.maxHourLabel = this.getHourLabelByIndex(selectedCells.maxHourIndex).to;
        selectedCellsParams.selectedIndexes = selectedCells.cellsIndexesInRange;
        this.selectedCellsIndexes = selectedCells.cellsIndexesInRange;
      } else {
        this.selectedCellsIndexes = [];
      }
      this.$emit('selectCells', selectedCellsParams);
    }
  }
};
</script>

<style lang="scss" scoped>
.scheduler {
  font-family: var(--font-family-secondary);
  color: var(--textColor);

  .hours-header-container {
    .hour-header {
      display: inline-block;
      position: relative;
      height: 20px;

      &.scheduler-scale-15 {
        width: 8.3px;
      }

      &.scheduler-scale-30 {
        width: 16px;
      }

      &.day-header {
        width: 90px;
      }

      .hour-label {
        position: absolute;
        top: 0;
        left: -6px;
        font-size: 12px;
      }

      &:last-child {
        .hour-label {
          left: auto;
          right: -6px;
        }
      }
    }
  }

  .days-header-container {
    display: inline-block;
    vertical-align: top;

    .day-header {
      width: 90px;
      height: 20px;
      line-height: 20px;

      .day-label {
        font-size: 16px;
      }
    }
  }

  .days-hours-container {
    display: inline-block;
    position: relative;

    .day-container {
      height: 20px;
      border-bottom: 2px solid var(--secondaryColor);

      .day-hour-container {
        display: inline-block;
        height: 20px;
        border-left: 1px dotted var(--secondaryColor);
        border-bottom: 2px solid var(--secondaryColor);
        background-color: var(--secondaryColorShade2);

        &.scheduler-scale-15 {
          width: 7px;
        }

        &.scheduler-scale-30 {
          width: 16px;
        }

        &.selected {
          background-color: var(--secondaryColorShade1);
        }

        &.candidate {
          background-color: var(--highlightColorShade1);
        }

        &.round-hour {
          border-left: 2px solid var(--secondaryColor);
        }

        &.first-hour {
          border-left: 2px solid var(--secondaryColor);
        }

        &.last-hour {
          border-right: 2px solid var(--secondaryColor);
        }

        .day-hour-fill {
          width: 100%;
          height: 100%;
        }
      }

      &.first-day {
        border-bottom: 4px solid var(--secondaryColor);

        .day-hour-container {
          border-top: 2px solid var(--secondaryColor);
        }
      }
    }

    .selected-cells-popup-container {
      position: fixed;
      background: var(--primaryColor);
      padding: 20px;
      z-index: 10;
      box-shadow: 2px 2px 0.5rem var(--shadowColor);

      .icon-close-container {
        position: absolute;
        top: 2px;
        right: 5px;

        .icon {
          cursor: pointer;
          color: var(--secondaryTextColor);
          transition: 0.2s;
          width: 0.6rem;
          height: 0.6rem;

          &:hover {
            filter: brightness(1.2);
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
</style>
