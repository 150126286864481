import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", { transform: "translate(-373 -2194) translate(40 1620) translate(333 574)" }, [
      _createElementVNode("circle", {
        cx: "12",
        cy: "12",
        r: "12",
        fill: "#DD4EAD"
      }),
      _createElementVNode("g", {
        fill: "#FFF",
        "fill-rule": "nonzero",
        stroke: "#FFF",
        "stroke-width": ".5"
      }, [
        _createElementVNode("path", {
          d: "M6.675 11.82v.347h2.663v-.347H15v.143c0 .225-.056.45-.168.612-.113.164-.267.245-.42.245h0H1.588c-.154 0-.309-.081-.42-.245-.113-.163-.169-.387-.169-.612h0v-.143h5.675zM13.332 3c.098 0 .196.042.266.112s.112.17.112.268h0v6.836c0 .098-.042.197-.112.267s-.168.112-.266.112h0H2.668c-.098 0-.183-.028-.267-.112-.07-.07-.112-.17-.112-.268h0V3.38c0-.099.042-.197.112-.268.07-.07.169-.112.267-.112h0zm-.21.59H2.892v6.415h10.23V3.59z",
          transform: "translate(4 4)"
        })
      ])
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }