<template>
  <div class="point-card">
    <div class="thumbnail">
      <BaseLoader v-if="isFetchingImage" class="img-loader" />
      <img v-else-if="thumbnail" :src="thumbnail" class="thumbnail-img" />
      <span v-else class="image-load-error">Image is not currently available.</span>
    </div>
    <div class="card-bottom">
      <div class="card-data">
        <div class="title">{{ title }}</div>
        <div v-if="description" class="description">{{ description }}</div>
        <div v-if="showTime" class="timestamp">{{ getFullTime(item.timestamp) }}</div>
      </div>
      <BaseShareButton v-show="thumbnail" class="icon" :image="thumbnail" :url="itemUrl" />
      <IconSkipTo v-if="item.skipVideoFunction" class="icon" @click="skipToItemTime"><title>Play Video</title></IconSkipTo>
      <router-link v-if="showNavigationIcon" :to="routeLink"
        ><IconOpenLink class="icon"><title>View Page</title></IconOpenLink></router-link
      >
    </div>
  </div>
</template>

<script>
import IconOpenLink from '../icons/IconOpenLink.svg?component';
import BaseShareButton from '../base/BaseShareButton.vue';
import IconSkipTo from '../icons/IconSkipTo.svg?component';
import { EVENT_TYPE, EVENT_TYPE_NAME } from '../../consts/eventConsts';
import { getFullTime } from '../../utils/DateUtils';
import { PAGES } from '../../router/index.js';
import missionsService from '../../services/api/missionsService.js';
import eventsService from '../../services/api/eventsService.js';
import BaseLoader from '../base/BaseLoader.vue';
import { useContextStore } from '../../store/ContextStore.js';
import { mapActions } from 'pinia';
import { SNACKBAR_TYPE } from '../../consts/appConsts.js';

export default {
  name: 'MapThumbnailCard',
  components: {
    IconOpenLink,
    BaseShareButton,
    IconSkipTo,
    BaseLoader
  },
  props: {
    position: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    showTime: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      thumbnail: '',
      isFetchingImage: false
    };
  },
  computed: {
    title() {
      if (this.item.event_type === EVENT_TYPE.MISSION) {
        return `POI #${this.item.poiIndex + 1}`;
      } else {
        return EVENT_TYPE_NAME[this.item.event_type];
      }
    },
    description() {
      if (this.item.event_type !== EVENT_TYPE.MISSION) {
        return this.item.custom_data?.description;
      }
      return '';
    },
    routeLink() {
      if (this.item.event_type === EVENT_TYPE.MISSION) {
        return {
          name: PAGES.MISSION,
          params: {
            missionId: this.item.id
          }
        };
      } else {
        return {
          name: PAGES.EVENT,
          params: {
            eventId: this.item.id
          }
        };
      }
    },
    itemUrl() {
      return window.location.origin + this.$router.resolve(this.routeLink).href;
    },
    showNavigationIcon() {
      if (this.item.event_type === EVENT_TYPE.MISSION) {
        return this.$route.name !== PAGES.MISSION;
      } else {
        return this.$route.name !== PAGES.EVENT;
      }
    }
  },
  watch: {
    item: {
      handler: function onItemChange() {
        this.fetchItemThumbnail();
      },
      deep: true
    }
  },
  created() {
    this.fetchItemThumbnail();
  },
  methods: {
    getFullTime,
    ...mapActions(useContextStore, ['showSnackbar']),
    async fetchItemThumbnail() {
      this.isFetchingImage = true;
      try {
        if (this.item.event_type === EVENT_TYPE.MISSION) {
          this.thumbnail = await missionsService.fetchMissionSnapshot(this.item.id, this.item.timestamp);
        } else {
          this.thumbnail = await eventsService.fetchEventSnapshot(this.item.id);
        }
      } catch (err) {
        console.error(err);
        this.showSnackbar({
          message: `Error while fetching thumbnail`,
          type: SNACKBAR_TYPE.ERROR
        });
      }
      this.isFetchingImage = false;
    },
    skipToItemTime() {
      this.item.skipVideoFunction(this.item.timestamp);
    }
  }
};
</script>

<style scoped lang="scss">
.point-card {
  position: relative;
  max-width: 20rem;

  .thumbnail {
    width: 100%;
    min-height: 10rem;
    background: var(--secondaryOverlayShade1);
    aspect-ratio: 16/9;
    display: grid;
    place-content: center;

    .img-loader {
      width: 3rem;
    }

    .thumbnail-img {
      width: 100%;
      aspect-ratio: 16/9;
    }

    .image-load-error {
      opacity: 0.7;
      font-size: 1rem;
    }
  }

  .card-bottom {
    display: flex;
    padding-top: 0.7rem;
    align-items: baseline;
    column-gap: 0.8rem;
    justify-content: space-between;
    width: 100%;

    .card-data {
      display: grid;
      row-gap: 0.2rem;
      flex-grow: 1;

      .title {
        font-weight: bold;
        font-size: 1.25rem;
      }

      .timestamp,
      .description {
        font-size: 0.9rem;
        color: var(--secondaryTextColor);
      }

      .timestamp {
        opacity: 0.6;
      }
    }

    .icon {
      height: 1rem;
      width: 1rem;
      color: var(--highlightColor);

      &:hover {
        color: var(--textColor);
      }
    }
  }
}
</style>
