<template>
  <div class="base-share-button">
    <IconShare v-if="isShareNativelySupported" title="Share" class="share-icon bsb-icon" @click="share" />
    <BaseMenu v-else-if="showSharePopup" :position="menuPosition">
      <template #toggle>
        <IconShare class="share-icon bsb-icon"><title>Share</title></IconShare>
      </template>
      <template #content>
        <div class="icons-container">
          <IconWhatsApp class="bsb-icon medium-icon" @click="whatsapp"><title>WhatsApp</title></IconWhatsApp>
          <IconEnvelope class="bsb-icon medium-icon" @click="email"><title>Email</title></IconEnvelope>
          <IconCopy class="bsb-icon medium-icon" @click="copyToClipboard"><title>Copy to Clipboard</title></IconCopy>
        </div>
      </template>
      <template #reference> </template>
    </BaseMenu>
    <IconCopy v-else class="share-icon" @click="copyToClipboard"><title>Copy to Clipboard</title></IconCopy>
  </div>
</template>

<script>
import CopyToClipboard from '../../utils/CopyToClipboard.js';
import BaseMenu, { MENU_POSITION_OPTIONS } from './BaseMenu.vue';
import { useContextStore } from '../../store/ContextStore';
import { mapActions } from 'pinia';
import { SNACKBAR_TYPE } from '../../consts/appConsts';
import IconCopy from '../../components/icons/IconCopy.svg?component';
import IconShare from '../../components/icons/IconShare.svg?component';
import IconEnvelope from '../../components/icons/IconEnvelope.svg?component';
import IconWhatsApp from '../../components/icons/IconWhatsApp.svg?component';
import { base64ToBlob } from '../../utils/browserUtils.js';
const CONFIRMATION_SNACKBAR_DURATION_MS = 1000;
export default {
  name: 'BaseShareButton',
  components: {
    IconCopy,
    IconShare,
    IconEnvelope,
    IconWhatsApp,
    BaseMenu
  },
  extends: CopyToClipboard,
  props: {
    url: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: document.title
    },
    text: {
      type: String,
      default: document.title
    },
    image: {
      type: Object,
      default: null
    }
  },
  emits: ['close'],
  data() {
    return {
      menuPosition: [MENU_POSITION_OPTIONS.BOTTOM, MENU_POSITION_OPTIONS.CENTER]
    };
  },
  computed: {
    showSharePopup() {
      return !this.image;
    },
    shareUrl() {
      return this.url || window.location.href;
    },
    isShareNativelySupported() {
      return navigator?.share;
    }
  },
  methods: {
    ...mapActions(useContextStore, ['showSnackbar']),
    share() {
      try {
        navigator.share({
          url: this.shareUrl,
          title: this.title,
          text: this.text
        });
      } catch (err) {
        console.error(err);
      }
    },
    async copyToClipboard() {
      try {
        if (this.image) {
          await navigator.clipboard.write([new ClipboardItem({ 'image/png': await base64ToBlob(this.image) })]);
        } else {
          await this.copyToClipboard(this.shareUrl);
        }
        this.showSnackbar({
          message: 'Copied to clipboard',
          type: SNACKBAR_TYPE.INFO,
          duration: CONFIRMATION_SNACKBAR_DURATION_MS
        });
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
        this.showSnackbar({
          message: 'Unable to copy to clipboard',
          type: SNACKBAR_TYPE.ERROR
        });
      }
      this.$emit('close');
    },
    email() {
      const body = [this.text, this.url].filter(str => str).join('%0D%0A');
      window.open(`mailto:?subject=${this.title}&body=${body}`);
    },
    whatsapp() {
      const body = [this.title, this.text, this.url].filter(str => str).join('%0D%0A');
      window.open(`https://web.whatsapp.com/send?text=${body}`);
    }
  }
};
</script>
<style scoped lang="scss">
.base-share-button {
  width: max-content;
  z-index: 5;
  position: relative;

  .share-icon {
    min-width: 1rem;
    min-height: 1rem;
  }

  .icons-container {
    width: 8rem;
    height: 1.4rem;
    position: absolute;
    transform: translateX(-70%);
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    border-radius: 1rem;
    background: var(--secondaryInfoColor);
    box-shadow: 0 0 0.5em var(--shadowColor);
    padding: 0.4rem;

    .medium-icon {
      height: 1.4rem;
      width: 1.4rem;

      & .email-icon {
        width: 1.6rem;
      }
    }
  }

  .bsb-icon {
    color: var(--highlightColor);
    outline: none;

    &:hover {
      color: var(--textColor);
      cursor: pointer;
    }
  }
}
</style>
