import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      stroke: "#D1D1D1",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, [
      _createElementVNode("path", { d: "M19 16v6m0 0 3-3m-3 3-3-3M4 6v6s0 3 7 3 7-3 7-3V6" }),
      _createElementVNode("path", { d: "M11 3c7 0 7 3 7 3s0 3-7 3-7-3-7-3 0-3 7-3Zm0 18c-7 0-7-3-7-3v-6" })
    ], -1)
  ])))
}
export default { render: render }