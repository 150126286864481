import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "-13 -13 26 26",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "m8.5 11.45v-22.9q0-1.015.7145-1.7325.7145-.7175 1.725-.7175t1.7355.7175q.725.7175.725 1.7325v22.9q0 1.015-.7145 1.7325-.7145.7175-1.725.7175t-1.7355-.7175q-.725-.7175-.725-1.7325zm-22.9-3.2645v-16.369q0-1.0665.7465-1.7665.747-.7 1.7255-.7.378 0 .653.125.275.125.625.325l12.8 8.15q.55.372.8.9075.25.5355.25 1.139t-.25 1.1415q-.25.538-.8.912l-12.8 8.15q-.35.2-.625.325t-.653.125q-.9785 0-1.7255-.699-.7465-.699-.7465-1.7655z" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }