import { get24Time } from '../DateUtils';
import { EVENT_TYPE_ICONS } from '../../consts/eventConsts';
import { router } from '../../router/index.js';
import { shallowRef } from 'vue';

export function deviceLabel(event) {
  return event.device_name || event.device_id;
}
export function hasObjectDetection(event) {
  return !!event?.streamsData?.data.objectDetectionConfig;
}
export function getDisplayConfigForMap({ event, clickable, skipVideoFunction } = {}) {
  const config = {
    id: event.id,
    x: event?.location?.x,
    y: event?.location?.y,
    icon: shallowRef(EVENT_TYPE_ICONS[event.event_type]),
    iconKey: event.event_type,
    text: get24Time(new Date(event.timestamp)),
    time: new Date(event.timestamp)
  };

  if (clickable) {
    config.cardItem = {
      ...event,
      skipVideoFunction
    };
  }

  return config;
}
