export const PRIVILEGES = {
  TECH_ONLY: 0,
  INVOKE_CUSTOM_MISSIONS: 1,
  INVOKE_MISSION_TEMPLATE: 2,
  MANAGE_MISSION_TEMPLATES: 3,
  SCHEDULE_MISSIONS: 4,
  INVOKE_VIDEO_STREAM: 5,
  MANAGE_DEVICES: 6,
  MANAGE_USERS: 7,
  MANAGE_INTEGRATIONS: 8,
  MANAGE_AREA_RESTRICTIONS: 9,
  INVOKE_IOT_DEVICE: 10,
  MANAGE_ROLES: 11,
  MANAGE_GROUPS: 12,
  HALT_ALL_FLIGHTS: 13,
  DOWNLOAD_VIDEOS: 14,
  MANAGE_MAP_LANDMARKS: 15,
  DEVICES_MAINTENANCE: 16,
  RESTART_DEVICE: 17,
  SHUTDOWN_DEVICE: 18,
  RESET_DEVICE_ERRORS: 19,
  INVOKE_DEBUG_MISSION: 20,
  REPOSITION_DEVICE: 21,
  VIEW_ERROR_DESCRIPTION: 22,
  VIEW_DEVICE_DEBUG_DETAILS: 23,
  CREATE_EVENT: 24,
  EXPORT_EVENT_LIST: 25
};
