import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 205 205",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M102.5 202.5A100 100 0 102.5 102.5 100 100 0 00102.5 202.5M69.43 46.96 140 47 140 169 69 169 77 161 132 161 132 55 77 55 77 161 69 169 69 47M146 171 146 41 63 41 63 171 55 171 55 33 154 33 154 171M84 101 99 101A1 1 0 0199 107L87 107 87 111A1 1 0 0181 111L81 104Q81 101 84 101" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }