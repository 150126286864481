import apiRequest from './apiRequest';

class GenAiService {
  async fetchPrompts(params) {
    try {
      const response = await apiRequest({
        path: `/gen-ai-prompts`,
        method: 'GET',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new GenAiService();
