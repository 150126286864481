import apiRequest from './apiRequest';

class EventListService {
  async fetchEventList(params) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zone/{zoneId}/event-list`,
        method: 'GET',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchDashboardEventListByZoneId(zoneId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zone/${zoneId}/event-list/get-dashboard-event-list`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async resolveAllItems() {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zone/{zoneId}/event-list/close-all`,
        method: 'POST'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async ExportToCsv(filters) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/zone/{zoneId}/event-list/export`,
        method: 'POST',
        data: filters,
        responseType: 'blob'
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new EventListService();
