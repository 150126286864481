import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "2 2 26 26",
  role: "icon",
  fill: "transparent",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", null, [
      _createElementVNode("path", {
        class: "indr__cls-1",
        stroke: "currentColor",
        d: "M22,11A7,7,0,0,0,8,11c0,3.34,3.73,9.86,6,13.51a1.15,1.15,0,0,0,1.94,0C18.27,20.86,22,14.34,22,11Z"
      }),
      _createElementVNode("circle", {
        class: "indr__cls-1",
        cx: "15",
        cy: "10",
        r: "2",
        stroke: "currentColor"
      }),
      _createElementVNode("line", {
        class: "indr__cls-2",
        x1: "26",
        y1: "24",
        x2: "20",
        y2: "24",
        stroke: "currentColor"
      }),
      _createElementVNode("polyline", {
        class: "indr__cls-2",
        points: "23 21 20 24 23 27",
        stroke: "currentColor"
      })
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }