<template>
  <BaseModal
    :confirmation-option="{ text: 'Pair', disabled: !selectedOptionId }"
    cancel-option
    class="pair-device-modal"
    @close="onResolve"
    @confirm="onResolve(true)"
  >
    <template #title>Pair to tile</template>
    <template #body>
      <div class="dialog-body">
        <div class="text">Which tile would you like to pair {{ deviceName }} to?</div>
        <BaseDropdown v-model="selectedOptionId" :options="options" />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '../../components/base/BaseModal.vue';
import BaseDropdown from '../../components/base/BaseDropdown.vue';
import { mapState, mapStores } from 'pinia';
import { useDevicesStore } from '../../store/DevicesStore';
import { useContextStore } from '../../store/ContextStore';
import devicesService from '../../services/api/devicesService';
import { SNACKBAR_TYPE } from '../../consts/appConsts';

export default {
  name: 'PairDeviceModal',
  components: {
    BaseModal,
    BaseDropdown
  },
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceName: {
      type: String,
      required: true
    }
  },
  emits: ['close'],
  data() {
    return {
      selectedOptionId: -1
    };
  },
  computed: {
    ...mapState(useDevicesStore, ['contextZoneActiveTiles']),
    ...mapStores(useContextStore),
    options() {
      return this.contextZoneActiveTiles.filter(tile => tile.physical_id).map(tile => ({ id: tile.physical_id, label: tile.name }));
    }
  },
  watch: {
    options: {
      handler: function (options) {
        if (this.selectedOptionId === -1) {
          this.selectedOptionId = options[0]?.id || -1;
        }
      },
      immediate: true
    }
  },
  methods: {
    async onResolve(isConfirmed = false) {
      if (isConfirmed && this.selectedOptionId != -1) {
        try {
          await devicesService.invokeDevicePair({
            deviceId: this.deviceId,
            tileId: this.selectedOptionId
          });
          this.contextStore.showSnackbar({
            message: 'Pair to tile request was sent successfully',
            type: SNACKBAR_TYPE.SUCCESS
          });
        } catch (e) {
          console.error(e);
          this.contextStore.showSnackbar({
            message: 'Failed to send pair to tile request, please try again later',
            type: SNACKBAR_TYPE.ERROR
          });
        }
      }

      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.pair-device-modal {
  .dialog-body {
    padding: 0 2rem;
    line-height: 2rem;
    overflow: auto;
    max-height: 75vh;
    margin: 1rem 0;
  }

  .text {
    max-width: 30rem;
    padding: 0 0 0.5rem 0;
  }
}
</style>
