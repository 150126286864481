import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 30 30",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createStaticVNode("<g><path d=\"M29,11.15c-0.27,0-0.53-0.11-0.73-0.31C24.72,7.07,20.01,5,15,5S5.28,7.07,1.73,10.84c-0.38,0.4-1.01,0.42-1.41,0.04  c-0.4-0.38-0.42-1.01-0.04-1.41C4.2,5.3,9.43,3,15,3s10.8,2.3,14.73,6.46c0.38,0.4,0.36,1.03-0.04,1.41  C29.49,11.06,29.25,11.15,29,11.15z\"></path><path d=\"M25.79,14.8c-0.27,0-0.54-0.11-0.73-0.32C22.38,11.59,18.8,10,15,10s-7.38,1.59-10.05,4.48c-0.38,0.41-1.01,0.43-1.41,0.05  c-0.41-0.38-0.43-1.01-0.05-1.41C6.54,9.82,10.63,8,15,8s8.46,1.82,11.52,5.12c0.38,0.4,0.35,1.04-0.05,1.41  C26.27,14.71,26.03,14.8,25.79,14.8z\"></path><path d=\"M19.31,22.12c-0.29,0-0.58-0.13-0.78-0.38C17.64,20.64,16.36,20,15,20s-2.64,0.64-3.53,1.74c-0.34,0.43-0.97,0.5-1.41,0.16  c-0.43-0.34-0.5-0.97-0.16-1.41C11.18,18.91,13.03,18,15,18s3.82,0.91,5.09,2.5c0.34,0.43,0.27,1.06-0.16,1.41  C19.75,22.05,19.53,22.12,19.31,22.12z\"></path><path d=\"M22.55,18.46c-0.28,0-0.55-0.11-0.75-0.34C20.02,16.11,17.6,15,15,15s-5.02,1.11-6.8,3.12c-0.37,0.41-1,0.45-1.41,0.09  c-0.41-0.37-0.45-1-0.09-1.41C8.84,14.38,11.86,13,15,13s6.16,1.38,8.3,3.8c0.37,0.41,0.33,1.05-0.09,1.41  C23.02,18.38,22.79,18.46,22.55,18.46z\"></path><circle cx=\"15\" cy=\"25\" r=\"2\"></circle></g>", 1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }