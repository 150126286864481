<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" role="icon" fill="currentColor">
    <g>
      <path
        :stroke="hex"
        d="M19,7V4c0-0.55-0.45-1-1-1h-6c-0.55,0-1,0.45-1,1v3H7C6.45,7,6,7.45,6,8v18c0,0.55,0.45,1,1,1h16  c0.55,0,1-0.45,1-1V8c0-0.55-0.45-1-1-1H19z"
      />
      <rect x="9" :y="batteryLevels[0]" width="12" :height="batteryLevels[1]" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconBattery',
  props: {
    hex: {
      type: String,
      default: 'var(--highlightColor)'
    },
    props: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    batteryLevels() {
      if (this.props.batteryLevel > 75) {
        return [10, 14];
      } else if (this.props.batteryLevel > 50) {
        return [14, 10];
      } else if (this.props.batteryLevel > 25) {
        return [17, 7];
      } else if (this.props.batteryLevel > 0) {
        return [21, 3];
      } else {
        return [21, 0];
      }
    }
  }
};
</script>

<style scoped lang="scss">
path {
  fill: none;
  stroke-width: 2;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}
</style>
