<template>
  <div v-if="!contextStore.auth.isAuthenticated" class="login-view">
    <BaseLogo class="logo" />
    <UserLoginForm v-show="formToDisplay === FORMS.USER_LOGIN" @next="formToDisplay = FORMS.ACCOUNT_LOGIN" @done="routeToRequestedPath">
      <div v-if="errorMsg">{{ errorMsg }}</div>
    </UserLoginForm>
    <AccountLoginForm
      v-show="formToDisplay === FORMS.ACCOUNT_LOGIN"
      @back="onAccountLoginBack"
      @create-account="formToDisplay = FORMS.CREATE_ACCOUNT"
      @done="routeToRequestedPath"
    />
    <CreateAccountForm v-if="formToDisplay === FORMS.CREATE_ACCOUNT" @back="formToDisplay = FORMS.ACCOUNT_LOGIN" />
  </div>
</template>

<script>
import BaseLogo from '../../components/base/BaseLogo.vue';
import { mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import UserLoginForm from './UserLoginForm.vue';
import AccountLoginForm from './AccountLoginForm.vue';
import CreateAccountForm from './CreateAccountForm.vue';
import { ERROR_MESSAGES } from '../../consts/appConsts';
import LocalStorageManager from '../../services/LocalStorageManager';
import { PAGES } from '../../router';

const FORMS = {
  USER_LOGIN: 0,
  ACCOUNT_LOGIN: 1,
  CREATE_ACCOUNT: 2,
  MFA_LOGIN: 3
};

export default {
  name: 'LoginView',
  components: {
    BaseLogo,
    UserLoginForm,
    AccountLoginForm,
    CreateAccountForm
  },
  props: {
    hasSessionExpired: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      errorMsg: this.hasSessionExpired ? ERROR_MESSAGES.SESSION_EXPIRED : false,
      FORMS,
      formToDisplay: FORMS.USER_LOGIN
    };
  },
  computed: {
    ...mapStores(useContextStore)
  },
  watch: {
    'contextStore.auth.isAuthenticated': {
      handler: function isAuthenticatedWatch(newVal) {
        newVal && this.routeToRequestedPath();
      }
    },
    'contextStore.auth.isSessionLoading': {
      handler: function isSessionLoadingWatch(newVal) {
        if (!newVal && !this.contextStore.auth?.isAuthenticated) {
          this.errorMsg = this.contextStore.auth.sessionLoadingResults.hasSessionExpired
            ? ERROR_MESSAGES.SESSION_EXPIRED
            : ERROR_MESSAGES.SESSION_RESTORE_FAILED;
        }
      }
    }
  },
  created() {
    if (this.contextStore.auth?.isAuthenticated) {
      this.routeToRequestedPath();
    }
  },
  methods: {
    routeToRequestedPath() {
      const requestedPath = LocalStorageManager.getItem('RequestedPath');
      if (requestedPath) {
        LocalStorageManager.removeItem('RequestedPath');
        this.$router.push(requestedPath);
      } else {
        this.$router.push({
          name: PAGES.DASHBOARD
        });
      }
    },
    onAccountLoginBack(errorMessage) {
      this.formToDisplay = FORMS.USER_LOGIN;
      this.errorMsg = errorMessage;
    }
  }
};
</script>

<style scoped lang="scss">
.login-view {
  color: var(--secondaryTextColor);
  padding: 5rem 5rem 2rem;
  font-size: 1.2rem;
  flex-grow: 1;
  margin: auto;

  .logo {
    margin-bottom: 12px;
  }
}
</style>
