<template>
  <div class="detection-settings-form">
    <IconTrash class="remove-icon" @click="$emit('remove')">
      <title>Remove</title>
    </IconTrash>
    <div class="form-fields">
      <span>Prompt Type</span>
      <BaseDropdown v-model="detectionData.type" :options="enabledDetectionPromptsOptions" />
      <template v-if="allowTriggerType(detectionData.type)">
        <span>Trigger type</span>
        <BaseRadioGroup v-model="detectionData.trigger_type" :options="TRIGGER_TYPE_OPTIONS" />
      </template>
      <template v-if="detectionData.type === 'CUSTOM' || detectionData.type === 'RAW'">
        <span>(User) Prompt</span>
        <component :is="userPromptComponent" v-model="detectionData.user_prompt" :maxlength="TEXT_INPUT_MAX_LENGTH" :rows="RAW_INPUT_PROMPTS_ROWS" />
      </template>
      <template v-if="detectionData.type === 'RAW'">
        <span>System Prompt</span>
        <BaseTextareaInput v-model="detectionData.system_prompt" :maxlength="TEXT_AREA_MAX_LENGTH" :rows="RAW_INPUT_PROMPTS_ROWS" />
      </template>
    </div>
  </div>
</template>

<script>
import BaseDropdown from './base/BaseDropdown.vue';
import BaseRadioGroup from './base/BaseRadioGroup.vue';
import BaseTextareaInput from './base/BaseTextareaInput.vue';
import BaseTextInput from './base/BaseTextInput.vue';
import IconTrash from '../components/icons/IconTrash.svg?component';
import { useContextStore } from '../store/ContextStore';
import genAiService from '../services/api/genAiService';
import { mapState } from 'pinia';
const TRIGGER_TYPES = {
  OBJECT_EXIST: 'OBJECT_EXIST',
  OBJECT_MISSING: 'OBJECT_MISSING'
};
const TRIGGER_TYPE_OPTIONS = [
  {
    id: TRIGGER_TYPES.OBJECT_EXIST,
    label: 'Trigger Event if object found'
  },
  {
    id: TRIGGER_TYPES.OBJECT_MISSING,
    label: 'Trigger Event if object not found'
  }
];

const TECHNICIAN_PROMPTS_OPTIONS = [
  {
    id: 'CUSTOM',
    label: 'Custom'
  },
  {
    id: 'RAW',
    label: 'Raw'
  }
];
export default {
  name: 'DetectionSettingForm',
  components: {
    BaseDropdown,
    BaseTextareaInput,
    BaseTextInput,
    BaseRadioGroup,
    IconTrash
  },
  props: {
    modelValue: {
      required: false,
      default: null,
      type: [null, Object]
    }
  },
  emits: ['remove', 'update:modelValue'],
  data() {
    return {
      TRIGGER_TYPE_OPTIONS,
      detectionData: {
        type: '',
        trigger_type: TRIGGER_TYPES.OBJECT_EXIST,
        user_prompt: '',
        system_prompt: '',
        technician_mode: false
      },
      TEXT_INPUT_MAX_LENGTH: 40,
      TEXT_AREA_MAX_LENGTH: 140,
      RAW_INPUT_PROMPTS_ROWS: 3,
      promptsMap: {},
      enabledDetectionPromptsOptions: []
    };
  },
  computed: {
    ...mapState(useContextStore, ['zone', 'isTechnician']),
    userPromptComponent() {
      if (this.detectionData.type === 'RAW') {
        return BaseTextareaInput;
      }
      return BaseTextInput;
    }
  },
  watch: {
    'detectionData.type': {
      handler: function onDetectionTypeChange(newVal) {
        if (this.allowTriggerType(newVal) && !this.detectionData.trigger_type) {
          this.detectionData.trigger_type = TRIGGER_TYPES.OBJECT_EXIST;
        } else if (!this.allowTriggerType(newVal)) {
          this.detectionData.trigger_type = '';
        }
      }
    },
    detectionData: {
      handler: function onDetectionFormFieldChange(newVal) {
        if (this.isTechnician && !this.zone?.enabled_gen_ai_prompts?.includes(newVal.type)) {
          newVal.technician_mode = true;
        } else {
          newVal.technician_mode = false;
        }
        this.$emit('update:modelValue', newVal);
      },
      deep: true
    }
  },
  async created() {
    const promptsArray = await genAiService.fetchPrompts();
    this.promptsMap = this.allowObjectMissingMap = promptsArray.reduce((acc, prompt) => ((acc[prompt.id] = prompt), acc), {});

    if (this.isTechnician) {
      this.enabledDetectionPromptsOptions = promptsArray.map(({ id, name: label }) => ({ id, label })).concat(TECHNICIAN_PROMPTS_OPTIONS);
    } else {
      this.enabledDetectionPromptsOptions = promptsArray
        .filter(prompt => this.zone?.enabled_gen_ai_prompts?.includes(prompt.id))
        .map(({ id, name: label }) => ({ id, label }));
    }

    this.detectionData.type = this.enabledDetectionPromptsOptions[0].id;
    if (this.modelValue) {
      Object.keys(this.detectionData).forEach(field => {
        this.detectionData[field] = this.modelValue[field];
      });
    } else {
      this.$emit('update:modelValue', this.detectionData);
    }
  },
  methods: {
    allowTriggerType(type) {
      return (type !== 'RAW' && this.promptsMap[type]?.allow_missing) || type === 'CUSTOM';
    }
  }
};
</script>

<style lang="scss" scoped>
.detection-settings-form {
  padding: 1rem 10px;
  border-radius: 8px;
  background: var(--secondaryOverlayShade1);
  font-size: 1rem;
  display: flex;
  flex-direction: row-reverse;
  column-gap: 1rem;

  .form-fields {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    flex-grow: 1;

    span {
      line-height: 2rem;
    }
  }

  .remove-icon {
    width: 1.1rem;
    height: 1.1rem;
    cursor: pointer;
    transition: background 0.25s ease-out, transform 0.2s cubic-bezier(0.36, 1.47, 0.59, 1.63);

    &:hover {
      color: var(--highlightColor);
      transform: scale(1.1, 1.1);
    }

    &:active {
      color: var(--highlightColorShade1);
    }
  }
}
</style>
