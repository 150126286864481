<template>
  <span class="mission-hooks-results">
    <div v-if="groupedMissionHooks['BEFORE'] && groupedMissionHooks['BEFORE'].length > 0" class="hook-result">
      <div class="title">Before</div>
      <div v-for="hookData in groupedMissionHooks['BEFORE']" :key="`hook-result-${hookData.id}`" class="hook">
        <span>{{ hookData.name }}:</span>
        <span class="target-action">{{ hookData.state }}</span>
        <span class="result-text" :class="{ success: hookData.success, fail: !hookData.success }">{{ hookData.result }}</span>
      </div>
    </div>
    <div v-if="groupedMissionHooks['AFTER'] && groupedMissionHooks['AFTER'].length > 0" class="hook-result">
      <div class="title">After</div>
      <div v-for="hookData in groupedMissionHooks['AFTER']" :key="`hook-result-${hookData.id}`" class="hook">
        <span>{{ hookData.name }}:</span>
        <span class="target-action">{{ hookData.state }}</span>
        <span class="result-text" :class="{ success: hookData.success, fail: !hookData.success }">{{ hookData.result }}</span>
      </div>
    </div>
  </span>
</template>

<script>
import { MISSION_STATE } from '../../consts/missionConsts';
import { hasMissionEnded } from '../../utils/models/MissionUtils';
export default {
  name: 'MissionHooksResults',
  props: {
    mission: {
      type: Object,
      required: true
    }
  },
  computed: {
    groupedMissionHooks() {
      return this.mission.hooks.reduce((map, hook) => {
        map[hook.timing] = map[hook.timing] || [];
        let resultStr = '';
        if (hook.status) {
          resultStr = hook.status.success ? 'Success' : 'Failed';
        } else {
          resultStr = 'Pending';

          if (hasMissionEnded(this.mission) && this.mission.state !== MISSION_STATE.FINISHED) {
            resultStr = 'Skipped';
          }
        }

        map[hook.timing].push({
          name: hook.device_name || hook.device_id,
          state: hook.initialState || hook.action.state,
          result: resultStr,
          success: hook.status && hook?.status?.success
        });
        return map;
      }, {});
    }
  }
};
</script>
<style lang="scss" scoped>
.mission-hooks-results {
  display: flex;
  column-gap: 0.7em;
  margin: 0.2em 0;
  flex-wrap: wrap;
  row-gap: 8px;

  .hook-result {
    display: flex;
    flex-direction: column;
    padding: 0.2em;
    flex-grow: 1;
    row-gap: 0.2em;
    line-height: 1.5;
    background: var(--primaryColorBrightShade1);

    .title {
      font-weight: 500;
      text-transform: capitalize;
      align-self: center;
      text-align: center;
      width: 100%;
      background: var(--primaryColor);
    }

    .hook {
      display: flex;
      flex-wrap: wrap;
      column-gap: 0.7rem;

      .target-action {
        flex-shrink: 0;
      }

      .result-text {
        flex-shrink: 0;
        &.success {
          color: var(--successColor);
        }

        &.fail {
          color: var(--errorColor);
        }
      }
    }
  }
}
</style>
