import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 18 18",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", {
      fill: "currentColor",
      d: "M.3.3c-.4.4-.4 1.05 0 1.45L7.05 8.5.3 15.25a1.025 1.025 0 1 0 1.45 1.45L8.5 9.95l6.75 6.75a1.025 1.025 0 1 0 1.45-1.45L9.95 8.5l6.75-6.75A1.025 1.025 0 0 0 15.25.3L8.5 7.05 1.75.3C1.35-.1.7-.1.3.3z"
    }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }