import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "2 4 22 22",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", { transform: "translate(6 4)" }, [
      _createElementVNode("path", { d: "M10 0l1 1h4l.117.007A1 1 0 0 1 15 3v15c0 1.093-.907 2-2 2H3c-1.093 0-2-.907-2-2V3l-.117-.007A1 1 0 0 1 1 1h4l1-1h4zm3 3H3v15h10V3zM6 5a1 1 0 0 1 1 1v9a1 1 0 0 1-2 0V6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v9a1 1 0 0 1-2 0V6a1 1 0 0 1 1-1z" })
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }