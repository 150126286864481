<template>
  <div class="event-stat">
    <div v-if="EVENT_TYPE_ICONS[eventStat.event_type]">
      <component :is="EVENT_TYPE_ICONS[eventStat.event_type]" class="icon" />
    </div>
    <div>{{ EVENT_TYPE_NAME[eventStat.event_type] }}</div>
    <div class="amount">{{ eventStat.count }}</div>
  </div>
</template>

<script>
import { EVENT_TYPE_ICONS, EVENT_TYPE_NAME } from '../../consts/eventConsts';
import { shallowRef } from 'vue';

export default {
  name: 'DashboardEventStat',
  props: {
    eventStat: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      EVENT_TYPE_ICONS: shallowRef(EVENT_TYPE_ICONS),
      EVENT_TYPE_NAME
    };
  }
};
</script>

<style lang="scss" scoped>
.event-stat {
  text-align: center;

  .icon {
    color: var(--highlightColor);
    margin: 0 0 0.5rem 0;
    width: 3.15rem;
    height: 3.15rem;
  }

  .amount {
    font-weight: bold;
    font-size: 1.55rem;
  }
}
</style>
