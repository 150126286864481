<template>
  <svg width="400" height="152" viewBox="0 0 100 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.7529 18.8116V4.74005H42.2764V18.8116H39.7529Z" fill="white" />
    <path
      d="M43.9684 18.8116V8.63572H46.376V10.0853C46.8615 9.09595 48.0064 8.43097 49.4556 8.43097C51.8777 8.43097 53.3106 9.98561 53.3106 12.5078V18.8079H50.8922V12.8648C50.8922 11.4714 50.086 10.6035 48.7328 10.6035C47.3796 10.6035 46.3778 11.5729 46.3778 12.959V18.8134L43.9684 18.8116Z"
      fill="white"
    />
    <path
      d="M62.4391 10.1088V4.74005H64.8539V18.8116H62.4391V17.3385C62.0949 18.326 60.8648 19.0326 59.1891 19.0326C58.5117 19.046 57.8388 18.9184 57.2133 18.6579C56.5877 18.3975 56.0231 18.0099 55.5552 17.5197C54.586 16.4905 54.1059 15.2384 54.1059 13.7146C54.1059 12.1907 54.5896 10.9496 55.5552 9.93851C56.0192 9.442 56.5827 9.04888 57.2088 8.78476C57.835 8.52064 58.5097 8.39145 59.1891 8.40561C60.8685 8.41467 62.0949 9.12132 62.4391 10.1088ZM61.7145 15.9451C62.0115 15.6581 62.2455 15.3123 62.4014 14.9299C62.5574 14.5474 62.6319 14.1366 62.6202 13.7236C62.6318 13.3108 62.5572 12.9 62.4013 12.5175C62.2453 12.135 62.0114 11.7893 61.7145 11.5022C61.4267 11.213 61.0832 10.9853 60.7049 10.8327C60.3266 10.6801 59.9212 10.6058 59.5134 10.6144C59.1118 10.6059 58.7128 10.6804 58.3412 10.8332C57.9697 10.986 57.6336 11.2137 57.354 11.5022C57.0726 11.7978 56.8527 12.1465 56.7072 12.5278C56.5616 12.9092 56.4933 13.3157 56.5062 13.7236C56.4931 14.1315 56.5611 14.5379 56.7063 14.9193C56.8516 15.3006 57.0711 15.6493 57.3522 15.9451C57.6318 16.2336 57.9679 16.4613 58.3394 16.6141C58.7109 16.7669 59.11 16.8414 59.5116 16.8329C59.9194 16.8415 60.3248 16.7672 60.7031 16.6146C61.0814 16.462 61.4249 16.2343 61.7126 15.9451H61.7145Z"
      fill="white"
    />
    <path
      d="M65.7289 13.7236C65.7155 13.0068 65.8524 12.2952 66.1307 11.6345C66.409 10.9739 66.8226 10.3788 67.3448 9.88776C68.4119 8.8887 69.8188 8.33282 71.2804 8.33282C72.742 8.33282 74.149 8.8887 75.216 9.88776C75.7383 10.3788 76.1518 10.9739 76.4302 11.6345C76.7085 12.2952 76.8453 13.0068 76.8319 13.7236C76.8453 14.4404 76.7085 15.1521 76.4302 15.8128C76.1518 16.4734 75.7383 17.0685 75.216 17.5595C74.149 18.5586 72.742 19.1145 71.2804 19.1145C69.8188 19.1145 68.4119 18.5586 67.3448 17.5595C66.8226 17.0685 66.409 16.4734 66.1307 15.8128C65.8524 15.1521 65.7155 14.4404 65.7289 13.7236ZM73.5186 16.0049C73.8153 15.7052 74.0485 15.3487 74.2042 14.9567C74.3598 14.5647 74.4347 14.1453 74.4244 13.7236C74.4365 13.3047 74.3624 12.8878 74.2066 12.4988C74.0508 12.1098 73.8166 11.757 73.5186 11.4623C72.921 10.8671 72.112 10.5328 71.2686 10.5328C70.4253 10.5328 69.6162 10.8671 69.0187 11.4623C68.7266 11.7605 68.4974 12.1143 68.3447 12.5028C68.1919 12.8912 68.1186 13.3064 68.1292 13.7236C68.1206 14.1438 68.1949 14.5616 68.3479 14.953C68.5008 15.3444 68.7294 15.7019 69.0205 16.0049C69.6248 16.5873 70.4313 16.9126 71.2704 16.9126C72.1096 16.9126 72.9161 16.5873 73.5204 16.0049H73.5186Z"
      fill="white"
    />
    <path
      d="M77.2758 13.7236C77.2625 13.007 77.3992 12.2956 77.6772 11.6349C77.9552 10.9743 78.3682 10.3792 78.8899 9.88776C79.9574 8.88897 81.3646 8.33331 82.8264 8.33331C84.2882 8.33331 85.6954 8.88897 86.7629 9.88776C87.2845 10.3792 87.6976 10.9743 87.9756 11.6349C88.2536 12.2956 88.3903 13.007 88.377 13.7236C88.3903 14.4403 88.2536 15.1517 87.9756 15.8123C87.6976 16.4729 87.2845 17.0681 86.7629 17.5595C85.6954 18.5583 84.2882 19.114 82.8264 19.114C81.3646 19.114 79.9574 18.5583 78.8899 17.5595C78.3682 17.0681 77.9552 16.4729 77.6772 15.8123C77.3992 15.1517 77.2625 14.4403 77.2758 13.7236ZM85.0655 16.0049C85.362 15.705 85.595 15.3485 85.7506 14.9565C85.9062 14.5646 85.9813 14.1452 85.9713 13.7236C85.9831 13.3048 85.9088 12.8879 85.753 12.499C85.5972 12.11 85.3632 11.7572 85.0655 11.4623C84.4676 10.8669 83.6583 10.5327 82.8146 10.5327C81.9709 10.5327 81.1616 10.8669 80.5638 11.4623C80.2723 11.7609 80.0436 12.1148 79.8911 12.5031C79.7387 12.8915 79.6656 13.3065 79.6761 13.7236C79.6584 14.5716 79.9776 15.392 80.5638 16.0049C81.1684 16.5874 81.9751 16.9128 82.8146 16.9128C83.6541 16.9128 84.4609 16.5874 85.0655 16.0049Z"
      fill="white"
    />
    <path
      d="M89.2665 18.8115V8.63569H91.665V10.1469C92.1487 9.076 93.2592 8.43094 94.6921 8.43094C94.935 8.42769 95.1777 8.44771 95.4167 8.49074V10.8372C95.0846 10.7698 94.7466 10.7359 94.4077 10.7357C92.732 10.7357 91.6632 11.7848 91.6632 13.4536V18.8043L89.2665 18.8115Z"
      fill="white"
    />
    <path
      d="M39.7529 33.5372V22.9138H43.1514C43.5603 22.9031 43.967 22.9761 44.3467 23.1281C44.7264 23.2801 45.0711 23.5081 45.3597 23.798C45.653 24.0779 45.885 24.4157 46.041 24.79C46.1969 25.1643 46.2733 25.5669 46.2655 25.9724C46.2655 27.3748 45.443 28.5326 44.1785 28.9439L46.7528 33.5318H45.885L43.3851 29.0653C43.2796 29.0817 43.1728 29.0871 43.0662 29.0816H40.5464V33.5318L39.7529 33.5372ZM40.5464 28.3551H43.0826C43.3977 28.3645 43.7116 28.3096 44.0048 28.1936C44.298 28.0776 44.5646 27.9031 44.788 27.6805C45.0115 27.458 45.1873 27.1923 45.3046 26.8995C45.4219 26.6067 45.4782 26.2931 45.4702 25.9778C45.4754 25.6681 45.4179 25.3606 45.3011 25.0737C45.1843 24.7869 45.0107 24.5266 44.7906 24.3087C44.5706 24.0908 44.3087 23.9197 44.0207 23.8058C43.7327 23.6919 43.4247 23.6375 43.1152 23.6458H40.5464V28.3551Z"
      fill="white"
    />
    <path
      d="M47.4955 29.6959C47.4842 29.1624 47.5808 28.6321 47.7797 28.137C47.9786 27.6418 48.2757 27.192 48.6529 26.8147C49.0302 26.4374 49.4798 26.1403 49.9749 25.9414C50.4699 25.7424 51.0001 25.6457 51.5335 25.6571C52.0649 25.6432 52.5935 25.739 53.0863 25.9386C53.579 26.1381 54.0254 26.437 54.3976 26.8167C55.15 27.5869 55.5712 28.6209 55.5712 29.6977C55.5712 30.7745 55.15 31.8085 54.3976 32.5787C53.8332 33.1518 53.1104 33.5429 52.322 33.7018C51.5336 33.8606 50.7157 33.78 49.9736 33.4702C49.2314 33.1604 48.5988 32.6356 48.1572 31.9633C47.7156 31.2911 47.4852 30.502 47.4955 29.6977V29.6959ZM48.2564 29.6959C48.2438 30.134 48.3196 30.5701 48.4792 30.9783C48.6389 31.3864 48.8792 31.7582 49.1857 32.0713C49.4933 32.3809 49.8608 32.6244 50.2657 32.7871C50.6707 32.9497 51.1045 33.028 51.5407 33.0172C51.9721 33.0259 52.4007 32.9465 52.8003 32.7839C53.2 32.6213 53.5622 32.3788 53.865 32.0713C54.1735 31.7585 54.416 31.3869 54.5781 30.9785C54.7403 30.5702 54.8187 30.1334 54.8088 29.6941C54.8189 29.258 54.7405 28.8244 54.5782 28.4196C54.416 28.0147 54.1734 27.6469 53.865 27.3386C53.5641 27.0257 53.2015 26.7789 52.8001 26.6139C52.3987 26.4489 51.9673 26.3692 51.5335 26.38C51.096 26.3682 50.6608 26.4472 50.2554 26.6122C49.8501 26.7772 49.4833 27.0245 49.1785 27.3386C48.8732 27.6486 48.6341 28.0174 48.4757 28.4226C48.3172 28.8278 48.2426 29.261 48.2564 29.6959Z"
      fill="white"
    />
    <path
      d="M57.0805 22.9138H57.8432V27.4564C58.0098 26.9526 58.3758 26.5413 58.9301 26.188C59.6717 25.7499 60.5388 25.5732 61.3927 25.6862C62.2466 25.7992 63.0379 26.1953 63.6401 26.8113C64.3647 27.5957 64.7671 28.6244 64.7671 29.6923C64.7671 30.7602 64.3647 31.7889 63.6401 32.5733C63.0362 33.1857 62.2464 33.5807 61.3942 33.6965C60.5419 33.8122 59.6754 33.6421 58.9301 33.2129C58.4292 32.927 58.0445 32.4742 57.8432 31.9337V33.5336H57.0805V22.9138ZM63.0985 32.0714C63.6784 31.4181 63.9987 30.5749 63.9987 29.7013C63.9987 28.8278 63.6784 27.9845 63.0985 27.3313C62.8212 27.0219 62.4803 26.776 62.0991 26.6106C61.718 26.4452 61.3056 26.364 60.8902 26.3728C60.4715 26.3629 60.0554 26.4419 59.6694 26.6046C59.2834 26.7673 58.9364 27.0101 58.6511 27.3168C58.3509 27.6338 58.1162 28.007 57.9608 28.415C57.8053 28.823 57.7321 29.2577 57.7453 29.6941C57.7185 30.5804 58.0442 31.4412 58.6511 32.0877C58.9415 32.3835 59.2881 32.6182 59.6706 32.7779C60.0531 32.9377 60.4637 33.0192 60.8782 33.0176C61.2927 33.0161 61.7027 32.9316 62.084 32.7691C62.4654 32.6066 62.8103 32.3693 63.0985 32.0714Z"
      fill="white"
    />
    <path
      d="M65.9191 29.6959C65.9377 28.7836 66.2707 27.9058 66.8619 27.2108C67.4531 26.5159 68.2661 26.0466 69.1634 25.8822C70.0607 25.7178 70.9873 25.8684 71.7863 26.3087C72.5854 26.7489 73.2079 27.4517 73.5485 28.2981C73.8891 29.1446 73.927 30.0827 73.6557 30.9539C73.3844 31.8251 72.8206 32.5757 72.0596 33.079C71.2987 33.5822 70.3873 33.8071 69.4796 33.7156C68.572 33.6241 67.7238 33.2218 67.0785 32.5768C66.6988 32.2012 66.4001 31.7518 66.2007 31.2564C66.0013 30.7609 65.9055 30.2298 65.9191 29.6959ZM66.6818 29.6959C66.6691 30.134 66.7449 30.5701 66.9046 30.9782C67.0643 31.3864 67.3046 31.7581 67.6111 32.0713C67.9175 32.3799 68.2835 32.6229 68.6868 32.7855C69.0901 32.9481 69.5223 33.0269 69.9571 33.0171C70.3884 33.0259 70.817 32.9465 71.2167 32.7839C71.6163 32.6212 71.9786 32.3788 72.2813 32.0713C72.5904 31.7588 72.8334 31.3873 72.9959 30.9789C73.1583 30.5705 73.2369 30.1335 73.2269 29.6941C73.2371 29.2578 73.1585 28.8241 72.996 28.4192C72.8334 28.0142 72.5903 27.6466 72.2813 27.3385C71.9805 27.0257 71.6178 26.7789 71.2164 26.6139C70.815 26.4488 70.3837 26.3692 69.9498 26.38C69.5138 26.3692 69.0803 26.4487 68.6765 26.6137C68.2728 26.7787 67.9076 27.0255 67.6038 27.3385C67.2983 27.6483 67.059 28.0171 66.9004 28.4224C66.7419 28.8276 66.6675 29.2609 66.6818 29.6959Z"
      fill="white"
    />
    <path d="M76.053 33.5372V26.5721H75.0947V25.8473H76.053V22.9138H76.8102V25.8564H78.6218V26.5812H76.8102V33.5463L76.053 33.5372Z" fill="white" />
    <path
      d="M79.9551 24.1785C79.86 24.0746 79.8072 23.9389 79.8072 23.798C79.8072 23.6572 79.86 23.5214 79.9551 23.4175C80.4424 22.8739 81.2648 23.7219 80.7322 24.1785C80.6257 24.2748 80.4872 24.3281 80.3436 24.3281C80.2001 24.3281 80.0616 24.2748 79.9551 24.1785ZM79.9696 33.5372V25.8564H80.7322V33.5372H79.9696Z"
      fill="white"
    />
    <path
      d="M89.2194 26.862L88.7665 27.3802C88.4509 27.0501 88.0694 26.7901 87.6469 26.6168C87.2243 26.4436 86.7701 26.3611 86.3137 26.3746C85.8762 26.3627 85.441 26.4417 85.0356 26.6067C84.6302 26.7717 84.2635 27.0191 83.9586 27.3331C83.6523 27.6423 83.412 28.0106 83.2523 28.4155C83.0925 28.8204 83.0167 29.2535 83.0293 29.6886C83.0162 30.1282 83.0921 30.5658 83.2524 30.9753C83.4127 31.3848 83.6542 31.7576 83.9623 32.0713C84.2699 32.3809 84.6374 32.6244 85.0423 32.787C85.4472 32.9497 85.8811 33.028 86.3173 33.0171C86.773 33.0304 87.2264 32.9479 87.6483 32.775C88.0702 32.6021 88.4511 32.3426 88.7665 32.0133L89.223 32.5315C88.4767 33.3379 87.502 33.7347 86.3137 33.7347C85.2427 33.7347 84.2156 33.3092 83.4584 32.5517C82.7011 31.7943 82.2757 30.767 82.2757 29.6959C82.2757 28.6247 82.7011 27.5974 83.4584 26.84C84.2156 26.0826 85.2427 25.657 86.3137 25.657C87.4984 25.657 88.473 26.0539 89.2194 26.862Z"
      fill="white"
    />
    <path
      d="M90.6378 31.602C90.9422 32.4391 91.6886 32.9882 92.8461 32.9882C94.0816 32.9882 94.6577 32.363 94.6577 31.5857C94.6686 31.3953 94.6344 31.2049 94.5579 31.0302C94.4815 30.8555 94.3648 30.7012 94.2175 30.5801C93.7449 30.2479 93.2324 29.9764 92.6922 29.772C91.3063 29.1776 90.6505 28.6848 90.6505 27.5469C90.6505 26.4507 91.5563 25.657 92.7701 25.657C93.7284 25.657 94.415 26.0539 94.8262 26.862L94.2012 27.1791C94.0653 26.9246 93.8601 26.7138 93.6094 26.5711C93.3587 26.4284 93.0727 26.3596 92.7845 26.3728C91.9929 26.3728 91.4132 26.8294 91.4132 27.5451C91.4132 28.3224 91.9313 28.6739 93.165 29.1758C94.7501 29.8318 95.4204 30.4098 95.4204 31.5839C95.4204 32.758 94.4929 33.7021 92.8461 33.7021C91.4295 33.7021 90.3643 33.0171 89.9984 31.843L90.6378 31.602Z"
      fill="white"
    />
    <path
      d="M18.9779 12.8303C17.9871 12.8304 17.0269 12.4869 16.2609 11.8582C15.4949 11.2295 14.9706 10.3546 14.7773 9.38259C14.584 8.41058 14.7337 7.4016 15.2008 6.52758C15.6679 5.65357 16.4236 4.96861 17.339 4.58942C18.2545 4.21023 19.273 4.16028 20.2212 4.44808C21.1693 4.73588 21.9884 5.34362 22.5387 6.16773C23.0891 6.99185 23.3367 7.98135 23.2394 8.96761C23.1421 9.95387 22.7058 10.8759 22.005 11.5765C21.6081 11.9749 21.1363 12.2907 20.6167 12.5059C20.0972 12.7211 19.5402 12.8314 18.9779 12.8303Z"
      fill="#E83C1A"
    />
    <path
      d="M12.4599 21.649L12.4599 5.08066C12.4599 4.87151 12.2904 4.70197 12.0813 4.70197L4.95643 4.70197C4.74733 4.70197 4.57781 4.87151 4.57781 5.08066L4.57781 21.649C4.57781 21.8582 4.74733 22.0277 4.95643 22.0277H12.0813C12.2904 22.0277 12.4599 21.8582 12.4599 21.649Z"
      fill="white"
    />
    <path
      d="M22.9199 33.1277V15.5066C22.9199 15.2974 22.7504 15.1279 22.5413 15.1279H15.4164C15.2073 15.1279 15.0378 15.2974 15.0378 15.5066V33.1277C15.0378 33.3368 15.2073 33.5064 15.4164 33.5064H22.5413C22.7504 33.5064 22.9199 33.3368 22.9199 33.1277Z"
      fill="white"
    />
    <path
      d="M33.378 33.1295V20.5311C33.378 20.322 33.2085 20.1524 32.9994 20.1524H25.8745C25.6654 20.1524 25.4959 20.322 25.4959 20.5311V33.1295C25.4959 33.3387 25.6654 33.5082 25.8745 33.5082H32.9994C33.2085 33.5082 33.378 33.3387 33.378 33.1295Z"
      fill="white"
    />
    <path
      d="M33.378 7.97799V17.2189C33.378 17.3193 33.3381 17.4156 33.2671 17.4867C33.1961 17.5577 33.0998 17.5976 32.9994 17.5976H25.8727C25.7726 17.5971 25.6768 17.557 25.6062 17.486C25.5356 17.4151 25.4959 17.319 25.4959 17.2189V5.07889C25.4959 4.97845 25.5358 4.88213 25.6068 4.81111C25.6778 4.74009 25.7741 4.7002 25.8745 4.7002H30.0991C30.9684 4.7002 31.8021 5.04547 32.417 5.66013C33.0319 6.27478 33.3775 7.1085 33.378 7.97799Z"
      fill="white"
    />
    <path
      d="M12.4617 24.9613V33.1277C12.4617 33.2281 12.4218 33.3245 12.3508 33.3955C12.2798 33.4665 12.1835 33.5064 12.0831 33.5064H7.84949C6.97987 33.5064 6.14586 33.1609 5.53094 32.5458C4.91603 31.9308 4.57057 31.0966 4.57057 30.2268V24.9613C4.57057 24.9114 4.58042 24.862 4.59956 24.8159C4.6187 24.7699 4.64675 24.728 4.6821 24.6929C4.71745 24.6577 4.7594 24.6298 4.80555 24.6109C4.85169 24.592 4.90112 24.5823 4.951 24.5826H12.0831C12.1835 24.5826 12.2798 24.6225 12.3508 24.6935C12.4218 24.7645 12.4617 24.8608 12.4617 24.9613Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'BaseLogo'
};
</script>
