<template>
  <div class="edit-integration-sms">
    <div class="property-container">
      <div class="label-container">Mobile Numbers:</div>
      <div class="value-container">
        <BaseTagInput v-model="integrationData.data.to" />
        <div v-if="showError" class="error-message">Please make sure all mobile numbers are in the form of +123456789012</div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTagInput from '../../components/base/BaseTagInput.vue';
import { toRaw } from 'vue';

const PHONE_REGEX = /^\+\d{9,12}$/;

export default {
  name: 'EditIntegrationSMS',
  components: { BaseTagInput },
  props: {
    integration: {
      type: Object,
      required: true
    }
  },
  emits: ['update'],
  data() {
    return {
      integrationData: {}
    };
  },
  computed: {
    toOptions() {
      return this.integrationData.data.to.map(mobileNumber => ({
        id: mobileNumber,
        label: mobileNumber
      }));
    },
    showError() {
      return !this.isAllNumbersValid;
    },
    isAllNumbersValid() {
      let valid = true;
      if (this.integrationData.data.to && this.integrationData.data.to.length > 0) {
        this.integrationData.data.to.forEach(mobileNumber => {
          valid = valid && PHONE_REGEX.test(mobileNumber);
        });
      }
      return valid;
    },
    isSaveEnabled() {
      return this.integrationData.data.to && this.integrationData.data.to.length > 0 && this.isAllNumbersValid;
    }
  },
  created() {
    this.integrationData = structuredClone(toRaw(this.integration));
    if (!this.integrationData.data.to) {
      this.integrationData.data.to = [];
    }
  },
  mounted() {
    this.$emit('update');
  },
  updated() {
    this.$emit('update');
  },
  methods: {
    getDiffAttributes() {
      const origin = this.integration.data;
      const diff = {};
      Object.keys(this.integrationData.data).forEach(key => {
        if (JSON.stringify(this.integrationData.data[key]) !== JSON.stringify(origin[key])) {
          diff[key] = this.integrationData.data[key];
        }
      });
      return diff;
    }
  }
};
</script>

<style scoped lang="scss">
.edit-integration-sms {
  .property-container {
    margin-top: 20px;
    .label-container {
      color: var(--secondaryTextColor);
      margin-bottom: 5px;
    }
    .value-container {
      &.text-value {
        color: var(--secondaryTextColor);
      }
      .error-message {
        font-size: 13px;
        color: var(--errorColor);
      }
    }
  }
}
</style>
