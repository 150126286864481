import { defineStore } from 'pinia';
import integrationsService from '../services/api/integrationsService';
import { INTEGRATION_EVENT_TYPE } from '../consts/integrationsConsts';
import { MISSION_STATE } from '../consts/missionConsts';

export const useIntegrationsStore = defineStore('integrations', {
  state() {
    return {
      integrationsMap: new Map()
    };
  },
  getters: {
    integrations() {
      return Array.from(this.integrationsMap.values());
    },
    getIntegrationById() {
      return integrationId => this.integrationsMap.get(integrationId);
    }
  },
  actions: {
    async fetchIntegrations() {
      try {
        this.$reset();
        const integrationsArr = await integrationsService.fetchIntegrations();

        // TAPP-617: STOPPED and FAILED should be addressed as the same state
        // therefore - hide the 'STOPPED' state from display
        const missionIntegration = integrationsArr.find(integration => integration.event_type === INTEGRATION_EVENT_TYPE.MISSION);

        if (missionIntegration) {
          missionIntegration.data.states = missionIntegration.data.states.filter(state => state !== MISSION_STATE.STOPPED);
        }

        integrationsArr.forEach(integration => {
          this.integrationsMap.set(integration.id, integration);
        });
        return integrationsArr;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async updateIntegration(integration) {
      try {
        let _integration;

        // TAPP-617: STOPPED and FAILED should be addressed as the same state
        // therefore - add 'STOPPED' whenever 'FAILED' is requested
        if (integration.data && Array.isArray(integration.data.states) && integration.data.states.includes(MISSION_STATE.FAILED)) {
          integration.data.states.push(MISSION_STATE.STOPPED);
        }

        if (integration.id) {
          _integration = await integrationsService.patchIntegration(integration);
        } else {
          _integration = await integrationsService.createIntegration(integration);
        }

        this.integrationsMap.set(_integration.id, _integration);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async deleteIntegration(integrationId) {
      try {
        await integrationsService.deleteIntegration(integrationId);
        this.integrationsMap.delete(integrationId);
      } catch (e) {
        return Promise.reject(e);
      }
    }
  }
});
