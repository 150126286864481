import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "-1 0 22 22",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M10 0c5.523 0 10 4.477 10 10a9.965 9.965 0 0 1-2.725 6.861l3.432 3.432a1 1 0 0 1-1.32 1.497l-.094-.083-3.5-3.5-.032-.033A9.945 9.945 0 0 1 10 20C4.477 20 0 15.523 0 10S4.477 0 10 0zm0 1.818a8.182 8.182 0 1 0 0 16.364 8.182 8.182 0 0 0 0-16.364zM10 7c3.636 0 5 3 5 3s-1.364 3-5 3-5-3-5-3 1.364-3 5-3zm0 1a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }