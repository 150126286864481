<template>
  <MapToolbox>
    <div
      v-for="areaShapeConfig in areaShapesConfig"
      :key="`area-shape-button:${areaShapeConfig.shape}`"
      :class="['area-shape-button', { selected: areaShapeConfig.shape === modelValue }]"
      :title="areaShapeConfig.title"
      @click="onAreaShapeSelect(areaShapeConfig.shape)"
    >
      <component :is="areaShapeConfig.icon" :class="['icon', areaShapeConfig.shape.toLowerCase()]" />
    </div>
  </MapToolbox>
</template>

<script>
import { AREA_SHAPES } from '../../consts/areasConsts';
import MapToolbox from './MapToolbox.vue';
import IconRectangle from '../../components/icons/IconRectangle.svg?component';
import IconPolygon from '../../components/icons/IconPolygon.svg?component';
import { shallowRef } from 'vue';

export default {
  name: 'MapAreaEditToolbox',
  components: {
    MapToolbox,
    IconRectangle,
    IconPolygon
  },
  props: {
    modelValue: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      areaShapesConfig: [
        {
          icon: shallowRef(IconRectangle),
          shape: AREA_SHAPES.RECTANGLE,
          title: 'Rectangle. Define the area using a single rectangle'
        },
        {
          icon: shallowRef(IconPolygon),
          shape: AREA_SHAPES.POLYGON,
          title: 'Polygon. Define the area using a set of ordered points'
        }
      ]
    };
  },
  methods: {
    onAreaShapeSelect(areaShape) {
      this.$emit('update:modelValue', areaShape);
    }
  }
};
</script>

<style scoped lang="scss">
.area-shape-button {
  cursor: pointer;
  transition: 0.2s cubic-bezier(0.77, 1.44, 0.76, 1.43);
  background: var(--secondaryOverlayShade1);
  border-radius: 50%;
  padding: 3px;
  width: calc(2.7rem - 6px);
  height: calc(2.7rem - 6px);

  &.selected {
    border-radius: 50%;
    box-shadow: 0 0 0px 2px var(--textColor);
  }

  &:hover {
    filter: brightness(1.2);
    transform: scale(1.05);
  }

  .icon {
    width: calc(2.7rem - 6px);
    height: calc(2.7rem - 6px);
  }

  .icon.rectangle {
    width: calc(2.7rem - 16px);
    height: calc(2.7rem - 16px);
    padding: 5px;
  }
}
</style>
