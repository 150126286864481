<template>
  <MapToolbox>
    <component
      :is="landmarkTypeConfig.icon"
      v-for="landmarkTypeConfig in landmarksTypesConfig"
      :key="`landmark-type-button:${landmarkTypeConfig.type}`"
      :class="['landmark-type-button', { selected: landmarkTypeConfig.type === modelValue }]"
      @click="onLandmarkTypeSelect(landmarkTypeConfig.type)"
    >
      <title>{{ landmarkTypeConfig.title }}</title>
    </component>
  </MapToolbox>
</template>

<script>
import MapToolbox from './MapToolbox.vue';
import IconDoorLandmark from '../../components/icons/IconDoorLandmark.svg?component';
import IconWindowLandmark from '../../components/icons/IconWindowLandmark.svg?component';
import IconWC from '../../components/icons/IconWC.svg?component';
import IconEmergencyExit from '../../components/icons/IconEmergencyExit.svg?component';
import IconMainEntrance from '../../components/icons/IconMainEntrance.svg?component';
import IconServerRoom from '../../components/icons/IconServerRoom.svg?component';
import IconSafetyEquipment from '../../components/icons/IconSafetyEquipment.svg?component';
import IconFireExtinguisher from '../../components/icons/IconFireExtinguisher.svg?component';

import IconText from '../../components/icons/IconText.svg?component';
import { LANDMARKS_TYPES } from '../../consts/mapConsts';
import { shallowRef } from 'vue';

export default {
  name: 'MapLandmarksToolbox',
  components: {
    MapToolbox,
    IconDoorLandmark,
    IconWindowLandmark,
    IconText
  },
  props: {
    modelValue: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      landmarksTypesConfig: [
        {
          icon: shallowRef(IconDoorLandmark),
          type: LANDMARKS_TYPES.DOOR,
          title: 'Door'
        },
        {
          icon: shallowRef(IconWindowLandmark),
          type: LANDMARKS_TYPES.WINDOW,
          title: 'Window'
        },
        {
          icon: shallowRef(IconEmergencyExit),
          type: LANDMARKS_TYPES.EMERGENCY_EXIT,
          title: 'Emergency Exit'
        },
        {
          icon: shallowRef(IconWC),
          type: LANDMARKS_TYPES.WC,
          title: 'WC'
        },
        {
          icon: shallowRef(IconMainEntrance),
          type: LANDMARKS_TYPES.MAIN_ENTRANCE,
          title: 'Main Entrance'
        },
        {
          icon: shallowRef(IconServerRoom),
          type: LANDMARKS_TYPES.SERVER_ROOM,
          title: 'Server Room'
        },
        {
          icon: shallowRef(IconSafetyEquipment),
          type: LANDMARKS_TYPES.SAFETY_EQUIPMENT,
          title: 'Safety Equipment'
        },
        {
          icon: shallowRef(IconFireExtinguisher),
          type: LANDMARKS_TYPES.FIRE_EXTINGUISHER,
          title: 'Fire Extinguisher'
        },
        {
          icon: shallowRef(IconText),
          type: LANDMARKS_TYPES.NOTE,
          title: 'Label'
        }
      ]
    };
  },
  methods: {
    onLandmarkTypeSelect(landmarkType) {
      this.$emit('update:modelValue', landmarkType);
    }
  }
};
</script>

<style scoped lang="scss">
.landmark-type-button {
  cursor: pointer;
  transition: 0.2s cubic-bezier(0.77, 1.44, 0.76, 1.43);
  border-radius: 50%;
  color: #bdbcbc;
  width: 2.7rem;
  height: 2.7rem;

  &.selected {
    border-radius: 50%;
    box-shadow: 0 0 0px 2px var(--highlightColor);
  }

  &:hover {
    filter: brightness(1.2);
    transform: scale(1.05);
  }
}
</style>
