<template>
  <div class="expandable-card">
    <slot name="foreground"></slot>
    <transition name="fade">
      <div v-if="isOpen" class="card-open">
        <slot name="body"></slot>
        <hr />
        <div class="footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'BaseExpandableCard',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click']
};
</script>

<style scoped lang="scss">
.expandable-card {
  width: 100%;

  .fade-leave-to,
  .fade-enter-from {
    transition: all 0.4s ease-in-out;
    max-height: 0;
    overflow: hidden;
  }

  .fade-leave-from,
  .fade-enter-to {
    transition: all 0.4s ease-in-out;
    max-height: 20rem;
    overflow: hidden;
  }

  .card-open {
    width: 100%;
    font-family: var(--font-family-tertiary);
    font-size: 1.125rem;
    position: relative;

    hr {
      border-bottom: 0 solid var(--primaryColorDarkShade1);
      margin: 5px 20px 5px;
    }

    .footer {
      margin: 0 20px;
    }
  }
}
</style>
