<template>
  <div class="devices-view">
    <DevicesLog class="devices-list" @button:open="onButtonOpen" @button:close="onButtonClose" />
    <MapCanvas v-if="zoneId" class="map" :indicators="mapIndicators" :is-quick-action-menu-enabled="true" />
  </div>
</template>

<script>
import DevicesLog from './DevicesLog.vue';
import MapCanvas from '../../components/theMap/MapCanvas.vue';
import { getDisplayConfigForMap } from '../../utils/models/DeviceUtils';
import { useContextStore } from '../../store/ContextStore';
import { useZonesStore } from '../../store/ZonesStore';
import { useDevicesStore } from '../../store/DevicesStore';
import { useAreasStore } from '../../store/AreasStore';
import { mapState } from 'pinia';

export default {
  name: 'DevicesView',
  components: {
    MapCanvas,
    DevicesLog
  },
  data() {
    return {
      focusedDevice: null
    };
  },
  computed: {
    ...mapState(useContextStore, ['zoneId', 'zone']),
    ...mapState(useZonesStore, ['getZoneById']),
    ...mapState(useDevicesStore, ['getDeviceById', 'contextZoneActiveDevices']),
    ...mapState(useAreasStore, ['activeNoFlightAreas']),
    zone() {
      return this.getZoneById(this.zoneId) || {};
    },
    mapIndicators() {
      return {
        icons: this.mapIconsFormatter(this.focusedDevice),
        areas: this.activeNoFlightAreas.map(area => ({
          ...area,
          isInBackground: true
        }))
      };
    }
  },
  methods: {
    onButtonOpen(deviceId) {
      this.focusedDevice = this.getDeviceById(deviceId);
    },
    onButtonClose() {
      this.focusedDevice = null;
    },
    mapIconsFormatter(selectedDevice) {
      const icons = this.contextZoneActiveDevices.reduce((iconsArray, device) => {
        if (device.location) {
          if (selectedDevice && device.id !== selectedDevice.id) {
            iconsArray.push(this.getDeviceIcon(device));
          } else if (selectedDevice) {
            iconsArray.push(
              getDisplayConfigForMap({
                device,
                showSelectionMark: true
              })
            );
          } else {
            // No selected device
            iconsArray.push(this.getDeviceIcon(device));
          }
        }
        return iconsArray;
      }, []);
      return icons;
    },
    getDeviceIcon(device) {
      return getDisplayConfigForMap({ device, clickable: true });
    }
  }
};
</script>

<style scoped lang="scss">
.devices-view {
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  .devices-list {
    flex-basis: 35rem;
    max-width: 40%;
  }

  .map {
    flex-grow: 1;
  }
}
</style>
