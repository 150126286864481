<template>
  <BaseModal :confirmation-option="false" @close="onCloseClick">
    <template #title> Detections Info </template>
    <template #body>
      <BaseLoader v-if="isLoading" class="detection-info-loader" />
      <BaseHorizontalObjectTable v-else-if="detectionsData?.length" :table-object="detectionsData" />
      <span v-else-if="errorMessage">{{ errorMessage }}</span>
      <span v-else>There is no detection data for this mission.</span>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '../../components/base/BaseModal.vue';
import BaseHorizontalObjectTable from '../../components/base/BaseHorizontalObjectTable.vue';
import BaseLoader from '../../components/base/BaseLoader.vue';
import missionsService from '../../services/api/missionsService';

export default {
  name: 'DetectionsInfoModal',
  components: {
    BaseModal,
    BaseHorizontalObjectTable,
    BaseLoader
  },
  props: {
    missionId: {
      type: String,
      required: true
    }
  },
  emits: ['close'],
  data() {
    return {
      isLoading: true,
      detectionsData: null,
      errorMessage: null
    };
  },
  async mounted() {
    try {
      this.detectionsData = await missionsService.fetchMissionObjectFrameDetectionsById(this.missionId);
    } catch (err) {
      this.errorMessage = 'Unable to retrieve detections log.';
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    onCloseClick() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped lang="scss">
.detection-info-loader {
  width: 5rem;
  height: 5rem;
  padding: 4rem 6rem;
  margin: auto;
}
</style>
