<template>
  <div class="device-details">
    <div class="header">
      <span class="text">{{ device.name }}</span>
      <span class="action-buttons">
        <DeviceActionsMenu :device="device" />
        <IconClose class="icon" @click="onCloseClick"><title>Close</title></IconClose>
      </span>
    </div>
    <div v-if="device.halted" class="halt-tag">Flights Halt</div>
    <div v-if="isIotController(device) && canInvokeIotDevice">
      <BaseOptionsSwitch
        v-model="switchState"
        :options="IOT_SWITCH_OPTIONS"
        :is-loading="triggeringIotIntegration"
        :disabled="triggeringIotIntegration"
        @change="onSwitchChange"
      >
      </BaseOptionsSwitch>
    </div>
    <div class="device-metadata-container">
      <template v-for="(item, index) in metadata" :key="`${index}-label`">
        <span> {{ item.label }}: </span>
        <router-link v-if="item.linkTo" :to="item.linkTo" class="value clickable">{{ item.value }}</router-link>
        <span v-else class="value" :style="item.color ? `color:${item.color}` : ''">
          {{ item.value }}
        </span>
      </template>
    </div>
    <div v-if="isVideoStreamingDevice(device)" class="device-video-container">
      <BaseLoader v-if="isFetchingStreams || videoPlayerStore.isFetchingConfig" class="loader" />
      <VideoSection
        v-else
        class="device-video"
        :single-cam-device="displayFrontCameraOnly"
        @stream-stop="onStreamStop"
        @is-streams-exist="onIsStreamsExist"
      />
    </div>
    <StartMissionButton
      v-else-if="isDockingStation(device) && !isPaired(device) && canInvokeCustomMission"
      class="start-mission-button"
      :point="device.location || {}"
      label="Send a Tando to dock here"
      :action-type="MAP_QUICK_ACTION_TYPES.SKIP_TILE"
      :metadata="deviceMetadata"
    />
    <BaseButton v-if="allowStartVideoStream" class="device-stream-button" @click="onStartVideoStreamClick">Start Video Streaming</BaseButton>
    <BaseButton v-if="allowStoppingVideoStream" class="device-stream-button" :disabled="stopStreamRequested" @click="onStopVideoStreamClick"
      >Stop Video Streaming</BaseButton
    >
    <BaseButton v-if="showRepositionDeviceButton" class="reposition-device-btn" @click="onToggleDeviceRepositioningModeClick"
      >{{ mapMode === MAP_MODE.REPOSITION ? 'Cancel Device Reposition' : 'Reposition Device' }}
    </BaseButton>
    <div v-if="mapMode === MAP_MODE.REPOSITION" class="reposition-attributes-container">
      <div class="reposition-attributes-description">You can click on the map or fill in hte attributes:</div>
      <div>
        <span>x: </span>
        <input v-model="repositionValues.x" class="position-input" type="number" />
      </div>
      <div>
        <span>y: </span>
        <input v-model="repositionValues.y" class="position-input" type="number" />
      </div>
      <div>
        <span>z: </span>
        <input v-model="repositionValues.z" class="position-input" type="number" />
      </div>
      <div>
        <span>h: </span>
        <input v-model="repositionValues.h" class="position-input" type="number" />
      </div>
      <BaseButton class="reposition-save-btn" @click="onSaveDeviceRepositioningClick">Save Parameters</BaseButton>
    </div>
    <StartMissionButton
      v-if="
        !isSkydioDevice &&
        isRobot(device) &&
        device.state === DEVICE_STATE.IDLE &&
        device.status === DEVICE_STATUS.DOCKED &&
        hasDevicesMaintenancePrivilege
      "
      class="start-mission-button"
      label="Detach Tando And Land"
      :action-type="MAP_QUICK_ACTION_TYPES.DROP_AND_LAND"
      :metadata="deviceMetadata"
    />
    <StartMissionButton
      v-else-if="isRobot(device) && device.status === DEVICE_STATUS.ON_GROUND && device.state === DEVICE_STATE.IDLE && hasDevicesMaintenancePrivilege"
      class="start-mission-button"
      label="Take off Tando and dock on a Tile"
      :action-type="MAP_QUICK_ACTION_TYPES.TAKEOFF_AND_DOCK"
      :metadata="deviceMetadata"
    />
    <div v-if="showDeviceIotConfig" class="iot-config-container">
      <div class="title-toggle" @click="expandConfigureIot = !expandConfigureIot">
        <span> Configure device IoT</span>
        <IconCollapse :class="['expand-icon', { expand: expandConfigureIot }]" />
      </div>
      <div v-if="expandConfigureIot" class="iot-config-form">
        <div class="iot-field">
          IoT Switch ON URL:
          <BaseTextInput v-model="iotIntegration.data.state['ON']" class="iot-input" />
        </div>
        <div class="iot-field">
          Iot Switch OFF URL:
          <BaseTextInput v-model="iotIntegration.data.state['OFF']" class="iot-input" />
        </div>
        <BaseLoader v-if="savingIotIntegration" />
        <BaseButton v-else class="iot-save-btn" @click="onSaveDeviceIotConfigClick">Save</BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import VideoSection from '../../components/thePlayer/VideoSection.vue';
import BaseLoader from '../../components/base/BaseLoader.vue';
import BaseButton from '../../components/base/BaseButton.vue';
import BaseOptionsSwitch from '../../components/base/BaseOptionsSwitch.vue';
import BaseTextInput from '../../components/base/BaseTextInput.vue';
import IconClose from '../../components/icons/IconClose.svg?component';
import IconCollapse from '../../components/icons/IconCollapse.svg?component';
import { clone } from '../../utils/ObjectUtils';
import StartMissionButton from './StartMissionButton.vue';
import { MAP_QUICK_ACTION_TYPES } from '../../consts/mapConsts';
import DeviceActionsMenu from './DeviceActionsMenu.vue';
import { DEVICE_STATE, DEVICE_STATUS, DEVICES_TYPES, DEVICE_PROPERTIES, COMMUNICATING_DEVICES } from '../../consts/deviceConsts';
import {
  getColorConnection,
  isRobot,
  getStrConnection,
  isIotController,
  isVideoStreamingDevice,
  isDockingStation,
  getPairedDevice,
  isPaired,
  getStrState,
  getColorState,
  getStrErrors,
  getStrCharging,
  getColorCharging,
  getStrBatteryLevel,
  getErrorsFriendlyDescriptions
} from '../../utils/models/DeviceUtils';
import { useDevicesStore } from '../../store/DevicesStore';
import { useContextStore } from '../../store/ContextStore';
import { useMapStore } from '../../store/MapStore';
import { mapState, mapActions, mapStores } from 'pinia';
import { MAP_MODE } from '../../consts/mapConsts';
import { SNACKBAR_TYPE } from '../../consts/appConsts';
import { PRIVILEGES } from '../../consts/authConsts';
import devicesService from '../../services/api/devicesService';
import { useVideoPlayerStore } from '../../store/VideoPlayerStore';
import { PAGES } from '../../router';

const AWAIT_STREAMS_INTERVAL_MS = 10000; // 10 seconds
const MAX_FETCH_STREAMS_ATTEMPTS = 6;
const IOT_SWITCH_OPTIONS = [
  {
    id: 'ON',
    label: 'ON'
  },
  {
    id: 'OFF',
    label: 'OFF'
  }
];

export default {
  name: 'DeviceDetails',
  components: {
    VideoSection,
    BaseLoader,
    StartMissionButton,
    BaseButton,
    BaseOptionsSwitch,
    DeviceActionsMenu,
    IconClose,
    IconCollapse,
    BaseTextInput
  },
  props: {
    deviceId: {
      required: true,
      type: Number
    }
  },
  emits: ['save-device-reposition-params'],
  data() {
    return {
      IOT_SWITCH_OPTIONS,
      DEVICE_STATE,
      DEVICE_STATUS,
      MAP_MODE,
      MAP_QUICK_ACTION_TYPES,
      isFetchingStreams: false,
      isStreamsStopped: false,
      repositionValues: {
        x: '0.0',
        y: '0.0',
        z: '0.0',
        h: '0.0'
      },
      expandConfigureIot: false,
      iotIntegration: {
        data: {
          state: {
            ON: '',
            OFF: ''
          }
        },
        device_id: null
      },
      savingIotIntegration: false,
      triggeringIotIntegration: false,
      switchState: 'OFF',
      stopStreamRequested: false,
      fetchStreamInterval: null,
      startStreamAttemptsCount: 0,
      allowSteamActions: false
    };
  },
  computed: {
    ...mapStores(useVideoPlayerStore),
    ...mapState(useDevicesStore, ['getDeviceById']),
    ...mapState(useContextStore, ['hasPrivilege', 'isTechnician']),
    ...mapState(useMapStore, ['repositionPoint', 'mapMode']),
    isSkydioDevice() {
      return this.device.type_id === DEVICES_TYPES.SKYDIO;
    },
    metadata() {
      let metadata = [
        {
          label: 'ID',
          value: this.device.id
        },
        {
          label: 'Serial Number',
          value: this.device.serial_number,
          condition: this.isTechnician
        },
        {
          label: 'Name',
          value: this.device.name
        },
        {
          label: 'Type',
          value: this.device.type_name
        }
      ];

      if (COMMUNICATING_DEVICES.includes(this.device.type_id) || this.device.type_id === DEVICES_TYPES.SKYDIO) {
        metadata.push(
          {
            label: 'Connection',
            value: getStrConnection(this.device),
            color: getColorConnection(this.device)
          },
          {
            label: 'Local IP',
            value: this.device.local_ip || 'N/A',
            condition: this.device.is_connected && this.isTechnician
          }
        );
      }

      if (isRobot(this.device)) {
        metadata.push(
          {
            label: 'Charging',
            value: getStrCharging(this.device),
            color: getColorCharging(this.device),
            condition: this.device.is_connected
          },
          {
            label: 'Battery Level',
            value: getStrBatteryLevel(this.device),
            condition: this.device.is_connected && this.device.battery_level
          },
          {
            label: 'Voltage',
            value: this.device.voltage,
            condition:
              !!this.device.voltage && !this.isSkydioDevice && this.hasPrivilege(PRIVILEGES.VIEW_DEVICE_DEBUG_DETAILS) && this.device.is_connected
          },
          {
            label: 'Height',
            value: this.device.location?.z,
            condition: !!this.device.location && this.device.location.hasOwnProperty('z') && this.hasPrivilege(PRIVILEGES.VIEW_DEVICE_DEBUG_DETAILS)
          },
          {
            label: 'State',
            value: getStrState(this.device),
            color: getColorState(this.device),
            condition: this.device.is_connected
          },
          {
            label: 'Errors',
            value: getErrorsFriendlyDescriptions(this.device),
            color: getColorState(this.device),
            condition: this.device.is_connected && this.device.errors.length > 0
          },
          {
            label: 'Detailed Errors',
            value: getStrErrors(this.device),
            color: getColorState(this.device),
            condition: this.hasPrivilege(PRIVILEGES.VIEW_ERROR_DESCRIPTION) && this.device.is_connected && this.device.errors.length > 0
          }
        );
      } else if (isDockingStation(this.device)) {
        const pairedDevice = getPairedDevice(this.device) || {};
        metadata.push(
          {
            label: 'Paired Device',
            value: pairedDevice.name,
            condition: isPaired(this.device),
            linkTo: {
              name: this.$route.name,
              params: {
                deviceId: pairedDevice.id
              }
            }
          },
          {
            label: 'State',
            value: getStrState(this.device),
            color: getColorState(this.device),
            condition: this.device.is_connected
          },
          {
            label: 'Errors',
            value: getStrErrors(this.device),
            color: getColorState(this.device),
            condition: this.device.is_connected && this.device.errors.length > 0
          }
        );
      }
      return metadata.filter(item => item.condition !== false);
    },
    deviceMetadata() {
      return {
        device: {
          id: this.device.id,
          name: this.device.name,
          physicalId: this.device.physical_id
        }
      };
    },
    displayFrontCameraOnly() {
      return (isDockingStation(this.device) && !this.device.streamsData?.rearCamStreamURL) || this.isSkydioDevice;
    },
    canInvokeCustomMission() {
      return this.hasPrivilege(PRIVILEGES.INVOKE_CUSTOM_MISSIONS);
    },
    hasDevicesMaintenancePrivilege() {
      return this.hasPrivilege(PRIVILEGES.DEVICES_MAINTENANCE);
    },
    canInvokeIotDevice() {
      return this.device.props.includes(DEVICE_PROPERTIES.ALARM) ? this.isTechnician : this.hasPrivilege(PRIVILEGES.INVOKE_IOT_DEVICE);
    },
    canStartVideoStream() {
      return this.hasPrivilege(PRIVILEGES.INVOKE_VIDEO_STREAM);
    },
    allowStartVideoStream() {
      return this.allowSteamActions && this.device.is_connected && !this.isFetchingStreams && (!this.deviceHasStreamsData || this.isStreamsStopped);
    },
    allowStoppingVideoStream() {
      return (
        this.allowSteamActions &&
        this.device.is_connected &&
        this.deviceHasStreamsData &&
        !this.isStreamsStopped &&
        !this.isFetchingStreams &&
        this.device.state === DEVICE_STATE.IDLE
      );
    },
    showRepositionDeviceButton() {
      return (this.device.is_connected || !isRobot(this.device)) && this.hasPrivilege(PRIVILEGES.REPOSITION_DEVICE);
    },
    showDeviceIotConfig() {
      return isIotController(this.device) && this.isTechnician;
    },
    deviceHasStreamsData() {
      return !!this.device.streamsData && Object.keys(this.device.streamsData).length;
    },
    device() {
      return this.getDeviceById(this.deviceId) || {};
    }
  },
  watch: {
    deviceId: {
      handler: function () {
        this.initDevice();
      }
    },
    'device.streamsData': {
      handler: function deviceStreamsWatch(newVal) {
        this.setDeviceVideo();
      },
      immediate: true
    }
  },
  mounted() {
    this.initDevice();
  },
  beforeUnmount() {
    if (this.mapMode === MAP_MODE.REPOSITION) {
      this.updateMapMode(MAP_MODE.DEFAULT);
    }
  },
  methods: {
    ...mapActions(useContextStore, ['showSnackbar']),
    ...mapActions(useMapStore, ['updateMapMode']),
    ...mapActions(useDevicesStore, ['fetchDeviceStreams', 'isDeviceStreamsExist', 'fetchDeviceIotIntegration', 'updateDeviceIotIntegration']),
    isIotController,
    isVideoStreamingDevice,
    isDockingStation,
    isRobot,
    isPaired,
    onToggleDeviceRepositioningModeClick() {
      this.updateMapMode(this.mapMode === MAP_MODE.REPOSITION ? MAP_MODE.DEFAULT : MAP_MODE.REPOSITION);
      let deviceLocation = { x: '0.0', y: '0.0', z: '0.0', h: '0.0' };
      if (this.mapMode === MAP_MODE.REPOSITION && this.device.location) {
        deviceLocation = {
          ...deviceLocation,
          ...clone(this.device.location)
        };
      }
      this.repositionValues = deviceLocation;
    },
    onSaveDeviceRepositioningClick() {
      this.$emit('save-device-reposition-params', this.repositionValues);
    },
    async initDevice() {
      if (this.device.is_connected && isVideoStreamingDevice(this.device)) {
        this.allowSteamActions = !this.isSkydioDevice && isVideoStreamingDevice(this.device) && this.canStartVideoStream;
        try {
          this.isFetchingStreams = true;
          await this.fetchDeviceStreams(this.device.id);
        } catch (err) {
          this.isStreamsStopped = true;
        } finally {
          this.isFetchingStreams = false;
        }
      }

      if (isIotController(this.device)) {
        this.switchState = this.device.state;
        this.iotIntegration.device_id = this.device.id;
        await this.fetchDeviceIotIntegration(this.iotIntegration.device_id);
        if (this.device?.iotIntegration) {
          this.iotIntegration = this.device.iotIntegration;
        }
      }
    },
    setDeviceVideo() {
      this.videoPlayerStore.setPlayerConfig({
        streams: this.device.streamsData,
        device_type_id: this.device.type_id,
        isLive: true,
        noVideoError: 'Device is not streaming'
      });
    },
    async onStartVideoStreamClick() {
      try {
        this.isFetchingStreams = true;
        await devicesService.invokeDeviceVideoStreaming({ deviceId: this.device.id });
        this.startStreamAttemptsCount = 0;
        this.fetchStreamInterval = setInterval(async () => {
          if (!this.deviceHasStreamsData) {
            this.isStreamsStopped = false;
            await this.fetchDeviceStreams(this.device.id);
          }
          this.startStreamAttemptsCount++;

          if (this.deviceHasStreamsData || this.startStreamAttemptsCount === MAX_FETCH_STREAMS_ATTEMPTS) {
            clearInterval(this.fetchStreamInterval);
            this.fetchStreamInterval = null;
            this.isFetchingStreams = false;
          }
        }, AWAIT_STREAMS_INTERVAL_MS);
      } catch (e) {
        this.showSnackbar({
          message: 'Unable to start stream',
          type: SNACKBAR_TYPE.ERROR
        });
      }
    },
    async onStopVideoStreamClick() {
      try {
        this.stopStreamButtonText = 'Stopping Video stream...';
        this.stopStreamRequested = true;
        await devicesService.invokeDeviceVideoStreaming({
          deviceId: this.device.id,
          stop: true
        });
      } catch (e) {
        this.showSnackbar({
          message: 'Unable to stop stream',
          type: SNACKBAR_TYPE.ERROR
        });
        this.stopStreamRequested = false;
      }
    },
    onStreamStop() {
      this.isStreamsStopped = true;
      if (this.stopStreamRequested) {
        this.stopStreamRequested = false;
        this.stopStreamButtonText = 'Stop Video Streaming';
      }
    },
    async onIsStreamsExist() {
      try {
        const res = await this.isDeviceStreamsExist(this.device.id);

        if (!res) {
          this.isStreamsStopped = true;
          this.stopStreamRequested = false;
        }
      } catch (err) {
        this.isStreamsStopped = true;
        this.stopStreamRequested = false;
      }
    },
    onCloseClick() {
      this.$router.push({
        name: PAGES.DEVICES
      });
    },
    async onSaveDeviceIotConfigClick() {
      this.savingIotIntegration = true;
      try {
        await this.updateDeviceIotIntegration(this.iotIntegration);
        this.showSnackbar({
          message: 'Iot integration config was saved successfully',
          type: SNACKBAR_TYPE.SUCCESS
        });
      } catch (e) {
        this.showSnackbar({
          message: 'Unable to save Iot integration config',
          type: SNACKBAR_TYPE.ERROR
        });
      }
      this.savingIotIntegration = false;
      this.expandConfigureIot = false;
    },
    async onSwitchChange() {
      this.triggeringIotIntegration = true;
      try {
        await devicesService.triggerDeviceIotIntegration({
          device_id: this.device.id,
          deviceProp: 'state',
          targetValue: this.switchState,
          verifyState: this.device.props.includes('SHELLY_SWITCH')
        });
        this.showSnackbar({
          message: `Turned ${this.switchState} successfully`,
          type: SNACKBAR_TYPE.SUCCESS
        });
      } catch (e) {
        this.showSnackbar({
          message: `Unable to switch device ${this.switchState}`,
          type: SNACKBAR_TYPE.ERROR
        });
        this.switchState = this.device.state;
      }
      this.triggeringIotIntegration = false;
    },
    handleMetadataClick(item) {
      if (item.linkTo) {
        this.$router.push(item.linkTo);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.device-details {
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  .icon {
    width: 1rem;
    height: 1rem;
  }

  .loader {
    width: 2.5rem;
    height: 2.5rem;
    margin: auto;
  }

  .toggle-loader {
    height: 2rem;
    width: 2rem;
  }

  .header {
    font-family: var(--font-family-primary);
    color: var(--textColor);
    display: flex;

    .text {
      font-size: 2rem;
      display: inline-block;
      vertical-align: middle;
      flex-grow: 1;
    }

    .action-buttons {
      flex-shrink: 0;
      margin-right: 1rem;
      cursor: pointer;
      display: flex;
      column-gap: 12px;
      align-items: baseline;
    }
  }

  .halt-tag {
    color: var(--errorColor);
    padding: 0.2rem 0.5rem;
    font-size: 1.1rem;
    border-radius: 20px;
    background: var(--primaryColorDarkShade1);
    font-weight: 400;
    width: fit-content;
  }

  .device-metadata-container {
    font-size: 1.3rem;
    word-break: break-all;
    text-overflow: ellipsis;
    font-family: var(--font-family-secondary);
    color: var(--textColor);
    display: grid;
    grid-template-columns: auto auto;

    .value {
      font-weight: 300;
      color: var(--secondaryTextColor);

      &.clickable {
        cursor: pointer;
        pointer-events: auto;

        &:hover {
          text-decoration: underline;
          text-decoration-color: var(--highlightColor);
        }
      }
    }
  }

  .device-video-container {
    display: flex;
    font-family: var(--font-family-secondary);
    color: var(--textColor);
    margin-top: 25px;
    flex-basis: 20rem;

    .device-video {
      flex-grow: 1;
    }
  }

  .start-mission-button,
  .device-stream-button,
  .reposition-device-btn {
    width: max-content;
  }

  .reposition-attributes-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.5rem;
    row-gap: 0.5rem;
    font-family: var(--font-family-secondary);
    color: var(--textColor);
    font-size: 1.1rem;

    .reposition-attributes-description {
      flex-basis: 100%;
    }

    .position-input {
      border: none;
      color: inherit;
      max-width: 5rem;
      min-height: 1.5rem;
      padding-left: 5px;
      background-color: var(--primaryColorDarkShade1);
      padding: 8px;
      border-radius: 7px;

      &:focus-visible {
        outline: inset;
        outline-color: var(--secondaryOverlayShade2);
        outline-style: solid;
        outline-width: 2px;
      }

      &.invalid-input {
        color: var(--errorColor);
      }

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }

  .iot-config-container {
    margin: 0.5em 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 0.5em 1rem;
    font-family: var(--font-family-secondary);
    margin-right: 1rem;
    background: var(--primaryColor);
    color: var(--textColor);

    .title-toggle {
      font-size: 1.1rem;
      display: flex;
      column-gap: 0.7em;
      align-items: center;
      color: var(--highlightColor);

      .expand-icon {
        transform: rotate(-90deg);
        transition: transform 0.4s;
        width: 1rem;
        height: 1rem;
        color: var(--highlightColor);
        cursor: pointer;
      }

      .expand {
        transform: rotate(0deg);
      }
    }

    .iot-config-form {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      padding: 0.5em 1rem;

      .iot-field {
        display: flex;
        column-gap: 1rem;

        .iot-input {
          background: var(--mainBackground);
        }
      }

      .iot-save-btn {
        width: max-content;
      }
    }
  }
}
</style>
