import apiRequest from './apiRequest';

class DevicesService {
  async createDevice(deviceObj) {
    try {
      const response = await apiRequest({
        path: `/devices`,
        method: 'POST',
        data: deviceObj
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchDevices(params) {
    try {
      const response = await apiRequest({
        path: `/devices`,
        method: 'GET',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchDeviceTypes(params) {
    try {
      const response = await apiRequest({
        path: `/devices/types`,
        method: 'GET',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchDeviceById(deviceId) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async updateDevice(params) {
    try {
      const response = await apiRequest({
        path: `/devices/${params.id}`,
        method: 'PATCH',
        data: params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async repositionDevice(params, force) {
    try {
      const response = await apiRequest({
        path: `/devices/${params.id}/reposition`,
        method: 'POST',
        data: params,
        params: { force: force.force }
      });
      if (response.data.success) {
        return;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchDeviceStreams(deviceId) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}/streams`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async isDeviceStreamsExist(deviceId) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}/isDeviceStreamsExist`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async invokeDeviceRestart(deviceId) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}/restart`,
        method: 'POST'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async invokeDeviceShutdown(deviceId) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}/shutdown`,
        method: 'POST'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async invokeUploadLogs(deviceId) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}/uploadLogs`,
        method: 'POST'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async invokeDeviceResetErrors(deviceId) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}/reset-errors`,
        method: 'POST'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async invokeDeviceResetStates(deviceId) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}/reset-states`,
        method: 'POST'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async invokeDevicePair(params) {
    try {
      const response = await apiRequest({
        path: `/devices/${params.deviceId}/pair`,
        method: 'POST',
        data: params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async invokeDeviceUnpair(deviceId) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}/unpair`,
        method: 'POST'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async invokeDeviceVideoStreaming({ deviceId, stop }) {
    try {
      const action = stop ? 'stop' : 'start';
      const response = await apiRequest({
        path: `/devices/${deviceId}/${action}-stream`,
        method: 'POST'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchRobotVersions(params) {
    try {
      const response = await apiRequest({
        path: `/devices/versions/robot`,
        method: 'GET',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchDeviceServerVersions(params) {
    try {
      const response = await apiRequest({
        path: `/devices/versions/device-server`,
        method: 'GET',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchOpenmvVersions(params) {
    try {
      const response = await apiRequest({
        path: `/devices/versions/openmv`,
        method: 'GET',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchMapVersions(params) {
    try {
      const response = await apiRequest({
        path: `/devices/versions/maps`,
        method: 'GET',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchInstallerVersions(params) {
    try {
      const response = await apiRequest({
        path: `/devices/versions/installer`,
        method: 'GET',
        params
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async installDeviceVersion({ deviceId, payload }) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}/install`,
        method: 'POST',
        data: payload
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async updatePixracerParameters(deviceId) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}/update-pixracer`,
        method: 'POST'
      });
      if (response.data.success) {
        return response;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchDeviceIotIntegration(deviceId) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}/iot-integrations`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchDeviceActiveNodes(deviceId) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}/request-active-nodes`,
        method: 'POST'
      });
      if (response.data.success) {
        return response;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async killDeviceActiveNode({ deviceId, nodeName }) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}/kill-active-node`,
        method: 'POST',
        data: { nodeName }
      });
      if (response.data.success) {
        return response;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async fetchDeviceAvailableTilesToReset(deviceId) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}/request-available-tiles-to-reset`,
        method: 'POST'
      });
      if (response.data.success) {
        return response;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async resetTile({ deviceId, tilePhysicalId }) {
    try {
      const response = await apiRequest({
        path: `/devices/${deviceId}/reset-tile`,
        method: 'POST',
        data: { tilePhysicalId }
      });
      if (response.data.success) {
        return response;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async createDeviceIotIntegration(iotIntegration) {
    try {
      const response = await apiRequest({
        path: `/devices/${iotIntegration.device_id}/iot-integrations`,
        method: 'POST',
        data: iotIntegration
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async updateDeviceIotIntegration(iotIntegration) {
    try {
      const response = await apiRequest({
        path: `/devices/${iotIntegration.device_id}/iot-integrations`,
        method: 'PATCH',
        data: iotIntegration
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async triggerDeviceIotIntegration({ device_id, deviceProp, targetValue, verifyState }) {
    try {
      const response = await apiRequest({
        path: `/devices/${device_id}/iot-integrations/${deviceProp}/${targetValue}`,
        method: 'POST',
        data: { verifyState }
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async triggerNestCommand({ device_id, command }) {
    try {
      const response = await apiRequest({
        path: `/devices/nest/${device_id}/control`,
        method: 'POST',
        data: { command }
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new DevicesService();
