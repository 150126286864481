import apiRequest from './apiRequest';

class AuthService {
  async azureUserLogin(credentials) {
    try {
      const response = await apiRequest({
        path: `/auth/login/azureUser`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${credentials.accessToken}`
        },
        data: { asIndoorUser: credentials.asIndoorUser },
        ignoreActivity: true
      });
      if (response.data.success) {
        return { data: response.data.data, headers: response.headers };
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async userLogin(loginName, password, asIndoorUser) {
    try {
      const response = await apiRequest({
        path: `/auth/login/user`,
        method: 'POST',
        data: { loginName, password, asIndoorUser },
        ignoreActivity: true
      });
      if (response.data.success) {
        return { data: response.data.data, headers: response.headers };
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async accountLogin(userLoginToken, accountId) {
    try {
      const response = await apiRequest({
        path: `/auth/login/account`,
        method: 'POST',
        headers: {
          'user-login-token': userLoginToken
        },
        data: { accountId },
        ignoreActivity: true
      });
      if (response.data.success) {
        return { data: response.data.data, headers: response.headers };
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async fetchSession(accessToken) {
    try {
      const response = await apiRequest({
        path: `/auth/session`,
        method: 'GET',
        headers: {
          'Access-Token': accessToken
        }
      });
      if (response.data.success) {
        return { data: response.data.data, headers: response.headers };
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async forgotPassword(params) {
    try {
      const response = await apiRequest({
        path: `/auth/login/forgot-password`,
        method: 'POST',
        data: params,
        ignoreActivity: true
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async unsubscribe(params) {
    try {
      const token = params.token;
      delete params.token;
      const response = await apiRequest({
        path: `/unsubscribe`,
        method: 'POST',
        data: params,
        headers: {
          'Unsubscribe-Token': token
        },
        ignoreActivity: true
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async resetPassword(params) {
    try {
      const token = params.token;
      delete params.token;
      const response = await apiRequest({
        path: `/auth/login/reset-password`,
        method: 'POST',
        data: params,
        headers: {
          'Reset-Password-Token': token
        },
        ignoreActivity: true
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async verifyUserInvitationToken(params) {
    try {
      const token = params.token;
      delete params.token;
      const response = await apiRequest({
        path: `/auth/login/user-invitation-token`,
        method: 'POST',
        data: params,
        headers: {
          'User-Invitation-Token': token
        },
        ignoreActivity: true
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async verifyAzureUser(params) {
    try {
      const token = params.token;
      const azureToken = params.azureToken;
      delete params.token;
      const response = await apiRequest({
        path: `/auth/login/verify-azure-user`,
        method: 'POST',
        data: params,
        headers: {
          Authorization: `Bearer ${azureToken}`,
          'User-Invitation-Token': token
        },
        ignoreActivity: true
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async googleUserLogin(credentials) {
    try {
      const response = await apiRequest({
        path: `/auth/login/googleUser`,
        method: 'POST',
        data: credentials,
        ignoreActivity: true
      });
      if (response.data.success) {
        return { data: response.data.data, headers: response.headers };
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async verifyGoogleUser(data) {
    try {
      const token = data.token;
      const googleCredentials = data.credentials;
      delete data.token;
      const response = await apiRequest({
        path: `/auth/login/verify-google-user`,
        method: 'POST',
        data: googleCredentials,
        headers: {
          'User-Invitation-Token': token
        },
        ignoreActivity: true
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async refreshTokenWithGoogleAuth() {
    try {
      const response = await apiRequest({
        path: `/auth/google-token-refresh`,
        method: 'POST',
        headers: {},
        ignoreActivity: true
      });
      if (response.data.success) {
        return { headers: response.headers };
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async verifyUser(params) {
    try {
      const token = params.token;
      delete params.token;
      const response = await apiRequest({
        path: `/auth/login/verify-user`,
        method: 'POST',
        data: params,
        headers: {
          'User-Invitation-Token': token
        },
        ignoreActivity: true
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async refreshToken() {
    try {
      const response = await apiRequest({
        path: `/auth/token-refresh`,
        method: 'POST',
        ignoreActivity: true
      });
      if (response.data.success) {
        return { headers: response.headers };
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async refreshTokenWithAzureAuth(azureAccessToken) {
    try {
      const response = await apiRequest({
        path: `/auth/azure-token-refresh`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${azureAccessToken}`
        },
        ignoreActivity: true
      });
      if (response.data.success) {
        return { headers: response.headers };
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async createAccount(userLoginToken, params) {
    try {
      const response = await apiRequest({
        path: `/auth/create-account`,
        method: 'POST',
        headers: {
          'user-login-token': userLoginToken
        },
        data: params,
        ignoreActivity: true
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async ping() {
    try {
      await apiRequest({
        path: `/auth/ping`,
        method: 'GET',
        ignoreActivity: true
      });
    } catch (err) {
      console.error(err);
    }
    return;
  }
}

export default new AuthService();
