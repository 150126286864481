class GoogleConnector {
  constructor() {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onerror = 'failed to load google API';
    script.onload = () => {
      this.googleClient = google.accounts.oauth2.initCodeClient({
        client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
        hd: '*',
        scope: 'email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid',
        redirect_uri: import.meta.env.VITE_SSO_REDIRECT_URI || 'http://127.0.0.1:4000'
      });
    };
    document.body.appendChild(script);
  }

  async authenticateUsingGoogle(callback, errorCallback) {
    try {
      if (!this.googleClient) {
        console.error('Google failed to initialize');
        return;
      }
      this.googleClient.callback = callback;
      this.googleClient.error_callback = errorCallback;
      this.googleClient.requestCode();
    } catch (e) {
      console.warn(e);
    }
  }
}

export default new GoogleConnector();
