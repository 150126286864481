export const DEVICE_STATE = {
  ON: 'ON',
  OFF: 'OFF',
  BOOT: 'BOOT',
  IDLE: 'IDLE',
  IN_MISSION: 'IN_MISSION',
  BLOCKING: 'BLOCKING',
  ERROR: 'ERROR',
  CRITICAL_ERROR: 'CRITICAL_ERROR',
  DOWN: 'DOWN',
  UPGRADE: 'UPGRADE',
  OPEN: 'OPEN',
  CLOSE: 'CLOSE'
};

export const DEVICE_STATE_NAMES = {
  ON: 'On',
  OFF: 'Off',
  BOOT: 'Boot',
  IDLE: 'Idle',
  IN_MISSION: 'In Mission',
  BLOCKING: 'Blocking',
  ERROR: 'Error',
  CRITICAL_ERROR: 'Critical',
  DOWN: 'Down',
  UPGRADE: 'Upgrade',
  OPEN: 'Open',
  CLOSE: 'Close'
};

export const DEVICE_STATUS = {
  ON_GROUND: 'ON_GROUND',
  DOCKED: 'DOCKED',
  AIRBORNE: 'AIRBORNE',
  LANDING: 'LANDING',
  DOCKING: 'DOCKING',
  DOCKING_FINAL: 'DOCKING_FINAL',
  DETACHING: 'DETACHING',
  DETACHING_FINAL: 'DETACHING_FINAL',
  TAKEOFF: 'TAKEOFF',
  UNKNOWN: 'UNKNOWN',
  PAIRED: 'PAIRED',
  NOT_PAIRED: 'NOT_PAIRED'
};

export const DEVICE_PROPERTIES = {
  VIDEO_STREAM: 'VIDEO_STREAM',
  MOTION_SENSOR: 'MOTION_SENSOR',
  THERMAL_SENSOR: 'THERMAL_SENSOR',
  DOCKING_STATION: 'DOCKING_STATION',
  CHARGER: 'CHARGER',
  ON_AIR: 'ON_AIR',
  ON_GROUND: 'ON_GROUND',
  AUTONOMOUS_NAVIGATION: 'AUTONOMOUS_NAVIGATION',
  STATIC: 'STATIC',
  CHARGEABLE: 'CHARGEABLE',
  ELECTRICITY_CONTROL: 'ELECTRICITY_CONTROL',
  LIGHT_CONTROL: 'LIGHT_CONTROL',
  IOT_CONTROLLER: 'IOT_CONTROLLER',
  IOT_SENSOR: 'IOT_SENSOR',
  SHELLY_SWITCH: 'SHELLY_SWITCH',
  IFTTT: 'IFTTT',
  ALARM: 'ALARM'
};

export const DEVICES_TYPES = {
  TANDO: 1,
  TANDO_TILE: 2,
  TANDO_TILE_NO_CHARGER: 3,
  OSTRICH: 4,
  OSTRICH_TILE: 5,
  GECKO: 6,
  OUTLET: 7,
  LIGHT: 8,
  SERVICE: 9,
  SKYDIO: 10,
  SKYDIO_TILE: 11,
  NEST: 12
};

export const SHELLY_SUPPORTED_MODELS_OPTIONS = [{ id: 'SHELLY_PLUS_1', label: 'Shelly Plus 1' }];

import IconDrone from '../components/icons/IconDrone.svg?component';
import IconTile from '../components/icons/IconTile.svg?component';
import IconLight from '../components/icons/IconLight.svg?component';
import IconOutlet from '../components/icons/IconOutlet.svg?component';
import IconDockedDrone from '../components/icons/IconDockedDrone.svg?component';

export const DEVICE_ICON = {
  [DEVICES_TYPES.TANDO]: IconDrone,
  [DEVICES_TYPES.SKYDIO]: IconDrone,
  [DEVICES_TYPES.TANDO_TILE]: IconTile,
  [DEVICES_TYPES.SKYDIO_TILE]: IconTile,
  [DEVICES_TYPES.NEST]: IconTile,
  [DEVICES_TYPES.LIGHT]: IconLight,
  [DEVICES_TYPES.OUTLET]: IconOutlet,
  [DEVICES_TYPES.SERVICE]: IconOutlet,
  DOCKED_DRONE: IconDockedDrone
};

export const COMMUNICATING_DEVICES = [DEVICES_TYPES.TANDO, DEVICES_TYPES.OSTRICH, DEVICES_TYPES.NEST];

export const SKYDIO_PAIR_TYPES = [DEVICES_TYPES.SKYDIO_TILE, DEVICES_TYPES.NEST];

export const NEST_DEVICE_OPERATIONS = {
  OPEN: 'Open Nest',
  CLOSE: 'Close Nest',
  OPEN_COVER: 'Open Cover',
  LOWER_RAMP: 'Lower Ramp',
  RESET_UPBOARD: 'Reset Upboard',
  RESET_CONTAINER: 'Reset Container',
  RESET_SYSTEM: 'Reset System'
};
