import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "3 -3 21 21",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", null, [
      _createElementVNode("path", {
        fill: "currentColor",
        d: "m 9 5 c 4 -7 5 -7 9 0 l 3 5 q 3 5 -2 5 l -11 0 q -5 0 -2 -5 l 3 -5 m 3 1 l 1 3 c 0 1 1 1 1 0 l 1 -3 c 0 -2 -3 -2 -3 0 m 1 5 a 0.5 0.5 0 0 0 1 1 a 0.5 0.5 0 0 0 -1 -1 m -1 -9 l -5 8 q -2 4 1 4 l 11 0 q 3 0 1 -4 l -5 -8 q -1.5 -2 -3 0"
      })
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }