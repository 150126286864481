import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "-1 -1 22 22",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M10 0c5.514 0 10 4.486 10 10 0 2.398-.848 4.6-2.26 6.325l.038.039-1.414 1.414-.039-.038A9.954 9.954 0 0 1 10 20C4.486 20 0 15.514 0 10c0-2.398.848-4.6 2.26-6.325l-.038-.039 1.414-1.414.039.038A9.954 9.954 0 0 1 10 0zM2 10c0 4.411 3.589 8 8 8a7.96 7.96 0 0 0 4.902-1.682L3.682 5.098A7.96 7.96 0 0 0 2 10zm8-8a7.96 7.96 0 0 0-4.902 1.682l11.22 11.22A7.96 7.96 0 0 0 18 10c0-4.411-3.589-8-8-8z" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }