import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 64 64",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createStaticVNode("<g><path transform=\"rotate(-45 50.1276741027832,13.87233066558837) \" d=\"m48.34548,23.97067l6.21598,-6.23721l-12.0183,0l-12.01827,0l0,-3.86113l0,-3.86113l11.8804,0c6.53421,0 11.88039,-0.1957 11.88039,-0.43489c0,-0.23919 -2.60125,-3.04593 -5.78054,-6.23721l-5.78056,-5.80232l5.34301,0l5.34299,0l8.15995,8.1756l8.15995,8.1756l-8.1756,8.15995l-8.1756,8.15995l-5.62489,0l-5.6249,0l6.216,-6.23721l0,0z\"></path><rect height=\"7.5\" width=\"23.87496\" y=\"0\" x=\"0\"></rect><rect height=\"24\" width=\"8.37499\" y=\"40\" x=\"55.62502\"></rect><rect height=\"7.5\" width=\"63.87489\" y=\"56.5\" x=\"0\"></rect><rect height=\"63.87494\" width=\"8.37499\" y=\"0\" x=\"0\"></rect></g>", 1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }