import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  role: "icon",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("g", { transform: "translate(-404 -2194) translate(40 1620) translate(364 574)" }, [
      _createElementVNode("circle", {
        cx: "12",
        cy: "12",
        r: "12",
        fill: "#358AF2"
      }),
      _createElementVNode("g", {
        fill: "#FFF",
        "fill-rule": "nonzero"
      }, [
        _createElementVNode("path", {
          d: "M13.717 9.024c.142.095.274.193.395.295.405.34.888.889.888 1.571 0 .4-.163.795-.485 1.174-.337.398-.828.759-1.459 1.073-1.343.67-3.14 1.04-5.056 1.04-1.917 0-3.713-.37-5.056-1.04-.63-.314-1.122-.675-1.459-1.073C1.163 11.684 1 11.29 1 10.89c0-.682.483-1.23.888-1.571.121-.102.253-.2.395-.295-.17.301-.258.62-.258.946 0 .904.659 1.731 1.856 2.328 1.107.553 2.57.857 4.119.857s3.012-.304 4.119-.857c1.197-.597 1.856-1.424 1.856-2.328 0-.327-.088-.645-.258-.946zm-1.806-.568c.797.432 1.244.97 1.244 1.514 0 .57-.511 1.152-1.403 1.597-.995.496-2.328.77-3.752.77s-2.757-.274-3.752-.77c-.892-.445-1.403-1.027-1.403-1.597 0-.544.447-1.082 1.244-1.514.525 1.655 2.08 2.858 3.911 2.858s3.386-1.203 3.911-2.858zM8 1l2.715 4.38.065.105.008.014c.323.517.493 1.114.493 1.725 0 1.804-1.472 3.272-3.281 3.272-1.81 0-3.281-1.468-3.281-3.272 0-.612.17-1.208.493-1.726z",
          transform: "translate(4 4)"
        })
      ])
    ], -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }