import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "1 1 26 26",
  role: "icon",
  fill: "currentColor",
  stroke: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M4.5 4A2.497 2.497 0 0 0 2 6.5v15C2 22.883 3.117 24 4.5 24h20c1.383 0 2.5-1.117 2.5-2.5v-15C27 5.117 25.883 4 24.5 4h-20zm0 2.5h20v15h-20v-15zM20.875 9L19 10.875l1.875 1.875 1.875-1.875L20.875 9zM7 10v2.5h9V10H7zm13.875 5L19 16.875l1.875 1.875 1.875-1.875L20.875 15zM7 16v2.5h9V16H7z" }, null, -1)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
export default { render: render }