<template>
  <BaseModal
    :confirmation-option="{ text: 'Invoke', disabled: !selectedOptionId }"
    cancel-option
    class="debug-mission-modal"
    @close="onResolve"
    @confirm="onResolve(true)"
  >
    <template #title>Invoke Debug Mission</template>
    <template #body>
      <div class="dialog-body">
        <div class="text">Which mission would you like {{ deviceName }} to execute?</div>
        <BaseDropdown v-model="selectedOptionId" :options="DEBUG_MISSION_OPTIONS" />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '../../components/base/BaseModal.vue';
import BaseDropdown from '../../components/base/BaseDropdown.vue';
import { mapActions, mapStores } from 'pinia';
import { useContextStore } from '../../store/ContextStore';
import { SNACKBAR_TYPE } from '../../consts/appConsts';
import { useEventListStore } from '../../store/EventListStore';

const DEBUG_MISSION_OPTIONS = [
  { id: 'TAKEOFF', label: 'Takeoff' },
  { id: 'LAND', label: 'Land' }
];

export default {
  name: 'DebugMissionModal',
  components: {
    BaseModal,
    BaseDropdown
  },
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceName: {
      type: String,
      required: true
    }
  },
  emits: ['close'],
  data() {
    return {
      DEBUG_MISSION_OPTIONS,
      selectedOptionId: DEBUG_MISSION_OPTIONS[0].id
    };
  },
  computed: {
    ...mapStores(useContextStore),
    options() {
      return this.contextZoneActiveTiles.filter(tile => tile.physical_id).map(tile => ({ id: tile.physical_id, label: tile.name }));
    }
  },
  methods: {
    ...mapActions(useEventListStore, ['invokeMission']),
    async onResolve(isConfirmed = false) {
      const zoneId = this.$route.params.zoneId;
      if (isConfirmed) {
        try {
          const missionObj = {
            zone_id: zoneId,
            type: this.selectedOptionId,
            device_id: this.deviceId,
            requested_pois: []
          };
          await this.invokeMission(missionObj);
          this.contextStore.showSnackbar({
            message: 'The mission was requested successfully',
            type: SNACKBAR_TYPE.SUCCESS
          });
        } catch (e) {
          console.error(e);
          this.contextStore.showSnackbar({
            message: 'Failed to start the mission',
            type: SNACKBAR_TYPE.ERROR
          });
        }
      }

      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.debug-mission-modal {
  .dialog-body {
    padding: 0 2rem;
    line-height: 2rem;
    overflow: auto;
    max-height: 75vh;
    margin: 1rem 0;
  }

  .text {
    max-width: 30rem;
    padding: 0 0 0.5rem 0;
  }
}
</style>
