import apiRequest from './apiRequest';

class IntegrationsService {
  async fetchIntegrations() {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/integrations`,
        method: 'GET'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async createIntegration(integrationDetails) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/integrations`,
        method: 'POST',
        data: integrationDetails
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async patchIntegration(integrationDetails) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/integrations/${integrationDetails.id}`,
        method: 'PATCH',
        data: integrationDetails
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async deleteIntegration(integrationId) {
    try {
      const response = await apiRequest({
        path: `/account/{accountId}/integrations/${integrationId}`,
        method: 'DELETE'
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new IntegrationsService();
