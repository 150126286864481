<template>
  <BaseBoundaryEnforcer :position="position" class="note-landmark-input-popup">
    <BaseButton class="small-btn close-button" @click="close">X</BaseButton>
    <BaseTextInput v-model="text" @keydown.enter.stop="submitValue" />
    <BaseButton class="small-btn" @click="submitValue">></BaseButton>
  </BaseBoundaryEnforcer>
</template>

<script>
import BaseBoundaryEnforcer from '../base/BaseBoundaryEnforcer.vue';
import BaseButton from '../base/BaseButton.vue';
import BaseTextInput from '../base/BaseTextInput.vue';
export default {
  name: 'NoteLandmarkInput',
  components: {
    BaseBoundaryEnforcer,
    BaseButton,
    BaseTextInput
  },
  props: {
    position: {
      type: Object,
      required: true
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  emits: ['close', 'update:modelValue'],
  data() {
    return { text: '' };
  },
  watch: {
    modelValue: {
      handler(newVal) {
        if (newVal !== this.text) this.text = newVal;
      },
      immediate: true
    }
  },
  methods: {
    submitValue() {
      this.$emit('update:modelValue', this.text);
      this.text = '';
    },
    close() {
      this.$emit('close');
      this.text = '';
    }
  }
};
</script>

<style scoped lang="scss">
.note-landmark-input-popup {
  background: var(--primaryColorBrightShade1);
  border-radius: 5px;
  padding: 0.3rem;
  color: var(--textColor);
  font-weight: bold;
  display: flex;
  column-gap: 0.3rem;
  font-size: 1.2rem;

  .small-btn {
    font-weight: bold;
    padding: 0 15px;

    &.close-button {
      background: var(--primaryColorBrightShade2);
      color: var(--primaryColorDarkShade1);
    }
  }
}
</style>
