<template>
  <label class="toggle">
    <input :checked="modelValue" type="checkbox" :disabled="disabled" @change="$emit('update:modelValue', $event.target.checked)" />
    <span :class="['slider', 'round', { disabled: disabled }]"></span>
  </label>
</template>

<script>
export default {
  name: 'BaseToggle',
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue']
};
</script>

<style scoped lang="scss">
.toggle {
  position: relative;
  display: inline-block;
  width: 2.7rem;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    width: 2.7rem;
    height: 1.4rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--secondaryOverlayShade1);
    transition: 0.4s;
    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    &:before {
      position: absolute;
      content: '';
      height: 1.2rem;
      width: 1.2rem;
      left: 0.2rem;
      bottom: 4px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      transition: 0.4s;
      box-shadow: 0 0px 15px #2020203d;
      background: var(--secondaryTextColor);
    }
  }
  input[type='checkbox']:checked + .slider:before {
    transform: translateX(1.1rem);
    background: var(--highlightColor);
  }
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
