import { useContextStore } from '../store/ContextStore';
import { mapStores } from 'pinia';
import { SNACKBAR_TYPE } from '../consts/appConsts';

export default {
  computed: {
    ...mapStores(useContextStore)
  },
  methods: {
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.contextStore.showSnackbar({
          message: 'Text copied to clipboard successfully',
          type: SNACKBAR_TYPE.SUCCESS
        });
      } catch (err) {
        console.error(err);
        this.contextStore.showSnackbar({
          message: 'Error while copied text to clipboard',
          type: SNACKBAR_TYPE.ERROR
        });
      }
    }
  }
};
