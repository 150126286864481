<template>
  <div class="mission-card" :class="{ selected }" @click="handleCardClick">
    <component :is="MISSION_STATE_ICONS[mission.state]" class="mission-icon" :style="{ background: STATE_ICON_COLOR_MAP[mission.state] }" />
    <div class="title">
      {{ mission.name + ' ' + MISSION_STATE_NAME[mission.state] }}
    </div>
    <div class="actions">
      <BaseLoader v-if="isLoading" class="loading" />
      <BaseSignButton v-else class="action" @click.stop="onResolvedIconClick">
        {{ handledText }}
        <template #sign>
          <span :class="[mission.handled ? 'unresolve' : 'resolve']">{{ handledIcon }}</span>
        </template>
      </BaseSignButton>
      <BaseSignButton class="action" @click.stop="handleMoreClick">
        More Info
        <template #sign>
          <IconCollapse class="arrow-icon" />
        </template>
      </BaseSignButton>
    </div>
    <div v-if="mission.source" class="source-info">
      {{ MISSION_SOURCE_LABELS[mission.source] }}
    </div>
    <div class="device-info">
      {{ deviceLabel(mission) }}
    </div>
    <div class="time">
      {{ getDateTime(new Date(mission.start_timestamp || mission.requested_timestamp)) }}
    </div>
  </div>
</template>

<script>
import { getDateTime } from '../../utils/DateUtils';
import { deviceLabel } from '../../utils/models/MissionUtils';
import BaseSignButton from '../../components/base/BaseSignButton.vue';
import BaseLoader from '../../components/base/BaseLoader.vue';
import { MISSION_STATE_ICONS, STATE_ICON_COLOR_MAP, MISSION_STATE_NAME, MISSION_SOURCE_LABELS } from '../../consts/missionConsts';
import { SNACKBAR_TYPE } from '../../consts/appConsts';
import { useContextStore } from '../../store/ContextStore';
import { useEventListStore } from '../../store/EventListStore';
import { mapActions } from 'pinia';
import IconCollapse from '../../components/icons/IconCollapse.svg?component';
import { shallowRef } from 'vue';
import { PAGES } from '../../router';

export default {
  name: 'MissionCard',
  components: {
    BaseSignButton,
    BaseLoader,
    IconCollapse
  },
  props: {
    mission: {
      type: Object,
      required: true
    },
    selected: Boolean
  },
  emits: ['select'],
  data() {
    return {
      isLoading: false,
      MISSION_STATE_ICONS: shallowRef(MISSION_STATE_ICONS),
      STATE_ICON_COLOR_MAP,
      MISSION_STATE_NAME,
      MISSION_SOURCE_LABELS
    };
  },
  computed: {
    handledText() {
      return this.mission.handled ? 'Unresolve' : 'Resolve';
    },
    handledIcon() {
      return this.mission.handled ? 'X' : '✓';
    }
  },
  methods: {
    getDateTime,
    deviceLabel,
    ...mapActions(useContextStore, ['showSnackbar']),
    ...mapActions(useEventListStore, ['updateMissionHandled']),
    async onResolvedIconClick() {
      try {
        const newValue = !this.mission.handled;
        this.isLoading = true;
        await this.updateMissionHandled({
          id: this.mission.id,
          handled: newValue
        });
      } catch (e) {
        this.showSnackbar({
          message: 'Failed to resolve mission',
          type: SNACKBAR_TYPE.ERROR
        });
        console.error(e);
      }
      this.isLoading = false;
    },
    handleCardClick() {
      this.$emit('select');
    },
    handleMoreClick() {
      this.$router.push({
        name: PAGES.MISSION,
        params: {
          missionId: this.mission.id
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.mission-card {
  border-bottom: 1px solid var(--primaryColorBrightShade1);
  background: var(--primaryColor);
  color: var(--textColor);
  display: grid;
  grid-template-areas:
    'mission-icon title title actions'
    'mission-icon source source .'
    '. . . .'
    'device device . time';
  grid-template-rows: auto auto 0.4fr 1fr;
  grid-template-columns: 3rem auto auto 14rem;
  padding: 1rem;
  row-gap: 0.3rem;
  align-items: center;
  transition: 0.3s;
  column-gap: 0.5rem;

  &.selected,
  &:hover {
    background: var(--primaryColorDarkShade1);
  }

  .mission-icon {
    grid-area: mission-icon;
    padding: 0.5rem;
    border-radius: 50%;
  }

  .title {
    grid-area: title;
    font-size: 1.3rem;
    text-transform: capitalize;
    word-break: break-word;
  }

  .loading {
    top: 5rem;
    margin: auto;
  }

  .actions {
    cursor: pointer;
    grid-area: actions;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
    transition: 0.3s;

    .action {
      font-size: 0.9rem;
      flex-grow: 1;

      .arrow-icon {
        height: 1rem;
        width: 1rem;
        transform: rotate(-90deg);
      }

      .resolve {
        font-size: 1.5rem;
        font-weight: bold;
        color: var(--successColor);
      }

      .unresolve {
        font-size: 1.5rem;
        color: var(--errorColor);
      }
    }
  }

  .source-info {
    grid-area: source;
    color: var(--secondaryTextColor);
  }

  .device-info {
    color: var(--highlightColor);
    grid-area: device;
  }

  .time {
    grid-area: time;
    text-align: right;
  }
}
</style>
