import { capitalize } from '../StringUtils';
import { MISSION_STATE, MISSION_SOURCE, STATE_COLOR_MAP, MISSION_STATE_LABELS, MISSION_SOURCE_LABELS } from '../../consts/missionConsts';
import { getEuclideanDistance } from '../PointsUtils';
import { getErrorMessage } from '../ErrorsUtils';
import { getStyleVar } from '../StyleUtils';
import { getTimeDifferenceFormattedString } from '../DateUtils';
import { ERROR_MESSAGES } from '../../consts/appConsts';

const MIN_DISTANCE_BETWEEN_POINTS = 1; // 1 Meter
const ON_POINT_MIN_DISTANCE = 0.3; // 30 Centimeters

export function deviceLabel(mission) {
  return mission.device_name || mission.device_id;
}
export function hasStarted(mission) {
  return !!mission?.start_timestamp;
}
export function isUnknown(mission) {
  return mission?.state === MISSION_STATE.UNKNOWN;
}
export function hasMissionEnded(mission) {
  return !!mission?.end_timestamp || [MISSION_STATE.FINISHED, MISSION_STATE.STOPPED, MISSION_STATE.FAILED].includes(mission?.state);
}
export function isActive(mission) {
  return [MISSION_STATE.RUNNING, MISSION_STATE.REQUESTED, MISSION_STATE.PENDING, MISSION_STATE.PREPARING].includes(mission?.state);
}
export function getExecutionStatusLabel(mission) {
  if ([MISSION_STATE.REQUESTED, MISSION_STATE.PENDING].includes(mission?.state)) {
    return 'starting...';
  } else if (mission?.state === MISSION_STATE.RUNNING) {
    return 'running now';
  }
}
export function hasObjectDetection(mission) {
  return !!mission.streamsData?.data?.objectDetectionConfig;
}
export function getMissionStateLabel(mission) {
  return MISSION_STATE_LABELS[mission?.state] || capitalize(mission?.state?.toLowerCase?.());
}
export function getStrDuration(mission) {
  return !mission?.end_timestamp
    ? 'Mission is still performing'
    : !mission?.start_timestamp
    ? 0
    : getTimeDifferenceFormattedString(new Date(mission.end_timestamp), new Date(mission.start_timestamp));
}
export function getTriggeredByLabel(mission) {
  let triggeredByStr = MISSION_SOURCE_LABELS[mission.source];

  if (mission.source === MISSION_SOURCE.MANUAL && mission.originator_name) {
    triggeredByStr += ` (${mission.originator_name})`;
  }
  return triggeredByStr;
}
export function getColorState(mission) {
  return getStyleVar(STATE_COLOR_MAP[mission?.state]);
}
export function getLocationDisplayConfigForMap(mission, isTechnician, currentFrame, skipVideoFunction) {
  if (mission) {
    const pois = getMissionTemplatePois(mission).map((poi, poiIndex) => {
      const point = {
        ...poi,
        id: `${mission.id}-${poiIndex}`,
        missionId: mission.id,
        configBadge: isTechnician && !!mission.poi_detection_settings?.[poiIndex]
      };

      if (isPoiAchieved(mission, poiIndex, !isActive(mission) && currentFrame)) {
        point.style = {
          strokeColor: getStyleVar('--successColor')
        };
      }

      if (!isActive(mission) && isPoiAchieved(mission, poiIndex)) {
        point.cardItem = {
          ...mission,
          poiIndex,
          timestamp: getPoiAchievementTime(mission, poiIndex),
          skipVideoFunction
        };
      }

      return point;
    });

    return pois;
  }
  return [];
}
export function getClosestLocationByTimestamp(missionPath, timestamp) {
  let location = { x: undefined, y: undefined, h: undefined };
  const path = missionPath ?? [];
  if (path && path.length > 0) {
    location = findClosestPointByTime(path, timestamp, Math.floor(path.length / 2), 0, path.length - 1);
  }

  return { ...location.data, index: location.index };
}
export function findClosestPointByTime(path, timestamp, index, scanStartIndex, scanEndIndex) {
  if (scanEndIndex == scanStartIndex) {
    return { ...path[index], index };
  } else if (scanEndIndex - scanStartIndex == 1) {
    const endIndexTimeDiff = Math.abs(path[scanEndIndex].timestamp - timestamp);
    const startIndexTimeDiff = Math.abs(path[scanStartIndex].timestamp - timestamp);
    const closestIndex = endIndexTimeDiff > startIndexTimeDiff ? scanStartIndex : scanEndIndex;
    return { ...path[closestIndex], index: closestIndex };
  } else {
    const indexTimeDiff = Math.abs(path[index].timestamp - timestamp);
    const nextIndexTimeDiff = Math.abs(path[Math.min(index + 1, path.length - 1)].timestamp - timestamp);
    if (indexTimeDiff < nextIndexTimeDiff) {
      return findClosestPointByTime(path, timestamp, scanStartIndex + Math.round((index - scanStartIndex) / 2), scanStartIndex, index);
    } else {
      return findClosestPointByTime(path, timestamp, Math.round(index + (scanEndIndex - index) / 2), index, scanEndIndex);
    }
  }
}
export function isPoiAchieved(mission, poiIndex, timestamp) {
  if (Array.isArray(mission.achieved_pois)) {
    return mission.achieved_pois.some(achievedPoi => achievedPoi.poi_index - 1 === poiIndex && (!timestamp || achievedPoi.timestamp < timestamp));
  }
  return false;
}
export function getPoiAchievementTime(mission, poiIndex) {
  if (Array.isArray(mission.achieved_pois)) {
    return mission.achieved_pois.find(achievedPoi => achievedPoi.poi_index - 1 === poiIndex)?.timestamp;
  }
}
export function isPredictedPointReached(mission, predictedPoint, pathEndIndex) {
  const path = mission.path;
  if (Array.isArray(path)) {
    const scanEndIndex = pathEndIndex || path.length;
    const scanStart = mission.lastPredictedPathScannedIndex !== undefined ? mission.lastPredictedPathScannedIndex + 1 : 0;
    return path.slice(scanStart, scanEndIndex).some((point, index) => {
      if (getEuclideanDistance(predictedPoint, point.data) <= ON_POINT_MIN_DISTANCE) {
        mission.lastPredictedPathScannedIndex = scanStart + index;
        return true;
      }
      return false;
    });
  }
  return false;
}
export function getMissionTemplatePois(mission) {
  return (mission.template_pois || []).filter(poi => !poi.isTile);
}
export function getRemainingPredictedPath(mission, { pathEndIndex } = {}) {
  let predictedPoints = mission.predicted_path || [];
  let scanIndex = mission.lastPredictedPathAchievedIndex !== undefined ? mission.lastPredictedPathAchievedIndex + 1 : 0;

  if (scanIndex < predictedPoints.length) {
    if (isPredictedPointReached(mission, predictedPoints[scanIndex], pathEndIndex)) {
      mission.lastPredictedPathAchievedIndex = scanIndex;
    }
  }

  if (mission.lastPredictedPathAchievedIndex === predictedPoints.length - 1) {
    // All points were reached
    return [];
  } else if (mission.lastPredictedPathAchievedIndex === undefined) {
    // No Points were reached
    return predictedPoints;
  } else {
    return predictedPoints.slice(mission.lastPredictedPathAchievedIndex + 1);
  }
}
export function getPathFilteredByPointsDistance(mission, { endIndex } = {}) {
  let filteredPath = [];
  const path = mission.path;
  if (path && path.length > 0) {
    const pois = mission.achieved_pois || [];

    // Always keep the start point
    filteredPath.push(path[0]);
    for (let i = 1; i < path.length && (!endIndex || i < endIndex - 1); i++) {
      const point = path[i];
      const lastLocationInPath = filteredPath[filteredPath.length - 1];

      if (getEuclideanDistance(lastLocationInPath.data, point.data) >= MIN_DISTANCE_BETWEEN_POINTS) {
        filteredPath.push(point);
      }
    }

    // Add POIs
    filteredPath = filteredPath.concat(
      pois.reduce((acc, point) => {
        if ((endIndex || endIndex === 0) && path[endIndex].timestamp < point.timestamp) {
          return acc;
        }
        if (!isNaN(point.x) && !isNaN(point.y)) {
          acc.push({ ...point, data: { x: point.x, y: point.y } });
        }

        return acc;
      }, [])
    );
  }
  return filteredPath.sort((a, b) => a.timestamp - b.timestamp);
}
export function getFailureCause(mission) {
  if (mission.custom_data?.error) {
    const errorCode = mission.custom_data.error.errorCode;
    const errMessage = getErrorMessage(errorCode);
    return `${errorCode ? `(${errorCode}):` : ''} ${errMessage || ERROR_MESSAGES.GENERIC_DEVICE_ERROR}`;
  }
}
export function getFailureError(mission) {
  if (mission.custom_data?.error) {
    const errorCode = mission.custom_data.error.errorCode;
    return `${errorCode ? `(${errorCode}):` : ''} ${mission.custom_data.error.errorDescription}`;
  }
}
