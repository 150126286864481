import { defineStore } from 'pinia';
import eventListService from '../services/api/eventListService';

export const useDashboardStore = defineStore('dashboard', {
  state() {
    return {
      dashboardEventList: {}
    };
  },
  getters: {
    getDashboardEventListByZoneId: state => {
      return zoneId => state.dashboardEventList[zoneId] || [];
    }
  },
  actions: {
    async fetchDashboardEventListByZoneId(zoneId) {
      try {
        if (!this.dashboardEventList[zoneId]) {
          this.dashboardEventList[zoneId] = await eventListService.fetchDashboardEventListByZoneId(zoneId);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    },
    addDashboardEventByZoneId(zoneId, item) {
      this.dashboardEventList[zoneId] = this.dashboardEventList[zoneId] || [];
      this.dashboardEventList[zoneId].push(item);
    }
  }
});
