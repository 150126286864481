<template>
  <div class="base-radio-group-container">
    <template v-for="option in options" :key="option.id">
      <label class="input-radio" :class="{ disabled: option.disabled }" tabindex="0">
        <input
          type="radio"
          :disabled="option.disabled"
          :checked="isOptionChecked(option.id)"
          :value="isOptionChecked(option.id)"
          :class="{ checked: isOptionChecked(option.id) }"
          @click="onOptionChecked(option.id)"
        />
        <slot
          ><span v-if="option.label">{{ option.label }}</span></slot
        >
      </label>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BaseRadioGroup',
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:modelValue'],
  methods: {
    isOptionChecked(optionId) {
      return this.modelValue === optionId;
    },
    onOptionChecked(optionId) {
      this.$emit('update:modelValue', optionId);
    }
  }
};
</script>
<style lang="scss">
.base-radio-group-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  column-gap: 0.5rem;

  .input-radio {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    column-gap: 0.2rem;

    &.disabled {
      cursor: default;
      opacity: 0.5;
    }

    &:hover:not(.disabled) {
      filter: brightness(1.1);
    }

    input[type='radio'] {
      cursor: inherit;
      flex-shrink: 0;
      column-gap: 0.2rem;
      appearance: none;
      position: relative;
      height: 1.25rem;
      width: 1.25rem;
      border-radius: 50%;
      border: 0.15rem solid var(--highlightColor);

      &.checked {
        &:after {
          content: '';
          pointer-events: none;
          width: 0.5rem;
          height: 0.5rem;
          background-color: var(--highlightColor);
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:active:enabled {
        filter: brightness(1.2);
        transform: scale(0.95);
      }
    }
  }
}
</style>
